<div class="searchbox-container">
	<button *ngIf="isUploadUser" (click)="showFileManager(null);"
		style=" float: left; padding-left: 30px; padding-right: 30px; margin-right: 30px; border: white; background-color: #99A8BD; color: white; height: 28px; position: relative; top: -6px;">Upload
		File</button>
	<input type="text" placeholder="&#xf002;  {{'main.tabs.downloads.search' | translate}}"
		(input)="onSearchInput(input.value)" class="form-control search-box" #input>
</div>
<rdo-grid-table-ng *ngIf="files" #grhid [name]="gridName" [gridConfig]="gridConfig" [pagedData]="files"
	[sorting]="sorting" [(paging)]="paging" (pageOnChange)="changePage($event)" [scrollScale]="'largeScale'"
	(lazyLoadOnChange)="changeLazyLoad($event)" [totalCount]="totalCount" [frozenWidth]="'100%'"
	[showColumnSelector]="false" [alwaysShowPager]="true" [fixedScrollableHeight]="'41vh'">
</rdo-grid-table-ng>
<div [class.hiden]="isloading">
	<rdo-overlay [enabled]="isloading"></rdo-overlay>
</div>