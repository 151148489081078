import { forwardRef, EventEmitter, Input, Output, Component, ElementRef, Renderer2, ChangeDetectorRef, ViewChild, ContentChild, ContentChildren, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { DomHandler } from 'primeng/dom';
import { ObjectUtils, FilterUtils } from 'primeng/utils';
import { Footer, Header, PrimeTemplate, SharedModule } from 'primeng/api';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TooltipModule } from 'primeng/tooltip';
var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
const MULTISELECT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultiSelect),
  multi: true
};
let MultiSelectItem = class MultiSelectItem {
  constructor() {
    this.onClick = new EventEmitter();
    this.onKeydown = new EventEmitter();
  }
  onOptionClick(event) {
    this.onClick.emit({
      originalEvent: event,
      option: this.option
    });
  }
  onOptionKeydown(event) {
    this.onKeydown.emit({
      originalEvent: event,
      option: this.option
    });
  }
};
__decorate([Input()], MultiSelectItem.prototype, "option", void 0);
__decorate([Input()], MultiSelectItem.prototype, "selected", void 0);
__decorate([Input()], MultiSelectItem.prototype, "disabled", void 0);
__decorate([Input()], MultiSelectItem.prototype, "visible", void 0);
__decorate([Input()], MultiSelectItem.prototype, "itemSize", void 0);
__decorate([Input()], MultiSelectItem.prototype, "template", void 0);
__decorate([Input()], MultiSelectItem.prototype, "maxSelectionLimitReached", void 0);
__decorate([Output()], MultiSelectItem.prototype, "onClick", void 0);
__decorate([Output()], MultiSelectItem.prototype, "onKeydown", void 0);
MultiSelectItem = __decorate([Component({
  selector: 'p-multiSelectItem',
  template: `
        <li class="ui-multiselect-item ui-corner-all" (click)="onOptionClick($event)" (keydown)="onOptionKeydown($event)" [attr.aria-label]="option.label"
            [style.display]="visible ? 'block' : 'none'" [attr.tabindex]="option.disabled ? null : '0'" [ngStyle]="{'height': itemSize + 'px'}"
            [ngClass]="{'ui-state-highlight': selected, 'ui-state-disabled': (option.disabled || (maxSelectionLimitReached && !selected))}">
            <div class="ui-chkbox ui-widget">
                <div class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"
                    [ngClass]="{'ui-state-active': selected}">
                    <span class="ui-chkbox-icon ui-clickable" [ngClass]="{'pi pi-check': selected}"></span>
                </div>
            </div>
            <span *ngIf="!template">{{option.label}}</span>
            <ng-container *ngTemplateOutlet="template; context: {$implicit: option}"></ng-container>
        </li>
    `
})], MultiSelectItem);
let MultiSelect = class MultiSelect {
  constructor(el, renderer, cd) {
    this.el = el;
    this.renderer = renderer;
    this.cd = cd;
    this.scrollHeight = '200px';
    this._defaultLabel = 'Choose';
    this.filter = true;
    this.displaySelectedLabel = true;
    this.maxSelectedLabels = 3;
    this.selectedItemsLabel = '{0} items selected';
    this.showToggleAll = true;
    this.emptyFilterMessage = 'No results found';
    this.resetFilterOnHide = false;
    this.dropdownIcon = 'pi pi-chevron-down';
    this.showHeader = true;
    this.autoZIndex = true;
    this.baseZIndex = 0;
    this.filterBy = 'label';
    this.showTransitionOptions = '225ms ease-out';
    this.hideTransitionOptions = '195ms ease-in';
    this.filterMatchMode = "contains";
    this.tooltip = '';
    this.tooltipPosition = 'right';
    this.tooltipPositionStyle = 'absolute';
    this.autofocusFilter = true;
    this.onChange = new EventEmitter();
    this.onFocus = new EventEmitter();
    this.onBlur = new EventEmitter();
    this.onClick = new EventEmitter();
    this.onPanelShow = new EventEmitter();
    this.onPanelHide = new EventEmitter();
    this.onModelChange = () => {};
    this.onModelTouched = () => {};
    this.disabledSelectedOptions = [];
  }
  set defaultLabel(val) {
    this._defaultLabel = val;
    this.updateLabel();
  }
  get defaultLabel() {
    return this._defaultLabel;
  }
  get options() {
    return this._options;
  }
  set options(val) {
    let opts = this.optionLabel ? ObjectUtils.generateSelectItems(val, this.optionLabel) : val;
    this.visibleOptions = opts;
    this._options = opts;
    this.updateLabel();
    if (this.filterValue && this.filterValue.length) {
      this.activateFilter();
    }
  }
  ngOnInit() {
    this.updateLabel();
  }
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'item':
          this.itemTemplate = item.template;
          break;
        case 'selectedItems':
          this.selectedItemsTemplate = item.template;
          break;
        default:
          this.itemTemplate = item.template;
          break;
      }
    });
  }
  ngAfterViewInit() {
    if (this.overlayVisible) {
      this.show();
    }
  }
  ngAfterViewChecked() {
    if (this.filtered) {
      this.alignOverlay();
      this.filtered = false;
    }
  }
  writeValue(value) {
    this.value = value;
    this.updateLabel();
    this.updateFilledState();
    this.setDisabledSelectedOptions();
    this.checkSelectionLimit();
    this.cd.markForCheck();
  }
  checkSelectionLimit() {
    if (this.selectionLimit && this.value && this.value.length === this.selectionLimit) {
      this.maxSelectionLimitReached = true;
    } else {
      this.maxSelectionLimitReached = false;
    }
  }
  updateFilledState() {
    this.filled = this.value && this.value.length > 0;
  }
  registerOnChange(fn) {
    this.onModelChange = fn;
  }
  registerOnTouched(fn) {
    this.onModelTouched = fn;
  }
  setDisabledState(val) {
    this.disabled = val;
  }
  onOptionClick(event) {
    let option = event.option;
    if (option.disabled) {
      return;
    }
    const optionValue = option.value;
    let selectionIndex = this.findSelectionIndex(optionValue);
    if (selectionIndex != -1) {
      this.value = this.value.filter((val, i) => i != selectionIndex);
      if (this.selectionLimit) {
        this.maxSelectionLimitReached = false;
      }
    } else {
      if (!this.selectionLimit || !this.value || this.value.length < this.selectionLimit) {
        this.value = [...(this.value || []), optionValue];
      }
      this.checkSelectionLimit();
    }
    this.onModelChange(this.value);
    this.onChange.emit({
      originalEvent: event.originalEvent,
      value: this.value,
      itemValue: optionValue
    });
    this.updateLabel();
    this.updateFilledState();
  }
  isSelected(value) {
    return this.findSelectionIndex(value) != -1;
  }
  findSelectionIndex(val) {
    let index = -1;
    if (this.value) {
      for (let i = 0; i < this.value.length; i++) {
        if (ObjectUtils.equals(this.value[i], val, this.dataKey)) {
          index = i;
          break;
        }
      }
    }
    return index;
  }
  toggleAll(event) {
    if (this.isAllChecked()) {
      if (this.disabledSelectedOptions && this.disabledSelectedOptions.length > 0) {
        let value = [];
        value = [...this.disabledSelectedOptions];
        this.value = value;
      } else {
        this.value = [];
      }
    } else {
      let opts = this.getVisibleOptions();
      if (opts) {
        let value = [];
        if (this.disabledSelectedOptions && this.disabledSelectedOptions.length > 0) {
          value = [...this.disabledSelectedOptions];
        }
        for (let i = 0; i < opts.length; i++) {
          let option = opts[i];
          if (!option.disabled) {
            value.push(opts[i].value);
          }
        }
        this.value = value;
      }
    }
    this.onModelChange(this.value);
    this.onChange.emit({
      originalEvent: event,
      value: this.value
    });
    this.updateFilledState();
    this.updateLabel();
  }
  isAllChecked() {
    if (this.filterValue && this.filterValue.trim().length) {
      return this.value && this.visibleOptions && this.visibleOptions.length && this.isAllVisibleOptionsChecked();
    } else {
      let optionCount = this.getEnabledOptionCount();
      let disabledSelectedOptionCount = this.disabledSelectedOptions.length;
      return this.value && this.options && this.value.length > 0 && this.value.length == optionCount + disabledSelectedOptionCount;
    }
  }
  isAllVisibleOptionsChecked() {
    if (!this.visibleOptions || this.visibleOptions.length === 0) {
      return false;
    } else {
      for (let option of this.visibleOptions) {
        if (!this.isSelected(option.value)) {
          return false;
        }
      }
      return true;
    }
  }
  getEnabledOptionCount() {
    if (this.options) {
      let count = 0;
      for (let opt of this.options) {
        if (!opt.disabled) {
          count++;
        }
      }
      return count;
    } else {
      return 0;
    }
  }
  setDisabledSelectedOptions() {
    if (this.options) {
      this.disabledSelectedOptions = [];
      if (this.value) {
        for (let opt of this.options) {
          if (opt.disabled && this.isSelected(opt.value)) {
            this.disabledSelectedOptions.push(opt.value);
          }
        }
      }
    }
  }
  show() {
    if (!this.overlayVisible) {
      this.overlayVisible = true;
    }
  }
  onOverlayAnimationStart(event) {
    switch (event.toState) {
      case 'visible':
        this.overlay = event.element;
        this.appendOverlay();
        if (this.autoZIndex) {
          this.overlay.style.zIndex = String(this.baseZIndex + ++DomHandler.zindex);
        }
        this.alignOverlay();
        this.bindDocumentClickListener();
        this.bindDocumentResizeListener();
        if (this.filterInputChild && this.filterInputChild.nativeElement) {
          this.preventModelTouched = true;
          if (this.autofocusFilter) {
            this.filterInputChild.nativeElement.focus();
          }
        }
        this.onPanelShow.emit();
        break;
      case 'void':
        this.onOverlayHide();
        break;
    }
  }
  appendOverlay() {
    if (this.appendTo) {
      if (this.appendTo === 'body') document.body.appendChild(this.overlay);else DomHandler.appendChild(this.overlay, this.appendTo);
      if (!this.overlay.style.minWidth) {
        this.overlay.style.minWidth = DomHandler.getWidth(this.containerViewChild.nativeElement) + 'px';
      }
    }
  }
  restoreOverlayAppend() {
    if (this.overlay && this.appendTo) {
      this.el.nativeElement.appendChild(this.overlay);
    }
  }
  alignOverlay() {
    if (this.overlay) {
      if (this.appendTo) DomHandler.absolutePosition(this.overlay, this.containerViewChild.nativeElement);else DomHandler.relativePosition(this.overlay, this.containerViewChild.nativeElement);
    }
  }
  hide() {
    this.overlayVisible = false;
    this.unbindDocumentClickListener();
    if (this.resetFilterOnHide) {
      this.filterInputChild.nativeElement.value = '';
      this.onFilter();
    }
    this.onPanelHide.emit();
  }
  close(event) {
    this.hide();
    event.preventDefault();
    event.stopPropagation();
  }
  onMouseclick(event, input) {
    if (this.disabled || this.readonly || event.target.isSameNode(this.accessibleViewChild.nativeElement)) {
      return;
    }
    this.onClick.emit(event);
    if (!this.isOverlayClick(event)) {
      if (this.overlayVisible) {
        this.hide();
      } else {
        input.focus();
        this.show();
      }
    }
  }
  isOverlayClick(event) {
    return this.overlay && this.overlay.contains(event.target);
  }
  isOutsideClicked(event) {
    return !(this.el.nativeElement.isSameNode(event.target) || this.el.nativeElement.contains(event.target) || this.isOverlayClick(event));
  }
  onInputFocus(event) {
    this.focus = true;
    this.onFocus.emit({
      originalEvent: event
    });
  }
  onInputBlur(event) {
    this.focus = false;
    this.onBlur.emit({
      originalEvent: event
    });
    if (!this.preventModelTouched) {
      this.onModelTouched();
    }
    this.preventModelTouched = false;
  }
  onOptionKeydown(event) {
    if (this.readonly) {
      return;
    }
    switch (event.originalEvent.which) {
      //down
      case 40:
        var nextItem = this.findNextItem(event.originalEvent.target.parentElement);
        if (nextItem) {
          nextItem.focus();
        }
        event.originalEvent.preventDefault();
        break;
      //up
      case 38:
        var prevItem = this.findPrevItem(event.originalEvent.target.parentElement);
        if (prevItem) {
          prevItem.focus();
        }
        event.originalEvent.preventDefault();
        break;
      //enter
      case 13:
        this.onOptionClick(event);
        event.originalEvent.preventDefault();
        break;
    }
  }
  findNextItem(item) {
    let nextItem = item.nextElementSibling;
    if (nextItem) return DomHandler.hasClass(nextItem.children[0], 'ui-state-disabled') || DomHandler.isHidden(nextItem.children[0]) ? this.findNextItem(nextItem) : nextItem.children[0];else return null;
  }
  findPrevItem(item) {
    let prevItem = item.previousElementSibling;
    if (prevItem) return DomHandler.hasClass(prevItem.children[0], 'ui-state-disabled') || DomHandler.isHidden(prevItem.children[0]) ? this.findPrevItem(prevItem) : prevItem.children[0];else return null;
  }
  onKeydown(event) {
    switch (event.which) {
      //down
      case 40:
        if (!this.overlayVisible && event.altKey) {
          this.show();
          event.preventDefault();
        }
        break;
      //space
      case 32:
        if (!this.overlayVisible) {
          this.show();
          event.preventDefault();
        }
        break;
      //escape
      case 27:
        this.hide();
        break;
    }
  }
  updateLabel() {
    if (this.value && this.options && this.value.length && this.displaySelectedLabel) {
      let label = '';
      for (let i = 0; i < this.value.length; i++) {
        let itemLabel = this.findLabelByValue(this.value[i]);
        if (itemLabel) {
          if (label.length > 0) {
            label = label + ', ';
          }
          label = label + itemLabel;
        }
      }
      if (this.value.length <= this.maxSelectedLabels) {
        this.valuesAsString = label;
      } else {
        let pattern = /{(.*?)}/;
        if (pattern.test(this.selectedItemsLabel)) {
          this.valuesAsString = this.selectedItemsLabel.replace(this.selectedItemsLabel.match(pattern)[0], this.value.length + '');
        } else {
          this.valuesAsString = this.selectedItemsLabel;
        }
      }
    } else {
      this.valuesAsString = this.defaultLabel;
    }
  }
  findLabelByValue(val) {
    let label = null;
    for (let i = 0; i < this.options.length; i++) {
      let option = this.options[i];
      if (val == null && option.value == null || ObjectUtils.equals(val, option.value, this.dataKey)) {
        label = option.label;
        break;
      }
    }
    return label;
  }
  onFilter() {
    let inputValue = this.filterInputChild.nativeElement.value;
    if (inputValue && inputValue.length) {
      this.filterValue = inputValue;
      this.activateFilter();
    } else {
      this.filterValue = null;
      this.visibleOptions = this.options;
      this.filtered = false;
    }
  }
  activateFilter() {
    if (this.options && this.options.length) {
      let searchFields = this.filterBy.split(',');
      this.visibleOptions = FilterUtils.filter(this.options, searchFields, this.filterValue, this.filterMatchMode, this.filterLocale);
      this.filtered = true;
    }
  }
  isItemVisible(option) {
    if (this.filterValue && this.filterValue.trim().length) {
      for (let i = 0; i < this.visibleOptions.length; i++) {
        if (this.visibleOptions[i].value == option.value) {
          return true;
        }
      }
    } else {
      return true;
    }
  }
  getVisibleOptions() {
    return this.visibleOptions || this.options;
  }
  onHeaderCheckboxFocus() {
    this.headerCheckboxFocus = true;
  }
  onHeaderCheckboxBlur() {
    this.headerCheckboxFocus = false;
  }
  bindDocumentClickListener() {
    if (!this.documentClickListener) {
      this.documentClickListener = this.renderer.listen('document', 'click', event => {
        if (this.isOutsideClicked(event)) {
          this.hide();
        }
        this.cd.markForCheck();
      });
    }
  }
  unbindDocumentClickListener() {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }
  bindDocumentResizeListener() {
    this.documentResizeListener = this.onWindowResize.bind(this);
    window.addEventListener('resize', this.documentResizeListener);
  }
  unbindDocumentResizeListener() {
    if (this.documentResizeListener) {
      window.removeEventListener('resize', this.documentResizeListener);
      this.documentResizeListener = null;
    }
  }
  onWindowResize() {
    if (!DomHandler.isAndroid()) {
      this.hide();
    }
  }
  onOverlayHide() {
    this.unbindDocumentClickListener();
    this.unbindDocumentResizeListener();
    this.overlay = null;
    this.onModelTouched();
  }
  ngOnDestroy() {
    this.restoreOverlayAppend();
    this.onOverlayHide();
  }
};
MultiSelect.ctorParameters = () => [{
  type: ElementRef
}, {
  type: Renderer2
}, {
  type: ChangeDetectorRef
}];
__decorate([Input()], MultiSelect.prototype, "scrollHeight", void 0);
__decorate([Input()], MultiSelect.prototype, "defaultLabel", null);
__decorate([Input()], MultiSelect.prototype, "style", void 0);
__decorate([Input()], MultiSelect.prototype, "styleClass", void 0);
__decorate([Input()], MultiSelect.prototype, "panelStyle", void 0);
__decorate([Input()], MultiSelect.prototype, "panelStyleClass", void 0);
__decorate([Input()], MultiSelect.prototype, "inputId", void 0);
__decorate([Input()], MultiSelect.prototype, "disabled", void 0);
__decorate([Input()], MultiSelect.prototype, "readonly", void 0);
__decorate([Input()], MultiSelect.prototype, "filter", void 0);
__decorate([Input()], MultiSelect.prototype, "filterPlaceHolder", void 0);
__decorate([Input()], MultiSelect.prototype, "filterLocale", void 0);
__decorate([Input()], MultiSelect.prototype, "overlayVisible", void 0);
__decorate([Input()], MultiSelect.prototype, "tabindex", void 0);
__decorate([Input()], MultiSelect.prototype, "appendTo", void 0);
__decorate([Input()], MultiSelect.prototype, "dataKey", void 0);
__decorate([Input()], MultiSelect.prototype, "name", void 0);
__decorate([Input()], MultiSelect.prototype, "ariaLabelledBy", void 0);
__decorate([Input()], MultiSelect.prototype, "displaySelectedLabel", void 0);
__decorate([Input()], MultiSelect.prototype, "maxSelectedLabels", void 0);
__decorate([Input()], MultiSelect.prototype, "selectionLimit", void 0);
__decorate([Input()], MultiSelect.prototype, "selectedItemsLabel", void 0);
__decorate([Input()], MultiSelect.prototype, "showToggleAll", void 0);
__decorate([Input()], MultiSelect.prototype, "emptyFilterMessage", void 0);
__decorate([Input()], MultiSelect.prototype, "resetFilterOnHide", void 0);
__decorate([Input()], MultiSelect.prototype, "dropdownIcon", void 0);
__decorate([Input()], MultiSelect.prototype, "optionLabel", void 0);
__decorate([Input()], MultiSelect.prototype, "showHeader", void 0);
__decorate([Input()], MultiSelect.prototype, "autoZIndex", void 0);
__decorate([Input()], MultiSelect.prototype, "baseZIndex", void 0);
__decorate([Input()], MultiSelect.prototype, "filterBy", void 0);
__decorate([Input()], MultiSelect.prototype, "virtualScroll", void 0);
__decorate([Input()], MultiSelect.prototype, "itemSize", void 0);
__decorate([Input()], MultiSelect.prototype, "showTransitionOptions", void 0);
__decorate([Input()], MultiSelect.prototype, "hideTransitionOptions", void 0);
__decorate([Input()], MultiSelect.prototype, "ariaFilterLabel", void 0);
__decorate([Input()], MultiSelect.prototype, "filterMatchMode", void 0);
__decorate([Input()], MultiSelect.prototype, "tooltip", void 0);
__decorate([Input()], MultiSelect.prototype, "tooltipPosition", void 0);
__decorate([Input()], MultiSelect.prototype, "tooltipPositionStyle", void 0);
__decorate([Input()], MultiSelect.prototype, "tooltipStyleClass", void 0);
__decorate([Input()], MultiSelect.prototype, "autofocusFilter", void 0);
__decorate([ViewChild('container')], MultiSelect.prototype, "containerViewChild", void 0);
__decorate([ViewChild('filterInput')], MultiSelect.prototype, "filterInputChild", void 0);
__decorate([ViewChild('in')], MultiSelect.prototype, "accessibleViewChild", void 0);
__decorate([ContentChild(Footer)], MultiSelect.prototype, "footerFacet", void 0);
__decorate([ContentChild(Header)], MultiSelect.prototype, "headerFacet", void 0);
__decorate([ContentChildren(PrimeTemplate)], MultiSelect.prototype, "templates", void 0);
__decorate([Output()], MultiSelect.prototype, "onChange", void 0);
__decorate([Output()], MultiSelect.prototype, "onFocus", void 0);
__decorate([Output()], MultiSelect.prototype, "onBlur", void 0);
__decorate([Output()], MultiSelect.prototype, "onClick", void 0);
__decorate([Output()], MultiSelect.prototype, "onPanelShow", void 0);
__decorate([Output()], MultiSelect.prototype, "onPanelHide", void 0);
__decorate([Input()], MultiSelect.prototype, "options", null);
MultiSelect = __decorate([Component({
  selector: 'p-multiSelect',
  template: `
        <div #container [ngClass]="{'ui-multiselect ui-widget ui-state-default ui-corner-all':true,'ui-multiselect-open':overlayVisible,'ui-state-focus':focus,'ui-state-disabled': disabled}" [ngStyle]="style" [class]="styleClass"
            (click)="onMouseclick($event,in)">
            <div class="ui-helper-hidden-accessible">
                <input #in type="text" readonly="readonly" [attr.id]="inputId" [attr.name]="name" (focus)="onInputFocus($event)" (blur)="onInputBlur($event)"
                       [disabled]="disabled" [attr.tabindex]="tabindex" (keydown)="onKeydown($event)" aria-haspopup="listbox" [attr.aria-expanded]="overlayVisible"
                       [attr.aria-labelledby]="ariaLabelledBy" role="listbox">
            </div>
            <div class="ui-multiselect-label-container" [pTooltip]="tooltip" [tooltipPosition]="tooltipPosition" [positionStyle]="tooltipPositionStyle" [tooltipStyleClass]="tooltipStyleClass">
                <span class="ui-multiselect-label ui-corner-all">
                    <ng-container *ngIf="!selectedItemsTemplate">{{valuesAsString}}</ng-container>
                    <ng-container *ngTemplateOutlet="selectedItemsTemplate; context: {$implicit: value}"></ng-container>
                </span>
            </div>
            <div [ngClass]="{'ui-multiselect-trigger ui-state-default ui-corner-right':true}">
                <span class="ui-multiselect-trigger-icon ui-clickable" [ngClass]="dropdownIcon"></span>
            </div>
            <div *ngIf="overlayVisible" [ngClass]="['ui-multiselect-panel ui-widget ui-widget-content ui-corner-all ui-shadow']" [@overlayAnimation]="{value: 'visible', params: {showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions}}" (@overlayAnimation.start)="onOverlayAnimationStart($event)"
                [ngStyle]="panelStyle" [class]="panelStyleClass" (keydown)="onKeydown($event)">
                <div class="ui-widget-header ui-corner-all ui-multiselect-header ui-helper-clearfix" [ngClass]="{'ui-multiselect-header-no-toggleall': !showToggleAll}" *ngIf="showHeader">
                <ng-content select="p-header"></ng-content>
                <div class="ui-chkbox ui-widget" *ngIf="showToggleAll && !selectionLimit">
                        <div class="ui-helper-hidden-accessible">
                            <input type="checkbox" readonly="readonly" [checked]="isAllChecked()" (focus)="onHeaderCheckboxFocus()" (blur)="onHeaderCheckboxBlur()" (keydown.space)="toggleAll($event)">
                        </div>
                        <div class="ui-chkbox-box ui-widget ui-corner-all ui-state-default" role="checkbox" [attr.aria-checked]="isAllChecked()" [ngClass]="{'ui-state-active':isAllChecked(), 'ui-state-focus': headerCheckboxFocus}" (click)="toggleAll($event)">
                            <span class="ui-chkbox-icon ui-clickable" [ngClass]="{'pi pi-check':isAllChecked()}"></span>
                        </div>
                    </div>
                    <div class="ui-multiselect-filter-container" *ngIf="filter">
                        <input #filterInput type="text" role="textbox" [value]="filterValue||''" (input)="onFilter()" class="ui-inputtext ui-widget ui-state-default ui-corner-all" [attr.placeholder]="filterPlaceHolder" [attr.aria-label]="ariaFilterLabel">
                        <span class="ui-multiselect-filter-icon pi pi-search"></span>
                    </div>
                    <a class="ui-multiselect-close ui-corner-all" tabindex="0" (click)="close($event)" (keydown.enter)="close($event)">
                        <span class="pi pi-times"></span>
                    </a>
                </div>
                <div class="ui-multiselect-items-wrapper" [style.max-height]="virtualScroll ? 'auto' : (scrollHeight||'auto')">
                    <ul class="ui-multiselect-items ui-multiselect-list ui-widget-content ui-widget ui-corner-all ui-helper-reset" role="listbox" aria-multiselectable="true">
                        <ng-container *ngIf="!virtualScroll; else virtualScrollList">
                            <ng-template ngFor let-option let-i="index" [ngForOf]="options">
                                <p-multiSelectItem [option]="option" [selected]="isSelected(option.value)" (onClick)="onOptionClick($event)" (onKeydown)="onOptionKeydown($event)"
                                        [maxSelectionLimitReached]="maxSelectionLimitReached" [visible]="isItemVisible(option)" [template]="itemTemplate"></p-multiSelectItem>
                            </ng-template>
                        </ng-container>
                        <ng-template #virtualScrollList>
                            <cdk-virtual-scroll-viewport #viewport [ngStyle]="{'height': scrollHeight}" [itemSize]="itemSize" *ngIf="virtualScroll && visibleOptions && visibleOptions.length">
                                <ng-container *cdkVirtualFor="let option of visibleOptions; let i = index; let c = count; let f = first; let l = last; let e = even; let o = odd">
                                    <p-multiSelectItem [option]="option" [selected]="isSelected(option.value)" (onClick)="onOptionClick($event)" (onKeydown)="onOptionKeydown($event)"
                                        [maxSelectionLimitReached]="maxSelectionLimitReached" [visible]="isItemVisible(option)" [template]="itemTemplate" [itemSize]="itemSize"></p-multiSelectItem>
                                </ng-container>
                            </cdk-virtual-scroll-viewport>
                        </ng-template>
                        <li *ngIf="filter && visibleOptions && visibleOptions.length === 0" class="ui-multiselect-empty-message">{{emptyFilterMessage}}</li>
                    </ul>
                </div>
                <div class="ui-multiselect-footer ui-widget-content" *ngIf="footerFacet">
                    <ng-content select="p-footer"></ng-content>
                </div>
            </div>
        </div>
    `,
  animations: [trigger('overlayAnimation', [state('void', style({
    transform: 'translateY(5%)',
    opacity: 0
  })), state('visible', style({
    transform: 'translateY(0)',
    opacity: 1
  })), transition('void => visible', animate('{{showTransitionParams}}')), transition('visible => void', animate('{{hideTransitionParams}}'))])],
  host: {
    '[class.ui-inputwrapper-filled]': 'filled',
    '[class.ui-inputwrapper-focus]': 'focus'
  },
  providers: [MULTISELECT_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.Default
})], MultiSelect);
let MultiSelectModule = class MultiSelectModule {};
MultiSelectModule = __decorate([NgModule({
  imports: [CommonModule, SharedModule, ScrollingModule, TooltipModule],
  exports: [MultiSelect, SharedModule, ScrollingModule],
  declarations: [MultiSelect, MultiSelectItem]
})], MultiSelectModule);

/**
 * Generated bundle index. Do not edit.
 */

export { MULTISELECT_VALUE_ACCESSOR, MultiSelect, MultiSelectItem, MultiSelectModule };
