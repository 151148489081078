import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FilterProfile } from '../models/filter-profile';
import { FilterProfileService } from '../filter-profile.service';
import { AuthenticationService } from '../../../core/authentication/authentication.service';

/**
 * This component is used to manage FilterProfiles by renaming,
 * deleting or setting them as default.
 */
@Component({
  selector: 'rdo-filter-profile-management',
  templateUrl: './filter-profile-management.component.html',
  styleUrls: ['./filter-profile-management.component.scss']
})
export class FilterProfileManagementComponent implements OnInit {
  public saving = false;
  public selected: number;
  public nameIndex: number = -1;
  public clientShortName: string;
  public translatedClientProfileName: string;
  public profilesList: FilterProfile[];
  public idsToDelete: Array<number> = [];
  public nonModifiedProfilesList: FilterProfile[];
  public showManagement: boolean = false;

  constructor(
    public translateService: TranslateService,
    private filterService: FilterProfileService,
    private authenticationService: AuthenticationService,
  ) {
    this.authenticationService?.selectedClientId.subscribe(() => {
      this.clientShortName = this.authenticationService._userInfoView?.SelectedClient?.ClientShortName || '';
    });
    this.filterService.profilesList.subscribe((value) => {
      value = value.filter((x) => !x.isTempProfile && x.profileName);
      // eslint-disable-next-line @typescript-eslint/unbound-method
      value = !value?.length ? [] : value.sort(FilterProfile.compareByName); // Validate and Sort
      this.profilesList = JSON.parse(JSON.stringify(value)); // Deep Copy
      this.nonModifiedProfilesList = JSON.parse(JSON.stringify(value)); // Deep Copy
      this.handleArrayChange();
      this.updateDefaultProfileName();
    });
    this.filterService.showManagement.subscribe(value => {
      this.showManagement = value;
    });
  }

  private updateDefaultProfileName() {
    this.translatedClientProfileName = this.translateService.instant('main.filters.profiles.list.no_selected');
  }

  ngOnInit(): void {
    //Check to see if it is null for tests
    if (this.filterService.currentProfile) {
      this.filterService.currentProfile.subscribe(currentProfile => {
        // Used after saving updates
        if (this.showManagement) {
          this.onClose();
        }
      });
    }
  }

  /**
   * Simply closes the profile management modal. All changes are
   * lost when this happens.
   */
  public onClose() {
    this.showManagement = false;
    this.filterService.showManagement.next(false);
    this.selected = this.nonModifiedProfilesList.findIndex(x => x.isDefault);
    this.resetComponent();
  }

  /**
   * Resets the inner data of the current component.
   */
  private resetComponent() {
    this.idsToDelete = [];
    this.saving = false;
    this.profilesList = JSON.parse(JSON.stringify(this.nonModifiedProfilesList)); // Deep Copy
    this.profilesList.sort(FilterProfile.compareByName); // eslint-disable-line @typescript-eslint/unbound-method
    this.filterService.toggleSpinner(false);
  }

  /**
   * Calls the Python API to delete a list of FilterProfiles which
   * ids are stoed in idsToDelete and also calls the API to persist
   * profile modifications into the DB.
   */
  public onSave() {
    this.filterService.toggleSpinner(true);
    this.saving = true;
    const modifiedProfiles = this.getModifiedProfiles();
    this.filterService.updateSomeDeleteOthers(modifiedProfiles, this.idsToDelete)
      .subscribe(() => {
        const currentProfile = this.filterService.currentProfile.value;
        let currentProfileId = currentProfile ? currentProfile.profileId : null;
        if (currentProfileId !== null && this.idsToDelete.includes(currentProfileId)) {
          currentProfileId = null;
        }
        this.filterService.loadProfiles(currentProfileId, true);
      });
  }

  /**
   * Returns the list of FilterProfiles that have been modified by
   * the user excluding the ones that have been deleted.
   */
  public getModifiedProfiles() {
    this.persistSelection();
    const nonDeletedUnmodifiedProfiles = this.nonModifiedProfilesList.filter(x => !this.idsToDelete.includes(x.profileId));
    const modifiedProfiles: FilterProfile[] = [];
    this.profilesList.forEach(profile => {
      const unchangedVersion = nonDeletedUnmodifiedProfiles.find(x => x.profileId === profile.profileId);
      const wasModified = !!unchangedVersion &&
        (unchangedVersion.isDefault !== profile.isDefault || unchangedVersion.profileName !== profile.profileName);
      if (wasModified) {
        modifiedProfiles.push(profile);
      }
    });
    return modifiedProfiles;
  }

  /**
   * Used to show or hide the input required for renaming filter
   * profiles.
   */
  public onNameEditClick(index: number) {
    this.nameIndex = index !== this.nameIndex ? index : -1;
  }

  /**
   * Handles deleting a filter profile but it doesn't delete it
   * from the db until the onSave function is called.
   */
  public onDelete(index: number) {
    if (index >= 0) {
      this.persistSelection();
      this.idsToDelete.push(this.profilesList[index].profileId);
      this.profilesList.splice(index, 1);
      this.handleArrayChange();
    }
  }

  /**
   * Sets the default filter in the local profiles list using the
   * value in the 'selected' variable. This is done this way to
   * make it easier to switch checkboxes when selecting only one
   * profile as default.
   */
  private persistSelection() {
    this.profilesList.forEach((x, index) => x.isDefault = index === this.selected);
  }

  /**
   * Used to set the initial value of the 'selected' variable, which
   * contains the index of the profile that is currently set as default.
   */
  private handleArrayChange() {
    if (this.profilesList && this.profilesList.length) {
      const defaultProfile = this.profilesList.find(x => x.isDefault === true);
      const index = this.profilesList.indexOf(defaultProfile);
      this.selected = index;
    } else {
      this.selected = -1;
    }
  }

  /**
   * Tells whether to show or not the save btn if there are profiles
   * available or if some profiles have been removed, as long as no
   * filter profile name is being edited.
   */
  public showSaveBtn() {
    return ((this.profilesList && this.profilesList.length) || (this.idsToDelete && this.idsToDelete.length)) && this.nameIndex === -1 && !this.saving;
  }

  /**
   * Shows a disabled version of the save btn for the cases where the
   * btn has to be shown but some profile name is being modified.
   */
  public showDisabledSaveBtn() {
    return (((this.profilesList && this.profilesList.length) || (this.idsToDelete && this.idsToDelete.length)) && this.nameIndex !== -1) || this.saving;
  }

  /**
   * Handles the changing of a profile name by hiding the profile
   * name input and then checking for duplicates. If a duplicated
   * name is found, the current name gets a (<n>) at its end that
   * increased with every new duplicate found.
   */
  public onNameChanged(index: number) {
    this.nameIndex = -1
    // Add (<n>) to the edited name
    const nonAvailableNames = [];
    let currentName = this.profilesList[index].profileName.trim();
    this.profilesList.forEach((x, i) => {
      if (i !== index) {
        nonAvailableNames.push(x.profileName.trim());
      }
    });
    let duplicatedNameIndex = 1;
    if (nonAvailableNames.includes(currentName)) {
      while (nonAvailableNames.includes(currentName + ' (' + duplicatedNameIndex + ')')) {
        duplicatedNameIndex++;
      }
      currentName = currentName + ' (' + duplicatedNameIndex + ')';
    }
    this.profilesList[index].profileName = currentName;
  }

  /**
   * Used by the filterComponent, returns true if the given className
   * contains any of a given set of css classes within this component.
   * This is used so that clicks within the FilterProfile management
   * component don't close the filters screen.
   */
  public static checkClassName(className: string) {
    const classesInManagement = ['background', 'popup', 'checkbox', 'profile'];
    let isManagementGrandChild = false;
    classesInManagement.forEach(x => isManagementGrandChild = isManagementGrandChild || (className && className?.includes(x)));
    return isManagementGrandChild;
  }
}
