import { StepActionType, TriggerType, UserGuide, UserGuideOptions, UserGuideStep } from '../models';

function getGuide() {
    const guide: UserGuide = {
        // Data to audit the object in the db
        id: null,
        name: 'filters',
        translatedName: 'Filters Tour',
        created_date: new Date(2023, 11, 28),
        created_by: 'Marcelo Schild',
        modified_date: null,
        modified_by: null,
  
        // Data to know when and where to execute it
        application: 'RDO',
        trigger_type: TriggerType.USER,
        trigger_value: 'Manual',
  
        // Actual JSON that affects IntroJS
        options: Object.assign(new UserGuideOptions(), {
          exitOnOverlayClick: false,
          keyboardNavigation: true,
          doneLabel: 'main.shared.finish',
          prevLabel: 'main.shared.back',
          nextLabel: 'main.shared.next',
          setupActionCode: `let btn = document.querySelector('#close-filter-button');btn && btn.click();`, // Make sure the filters are closed
          finishingActionCode: `let elem = document.getElementById('rate-benchmark-label');elem && elem.dispatchEvent(new Event('mouseleave'));`, // Make sure the hover tooltip is hidden
          steps: [{
            element: '#filter_toggle',
            intro: 'main.filters.step_text_1',
            type: StepActionType.HIGHLIGHT
          }, {
            actionCode: `let btn = document.querySelector('#filters-menu');btn && btn.click();`,
            undoActionCode: `let btn = document.querySelector('#close-filter-button');btn && btn.click();`,
            type: StepActionType.ACTION
          }, {
            element: '#rate-benchmark-label',
            title: 'main.filters.step_title_2',
            intro: 'main.filters.step_text_2',
            type: StepActionType.HIGHLIGHT
          }, {
            // Hover Action
            actionCode: `let elem = document.getElementById('rate-benchmark-label');elem && elem.dispatchEvent(new Event('mouseenter'));`,
            undoActionCode: `let elem = document.getElementById('rate-benchmark-label');elem && elem.dispatchEvent(new Event('mouseleave'));`,
            type: StepActionType.ACTION
          }, {
            stepsCount: 1,
            waitForElement: '.mat-tooltip',
            type: StepActionType.REFRESH_FORWARD
          }, {
            element: '.mat-tooltip',
            title: 'main.filters.step_title_2',
            intro: 'main.filters.step_text_2',
            type: StepActionType.HIGHLIGHT
          }, {
            stepsCount: 1,
            type: StepActionType.REFRESH_BACKWARDS
          },{
            actionCode: `let elem = document.getElementById('rate-benchmark-label');elem && elem.dispatchEvent(new Event('mouseleave'));`,
            undoActionCode: `let elem = document.getElementById('rate-benchmark-label');elem && elem.dispatchEvent(new Event('mouseenter'));`,
            type: StepActionType.ACTION
          }, {
            element: '#Filter_Month',
            title: 'main.filters.step_title_3',
            intro: 'main.filters.step_text_3',
            type: StepActionType.HIGHLIGHT
          }, {
            element: '#Filter_Comparison',
            title: 'main.filters.step_title_4',
            intro: 'main.filters.step_text_4',
            type: StepActionType.HIGHLIGHT
          }, {
            element: '#geo-filters',
            title: 'main.filters.step_title_5',
            intro: 'main.filters.step_text_5',
            type: StepActionType.HIGHLIGHT
          }, {
            element: '#equipment-title',
            title: 'main.filters.step_title_6',
            intro: 'main.filters.step_text_6',
            type: StepActionType.HIGHLIGHT
          }, {
            element: '#transaction-title',
            title: 'main.filters.step_title_7',
            intro: 'main.filters.step_text_7',
            type: StepActionType.HIGHLIGHT
          }, {
            element: '#saveFilterProfileAsBtn',
            title: 'main.filters.step_title_8',
            intro: 'main.filters.step_text_8',
            type: StepActionType.HIGHLIGHT
          }, {
            element: '#apply-filters-button',
            intro: 'main.filters.step_text_9',
            type: StepActionType.HIGHLIGHT
          }, {
            actionCode: `let btn = document.querySelector('#close-filter-button');btn && btn.click();`,
            undoActionCode: `let btn = document.querySelector('#filters-menu');btn && btn.click();`,
            type: StepActionType.ACTION
          }, {
            type: StepActionType.REFRESH_FORWARD
          }, {
            element: '#filters-container',
            intro: 'main.filters.step_text_10',
            type: StepActionType.HIGHLIGHT
          }]
        })
      };
      guide.options.steps = guide.options.steps.map(x => Object.assign(new UserGuideStep(), x));
      return guide;
}

export const FILTERS_GUIDE = getGuide();
