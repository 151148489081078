import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '../core';
import { DownloadService } from './download.service';
import { HeaderService } from '../header';
import { AuthenticationService } from '../core/authentication/authentication.service';

@Component({
    selector: 'rdo-download',
    template: ''
})

export class DownloadComponent implements OnInit { //, OnDestroy {

    constructor(private authenticationService: AuthenticationService,
                private activatedRoute: ActivatedRoute,
                private downloadService: DownloadService) {
    }

    ngOnInit() {

        this.activatedRoute.paramMap.subscribe(params => {

            const filename = params.get('filename');
            this.getFile(filename);
        });
    }

    public getFile = (filename: string) => {

        this.downloadService.getSaveFile(filename);  
    }
}

