import { Injectable } from '@angular/core';
import { ChartService } from './../base/chart.service'
import { ActiveFilterService, FormatService } from './../../query';
import { MathService } from './../../math';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class RentalGrowthChartService extends ChartService {

    constructor(
        mathService: MathService,
		formatService: FormatService,
		translateService: TranslateService,
		activeFilterService: ActiveFilterService,
    ) {
        super(mathService, formatService, translateService, activeFilterService);
    }

    protected getOptions(chart: any, data: any): object {
        return null;
    }

}
