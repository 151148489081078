import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';

export class HttpLanguageLoader implements TranslateLoader {

    constructor(
        private httpClient: HttpClient,
        private authenticationService: AuthenticationService
    ) { }
    
    getTranslation(lang: string, localizationSubdirectory: string='static-translations'): Observable<any> {
        const result: Observable<any> = new Observable((subscriber) => {
            this.authenticationService.Authenticated.subscribe(value => {
                if (value) {
                    this.authenticationService
                    .getAuthToken()
                    .subscribe(token => {
                        const gotLang = this.authenticationService && this.authenticationService._userInfoView && this.authenticationService._userInfoView.lang;
                        const actualLang = gotLang ? this.authenticationService._userInfoView.lang : lang;
                        this.callApi(actualLang, token, localizationSubdirectory).toPromise()
                        .then(apiResult => {
                            subscriber.next(apiResult);
                        });
                    });
                }
            });
        });
        return result;
    }

    /**
     * Gets the proper translations from the api.
     */
    private callApi(lang: string, token: any, localizationSubdirectory: string='static-translations') {
        let url = environment.apiUrl;
        url = url.charAt(url.length) === '/' ? url : url + '/';
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token.AccessToken}`);
        const endpoint = url + 'localization/' + localizationSubdirectory + '?locale=' + lang;
        return this.httpClient.get(endpoint, {headers: headers,  withCredentials: true});
    }
}