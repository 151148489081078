import { SortOptions, PageOptions} from '../models';

export enum Interval {
	Hourly = 0,
	Daily = 1,
	Weekly = 2,
	Monthly = 3
}

export interface ProductTypeTransactionQuery {
	productType?: string;
	rateType?: Interval;
	customer?: number;
	salesRep?: string;
	category?: string;
	fromValue?: number;
	toValue?: number;
	belowBq?: boolean;
	aboveTq?: boolean;
	tqToAvg?: boolean;
	avgToBq?: boolean;
	unbenchmarked?: boolean;
	useRentedAsProductType?: boolean;
	sort?: SortOptions;
	paging?: PageOptions;
}

