import { NgModule } from '@angular/core';
import { RouterModule, withDebugTracing } from '@angular/router';
import { SummaryComponent } from './../summary';

const enableTracing = false;

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: 'summary',
          component: SummaryComponent,
          data: { title: 'main.tabs.summary.page_title' },
        },
      ],
      {
        onSameUrlNavigation: 'reload',
        enableTracing,
      }
    ),
  ],
})
export class AppRouterModule {}
