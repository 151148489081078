import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbService } from '../core/view/breadcrumb/breadcrumb.service';
import { DownloadsService } from './downloads.service';
import { HeaderService } from '../header';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { DownloadService } from '../download/download.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'rdo-downloads',
    templateUrl: 'downloads.component.html',
    styleUrls: ['./downloads.component.scss'],
})
export class DownloadsComponent implements OnInit {
    constructor(
        private authenticationService: AuthenticationService,
        private breadcrumbService: BreadcrumbService,
        private downloadsService: DownloadsService,
        private headerService: HeaderService,
        private router: Router
    ) { }

    ngOnInit() {
        this.breadcrumbService.update([{ title: 'main.tabs.downloads.title' }]);
    }
}
