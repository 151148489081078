import { ElementRef, Injectable } from '@angular/core';

import { MetricsGridConfig, IGridColumnGroup } from '../../core';
import { ProfileService } from './../../core/user';

import * as _ from 'lodash';

type StoredGridEntity = {
    title: string;
    visible: boolean;
}

type StoredGridGroup = { columns: StoredGridEntity[] } & StoredGridEntity;


/**
 * Persists grid configurations across component reloads
 */
@Injectable()
export class GridConfigService {

    private configsCache: any = {};
    // tslint:disable-next-line:variable-name
    private _dummyDiv: ElementRef;

    public set DummyDiv(div: ElementRef) {
        this._dummyDiv = div;
    }
    public get DummyDiv(): ElementRef {
        return this._dummyDiv;
    }
    constructor(private profileService: ProfileService) {
    }
    /**
     * applies previously stored (if any) column group visibility settings
     * to given grid configuration and returns same instance
     */
    public applyVisibilityOptions(grid: string, config: MetricsGridConfig): MetricsGridConfig {
        let options = this.configsCache[grid];
        if (!options) {
            options = this.profileService.getOptions(this.getKey(grid));
            this.configsCache[grid] = options;
            return this.applyOptions(options, config);
        }

        return this.applyOptions(options, config);
    }

    /**
     * Applies a grid layout to the grid configuration pulling from storage.
     * @param grid
     * @param config
     */
    public applyStoredLayoutToConfig(gridName: string, config: MetricsGridConfig): MetricsGridConfig {
        let storedConfig = this.configsCache[gridName];
        if (!storedConfig) {
           storedConfig = this.profileService.getOptions(this.getKey(gridName));
        }

        // for some reason, profileService.getOptions returns an empty object if the key can't be found.
        if (_.isEmpty(storedConfig)) {
            return config;
        }

        this.configsCache[gridName] = storedConfig;

        const clonedConfig = _.cloneDeep(config);

        storedConfig.forEach((storedGroup: StoredGridGroup, index) => {
            // can't move groups at this point so just go in order.
            const group = clonedConfig.groups[index];
            group.visible = storedGroup.visible;
            const columnMap = {};
            group.columns.forEach((col, index) => {
                columnMap[col.title] = col;
            });

            // Order the columns and apply visibility from storage.
            const columns = storedGroup.columns.map((item) => {
                const col = columnMap[item.title]
                col.visible = item.visible;
                return col;
            });
            group.columns = columns;

        })

        return clonedConfig;
    }

    /**
     * saves current column group visibility settings
     */
    public saveVisibilityOptions(gridSessionKey: string, config: MetricsGridConfig, storage: 'session' | 'local' = 'session') {
        this.configsCache[gridSessionKey] = _.map(config.groups, (g: IGridColumnGroup) => {
            const columns = g.columns.map(c => c.visible);
            return {
                visible: g.visible,
                columns
            };
        });
        this.profileService.saveOptions(this.getKey(gridSessionKey), this.configsCache[gridSessionKey], storage);
    }

    /**
     * Saves the location and visibility information for the grid.
     */
    public saveGridLayout(gridSessionKey: string, config: MetricsGridConfig, storage: 'session' | 'local' = 'session'): void {
        this.configsCache[gridSessionKey] = _.map(config.groups, (g: IGridColumnGroup): StoredGridGroup => {
            const columns: StoredGridEntity[] = g.columns.map(c => {
                return {
                    title: c.title,
                    visible: c.visible
                }
            });
            return {
                title: g.title || g.columnSelectorTitle,
                visible: g.visible,
                columns
            };
        });
        this.profileService.saveOptions(this.getKey(gridSessionKey), this.configsCache[gridSessionKey], storage);
    }

    private applyOptions(options: any, gridConfig: MetricsGridConfig): MetricsGridConfig {
        _.zipWith(gridConfig.groups, options, (g: IGridColumnGroup, v: any) => {
            if (v) {
                // mutate original
                g.visible = v.visible;

                // apply visibibility to columns too
                if (v.columns) {
                    v.columns.forEach((visible, index) => {
                        if (visible !== null && visible !== undefined) {
                            g.columns[index].visible = visible;
                        }
                    })
                }

            }
            return g;
        });
        return gridConfig;
    }

    private getKey(gridName: string): string {
        return `GRID_CFG_${gridName}`;
    }

    public getConfig(gridName: string): MetricsGridConfig {
        return this.configsCache[gridName];
    }
}
