export class FilterBox {
    public innerTitle: string;

    constructor(
        private _title: string | string[],
        public closeFunc: () => void,
        public dependents: FilterBox[] = [],
        public description = null
    ) {
        if (Array.isArray(_title)) {
            this.innerTitle = _title[1];
            this._title = _title[0];
        }
    }

    private get titleBase(): string {
        return (this.description ? this.description + ': ' : '') + this._title;
    }

    get title(): string {
        const MAX_LENGTH = 250;
        return this.titleBase.length > MAX_LENGTH ? this.titleBase.substring(0, MAX_LENGTH) : this.titleBase;
    }

    close = () => {
        this.closeFunc();
        if (this.dependents && !!this.dependents.length) {
            this.dependents
                .filter(dep => !!dep)
                .map(dep => dep.close());
        }
    }
}
