import {Injectable} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

enum RATE_TYPE {
    MONTHLY = 'monthly', MONTHLY_NEW = 'monthly_new', WEEKLY = 'weekly', DAILY = 'daily',
    HOURLY = 'hourly'
}

@Injectable()
export class RevenueMappingService {
    private rateTypes;
    constructor(
        private translateService: TranslateService
    ) {
        this.translateService.stream('main.tabs.summary.tables.rate_types').subscribe((i18n) => {
            if (this.rateTypes) {
                this.rateTypes.find(rt => rt.id === RATE_TYPE.MONTHLY).type = i18n[RATE_TYPE.MONTHLY];
                this.rateTypes.find(rt => rt.id === RATE_TYPE.MONTHLY_NEW).type = i18n[RATE_TYPE.MONTHLY_NEW];
                this.rateTypes.find(rt => rt.id === RATE_TYPE.WEEKLY).type = i18n[RATE_TYPE.WEEKLY];
                this.rateTypes.find(rt => rt.id === RATE_TYPE.DAILY).type = i18n[RATE_TYPE.DAILY];
                this.rateTypes.find(rt => rt.id === RATE_TYPE.HOURLY).type = i18n[RATE_TYPE.HOURLY];
            }
        });
    }

    public processRateTypesResponse(response: any): any {
        this.rateTypes = [
            {
                id: RATE_TYPE.MONTHLY,
                type: this.translateService.instant('main.tabs.summary.tables.rate_types.monthly'),
                BenchmarkedRevenue: response.BenchmarkedRevenueMonthly,
                ClientBenchmarkedRevenue: response.ClientBenchmarkedRevenueMonthly,
                RevenueBook: response.ClientBookRevenueMonthly,
                RevenueTotal: response.ClientRevenueMonthly
            },
            {
                id: RATE_TYPE.MONTHLY_NEW,
                type: this.translateService.instant('main.tabs.summary.tables.rate_types.monthly_new'),
                BenchmarkedRevenue: response.BenchmarkedRevenueNewMonthly,
                ClientBenchmarkedRevenue: response.ClientBenchmarkedRevenueNewMonthly,
                RevenueBook: response.ClientBookRevenueNewMonthly,
                RevenueTotal: response.ClientRevenueNewMonthly
            },
            {
                id: RATE_TYPE.WEEKLY,
                type: this.translateService.instant('main.tabs.summary.tables.rate_types.weekly'),
                BenchmarkedRevenue: response.BenchmarkedRevenueWeekly,
                ClientBenchmarkedRevenue: response.ClientBenchmarkedRevenueWeekly,
                RevenueBook: response.ClientBookRevenueWeekly,
                RevenueTotal: response.ClientRevenueWeekly
            },
            {
                id: RATE_TYPE.DAILY,
                type: this.translateService.instant('main.tabs.summary.tables.rate_types.daily'),
                BenchmarkedRevenue: response.BenchmarkedRevenueDaily,
                ClientBenchmarkedRevenue: response.ClientBenchmarkedRevenueDaily,
                RevenueBook: response.ClientBookRevenueDaily,
                RevenueTotal: response.ClientRevenueDaily
            },
            {
                id: RATE_TYPE.HOURLY,
                type: this.translateService.instant('main.tabs.summary.tables.rate_types.hourly'),
                BenchmarkedRevenue: response.BenchmarkedRevenueHourly,
                ClientBenchmarkedRevenue: response.ClientBenchmarkedRevenueHourly,
                RevenueBook: response.ClientBookRevenueHourly,
                RevenueTotal: response.ClientRevenueHourly
            }
        ];

        return { Items: this.rateTypes };
    }
}
