import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';

import { PagedQuery } from '../../../models';
import { FilterInfoService } from '../../../filter';

import { ExcelExportService } from '../../../core/http/excel-export.service';
import { SortOptions } from '../../../core/query/query.service';
import { ChartExportSpreadsheetBody } from '../../http';

@Injectable()
export class ChartExportService {

    constructor(
        private filterInfoService: FilterInfoService,
		private excelExportService: ExcelExportService
    ) {
    }

    getChartDownload = (sorting: SortOptions = new SortOptions(), chartExportConfig: any): Observable<Record<any, any>[]> => {
		const pagedSorted = new PagedQuery();
		pagedSorted.SetSorting(sorting);
        const body = new ChartExportSpreadsheetBody(chartExportConfig.columns, 
            this.filterInfoService.getFilterExport(), 
            chartExportConfig.data, 
            chartExportConfig.image);
       return this.excelExportService.generateChartSpreadsheet('chart-export/format', body, pagedSorted);
    }
}
