<div class="dropdown column-selector-container" style="overflow:visible">
    <a id="dLabel" class="btn btn-primary btn-inverse column-selector-link" (click)="showDropDown = !showDropDown">
		<i class="fa fa-fw fa-columns column-selector-icon" [ngClass]="{'fa-lg': size === 'md'}">
		</i>&nbsp;{{handleTranslation('main.core.tables.common.select_columns')}}
	</a>

    <ul class="dropdown-menu pull-right column-selector-list" [style.display]="showDropDown ? 'inline' : 'none'" id="dLabelDropdown">
        <ng-template ngFor let-li [ngForOf]="listItems">
			<li *ngIf="li.isHeader && !li.hidden" class="dropdown-header column-selector-li"
                id="{{(li.title || li.columnSelectorTitle) | rdoPathToId:'list-selector'}}">
				{{handleTranslation(li.title)}}
			</li>
            <li *ngIf="!li.isHeader && (!li.locked || li.forceCanClose) && !li.hidden" class="column-selector-li">
				<a (click)="toggleVisibility($event,li)" class="column-selector-link"
                    id="{{(li.title || li.columnSelectorTitle) | rdoPathToId:'list-selector'}}">
					<i class="fa fa-fw" [ngClass]="{'fa-check':li.visible}"></i>
					{{ handleTranslation(li.columnSelectorTitle ||  li.title)}}
                </a>
            </li>
        </ng-template>
    </ul>

	<button id="download-excel-button" *ngIf="authenticationService._userInfoView.HasClientAccessToExportData"
	(click)="clickExcelDownload($event,g); showDropDown = false;" class="btn btn-success btn-inverse">
		<i class="fa fa-fw fa-file-excel-o" [ngClass]="{'fa-lg': size === 'md'}"></i>&nbsp;
		{{'main.core.tables.common.excel' | translate}}</button>
	<button [disabled]="gridConfig.disableDeleteButton" *ngIf="gridConfig.enableDelete" 
	(click)="clickDelete($event)" class="btn btn-danger btn-inverse">
		<i class="fa fa-fw fa-trash-o"></i>&nbsp;
		{{'main.core.tables.common.delete' | translate}}</button>
</div>
