import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, UrlTree } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { map } from 'rxjs/operators';
import { LazyLoadEvent } from 'primeng/api';
import * as _ from 'lodash';
import {
  ActiveFilterService,
  MetricsGridConfig,
  ColumnDefinitionService,
  BreadcrumbService,
  GridTableConfiguredExpandableComponent,
  FormatService,
  GridSortingService,
} from './../../../core';
import { HeaderService } from './../../../header';
import { ProductTypeBaseComponent } from './../product-type-base.component';
import { SelectedProductTypeService } from './../selected-product-type.service';
import { ProductTypeSalesRepService } from './product-type-sales-rep.service';
import { DownloadsService } from './../../../downloads';
import { PageOptionsNg, SortOptionsNg, SortOptions } from './../../../models';
import { AuthenticationService } from './../../../core/authentication/authentication.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'rdo-product-type-sales-reps',
  templateUrl: 'product-type-sales-reps.component.html',
})
export class ProductTypeSalesRepsComponent
  extends ProductTypeBaseComponent
  implements OnInit, OnDestroy
{
  @ViewChild('salesRepsGrid')
  salesRepsGrid: GridTableConfiguredExpandableComponent;
  loading = true;
  gridName = 'PRODUCT_TYPE_SALESREPS_GRID';
  data: any;
  gridConfig: MetricsGridConfig;
  frozenRows: any;
  paging = new PageOptionsNg();
  sorting: SortOptionsNg;
  totalCount: any;
  frozenWidth: string;
  dataKey = 'SalesRepId';
  subscriptions: Array<Subscription> = [];

  constructor(
    private authenticationService: AuthenticationService,
    filterService: ActiveFilterService,
    private columnService: ColumnDefinitionService,
    private salesRepsService: ProductTypeSalesRepService,
    private downloadsService: DownloadsService,
    private router: Router,
    private route: ActivatedRoute,
    selectedProductTypeService: SelectedProductTypeService,
    breadcrumbService: BreadcrumbService,
    private headerService: HeaderService,
    private location: Location,
    private formatService: FormatService,
    private gridSortingService: GridSortingService
  ) {
    super(selectedProductTypeService, breadcrumbService, filterService);
  }

  ngOnInit() {
    this.gridSortingService.setGridName(this.gridName);
    this.sorting = this.gridSortingService.getSortOptionsNg();

    this.subscriptions.push(
      this.route.parent.params.subscribe((params) => {
        const routeParams = this.getRouteParams(params);
        if (this.hasRouteChanged(this.params, routeParams)) {
          this.params = routeParams;
          this.doUpdateBreadcrumbs();
          this.configureGrid();
          this.load();
        }
      })
    );

    this.subscriptions.push(
      this.filterService.filterChange.subscribe(() => {
        this.paging.page = 1;
        if (this.salesRepsGrid) {
          this.salesRepsGrid.resetPaginator();
        }
        this.load();
      })
    );

    // TODO: This is not being called when the user returns from the transactions page.
    this.subscriptions.push(
      this.selectedProductTypeService.productTypeChange.subscribe((p) => {
        this.doUpdateBreadcrumbs();
        if (this.params.productType !== p.ProductType) {
          this.selectedProductTypeService.loading = true;
          this.params.productType = p.ProductType;
          this.paging.page = 1;
          if (this.salesRepsGrid) {
            this.salesRepsGrid.resetPaginator();
          }
          this.load();
        }
      })
    );
    if (!this.selectedProductTypeService.selectedProductType) {
      this.subscriptions.push(
        this.selectedProductTypeService.productType.subscribe((p) => {
          this.doUpdateBreadcrumbs();
        })
      );
    }
    this.subscriptions.push(
      this.authenticationService.userInfoView.subscribe((userInfo) => {
        if (this.gridConfig) {
          this.gridConfig.enableExcelExport =
            userInfo.HasClientAccessToExportData;
        }
      })
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private doUpdateBreadcrumbs() {
    this.updateBreadcrumbs([
      {
        title: 'main.core.common.counts.sales_reps.plural',
        class: 'active',
      },
    ]);
  }

  load = () => {
    this.loading = true;
    const sorting = this.getSorting();
    this.salesRepsService
      .getProductTypeSalesReps(
        {
          productType: this.params.productType,
          category: this.params.category,
          useRentedAsProductType: this.params.useRentedAsProductType,
        },
        this.paging,
        sorting
      )
      .subscribe((result) => {
        this.loading = false;
        this.selectedProductTypeService.loading = false;
        if (result.Items && result.Items.length > 0) {
          this.data = result.Items;
          this.totalCount = result.TotalCount;
        } else {
          this.data = [];
          this.totalCount = 0;
        }
      });
  };

  changePage = (event: any) => {
    if (event.rows !== this.paging.pageSize) {
      this.paging.pageSize = event.rows;
    }
    this.paging.page = event.first < 1 ? 1 : event.first / event.rows + 1;
    this.load();
  };

  changeLazyLoad = (event: LazyLoadEvent) => {
    if (
      this.sorting.sortField === event.sortField &&
      this.sorting.sortOrder === event.sortOrder
    ) {
      return;
    }
    this.gridSortingService.setSortOption(
      event.sortField,
      event.sortOrder === -1
    );

    this.sorting.sortField =
      event.sortField || this.gridSortingService.getSortFieldOrDefault();
    this.sorting.sortOrder =
      event.sortOrder || this.gridSortingService.getSortDirectionOrDefault();
    this.load();
  };

  exportExcel = (args: any) => {
    this.loading = true;
    const sorting = this.getSorting();
    const translatedConfig = this.gridConfig.cloneAndTranslate((text) =>
      this.formatService.translateAndFormat(text, false)
    );
    this.salesRepsService
      .getProductTypeSalesRepsDownload(
        this.selectedProductTypeService.selectedProductType.Description,
        this.params.productType,
        translatedConfig,
        this.params.useRentedAsProductType,
        this.params.category,
        sorting
      )
      .subscribe((blob) => {
        this.loading = false;
        this.downloadsService.saveExcelBlob(blob);
      });
  };

  private handleTransactionCountClick = (record: any, parent: any): boolean => {
    this.updateBreadcrumbs([
      {
        title: parent.SalesRepName,
        class: 'active',
      },
      {
        title: record.CustomerName,
        class: 'active',
      },
    ]);
    this.router.navigateByUrl(this.getTransactionUrlTree(record, parent));
    return false;
  };

  private getTransactionUrlTree = (record: any, parent: any): UrlTree => {
    return this.router.createUrlTree(
      [
        'transactions',
        {
          customer: record.CustomerId,
          salesRep: parent.SalesRepId,
        },
      ],
      {
        relativeTo: this.route.parent,
      }
    );
  };

  private generateTransactionsLink = (record: any, parent: any) => {
    const urlTree = this.getTransactionUrlTree(record, parent);
    return this.location.prepareExternalUrl(urlTree.toString());
  };

  private getSorting = (): SortOptions => {
    this.sorting.sortField = this.gridSortingService.getSortFieldOrDefault();
    this.sorting.sortOrder =
      this.gridSortingService.getSortDirectionOrDefault();

    return new SortOptions(
      this.sorting.sortField,
      !(this.sorting.sortOrder === 1)
    );
  };

  private configureGrid = () => {
    this.frozenWidth = '460px';
    this.gridConfig = new MetricsGridConfig(
      [
        {
          title: '',
          visible: true,
          locked: true,
          columnSelectorTitle: 'main.core.common.counts.sales_reps.singular',
          columns: [
            {
              title: 'main.core.common.counts.sales_reps.singular',
              field: 'SalesRepName',
              sortColumn: 'SalesRepName',
              width: 200,
              maxWidth: 255,
              autoWidth: false,
              headerStyle: {
                'text-align': 'left',
              },
              cellStyle: {
                'text-align': 'left',
              },
              childConfig: {
                field: '',
              },
            },
            this.columnService.CustomerCount(this.columnService.Customer()),
            this.columnService.TransactionCount(
              this.columnService.TransactionCount(
                null,
                (v, r, p) => this.generateTransactionsLink(r, p),
                (v, r, p) => this.handleTransactionCountClick(r, p)
              )
            ),
          ],
        },
        ...this.columnService.StandardMetricSetColumnGroups(),
      ],
      (p) => this.loadChildren(p),
      true
    );
  };

  private loadChildren = (parent: any): Observable<Array<any>> => {
    const sorting = this.getSorting();
    return this.salesRepsService
      .getProductTypeSalesRepCustomers(
        this.params.productType,
        parent.SalesRepId,
        {
          category: this.params.category,
          useRentedAsProductType: this.params.useRentedAsProductType,
        },
        sorting
      )
      .pipe(
        map((items) => {
          return items.map(this.convert);
        })
      );
  };

  private convert = (item: any): any => {
    const clone = (<any>Object).assign({}, item);
    clone.SalesRepName = null;
    clone.CustomerCount = item.CustomerName;
    return clone;
  };
}
