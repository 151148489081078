export class FilterSlider {
    public id: string;
    public low: number;
    public high: number;
    public domElement: any;

    constructor(id: string, low: number, high: number) {
        this.id = id;
        this.low = low;
        this.high = high;
    }
}
