import { Injectable, Injector } from '@angular/core';
import { ChecklistService } from './check-list.service';
import { UserFlatNode } from './user-flat-node';
import { UserNode } from './user-node';
import { FlatTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import _ from 'lodash';
import { ClientUserRole } from '../../models';

@Injectable()
export class TreeService {
  private flatNodeMap = new Map<UserFlatNode, UserNode>();
  private nestedNodeMap = new Map<UserNode, UserFlatNode>();
  //public checklistService: ChecklistService;
  public treeControl: FlatTreeControl<UserFlatNode>;
  public usersSelection = new SelectionModel<UserFlatNode>(true);
  private treeFlattener: MatTreeFlattener<UserNode, UserFlatNode>;
  public clientAvailableUsers: any;
  //public preventDeselectAuthorName = null;

  constructor(private injector: Injector, public checklistService: ChecklistService) {
    //this.initialize();
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<UserFlatNode>(
      this.getLevel,
      this.isExpandable
    );


  }
  getLevel = (node: UserFlatNode) => node.level;

  isExpandable = (node: UserFlatNode) => node.expandable;

  getChildren = (node: UserNode): UserNode[] => node.users;

  hasChild = (_: number, _nodeData: UserFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: UserFlatNode) => _nodeData.item === '';

  displayNonRdoTooltip = (node: UserFlatNode, clientAvailableUsers: Array<ClientUserRole>) => {
    if (node && node.item && clientAvailableUsers && clientAvailableUsers.length > 0) {
      const user = clientAvailableUsers.find(r => r.Role === node.item);
      if (user && user.RoleId === -1) {
        return true;
      }
    }
    return false;
  }

  getNewDatasource = () => {
    const dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );
    this.checklistService.dataChange.subscribe((data) => {
      dataSource.data = data;
    });
    return dataSource;
  };

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: UserNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item
        ? existingNode
        : new UserFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.users?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: UserFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.usersSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: UserFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) =>
      this.usersSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: UserFlatNode, selectedUsers: any[]): void {
    this.usersSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);//_.filter(this.treeControl.getDescendants(node), (flatNode) => !(this.preventDeselectAuthorName && flatNode.item.includes(this.preventDeselectAuthorName)));
    // eslint-disable-next-line no-unused-expressions
    this.usersSelection.isSelected(node) ? this.usersSelection.select(...descendants) : this.usersSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach((child) => {
      this.usersSelection.isSelected(child);
      this.toggleExternalSelection(child, selectedUsers);
    });
    this.checkAllParentsSelection(node);

  }

  unselect(node: UserFlatNode) {
    this.usersSelection.deselect(node);
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: UserFlatNode, selectedUsers: any[]): void {
    this.usersSelection.toggle(node);
    this.toggleExternalSelection(node, selectedUsers);
    this.checkAllParentsSelection(node);
  }

  toggleExternalSelection(node: UserFlatNode, selectedUsers: any[]) {
    if (this.usersSelection.isSelected(node)) {
      if (!_.find(selectedUsers, (selectedUser) => `${selectedUser.FirstName} ${selectedUser.LastName} (${selectedUser.UserName})` === node.item)) {
        const userToSelect = _.find(this.clientAvailableUsers, (availableUser) => `${availableUser.FirstName} ${availableUser.LastName} (${availableUser.UserName})` === node.item);
        if (userToSelect) {
          selectedUsers.push(userToSelect);
          selectedUsers = selectedUsers.sort((a, b) => {
            if (a.FirstName.toLowerCase() === b.FirstName.toLowerCase()) {
              return a.LastName.toLowerCase() < b.LastName.toLowerCase() ? -1 : a.LastName.toLowerCase() > b.LastName.toLowerCase() ? 1 : 0;
            } else {
              return a.FirstName.toLowerCase() < b.FirstName.toLowerCase() ? -1 : a.FirstName.toLowerCase() > b.FirstName.toLowerCase() ? 1 : 0;
            }
          });
        }
      }
    }
    else {
      _.remove(selectedUsers, (selectedUser) => `${selectedUser.FirstName} ${selectedUser.LastName} (${selectedUser.UserName})` === node.item);
    }
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: UserFlatNode): void {
    let parent: UserFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: UserFlatNode): void {
    const nodeSelected = this.usersSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.usersSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.usersSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.usersSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: UserFlatNode): UserFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Select the category so we can insert the new item. */
  addNewItem(node: UserFlatNode) {
    const parentNode = this.flatNodeMap.get(node);
    this.checklistService.insertItem(parentNode!, ''); // eslint-disable-line  @typescript-eslint/no-non-null-assertion
    this.treeControl.expand(node);
  }

  /** Save the node to database */
  saveNode(node: UserFlatNode, itemValue: string) {
    const nestedNode = this.flatNodeMap.get(node);
    this.checklistService.updateItem(nestedNode!, itemValue); // eslint-disable-line  @typescript-eslint/no-non-null-assertion
  }
  public isUserSelectionDisabled = (node: UserFlatNode, currentBranches: number[]): boolean => {
    const user = this.getUser(node, this.clientAvailableUsers);
    if (!user || (!_.isEmpty(user.BranchACL) && !_.isEmpty(currentBranches) && !currentBranches.every(branch => user.BranchACL.includes(branch)))){
      return true;
    }
    return false;
  }

  public areDescendantsDisabled = (node: UserFlatNode, currentBranches: number[]): boolean => {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.length > 0 && descendants.every((child) => this.isUserSelectionDisabled(child,currentBranches));
  }

  public getUser = (node: UserFlatNode, clientAvailableUsers: Array<ClientUserRole>) => {
    return _.find(clientAvailableUsers, (availableUser) => `${availableUser.FirstName} ${availableUser.LastName} (${availableUser.UserName})` === node.item);
  }

  public setChecklistData = (rootNode: any) => this.checklistService.data = rootNode;
}
