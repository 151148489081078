﻿<rdo-overlay [enabled]="loadingProductTypesRentalGrowth"></rdo-overlay>

<rental-growth-grid *ngIf="selectedProductTypeService.isProductSelected && data!==null"
                        [data]="data"></rental-growth-grid>

 <div class="no-data-available" *ngIf="((!currentYearRaw || !previousYearRaw) && !loadingProductTypesRentalGrowth)">
    {{'main.tabs.equipment.product_types.no_data_available' | translate}}
</div>

<rdo-rental-growth-chart *ngIf="!loadingChart && selectedProductTypeService.isProductSelected && data!==null && chartData!==null" [chart-data]="chartData" ></rdo-rental-growth-chart>
<div id="chart-loader" *ngIf="chartData===null && data!==null && loadingChart" class="chart-loader">
    <img src="/assets/images/loader-sm.gif" />
</div>

