import { TranslateService } from '@ngx-translate/core';
import { HistogramLabel } from './histogram-label';
declare let $: any;
/**
 * This class is in charge of managing labels that contain complex html
 * within histogram charts.
 */
export class HistogramHtmlLabel extends HistogramLabel {
    public type: string;
    public icon: string;
    public value: number;
    public color: string;
    public cssClass: string;
    public highChartsLabel: any;

    constructor(
        icon: string,
        color: string,
        value: number,
        cssClass: string,
        translationPath: string,
        translateService: TranslateService
    ) {
        super(translationPath, translateService);
        this.icon = icon;
        this.color = color;
        this.value = value;
        this.cssClass = cssClass;
    }

    public updateLanguage(): void {
        if (!this.highChartsLabel) {
            throw Error('Invalid high charts label reference.');
        }
        this.highChartsLabel.attr({ text: this.getHTML()});
        (<any> $('.' + this.cssClass)).popover();
    }

    public getHTML(): string {
        const html =
            `<div style="margin-top: -11px;" class="${this.cssClass}" data-container="body" data-toggle="popover"
			data-placement="left" data-trigger="hover">
				<i style="color: ${this.color}; cursor: pointer;" class="fa fa-fw ${this.icon}"></i>
			</div>`;
        return html;
    }

    public getPopOverText(): string {
        return `${this.getText()}: ${this.value}`;
    }
}
