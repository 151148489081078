import { Component, Input, Output, EventEmitter } from '@angular/core';

import {
  ComparisonMode,
  ActiveFilterService,
} from './../../../../app/core/query';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rdo-nav-card',
  templateUrl: 'nav-card.component.html',
})
export class NavCardComponent {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('data')
  cardData: any;

  @Input()
  titleSelector: string;

  @Input()
  selected: boolean;

  @Output()
  selectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  defaultTitle: string;

  @Input()
  comparisonMode: ComparisonMode;

  private hidden = false;
  private allRatesMode = false;

  constructor(
    private filterService: ActiveFilterService,
    private translateService: TranslateService
  ) {
    this.allRatesMode =
      filterService.getCurrentFilter().includeAllRateTransactions;
  }

  public select() {
    if (!this.selected) {
      this.selected = true;
      this.selectedChange.next(this.selected);
    }
  }

  private isBenchmarkMode(): boolean {
    return this.comparisonMode === ComparisonMode.Benchmark;
  }

  public getTitle() {
    return this.translateService.instant(
      this.cardData[this.titleSelector] || this.defaultTitle
    );
  }
}
