import { Component, Input } from '@angular/core';
import { HighchartsConfiguration } from '../highcharts.directive';
import { AuthenticationService } from '../../../../core/authentication/authentication.service';

@Component({
  selector: 'rdo-chart-export-buttons',
  templateUrl: './chart-export-buttons.component.html',
  styleUrls: ['./chart-export-buttons.component.scss']
})
export class ChartExportButtonsComponent {
  @Input() config: HighchartsConfiguration;
  @Input() topOffset: string = '15px';

  constructor(private authenticationService: AuthenticationService) { }

  downloadEnabled(): boolean {
    if (this.authenticationService._userInfoView) {
      return this.authenticationService._userInfoView.HasClientAccessToExportData;
    }
    return false;
  }

  xlsx() {
    // The timeout function is used to let bootstrap close the dropdown before
    // the screenshot is taken.
    setTimeout(() => {
      this.config.xlsx();
    }, 1);
  }

  jpeg() {
    // The timeout function is used to let bootstrap close the dropdown before
    // the screenshot is taken.
    setTimeout(() => {
      this.config.jpeg();
    }, 1);
  }
}
