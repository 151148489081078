import { SelectableClient } from '../models/selectableclient';

/// <summary>
/// the data needed to display the client selector component
/// </summary>
export class ClientSelectionDialogData {

    public SelectableClients: Array<SelectableClient>;
    public SelectedClientId: number;                    // the client selected in the dialog (intended to change during dialog presentation)
    public CurrentClientId: number;                     // the client selected (loaded and displaying metrics etc) in the app - this remains static

    constructor(private selectableclients: Array<SelectableClient>,
                private selectedclientid: number) {

        this.SelectableClients = selectableclients;
        this.SelectedClientId = selectedclientid;
        this.CurrentClientId = selectedclientid;
    }
}
