"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var Observable_1 = require("../Observable");
var Subscription_1 = require("../Subscription");
function scheduleArray(input, scheduler) {
  return new Observable_1.Observable(function (subscriber) {
    var sub = new Subscription_1.Subscription();
    var i = 0;
    sub.add(scheduler.schedule(function () {
      if (i === input.length) {
        subscriber.complete();
        return;
      }
      subscriber.next(input[i++]);
      if (!subscriber.closed) {
        sub.add(this.schedule());
      }
    }));
    return sub;
  });
}
exports.scheduleArray = scheduleArray;
