import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormatService } from '../query';
import { IColumnConfig } from './index';

import * as _ from 'lodash';

@Component({
    selector: 'rdo-sort-header',
    templateUrl: './sort-header.html'
})
export class SortHeaderComponent  {

    constructor(
        private formatService: FormatService
    ) {}

    @Input()
    column: IColumnConfig;

    @Input()
    currentSortColumn: string;

    @Output()
    columnClick: EventEmitter<any> = new EventEmitter();

    @Input()
    desc: boolean;

    @Input()
    sortInProgress: boolean;

    @Input() 
    enableOrdering: boolean = true;

    @Output()
    descChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    changeSortDirection() {
        if(this.enableOrdering) {
            this.desc = !this.desc;
            this.descChange.next(this.desc);
        }
    }

    onColumnClick(column) {
        if(this.enableOrdering) {
            this.columnClick.next(column);
        }
    }

}
