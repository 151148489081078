<div id="filePermissionsDialog" mat-dialog-content [class.dialog-container]="standalone"
    [class.embedded-dialog-container]="!standalone" style="display:block;position:relative;">
    <div id="fileUploadLoader" class="chart-loader" *ngIf="isFileUploading" style="width: 100%">
        <img src="/assets/images/loader-sm.gif" />
    </div>
    <div *ngIf="isUploadMode">
        <div class="dialog-header">
            <div class="dialog-title">
                {{ 'main.core.file_manager.access_for' | translate }} {{
                this.authenticationService._userInfoView.SelectedClient.ClientShortName }}
                {{'main.core.file_manager.download_tab' | translate}}
            </div>
        </div>
        <div class="dialog-content" [class.embedded-dialog-content]="!standalone">
            <div style="padding: 10px;padding-bottom: 28px;">
                <input type="file" #UploadFileInput style="display:none;" (change)="onFileSelected($event)" />
                <br />
                <table style="width: 100%;">
                    <tr>
                        <td style="width: 100%;">
                            <div type="text" #FileNameInput class="form-control" style="width: 100%;"></div>
                        </td>
                        <td>
                            <button class="dialog-button" (click)="selectFile()">{{ 'main.core.file_manager.select_file'
                                | translate }}</button>
                        </td>
                        <td>
                            <button *ngIf="fileName !== ''" (click)="uploadFile()" class="dialog-button-default">{{
                                'main.core.file_manager.upload' | translate }}</button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="dialog-footer">
            <button class="dialog-button-default" id="FileManagerConfirmButton" (click)="close()"
                title="{{ 'main.core.file_manager.confirm_and_close' | translate }}">
                {{ 'main.core.file_manager.cancel' | translate }}
            </button>
        </div>
    </div>
    <div *ngIf="!isUploadMode" [class.smaller-dialog-content]="useSmallerLists">
        <div [class.dialog-header]="standalone" [class.embedded-dialog-header]="!standalone">
            <div class="dialog-title" *ngIf="standalone">
                {{ modalTitle? modalTitle : ('main.core.file_manager.access_for' | translate) + " " +
                fileQueryResult?.Name }}
            </div>
            <div [class.dialog-subtitle]="standalone" [class.embedded-dialog-subtitle]="!standalone">
                <p *ngIf="openFrom === 'downloads'">{{ ('main.core.file_manager.subtitle_downloads' | translate) }}</p>
                <p *ngIf="openFrom === 'uploads'">{{ ('main.core.file_manager.subtitle_upload' | translate) }}</p>
                <p *ngIf="openFrom !== 'downloads' && openFrom !== 'uploads'">{{
                    ('main.core.file_manager.subtitle_custom_grids' | translate) }}</p>
            </div>
        </div>
        <div class="restricted-or-public-select">
            <mat-icon [hidden]="!canEditPermissions()" class="lock-icon">
                {{restrictedOrPublicSelectModel[0] === "restricted" ? 'lock' : 'lock_open'}}
            </mat-icon>
            <rdo-multiselect-dropdown tabindex="0" title="Select visibility" id="restrictedOrPublicSelect"
                [disabled]="!canEditPermissions()" [settings]="visibilitySelectSettings"
                [options]="visibilitySettingsOptions" [(ngModel)]="restrictedOrPublicSelectModel"
                (ngModelChange)="restrictedOrPublicSelectChange($event)"></rdo-multiselect-dropdown>
        </div>
        <div class="dialog-content flex-container">
            <div *ngIf="allSelected">
                <p [class.embedded-dialog-subtitle]="true" class="dialog-subtitle">{{ noRestrictedUsersMessage }}</p>
            </div>

            <div class="d-content flex-container" [class.disabled-area]="!canEditPermissions()">
                <div style="padding: 10px;" class="flex-child">
                    <div class="selection-box-inner-title">{{ 'main.core.file_manager.grant_access_to_report' |
                        translate }}</div>
                    <mat-tree [dataSource]="usersByRoleDataSource" [treeControl]="treeService.treeControl"
                        [class.embedded-mat-tree]="!standalone" [class.smaller-mat-tree]="useSmallerLists">
                        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
                            <button mat-icon-button disabled></button>
                            <mat-checkbox
                                [class.disabled-area]="treeService.isUserSelectionDisabled(node, currentGridBranches)"
                                color="primary" class="checklist-leaf-node"
                                [checked]="treeService.usersSelection.isSelected(node)"
                                (change)="treeService.todoLeafItemSelectionToggle(node, selectedUsers); refreshSaveBtnStatus();">{{node.item}}</mat-checkbox>
                            <i *ngIf="treeService.usersSelection.isSelected(node) && treeService.isUserSelectionDisabled(node, currentGridBranches)"
                                class="fa fa-fw fa-info-circle"
                                tooltip="{{'main.core.file_manager.user_with_no_access_to_current_report' | translate}}"
                                [hideDelay]="0" placement="right"></i>
                        </mat-tree-node>

                        <mat-tree-node *matTreeNodeDef="let node; when: treeService.hasChild" matTreeNodePadding>
                            <button mat-icon-button matTreeNodeToggle
                                [attr.aria-label]="('main.core.file_manager.toggle' | translate) + ' ' + node.item">
                                <i class="fa" [class.fa-chevron-right]="!treeService.treeControl.isExpanded(node)"
                                    [class.fa-chevron-down]="treeService.treeControl.isExpanded(node)"></i>
                                <!-- <mat-icon class="mat-icon-rtl-mirror" *ngIf="initialLoadCompleted">
                                {{treeService.treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon> -->
                            </button>
                            <mat-checkbox s
                                [class.disabled-area]="treeService.areDescendantsDisabled(node, currentGridBranches)"
                                color="primary" [checked]="treeService.descendantsAllSelected(node)" class="parent-node"
                                [indeterminate]="treeService.descendantsPartiallySelected(node)"
                                (change)="treeService.todoItemSelectionToggle(node, selectedUsers); refreshSaveBtnStatus();"><span
                                    style="font-weight: 700;">{{node.item}}</span></mat-checkbox>
                            <i *ngIf="treeService.displayNonRdoTooltip(node, clientAvailableUsers)"
                                class="fa fa-fw fa-info-circle"
                                tooltip="{{'main.core.file_manager.non_rdo_user' | translate}}" [hideDelay]="0"
                                placement="right">

                            </i>
                        </mat-tree-node>
                    </mat-tree>
                </div>
                <div style="padding: 10px;" class="flex-child">
                    <div class="selected-box-inner-title" *ngIf="!isDownloadsTab">{{
                        'main.core.file_manager.notify_user_by_email' | translate}}</div>
                    <mat-list #selectedUsersList [class.embedded-mat-list]="!standalone"
                        [class.smaller-selected-list]="useSmallerLists">
                        <mat-list-item
                            *ngIf="(!this.selectedUsers || this.selectedUsers.length === 0) && initialLoadCompleted"
                            class="no-restricted-users-message">{{noRestrictedUsersMessage}}</mat-list-item>
                        <mat-list-item *ngIf="canEditPermissions() && this.selectedUsers?.length > 0"
                            class="list-item unselect-all">
                            <div matListItemLine>
                                <span class="remove-button-container"><i class="fa fa-trash remove-button"
                                        (click)="unSelectAll()"></i></span>
                                <span style="font-weight: 700;">{{ 'main.core.file_manager.unselect_all' | translate
                                    }}</span>
                            </div>

                        </mat-list-item>
                        <mat-list-item class="list-item-right" *ngFor="let selectedUser of selectedUsers">
                            <div matListItemLine>
                                <span *ngIf="canEditPermissions() && userNotGeoRestricted(selectedUser)"
                                    class="remove-button-container"><i class="fa fa-trash remove-button"
                                        (click)="unSelect(selectedUser)"></i></span>
                                <span *ngIf="userNotGeoRestricted(selectedUser)">{{selectedUser.FirstName}}
                                    {{selectedUser.LastName}} {{
                                    selectedUser.UserId > -1 ? "(" + selectedUser.UserName + ")" : "" }}</span>
                                <mat-checkbox *ngIf="!isDownloadsTab && userNotGeoRestricted(selectedUser)"
                                    color="primary" class="checklist-leaf-node notification-checkbox"
                                    [checked]="notificationUsersList.has(selectedUser.UserId)"
                                    tooltip="{{'main.core.file_manager.notification_checkbox_tooltip' | translate}}"
                                    [hideDelay]="0"
                                    (change)="updateNotificationUsersList($event, selectedUser.UserId);"></mat-checkbox>
                            </div>


                            <!-- <div *ngIf="canEditPermissions()" class="remove-button-container"><i [ngClass]="{'disabled-trashcan': disableTrashCan(selectedUser)}" class="fa fa-trash remove-button" (click)="unSelect(selectedUser)"></i></div> -->
                        </mat-list-item>
                    </mat-list>
                </div>
            </div>
        </div>
        <div class="notification-message-box" [class.smaller-notification-box]="useSmallerLists"
            *ngIf="!isDownloadsTab">
            <mat-form-field style="min-width: 100%; max-width: 100%;">
                <div><mat-label class="notification-label">{{ 'main.core.file_manager.optional_message' |
                        translate
                        }}</mat-label>
                </div>
                <div><textarea [(ngModel)]="notificationMsg" class="notification-input" matInput matTextareaAutosize
                        matAutosizeMinRows=1 matAutosizeMaxRows=5
                        placeholder="{{ 'main.core.file_manager.notification_message_placeholder' | translate }}"
                        tooltip="{{'main.core.file_manager.notification_message_placeholder' | translate}}"
                        [hideDelay]="0" maxlength="255"></textarea></div>
            </mat-form-field>
        </div>
        <div class="dialog-footer responsive-footer" *ngIf="standalone">
            <span>
                <button *ngIf="showDeleteFileBtn()" class="dialog-button delete-file-button responsive-btn"
                    id="FileManagerDeleteFileButton" (click)="deleteFile(fileQueryResult.FileName)"
                    title="{{ 'main.core.file_manager.delete_file' | translate }}">
                    {{ 'main.core.file_manager.delete_file' | translate }}
                </button>
            </span>
            <div>
                <button *ngIf="showCancelBtn()" class="dialog-button cancel-button responsive-btn"
                    id="FileManagerConfirmButton" (click)="close(false)"
                    title="{{ 'main.core.file_manager.cancel' | translate }}">
                    {{ 'main.core.file_manager.cancel' | translate }}
                </button>
                <button *ngIf="showSaveBtn()" [disabled]="isSaveDisabled()" [class.disabled]="isSaveDisabled()"
                    class="dialog-button-default save-button responsive-btn" id="FileManagerDeleteFileButton"
                    (click)="saveChanges()"
                    [title]="avoidApiInteractions? ('main.core.file_manager.export' | translate) : ('main.core.file_manager.save_changes' | translate)">
                    {{avoidApiInteractions? ('main.core.file_manager.export' | translate) :
                    ('main.core.file_manager.save_changes' | translate) }}
                </button>
            </div>
        </div>
    </div>
</div>
