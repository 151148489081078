"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var config_1 = require("./config");
var hostReportError_1 = require("./util/hostReportError");
exports.empty = {
  closed: true,
  next: function (value) {},
  error: function (err) {
    if (config_1.config.useDeprecatedSynchronousErrorHandling) {
      throw err;
    } else {
      hostReportError_1.hostReportError(err);
    }
  },
  complete: function () {}
};
