import { Component, Input, ElementRef } from '@angular/core';
import * as _ from 'lodash';

import { FormatService } from './../../query';
import { RevenueChartHelperService, HighchartsConfiguration } from './../base';
import { TranslateService } from '@ngx-translate/core';
import { CHART_COLORS } from '../base/chart-color-constants';
import { LocaleService } from '../../services';

/* eslint-disable @angular-eslint/no-input-rename, no-use-before-define */
declare let $: any;
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'revenue-vs-book-chart',
  templateUrl: './revenue-versus-book-chart.component.html',
})
export class RevenueBookChartComponent {
  chartConfig: HighchartsConfiguration = new HighchartsConfiguration();
  private I18N_PATH = 'main.tabs.summary.charts.client_revenue.vs_books';
  private chart: any;
  private label: any;

  @Input('chart-data') set chartData(data: any) {
    if (data) {
      this.loadChart(data);
    }
  }

  constructor(
    private element: ElementRef,
    private formatService: FormatService,
    private translateService: TranslateService,
    private revUtilChartService: RevenueChartHelperService,
    private localeService: LocaleService
  ) {
    this.setupLanguageUpdate();
  }

  /**
   * Observes language changes and updates legend and labels in the chart.
   */
  private setupLanguageUpdate() {
    this.translateService.stream(this.I18N_PATH).subscribe((i18n) => {
      if (this.chart) {
        this.chart
          .get(LEGEND_TYPE.BOOK_REVENUE)
          .update({ name: i18n[LEGEND_TYPE.BOOK_REVENUE] });
        this.chart
          .get(LEGEND_TYPE.REVENUE_DIFFERENCE)
          .update({ name: i18n[LEGEND_TYPE.REVENUE_DIFFERENCE] });
        this.chart
          .get(LEGEND_TYPE.TOTAL_REVENUE)
          .update({ name: i18n[LEGEND_TYPE.TOTAL_REVENUE] });
        this.label.attr({
          text: i18n.book,
        });
      }
    });
  }

  private compareRevenue(obj: any): IRevenueComparison {
    const diff = obj.RevenueTotal - obj.RevenueBook;
    const diffPct = (diff !== 0 ? diff / obj.RevenueBook : 0) * 100;
    const diffPctFmt =
      this.formatService.getDifferencePercent(
        obj.RevenueTotal,
        obj.RevenueBook
      ) * 100;
    return {
      diff: diff,
      diffPct: diffPct,
      diffPctFmt: diffPctFmt,
    };
  }

  private loadChart(data: Array<any>) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;
    const options = {
      chart: {
        height: this.element.nativeElement.parentElement.offsetHeight,
        width: this.element.nativeElement.parentElement.offsetWidth,
        selectionMarkerFill: CHART_COLORS.COLOR_5779A3,
        type: 'column',
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      legend: {
        enabled: true,
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        itemMarginTop: 5,
        itemMarginBottom: 5,
        labelFormatter: function () {
          return `<div id="legend-${this.userOptions.id}">${this.name}</div>`;
        },
        useHTML: true,
        itemHiddenStyle: {
          symbolOpacity: 0.3,
          color: CHART_COLORS.TRANSPARENCY,
          textDecoration: 'none',
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          grouping: false,
          events: {
            click: (e) => {
              this.revUtilChartService.selectMonthFromChart(e.point.category);
            },
          },
        },
        // line: {
        //     dataLabels: {
        //         enabled: true,
        //         formatter: function () {
        //             var last = this.series.data[this.series.data.length - 1];
        //             console.log('last', last);
        //             console.log('this.point.category', this.point.category);
        //             console.log('this.point.y', this.point.y);
        //             if (this.point.category === last.category && this.point.y === last.y) {
        //                 return 'BOOK';
        //             }
        //             return '';
        //         }
        //     }
        // }
      },
      tooltip: {
        backgroundColor: null,
        borderWidth: 0,
        shadow: false,
        style: {
          padding: 0,
        },
        positioner: function (boxWidth, boxHeight, point) {
          return component.revUtilChartService.getTooltipPositionClientRateBenchmark(
            point
          );
        },
        formatter: function () {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const chart = this;
          const data =
            component.revUtilChartService.getDataForClientBookTooltip(chart);

          const markup = `<div class="client-rate-bench-chart">
                        <table class="table rdo-table-tooltip">
                            <tr>
                                <td id="tooltip-client_book_revenue">
                                    <span style="color:rgba(36, 90, 140, 1)">&#x25CF</span> ${component.translateService.instant('main.tabs.summary.charts.client_revenue.vs_books.client_book_revenue')}
                                </td>
                                <td>
                                    ${component.formatService.selectedCurrencySymbol}${component.formatService.formatCurrency(this.x.RevenueBook, false)}
                                </td>
                            </tr>
                            <tr>
                                <td id="tooltip-client_total_revenue">
                                    <span style="color:rgba(153, 168, 189,0.1)">&#x25CF</span> ${component.translateService.instant('main.tabs.summary.charts.client_revenue.vs_books.client_total_revenue')}
                                </td>
                                 <td>
                                    ${component.formatService.selectedCurrencySymbol}${component.formatService.formatCurrency(this.x.RevenueTotal, false)}
                                </td>
                            </tr>
                            <tr>
                                <td id="tooltip-revenue_difference">
                                    <span class="${data.point.cssClass}">&#x25CF</span> ${component.translateService.instant('main.tabs.summary.charts.client_revenue.vs_books.revenue_difference')}
                                </td>
                                <td>
                                    <span class="${data.point.cssClass}">${component.formatService.formatCurrencyDifference(this.x.RevenueTotal, this.x.RevenueBook, true)}</span>
                                </td>
                            </tr>
                        </table></div>`;
          return markup;
        },
        shared: true,
        useHTML: true,
      },
      yAxis: [
        {
          // minPadding: 0.35,
          // maxPadding: 0.35,
          endOnTick: false,
          startOnTick: false,
          // lineWidth: 0,
          // minorTickLength: 0,
          // tickLength: 0,
          // gridLineColor: 'transparent',
          title: {
            enabled: false,
          },
          plotLines: [
            {
              //color: '#D8D8D8',
              color: CHART_COLORS.COLOR_5779A3,
              width: 1,
              value: 0,
            },
          ],
          minorGridLineWidth: 0,
          labels: {
            formatter: function () {
              return (
                component.formatService.selectedCurrencySymbol +
                this.axis.defaultLabelFormatter.call(this)
              );
            },
          },
        },
      ],
      xAxis: {
        labels: {
          useHTML: true,
          formatter: function () {
            const locale = component.localeService.getLocale();
            const label =
              component.revUtilChartService.addBenchmarkDiffForRevenueChart(
                this.value,
                locale
              );
            return `<div id="x-axis-${this.value.index}">${label}</div>`;
          },
        },
        categories: data.map((obj, index) => {
          obj.index = index;
          return obj;
        }),
        // plotLines: [{
        //     color: '#000000',
        //     width: 2,
        //     dashStyle: 'Dash',
        //     value: data.length - 3.5 // dashed line at the carryover months.
        // }]
      },
      series: [
        {
          id: LEGEND_TYPE.BOOK_REVENUE,
          type: 'spline',
          color: 'rgba(36, 90, 140, 1)',
          name: this.translateService.instant(
            this.I18N_PATH + '.' + LEGEND_TYPE.BOOK_REVENUE
          ),
          data: data.map(function (obj) {
            return {
              y: obj.RevenueBook,
            };
          }),
          pointPadding: 0.2,
          pointPlacement: 0,
          zIndex: 10,
          events: {
            legendItemClick: function () {
              $('#hcRevenueBookLabel').remove();
            },
          },
        },
        {
          id: LEGEND_TYPE.REVENUE_DIFFERENCE,
          name: this.translateService.instant(
            this.I18N_PATH + '.' + LEGEND_TYPE.REVENUE_DIFFERENCE
          ),
          //color: 'rgba(204, 0, 0, 0.2)',
          color: CHART_COLORS.COLOR_2F5597,
          data: data.map(function (obj) {
            return obj.RevenueTotal - obj.RevenueBook < 0
              ? Math.abs(obj.RevenueTotal - obj.RevenueBook)
              : 0;
          }),
          dataLabels: {
            enabled: true,
            crop: false,
            borderRadius: 1,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            overflow: 'none',
            padding: 1,
            style: {
              z: '1 !important',
              fontSize: '10px',
            },
            formatter: function () {
              const comparison = component.compareRevenue(this.x);
              if (!this.x.RevenueBook) {
                if (!this.x.RevenueTotal) {
                  return ``;
                }
                return `${component.formatService.formatCurrency(comparison.diff, false)} / -`;
              }
              if (comparison.diff < 0) {
                return `<div class="client-benchmark-data-label">
                                    <span class='text-danger' style="font-weight:normal">
                                    ${comparison.diffPct.toFixed(1)}%
                                    </span></div>`;
              } else {
                return `<div class="client-benchmark-data-label">
                                    <span style="font-weight:normal">
                                    ${comparison.diffPct.toFixed(1)}%
                                    </span></div>`;
              }
            },
            useHTML: true,
          },
          pointWidth: 2.5,
          pointPlacement: 0,
          showInLegend: false,
        },
        {
          id: LEGEND_TYPE.TOTAL_REVENUE,
          name: this.translateService.instant(
            this.I18N_PATH + '.' + LEGEND_TYPE.TOTAL_REVENUE
          ),
          color: CHART_COLORS.COLOR_5779A3,
          borderRadiusTopLeft: 2,
          borderRadiusTopRight: 2,
          borderRadiusBottomLeft: 2,
          borderRadiusBottomRight: 2,
          data: data.map(function (obj) {
            if (
              component.revUtilChartService.isCurrentCategory(obj) &&
              obj.RevenueTotal
            ) {
              return {
                y: obj.RevenueTotal,
                color: CHART_COLORS.COLOR_5779A3,
                borderColor: CHART_COLORS.COLOR_000000,
                borderWidth: 3,
                selected: true,
              };
            }
            return obj.RevenueTotal;
          }),
          states: {
            select: {
              color: CHART_COLORS.COLOR_5779A3,
            },
          },
          pointPlacement: 0,
          point: {
            events: {
              click: function () {
                this.selected = !this.selected;

                this.graphic.attr({
                  color: CHART_COLORS.COLOR_5779A3,
                  stroke: 'black',
                  'stroke-width': 3,
                });
              },
            },
          },
        },
      ],
    };

    const config = new HighchartsConfiguration();

    config.exportingOptions = {
      getColumnsConfig: () => {
        return [
          {
            field: 'Month',
            displayName: component.translateService.instant(
              'main.core.charts.export.month'
            ),
            customFormat: (rowValues) => {
              return component.revUtilChartService.getShortDate(
                rowValues.Month
              );
            },
          },
          {
            field: 'RevenueBook',
            displayName:
              component.translateService.instant(
                'main.core.charts.export.client_book_revenue'
              ) + ` ${component.formatService.selectedCurrencySymbol}`,
          },
          {
            field: 'RevenueTotal',
            displayName:
              component.translateService.instant(
                'main.core.charts.export.client_total_revenue'
              ) + ` ${component.formatService.selectedCurrencySymbol}`,
          },
          {
            field: 'RevenueDifference',
            displayName:
              component.translateService.instant(
                'main.core.charts.export.revenue_diff'
              ) + ` ${component.formatService.selectedCurrencySymbol}`,
            customFormat: (rowValues) => {
              return rowValues.RevenueTotal - rowValues.RevenueBook;
            },
          },
          {
            field: 'RevenueDifferencePercent',
            displayName: component.translateService.instant(
              'main.core.charts.export.revenue_diff_percent'
            ),
            customFormat: (rowValues) => {
              const diff = <any>(
                (rowValues.RevenueTotal - rowValues.RevenueBook).toFixed(1)
              );
              if (rowValues.RevenueBook !== 0) {
                return diff / rowValues.RevenueBook.toFixed(1);
              }
              return 0;
            },
            isPercent: true,
          },
        ];
      },
      getFileName: () =>
        this.translateService.instant(
          'main.tabs.summary.charts.client_revenue.vs_books.title'
        ),
    };
    config.options = options;
    config.onLoadCallback = (chart) => {
      this.chart = chart;
      this.addBookLabel();
    };
    this.chartConfig = config;
  }

  /**
   * Adds a label right over the last point that says "BOOK" or its equivalent depending on language.
   */
  private addBookLabel(text?: string) {
    const serie = this.chart.get(LEGEND_TYPE.BOOK_REVENUE);
    const lastDot = serie && serie.points ? serie.points : [];
    let lastDotX = 0;
    let lastDotY = 0;
    if (lastDot.length > 0) {
      lastDotX = lastDot[lastDot.length - 1].plotX;
      lastDotY = lastDot[lastDot.length - 1].plotY;
    }
    if (lastDot.length > 0) {
      const actualText = text
        ? text
        : this.translateService.instant(this.I18N_PATH + '.book');
      this.label = this.chart.renderer
        .text(actualText, lastDotX + 70, lastDotY)
        .css({
          color: 'rgba(36, 90, 140, 1)',
          fontSize: '11px',
          fontWeight: 'bold',
        })
        .attr({
          zIndex: 6,
          id: 'hcRevenueBookLabel',
        })
        .add();
    }
  }
}

interface IRevenueComparison {
  /**
   * Difference of two numbers
   */
  diff: number;

  /**
   * Percent difference of two numbers
   */
  diffPct: number;

  /**
   * Percent difference of two numbers
   */
  diffPctFmt: number;
}

enum LEGEND_TYPE {
  BOOK_REVENUE = 'book_revenue',
  REVENUE_DIFFERENCE = 'revenue_difference',
  TOTAL_REVENUE = 'total_revenue',
}
