import { IntroJsStep } from './intro-js-step';

export class IntroJsOptions {
    public doneLabel: string;
    public nextLabel: string;
    public prevLabel: string;
    public exitOnOverlayClick: boolean;
    public keyboardNavigation: boolean;
    public steps: IntroJsStep[];
    public tooltipClass: string;
    public disableInteraction: boolean;
    public showBullets: boolean;
    public showProgress: boolean;
    public waitForElement: string;
}
