import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs/observable/of';
import * as _ from 'lodash';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { FilterProfileService } from '../filter/profiles/filter-profile.service';
import { CODENAMES } from '../core/constants';
import { TxAttributes } from '../models';
import { ClientProfileService } from '../core';

@Injectable()
export class CustomGridsGuardService {
    constructor(
        private authenticationService: AuthenticationService,
        private filterProfileService: FilterProfileService,
        private clientProfileService: ClientProfileService,
        private router: Router) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const showCustomGrids = !!this.filterProfileService.readClientProfileProperty(CODENAMES.CN_SHOW_CUSTOM_GRIDS);
        const filterProfile = this.clientProfileService.txAttributes ? this.clientProfileService.txAttributes : new TxAttributes(<TxAttributes>{ IsTrial: true });
        const hasAccess = this.authenticationService.hasAccessToCustomGrids(showCustomGrids, filterProfile.IsTrial);
        if (!hasAccess) {

            this.router.navigate(['/summary']);
        }

        return of(hasAccess);
    }
}
