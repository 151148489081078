import * as _ from 'lodash';
import {MetricsGridConfig} from '../MetricsGridConfig';
import { IGridColumnGroup } from '../IGridColumnGroup.interface';
import {IColumnConfig} from '../IColumnConfig.interface';

export const ERROR_MISSING_SELECTOR ='columnSelectorTitle must be assigned to all column definitions.';

export const moveGridColumn = (column: IColumnConfig, gridConfig: MetricsGridConfig, newPosition: number, oldPosition = -1, shouldClone = false): MetricsGridConfig => {
    const cloneGridConfig = shouldClone ? _.cloneDeep(gridConfig) : gridConfig;

    const foundGroupIndex: number = oldPosition > -1 ? oldPosition : cloneGridConfig.groups[0].columns.findIndex((item) => {
        if (!item.title || !column.title) {
            throw new Error(ERROR_MISSING_SELECTOR);
        }
        return item.title === column.title;
    });
    const splicedGroup = cloneGridConfig.groups[0].columns.splice(foundGroupIndex, 1);
    cloneGridConfig.groups[0].columns.splice(newPosition, 0, splicedGroup[0]);
    return cloneGridConfig;
}
