import {Directive, ElementRef, Input} from '@angular/core';
import {SvgService} from './../../query';
/* eslint-disable @angular-eslint/directive-selector */
@Directive({
    selector: '[rdo-dynamic-pie]'
})
export class DynamicPieDirective {
    hostElement: ElementRef;

    @Input('rdo-dynamic-pie')
    set percentage(percentage: number) {

        if (isNaN(percentage) || percentage < 0 || percentage > 100) {
            return;
        }

        const nameNumber = Math.round(percentage / 5.0) * 5;

        this.svgService.getIconMarkup(`assets/images/pie/${nameNumber}.svg`).subscribe(markup => {
            const node = this.hostElement.nativeElement;
            while (node.firstChild) {
                node.removeChild(node.firstChild);
            }

            this.hostElement.nativeElement.appendChild(markup);
        });
    }

    constructor(ele: ElementRef, private svgService: SvgService) {
        this.hostElement = ele;
    }
}
