import { Body } from './body';

export class ChangeLog implements Body {
	UserId: number;
	ClientProductType: string;
    MonthId: number;
    ClientRegion: string;
    RegionList: Array<number>;
	DistrictList: Array<number>;
    BranchList: Array<number>;
	MonthlyBookRevenueRate?: number;
	NewMonthlyBookRevenueRate?: number;
	MonthlyFloorRevenueRate?: number;
	NewMonthlyFloorRevenueRate?: number;
	WeeklyBookRevenueRate?: number;
	NewWeeklyBookRevenueRate?: number;
	WeeklyFloorRevenueRate?: number;
	NewWeeklyFloorRevenueRate?: number;
	DailyBookRevenueRate?: number;
	NewDailyBookRevenueRate?: number;
	DailyFloorRevenueRate?: number;
	NewDailyFloorRevenueRate?: number;
	Comment: string;

	public constructor(init?: Partial<ChangeLog>) {
		Object.assign(this, init);
	}
}