﻿<div class="panel panel-default rdo-card rdo-card-default" [ngClass]="{'rdo-card-bench' : isBenchmarkMode(), 'rdo-card-book': !isBenchmarkMode()}">
    <div class="panel-heading">
        <div class="panel-title" id="{{title | rdoPathToId}}">{{title | translate}}</div>
    </div>
    <table class="table table-nowrap">
        <tbody>
            <tr>
                <th></th>
                <ng-template [ngIf]="isBenchmarkMode()">
                    <th id="{{title + '.client_compared' | rdoPathToId}}" class="rdo-table-title rdo-narrow-title"><div
                    >{{'main.tabs.summary.tables.client_compared_revenue' | translate}}</div></th>
                    <th id="{{title + '.client_rate_vs.1' | rdoPathToId}}" class="rdo-table-title rdo-wide-title"><div
                    >{{'main.tabs.summary.tables.client_rate_vs_benchmark' | translate}}</div></th>
                </ng-template>
                <ng-template [ngIf]="!isBenchmarkMode()">
                    <th id="{{title + '.client_total' | rdoPathToId}}" class="rdo-table-title rdo-narrow-title"><div
                    >{{'main.tabs.summary.tables.client_total_revenue' | translate}}</div></th>
                    <th id="{{title + '.client_rate_vs.2' | rdoPathToId}}" class="rdo-table-title rdo-wide-title"><div
                    >{{'main.tabs.summary.tables.client_rate_vs_book' | translate}}</div></th>
                </ng-template>
            </tr>
            <tr *ngFor="let i of items">
                <td>
                    <span title="{{i[nameSelector]}}">
                        <a *ngIf="linkUrl" (click)="handleClick(i)" [href]="linkUrl(router, location, i)">
                            {{i[nameSelector]}}
                        </a>
                        <span *ngIf="!linkUrl">
                             {{i[nameSelector]}}
                        </span>
                    </span>
                </td>
                <ng-template [ngIf]="isBenchmarkMode()">
                    <td [ngClass]="{'text-muted':i.ClientBenchmarkedRevenue === 0}">
                        {{i.ClientBenchmarkedRevenue | rdoCurrency:true}}
                    </td>
                    <td>
                        <rdo-amount-difference [clientValue]="i.ClientBenchmarkedRevenue" [benchmarkedValue]="i.BenchmarkedRevenue"></rdo-amount-difference>
                    </td>
                </ng-template>
                <ng-template [ngIf]="!isBenchmarkMode()">
                    <td [ngClass]="{'text-muted':i.RevenueTotal === 0}">
                        {{i.RevenueTotal | rdoCurrency:true}}
                    </td>
                    <td>
                        <rdo-amount-difference [clientValue]="i.RevenueTotal" [benchmarkedValue]="i.RevenueBook"></rdo-amount-difference>
                    </td>
                </ng-template>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td colspan="3">
                    <ng-template [ngIf]="footerLinkDsl">
                        <a [routerLink]="footerLinkDsl" id="{{title + '.all' | rdoPathToId}}">{{getFooterLinkTitle(footerLinkTitle)}}<i class="fa fa-fw fa-angle-double-right"></i></a>
                    </ng-template>
                </td>
            </tr>
        </tfoot>
    </table>
</div>