import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

import { PageOptions, SortOptions, CustomerQuery } from '../models';

import {	
	RdoHttpService,
	ExportSpreadsheetBody,
	QueryService,
	ViewService,
	MetricsGridConfig,
	RevenueMappingService,
    ExcelExportService
} from '../core';

import { FilterInfoService } from '../filter';
import * as filterFunctions from './../filter/functions/filter.functions';

@Injectable()
export class CustomerSalesRepService {
	private isGetRequest = false;

	constructor(
		private viewService: ViewService,
		private filterInfoService: FilterInfoService,
		private queryService: QueryService,
		private rdoHttp: RdoHttpService,
		private revenueMappingService: RevenueMappingService,
		private excelExportService: ExcelExportService
	) {
		this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
	}

	public getCustomerSalesReps = (customerId: number, paging: PageOptions = new PageOptions(), sorting: SortOptions = new SortOptions()): Observable<any> => {
        const pagedSorted = new CustomerQuery();
        pagedSorted.CustomerId = customerId;
		
		this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);

		return this.queryService
			.getPagedSorted('metrics/customers/sales-reps', paging, sorting, false, null, pagedSorted);
	}

	public getCustomerSalesRepsDownload = (
		customer: string,
		customerId: number,
		sorting: SortOptions = new SortOptions(),
		gridConfig: MetricsGridConfig
	): Observable<Record<any,any>[]> => {
		const filters = this.filterInfoService.getFilterExport();
		filters.Customer = customer;
        const pagedSorted = new CustomerQuery();
        pagedSorted.CustomerId = customerId;
        pagedSorted.SetSorting(sorting);
		const body = new ExportSpreadsheetBody(gridConfig.getAllColumns(), filters);
		return this.excelExportService.generateGridSpreadsheet('metrics/customers/sales-reps-download', body, pagedSorted);
	}

    public getCustomerSalesRepsProductTypes = (customerId: number, salesRepId: string, sorting: SortOptions = new SortOptions()): Observable<any> => {
        const pagedSorted = new CustomerQuery();
        pagedSorted.Page = 1;
        pagedSorted.PageSize = 500;
        pagedSorted.SalesRepId = salesRepId;
        pagedSorted.CustomerId = customerId;	

        pagedSorted.SetSorting(sorting);

		return this
            .rdoHttp
            .post('metrics/sales-reps/customers/product-types', pagedSorted)
			.pipe(map((r: any) => {
				return r.Items;
			}));
	}

	public getCustomerSalesRepsProductTypesDownload = (
		customerId: number,
		salesRepId: string,
		sorting: SortOptions = new SortOptions(),
		gridConfig: MetricsGridConfig
	): Observable<Record<any, any>[]> => {
        const pagedSorted = new CustomerQuery();
        pagedSorted.SalesRepId = salesRepId;
        pagedSorted.CustomerId = customerId;        

		pagedSorted.SetSorting(sorting);
		const body = new ExportSpreadsheetBody(gridConfig.getAllColumns(), this.filterInfoService.getFilterExport());

		return this.excelExportService.generateGridSpreadsheet('metrics/sales-reps/customers/product-types-download', body, pagedSorted);
	}
}
