const LOAD_GRID_TRANSACTION = 'Load Grid';
const TOTAL_TIME_SPAN = 'Load Grid Total Time';
const FIELD_LIST_CHECK = 'Check in Field List';
const SAVE_AS = 'Save As';
const DRAG_DROP = 'Drag and Drop';
const CONDITIONAL_FORMATTING = 'Conditional Formatting';
const APPLY_CONFIG = 'Apply Configuration'
const RENAME = 'Rename Report';

const SENTRY_KEYS = {
    LOAD_GRID_TRANSACTION,
    TOTAL_TIME_SPAN,
    FIELD_LIST_CHECK,
    SAVE_AS,
    DRAG_DROP,
    CONDITIONAL_FORMATTING,
    APPLY_CONFIG,
    RENAME
};

export { SENTRY_KEYS};