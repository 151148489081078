import { ManagedPermissions, StepActionType, SystemTriggers, TriggerType, UserGuide, UserGuideOptions, UserGuideStep } from '../models';

function getGuide() {
    const guide: UserGuide = {
        // Data to audit the object in the db
        id: null,
        name: 'welcome',
        translatedName: 'Welcome',
        created_date: new Date(2023, 11, 28),
        created_by: 'Marcelo Schild',
        modified_date: null,
        modified_by: null,
  
        // Data to know when and where to execute it
        application: 'RDO',
        trigger_type: TriggerType.SYSTEM,
        trigger_value: SystemTriggers.FIRST_LOGIN,
  
        // Actual JSON that affects IntroJS
        options: Object.assign(new UserGuideOptions(), {
          exitOnOverlayClick: false,
          keyboardNavigation: true,
          doneLabel: 'main.shared.finish',
          prevLabel: 'main.shared.back',
          nextLabel: 'main.shared.next',
          requiredElements: ['#main-tabs-summary-page_title-selector', '#filters-menu'],
          steps: [{
            intro: 'main.welcome.step_text_1',
            type: StepActionType.MESSAGE
          }, {
            element: '#main-tabs-summary-page_title-selector',
            intro: 'main.welcome.step_text_2',
            type: StepActionType.HIGHLIGHT
          }, {
            element: '#filters-menu',
            intro: 'main.welcome.step_text_3',
            type: StepActionType.HIGHLIGHT
          }, {
            element: '#geography-dropdown',
            intro: 'main.welcome.step_text_4',
            type: StepActionType.HIGHLIGHT
          }, {
            element: '#sales-reps-list-item',
            intro: 'main.welcome.step_text_5',
            type: StepActionType.HIGHLIGHT
          }, {
            element: '#equipment-dropdown',
            intro: 'main.welcome.step_text_6',
            type: StepActionType.HIGHLIGHT
          }, {
            element: '#downloads-list-item',
            intro: 'main.welcome.step_text_7',
            requiresPermission: ManagedPermissions.DOWNLOADS,
            type: StepActionType.HIGHLIGHT
          }, {
            actionCode: `let btn = document.querySelector('#guided-tours-show-btn');btn && btn.click();`,
            undoActionCode: `let btn = document.querySelector('#guided-tours-hide-btn');btn && btn.click();`,
            type: StepActionType.ACTION
          }, {
            element: '#guided-tours',
            intro: 'main.welcome.step_text_8',
            type: StepActionType.HIGHLIGHT
          }]
        })
      };
      guide.options.steps = guide.options.steps.map(x => Object.assign(new UserGuideStep(), x));
      return guide;
}

export const WELCOME_GUIDE = getGuide();
