import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ExportsService } from '../../core/services/exports.service';
import _ from 'lodash';
import { PageOptionsNg, SortOptionsNg } from '../../../app/models/query';
import { LazyLoadEvent } from 'primeng/api';
import { MetricsGridConfig } from '../../../app/core/grid/MetricsGridConfig';
import { FormatService } from '../../../app/core/query/format.service';
import { GridSortingService } from '../../../app/core/services/grid-sorting.service';

@Component({
  selector: 'rdo-custom-grids-queue',
  templateUrl: './custom-grids-queue.component.html',
  styleUrls: ['./custom-grids-queue.component.scss'],
  providers: [GridSortingService]
})
export class CustomGridsQueueComponent implements OnInit, OnDestroy {
  @Output()
  successCountChange: EventEmitter<number> = new EventEmitter<number>();

  files: any[];
  public subscriptions = [];
  public isloading: boolean = false;

  gridName: string = 'EXPORTSQUEUE_GRID';
  gridConfig: MetricsGridConfig;
  paging = new PageOptionsNg();
  sorting: SortOptionsNg;
  totalCount = 0;
  frozenWidth: string;

  public jobs: any[];
  constructor(
    private translateService: TranslateService,
    private exportsService: ExportsService,
    private formatService: FormatService,
    private gridSortingService: GridSortingService,
  ) {

  }

  ngOnInit() {
    this.gridSortingService.setGridName(this.gridName);
    this.sorting = this.gridSortingService.getSortOptionsNg('file', -1);

    const unknownWordTranslation = this.translateService.instant('main.tabs.downloads.unknown_file_size');
    const statusesMap = {
      failure: this.getLocalizedStatus('failure'),
      queued: this.getLocalizedStatus('queued'),
      success: this.getLocalizedStatus('success'),
      working: this.getLocalizedStatus('working')
    };

    this.isloading = true;
    this.exportsService.jobs$.subscribe(jobs => {
      this.isloading = false;
      const date = new Date();
      date.setDate(date.getUTCDate() - 2);
      const limitDateUTCTimestamp = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
        date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds());

      const unsuccessJobs = _.filter(jobs, (job) => {
        const jobUTCTimestamp = Date.UTC(job.creation.getUTCFullYear(), job.creation.getUTCMonth(), job.creation.getUTCDate(),
          job.creation.getUTCHours(), job.creation.getUTCMinutes(), job.creation.getUTCSeconds(), job.creation.getUTCMilliseconds());
        return job.status !== 'Success' && jobUTCTimestamp > limitDateUTCTimestamp;
      });
      this.jobs = _.orderBy(unsuccessJobs, [this.sorting.sortField], [this.sorting.sortOrder === -1 ? 'desc' : 'asc']);
      this.jobs.forEach(element => {
        element.size = unknownWordTranslation;
        element.status = statusesMap && element.status && element.status.toLowerCase() in statusesMap ? statusesMap[element.status.toLowerCase()] : element.status;
      });
    });

    this.gridConfig = new MetricsGridConfig(
      [
        {
          title: 'main.tabs.downloads.custom_grids_queue',
          visible: true,
          headerStyle: {
            'text-align': 'center',
            color: '#fff',
            background: '#99A8BD'
          },
          forceCanClose: false,
          columns: [
            {
              title: 'main.tabs.downloads.file_name',
              field: 'file',
              sortColumn: 'file',
              width: 500,
              headerStyle: {
                'text-align': 'left'
              },
              cellStyle: {
                'text-align': 'left'
              }
            },
            {
              title: 'main.tabs.downloads.status',
              field: 'status',
              sortColumn: 'status',
              width: 100,
              headerStyle: {
                'text-align': 'right'
              },
              cellStyle: {
                'text-align': 'right'
              }
            },
            {
              title: 'main.tabs.downloads.size',
              field: 'size',
              headerStyle: {
                'text-align': 'right'
              },
              cellStyle: {
                'text-align': 'right'
              }
            },
            {
              title: 'main.tabs.downloads.exported_by',
              field: 'user_email',
              sortColumn: 'user_email',
              headerStyle: {
                'text-align': 'right'
              },
              cellStyle: {
                'text-align': 'right'
              }
            },
            {
              title: 'main.tabs.downloads.modified_date',
              field: 'creation',
              valueFactory: (v, r) => {
                return this.formatService.formatDate(v, 'MMM dd, y');
              },
              sortColumn: 'creation',
              headerStyle: {
                'text-align': 'right'
              },
              cellStyle: {
                'text-align': 'right'
              },
            }
          ]
        }]);

  }

  ngOnDestroy() {
    while (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.pop().unsubscribe();
    }
  }

  public getLocalizedStatus(status: string): string {
    const basePath = 'main.tabs.downloads.statuses';
    return this.translateService.instant(`${basePath}.${status.toLowerCase()}`);
  }

  changePage = (event: any) => {
  }

  changeLazyLoad = (event: LazyLoadEvent) => {
    if (this.sorting.sortField === event.sortField && this.sorting.sortOrder === event.sortOrder) {
      return;
    }

    this.gridSortingService.setSortOption(event.sortField, event.sortOrder === -1);

    this.sorting.sortField = event.sortField || this.gridSortingService.defaultSortColumn;
    this.sorting.sortOrder = event.sortOrder || -1;

    this.jobs = _.orderBy(this.jobs, [this.sorting.sortField], [this.sorting.sortOrder === -1 ? 'desc' : 'asc']);
  }
}
