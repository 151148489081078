import {Component, ViewEncapsulation} from '@angular/core';
import {MaintenanceModeService} from './maintenance-mode.service';

@Component({
  selector: 'rdo-maintenance-window',
  templateUrl: './maintenance-mode.component.html',
  styleUrls: ['./maintenance-mode.component.scss'],
  // encapsulation breaks the main content when using maintenance mode as a wrapper.
  // encapsulation: ViewEncapsulation.ShadowDom
})
export class MaintenanceModeComponent {
  constructor(public service: MaintenanceModeService) {}
}
