import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { map, mergeMap, filter } from 'rxjs/operators';
import * as _ from 'lodash';
import {
  ActiveFilterService,
  SortOptions,
  ConfigService,
  ViewService,
} from '../core';
import { RentalAssetService } from './rental-asset.service';
import { SelectedAssetService } from './selected-asset.service';
import { RentalAssetCard } from '../models';
import { AuthenticationService } from '../core/authentication/authentication.service';

class AssetRouteParams {
  rouseEquipmentId: number;
  productType: string;
  sortOn: string;
  desc: boolean;

  get sortOptions(): SortOptions {
    return { sortOn: this.sortOn, descending: this.desc };
  }

  clone = () => {
    return {
      sortOn: this.sortOn,
      desc: this.desc,
    };
  };
}

@Component({
  templateUrl: 'rental-asset.component.html',
  styleUrls: ['rental-asset.component.scss'],
})
export class RentalAssetComponent implements OnInit, OnDestroy {
  params: AssetRouteParams;
  private selectedAsset: RentalAssetCard;
  private allAssetCards: Array<RentalAssetCard>;
  private assetCards: Array<RentalAssetCard>;
  private subscriptions = [];
  private currentChildRoute = '';
  private currentCardPage = 1;
  private cardPageSize = 20;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private filterService: ActiveFilterService,
    private rentalAssetService: RentalAssetService,
    private selectedAssetService: SelectedAssetService,
    private authenticationService: AuthenticationService,
    private configService: ConfigService,
    private viewService: ViewService
  ) {}

  ngOnInit() {
    this.authenticationService.startSentryTransaction(
      this.router.routerState.snapshot.url
    );
    if (this.params == null) {
      this.subscriptions.push(
        this.route.params.subscribe((params) => {
          if (this.params) {
            if (this.params.rouseEquipmentId === parseInt(params.asset, 10)) {
              return;
            }
          }

          const p = new AssetRouteParams();
          p.rouseEquipmentId = parseInt(params.asset, 10);
          p.productType = params.productType || null;
          p.sortOn = params.sortOn || '';
          p.desc = params.desc === 'true';
          const reloadCards = params.reloadCards === 'true';
          this.load(p, reloadCards);
        })
      );
    }

    this.subscriptions.push(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.route),
          map((route) => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          }),
          filter((route) => route.outlet === 'primary'),
          mergeMap((route) => route.data)
        )
        .subscribe((event) => {
          if (event.name) {
            // event.name === 'dashboard' ? this.currentChildRoute = '' : this.currentChildRoute = event.name;
            this.currentChildRoute =
              event.name === 'dashboard' ? '' : event.name;
          }
        })
    );

    this.subscriptions.push(
      this.filterService.filterChange.subscribe(() =>
        this.load(this.params, true)
      )
    );
  }

  ngOnDestroy() {
    while (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.pop().unsubscribe();
    }

    this.selectedAssetService.clear();
  }

  select = (selected: RentalAssetCard) => {
    this.selectedAsset = selected;

    const urlTree = this.currentChildRoute
      ? this.router.createUrlTree(
          [
            '../',
            selected.RouseEquipmentID,
            this.params.clone(),
            this.currentChildRoute,
          ],
          { relativeTo: this.route }
        )
      : this.router.createUrlTree(
          ['../', selected.RouseEquipmentID, this.params.clone()],
          { relativeTo: this.route }
        );

    this.router.navigateByUrl(urlTree);
    this.selectedAssetService.notify(this.selectedAsset);
  };

  isSelected(asset: RentalAssetCard): boolean {
    return (
      !this.selectedAsset ||
      this.selectedAsset.RouseEquipmentID === asset.RouseEquipmentID
    );
  }

  private load(params: AssetRouteParams, reloadCards: boolean = false) {
    if (!this.allAssetCards || reloadCards) {
      this.rentalAssetService
        .getRentalAssetsCards(
          params.rouseEquipmentId,
          params.productType,
          params.sortOptions
        )
        .pipe(map((d) => <Array<RentalAssetCard>>d))
        .subscribe((cards) => {
          this.allAssetCards = cards;
          this.params = params;

          const index = _.findIndex(
            this.allAssetCards,
            (x: RentalAssetCard) =>
              x.RouseEquipmentID === params.rouseEquipmentId
          );
          this.selectedAsset = this.allAssetCards[index];

          if (!this.selectedAsset) {
            this.router.navigate(['/rental-assets']);
            return;
          }

          if (this.allAssetCards.length <= 20) {
            this.assetCards = this.allAssetCards;
          } else {
            this.currentCardPage =
              index <= this.cardPageSize
                ? this.currentCardPage
                : index / this.cardPageSize;
            const previousTwenty = _.slice(
              this.allAssetCards,
              index <= this.cardPageSize ? 0 : index - this.cardPageSize,
              index
            );
            this.assetCards = _.union(
              previousTwenty,
              _.slice(this.allAssetCards, index, index + this.cardPageSize)
            );
          }

          this.selectedAssetService.notify(this.selectedAsset);
          this.authenticationService.finishSentryTransaction();
        });
    } else {
      const index = _.findIndex(
        this.allAssetCards,
        (x: RentalAssetCard) => x.RouseEquipmentID === params.rouseEquipmentId
      );
      this.selectedAsset = this.allAssetCards[index];

      this.selectedAssetService.notify(this.selectedAsset);
      this.params = params;
    }
  }

  getPaginatedCards = () => {
    this.currentCardPage++;
    const offset = (this.currentCardPage - 1) * this.cardPageSize;
    const nextCards = _.drop(this.allAssetCards, offset).slice(
      0,
      this.cardPageSize
    );

    if (nextCards && nextCards.length) {
      this.assetCards = _.union(this.assetCards, nextCards);
    }
  };
}
