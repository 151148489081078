<nav id="nav-primary">
	<ul class="nav nav-justified nav-tabs">
		<li class="text-uppercase" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
			*ngIf="clientAttributes !== undefined && hasRDOAccess">
			<a routerLink="summary" id="{{'main.tabs.summary.page_title' | rdoPathToId}}"><i
					class="fa fa-fw fa-home"></i>&nbsp; {{'main.tabs.summary.page_title' | translate}}</a>
		</li>

		<!-- Watchlist -->
		<li class="text-uppercase" style="display: none;">
			<div class="fake-tab"></div>
			<a href="#" id="{{'main.navigation.watchlists' | rdoPathToId}}">
				<i class="fa fa-fw fa-eye"></i>&nbsp; {{'main.navigation.watchlists' | translate}}
				<span class="label label-badge label-success-inverse margin-left-xl">
					<i class="fa fa-long-arrow-up"></i>&nbsp; 0.00</span>
			</a>
		</li>

		<!-- Geography -->
		<li class="dropdown geo-list" id="geography-dropdown" routerLinkActive="active"
			*ngIf="clientAttributes !== undefined && !clientAttributes?.IsTrial && hasRDOAccess">
			<a class="dropdown-toggle geo-link" (click)="displayGeoDropdown = !displayGeoDropdown">
				<i class="fa fa-fw fa-globe" aria-hidden="true"></i>&nbsp;
				{{"main.tabs.geography.title" | translate}} <span class="caret"></span>
			</a>
			<ul class="dropdown-menu text-uppercase geo-ul" [style.display]="displayGeoDropdown ? 'inline' : none">
				<li id="regions-list-item" (click)="displayGeoDropdown = !displayGeoDropdown" class="geo-li">
					<a routerLink="regions">
						<i class="fa fa-fw fa-globe" aria-hidden="true"></i>&nbsp;
						{{'main.tabs.geography.regions.title' | translate}}
					</a>
				</li>
				<li id="districts-list-item" (click)="displayGeoDropdown = !displayGeoDropdown" class="geo-li">
					<a routerLink="districts">
						<i class="fa fa-fw fa-road" aria-hidden="true"></i>&nbsp;
						{{'main.tabs.geography.districts.title' | translate}}
					</a>
				</li>
				<li id="branches-list-item" (click)="displayGeoDropdown = !displayGeoDropdown" class="geo-li">
					<a routerLink="branches">
						<i class="fa fa-fw fa-university" aria-hidden="true"></i>&nbsp;
						{{'main.tabs.geography.branches.title' | translate}}
					</a>
				</li>
			</ul>
		</li>

		<li class="text-uppercase" *ngIf="clientAttributes !== undefined && clientAttributes?.IsTrial && hasRDOAccess"
			id="geography-dropdown">
			<div class="fake-tab"></div>
			<a class="fake-anchor"><i class="fa fa-fw fa-globe" aria-hidden="true">
				</i>&nbsp; {{"main.tabs.geography.title" | translate}} <span class="caret"></span>
			</a>
		</li>

		<!-- Sales Reps -->
		<li class="text-uppercase" id="sales-reps-list-item" routerLinkActive="active"
			*ngIf="clientAttributes !== undefined && !clientAttributes?.IsTrial && hasRDOAccess">
			<a routerLink="sales-reps"><i class="fa fa-fw fa-user">
				</i>&nbsp; {{"main.tabs.sales_reps.sales_reps" | translate}}
			</a>
		</li>

		<li class="text-uppercase" id="sales-reps-list-item"
			*ngIf="clientAttributes !== undefined && clientAttributes?.IsTrial && hasRDOAccess">
			<div class="fake-tab"></div>
			<a class="fake-anchor"><i class="fa fa-fw fa-user">
				</i>&nbsp; {{"main.tabs.sales_reps.sales_reps" | translate}}
			</a>
		</li>

		<!-- Customers -->
		<li class="text-uppercase" id="customers-list-item" routerLinkActive="active"
			*ngIf="clientAttributes !== undefined && !clientAttributes?.IsTrial && hasRDOAccess">
			<a routerLink="customers"><i class="fa fa-fw fa-building">
				</i>&nbsp; {{"main.tabs.customers.customers" | translate}}
			</a>
		</li>

		<li class="text-uppercase" id="customers-list-item"
			*ngIf="clientAttributes !== undefined && clientAttributes?.IsTrial && hasRDOAccess">
			<div class="fake-tab"></div>
			<a class="fake-anchor"><i class="fa fa-fw fa-building">
				</i>&nbsp; {{"main.tabs.customers.customers" | translate}}
			</a>
		</li>

		<!-- Equipment -->
		<li class="dropdown text-uppercase equipment-dropdown-list" id="equipment-dropdown" routerLinkActive="active"
			style="user-select: none;"
			*ngIf="clientAttributes !== undefined && !clientAttributes?.IsTrial && hasAssetGrid && hasRDOAccess">
			<a (click)="displayEquipmentDropdown = !displayEquipmentDropdown" class="equipment-dropdown-link">
				<rdo-category-icon [category]="gear" class="equipment-icon"></rdo-category-icon>
				&nbsp; {{"main.tabs.equipment.title" | translate}}
				<span class="caret equipment-caret"></span>
			</a>
			<ul class="dropdown-menu text-uppercase equipment-dropdown-container"
				[style.display]="displayEquipmentDropdown ? 'inline' : none">
				<li id="equipment-list-item" (click)="displayEquipmentDropdown = !displayEquipmentDropdown">
					<a routerLink="equipment">
						<img src="/assets/images/icons/gear.svg" class="gear-icon">
						&nbsp; {{'main.core.common.counts.product_types.plural' | translate}}</a>
				</li>
				<li id="rental-assets-list-item" (click)="displayEquipmentDropdown = !displayEquipmentDropdown">
					<a routerLink="rental-assets">
						<i class="fa fa-fw fa-tags" aria-hidden="true"></i>
						&nbsp; {{'main.tabs.equipment.rental_assets.title' | translate}}</a>
				</li>
			</ul>
		</li>

		<li class="text-uppercase" id="equipment-list-item" routerLinkActive="active"
			*ngIf="clientAttributes !== undefined && !clientAttributes?.IsTrial && !hasAssetGrid && hasRDOAccess">
			<a routerLink="equipment">
				<rdo-category-icon [category]="gear"></rdo-category-icon>&nbsp; {{"main.tabs.equipment.title" |
				translate}}
			</a>
		</li>

		<li class="text-uppercase" id="equipment-list-item"
			*ngIf="clientAttributes !== undefined && clientAttributes?.IsTrial && hasRDOAccess">
			<div class="fake-tab"></div>
			<a class="fake-anchor">
				<rdo-category-icon [category]="gear"></rdo-category-icon>&nbsp; {{"main.tabs.equipment.title" |
				translate}}
			</a>
		</li>

		<!-- Changes Log -->
		<li class="text-uppercase" id="changes-log-list-item" routerLinkActive="active"
			*ngIf="clientAttributes !== undefined && hasChangeLog && hasRDOAccess">
			<a routerLink="changes-log"><i class="fa fa-fw fa-file-text"></i>&nbsp; {{"main.tabs.changes_log.title" |
				translate}}</a>
		</li>
		<li class="text-uppercase" id="changes-log-list-item"
			*ngIf="clientAttributes !== undefined && !hasChangeLog && hasRDOAccess">
			<div class="fake-tab"></div>
			<a class="fake-anchor"><i class="fa fa-fw fa-file-text"></i>&nbsp; {{"main.tabs.changes_log.title" |
				translate}}</a>
		</li>

		<li *ngIf="authenticationService._userInfoView.HasClientAccessToDownloads" id="downloads-list-item"
			class="text-uppercase" routerLinkActive="active">
			<a routerLink="downloads"><i class="fa fa-fw fa-download"></i>&nbsp; {{"main.tabs.downloads.title" |
				translate}}</a>
		</li>
		<li *ngIf="authenticationService.hasAccessToCustomGrids(showCustomGrids, clientAttributes?.IsTrial)"
			id="custom-grids-list-item" class="text-uppercase" routerLinkActive="active">
			<a routerLink="custom-grids"><i class="fa fa-fw fa-cog"></i>&nbsp; {{"main.tabs.custom_grids.title" |
				translate}}</a>
		</li>
	</ul>
</nav>
