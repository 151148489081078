import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { zip } from 'rxjs/observable/zip';
import * as _ from 'lodash';
import * as filterFunctions from './../filter/functions/filter.functions';
import {
	ActiveFilterService,
	BreadcrumbService,
	ViewService,
	ComparisonMode,
	ChartType,
	ChartSwitcher,
	ClientProfileService,
	ChartSwitchingService,
	ChartDisplayer
} from '../core';
import { SalesRepBaseComponent } from './sales-rep-base.component';
import { SelectedSalesRepService } from './selected-sales-rep.service';
import { SalesRepService } from './sales-rep.service';
import { RevenueService, RevenueSlice } from '../summary';
import { ChartData, TopItemsData, DashboardCardType, DashboardCardSetData, TxAttributes } from '../models';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { CODENAMES } from '../core/constants';
import { FilterProfileService } from '../filter/profiles/filter-profile.service';

@Component({
	selector: 'rdo-sales-rep-dashboard',
	templateUrl: 'sales-rep-dashboard.html',
	styleUrls: ['sales-rep.component.scss', '../../styles/_summary-charts.scss']
})
export class SalesRepDashboardComponent extends SalesRepBaseComponent implements OnInit, OnDestroy {
	private selectedSalesRep: any;
	private chartData = new ChartData({ data: [], loading: false });
	private dashboardCardData: DashboardCardSetData;
	private productTypes = new TopItemsData();
	private customers = new TopItemsData();
	private rateTypes = new TopItemsData();
	private loading = true;
	private comparisonMode = ComparisonMode;
	private mode: ComparisonMode = ComparisonMode.Benchmark;
	private chartSwitcher = new ChartSwitcher(ChartType.None);
	private clientAttributes = new TxAttributes(<TxAttributes>{ IsTrial: true });
	private monthsToReturn = 6;

    constructor(
		public breadcrumbService: BreadcrumbService,
		public selectedSalesRepService: SelectedSalesRepService,
		private router: Router,
		private route: ActivatedRoute,
		private viewService: ViewService,
		private salesRepsService: SalesRepService,
		private filterService: ActiveFilterService,
		private revenueService: RevenueService,
		private translateService: TranslateService,
		private authenticationService: AuthenticationService,
		private chartSwitchingService: ChartSwitchingService,
		protected clientProfileService: ClientProfileService,
		protected filterProfileService: FilterProfileService
	) {
		super(selectedSalesRepService, breadcrumbService);
	}

    ngOnInit() {
        this.subscriptions.push(
            this.authenticationService.selectedClientId.subscribe(clientid => {
                if (clientid) {
                    const component = this; // eslint-disable-line @typescript-eslint/no-this-alias
                    component.clearData();
                    component.subscriptions.push(
                        component.clientProfileService.getClientAttributes().subscribe(
                            attributes => component.clientAttributes = attributes
                        )
                    );
                    this.subscriptions.push(
                        this.filterProfileService.wait4CurrentProfile().subscribe(
                            () => {
                                const filterValue = this.filterProfileService.readClientProfileProperty(CODENAMES.CN_MONTHS_TO_RETURN);
								this.monthsToReturn = filterValue ? filterValue : 6;
                                component.selectedSalesRepService.SalesRep.subscribe(s => {
                                    component.selectedSalesRep = s;
                                    component.load();
                                    component.subscriptions.push(
                                        component.selectedSalesRepService.salesRepChange.subscribe(x => {
                                            if (component.selectedSalesRep.SalesRepId !== x.SalesRepId) {
                                                component.selectedSalesRep = x;
                                                component.load();
                                            }
                                        })
                                    );
                                });
                                component.subscriptions.push(
                                    component.filterService.filterChange.subscribe(
                                        component.load
                                    )
                                );
                            }
                        )
                    );
                }
            })
        );
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	private load = () => {
		this.loading = true;
		this.loadChart();
		zip(
			this.salesRepsService
				.getDashboardCardData(this.selectedSalesRep.SalesRepId)
				.pipe(map(x => <DashboardCardSetData>x.Items[0])),
			this.salesRepsService
				.getTopCustomers(this.selectedSalesRep.SalesRepId)
				.pipe(map(p => <TopItemsData>{ data: p })),
			this.salesRepsService
				.getRateTypes(this.selectedSalesRep.SalesRepId)
				.pipe(map(p => <TopItemsData>{ data: p })),
			this.salesRepsService
				.getTopProductTypes(this.selectedSalesRep.SalesRepId)
				.pipe(map(p => <TopItemsData>{ data: p }))
		).subscribe((results: [DashboardCardSetData, TopItemsData, TopItemsData, TopItemsData]) => {
			this.dashboardCardData = results[0];
			this.customers = results[1];
			this.rateTypes = results[2];
			this.productTypes = results[3];
			this.loading = false;
		});
		this.updateBreadcrumbs([{ title: 'main.core.common.dashboard', class: 'active' }]);
	}

	private loadChart = () => {
		this.chartData = new ChartData({ data: [], loading: true });
		zip(
			this.salesRepsService
				.getRevenueByMonthData(this.selectedSalesRep.SalesRepId, this.monthsToReturn)
				.pipe(map(x => <ChartData>{ data: x, loading: false })),
			this.viewService.getComparisonMode())
			.subscribe((results: [ChartData, ComparisonMode]) => {
				this.chartData = results[0];
				this.mode = results[1];

				this.chartSwitcher = this.chartSwitchingService.getCurrentChart(
					ChartDisplayer.SALES_REPS,
					this.mode,
					this.chartSwitcher,
					null);
				this.subscriptions.push(this.chartSwitcher.chartChanged.subscribe(chart => {
					this.chartSwitchingService.setCurrentChart(ChartDisplayer.SALES_REPS, chart);
				}));
			});
	}

	private clearData = () => {
		this.chartData = new ChartData();
		this.chartData.data = [];
		this.customers = new TopItemsData();
		this.rateTypes = new TopItemsData();
		this.productTypes = new TopItemsData();
	}

	private onSliceClick = (slice: RevenueSlice) => {
		const sliceParams = this.revenueService.getSliceParams(slice);
		const sliceName = this.revenueService.getSliceDisplayTitle(slice);
		this.updateBreadcrumbs([
			{
				title: sliceName,
				class: 'active'
			}]);
		const urlTree = this.router.createUrlTree(['transactions', sliceParams], { relativeTo: this.route });
		this.router.navigateByUrl(urlTree);
	}

	onCardClicked = (cardType: DashboardCardType) => {
		switch (cardType) {
			case DashboardCardType.ClientRevenue:
				this.chartSwitcher.selectRevenueBookChart();
				break;
			case DashboardCardType.ClientRateVsBenchmark:
				this.chartSwitcher.selectRevenueBenchChart();
				break;
			case DashboardCardType.TopQuartile:
			case DashboardCardType.BottomQuartile:
				this.chartSwitcher.selectTotalRevenueVsBenchmarkChart();
				break;
			default:
				break;
		}
	}

	getSelectedChartName() {
		return this.chartSwitcher.translatedCurrent(text => this.translateService.instant(text));
	}

	transactionsBelowBQBreadcrumb() {
		this.updateBreadcrumbs([{
			title: 'main.core.common.counts.transactions.breadcrumb_titles.below_bottom_quartile',
			class: 'active'
		}]);
	}
	
	transactionsAboveTQBreadcrumb() {
		this.updateBreadcrumbs([{
			title: 'main.core.common.counts.transactions.breadcrumb_titles.above_top_quartile',
			class: 'active'
		}]);
	}

}
