import { Component } from '@angular/core';
import { FilterProfileService } from '../filter-profile.service';

@Component({
  selector: 'rdo-filter-profile-spinner',
  templateUrl: './filter-profile-spinner.component.html',
  styleUrls: ['./filter-profile-spinner.component.scss']
})
export class FilterProfileSpinnerComponent {
  public saving: boolean = false;

  constructor(
    private filterProfileService: FilterProfileService
  ) {
    this.filterProfileService.showSpinner.subscribe(value => {
      this.saving = value;
    });
  }
}
