import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { FormatService } from './../../../../app/core/query';

@Component({
	selector: 'rdo-rate-type-panel',
	templateUrl: 'rate-type-panel.component.html'
})

export class RateTypePanelComponent implements OnInit {
	data: Array<any>;
	revenueAboveTQ: number;
	revenueAboveTQBench: number;
	revenueBelowBQ: number;
	revenueBelowBQBench: number;
	selectedCurrencySymbol: string;

	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input('chart-data')
	set chartData(data: any) {
		if (data) {
			this.data = data;
			this.loadChart(data);
		}
	}

	constructor(private formatService: FormatService) {
	}

	ngOnInit(): void {
		this.selectedCurrencySymbol = this.formatService.selectedCurrencySymbol;
	}

	private loadChart = (chartData: Array<any>) => {
		let data = chartData;

		if (!_.some(chartData, o => o.FromValue && o.ToValue)) {
			data = [];
		}

		this.revenueAboveTQ = _.max(_.map(data, o => o.RevenueAboveTQ));
		this.revenueAboveTQBench = _.max(_.map(data, o => o.RevenueAboveTQBench));
		this.revenueBelowBQ = _.max(_.map(data, o => o.RevenueBelowBQ));
		this.revenueBelowBQBench = _.max(_.map(data, o => o.RevenueBelowBQBench));
	}
}
