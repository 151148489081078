import { initReportObservable, RawReportType } from '@datadog/browser-core';
export function trackReportError(configuration, errorObservable) {
  var subscription = initReportObservable(configuration, [RawReportType.cspViolation, RawReportType.intervention]).subscribe(function (rawError) {
    return errorObservable.notify(rawError);
  });
  return {
    stop: function () {
      subscription.unsubscribe();
    }
  };
}
