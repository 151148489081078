import { Injectable } from '@angular/core';
import { RdoHttpService } from '../http/rdo-http.service';

@Injectable()
export class FirestoreSettingsService {

  constructor(
    private rdoHttp: RdoHttpService
  ) { }

  public getSettings() {
    return this.rdoHttp.get('/filter/firestore-defaults');
  }
}
