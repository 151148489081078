import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { CustomerQuery } from '../models';
import {
	PageOptions,
	SortOptions,
	RdoHttpService,
	ExportSpreadsheetBody,
	QueryService,
	MetricsGridConfig,
    ExcelExportService
} from '../core';
import { FilterInfoService } from '../filter/services/filter-info.service';
import * as filterFunctions from './../filter/functions/filter.functions';
import * as _ from 'lodash';

@Injectable()
export class SalesRepCustomerService {
	private isGetRequest = false;

	constructor(
		private rdoHttp: RdoHttpService,
		private filterInfoService: FilterInfoService,
		private queryService: QueryService,
        private excelExportService: ExcelExportService
	) {
		this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
	}

	public getSalesRepCustomersDownload = (
		salesRep: string,
		salesRepId: string,
		sorting: SortOptions = new SortOptions(),
		gridConfig: MetricsGridConfig
	): Observable<Record<any, any>[]> => {
		const filters = this.filterInfoService.getFilterExport();
		filters['Sales Rep'] = salesRep;

		const pagedSorted = new CustomerQuery();
		pagedSorted.SalesRepId = salesRepId;
		pagedSorted.SetSorting(sorting);
		const body = new ExportSpreadsheetBody(gridConfig.getAllColumns(), filters);
		return this.excelExportService.generateGridSpreadsheet('metrics/sales-reps/customers-download', body, pagedSorted);
	}

	public getSalesRepCustomers = (salesRepId: string, paging: PageOptions = new PageOptions(), sorting: SortOptions = new SortOptions()): Observable<any> => {
		const pagedSorted = new CustomerQuery();
		pagedSorted.SalesRepId = salesRepId;
		return this.queryService.getPagedSorted('metrics/sales-reps/customers', paging, sorting, false, null, pagedSorted);
	}

	public getSalesRepCustomersProductTypes = (salesRepId: string, customerId: number, sorting: SortOptions = new SortOptions()): Observable<any> => {
		const pagedSorted = new CustomerQuery();
		pagedSorted.Page = 1;
		pagedSorted.PageSize = 500;
		pagedSorted.SalesRepId = salesRepId;
		pagedSorted.CustomerId = customerId;
		pagedSorted.SetSorting(sorting);
		return this
			.rdoHttp
			.post('metrics/sales-reps/customers/product-types', pagedSorted)
			.pipe(map((r: any) => {
				return r.Items;
			}));
	}
}
