import { Pipe, PipeTransform } from '@angular/core';
import { FormatService } from '../../core/query';

/*
 * Turns a path from the translations JSON into an id for
 * an html tag.
 * Usage:
 *   [attr.id]="path | rdoPathToId"
 * Or:
 *   id="{{'' | rdoPathToId}}"
 **/
@Pipe({
    name: 'rdoPathToId'
})
export class RdoPathToIdPipe implements PipeTransform {

    transform = (path: string, suffix: string = 'selector'): string => {
        if (path) {
            return `${path.toLowerCase().split(' ').join('-').split('.').join('-')}-${suffix}`;
        } else {
            return undefined;
        }
    }
}
