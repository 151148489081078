"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ObjectUnsubscribedErrorImpl = function () {
  function ObjectUnsubscribedErrorImpl() {
    Error.call(this);
    this.message = 'object unsubscribed';
    this.name = 'ObjectUnsubscribedError';
    return this;
  }
  ObjectUnsubscribedErrorImpl.prototype = Object.create(Error.prototype);
  return ObjectUnsubscribedErrorImpl;
}();
exports.ObjectUnsubscribedError = ObjectUnsubscribedErrorImpl;
