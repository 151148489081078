export enum MonthSetting {
    NO_MONTHS = 'no_months',
    ALL_MONTHS = 'all_months',
    LATEST_MONTH = 'latest_month',
    LATEST_FULL_MONTH = 'latest_full_month',
    LATEST_3_FULL_MONTHS = 'latest_3_full_months',
    LATEST_6_FULL_MONTHS = 'latest_6_full_months',
    LATEST_9_FULL_MONTHS = 'latest_9_full_months',
    LATEST_12_FULL_MONTHS = 'latest_12_full_months',
    YEAR_TO_DATE = 'year_to_date',
    SELECTION = 'selection',
    DEFAULT = 'default',
}
