import { RecordType } from '../../../types';
export function trackFrustration(lifeCycle, frustrationCb, recordIds) {
  var frustrationSubscription = lifeCycle.subscribe(11 /* LifeCycleEventType.RAW_RUM_EVENT_COLLECTED */, function (data) {
    var _a, _b;
    if (data.rawRumEvent.type === "action" /* RumEventType.ACTION */ && data.rawRumEvent.action.type === "click" /* ActionType.CLICK */ && ((_b = (_a = data.rawRumEvent.action.frustration) === null || _a === void 0 ? void 0 : _a.type) === null || _b === void 0 ? void 0 : _b.length) && 'events' in data.domainContext && data.domainContext.events && data.domainContext.events.length) {
      frustrationCb({
        timestamp: data.rawRumEvent.date,
        type: RecordType.FrustrationRecord,
        data: {
          frustrationTypes: data.rawRumEvent.action.frustration.type,
          recordIds: data.domainContext.events.map(function (e) {
            return recordIds.getIdForEvent(e);
          })
        }
      });
    }
  });
  return {
    stop: function () {
      frustrationSubscription.unsubscribe();
    }
  };
}
