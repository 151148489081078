import { assign, runOnReadyState } from '@datadog/browser-core';
import { RumPerformanceEntryType } from '../../browser/performanceObservable';
import { getDocumentTraceId } from '../tracing/getDocumentTraceId';
import { getNavigationEntry } from '../../browser/performanceUtils';
import { FAKE_INITIAL_DOCUMENT } from './resourceUtils';
export function retrieveInitialDocumentResourceTiming(configuration, callback) {
  runOnReadyState(configuration, 'interactive', function () {
    var entry = assign(getNavigationEntry().toJSON(), {
      entryType: RumPerformanceEntryType.RESOURCE,
      initiatorType: FAKE_INITIAL_DOCUMENT,
      traceId: getDocumentTraceId(document),
      toJSON: function () {
        return assign({}, entry, {
          toJSON: undefined
        });
      }
    });
    callback(entry);
  });
}
