﻿<rdo-loader [enabled]="loading"></rdo-loader>
<ng-template [ngIf]="data">
	<div class="card-set-container">	
		<div class="panel panel-default rdo-card rdo-card-dashboard-default rdo-card-primary-blue cursor-pointer flex-card-4"
			(click)="notifyCardClicked(dashboardCardType.ClientRevenue)">
			<i class="fa fa-fw fa-line-chart" id="client-revenue-vs-book-trend"
			title="{{'main.tabs.summary.cards.client_revenue.client_revenue_vs_book_trend' | translate}}"></i>
			<div class="panel-title"
			id="{{'main.tabs.summary.cards.client_revenue.title' | rdoPathToId}}"
			title="{{'main.tabs.summary.cards.client_revenue.title' | translate}}"
			>{{'main.tabs.summary.cards.client_revenue.title' | translate}}</div>
			<div class="panel-body">
				<div class="rdo-card-metric" id="client-revenue-vs-book-metric">
					{{data.RevenueTotal | rdoCurrency:true:translateService.currentLang}}
				</div>
				<div class="col-xs-12" id="{{'main.tabs.summary.cards.client_revenue.off_book' | rdoPathToId}}">
					<span>({{('main.tabs.summary.cards.client_revenue.off_book' | translate).replace('${1}', variancePercent(data.RevenueTotal, data.RevenueBook) | percent:'1.1-1')}})</span>
				</div>
			</div>
		</div>
		
		<div class="panel panel-default rdo-card rdo-card-dashboard-default rdo-card-accent-blue cursor-pointer flex-card-4"
		    (click)="notifyCardClicked(dashboardCardType.ClientRateVsBenchmark)">
			<i class="fa fa-fw fa-line-chart" id="client-rate-vs-benchmark-trend"
			title="{{'main.tabs.summary.cards.client_rate_vs_benchmark.client_rate_vs_bench_trend' | translate}}"></i>
			<div class="panel-title"
			id="{{'main.tabs.summary.cards.client_rate_vs_benchmark.title' | rdoPathToId}}"
			title="{{'main.tabs.summary.cards.client_rate_vs_benchmark.title' | translate}}"
			>{{'main.tabs.summary.cards.client_rate_vs_benchmark.title' | translate}}</div>
			<div class="panel-body">
				<div class="rdo-card-metric" id="client-rate-vs-benchmark-metric">
					<span *ngIf="variance(data.RevenueBenchmarked, data.RevenueBench) <= 0">{{(variancePercent(data.RevenueBenchmarked, data.RevenueBench) | percent:'1.1-1') || "-"}}</span>
					<span *ngIf="variance(data.RevenueBenchmarked, data.RevenueBench) > 0">+{{variancePercent(data.RevenueBenchmarked, data.RevenueBench) | percent:'1.1-1'}}</span>
				</div>
				<div class="col-xs-12" id="{{'main.tabs.summary.cards.client_rate_vs_benchmark.client_revenue_compared' | rdoPathToId}}">
					({{data.RevenueBenchmarked | rdoCurrency:true:translateService.currentLang}} {{'main.tabs.summary.cards.client_rate_vs_benchmark.client_revenue_compared' | translate}})
				</div>
			</div>
		</div>
	
		<ng-template [ngIf]="displayQuartiles()">			
			<div class="panel panel-default rdo-card rdo-card-dashboard-default rdo-card-primary-green cursor-pointer flex-card-4"
				(click)="notifyCardClicked(dashboardCardType.TopQuartile)">
				<i class="fa fa-fw fa-line-chart" id="premium-to-top-quartile-trend"
				title="{{'main.tabs.summary.cards.premium_to_top_quartile.trend' | translate}}"></i>
				<div class="panel-title"
				id="{{'main.tabs.summary.cards.premium_to_top_quartile.title' | rdoPathToId}}"
				title="{{'main.tabs.summary.cards.premium_to_top_quartile.title' | translate}}"
				>{{'main.tabs.summary.cards.premium_to_top_quartile.title' | translate}}</div>
				<div class="panel-body">
					<div class="rdo-card-metric" id="premium-to-top-quartile-metric">
						{{data.RevenueAboveTQ - data.RevenueAboveTQBench | rdoCurrency:true:translateService.currentLang}}
					</div>
					<div class="col-xs-12" id="{{'main.tabs.summary.cards.premium_to_top_quartile.transactions' | rdoPathToId}}">
						<a *ngIf="data.TransactionsAboveTQ > 0" [routerLink]="['transactions',{aboveTq:true}]" (click)="notifyTq()">{{data.TransactionsAboveTQ}} {{'main.core.common.counts.transactions.plural' | translate}}</a>
						<span *ngIf="data.TransactionsAboveTQ === 0">0 {{'main.core.common.counts.transactions.plural' | translate}}</span>
					</div>
				</div>
			</div>
			
			<div class="panel panel-default rdo-card rdo-card-dashboard-default rdo-card-primary-red cursor-pointer flex-card-4"
				(click)="notifyCardClicked(dashboardCardType.BottomQuartile)">
				<i class="fa fa-fw fa-line-chart" id="discount-to-bottom-quartile-trend"
				title="{{'main.tabs.summary.cards.discount_to_bottom_quartile.trend' | translate}}"></i>
				<div class="panel-title"
				id="{{'main.tabs.summary.cards.discount_to_bottom_quartile.title' | rdoPathToId}}"
				title="{{'main.tabs.summary.cards.discount_to_bottom_quartile.title' | translate}}"
				>{{'main.tabs.summary.cards.discount_to_bottom_quartile.title' | translate}}</div>
				<div class="panel-body">
					<div class="rdo-card-metric" id="discount-to-bottom-quartile-metric">
						{{data.RevenueBelowBQ - data.RevenueBelowBQBench | rdoCurrency:true:translateService.currentLang}}
					</div>
					<div class="col-xs-12" id="{{'main.tabs.summary.cards.discount_to_bottom_quartile' + '.transactions' | rdoPathToId}}">
						<a *ngIf="data.TransactionsBelowBQ > 0" [routerLink]="['transactions',{belowBq:true}]" (click)="notifyBq()">{{data.TransactionsBelowBQ}} {{'main.core.common.counts.transactions.plural' | translate}}</a>
						<span *ngIf="data.TransactionsBelowBQ ===0">0 {{'main.core.common.counts.transactions.plural' | translate}}</span>
					</div>
				</div>
			</div>
		</ng-template>
		<ng-template [ngIf]=!displayQuartiles()>
		
			<div class="panel panel-default rdo-card rdo-card-dashboard-default rdo-card-primary-gold cursor-pointer flex-card-4"
			    (click)="notifyCardClicked(dashboardCardType.ClientPhysicalUtilization)">
				<i class="fa fa-fw fa-line-chart" id="client-physical-utilization-trend"
				title="{{'main.tabs.summary.cards.client_physical_utilization.client_physical_utilization_total_trend' | translate}}"></i>
				<div class="panel-title"
				id="{{'main.tabs.summary.cards.client_physical_utilization.title' | rdoPathToId}}"
				title="{{'main.tabs.summary.cards.client_physical_utilization.title' | translate}}"
				>{{'main.tabs.summary.cards.client_physical_utilization.title' | translate}}</div>
				<div class="panel-body">
					<div class="rdo-card-metric" id="client-physical-utilization-metric">
						{{data.PhysicalUtilizationTotal | percent:'1.1-1'}}
					</div>
					<div class="col-xs-12" id="{{'main.tabs.summary.cards.client_physical_utilization.units' | rdoPathToId}}">
						({{data.CostInFleetTotal | rdoCurrency:true:translateService.currentLang}} {{'main.tabs.summary.cards.client_physical_utilization.oec' | translate}}, {{data.UnitCountTotal | number:'1.0-0'}} {{'main.tabs.summary.cards.client_physical_utilization.units' | translate}})
					</div>
				</div>
			</div>
				
			<div class="panel panel-default rdo-card rdo-card-dashboard-default rdo-card-accent-gold cursor-pointer flex-card-4"
			    (click)="notifyCardClicked(dashboardCardType.ClientUtilizationVsBenchmark)">
				<i class="fa fa-fw fa-line-chart" id="client-physical-utilization-vs-benchmark-trend"
				title="{{'main.tabs.summary.cards.client_physical_utilization_vs_benchmark.client_physical_utilization_vs_bench_trend' | translate}}"></i>
				<div class="panel-title"
				id="{{'main.tabs.summary.cards.client_physical_utilization_vs_benchmark.util_vs_bench' | rdoPathToId}}"
				title="{{'main.tabs.summary.cards.client_physical_utilization_vs_benchmark.util_vs_bench' | translate}}"
				>{{'main.tabs.summary.cards.client_physical_utilization_vs_benchmark.util_vs_bench' | translate}}</div>
				<div class="panel-body">
					<div class="rdo-card-metric" id="{{'main.tabs.summary.cards.client_physical_utilization_vs_benchmark.util_vs_bench' + '.pts' | rdoPathToId}}" >
						<span *ngIf="variance(data.PhysicalUtilizationBenchmarked, data.PhysicalUtilizationBench) <= 0">{{variance(data.PhysicalUtilizationBenchmarked, data.PhysicalUtilizationBench) | percent:'1.1-1'}} {{'main.core.common.counts.pts' | translate}}</span>
						<span *ngIf="variance(data.PhysicalUtilizationBenchmarked, data.PhysicalUtilizationBench) > 0">+{{variance(data.PhysicalUtilizationBenchmarked, data.PhysicalUtilizationBench) | percent:'1.1-1'}} {{'main.core.common.counts.pts' | translate}}</span>
					</div>
					<div class="col-xs-12" id="{{'main.tabs.summary.cards.client_physical_utilization_vs_benchmark.util_vs_bench' + '.units' | rdoPathToId}}">
						({{data.CostInFleetBenchmarked | rdoCurrency:true:translateService.currentLang}} {{'main.tabs.summary.cards.client_physical_utilization_vs_benchmark.oec' | translate}}, {{data.UnitCountBenchmarked | number}} {{'main.tabs.summary.cards.client_physical_utilization_vs_benchmark.units_compared' | translate}})
					</div>
				</div>
			</div>
		</ng-template>
	</div>
</ng-template>
