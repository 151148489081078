import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
	name: 'rdoAlerts'
})
export class RdoAlertsPipe implements PipeTransform {

	transform(items: any[], hideReadAlerts: boolean, hideClearedAlerts: boolean): any {
		if (!hideReadAlerts && !hideClearedAlerts) {
			return items;
		}

		let shownAlerts = [];

		if (hideReadAlerts && hideClearedAlerts) {
			shownAlerts = _.filter(items, (item) => {
				return item.AlertStatus !== 2 && !item.IsCleared;
			});
		} else if (hideReadAlerts) {
			shownAlerts = _.filter(items, (item) => {
				return item.AlertStatus !== 2;
			});

		} else if (hideClearedAlerts) {
			shownAlerts = _.filter(items, (item) => {
				return !item.IsCleared;
			});
		}

		return shownAlerts;
	}
}