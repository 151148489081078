import { elapsed, find } from '@datadog/browser-core';
import { isElementNode } from '../../../browser/htmlDomUtils';
import { createPerformanceObservable, RumPerformanceEntryType } from '../../../browser/performanceObservable';
import { getSelectorFromElement } from '../../getSelectorFromElement';
/**
 * Track the first input occurring during the initial View to return:
 * - First Input Delay
 * - First Input Time
 * Callback is called at most one time.
 * Documentation: https://web.dev/fid/
 * Reference implementation: https://github.com/GoogleChrome/web-vitals/blob/master/src/getFID.ts
 */
export function trackFirstInput(configuration, firstHidden, callback) {
  var performanceFirstInputSubscription = createPerformanceObservable(configuration, {
    type: RumPerformanceEntryType.FIRST_INPUT,
    buffered: true
  }).subscribe(function (entries) {
    var firstInputEntry = find(entries, function (entry) {
      return entry.startTime < firstHidden.timeStamp;
    });
    if (firstInputEntry) {
      var firstInputDelay = elapsed(firstInputEntry.startTime, firstInputEntry.processingStart);
      var firstInputTargetSelector = void 0;
      if (firstInputEntry.target && isElementNode(firstInputEntry.target)) {
        firstInputTargetSelector = getSelectorFromElement(firstInputEntry.target, configuration.actionNameAttribute);
      }
      callback({
        // Ensure firstInputDelay to be positive, see
        // https://bugs.chromium.org/p/chromium/issues/detail?id=1185815
        delay: firstInputDelay >= 0 ? firstInputDelay : 0,
        time: firstInputEntry.startTime,
        targetSelector: firstInputTargetSelector
      });
    }
  });
  return {
    stop: function () {
      performanceFirstInputSubscription.unsubscribe();
    }
  };
}
