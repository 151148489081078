import { Injectable } from '@angular/core';
import { Observable } from 'rxjs'; //Observable';
import { of } from 'rxjs'; //observable/of';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  public clear = () => {
    sessionStorage.clear();
  };

  public saveOptions = (
    key: string,
    options: any,
    storageType: 'session' | 'local' = 'session'
  ) => {
    const storage = storageType === 'session' ? sessionStorage : localStorage;
    storage.setItem(key, JSON.stringify(options));
  };

  public getOptions = (key: string): Record<string, any> => {
    let options = sessionStorage.getItem(key);
    if (!options) {
      options = localStorage.getItem(key);
    }
    const result: Record<string, any> = !options
      ? JSON.parse('{}')
      : JSON.parse(options);
    return result;
  };
}
