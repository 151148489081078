import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TxAttributes, RentalAssetCard, EquipmentAsset } from '../models';
import { RentalAssetBaseComponent } from './rental-asset-base.component';
import { SelectedAssetService } from './selected-asset.service';
import { RentalAssetService } from './rental-asset.service';
import { TranslateService } from '@ngx-translate/core';
import {
  ActiveFilterService,
  PageOptions,
  SortOptions,
  BreadcrumbService,
  ViewService,
  ClientProfileService,
  GridSortingService,
  LocaleService,
} from '../core';

@Component({
  selector: 'app-rental-asset-dashboard',
  templateUrl: 'rental-asset-dashboard.component.html',
  styleUrls: ['rental-asset.component.scss'],
})
export class RentalAssetDashboardComponent
  extends RentalAssetBaseComponent
  implements OnInit, OnDestroy
{
  private clientAttributes = new TxAttributes({
    IsTrial: true,
  } as TxAttributes);
  protected loading = true;
  private selectedAsset: RentalAssetCard;
  protected data: EquipmentAsset;
  paging: PageOptions = new PageOptions();
  sorting: SortOptions;
  private gridName: string = 'RENTAL_ASSET_DASHBOARD';
  protected locale: string = '';

  constructor(
    private router: Router,
    private viewService: ViewService,
    private rentalAssetService: RentalAssetService,
    public filterService: ActiveFilterService,
    private route: ActivatedRoute,
    private clientProfileService: ClientProfileService,
    selectedAssetService: SelectedAssetService,
    breadcrumbService: BreadcrumbService,
    protected translateService: TranslateService,
    private gridSortingService: GridSortingService,
    private localeService: LocaleService
  ) {
    super(selectedAssetService, breadcrumbService);
    this.locale = this.localeService.getLocale();
  }

  ngOnInit() {
    this.gridSortingService.setGridName(this.gridName);
    this.sorting = this.gridSortingService.getSortOptions();

    const component = this; // eslint-disable-line @typescript-eslint/no-this-alias
    component.subscriptions.push(
      component.clientProfileService
        .getClientAttributes()
        .subscribe((attributes) => (component.clientAttributes = attributes))
    );
    component.subscriptions.push(
      component.selectedAssetService.Asset.subscribe((x) => {
        component.selectedAsset = x;
        component.load();
      })
    );
    component.subscriptions.push(
      component.selectedAssetService.assetChange.subscribe(
        (x: RentalAssetCard) => {
          if (component.selectedAsset.RouseEquipmentID !== x.RouseEquipmentID) {
            component.selectedAsset = x;
            component.load();
          }
        }
      )
    );
    component.subscriptions.push(
      component.filterService.filterChange.subscribe(component.load)
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private load = () => {
    this.loading = true;
    this.sorting.sortOn = this.gridSortingService.getSortFieldOrDefault();
    this.sorting.descending =
      this.gridSortingService.getSortDirectionOrDefault() === -1;
    this.rentalAssetService
      .getProductTypeEquipment(
        this.paging,
        this.sorting,
        this.selectedAsset.RouseEquipmentID.toString()
      )
      .subscribe((result) => {
        if (result && result.Items.length) {
          this.data = result.Items[0];
        }
        this.loading = false;
      });
    this.updateBreadcrumbs([
      { title: 'main.core.common.dashboard', class: 'active' },
    ]);
  };

  getDaysInSameStatusQty(): number | string {
    let result: number | string = '-';
    const dany = this.data as any;
    if (dany) {
      if (dany.DaysInSameStatus > 90) {
        result = this.translateService.instant(
          'main.tabs.equipment.rental_assets.current_rental_status.more_than_90'
        );
      } else {
        result = dany.DaysInSameStatus;
      }
    }
    return result;
  }
}
