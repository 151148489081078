import { Component, ElementRef, Input, OnDestroy } from '@angular/core';
import {
    HighchartsConfiguration,
    ChartType,
    ChartSwitcher,
    RentalGrowthData,
    RentalGrowthType,
    DailyRatesChartService,
    HourlyRatesChartService,
    UnitsOnRentChartService,
    UnitsInFleetChartService,
    PhysicalUtilChartService,
    DollarUtilChartService,
    MonthlyRatesChartService,
    WeeklyRatesChartService,
    FormatService,
    MathService
} from './../../../core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'rdo-rental-growth-chart',
    templateUrl: 'rental-growth-chart.html',
    styleUrls: ['rental-growth-charts.scss'],
    providers: [MathService, FormatService]
})
export class RentalGrowthChartComponent implements OnDestroy {
    chartConfig: HighchartsConfiguration = new HighchartsConfiguration();
    private chartSwitcher: ChartSwitcher;
    private chartLoading: boolean = true;
    private data: Array<RentalGrowthData>;

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('chart-data') set chartData(data: Array<RentalGrowthData>) {
        this.data = null;
        if (data != null) {
            this.data = data;
            this.chartSwitcher = new ChartSwitcher(ChartType.RentalGrowthUnitsOnRent);
            this.chartSwitcher.chartChanged.subscribe(item => this.onChartChanged(item));
            this.loadChart(this.chartSwitcher.selectedChart);
        }
    }

    constructor(
        private element: ElementRef,
        private unitsOnRentChartService: UnitsOnRentChartService,
        private unitsInFleetChartService: UnitsInFleetChartService,
        private physicalUtilChartService: PhysicalUtilChartService,
        private dollarUtilChartService: DollarUtilChartService,
        private monthlyRatesChartService: MonthlyRatesChartService,
        private weeklyRatesChartService: WeeklyRatesChartService,
        private dailyRatesChartService: DailyRatesChartService,
        private hourlyRatesChartService: HourlyRatesChartService,
        private formatService: FormatService,
        private mathService: MathService,
        private translateService: TranslateService
    ) { }

    ngOnDestroy() {
        if (this.chartSwitcher) {
            this.chartSwitcher.chartChanged.unsubscribe();
        }
    }

    private loadChart(chartType: ChartType): void {
        if (this.data != null) {
            const component = this; // eslint-disable-line @typescript-eslint/no-this-alias
            this.chartLoading = true;
            const chart = {
                height: 280,
                width: this.element.nativeElement.parentElement.offsetWidth,
                type: 'column',
                backgroundColor:'#ffffff'
            };
            switch (chartType) {
                case ChartType.RentalGrowthUnitsOnRent: {
                    this.chartConfig = this.unitsOnRentChartService.loadChart(chart, this.data);
                    this.chartConfig.exportingOptions = 
                    {            
                        getColumnsConfig: () => {
                           return [{
                                    field: 'Month',
                                    displayName: component.translateService.instant('main.core.charts.export.month'),
                                    customFormat: (rowValues) => { return component.formatService.formatDate(rowValues.dateFrom, 'MMM yyyy')}
                                },
                                {
                                    field: 'OnRentMoMClient',
                                    displayName: component.translateService.instant('main.core.charts.export.client_percent'),
                                    isPercent: true,
                                    customFormat: (rowValues) => { return rowValues.unitsOnRent.ClientGrowth; }
                                },
                                {
                                    field: 'OnRentMoMBench',
                                    displayName: component.translateService.instant('main.core.charts.export.benchmark_percent'),
                                    customFormat: (rowValues) => { return rowValues.unitsOnRent.BenchmarkGrowth;},
                                    isPercent: true
                                },
                                {
                                    field: 'OnRentMoMDifference',
                                    displayName: component.translateService.instant('main.core.charts.export.difference_percent_pts'),
                                    customFormat: (rowValues) => { return rowValues.unitsOnRent.CvsBGrowthPointsDiff;},
                                    isPercent: true
                                }]
                        }         ,
                        getFileName: () => component.translateService.instant('main.tabs.equipment.product_types.change.charts.client_relative_rentals.title') 
                    }
                    break;
                }
                case ChartType.RentalGrowthUnitsInFleet:
                    {
                        this.unitsInFleetChartService.setTooltipFunc({
                            backgroundColor: null,
                            borderWidth: 0,
                            shadow: false,
                            style: {
                                padding: 0
                            },
                            positioner: (boxWidth, boxHeight, point) =>
                                component.unitsInFleetChartService.getTooltipPositionCustom(chart, point, 100, 345, 0),
                            formatter: function () {
                                const obj: RentalGrowthData = this.x;
                                return `<div class="rental-growth-tooltip-chart">
                            <table class="table rdo-table-tooltip">
                                <tr>
                                    <td style="border-right: 1px solid #ddd !important;"></td>
                                    <td style="border-right: 1px solid #ddd !important;"><strong>
                                        ${component.translateService.instant('main.core.charts.client')}
                                    </strong></td>
                                    <td style="border-right: 1px solid #ddd !important;"><strong>
                                        ${component.translateService.instant('main.core.charts.bench')}
                                    </strong></td>
                                    <td><strong>
                                        ${component.translateService.instant('main.core.charts.diff')}
                                    </strong></td>
                                </tr>
                                <tr>
                                    <td style="border-right: 1px solid #ddd !important;">
                                        <span>${obj.type === RentalGrowthType.yearly ?
                                            component.translateService.instant(
                                                'main.tabs.equipment.product_types.change.charts.on_rent_and_in_fleet.on_rent_yoy')
                                            : component.translateService.instant(
                                                'main.tabs.equipment.product_types.change.charts.on_rent_and_in_fleet.on_rent_mom')}
                                        </span>
                                    </td>
                                    <td style="border-right: 1px solid #ddd !important;" class="${
                                    obj.unitsOnRent.ClientGrowth < 0 ? 'text-danger' : ''}">
                                        ${component.formatService.formatPercent(obj.unitsOnRent.ClientGrowth)}
                                    </td>
                                     <td style="border-right: 1px solid #ddd !important;" class="${
                                    (obj.unitsOnRent.BenchmarkGrowth) < 0 ? 'text-danger' : ''}">
                                        ${component.formatService.formatPercent(obj.unitsOnRent.BenchmarkGrowth)}
                                    </td>
                                    <td class="${obj.unitsOnRent.CvsBGrowthPointsDiff < 0 ? 'text-danger' : ''}">
                                        ${component.formatService.formatNumber(obj.unitsOnRent.CvsBGrowthUnitsDiff, 1)}&nbsp;
                                        ${component.translateService.instant('main.core.common.counts.units')} ${' / '}
                                        ${component.formatService.formatPercent(obj.unitsOnRent.CvsBGrowthPointsDiff)}&nbsp;
                                        ${component.translateService.instant('main.core.common.counts.pts')}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="border-right: 1px solid #ddd !important;">
                                        <span>
                                            ${obj.type === RentalGrowthType.yearly ?
                                                component.translateService.instant(
                                                    'main.tabs.equipment.product_types.change.charts.on_rent_and_in_fleet.in_fleet_yoy')
                                                : component.translateService.instant(
                                                    'main.tabs.equipment.product_types.change.charts.on_rent_and_in_fleet.in_fleet_mom')}
                                        </span>
                                    </td>
                                    <td style="border-right: 1px solid #ddd !important;" class="${
                                    obj.unitsOnFleet.ClientGrowth < 0 ? 'text-danger' : ''}">
                                        ${component.formatService.formatPercent(obj.unitsOnFleet.ClientGrowth)}
                                    </td>
                                     <td style="border-right: 1px solid #ddd !important;" class="${
                                    obj.unitsOnFleet.BenchmarkGrowth < 0 ? 'text-danger' : ''}">
                                        ${component.formatService.formatPercent(obj.unitsOnFleet.BenchmarkGrowth)}
                                    </td>
                                    <td class="${obj.unitsOnFleet.CvsBGrowthPointsDiff < 0 ? 'text-danger' : ''}">
                                        ${component.formatService.formatNumber(obj.unitsOnFleet.CvsBGrowthUnitsDiff, 1)}&nbsp;
                                        ${component.translateService.instant('main.core.common.counts.units')} ${' / '}
                                        ${component.formatService.formatPercent(obj.unitsOnFleet.CvsBGrowthPointsDiff)}&nbsp;
                                        ${component.translateService.instant('main.core.common.counts.pts')}
                                    </td>
                                </tr>
                                 <tr>
                                    <td style="border-right: 1px solid #ddd !important;">
                                        <span>
                                            ${component.translateService.instant('main.core.charts.diff')}
                                        </span>
                                    </td>
                                    <td style="border-right: 1px solid #ddd !important;" class="${
                                    (obj.unitsOnRent.ClientGrowth - obj.unitsOnFleet.ClientGrowth) < 0 ? 'text-danger' : ''}">
                                        ${component.formatService
                                        .formatPercent(component.mathService.substract(obj.unitsOnRent
                                            .ClientGrowth, obj.unitsOnFleet.ClientGrowth, 1))}&nbsp;
                                            ${component.translateService.instant('main.core.common.counts.pts')}
                                    </td>
                                     <td style="border-right: 1px solid #ddd !important;" class="${
                                    (obj.unitsOnRent.BenchmarkGrowth - obj.unitsOnFleet.BenchmarkGrowth) < 0 ? 'text-danger' : ''}">
                                        ${component.formatService
                                        .formatPercent(component.mathService.substract(obj.unitsOnRent.BenchmarkGrowth,
                                            obj.unitsOnFleet.BenchmarkGrowth,
                                            1))}&nbsp;${component.translateService.instant('main.core.common.counts.pts')}
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                                </table>
                            </div>`;
                            },
                            shared: false,
                            useHTML: true
                        });
                        this.chartConfig = this.unitsInFleetChartService.loadChart(chart, this.data);
                        this.chartConfig.exportingOptions = 
                        {            
                            getColumnsConfig: () => {
                            return [{
                                        field: 'Month',
                                        displayName: component.translateService.instant('main.core.charts.export.month'),
                                        customFormat: (rowValues) => { return component.formatService.formatDate(rowValues.dateFrom, 'MMM yyyy')}
                                    },
                                    {
                                        field: 'OnRentMoMClient',
                                        displayName: (this.data && this.data[0].type === RentalGrowthType.monthly) ?
                                            component.translateService.instant('main.core.charts.export.client_on_rent_mom_growth_percent')	 : 
                                            component.translateService.instant('main.core.charts.export.client_on_rent_yoy_growth_percent'),
                                        isPercent: true,
                                        customFormat: (rowValues) => { return rowValues.unitsOnRent.ClientGrowth; }
                                    },
                                    {
                                        field: 'InFleetMoMClient',
                                        displayName: (this.data && this.data[0].type === RentalGrowthType.monthly) ?
                                            component.translateService.instant('main.core.charts.export.client_in_fleet_mom_growth_rate_percent'): 
                                            component.translateService.instant('main.core.charts.export.client_in_fleet_yoy_growth_rate_percent'),
                                        isPercent: true,
                                        customFormat: (rowValues) => { return rowValues.unitsOnFleet.ClientGrowth; }
                                    },
                                    {
                                        field: 'DiffClient',
                                        displayName: component.translateService.instant('main.core.charts.export.diff_btw_client_on_rent_and_in_fleet'),
                                        customFormat: (rowValues) => { return rowValues.unitsOnRent.ClientGrowth - rowValues.unitsOnFleet.ClientGrowth;},
                                        isPercent: true
                                    },
                                    {
                                        field: 'OnRentMoMBench',
                                        displayName: (this.data && this.data[0].type === RentalGrowthType.monthly) ?
                                            component.translateService.instant('main.core.charts.export.bench_on_rent_mom_growth_rate_percent'): 
                                            component.translateService.instant('main.core.charts.export.bench_on_rent_yoy_growth_rate_percent'),
                                        customFormat: (rowValues) => { return rowValues.unitsOnRent.BenchmarkGrowth;},
                                        isPercent: true
                                    },
                                    {
                                        field: 'InFleetMoMBench',
                                        displayName: (this.data && this.data[0].type === RentalGrowthType.monthly) ?
                                            component.translateService.instant('main.core.charts.export.bench_in_fleet_mom_growth_rate_percent'): 
                                            component.translateService.instant('main.core.charts.export.bench_in_fleet_yoy_growth_rate_percent'),                                        
                                        customFormat: (rowValues) => { return rowValues.unitsOnFleet.BenchmarkGrowth;},
                                        isPercent: true
                                    },
                                    {
                                        field: 'DiffBench',
                                        displayName: component.translateService.instant('main.core.charts.export.diff_btw_bench_on_rent_and_bench_in_fleet_growth_rate'),
                                        customFormat: (rowValues) => { return rowValues.unitsOnRent.BenchmarkGrowth - rowValues.unitsOnFleet.BenchmarkGrowth;},
                                        isPercent: true
                                    },
                                    {
                                        field: 'OnRentMoMDifference',
                                        displayName: component.translateService.instant('main.core.charts.export.diff_btw_client_on_rent_and_bench'),
                                        customFormat: (rowValues) => { return rowValues.unitsOnRent.CvsBGrowthPointsDiff;},
                                        isPercent: true
                                    },                                                                        
                                    {
                                        field: 'InFleetMoMDifference',
                                        displayName: component.translateService.instant('main.core.charts.export.diff_btw_client_in_fleet_and_bench'),
                                        customFormat: (rowValues) => { return rowValues.unitsOnFleet.CvsBGrowthPointsDiff;},
                                        isPercent: true
                                    }]
                            }         ,
                            getFileName: () => component.translateService.instant('main.tabs.equipment.product_types.change.charts.on_rent_and_in_fleet.title') 
                        }
                        break;
                    }
                case ChartType.RentalGrowthPhysicalUtil: {
                    this.chartConfig = this.physicalUtilChartService.loadChart(chart, this.data);
                    this.chartConfig.exportingOptions = 
                        {            
                            getColumnsConfig: () => {
                            return [{
                                        field: 'Month',
                                        displayName: component.translateService.instant('main.core.charts.export.month'),
                                        customFormat: (rowValues) => { return component.formatService.formatDate(rowValues.dateFrom, 'MMM yyyy')}
                                    },
                                    {
                                        field: 'CurrentMonthClient',
                                        displayName: (this.data && this.data[0].type === RentalGrowthType.monthly) ?
                                            component.translateService.instant('main.core.charts.export.curr_month_client_percent') : 
                                            component.translateService.instant('main.core.charts.export.curr_year_client_percent'),
                                        isPercent: true,
                                        customFormat: (rowValues) => { return rowValues.physicalUtil.ClientAvg; }
                                    },
                                    {
                                        field: 'CurrentMonthBench',
                                        displayName: (this.data && this.data[0].type === RentalGrowthType.monthly) ?
                                            component.translateService.instant('main.core.charts.export.curr_month_bench_percent') : 
                                            component.translateService.instant('main.core.charts.export.curr_year_bench_percent'),                                        
                                        customFormat: (rowValues) => { return rowValues.physicalUtil.BenchmarkAvg;},
                                        isPercent: true
                                    },
                                    {
                                        field: 'CurrentMonthDifference',
                                        displayName: (this.data && this.data[0].type === RentalGrowthType.monthly) ?
                                            component.translateService.instant('main.core.charts.export.curr_month_diff_percent_pts') : 
                                            component.translateService.instant('main.core.charts.export.curr_year_diff_percent_pts'),                                         
                                        customFormat: (rowValues) => { return rowValues.physicalUtil.ClientAvg - rowValues.physicalUtil.BenchmarkAvg;},
                                        isPercent: true
                                    },
                                    {
                                        field: 'PreviousMonthClient',
                                        displayName: (this.data && this.data[0].type === RentalGrowthType.monthly) ?
                                            component.translateService.instant('main.core.charts.export.prev_month_client_percent'): 
                                            component.translateService.instant('main.core.charts.export.prev_year_client_percent'),                                        
                                        isPercent: true,
                                        customFormat: (rowValues) => { return rowValues.physicalUtil.ClientAvg - rowValues.physicalUtil.ClientGrowth; }
                                    },
                                    {
                                        field: 'PreviousMonthBench',
                                        displayName: (this.data && this.data[0].type === RentalGrowthType.monthly) ?
                                            component.translateService.instant('main.core.charts.export.prev_month_bench_percent'): 
                                            component.translateService.instant('main.core.charts.export.prev_year_bench_percent'),                                         
                                        customFormat: (rowValues) => { return rowValues.physicalUtil.BenchmarkAvg - rowValues.physicalUtil.BenchmarkGrowth;},
                                        isPercent: true
                                    },
                                    {
                                        field: 'PreviousMonthDifferent',
                                        displayName: (this.data && this.data[0].type === RentalGrowthType.monthly) ?
                                            component.translateService.instant('main.core.charts.export.prev_month_diff_percent_pts'): 
                                            component.translateService.instant('main.core.charts.export.prev_year_diff_percent_pts'),
                                        customFormat: (rowValues) => { return (rowValues.physicalUtil.ClientAvg - rowValues.physicalUtil.ClientGrowth) -
                                            (rowValues.physicalUtil.BenchmarkAvg - rowValues.physicalUtil.BenchmarkGrowth);},
                                        isPercent: true
                                    },
                                    {
                                        field: 'MoMClient',
                                        displayName: (this.data && this.data[0].type === RentalGrowthType.monthly) ?
                                            component.translateService.instant('main.core.charts.export.mom_change_client_percent'): 
                                            component.translateService.instant('main.core.charts.export.yoy_change_client_percent'),
                                        customFormat: (rowValues) => { return rowValues.physicalUtil.ClientGrowth;},
                                        isPercent: true
                                    },
                                    {
                                        field: 'MoMBench',
                                        displayName: (this.data && this.data[0].type === RentalGrowthType.monthly) ?
                                            component.translateService.instant('main.core.charts.export.mom_change_bench_percent'): 
                                            component.translateService.instant('main.core.charts.export.yoy_change_bench_percent'),
                                        customFormat: (rowValues) => { return rowValues.physicalUtil.BenchmarkGrowth;},
                                        isPercent: true
                                    },
                                    {
                                        field: 'MoMDifference',
                                        displayName: (this.data && this.data[0].type === RentalGrowthType.monthly) ?
                                            component.translateService.instant('main.core.charts.export.mom_change_diff_percent_pts'): 
                                            component.translateService.instant('main.core.charts.export.yoy_change_diff_percent_pts'),
                                        customFormat: (rowValues) => { return rowValues.physicalUtil.CvsBGrowthPointsDiff;},
                                        isPercent: true
                                    }]
                            }         ,
                            getFileName: () => component.translateService.instant('main.tabs.equipment.product_types.change.table.physical_utilization') 
                        }
                    break;
                }
                case ChartType.RentalGrowthDollarUtil: {
                    this.chartConfig = this.dollarUtilChartService.loadChart(chart, this.data);
                    this.chartConfig.exportingOptions = 
                        {            
                            getColumnsConfig: () => {
                            return [{
                                        field: 'Month',
                                        displayName: component.translateService.instant('main.core.charts.export.month'),
                                        customFormat: (rowValues) => { return component.formatService.formatDate(rowValues.dateFrom, 'MMM yyyy')}
                                    },
                                    {
                                        field: 'CurrentMonthClient',
                                        displayName: (this.data && this.data[0].type === RentalGrowthType.monthly) ?
                                        component.translateService.instant('main.core.charts.export.curr_month_client_percent'): 
                                        component.translateService.instant('main.core.charts.export.curr_year_client_percent'),
                                        isPercent: true,
                                        customFormat: (rowValues) => { return rowValues.financialUtil.ClientAvg; }
                                    },
                                    {
                                        field: 'CurrentMonthBench',
                                        displayName: (this.data && this.data[0].type === RentalGrowthType.monthly) ?
                                        component.translateService.instant('main.core.charts.export.curr_month_bench_percent'): 
                                        component.translateService.instant('main.core.charts.export.curr_year_bench_percent'),
                                        customFormat: (rowValues) => { return rowValues.financialUtil.BenchmarkAvg;},
                                        isPercent: true
                                    },
                                    {
                                        field: 'CurrentMonthDifference',
                                        displayName: (this.data && this.data[0].type === RentalGrowthType.monthly) ?
                                        component.translateService.instant('main.core.charts.export.curr_month_diff_percent_pts'): 
                                        component.translateService.instant('main.core.charts.export.curr_year_diff_percent_pts'),
                                        customFormat: (rowValues) => { return rowValues.financialUtil.ClientAvg - rowValues.financialUtil.BenchmarkAvg;},
                                        isPercent: true
                                    },
                                    {
                                        field: 'PreviousMonthClient',
                                        displayName: (this.data && this.data[0].type === RentalGrowthType.monthly) ?
                                        component.translateService.instant('main.core.charts.export.prev_month_client_percent'): 
                                        component.translateService.instant('main.core.charts.export.prev_year_client_percent'),
                                        isPercent: true,
                                        customFormat: (rowValues) => { return rowValues.financialUtil.ClientAvg - rowValues.financialUtil.ClientGrowth; }
                                    },
                                    {
                                        field: 'PreviousMonthBench',
                                        displayName: (this.data && this.data[0].type === RentalGrowthType.monthly) ?
                                        component.translateService.instant('main.core.charts.export.prev_month_bench_percent'): 
                                        component.translateService.instant('main.core.charts.export.prev_year_bench_percent'),
                                        customFormat: (rowValues) => { return rowValues.financialUtil.BenchmarkAvg - rowValues.financialUtil.BenchmarkGrowth;},
                                        isPercent: true
                                    },
                                    {
                                        field: 'PreviousMonthDifferent',
                                        displayName: (this.data && this.data[0].type === RentalGrowthType.monthly) ?
                                        component.translateService.instant('main.core.charts.export.prev_month_diff_percent_pts'): 
                                        component.translateService.instant('main.core.charts.export.prev_year_diff_percent_pts'),
                                        customFormat: (rowValues) => { return (rowValues.financialUtil.ClientAvg - rowValues.financialUtil.ClientGrowth) -
                                            (rowValues.financialUtil.BenchmarkAvg - rowValues.financialUtil.BenchmarkGrowth);},
                                        isPercent: true
                                    },
                                    {
                                        field: 'MoMClient',
                                        displayName: (this.data && this.data[0].type === RentalGrowthType.monthly) ?
                                        component.translateService.instant('main.core.charts.export.mom_change_client_percent_pts'): 
                                        component.translateService.instant('main.core.charts.export.yoy_change_client_percent_pts'),
                                        customFormat: (rowValues) => { return rowValues.financialUtil.ClientGrowth;},
                                        isPercent: true
                                    },
                                    {
                                        field: 'MoMBench',
                                        displayName: (this.data && this.data[0].type === RentalGrowthType.monthly) ?
                                        component.translateService.instant('main.core.charts.export.mom_change_bench_percent_pts'): 
                                        component.translateService.instant('main.core.charts.export.yoy_change_bench_percent_pts'),
                                        customFormat: (rowValues) => { return rowValues.financialUtil.BenchmarkGrowth;},
                                        isPercent: true
                                    },
                                    {
                                        field: 'MoMDifference',
                                        displayName: (this.data && this.data[0].type === RentalGrowthType.monthly) ?
                                        component.translateService.instant('main.core.charts.export.mom_change_diff_percent_pts'): 
                                        component.translateService.instant('main.core.charts.export.yoy_change_diff_percent_pts'),
                                        customFormat: (rowValues) => { return rowValues.financialUtil.CvsBGrowthPointsDiff;},
                                        isPercent: true
                                    }]
                            }         ,
                            getFileName: () => component.translateService.instant('main.tabs.equipment.product_types.change.table.financial_utilization') 
                        }
                    break;
                }
                case ChartType.RentalGrowthMonthlyRates: {
                    this.chartConfig = this.monthlyRatesChartService.loadChart(chart, this.data);
                    this.chartConfig.exportingOptions = 
                    {            
                        getColumnsConfig: () => {
                           return [{
                                    field: 'Month',
                                    displayName: component.translateService.instant('main.core.charts.export.month'),
                                    customFormat: (rowValues) => { return component.formatService.formatDate(rowValues.dateFrom, 'MMM yyyy')}
                                },
                                {
                                    field: 'MonthlyRatesMoMClient',
                                    displayName: component.translateService.instant('main.core.charts.export.client_percent'),
                                    isPercent: true,
                                    customFormat: (rowValues) => { return rowValues.monthlyRentalRates.ClientGrowth; }
                                },
                                {
                                    field: 'MonthlyRatesMoMBench',
                                    displayName: component.translateService.instant('main.core.charts.export.benchmark_percent'),
                                    customFormat: (rowValues) => { return rowValues.monthlyRentalRates.BenchmarkGrowth;},
                                    isPercent: true
                                },
                                {
                                    field: 'MonthlyRatesMoMDifferent',
                                    displayName: component.translateService.instant('main.core.charts.export.difference_percent_pts'),
                                    customFormat: (rowValues) => { return rowValues.monthlyRentalRates.CvsBGrowthPointsDiff;},
                                    isPercent: true
                                }]
                        }         ,
                        getFileName: () => component.translateService.instant('main.tabs.equipment.product_types.change.table.monthly_rates') 
                    }
                    break;
                }
                case ChartType.RentalGrowthWeeklyRates: {
                    this.chartConfig = this.weeklyRatesChartService.loadChart(chart, this.data);
                    this.chartConfig.exportingOptions = 
                    {            
                        getColumnsConfig: () => {
                           return [{
                                    field: 'Month',
                                    displayName: component.translateService.instant('main.core.charts.export.month'),
                                    customFormat: (rowValues) => { return component.formatService.formatDate(rowValues.dateFrom, 'MMM yyyy')}
                                },
                                {
                                    field: 'WeeklyRatesMoMClient',
                                    displayName: component.translateService.instant('main.core.charts.export.client_percent'),
                                    isPercent: true,
                                    customFormat: (rowValues) => { return rowValues.weeklyRentalRates.ClientGrowth; }
                                },
                                {
                                    field: 'WeeklyRatesMoMBench',
                                    displayName: component.translateService.instant('main.core.charts.export.benchmark_percent'),
                                    customFormat: (rowValues) => { return rowValues.weeklyRentalRates.BenchmarkGrowth;},
                                    isPercent: true
                                },
                                {
                                    field: 'WeeklyRatesMoMDifferent',
                                    displayName: component.translateService.instant('main.core.charts.export.difference_percent_pts'),
                                    customFormat: (rowValues) => { return rowValues.weeklyRentalRates.CvsBGrowthPointsDiff;},
                                    isPercent: true
                                }]
                        }         ,
                        getFileName: () => component.translateService.instant('main.tabs.equipment.product_types.change.table.weekly_rates') 
                    }
                    break;
                }
                case ChartType.RentalGrowthDailyRates: {
                    this.chartConfig = this.dailyRatesChartService.loadChart(chart, this.data);
                    this.chartConfig.exportingOptions = 
                    {            
                        getColumnsConfig: () => {
                           return [{
                                    field: 'Month',
                                    displayName: component.translateService.instant('main.core.charts.export.month'),
                                    customFormat: (rowValues) => { return component.formatService.formatDate(rowValues.dateFrom, 'MMM yyyy')}
                                },
                                {
                                    field: 'DailyRatesMoMClient',
                                    displayName: component.translateService.instant('main.core.charts.export.client_percent'),
                                    isPercent: true,
                                    customFormat: (rowValues) => { return rowValues.dailyRentalRates.ClientGrowth; }
                                },
                                {
                                    field: 'DailyRatesMoMBench',
                                    displayName: component.translateService.instant('main.core.charts.export.benchmark_percent'),
                                    customFormat: (rowValues) => { return rowValues.dailyRentalRates.BenchmarkGrowth;},
                                    isPercent: true
                                },
                                {
                                    field: 'DailyRatesMoMDifferent',
                                    displayName: component.translateService.instant('main.core.charts.export.difference_percent_pts'),
                                    customFormat: (rowValues) => { return rowValues.dailyRentalRates.CvsBGrowthPointsDiff;},
                                    isPercent: true
                                }]
                        },
                        getFileName: () => component.translateService.instant('main.tabs.equipment.product_types.change.table.daily_rates') 
                    }
                    break;
                }
                case ChartType.RentalGrowthHourlyRates: {
                    this.chartConfig = this.hourlyRatesChartService.loadChart(chart, this.data);
                    this.chartConfig.exportingOptions = 
                    {            
                        getColumnsConfig: () => {
                           return [{
                                    field: 'Month',
                                    displayName: component.translateService.instant('main.core.charts.export.month'),
                                    customFormat: (rowValues) => { return component.formatService.formatDate(rowValues.dateFrom, 'MMM yyyy')}
                                },
                                {
                                    field: 'HourlyRatesMoMClient',
                                    displayName: component.translateService.instant('main.core.charts.export.client_percent'),
                                    isPercent: true,
                                    customFormat: (rowValues) => { return rowValues.hourlyRentalRates.ClientGrowth; }
                                },
                                {
                                    field: 'HourlyRatesMoMBench',
                                    displayName: component.translateService.instant('main.core.charts.export.benchmark_percent'),
                                    customFormat: (rowValues) => { return rowValues.hourlyRentalRates.BenchmarkGrowth;},
                                    isPercent: true
                                },
                                {
                                    field: 'HourlyRatesMoMDifferent',
                                    displayName: component.translateService.instant('main.core.charts.export.difference_percent_pts'),
                                    customFormat: (rowValues) => { return rowValues.hourlyRentalRates.CvsBGrowthPointsDiff;},
                                    isPercent: true
                                }]
                        }         ,
                        getFileName: () => component.translateService.instant('main.tabs.equipment.product_types.change.table.hourly_rates') 
                    }
                    break;
                }
            }
            this.chartLoading = false;
        }
    }

    private onChartChanged(chartType: ChartType): void {
        this.loadChart(chartType);
    }
}
