export class CurrentRate {
	ProductType: string;
	Description: string;
	MonthlyBookRevenueRate?: number;
	MonthlyFloorRevenueRate?: number;
	WeeklyBookRevenueRate?: number;
	WeeklyFloorRevenueRate?: number;
	DailyBookRevenueRate?: number;
	DailyFloorRevenueRate?: number;
	HourlyBookRevenueRate?: number;
	HourlyFloorRevenueRate?: number;
}