import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CoreModule } from './../core/core.module';
import { ChangesLogGuardService } from '../core';
import { ChangesLogComponent, ChangesLogEntryComponent, ChangesLogService } from '../changes-log';

const root = 'changes-log';
const changesLogRoutes: Routes = [
	{
		path: root,
		component: ChangesLogComponent,
		data: { title: 'main.tabs.changes_log.page_titles.root' },
		canActivate: [ChangesLogGuardService]
	}
];
@NgModule({
	imports: [
		CurrencyMaskModule,
		RouterModule
			.forChild(changesLogRoutes), FormsModule, CommonModule, CoreModule],
	declarations: [
		ChangesLogComponent,
		ChangesLogEntryComponent
	],
	providers: [
		ChangesLogService,
		ChangesLogGuardService
	],
	exports: [ChangesLogEntryComponent]
})
export class ChangesLogModule { }
