import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MetricsHttpService } from '../core';

@Injectable({
  providedIn: 'root'
})
export class UnsubscribeService {

  constructor(private metricsHttpService: MetricsHttpService, private translateService: TranslateService) { }

  unsubscribeUserFromEmailList(hash: string) {
    return this.metricsHttpService.postWithoutAuthentication(`exports/schedule/unsubscribe/${hash}`);
  }

  setLanguages(lang: any = 'en-US') {
    // if (!this.translateService.langs.includes(this.translateService.getBrowserCultureLang())) {
    //   this.translateService.use('en-US');
    // }
    const supportedLanguages = ['en-US','fr-CA','de-DE','ja-JP', 'en-GB'];
    if (!supportedLanguages.includes(lang)) {
      lang = 'en-US';
    }
    this.translateService.use(lang);
    this.translateService.setTranslation('en-US', {
      unsubscribe_page_title: 'Unsubscribe',
      unsubscribe_fail: 'Your request to unsubscribe was not successful. You can try forwarding the email you’d like to unsubscribe from to your administrator for assistance.',
      unsubscribe_success: 'Your request to unsubscribe was successful. Your email has been removed from the list of recipients scheduled to receive notifications when this report is run in the future.'
    }, true);
    this.translateService.setTranslation('de-DE', {
      unsubscribe_page_title: 'Abmelden',
      unsubscribe_fail: 'Ihre Abmeldeanfrage war nicht erfolgreich. Sie können versuchen, die E-Mail, von der Sie sich abmelden möchten, an Ihren Administrator weiterzuleiten, um Hilfe zu erhalten.',
      unsubscribe_success: 'Ihre Abmeldeanfrage war erfolgreich. Ihre E-Mail-Adresse wurde aus der Liste der Empfänger entfernt, die Benachrichtigungen erhalten sollen, wenn dieser Bericht in Zukunft ausgeführt wird.'
    }, true);
    this.translateService.setTranslation('fr-CA', {
      unsubscribe_page_title: 'Se désinscrire',
      unsubscribe_fail: "Votre demande de désabonnement n'a pas abouti. Vous pouvez essayer de transférer l'e-mail dont vous souhaitez vous désabonner à votre administrateur pour obtenir de l'aide.",
      unsubscribe_success: "Votre demande de désabonnement a réussi. Votre e-mail a été supprimé de la liste des destinataires devant recevoir des notifications lorsque ce rapport sera exécuté à l'avenir."
    }, true);
    this.translateService.setTranslation('ja-JP', {
      unsubscribe_page_title: '購読を解除する',
      unsubscribe_fail: '購読解除のリクエストは成功しませんでした。 購読を解除したいメールを管理者に転送してサポートを求めることができます。',
      unsubscribe_success: '購読解除リクエストは成功しました。 あなたの電子メールは、今後このレポートが実行されるときに通知を受信する予定の受信者のリストから削除されました。'
    }, true);
    this.translateService.setTranslation('en-GB', {
      unsubscribe_page_title: 'Unsubscribe',
      unsubscribe_fail: 'Your request to unsubscribe was not successful. You can try forwarding the email you’d like to unsubscribe from to your administrator for assistance.',
      unsubscribe_success: 'Your request to unsubscribe was successful. Your email has been removed from the list of recipients scheduled to receive notifications when this report is run in the future.'
    }, true);
  }
}
