import { Component, Input, ElementRef, OnInit } from '@angular/core';
import { RevenueChartHelperService, HighchartsConfiguration } from './../base';
import { FormatService, ViewService } from './../../query';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { CHART_COLORS } from '../base/chart-color-constants';

enum LEGEND_TYPE {
    CLIENT = 'client', BENCHMARK = 'benchmark', DIFFERENCE = 'difference',
    REVENUE = 'revenue', OF_RENTAL_REV = 'of_rental_rev', OF_RENTAL_REVENUE = 'of_rental_revenue', 
    INVOICED_REVENUE = 'invoiced_revenue', ANCILLARY_FEES = 'ancillary_fees'
}

@Component({
    selector: 'rdo-revenue-distribution-chart',
    styleUrls: ['./../base/revenue-chart-helper.scss'],
    templateUrl: './revenue-distribution-chart.component.html'
})
export class RevenueDistributionChartComponent implements OnInit {
    private I18N_PATH = 'main.tabs.summary.charts.client_business_mix_vs_bench';
    chartConfig: HighchartsConfiguration;
    private chart;

    @Input() abbreviateCategories = false;
    private data = null;
    // eslint-disable-next-line  @angular-eslint/no-input-rename
    @Input('chart-data') set chartData(data: any) {
        this.data = data;
        if (data && this.totalMode != null) {
            this.loadChart(data);
        }
    }

    private totalMode = null;
    // eslint-disable-next-line  @angular-eslint/no-input-rename
    @Input('chart-type') set chartType(chartType: any) {
        if (chartType != null) {            
            this.totalMode = chartType === 'Total';            
        }
    }

    constructor(
        private element: ElementRef,
        private viewService: ViewService,
        private formatService: FormatService,
        private translateService: TranslateService,
        private revUtilChartService: RevenueChartHelperService
    ) {
        this.setupChartTranslation();
    }

    ngOnInit() {
        if (this.totalMode == null) {
            this.totalMode = false;
        }
        this.loadChart(this.data);
    }

    private setupChartTranslation() {
        this.translateService.stream(this.I18N_PATH).subscribe((i18n) => {
            if (this.chart) {
                this.chart.get(LEGEND_TYPE.OF_RENTAL_REVENUE).update({ title: { text: i18n[LEGEND_TYPE.OF_RENTAL_REVENUE] } });
                this.chart.get(LEGEND_TYPE.CLIENT).update({ name: i18n[LEGEND_TYPE.CLIENT] });
                this.chart.get(LEGEND_TYPE.BENCHMARK).update({ name: i18n[LEGEND_TYPE.BENCHMARK] });
            }
        });
    }

    private loadChart = (data: Array<any>) => {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const component = this;
        const chartItems = data;
        const colors = ['rgba(153, 168, 189,1)', '#000000', 'rgba(240,234,214,.3)'];
        const colorType = { client: 0, benchmark: 1, eggshell: 2 };
        if (component.abbreviateCategories) {
            chartItems.forEach(chartItem => {
                let formattedCategory = chartItem.Category;
                formattedCategory = formattedCategory.replace('Other Rate Type Revenue', 'Other Rate Revenue');
                formattedCategory = formattedCategory.replace('Unclassified Revenue', 'Uncl Revenue');
                formattedCategory = formattedCategory.replace('Environmental Fees', 'Env Fees');
                chartItem.Category = formattedCategory;
            });
        }
        const options = {
            chart: {
                height: component.element.nativeElement.parentElement.offsetHeight,
                width: component.element.nativeElement.parentElement.offsetWidth,
                type: 'column',
                marginTop: 22,
                events: {
                    load: function () {
                        const chartWidth = this.plotWidth;

                        const categoriesCount = chartItems.length;
                        const labelFontSize = '17px';

                        const sampleText = component.translateService.instant(component.I18N_PATH + '.' + LEGEND_TYPE.INVOICED_REVENUE);
                        const labelWidth = component.getTextWidth(sampleText, labelFontSize);
                        const sampleText2 = component.translateService.instant(component.I18N_PATH + '.' + LEGEND_TYPE.ANCILLARY_FEES);
                        const labelWidth2 = component.getTextWidth(sampleText2, labelFontSize);

                        const labelX1 = chartWidth * 0.31 - labelWidth / 2;
            
                        const labelX2 = chartWidth * 0.823 - labelWidth2 / 2;
            
                        this.renderer.label(sampleText, labelX1, -7)
                            .css({
                                color: '#7F7F7F',
                                'font-size': labelFontSize
                            })
                            .add();
            
                        this.renderer.label(sampleText2, labelX2, -7)
                            .css({
                                color: '#7F7F7F',
                                'font-size': labelFontSize
                            })
                            .add();
                    }
                }
            },
            yAxis: {
                id: LEGEND_TYPE.OF_RENTAL_REVENUE,
                labels: {
                    format: '{value}%'
                },
                minPadding: 0.2,
                title: {
                    text: component.translateService.instant(component.I18N_PATH + '.' + LEGEND_TYPE.OF_RENTAL_REVENUE),
                    style: {
						fontSize: '12px'
					}
                },
                tickInterval: 20,
                minorGridLineWidth: 0
            },
            xAxis: {
                categories: _.map(chartItems, i => i.Category),
                labels: {
                    useHTML: true,
                    formatter: function () {
                        if (component.abbreviateCategories) {
                            return this.value.replace(/ /g, '<br />');
                        }
                        return `<div style="text-align:center; overflow: hidden; text-overflow: ellipsis;">${this.value}</div>`;
                    }
                },
                plotBands: [{
                    color: colors[colorType.eggshell],
                    from: -.5,
                    to: 7.5
                }],
                plotLines: [
					{
						color: CHART_COLORS.COLOR_000000,
						width: 2,
						value: 7.5,
                        zIndex: 100
					}
				]
            },
            legend: {
				itemHiddenStyle: {
                    symbolOpacity: 0.3,
                    color: CHART_COLORS.TRANSPARENCY,
					textDecoration: 'none'
				}
            },
            series: [{
                id: LEGEND_TYPE.CLIENT,
                name: component.translateService.instant(component.I18N_PATH + '.' + LEGEND_TYPE.CLIENT),
                //color: colors[colorType.client],
                color: CHART_COLORS.COLOR_5779A3,
                pointPlacement: -0.05,
                data: _.map(chartItems, i => [i.Category, (component.totalMode ? i.ClientTotalPercent : i.ClientPercent) * 100]),
                dataLabels: {
                    enabled: true,
                    crop: false,
                    overflow: 'none',
                    style: {
                        z: '1 !important'
                    },
                    align: 'center',
                    formatter: function () {
                        let dataProperty = 'ClientPercent';
                        if (component.totalMode) {
                            dataProperty = 'ClientTotalPercent';
                        }
                        if (this.y < 0) {                            
                            return `<div class="client-benchmark-data-label"><span class='text-danger' style="font-weight:normal;font-size:11px;">${component.formatService.formatPercent(_.find(chartItems, i => i.Category === this.x)[dataProperty])}</span></div>`;
                        } else {
                            return `<div class="client-benchmark-data-label"><span style="font-weight:normal; color: #4685BB;font-size:11px;">${component.formatService.formatPercent(_.find(chartItems, i => i.Category === this.x)[dataProperty])}</span></div>`;
                        }
                    },
                    useHTML: true
                }
            },
            ...(!component.totalMode ? [{
                id: LEGEND_TYPE.BENCHMARK,
                name: component.translateService.instant(component.I18N_PATH + '.' + LEGEND_TYPE.BENCHMARK),
                color: colors[colorType.benchmark],
                pointPlacement: 0.05,
                data: _.map(chartItems, i => [i.Category, i.BenchmarkPercent * 100]),
                dataLabels: {
                    enabled: true,
                    crop: false,
                    overflow: 'none',
                    style: {
                        z: '1 !important'
                    },
                    formatter: function () {
                        if (this.y < 0) {                            
                            return `<div class="client-benchmark-data-label"><span class='text-danger' style="font-weight:normal;font-size:11px;">${component.formatService.formatPercent(_.find(chartItems, i => i.Category === this.x).BenchmarkPercent)}</span></div>`;
                        } else {
                            return `<div class="client-benchmark-data-label">
                                    <span style="font-weight:normal;font-size:11px;">
                                    ${component.formatService.formatPercent(_.find(
                                        chartItems, i => i.Category === this.x).BenchmarkPercent)}
                                    </span></div>`;
                        }
                    },
                    useHTML: true
                }
            }] : [])
            ],
            tooltip: {
                backgroundColor: null,
                borderWidth: 0,
                shadow: false,
                style: {
                    padding: 0
                },
                positioner: (boxWidth, boxHeight, point) => {
                    return component.revUtilChartService.getTooltipPositionClientRateBenchmark(point);
                },
                formatter: function () {
                    const chartItem = _.find(chartItems, i => i.Category === this.x);
                    if (component.totalMode){
                        const markup = `<div class="client-rate-bench-chart">
                        <table class="table rdo-table-tooltip" style="text-align: right;">
                            <tr>
                            <th></th>
                            <th style="text-align: right;">${component.translateService.instant(
                                component.I18N_PATH + '.' + LEGEND_TYPE.REVENUE)}</th>
                            <th>${component.translateService.instant(component.I18N_PATH + '.' + LEGEND_TYPE.OF_RENTAL_REV)}</th>
                            </tr>
                            <tr>
                            <td style="text-align: left;"><span style="color:${colors[colorType.client]}">&#x25CF</span>
                            ${component.translateService.instant(component.I18N_PATH + '.' + LEGEND_TYPE.CLIENT)}:</td>
                            <td class='${(chartItem.ClientTotal < 0) ? 'text-danger' : ''}'>
                            ${component.formatService.formatCurrency(chartItem.ClientTotal, true)}</td>
                            <td class='${(chartItem.ClientTotalPercent < 0) ? 'text-danger' : ''}'>
                            ${component.formatService.formatPercent(chartItem.ClientTotalPercent)}</td>
                            </tr>                            
                        </table>
                        </div>`;
                        return markup;
                    }
                    else{
                        const markup = `<div class="client-rate-bench-chart">
                        <table class="table rdo-table-tooltip" style="text-align: right;">
                            <tr>
                            <th></th>
                            <th style="text-align: right;">${component.translateService.instant(
                                component.I18N_PATH + '.' + LEGEND_TYPE.REVENUE)}</th>
                            <th>${component.translateService.instant(component.I18N_PATH + '.' + LEGEND_TYPE.OF_RENTAL_REV)}</th>
                            </tr>
                            <tr>
                            <td style="text-align: left;"><span style="color:${colors[colorType.client]}">&#x25CF</span>
                            ${component.translateService.instant(component.I18N_PATH + '.' + LEGEND_TYPE.CLIENT)}:</td>
                            <td class='${(chartItem.Client < 0) ? 'text-danger' : ''}'>
                            ${component.formatService.formatCurrency(chartItem.Client, true)}</td>
                            <td class='${(chartItem.ClientPercent < 0) ? 'text-danger' : ''}'>
                            ${component.formatService.formatPercent(chartItem.ClientPercent)}</td>
                            </tr>
                            <tr>
                            <td style="text-align: left;"><span style="color:${colors[colorType.benchmark]}">&#x25CF</span>
                            ${component.translateService.instant(component.I18N_PATH + '.' + LEGEND_TYPE.BENCHMARK)}:</td>
                            <td>-</td>
                            <td class='${(chartItem.BenchmarkPercent < 0) ? 'text-danger' : ''}'>
                            ${component.formatService.formatPercent(chartItem.BenchmarkPercent)}</td>
                            </tr>
                            <tr></tr>
                            <tr>
                            <td style="text-align: left;"><span class='
                            ${(chartItem.Difference < 0) ? 'text-danger' : ''}'>&#x25CF</span>
                            ${component.translateService.instant(component.I18N_PATH + '.' + LEGEND_TYPE.DIFFERENCE)}:</td>
                            <td class='${(chartItem.Difference < 0) ? 'text-danger' : ''}'>
                            ${component.formatService.formatCurrency(chartItem.Difference, true)}</td>
                            <td class='${(chartItem.Difference < 0) ? 'text-danger' : ''}'>
                            ${component.formatService.formatPercent(chartItem.DifferencePercent)}
                                ${(chartItem.DifferencePercent === null) ? '' : (Math.abs(chartItem.DifferencePercent) > .01) ?
                                component.translateService.instant('main.core.common.counts.pts') :
                                component.translateService.instant('main.core.common.counts.pts')}
                                </td>
                            </tr>
                        </table>
                        </div>`;
                        return markup;
                    }                    
                },
                shared: true,
                useHTML: true
            },
            credits: {
                enabled: false
            },
            title: {
                text: null
            }
        };
        const config = new HighchartsConfiguration();
        config.exportingOptions = 
        {            
            getColumnsConfig: () => {     
                    if (component.totalMode) {
                        return [{
                            field: 'Metric',
                            displayName: component.translateService.instant('main.core.charts.export.metric'),
                            customFormat: (rowValues, fullData, index) => { return chartItems[index].Category;}
                        },
                        {
                            field: 'ClientCurrency',
                            displayName: component.translateService.instant('main.core.charts.export.client') + ` ${component.formatService.selectedCurrencySymbol}`,
                            customFormat: (rowValues, fullData, index) => { return chartItems[index].ClientTotal; }
                        },
                        {
                            field: 'ClientPercent',
                            displayName: component.translateService.instant('main.core.charts.export.client_percent'),
                            customFormat: (rowValues, fullData, index) => { return chartItems[index].ClientTotalPercent;},
                            isPercent: true
                        }];
                    } else {
                        return [{
                            field: 'Metric',
                            displayName: component.translateService.instant('main.core.charts.export.metric'),
                            customFormat: (rowValues, fullData, index) => { return chartItems[index].Category;}
                        },
                        {
                            field: 'ClientCurrency',
                            displayName: component.translateService.instant('main.core.charts.export.client') + ` ${component.formatService.selectedCurrencySymbol}`,
                            customFormat: (rowValues, fullData, index) => { return chartItems[index].Client; }
                        },
                        {
                            field: 'ClientPercent',
                            displayName: component.translateService.instant('main.core.charts.export.client_percent'),
                            customFormat: (rowValues, fullData, index) => { return chartItems[index].ClientPercent;},
                            isPercent: true
                        },
                        {
                            field: 'BenchmarkPercent',
                            displayName: component.translateService.instant('main.core.charts.export.benchmark_percent'),
                            customFormat: (rowValues, fullData, index) => { return chartItems[index].BenchmarkPercent;},
                            isPercent: true
                        },
                        {
                            field: 'DifferenceCurrency',
                            displayName: component.translateService.instant('main.core.charts.export.difference') + ` ${component.formatService.selectedCurrencySymbol}`,
                            customFormat: (rowValues, fullData, index) => { return chartItems[index].Difference;}
                        },
                        {
                            field: 'DifferencePercent',
                            displayName: component.translateService.instant('main.core.charts.export.difference_percent_pts'),
                            customFormat: (rowValues, fullData, index) => { return chartItems[index].DifferencePercent;},
                            isPercent: true
                        }];
                    }
            },
			getFileName: () => {
                if (component.totalMode) {
                    return this.translateService.instant('main.tabs.summary.charts.client_business_mix_vs_bench.title_total') 
                } else {
                    return this.translateService.instant('main.tabs.summary.charts.client_business_mix_vs_bench.title')    
                }
            }
        }
        config.options = options;
        config.onLoadCallback = (chart) => {
            this.chart = chart;
        }; 
        this.chartConfig = config;
    }
    public getTextWidth(text, fontSize) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = fontSize + ' Arial';
        return context.measureText(text).width;
    }
}
