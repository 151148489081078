export class TxAttributes {
	ExistsNationalAcct: boolean;
	ExistsSubstitution: boolean;
	ExistsRpo: boolean;
	ExistsContracts: boolean;
	ExistsSpecialPricing: boolean;
	IsTrial: boolean;
    ExistsReRent: boolean;
	DashedLineOffset: number;
	MaxProdTypeLen: number;
	MaxEquipmentLen: number;

	constructor(init?: Partial<TxAttributes>) {
		Object.assign(this, init);
	}
}