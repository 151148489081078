export class SelectableClient {

    public ClientID: number;
    public ClientCode: string;
    public ClientShortName: string;
    public Lang: string;
    public MQAAccess: boolean;
    public RDOAccess: boolean;
    public RDODownloads: boolean;
    public RDOExportData: boolean;
    public RDOCustomGridsEditor: boolean;
    public RDOClientCustomGridsEditor: boolean;
    public HasGeographyFullAccess: boolean;
    public HasSalesRepRole: boolean;
    public HasCustomGridsData: boolean;
    public AvailableCustomGridSources: Array<string>;
    public RequiresCompetitionDisclaimer: boolean;

    constructor(clientid: number,
                clientcode: string,
                clientshortname: string,
                lang: string,
                RDOAccess: boolean,
                MQAAccess: boolean,
                RDODownloads: boolean,
                RDOExportData: boolean,
                RDOCustomGridsEditor: boolean,
                RDOClientCustomGridsEditor: boolean,
                hasGeographyFullAccess: boolean,
                HasSalesRepRole: boolean,
                HasCustomGridsData: boolean,
                AvailableCustomGridSources: Array<string>,
                RequiresCompetitionDisclaimer: boolean) {

        this.ClientID = clientid;
        this.ClientCode = clientcode;
        this.ClientShortName = clientshortname;
        this.Lang = lang;
        this.RDOAccess = RDOAccess;
        this.MQAAccess = MQAAccess;
        this.RDODownloads = RDODownloads;
        this.RDOExportData = RDOExportData;
        this.RDOCustomGridsEditor = RDOCustomGridsEditor;
        this.RDOClientCustomGridsEditor = RDOClientCustomGridsEditor;
        this.HasGeographyFullAccess = hasGeographyFullAccess;
        this.HasSalesRepRole = HasSalesRepRole;
        this.HasCustomGridsData = HasCustomGridsData;
        this.AvailableCustomGridSources = AvailableCustomGridSources;
        this.RequiresCompetitionDisclaimer = RequiresCompetitionDisclaimer;
    }
}
