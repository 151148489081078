import { Component, ElementRef, ViewContainerRef, ChangeDetectionStrategy, QueryList, Renderer2, Injector, ValueProvider } from '@angular/core';
import { ComponentBase, IComponentBase, applyMixins, ComponentMixins, PropertyCollectionInfo, setValue } from '../../ej2-angular-base';
import { PivotFieldList } from '../../ej2-pivotview';



export const /** @type {?} */ inputs: string[] = ['aggregateTypes','allowCalculatedField','allowDeferLayoutUpdate','cssClass','dataSourceSettings','enablePersistence','enableRtl','loadOnDemandInMemberEditor','locale','maxNodeLimitInMemberEditor','renderMode','showValuesButton','spinnerTemplate','target'];
export const /** @type {?} */ outputs: string[] = ['aggregateCellInfo','aggregateMenuOpen','beforeServiceInvoke','calculatedFieldCreate','created','dataBound','destroyed','enginePopulated','enginePopulating','fieldDragStart','fieldDrop','fieldRemove','load','memberEditorOpen','memberFiltering','onFieldDropped'];
export const /** @type {?} */ twoWays: string[] = [];
/**
 * `ej-pivotfieldlist` represents the Angular PivotFieldList Component.
 * ```html
 * <ej-pivotfieldlist></ej-pivotfieldlist>
 * ```
 */
@ComponentMixins([ComponentBase])
export class PivotFieldListComponent extends PivotFieldList implements IComponentBase {
public context : any;
public tagObjects: any;
	aggregateCellInfo: any;
	aggregateMenuOpen: any;
	beforeServiceInvoke: any;
	calculatedFieldCreate: any;
	created: any;
	dataBound: any;
	destroyed: any;
	enginePopulated: any;
	enginePopulating: any;
	fieldDragStart: any;
	fieldDrop: any;
	fieldRemove: any;
	load: any;
	memberEditorOpen: any;
	memberFiltering: any;
public onFieldDropped: any;
/**
 * @param {?} ngEle
 * @param {?} srenderer
 * @param {?} viewContainerRef
 * @param {?} injector
 */
constructor(private ngEle: ElementRef,
private srenderer: Renderer2,
private viewContainerRef:ViewContainerRef,
private injector: Injector) {
        super();
        this.element = this.ngEle.nativeElement;
        this.injectedModules = this.injectedModules || [];
        try {
                let mod = this.injector.get('PivotViewCalculatedField');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }

        this.registerEvents(outputs);
        this.addTwoWay.call(this, twoWays);
        setValue('currentInstance', this, this.viewContainerRef);
        this.context  = new ComponentBase();
    }
/**
 * @return {?}
 */
public ngOnInit() {
        this.context.ngOnInit(this);
    }
/**
 * @return {?}
 */
public ngAfterViewInit(): void {
        this.context.ngAfterViewInit(this);
    }
/**
 * @return {?}
 */
public ngOnDestroy(): void {
        this.context.ngOnDestroy(this);
    }
/**
 * @return {?}
 */
public ngAfterContentChecked(): void {
        
        this.context.ngAfterContentChecked(this);
    }
public registerEvents: (eventList: string[]) => void;
public addTwoWay: (propList: string[]) => void;
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
    selector: 'ejs-pivotfieldlist',
    inputs: inputs,
    outputs: outputs,
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
    queries: {

    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
{type: Renderer2, },
{type: ViewContainerRef, },
{type: Injector, },
];
}

function PivotFieldListComponent_tsickle_Closure_declarations() {
/** @type {?} */
PivotFieldListComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
PivotFieldListComponent.ctorParameters;
/** @type {?} */
PivotFieldListComponent.prototype.context;
/** @type {?} */
PivotFieldListComponent.prototype.tagObjects;
/** @type {?} */
PivotFieldListComponent.prototype.aggregateCellInfo;
/** @type {?} */
PivotFieldListComponent.prototype.aggregateMenuOpen;
/** @type {?} */
PivotFieldListComponent.prototype.beforeServiceInvoke;
/** @type {?} */
PivotFieldListComponent.prototype.calculatedFieldCreate;
/** @type {?} */
PivotFieldListComponent.prototype.created;
/** @type {?} */
PivotFieldListComponent.prototype.dataBound;
/** @type {?} */
PivotFieldListComponent.prototype.destroyed;
/** @type {?} */
PivotFieldListComponent.prototype.enginePopulated;
/** @type {?} */
PivotFieldListComponent.prototype.enginePopulating;
/** @type {?} */
PivotFieldListComponent.prototype.fieldDragStart;
/** @type {?} */
PivotFieldListComponent.prototype.fieldDrop;
/** @type {?} */
PivotFieldListComponent.prototype.fieldRemove;
/** @type {?} */
PivotFieldListComponent.prototype.load;
/** @type {?} */
PivotFieldListComponent.prototype.memberEditorOpen;
/** @type {?} */
PivotFieldListComponent.prototype.memberFiltering;
/** @type {?} */
PivotFieldListComponent.prototype.onFieldDropped;
/** @type {?} */
PivotFieldListComponent.prototype.registerEvents;
/** @type {?} */
PivotFieldListComponent.prototype.addTwoWay;
/** @type {?} */
PivotFieldListComponent.prototype.ngEle;
/** @type {?} */
PivotFieldListComponent.prototype.srenderer;
/** @type {?} */
PivotFieldListComponent.prototype.viewContainerRef;
/** @type {?} */
PivotFieldListComponent.prototype.injector;
}



interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
