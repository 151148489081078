<rdo-overlay [enabled]="loading"></rdo-overlay>
<div id="rdo-grid">
    <rdo-grid-table-ng #rentalAssetsGrid *ngIf="selectedProductTypeService.isProductSelected && totalCount > 0"
        [name]="gridName" [gridConfig]="gridConfig" [pagedData]="data" [sorting]="sorting" [(paging)]="paging"
        (pageOnChange)="changePage($event)" (lazyLoadOnChange)="changeLazyLoad($event)" [totalCount]="totalCount"
        [frozenWidth]="frozenWidth" [showColumnSelector]="true" [scrollScale]="'smallScale'"
        (downloadExcelClick)="exportExcel($event)">
    </rdo-grid-table-ng>
</div>
<div class="no-data-available" *ngIf="!selectedProductTypeService.isProductSelected || totalCount === 0">
    {{'main.tabs.equipment.product_types.no_data_available' | translate}}
</div>