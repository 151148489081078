import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { ExportJob } from './model/export-job';
import { ExportJobStatus } from './model/export-job-status';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';
import { get, pick, set } from 'lodash';
import { finalize, take } from 'rxjs/operators';
// Interfaces
import firebase from 'firebase/compat/app';

import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// import firestore from 'firebase/firestore'
import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;
import WhereFilterOp = firebase.firestore.WhereFilterOp;
import DocRef = firebase.firestore.DocumentReference;
import {
    IMakeRequestOptions, IRequestOptions
} from '../interface';
import IdTokenResult = firebase.auth.IdTokenResult;
// Configs
import { environment } from '../../../environments/environment';
import { IFirestoreCondition, IFirestoreMethods, TFirestoreRef, IFirestoreListenPathStore } from '../interface';
import { MetricsHttpService } from '../http/metrics-http.service';
import { User } from 'firebase/auth';

@Injectable()
export class FirestoreService {
    public exportsUseCase: IFirestoreMethods;
    public trackedExports = new BehaviorSubject<Array<ExportJob>>([]);
    private readonly firestoreKeys: Array<string> = ['apiKey', 'appId', 'authDomain', 'databaseURL', 'measurementId', 'messagingSenderId', 'projectId', 'storageBucket'];
    private readonly firestoreKeysRequired: Array<string> = ['apiKey', 'appId', 'authDomain', 'databaseURL', 'messagingSenderId', 'projectId', 'storageBucket'];
    private readonly signOutTimeout: number = 1000;
    private readonly firestoreResetMarginSeconds: number = 120;
    private client: { [useCase: string]: firebase.app.App } = {};
    private identityTokens: { [useCase: string]: string } = {};
    private refreshTokens: { [useCase: string]: string } = {};
    private refreshFns: { [useCase: string]: () => Promise<string> } = {};
    private refreshTimers: { [useCase: string]: any } = {};
    private databases: { [useCase: string]: any } = {};
    private connected: { [useCase: string]: boolean } = {};
    private disconnectSubjects: { [useCase: string]: Subject<void> } = {};
    private listenPaths: IFirestoreListenPathStore = {};

    public get statMap(): { [docStatus: string]: string } {
        return {
            Working: 'Processing',
            Queued: 'Processing',
            Success: 'Done',
            Failure: 'Fail',
        };
    }

    constructor(private toasterService: ToastrService,
        private translateService: TranslateService,
        private metricsHttp: MetricsHttpService) {
        if ('firestore' in environment) {
            Object.keys(environment.firestore).forEach((useCase: string) => {
                this.initializeDb(useCase, environment.firestore[useCase]);
            });
            this.startUseCase('exports');
        }
    }
    /**
       * Used to start tracking a given export based on its path within firestore.
       * @param path
       */
    public trackExport(path: string): void {
        if ('exports' in this.identityTokens) {
            this.exportsUseCase.subscribeToPath(path).pipe(debounceTime(500)).subscribe(data => {
                if (data) {
                    const job: ExportJob = {
                        creation: data[0].creation,
                        file: data[0].file,
                        notify: true,
                        state_id: data[0].state_id,
                        status: ExportJobStatus[data[0].status],
                        user_id: data[0].user_id,
                        user_email: data[0].user_email,
                        expires: 0,
                        store_id: '',
                        id: data[1]
                    };
                    this.onJobChanged(job);
                }
            });
        }
        else {
            this.getToken(data => {
                const token = JSON.parse(data).fbt;
                if (!token) {
                    return;
                }

                this.signInWithToken(token)
                    .then(() => {
                        this.exportsUseCase.subscribeToPath(path).pipe(debounceTime(500)).subscribe(data => {
                            const job: ExportJob = {
                                creation: data[0].creation,
                                file: data[0].file,
                                notify: true,
                                state_id: data[0].state_id,
                                status: ExportJobStatus[data[0].status],
                                user_id: data[0].user_id,
                                user_email: data[0].user_email,
                                expires: 0,
                                store_id: '',
                                id: data[1]
                            };
                            this.onJobChanged(job);
                        });
                    })
                    .catch(err => {
                        throw err;
                    });
            });
        }

    }

    private getToken(cb): void {
        const url = `${environment.metricsApiUrl}/getFirestoreToken`;
        const options = { requestOptions: this.readyOptions() };
        this.makeRequest('post', url, options).subscribe(data => cb(data));
    }

    private makeRequest(method: 'post' | 'get', url: string, options: IMakeRequestOptions = {}): Observable<any> {
        options.requestOptions = this.readyOptions(options.requestOptions);
        return this.metricsHttp.post('getFirestoreToken', options);
    }

    private readyOptions(requestOptions: IRequestOptions = {}): IRequestOptions {
        if (!('observe' in requestOptions)) {
            requestOptions.observe = 'body';
        }

        set(requestOptions, 'responseType', get(requestOptions, 'responseType', 'json'));
        set(requestOptions, 'headers.Content-Type', get(requestOptions, 'headers.Content-Type', 'application/json; charset=utf-8'));

        const bearerToken = get(this, 'auth.token');
        if (bearerToken) {
            set(requestOptions, 'headers.authorization', 'Bearer ' + bearerToken);
        }
        return requestOptions;
    }

    /**
     * Executed whenever the status of an export job changes in firestore.
     * @param job
     */
    private onJobChanged(job: ExportJob): void {
        this.showToast(job);
    }

    /**
     * Shows a localized toast based on the status of the current job.
     * @param job
     */
    private showToast(job: ExportJob) {
        const jobStatus = job.status.toLocaleLowerCase();
        // let toastType = job.status === ExportJobStatus.Failure ? 'error' : 'info';
        let message: string = this.translateService.instant(`main.tabs.downloads.toasts.messages.${jobStatus}`);
        if (!message) {
            message = this.translateService.instant(`main.tabs.downloads.toasts.messages.default`);
        }
        message = message.replace(`{file}`, job.file);
        if (job.status === ExportJobStatus.Failure) {
            this.toasterService.error(this.translateService.instant(`main.tabs.downloads.toasts.titles.${jobStatus}`),
                message);
        } else if (job.status === ExportJobStatus.Success) {
            this.toasterService.success(this.translateService.instant(`main.tabs.downloads.toasts.titles.${jobStatus}`),
                message);
        } else {
            this.toasterService.info(this.translateService.instant(`main.tabs.downloads.toasts.titles.${jobStatus}`),
                message);
        }
    }
    isInitialized(useCase: string): boolean {
        return useCase in this.client;
    }

    isConnected(useCase: string): boolean {
        return this.isInitialized(useCase) && get(this.connected, useCase, false);
    }

    initializeDb(useCase: string, config: any): void {
        if (this.isInitialized(useCase)) {
            return;
        }
        config = pick(config, this.firestoreKeys);
        if (this.validateConfig(config)) {
            if (typeof config.projectId === 'object') {
                switch (config.projectId.source) {
                    case 'window':
                        config.projectId = (window as any)[config.projectId.variable];
                        break;
                    default:
                        throw new Error('Project ID source unknown: ' + config.projectId.source);
                }
            }

            this.client[useCase] = firebase.initializeApp(config, useCase);
        }
    }

    startUseCase(useCase: string) {
        if (!(useCase in this.client)) {
            throw new Error(`Use case does not exist: ${useCase}`);
        } else if (!this.isInitialized(useCase)) {
            throw new Error(`Use case does not exist: ${useCase}`);
        }

        set(this.disconnectSubjects, useCase, new Subject());

        this.exportsUseCase = {
            getPath: (path: string, conditions: Array<IFirestoreCondition>) => this.getPath(useCase, path, conditions),
            subscribeToPath: (path: string, conditions?: Array<IFirestoreCondition>) => this.subscribeToPath(useCase, path, conditions),
            signIn: (token, callback?: (err: null | Error, success: boolean) => void) => this.signIn(useCase, token, callback),
            signOut: (callback?: (err: null | Error, success?: boolean) => void) => this.signOut(useCase, callback),
            isConnected: (): boolean => this.isConnected(useCase),
            disconnect$: () => this.disconnectSubjects[useCase].asObservable().pipe(take(1)),
        };

    }

    signOutOfAll(): Promise<void> {
        const disconnects: Array<Promise<void>> = [];

        Object.keys(this.client)
            .filter(useCase => this.isConnected(useCase))
            .forEach(useCase => disconnects.push(new Promise((resolve, reject) => {
                // eslint-disable-next-line prefer-const
                let timer: any;
                const resolved = () => {
                    clearTimeout(timer);
                    resolve();
                };
                timer = setTimeout(() => {
                    resolve();
                }, this.signOutTimeout);
                this.signOut(useCase, err => err ? reject(err) : resolved());
            })));

        const promiseChain = Promise.resolve();
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        disconnects.forEach(promise => promiseChain.then(() => promise));
        return promiseChain;
    }

    private validateConfig(config: any): boolean {
        return !this.firestoreKeysRequired.filter(key => !(key in config)).length;
    }

    private signIn(useCase: string, token: string, callback?: (err: null | Error, success: boolean) => void): void {
        if ('exports' in this.identityTokens) {
            return;
        }
        if (token === this.identityTokens[useCase]) {
            if (callback) {
                callback(null, false);
            }
        }

        this.client[useCase].auth().signInWithCustomToken(token).then((credentials: firebase.auth.UserCredential) => {

            this.databases[useCase] = firebase.firestore(this.client[useCase]);
            this.databases[useCase].settings({ experimentalForceLongPolling: true });

            const auth: firebase.auth.Auth = this.client[useCase].auth();
            auth.onAuthStateChanged((user: User) => this.handleAuthChange(useCase, user));

            // Reinitialize existing listeners
            if (useCase in this.listenPaths) {
                const keys: Array<string> = Object.keys(this.listenPaths[useCase]);
                if (keys.length > 0) {
                    keys.forEach(path => this.subscribeToPath(useCase, path));
                }
            }

            // Set the new token
            this.setupFirestoreSession(useCase, token);
            if (callback) {
                callback(null, true);
            }
        }).catch(err => {
            if (callback) {
                callback(err, false);
            }
        });
    }

    private signInWithToken(token: string): Promise<void> {
        return new Promise((resolve, reject) => (this.exportsUseCase)
            .signIn(token, (err: Error | null, success: boolean) => {
                if (err) {
                    return reject(err);
                }
                resolve();
            }));
    }
    private handleAuthChange(useCase: string, user: User): Promise<void> {
        if (!!user && 'refreshToken' in user) {
            this.refreshTokens[useCase] = get(user, 'refreshToken');
        } else if (!user) {
            return;
        }

        // Store our 'get new token' function
        this.refreshFns[useCase] = () => user.getIdToken(true);

        return user.getIdTokenResult()
            // Get expiration time of token
            .then((meta: IdTokenResult) => {

                const expireTime = (new Date(meta.expirationTime)).getTime();
                const nowTime = (new Date()).getTime();
                return Math.floor((expireTime - nowTime) / 1000);
            })
            // Check the tokens and ensure they're valid and have enough time
            .then(async (secondsLeft: number) => this.checkExpiration(useCase, secondsLeft))
            // Setup the refresh timer
            .then((secondsLeft: number) => {
                this.refreshTimers[useCase] = setTimeout(() => {
                    this.refreshToken(useCase).catch(reason => {
                        if (reason.message.startsWith('The custom token format is incorrect.')) {
                            return;
                        }
                    });
                }, secondsLeft * 1000);
            });
    }

    private async checkExpiration(useCase: string, secondsLeft: number): Promise<number> {
        if (secondsLeft <= 0) {
            // -- Token already expired.
            secondsLeft = await this.refreshToken(useCase).then(() => {
                // Reset the time remaining (59m 55s)
                return 3595;
            }).catch(reason => {
                return 0;
            });
        } else if (secondsLeft <= this.firestoreResetMarginSeconds) {
            // -- Reset the token now
            secondsLeft = await this.refreshToken(useCase).then(() => {
                // Reset the time remaining (59m 55s)
                return 3595;
            }).catch(err => {
                return 0;
            });
        }

        return secondsLeft > 5 ? secondsLeft - 5 : secondsLeft;
    }

    private refreshToken(useCase: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.refreshFns[useCase]()
                .then((freshIdToken: string) => {
                    this.signIn(useCase, freshIdToken, (err, success) => {
                        if (err) {
                            return reject(err);
                        } else if (success) {
                            this.identityTokens[useCase] = freshIdToken;
                        }
                        resolve();
                    });
                });
        });
    }

    private signOut(useCase: string, callback?: (err: null | Error, success?: boolean) => void): void {
        if (!(useCase in this.identityTokens) || !this.identityTokens[useCase]) {
            return;
        }

        this.client[useCase].auth().signOut().then(() => {
            // End all listeners
            const keys = Object.keys(this.listenPaths[useCase]);
            if (keys.length) {
                keys.forEach((path: string) => this.killListener(useCase, path));
            }

            // Unset the database
            this.resetFirestoreSession(useCase);
            if (callback) {
                callback(null, true);
            }
        }).catch((err) => {
            if (callback) {
                callback(err);
            }
        });
    }

    private setupFirestoreSession(useCase: string, token: string): void {
        this.identityTokens[useCase] = token;
        this.connected[useCase] = true;
    }

    private resetFirestoreSession(useCase: string): void {
        this.databases[useCase] = undefined;
        this.connected[useCase] = false;
        this.identityTokens[useCase] = '';
    }

    /**
     * Sets or unsets monitoring on a specific FS path
     */
    private subscribeToPath(useCase: string, path: string, conditions?: Array<IFirestoreCondition>): Observable<[any, any]> {
        const [subject, type, ref] = this.getListener(useCase, path, conditions);

        if (ref === undefined || ref[0] === undefined) {
            return;
        }

        const updateFn = (doc) => subject.next([
            ref[1] ? doc.data() : doc.docs.map((fsDoc: QueryDocumentSnapshot) => fsDoc.data()),
            ref[1] ? doc.id : doc.docs.map((fsDoc: QueryDocumentSnapshot) => fsDoc.id)
        ]);

        const errorFn = (err) => {
            if (!this.connected[useCase]) {
                return;
            }
        };

        const unsubscriber = (ref[0] as DocRef).onSnapshot(updateFn, errorFn, () => {
            // Remove the path and its callback from the listenPaths list
            setTimeout(() => { delete this.listenPaths[useCase][path]; }, 10);
        });

        // Remember the path, subject, unsubscriber, and reference array in case it needs to be reinitialized
        // or we need to sign out and remove the listeners (prevent memory leaks!)
        set(this.listenPaths, useCase, { [path]: { subject, unsubscriber, ref } });

        return subject.asObservable();
    }

    private killListener(useCase: string, path: string): void {
        const listener = get(this.listenPaths, useCase + '.' + path);
        if (listener === undefined) {
            return;
        }

        if (listener.subject) {
            try {
                (<any>listener.subject).complete();
            } catch (e) {
            }
        }

        if (!!listener.unsubscriber && typeof listener.unsubscriber === 'function') {
            // Kill the listener
            (<any>listener).unsubscriber();
        }
    }

    private getListener(useCase: string, path: string, conditions?: Array<IFirestoreCondition>): [Subject<any>, string, [TFirestoreRef?, boolean?]] {
        let ref;
        let type: string;
        let subject: Subject<any>;

        // We're not getting the path here in case the path string has a `.` (period) in it.
        let existingListener = get(this.listenPaths, useCase);
        if (existingListener) {
            existingListener = path in existingListener ? (<any>existingListener[path]) : undefined;
        }
        if (!!existingListener && !(<any>existingListener.subject).isStopped) {
            ref = existingListener.ref[0];
            type = existingListener.ref[1] ? 'doc' : 'collection';
            subject = (<any>existingListener.subject);
        } else {
            // If the subject was stopped, we'll want to start over.
            if (existingListener) {
                this.killListener(useCase, path);
            }

            // Set the listener
            ref = this.getPath(useCase, path, conditions);
            if (ref.length) {
                // Create a new observable (behavior)subject
                subject = new Subject();
                subject.pipe(finalize(() => this.killListener(useCase, path)));
                type = ref[1] ? 'doc' : 'collection';
            }
        }

        return [subject, type, ref];
    }

    /**
     * Returns a collection/doc reference obj
     */
    private getPath(useCase: string, path: string, conditions?: Array<IFirestoreCondition>): [TFirestoreRef?, boolean?] {
        if (!(useCase in this.connected) || !this.connected[useCase]) {
            // this.debug.debug('(' + useCase + '):getPath() FS instance not active.')
            return [];
        }

        let isDoc = true;
        let ref = null;
        try {
            path.split('/').every((pathItem, i) => {
                if (i === 0) {
                    ref = this.databases[useCase].collection(pathItem);
                } else if (ref === undefined) {
                    return false;
                } else if (isDoc) {
                    ref = ref.doc(pathItem);
                    isDoc = false;
                } else {
                    if (pathItem.indexOf(':') !== -1) {
                        const piS = pathItem.split(':');
                        pathItem = piS[0];
                        const whereParts = piS[1].split('|', 3);
                        ref.collection(pathItem).where(whereParts[0], <WhereFilterOp>whereParts[1], whereParts[2]);
                    } else {
                        ref = ref.collection(pathItem);
                    }
                    isDoc = true;
                }
                return ref !== undefined;
            });
        } catch (e) {
            return [];
        }

        if (conditions) {
            conditions.forEach((condition: IFirestoreCondition) => {
                switch (condition.type) {
                    case 'where':
                        ref = ref.where(condition.prop, condition.op, condition.compare);
                        break;
                }
            });
        }

        return [ref, !isDoc];
    }

}
