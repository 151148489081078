import { Injectable } from '@angular/core';
import { UserNode } from './user-node';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ChecklistService {
  dataChange = new BehaviorSubject<UserNode[]>([]);

  get data(): UserNode[] {
    return this.dataChange.value;
  }

  set data(newData: any) {
    const data = this.buildFileTree(newData, 0);
    this.dataChange.next(data);
  }

  constructor() {
    //this.initialize();
  }

  initialize() {
    //const data = this.buildFileTree(TREE_DATA, 0);
    //this.dataChange.next(data);
  }

  buildFileTree(obj: { [key: string]: any }, level: number): UserNode[] {
    return Object.keys(obj).reduce<UserNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new UserNode();
      node.item = key;

      if (value != null) {
        if (typeof value === 'object') {
          node.users = this.buildFileTree(value, level + 1);
        } else {
          node.item = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  /** Add an item to to-do list */
  insertItem(parent: UserNode, name: string) {
    if (parent.users) {
      parent.users.push({ item: name } as UserNode);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: UserNode, name: string) {
    node.item = name;
    this.dataChange.next(this.data);
  }
}
