<div id="filterMenu" class="container-fluid filter-menu" type="popup" [class.active]="isFilterVisible"
    [hidden]="!isFilterVisible">
    <div class="row">
        <div class="col-md-4 col-xs-12 container-left" [class.large]="useLarge"
            [class.extra-large]="getFilterDefaultBoolean('filter.general.has-customer-size')"
            [class.extra-extra-large]="hasOutlier || hasVertical">
            <div class="row">
                <div class="col-md-6 col-xs-6">
                    <div class="form-group">
                        <label [tooltip]="monthTooltip"
                            placement="right">{{filterLabels['filter.general.month']}}</label>
                        <rdo-multiselect-dropdown id="Filter_Month" [order]="false" [texts]="monthMultiSelectionTexts"
                            [settings]="monthMultiSelectSettings" [options]="dates"
                            [(ngModel)]="filterProfileService.selectedMonthIds"
                            (onMonthTextClicked)="onMonthCodeChanged($event)"
                            (ngModelChange)="dateSelectionChange($event, false)"></rdo-multiselect-dropdown>
                    </div>

                    <div class="form-group">
                        <label tooltip="{{'main.tooltips.filters.primary-comparison' | translate}}"
                            placement="right">{{filterLabels['filter.geography.primary-comparison'] |
                            translate}}</label>
                        <rdo-multiselect-dropdown title="{{filterLabels['filter.general.month'] | uppercase }}"
                            id="Filter_Comparison" [settings]="singleSelectSettings" [options]="comparisons"
                            [(ngModel)]="selectedComparisonIds"
                            (ngModelChange)="comparisonSelectionChange($event)"></rdo-multiselect-dropdown>
                    </div>
                    <div class="form-group" id="rate-benchmark-dropdown-container">
                        <label for="Filter_RateSource" id="rate-benchmark-label"
                            tooltip="{{'main.tooltips.filters.rate-benchmark' | translate}}"
                            placement="right">{{filterLabels['filter.geography.rate-benchmark']}}</label>
                        <rdo-multiselect-dropdown id="Filter_RateSource" [settings]="singleSelectSettings"
                            [options]="benchmarks" [(ngModel)]="selectedBenchmarkIds"
                            (ngModelChange)="benchmarkSelectionChange($event)"></rdo-multiselect-dropdown>
                    </div>

                    <div class="form-group" *ngIf="getFilterDefaultBoolean('filter.general.has-customer-size')">
                        <label for="customer-size-select"
                            tooltip="{{'main.tooltips.filters.customer-size' | translate}}"
                            placement="right">{{filterLabels['filter.general.customer-size']}}</label>
                        <rdo-multiselect-dropdown id="customer-size-select" [order]="false"
                            [texts]="filterMultiSelectTexts" [settings]="multiSelectSettings" [options]="customerSizes"
                            [(ngModel)]="selectedCustomerSizeIds" (ngModelChange)="customerSizeSelectionChange($event)">
                        </rdo-multiselect-dropdown>
                    </div>
                </div>

                <div class="col-md-6 col-xs-6">
                    <div id="geo-filters">
                        <div class="form-group">
                            <label for="Filter_ClientRegion"
                                title="{{filterLabels['filter.geography.region'] | uppercase }}">{{filterLabels['filter.geography.region']}}</label>
                            <rdo-multiselect-dropdown id="Filter_ClientRegion" #regionDropDown
                                [texts]="filterMultiSelectTexts" [settings]="multiSelectRegionSettings"
                                [options]="regions" [(ngModel)]="selectedRegions"
                                (ngModelChange)="regionChanged($event)"></rdo-multiselect-dropdown>
                        </div>

                        <div class="form-group">
                            <label for="Filter_ClientDistrict"
                                title="{{filterLabels['filter.geography.district'] | uppercase }}">{{filterLabels['filter.geography.district']}}</label>
                            <rdo-multiselect-dropdown id="Filter_ClientDistrict" #districtDropDown
                                [texts]="filterMultiSelectTexts" [settings]="multiSelectDistrictSettings"
                                [options]="filteredDistricts" [(ngModel)]="selectedDistricts"
                                (ngModelChange)="districtChanged($event)"></rdo-multiselect-dropdown>
                        </div>

                        <div class="form-group">
                            <label for="Filter_LocationCode"
                                title="{{filterLabels['filter.geography.branch'] | uppercase }}">{{filterLabels['filter.geography.branch']}}</label>
                            <rdo-multiselect-dropdown id="Filter_LocationCode" #branchDropDown
                                [texts]="filterMultiSelectTexts" [settings]="multiSelectBranchSettings"
                                [options]="filteredBranches" [(ngModel)]="selectedBranches"
                                (ngModelChange)="branchChanged($event)"></rdo-multiselect-dropdown>
                        </div>

                        <div class="form-group" *ngIf="getFilterDefaultBoolean('filter.equipment.rouse-market')">
                            <label for="rouse-market-select"
                                tooltip="{{'main.tooltips.filters.rouse-market' | translate}}">{{filterLabels['filter.equipment.rouse-market']}}
                            </label>
                            <rdo-multiselect-dropdown id="rouse-market-select" [texts]="filterMultiSelectTexts"
                                [settings]="multiSelectMarketSettings" [options]="filteredRouseMarkets"
                                [(ngModel)]="selectedRouseMarketIds" #marketDropDown
                                (ngModelChange)="rouseMarketSelectionChange($event)">
                            </rdo-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="checkbox">
                        <div class="form-group">
                            <label tooltip="{{'main.tooltips.filters.use_rouse_schema' | translate}}">
                                <input id="use-rouse-schema-checkbox" type="checkbox"
                                    [(ngModel)]="filterProfileService.selectedFilterValues.useRouseSchema">
                                <span>
                                    <div>{{'main.filters.use_rouse_schema' | translate}}</div>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <div class="form-group slider-container">
                        <label tooltip="{{'main.tooltips.filters.benchmark-geography' | translate}}" placement="right"
                            title="{{filterLabels['filter.geography.benchmark-geography'] | uppercase }}">{{filterLabels['filter.geography.benchmark-geography']}}</label>
                        <div class="form_control">
                            <input id="geo-slider" type="text" class="span2" value="" />
                            <div id="geo-slider-legend" class="rdo-slider-legend"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-2 col-xs-12 container-center" [class.large]="useLarge"
            [class.extra-large]="getFilterDefaultBoolean('filter.general.has-customer-size')"
            [class.extra-extra-large]="hasOutlier || hasVertical">
            <div class="unit-filters">
                <div class="row">
                    <div class="col-xs-12">
                        <div id="equipment-title" class="header"
                            title="{{filterLabels['filter.equipment.title'] | uppercase }}">
                            {{filterLabels['filter.equipment.title']}}</div>
                    </div>
                </div>

                <div class="row" *ngIf="txAttributes?.ExistsPrimeUnit">
                    <div class="col-xs-12">
                        <div class="transaction-box">
                            <rdo-three-state-checkbox id="exclude-prime-units-group"
                                [(excludeTransactionType)]="filterProfileService.selectedFilterValues.excludePrimeUnits"
                                [falseLabel]="filterLabels['filter.equipment.prime-units']"
                                [falseTooltip]="'main.tooltips.filters.prime-units'"
                                [trueLabel]="filterLabels['filter.equipment.non-prime-units']"
                                [trueTooltip]="'main.tooltips.filters.non-prime-units'"
                                [nullLabel]="filterLabels['filter.equipment.both-prime-units']"
                                [nullTooltip]="'main.tooltips.filters.both-prime-units'">
                            </rdo-three-state-checkbox>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="txAttributes?.ExistsRpo">
                    <div class="col-xs-12">
                        <div class="transaction-box">
                            <rdo-three-state-checkbox id="exclude-rpo-group"
                                [(excludeTransactionType)]="filterProfileService.selectedFilterValues.excludeRpos"
                                [falseLabel]="filterLabels['filter.transaction.rpo']"
                                [falseTooltip]="'main.tooltips.filters.rpo'"
                                [trueLabel]="filterLabels['filter.transaction.non-rpo']"
                                [trueTooltip]="'main.tooltips.filters.non-rpo'"
                                [nullLabel]="filterLabels['filter.transaction.both-rpo']"
                                [nullTooltip]="'main.tooltips.filters.both-rpo'">
                            </rdo-three-state-checkbox>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12" *ngIf="hasRouseCategories">
                        <div class="form-group">
                            <label for="category-select"
                                tooltip="{{'main.tooltips.filters.rouse-category' | translate}}">{{filterLabels['filter.equipment.category']}}</label>
                            <rdo-multiselect-dropdown id="category-select" #rouseCatTypeDropDown
                                [texts]="filterMultiSelectTexts" [settings]="multiSelectCategorySettings"
                                [options]="rouseCategories" [(ngModel)]="selectedRouseCategoryIds"
                                (ngModelChange)="rouseCategorySelectionChange($event)">
                            </rdo-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="col-xs-12" *ngIf="getFilterDefaultBoolean('filter.equipment.rouse-product-type')">
                        <div class="form-group">

                            <label for="rouse-product-type-select"
                                tooltip="{{'main.tooltips.filters.rouse-product-type' | translate}}">{{filterLabels['filter.equipment.rouse-product-type']}}</label>
                            <rdo-multiselect-dropdown id="rouse-product-type-select" [texts]="filterMultiSelectTexts"
                                [settings]="rouseProductTypesmultiSelectSettings" [options]="filteredRouseProductTypes"
                                [(ngModel)]="selectedRouseProductTypeIds" #prodTypeDropDown [order]="false"
                                (ngModelChange)="rouseProductTypeSelectionChange($event)">
                            </rdo-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="col-xs-12" *ngIf="getFilterDefaultBoolean('filter.equipment.has-client-category')">
                        <div class="form-group">
                            <label for="client-category-select"
                                tooltip="{{'main.tooltips.filters.client-product-type' | translate}}">{{filterLabels['filter.equipment.client-category']}}</label>
                            <rdo-multiselect-dropdown id="client-category-select" #clientCatTypeDropDown
                                [texts]="filterMultiSelectTexts" [settings]="multiSelectWithSearchSettings"
                                [options]="clientCategories" [(ngModel)]="selectedClientCategoryIds"
                                (ngModelChange)="clientCategorySelectionChange($event)">
                            </rdo-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="col-xs-12" *ngIf="getFilterDefaultBoolean('filter.equipment.client-product-type')">
                        <div class="form-group">

                            <label for="client-product-type-select"
                                tooltip="{{'main.tooltips.filters.client-product-type' | translate}}">{{filterLabels['filter.equipment.client-product-type']}}</label>
                            <rdo-multiselect-dropdown id="client-product-type-select" [texts]="filterMultiSelectTexts"
                                [settings]="clientProductTypesmultiSelectSettings"
                                [options]="filteredClientProductTypes" [(ngModel)]="selectedClientProductTypeIds"
                                #clientProdTypeDropDown [order]="false"
                                (ngModelChange)="clientProductTypeSelectionChange($event)">
                            </rdo-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="col-xs-12" *ngIf="getFilterDefaultBoolean('filter.equipment.cat-product-group')">
                        <div class="form-group">
                            <label for="cat-product-group-select"
                                tooltip="{{'main.tooltips.filters.cat-product-group' | translate}}">{{filterLabels['filter.equipment.cat-product-group']}}</label>
                            <rdo-multiselect-dropdown id="cat-product-group-select" [texts]="filterMultiSelectTexts"
                                [settings]="multiSelectSettings" [options]="catProductGroups"
                                [(ngModel)]="selectedCatProductGroupsIds" #catProductGroupsDropDown
                                (ngModelChange)="catProductGroupSelectionChange($event)">
                            </rdo-multiselect-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-xs-12 container-right" [class.large]="useLarge"
            [class.extra-large]="getFilterDefaultBoolean('filter.general.has-customer-size')"
            [class.extra-extra-large]="hasOutlier || hasVertical">
            <div class="transaction-filters">

                <div class="row">
                    <div class="col-md-12 col-xs-12">
                        <div id="transaction-title" class="header"
                            title="{{filterLabels['filter.transaction.title'] | uppercase }}">
                            {{filterLabels['filter.transaction.title']}}</div>
                    </div>
                </div>

                <div class="row">
                    <div class="radiobutton-container col-xs-12">
                        <div class="col-md-4 col-xs-12" *ngIf="txAttributes?.ExistsSubstitution">
                            <div class="transaction-box">
                                <rdo-three-state-checkbox id="exclude-substitutions-group"
                                    [(excludeTransactionType)]="filterProfileService.selectedFilterValues.excludeSubstitutions"
                                    [falseLabel]="filterLabels['filter.transaction.substitutions']"
                                    [falseTooltip]="'main.tooltips.filters.substitutions'"
                                    [trueLabel]="filterLabels['filter.transaction.non-substitutions']"
                                    [trueTooltip]="'main.tooltips.filters.non-substitutions'"
                                    [nullLabel]="filterLabels['filter.transaction.both-substitutions']"
                                    [nullTooltip]="'main.tooltips.filters.both-substitutions'">
                                </rdo-three-state-checkbox>
                            </div>
                        </div>

                        <div class="col-md-4 col-xs-12" *ngIf="txAttributes?.ExistsNationalAcct">
                            <div class="transaction-box">
                                <rdo-three-state-checkbox id="exclude-national-accounts-group"
                                    [(excludeTransactionType)]="filterProfileService.selectedFilterValues.excludeNationalAccts"
                                    [falseLabel]="filterLabels['filter.transaction.national-accounts']"
                                    [falseTooltip]="'main.tooltips.filters.national-accounts'"
                                    [trueLabel]="filterLabels['filter.transaction.non-national-accounts']"
                                    [trueTooltip]="'main.tooltips.filters.non-national-accounts'"
                                    [nullLabel]="filterLabels['filter.transaction.both-national-accounts']"
                                    [nullTooltip]="'main.tooltips.filters.both-national-accounts'">
                                </rdo-three-state-checkbox>
                            </div>
                        </div>

                        <div class="col-md-4 col-xs-12" *ngIf="txAttributes?.ExistsContracts">
                            <div class="transaction-box">
                                <rdo-three-state-checkbox id="exclude-contracts-group"
                                    [(excludeTransactionType)]="filterProfileService.selectedFilterValues.excludeContracts"
                                    [falseLabel]="filterLabels['filter.transaction.contracts']"
                                    [falseTooltip]="'main.tooltips.filters.contracts'"
                                    [trueLabel]="filterLabels['filter.transaction.non-contracts']"
                                    [trueTooltip]="'main.tooltips.filters.non-contracts'"
                                    [nullLabel]="filterLabels['filter.transaction.both-contracts']"
                                    [nullTooltip]="'main.tooltips.filters.both-contracts'">
                                </rdo-three-state-checkbox>
                            </div>
                        </div>

                        <div class="col-md-4 col-xs-12" *ngIf="txAttributes?.ExistsSpecialPricing">
                            <div class="transaction-box">
                                <rdo-three-state-checkbox id="exclude-special-pricing-group"
                                    [(excludeTransactionType)]="filterProfileService.selectedFilterValues.excludeSpecialPricing"
                                    [falseLabel]="filterLabels['filter.transaction.special-pricing']"
                                    [falseTooltip]="'main.tooltips.filters.special-pricing'"
                                    [trueLabel]="filterLabels['filter.transaction.non-special-pricing']"
                                    [trueTooltip]="'main.tooltips.filters.non-special-pricing'"
                                    [nullLabel]="filterLabels['filter.transaction.both-special-pricing']"
                                    [nullTooltip]="'main.tooltips.filters.both-special-pricing'">
                                </rdo-three-state-checkbox>
                            </div>
                        </div>

                        <div class="col-md-4 col-xs-12" *ngIf="txAttributes?.ExistsReRent">
                            <div class="transaction-box">
                                <rdo-three-state-checkbox id="exclude-re-rent-group"
                                    [(excludeTransactionType)]="filterProfileService.selectedFilterValues.excludeReRent"
                                    [falseLabel]="filterLabels['filter.transaction.re-rents']"
                                    [falseTooltip]="'main.tooltips.filters.re-rents'"
                                    [trueLabel]="filterLabels['filter.transaction.non-re-rents']"
                                    [trueTooltip]="'main.tooltips.filters.non-re-rents'"
                                    [nullLabel]="filterLabels['filter.transaction.both-re-rents']"
                                    [nullTooltip]="'main.tooltips.filters.both-re-rents'">
                                </rdo-three-state-checkbox>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row" *ngIf="hasOutlier || hasVertical">
                    <div class="col-md-4 col-xs-12" *ngIf="hasVertical">
                        <div class="form-group">
                            <label tooltip="{{'main.tooltips.filters.verticals' | translate}}"
                                for="vertical-select">{{filterLabels['filter.transaction.verticals']}}</label>
                            <rdo-multiselect-dropdown id="vertical-select" [texts]="filterMultiSelectTexts"
                                [settings]="multiSelectSettings" [options]="verticals" [(ngModel)]="selectedVerticalIds"
                                #verticalsDropDown (ngModelChange)="verticalSelectionChange($event)">
                            </rdo-multiselect-dropdown>
                        </div>
                    </div>

                    <div class="col-md-4 col-xs-12" *ngIf="hasOutlier">
                        <div class="form-group">
                            <label for="outlier-reason-select"
                                tooltip="{{'main.tooltips.filters.outliers' | translate}}">{{filterLabels['filter.transaction.outliers']}}</label>
                            <rdo-multiselect-dropdown id="outlier-reason-select" [texts]="filterMultiSelectTexts"
                                [settings]="multiSelectSettings" [options]="outlierReasons"
                                [(ngModel)]="selectedOutlierReasonIds" #outliersDropDown [order]="false"
                                (ngModelChange)="outlierReasonSelectionChange($event)">
                            </rdo-multiselect-dropdown>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-8 col-xs-8">
                        <div class="cycle-bill-filter">
                            <div class="form-group">
                                <label title="{{filterLabels['filter.transaction.cycle-bill'] | uppercase }}"
                                    tooltip="{{'main.tooltips.filters.cycle-bills' | translate}}">{{filterLabels['filter.transaction.cycle-bill']}}</label>
                                <div class="slider-container">
                                    <input id="cycle-bill-slider" type="text" class="span2" value="" />
                                    <div id="cycle-bill-slider-legend" class="rdo-slider-legend"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 col-xs-4">
                        <div class="form-group">
                            <div class="checkbox">
                                <label>
                                    <input id="include-all-transations-checkbox" type="checkbox"
                                        [(ngModel)]="filterProfileService.selectedFilterValues.includeAllRateTransactions">
                                    <span id="include-all-transations-checkbox-label" class="text-wrap"
                                        tooltip="{{'main.tooltips.filters.include-not-compared-transactions' | translate}}">
                                        <div>{{filterLabels['filter.transaction.include-not-compared-transactions']}}
                                        </div>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="row buttons-row">
        <div class="buttons-footer">
            <div class="filter-left-btns">
                <rdo-filter-profile-list [class.disabled]="txAttributes?.IsTrial">&nbsp;loading
                    profiles...</rdo-filter-profile-list>
                <rdo-save-profile-as-buttons [class.disabled]="txAttributes?.IsTrial">loasing save profiles
                    btn...</rdo-save-profile-as-buttons>
            </div>
            <div class="filter-right-btns">
                <span style="padding-right:25px;"><button id="reset-filters-button"
                        style="width: 1px !important;background-color: #555;margin: 0px;color: #555;border: none !important;"
                        (click)="restoreDefaults()"></button></span>
                <button id="reinitializeFilterButton" (click)="reinitializeFilter()" style="display:none;"
                    title="{{'main.filters.buttons.re_initialize' | translate}}">{{'main.filters.buttons.re_initialize'
                    | translate}}</button>
                <button id="apply-filters-button" class="btn btn-primary apply-filters-btn"
                    *ngIf="!txAttributes?.IsTrial" (click)="applyFiltersClick()"
                    title="{{'main.filters.buttons.apply_filters' | translate}}"><i
                        class="fa fa-check-square-o apply-filters"></i>{{'main.filters.buttons.apply_filters' |
                    translate}}</button>
                <button id="apply-filters-button" class="btn btn-primary" *ngIf="txAttributes?.IsTrial" disabled
                    title="{{'main.filters.buttons.apply_filters' | translate}}"><i
                        class="fa fa-check-square-o apply-filters"></i>{{'main.filters.buttons.apply_filters' |
                    translate}}</button>
                <button id="close-filter-button" class="btn btn-default" (click)="closeFilter()"
                    title="{{'main.filters.buttons.close' | translate}}">{{'main.filters.buttons.close' |
                    translate}}</button>
            </div>
        </div>
    </div>
</div>

<header class="filter box filter-breadcrumb" id="filter" *ngIf="!isFilterVisible">
    <button mat-icon-button color="primary" (click)="listScroll(-1)" *ngIf="canGoBack()">
        <mat-icon>chevron_left</mat-icon>
    </button>
    <div *ngIf="!hideFilterSelectionsForRoute && showFilterMenu()" id="filters-container" class="list-container">
        <div id="filter_toggle" class="filter-bar-item margin-v-m">
            <a data-menu="filter-menu" data-type="header-menu" class="filter-month-highlight" (click)="openFilter()"
                id="filters-menu">
                <i class="fa fa-lg fa-filter"></i>
            </a>
        </div>
        <div class="filter-bar-item margin-v-m" *ngIf="selectedMonthRange">
            <span class="filter-month-highlight">
                {{selectedMonthRange}}
            </span>
        </div>
        <div class="filter-bar-item margin-v-m" *ngIf="selectedMonthRange">
            <span [ngClass]="selectedComparison === 'main.filters.primary_comparison.benchmark_focused' ?
                'filter-comparison-mode-bench' : 'filter-comparison-mode-book'">
                {{selectedComparison | translate}}
            </span>
        </div>
        <div class="filter-bar-item margin-v-m" *ngIf="selectedMonthRange">
            <span class="filter-month-highlight">
                {{selectedBenchmarkName}}
            </span>
        </div>
        <div class="filter-bar-item margin-v-m" *ngFor="let fb of engagedFilters">
            <a (click)="removeActiveFilter(fb)">
                {{handleTranslation(fb.title, fb.innerTitle)}}&nbsp;<i class="fa fa-close"></i>
            </a>
        </div>
    </div>
    <button mat-icon-button color="primary" (click)="listScroll(1)" *ngIf="canMoveForward()">
        <mat-icon>chevron_right</mat-icon>
    </button>
</header>

<!-- Dark Background -->
<div class="filter-dark-background" [hidden]="!isFilterVisible" (click)="closeFilter()"></div>
<div class="filter-dark-background-header" [hidden]="!isFilterVisible" (click)="closeFilter()"></div>

<!-- Filter Popups -->
<rdo-filter-profile-management></rdo-filter-profile-management>
<rdo-save-profile-as-popup [rawFilterProfileData]="rawFilterProfileData"></rdo-save-profile-as-popup>
<rdo-filter-profile-spinner></rdo-filter-profile-spinner>