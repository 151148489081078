﻿<div class="dropdown" [ngClass]="settings.containerClasses" [class.open]="isVisible">
	<button type="button" class="dropdown-toggle" [ngClass]="settings.buttonClasses"
		(click)="toggleDropdown()" [disabled]="disabled">
		<div class="truncate btn-label">{{ title | translate}}</div>
		<span class="btn-caret caret"></span>
	</button>
	<ul (mouseleave)="onMouseLeave($event)" *ngIf="isVisible" class="dropdown-menu" [class.pull-right]="settings.pullRight" [class.dropdown-menu-right]="settings.pullRight"
		[style.max-height]="settings.maxHeight" style="display: block; height: auto; overflow-y: auto;"
		 infinite-scroll
			[infiniteScrollThrottle]="500"
			(scrolled)="getPaginatedOptions()"
			[scrollWindow]="false">
		<li class="dropdown-item search" *ngIf="settings.enableSearch">
			<div class="input-group input-group-sm">
				<span class="input-group-addon" id="sizing-addon3"><i class="fa fa-search"></i></span>
				<input type="text" class="form-control" placeholder="{{ texts.searchPlaceholder | translate}}" aria-describedby="sizing-addon3" [(ngModel)]="searchFilterText"				
					   [ngModelOptions]="{standalone: true}" autofocus
					   [ngModel]="searchFilterText" (ngModelChange)="searchFilterTextChange($event)">
				<span class="input-group-btn" *ngIf="searchFilterText.length > 0">
					<button class="btn btn-default btn-secondary" type="button" (click)="clearSearch($event)"><i class="fa fa-times"></i></button>
				</span>
			</div>
		</li>
		<li class="dropdown-divider divider" *ngIf="settings.enableSearch"></li>
		<li class="dropdown-item check-control check-control-check" *ngIf="settings.showCheckAll">
			<a href="javascript:;" role="menuitem" tabindex="-1" (click)="checkAll()">
				<span style="width: 16px;" [ngClass]="{'glyphicon glyphicon-ok': settings.checkedStyle !== 'fontawesome','fa fa-check': settings.checkedStyle === 'fontawesome'}"></span>
				{{ texts.checkAll | translate }}
			</a>
		</li>
		<li class="dropdown-item check-control check-control-uncheck" *ngIf="settings.showUncheckAll">
			<a href="javascript:;" role="menuitem" tabindex="-1" (click)="uncheckAll() && emitUncheckedMonths()">
				<span style="width: 16px;" [ngClass]="{'glyphicon glyphicon-remove': settings.checkedStyle !== 'fontawesome','fa fa-times': settings.checkedStyle === 'fontawesome'}"></span>
				{{ texts.uncheckAll | translate }}
			</a>
		</li>
		<li class="dropdown-item check-control check-control-check" *ngIf="texts.yearToDate">
			<a href="javascript:;" role="menuitem" tabindex="-1" (click)="checkMonths('year_to_date')">
				<span style="width: 16px;" [ngClass]="{'glyphicon glyphicon-ok': settings.checkedStyle !== 'fontawesome','fa fa-check': settings.checkedStyle === 'fontawesome'}"></span>
				{{ texts.yearToDate | translate }}
			</a>
		</li>
		<li class="dropdown-item check-control check-control-check" *ngIf="texts.latestMonth">
			<a href="javascript:;" role="menuitem" tabindex="-1" (click)="checkMonths('latest_month', 1)">
				<span style="width: 16px;" [ngClass]="{'glyphicon glyphicon-ok': settings.checkedStyle !== 'fontawesome','fa fa-check': settings.checkedStyle === 'fontawesome'}"></span>
				{{ texts.latestMonth | translate }}
			</a>
		</li>
		<li class="dropdown-item check-control check-control-check" *ngIf="texts.latestFullMonth">
			<a href="javascript:;" role="menuitem" tabindex="-1" (click)="checkMonths('latest_full_month', 1, 1)">
				<span style="width: 16px;" [ngClass]="{'glyphicon glyphicon-ok': settings.checkedStyle !== 'fontawesome','fa fa-check': settings.checkedStyle === 'fontawesome'}"></span>
				{{ texts.latestFullMonth | translate }}
			</a>
		</li>
		<li class="dropdown-item check-control check-control-check" *ngIf="texts.latest3FullMonths">
			<a href="javascript:;" role="menuitem" tabindex="-1" (click)="checkMonths('latest_3_full_months', 3)">
				<span style="width: 16px;" [ngClass]="{'glyphicon glyphicon-ok': settings.checkedStyle !== 'fontawesome','fa fa-check': settings.checkedStyle === 'fontawesome'}"></span>
				{{ texts.latest3FullMonths | translate }}
			</a>
		</li>
		<li class="dropdown-item check-control check-control-check" *ngIf="texts.latest6FullMonths">
			<a href="javascript:;" role="menuitem" tabindex="-1" (click)="checkMonths('latest_6_full_months', 6)">
				<span style="width: 16px;" [ngClass]="{'glyphicon glyphicon-ok': settings.checkedStyle !== 'fontawesome','fa fa-check': settings.checkedStyle === 'fontawesome'}"></span>
				{{ texts.latest6FullMonths | translate }}
			</a>
		</li>
		<li class="dropdown-item check-control check-control-check" *ngIf="texts.latest9FullMonths">
			<a href="javascript:;" role="menuitem" tabindex="-1" (click)="checkMonths('latest_9_full_months', 9)">
				<span style="width: 16px;" [ngClass]="{'glyphicon glyphicon-ok': settings.checkedStyle !== 'fontawesome','fa fa-check': settings.checkedStyle === 'fontawesome'}"></span>
				{{ texts.latest9FullMonths | translate }}
			</a>
		</li>
		<li class="dropdown-item check-control check-control-check" *ngIf="texts.latest12FullMonths">
			<a href="javascript:;" role="menuitem" tabindex="-1" (click)="checkMonths('latest_12_full_months', 12)">
				<span style="width: 16px;" [ngClass]="{'glyphicon glyphicon-ok': settings.checkedStyle !== 'fontawesome','fa fa-check': settings.checkedStyle === 'fontawesome'}"></span>
				{{ texts.latest12FullMonths | translate }}
			</a>
		</li>
		<li *ngIf="settings.showCheckAll || settings.showUncheckAll" class="dropdown-divider divider"></li>
			<li class="dropdown-item" [ngStyle]="getItemStyle(option)" *ngFor="let option of scrollableOptions | searchFilter:searchFilterText"
		    (click)="!option.isLabel && setSelected($event, option)" [class.dropdown-header]="option.isLabel">
			<ng-template [ngIf]="option.isLabel">{{ option.name  | translate}}</ng-template>
			<a *ngIf="!option.isLabel" href="javascript:;" role="menuitem" tabindex="-1" [style.padding-left]="this.parents.length>0&&this.parents.indexOf(option.id)<0&&'30px'">
				<input *ngIf="settings.checkedStyle === 'checkboxes'" type="checkbox" [checked]="isSelected(option)" (click)="preventCheckboxCheck($event, option)"/>
				<span *ngIf="settings.checkedStyle === 'glyphicon'" style="width: 16px;" class="glyphicon" [class.glyphicon-ok]="isSelected(option)"></span>
				<span *ngIf="settings.checkedStyle === 'fontawesome'" style="width: 16px;display: inline-block;">
					<i *ngIf="isSelected(option)" class="fa fa-check" aria-hidden="true"></i>
				</span>
				<span [ngClass]="settings.itemClasses" [style.font-weight]="this.parents.indexOf(option.id)>=0?'bold':'normal'">
					{{ option.name | translate}}
				</span>
			</a>
		</li>
		
	</ul>
</div>
