class ObjectUtils {
  static equals(obj1, obj2, field) {
    if (field) return this.resolveFieldData(obj1, field) === this.resolveFieldData(obj2, field);else return this.equalsByValue(obj1, obj2);
  }
  static equalsByValue(obj1, obj2) {
    if (obj1 === obj2) return true;
    if (obj1 && obj2 && typeof obj1 == 'object' && typeof obj2 == 'object') {
      var arrA = Array.isArray(obj1),
        arrB = Array.isArray(obj2),
        i,
        length,
        key;
      if (arrA && arrB) {
        length = obj1.length;
        if (length != obj2.length) return false;
        for (i = length; i-- !== 0;) if (!this.equalsByValue(obj1[i], obj2[i])) return false;
        return true;
      }
      if (arrA != arrB) return false;
      var dateA = obj1 instanceof Date,
        dateB = obj2 instanceof Date;
      if (dateA != dateB) return false;
      if (dateA && dateB) return obj1.getTime() == obj2.getTime();
      var regexpA = obj1 instanceof RegExp,
        regexpB = obj2 instanceof RegExp;
      if (regexpA != regexpB) return false;
      if (regexpA && regexpB) return obj1.toString() == obj2.toString();
      var keys = Object.keys(obj1);
      length = keys.length;
      if (length !== Object.keys(obj2).length) return false;
      for (i = length; i-- !== 0;) if (!Object.prototype.hasOwnProperty.call(obj2, keys[i])) return false;
      for (i = length; i-- !== 0;) {
        key = keys[i];
        if (!this.equalsByValue(obj1[key], obj2[key])) return false;
      }
      return true;
    }
    return obj1 !== obj1 && obj2 !== obj2;
  }
  static resolveFieldData(data, field) {
    if (data && field) {
      if (this.isFunction(field)) {
        return field(data);
      } else if (field.indexOf('.') == -1) {
        return data[field];
      } else {
        let fields = field.split('.');
        let value = data;
        for (let i = 0, len = fields.length; i < len; ++i) {
          if (value == null) {
            return null;
          }
          value = value[fields[i]];
        }
        return value;
      }
    } else {
      return null;
    }
  }
  static isFunction(obj) {
    return !!(obj && obj.constructor && obj.call && obj.apply);
  }
  static reorderArray(value, from, to) {
    let target;
    if (value && from !== to) {
      if (to >= value.length) {
        to %= value.length;
        from %= value.length;
      }
      value.splice(to, 0, value.splice(from, 1)[0]);
    }
  }
  static generateSelectItems(val, field) {
    let selectItems;
    if (val && val.length) {
      selectItems = [];
      for (let item of val) {
        selectItems.push({
          label: this.resolveFieldData(item, field),
          value: item
        });
      }
    }
    return selectItems;
  }
  static insertIntoOrderedArray(item, index, arr, sourceArr) {
    if (arr.length > 0) {
      let injected = false;
      for (let i = 0; i < arr.length; i++) {
        let currentItemIndex = this.findIndexInList(arr[i], sourceArr);
        if (currentItemIndex > index) {
          arr.splice(i, 0, item);
          injected = true;
          break;
        }
      }
      if (!injected) {
        arr.push(item);
      }
    } else {
      arr.push(item);
    }
  }
  static findIndexInList(item, list) {
    let index = -1;
    if (list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i] == item) {
          index = i;
          break;
        }
      }
    }
    return index;
  }
  static removeAccents(str) {
    if (str && str.search(/[\xC0-\xFF]/g) > -1) {
      str = str.replace(/[\xC0-\xC5]/g, "A").replace(/[\xC6]/g, "AE").replace(/[\xC7]/g, "C").replace(/[\xC8-\xCB]/g, "E").replace(/[\xCC-\xCF]/g, "I").replace(/[\xD0]/g, "D").replace(/[\xD1]/g, "N").replace(/[\xD2-\xD6\xD8]/g, "O").replace(/[\xD9-\xDC]/g, "U").replace(/[\xDD]/g, "Y").replace(/[\xDE]/g, "P").replace(/[\xE0-\xE5]/g, "a").replace(/[\xE6]/g, "ae").replace(/[\xE7]/g, "c").replace(/[\xE8-\xEB]/g, "e").replace(/[\xEC-\xEF]/g, "i").replace(/[\xF1]/g, "n").replace(/[\xF2-\xF6\xF8]/g, "o").replace(/[\xF9-\xFC]/g, "u").replace(/[\xFE]/g, "p").replace(/[\xFD\xFF]/g, "y");
    }
    return str;
  }
}
class FilterUtils {
  static filter(value, fields, filterValue, filterMatchMode, filterLocale) {
    let filteredItems = [];
    let filterText = ObjectUtils.removeAccents(filterValue).toLocaleLowerCase(filterLocale);
    if (value) {
      for (let item of value) {
        for (let field of fields) {
          let fieldValue = ObjectUtils.removeAccents(String(ObjectUtils.resolveFieldData(item, field))).toLocaleLowerCase(filterLocale);
          if (FilterUtils[filterMatchMode](fieldValue, filterText, filterLocale)) {
            filteredItems.push(item);
            break;
          }
        }
      }
    }
    return filteredItems;
  }
  static startsWith(value, filter, filterLocale) {
    if (filter === undefined || filter === null || filter.trim() === '') {
      return true;
    }
    if (value === undefined || value === null) {
      return false;
    }
    let filterValue = ObjectUtils.removeAccents(filter.toString()).toLocaleLowerCase(filterLocale);
    let stringValue = ObjectUtils.removeAccents(value.toString()).toLocaleLowerCase(filterLocale);
    return stringValue.slice(0, filterValue.length) === filterValue;
  }
  static contains(value, filter, filterLocale) {
    if (filter === undefined || filter === null || typeof filter === 'string' && filter.trim() === '') {
      return true;
    }
    if (value === undefined || value === null) {
      return false;
    }
    let filterValue = ObjectUtils.removeAccents(filter.toString()).toLocaleLowerCase(filterLocale);
    let stringValue = ObjectUtils.removeAccents(value.toString()).toLocaleLowerCase(filterLocale);
    return stringValue.indexOf(filterValue) !== -1;
  }
  static endsWith(value, filter, filterLocale) {
    if (filter === undefined || filter === null || filter.trim() === '') {
      return true;
    }
    if (value === undefined || value === null) {
      return false;
    }
    let filterValue = ObjectUtils.removeAccents(filter.toString()).toLocaleLowerCase(filterLocale);
    let stringValue = ObjectUtils.removeAccents(value.toString()).toLocaleLowerCase(filterLocale);
    return stringValue.indexOf(filterValue, stringValue.length - filterValue.length) !== -1;
  }
  static equals(value, filter, filterLocale) {
    if (filter === undefined || filter === null || typeof filter === 'string' && filter.trim() === '') {
      return true;
    }
    if (value === undefined || value === null) {
      return false;
    }
    if (value.getTime && filter.getTime) return value.getTime() === filter.getTime();else return ObjectUtils.removeAccents(value.toString()).toLocaleLowerCase(filterLocale) == ObjectUtils.removeAccents(filter.toString()).toLocaleLowerCase(filterLocale);
  }
  static notEquals(value, filter, filterLocale) {
    if (filter === undefined || filter === null || typeof filter === 'string' && filter.trim() === '') {
      return false;
    }
    if (value === undefined || value === null) {
      return true;
    }
    if (value.getTime && filter.getTime) return value.getTime() !== filter.getTime();else return ObjectUtils.removeAccents(value.toString()).toLocaleLowerCase(filterLocale) != ObjectUtils.removeAccents(filter.toString()).toLocaleLowerCase(filterLocale);
  }
  static in(value, filter, filterLocale) {
    if (filter === undefined || filter === null || filter.length === 0) {
      return true;
    }
    if (value === undefined || value === null) {
      return false;
    }
    for (let i = 0; i < filter.length; i++) {
      if (ObjectUtils.equals(value, filter[i])) {
        return true;
      }
    }
    return false;
  }
  static lt(value, filter, filterLocale) {
    if (filter === undefined || filter === null) {
      return true;
    }
    if (value === undefined || value === null) {
      return false;
    }
    if (value.getTime && filter.getTime) return value.getTime() < filter.getTime();else return value < filter;
  }
  static lte(value, filter, filterLocale) {
    if (filter === undefined || filter === null) {
      return true;
    }
    if (value === undefined || value === null) {
      return false;
    }
    if (value.getTime && filter.getTime) return value.getTime() <= filter.getTime();else return value <= filter;
  }
  static gt(value, filter, filterLocale) {
    if (filter === undefined || filter === null) {
      return true;
    }
    if (value === undefined || value === null) {
      return false;
    }
    if (value.getTime && filter.getTime) return value.getTime() > filter.getTime();else return value > filter;
  }
  static gte(value, filter, filterLocale) {
    if (filter === undefined || filter === null) {
      return true;
    }
    if (value === undefined || value === null) {
      return false;
    }
    if (value.getTime && filter.getTime) return value.getTime() >= filter.getTime();else return value >= filter;
  }
}
var lastId = 0;
function UniqueComponentId() {
  let prefix = 'pr_id_';
  lastId++;
  return `${prefix}${lastId}`;
}

/**
 * Generated bundle index. Do not edit.
 */

export { FilterUtils, ObjectUtils, UniqueComponentId, lastId };
