import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { DashboardCardType, DashboardCardSetData } from '../../../models';
/*  eslint-disable @angular-eslint/no-output-on-prefix */
@Component({
	selector: 'rdo-dashboard-card-set',
	templateUrl: 'dashboard-card-set.component.html',
	styleUrls: ['dashboard-card-set.component.scss']
})

export class DashboardCardSetComponent {
	hasQuartiles: boolean;
	dashboardCardType: any = DashboardCardType;

	@Input()
	data: DashboardCardSetData;

	@Input()
	loading: boolean;

	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input('show-quartiles') set cardTypes(data: string) {
		this.hasQuartiles = data === 'quartiles';
	}

	@Output()
	onNavigateToBqTransactions: EventEmitter<boolean> = new EventEmitter<boolean>(false);

	@Output()
	onNavigateToTqTransactions: EventEmitter<boolean> = new EventEmitter<boolean>(false);

	@Output()
	onCardClicked: EventEmitter<DashboardCardType> = new EventEmitter<DashboardCardType>();

	constructor(
		protected router: Router,
		protected location: Location,
		protected translateService: TranslateService
	) {	}

	private displayQuartiles = () => {
		return this.hasQuartiles;
	}

	notifyTq = () => {
		this.onNavigateToTqTransactions.next(true);
	}

	notifyBq = () => {
		this.onNavigateToBqTransactions.next(true);
	}

	notifyCardClicked = (cardType: DashboardCardType) => {
		this.onCardClicked.next(cardType);
	}

	variance = (a: number, b: number): number => {
		return a - b;
	}

	variancePercent = (a: number, b: number): number => {
		if (b !== 0) {
			return (a / b) - 1;
		}
		return 0;
	};
}
