import { Injectable } from '@angular/core';
import { SupportedLocaleDictionary } from './az-lang-support';    // acquired from auth0 current lang config
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Injectable()
export class TooltipsService { 
    constructor(
        private translateService: TranslateService,
        private router: Router) {

    }

    public getGridTooltip(translationKey: string){
        const splitted = translationKey.split('.');
        let translation = null;
        let routeSpecificKey = '';
        if (this.router.url.endsWith('regions') || this.router.url.endsWith('districts') || this.router.url.endsWith('branches')) {
            routeSpecificKey = `main.tooltips.geography.${splitted[splitted.length - 1]}`;            
        }
        if (this.router.url.endsWith('sales-reps')) {
            routeSpecificKey = `main.tooltips.sales-reps.${splitted[splitted.length - 1]}`;            
        }
        if (this.router.url.endsWith('customers')) {
            routeSpecificKey = `main.tooltips.customers.${splitted[splitted.length - 1]}`;            
        }
        // if (this.router.url.endsWith('rental-assets')) {
        //     routeSpecificKey = `main.tooltips.rental-assets.${splitted[splitted.length - 1]}`;            
        // }
        if (this.router.url.endsWith('equipment') || (this.router.url.includes('equipment') && this.router.url.split('=').length > 1 && !this.router.url.split('=')[1].includes('/'))) {
            routeSpecificKey = `main.tooltips.equipment.${splitted[splitted.length - 1]}`;            
        }
        // if (this.router.url.endsWith('changes-log')) {
        //     routeSpecificKey = `main.tooltips.changes-log.${splitted[splitted.length - 1]}`;            
        // }
        if (routeSpecificKey) {
            const auxTranslation = this.translateService.instant(routeSpecificKey);
            if (auxTranslation !== routeSpecificKey) {
                translation = auxTranslation;
            }
        }
        
        return translation;
    }
}
