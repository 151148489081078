import { TranslateService } from '@ngx-translate/core';

/**
 * Used for translating the legend (field) of a chart.
 */
export class ChartLegend {
	constructor(
		protected translateService: TranslateService,
		public id: string
	) { }

	public getText(): string {
		return this.translateService.instant(this.id);
	}

	public updateText(chart: any): void {
		if (chart && chart.series) {
			const property = chart.get(this.id);
			if (property) {
				const propertyText = this.getText();
				property.update({title: {text: propertyText}});
				property.update({name: propertyText});
			}
		}
	}
}
