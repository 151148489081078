import { Injectable, EventEmitter } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { of } from 'rxjs/observable/of';

@Injectable()
export class RentalGrowthPanelEventService {
	public panelEventChange: EventEmitter<any> = new EventEmitter<any>();
	private panelEvent: any;
	private subject: Subject<any>;

	public get onPanelEventing(): Observable<any> {
        if (this.panelEvent) {
			return of(this.panelEvent);
        }

		this.subject = new Subject();
		return this.subject;
	}

	public notify = (evt) => {
        if (!evt) {
			this.panelEvent = null;
	        return;
        }

		this.panelEvent = evt;
        if (this.subject) {
			this.subject.next(evt);
        }

		this.panelEventChange.next(evt);
	}

	clear = () => {
		this.panelEvent = null;
	}
}
