const BENCHMARK_GEO_LEVEL = 'benchmark_geo_level';
const RATE_BENCHMARK = 'rate_benchmark';
const MONTH = 'month';
const TTM_END_MONTH = 'ttm_end_month';
const CG_FIELDS = {
    BENCHMARK_GEO_LEVEL,
    RATE_BENCHMARK,
    MONTH,
    TTM_END_MONTH
};

export { CG_FIELDS };
