import { IFieldListOptions } from '../pivotcharts/ej2-pivotview/src/base';
export const nameIsRequiredMsg = 'main.tabs.custom_grids.page_labels.formula_validator_required_name';
export const duplicateFieldNameMsg = 'main.tabs.custom_grids.page_labels.formula_validator_duplicate_name';
export const formulaRequiredMsg = 'main.tabs.custom_grids.page_labels.formula_validator_required_forumula';

export const validateCalculatedField = (calculatedField: any, fieldList: IFieldListOptions, isEdit: boolean) => {
    if (!calculatedField.caption || calculatedField.caption.trim() === '') {
        return (nameIsRequiredMsg);
    }
    // eslint-disable-next-line guard-for-in
    for (const key in fieldList) {
        const field = fieldList[key];
        if  (((!isEdit) && (field.caption.toLocaleLowerCase().trim() === calculatedField.caption.toLocaleLowerCase().trim())) ||
        ((isEdit) && (field.id.toLocaleLowerCase().trim() !== calculatedField.name.toLocaleLowerCase().trim())) && (field.caption.toLocaleLowerCase().trim() === calculatedField.caption.toLocaleLowerCase().trim())) {
            return duplicateFieldNameMsg;
        }
    }
    if (!calculatedField.formula || calculatedField.formula.trim() === '') {
        return formulaRequiredMsg;
    }
    return '';
};




