import {
  Component,
  Input,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { RevenueChartHelperService, HighchartsConfiguration } from './../base';
import { FormatService, ComparisonMode } from './../../query';
import { TranslateService } from '@ngx-translate/core';
import { RdoPathToIdPipe } from '../../pipes';
import { CHART_COLORS } from '../base/chart-color-constants';
import { LocaleService } from '../../services';

interface IRevenueComparison {
  /**
   * Difference of two numbers
   */
  diff: number;

  /**
   * Percent difference of two numbers
   */
  diffPct: number;

  /**
   * Percent difference of two numbers
   */
  diffPctFmt: number;
}

@Component({
  selector: 'rdo-revenue-by-month-chart',
  styleUrls: ['./../base/revenue-chart-helper.scss'],
  templateUrl: './revenue-by-month-chart.html',
})
export class ClientRateBenchChartComponent implements OnChanges {
  chartConfig: HighchartsConfiguration = new HighchartsConfiguration();
  private chart: any;
  @Input() comparisonMode: ComparisonMode;
  // eslint-disable-next-line
  @Input('chart-data') data: any;
  // eslint-disable-next-line
  @Input('dashed-line-offset') dashedLineOffset: number;

  constructor(
    private element: ElementRef,
    private formatService: FormatService,
    private translateService: TranslateService,
    private revUtilChartService: RevenueChartHelperService,
    private localeService: LocaleService
  ) {
    this.translateService
      .stream('main.core.charts.months')
      .subscribe((i18n) => {
        if (this.chart) {
          this.chart.render();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.data && this.dashedLineOffset) {
      this.loadChart(this.data, this.comparisonMode);
    }
  }

  private compareRevenue(obj: any, mode: ComparisonMode): IRevenueComparison {
    let diff = 0;
    let diffPct = 0;
    let diffPctFmt = 0;
    switch (mode) {
      case ComparisonMode.Benchmark:
        diff = obj.RevenueBenchmarked - obj.RevenueBench;
        diffPct = (diff !== 0 ? diff / obj.RevenueBench : 0) * 100;
        diffPctFmt =
          this.formatService.getDifferencePercent(
            obj.RevenueBenchmarked,
            obj.RevenueBench
          ) * 100;
        break;
      case ComparisonMode.Book:
        diff = obj.RevenueTotal - obj.RevenueBook;
        diffPct = (diff !== 0 ? diff / obj.RevenueBook : 0) * 100;
        diffPctFmt =
          this.formatService.getDifferencePercent(
            obj.RevenueTotal,
            obj.RevenueBook
          ) * 100;
        break;
      default:
        break;
    }
    return {
      diff: diff,
      diffPct: diffPct,
      diffPctFmt: diffPctFmt,
    };
  }

  private loadChart(data: Array<any>, mode: ComparisonMode) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;
    let chartColor1: string = '';
    let chartColor2: string = '';
    switch (mode) {
      case ComparisonMode.Benchmark:
        chartColor1 = 'rgba(153, 168, 189,1)';
        chartColor2 = 'rgba(153, 168, 189,0.3)';
        break;
      case ComparisonMode.Book:
        chartColor1 = 'rgba(45,162,108,1)';
        chartColor2 = 'rgba(45,162,108,0.3)';
        break;
      default:
        break;
    }
    const options = {
      chart: {
        height: this.element.nativeElement.parentElement.offsetHeight,
        width: this.element.nativeElement.parentElement.offsetWidth,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        column: {
          grouping: false,
          events: {
            click: (e) => {
              this.revUtilChartService.selectMonthFromChart(e.point.category);
            },
          },
          dataLabels: {
            enabled: true,
            crop: false,
            overflow: 'none',
            style: {
              z: '1 !important',
            },
            formatter: function () {
              const comparison = component.compareRevenue(this.x, mode);
              switch (mode) {
                case ComparisonMode.Benchmark:
                  if (!this.x.RevenueBench) {
                    if (!this.x.RevenueBenchmarked) {
                      return ``;
                    }
                    return `${component.formatService.formatCurrency(comparison.diff, false)} / -`;
                  }
                  break;
                case ComparisonMode.Book:
                  if (!this.x.RevenueBook) {
                    if (!this.x.RevenueTotal) {
                      return ``;
                    }
                    return `${component.formatService.formatCurrency(comparison.diff, false)} / -`;
                  }
                  break;
                default:
                  break;
              }
              if (comparison.diff < 0) {
                return `<div class="client-benchmark-data-label">
                                    <span class='text-danger' style="font-weight:normal; font-size:11px;">
                                    ${comparison.diffPct.toFixed(1)}%<br>${component.formatService.formatCurrency(comparison.diff, true)}
                                    </span></div>`;
              } else {
                return `<div class="client-benchmark-data-label">
                                    <span style="font-weight:normal; font-size:11px;">
                                    ${component.formatService.formatCurrency(comparison.diff, true)}<br>${comparison.diffPct.toFixed(1)}%
                                    </span></div>`;
              }
            },
            useHTML: true,
          },
        },
      },
      tooltip: {
        backgroundColor: null,
        borderWidth: 0,
        shadow: false,
        style: {
          padding: 0,
        },
        positioner: (boxWidth, boxHeight, point) => {
          return component.revUtilChartService.getTooltipPositionClientRateBenchmark(
            point
          );
        },
        formatter: function () {
          const data =
            component.revUtilChartService.getDataForClientBenchMarkTooltip(
              component.chart
            );
          let markup;
          const idPipe = new RdoPathToIdPipe();
          if (mode === ComparisonMode.Benchmark) {
            markup = `<div class="client-rate-bench-chart">
                        <table class="table rdo-table-tooltip">
                            <tr>
                                <td id="${idPipe.transform('tooltip-client_rate_vs_bench.client_compared_revenue')}">
                                    <span style="color:rgba(75,75,75,1)">&#x25CF</span>
                                    ${component.translateService.instant('main.tabs.summary.charts.client_rate_vs_bench.client_compared_revenue')}
                                </td>
                                 <td>
                                    ${component.formatService.selectedCurrencySymbol}${component.formatService.formatCurrency(this.x.RevenueBenchmarked, false)}
                                </td>
                            </tr>
                            <tr>
                                <td id="${idPipe.transform('tooltip-client_rate_vs_bench.benchmark_avg_revenue')}">
                                    <span style="color:rgba(75,75,75,1)">&#x25CF</span>
                                    ${component.translateService.instant('main.tabs.summary.charts.client_rate_vs_bench.benchmark_avg_revenue')}
                                </td>
                                <td>
                                    ${component.formatService.selectedCurrencySymbol}${component.formatService.formatCurrency(this.x.RevenueBench, false)}
                                </td>
                            </tr>
                            <tr>
                                <td id="${idPipe.transform('tooltip-client_rate_vs_bench.revenue_difference.1')}">
                                    <span class="${this.x.RevenueBenchmarked - this.x.RevenueBench < 0 ? 'text-danger' : ''}">&#x25CF</span> 
                                    ${component.translateService.instant('main.tabs.summary.charts.client_rate_vs_bench.revenue_difference')}
                                </td>
                                <td>
                                    <span class="${this.x.RevenueBenchmarked - this.x.RevenueBench < 0 ? 'text-danger' : ''}">
                                    ${component.formatService.formatCurrencyDifference(this.x.RevenueBenchmarked, this.x.RevenueBench, true)}</span>
                                </td>
                            </tr>
                        </table></div>`;
          } else {
            markup = `<div class="client-rate-bench-chart">
                        <table class="table rdo-table-tooltip">
                            <tr>
                                <td id="${idPipe.transform('tooltip-client_rate_vs_bench.client_total_revenue')}">
                                    <span style="color:rgba(75,75,75,1)">&#x25CF</span>
                                    ${component.translateService.instant('main.tabs.summary.charts.client_rate_vs_bench.client_total_revenue')}
                                </td>
                                 <td>
                                    ${component.formatService.selectedCurrencySymbol}${component.formatService.formatCurrency(this.x.RevenueTotal, false)}
                                </td>
                            </tr>
                            <tr>
                                <td id="${idPipe.transform('tooltip-client_rate_vs_bench.book_revenue')}">
                                    <span style="color:rgba(75,75,75,1)">&#x25CF</span>
                                    ${component.translateService.instant('main.tabs.summary.charts.client_rate_vs_bench.book_revenue')}
                                </td>
                                <td>
                                    ${component.formatService.selectedCurrencySymbol}${component.formatService.formatCurrency(this.x.RevenueBook, false)}
                                </td>
                            </tr>
                            <tr>
                                <td id="${idPipe.transform('tooltip-client_rate_vs_bench.revenue_difference.2')}">
                                    <span class="${data && data.point && data.point.cssClass ? data.point.cssClass : ''}">&#x25CF</span>
                                    ${component.translateService.instant('main.tabs.summary.charts.client_rate_vs_bench.revenue_difference')}
                                </td>
                                <td>
                                    <span class="${data && data.point && data.point.cssClass ? data.point.cssClass : ''}">
                                    ${component.formatService.formatCurrencyDifference(this.x.RevenueTotal, this.x.RevenueBook, true)}</span>
                                </td>
                            </tr>
                        </table></div>`;
          }
          return markup;
        },
        shared: true,
        useHTML: true,
      },
      yAxis: [
        {
          minPadding: 0.35,
          maxPadding: 0.35,
          endOnTick: false,
          startOnTick: false,
          lineWidth: 0,
          minorTickLength: 0,
          tickLength: 0,
          gridLineColor: 'transparent',
          plotLines: [
            {
              color: '#D8D8D8',
              width: 1,
              value: 0,
            },
          ],
          title: {
            enabled: false,
          },
          minorGridLineWidth: 0,
          labels: {
            formatter: function () {
              return this.axis.defaultLabelFormatter.call(this) + '%';
            },
          },
        },
      ],
      xAxis: {
        labels: {
          useHTML: true,
          formatter: function () {
            const locale = component.localeService.getLocale();
            return `<div id="x-axis-${this.value.index}">${component.revUtilChartService.addBenchmarkDiffForRevenueChart(this.value, locale)}</div>`;
          },
        },
        categories: data.map((obj, index) => {
          obj.index = index;
          return obj;
        }),
        plotLines: [
          {
            color: CHART_COLORS.COLOR_000000,
            width: 2,
            dashStyle: 'Dash',
            value: data.length - (component.dashedLineOffset + 0.5), // dashed line at the carryover months.
          },
        ],
      },
      series: [
        {
          type: 'column',
          color: chartColor1,
          data: data.map((obj) => {
            const comparison = component.compareRevenue(obj, mode);
            if (
              component.revUtilChartService.isCurrentCategory(obj) &&
              comparison.diffPct
            ) {
              return {
                y: comparison.diffPct,
                color: CHART_COLORS.COLOR_5779A3,
                borderColor: CHART_COLORS.COLOR_000000,
                borderWidth: 3,
                selected: true,
              };
            }
            return {
              y: comparison.diffPct,
              color: CHART_COLORS.COLOR_5779A3,
            };
          }),
          states: {
            select: {
              color: CHART_COLORS.COLOR_5779A3,
            },
          },
          point: {
            events: {
              // mouseOver: function () {
              // 	if (!this.selected) {

              // 		this.graphic.attr({
              // 			stroke: 'black',
              // 			'stroke-width': 3,
              // 			color: CHART_COLORS.COLOR_5779A3
              // 		});
              // 	}
              // },
              // mouseOut: function () {
              // 	if (!this.selected) {

              // 		this.graphic.attr({
              // 			stroke: '',
              // 			'stroke-width': 0,
              // 			color: CHART_COLORS.COLOR_5779A3
              // 		});

              // 	}
              // },
              click: function () {
                this.selected = !this.selected;

                this.graphic.attr({
                  color: CHART_COLORS.COLOR_5779A3,
                  stroke: 'black',
                  'stroke-width': 3,
                });
              },
            },
          },
          borderRadiusTopLeft: 2,
          borderRadiusTopRight: 2,
          borderRadiusBottomLeft: 2,
          borderRadiusBottomRight: 2,
          pointPadding: 0.2,
          pointPlacement: 0,
        },
      ],
    };

    const config = new HighchartsConfiguration();
    config.exportingOptions = {
      getColumnsConfig: () => {
        return [
          {
            field: 'Month',
            displayName: component.translateService.instant(
              'main.core.charts.export.month'
            ),
            customFormat: (rowValues) => {
              const locale = this.localeService.getLocale();
              return component.revUtilChartService.getShortDate(
                rowValues.Month,
                locale
              );
            },
          },
          {
            field: 'ClientComparedRevenue',
            displayName:
              component.translateService.instant(
                'main.core.charts.export.cilent_compared_revenue'
              ) + ` ${component.formatService.selectedCurrencySymbol}`,
            customFormat: (rowValues) =>
              mode === ComparisonMode.Benchmark
                ? rowValues.RevenueBenchmarked
                : rowValues.RevenueTotal,
          },
          {
            field: 'BenchAvgRevenue',
            displayName:
              component.translateService.instant(
                'main.core.charts.export.bench_avg_revenue'
              ) + ` ${component.formatService.selectedCurrencySymbol}`,
            customFormat: (rowValues) =>
              mode === ComparisonMode.Benchmark
                ? rowValues.RevenueBench
                : rowValues.RevenueBook,
          },
          {
            field: 'RevenueDifference',
            displayName:
              component.translateService.instant(
                'main.core.charts.export.revenue_difference'
              ) + ` ${component.formatService.selectedCurrencySymbol}`,
            customFormat: (rowValues) =>
              mode === ComparisonMode.Benchmark
                ? rowValues.RevenueBenchmarked - rowValues.RevenueBench
                : rowValues.RevenueTotal - rowValues.RevenueBook,
          },
          {
            field: 'RevenueDifferencePercentage',
            displayName: component.translateService.instant(
              'main.core.charts.export.revenue_diff_percent'
            ),
            customFormat: (rowValues) =>
              mode === ComparisonMode.Benchmark
                ? component.formatService.getDifferencePercent(
                    rowValues.RevenueBenchmarked,
                    rowValues.RevenueBench
                  )
                : component.formatService.getDifferencePercent(
                    rowValues.RevenueTotal,
                    rowValues.RevenueBook
                  ),
            isPercent: true,
          },
        ];
      },
      getFileName: () =>
        this.translateService.instant(
          'main.tabs.summary.charts.client_rate_vs_bench.title'
        ),
    };
    config.options = options;
    config.onLoadCallback = (chart) => {
      this.chart = chart;
    };
    this.chartConfig = config;
  }
}
