import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { QueryParams } from './query-params';
import { Observable } from 'rxjs/internal/Observable';
import { switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { ActiveFilterService } from './../query/active-filter.service';
import { ConfigService } from './config.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { PagedQuery, Body, ProductQuery, SearchQuery } from '../../models/body';
import 'rxjs/add/operator/map';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class RdoHttpService {

    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService,
        private filterService: ActiveFilterService,
        private config: ConfigService
    ) { }

    public delete = (...args: any[]): Observable<Record<any, any>> => {
        return this.authenticationService
            .getAuthToken()
            .pipe(
                switchMap(token => {
                    const endpoint = args[0].indexOf('/') === 0 ? args[0] : '/' + args[0];
                    args[1] = args[1] || {};
                    let searchParams = new HttpParams();
                    if (args.length > 1 && args[1]) {
                        searchParams = this.toHttpParams(args[1].search as QueryParams);
                    }
                    return this.config.baseApiUrl.pipe(switchMap(apiUrl => {
                        const url = apiUrl + endpoint;
                        const headers = new HttpHeaders().set('Authorization', `Bearer ${token.AccessToken}`);
                        const result = this.http.delete(url, { headers, params: searchParams, withCredentials: true });
                        return result;
                    }));
                }));
    }

    public get = (...args: any[]): Observable<Record<any, any>> => {
        return this.authenticationService
            .getAuthToken()
            .pipe(switchMap(token => {
                let endpoint = args[0].indexOf('/') === 0 ? args[0] : '/' + args[0];
                args[1] = args[1] || {};
                let searchParams = new HttpParams();

                if (args.length > 1 && args[1]) {
                    searchParams = this.toHttpParams(args[1].search as QueryParams);
                }

                if (this.authenticationService &&
                    this.authenticationService._userInfoView &&
                    this.authenticationService._userInfoView.SelectedClient) {

                    let delimit = '?';
                    if (endpoint.toLowerCase().indexOf('clientid=') === -1) {
                        if (endpoint.indexOf('?') > -1) {
                            delimit = '&';
                        }
                        endpoint = endpoint + '?' + 'ClientId=' + this.authenticationService._userInfoView.SelectedClient.ClientID.toString(); // this.filterService.clientId.trim();
                    }
                }

                return this.config.baseApiUrl.pipe(switchMap(apiUrl => {
                    let result = new Observable();
                    if (token) {
                        if (token.AccessToken) {
                            const url = apiUrl + endpoint;
                            const headers = new HttpHeaders().set('Authorization', `Bearer ${token.AccessToken}`);
                            result = this.http.get(url, { headers, params: searchParams, withCredentials: true });
                        }
                    }
                    return result;
                }));
            }));
    }

    public getFile = (endpoint: string): Observable<Record<any, any>[]> => {

        return Observable.create(observer => {

            this.authenticationService.getAuthToken().subscribe(token => {

                endpoint = endpoint.indexOf('/') === 0 ? endpoint : '/' + endpoint;

                // let postBody = {};
                // Object.assign(postBody, pagedSorted);
                // Object.assign(postBody, exportData);

                // this.filterService.filterParams.subscribe(f => {

                //// overwrite the selected rouse categories only if one passed from global filter
                // if (_.isNil(postBody['RouseCategoryList']) || !postBody['RouseCategoryList'].length) {
                //    Object.assign(postBody, f);
                // } else {
                //    let currentParams = f || new SearchQuery();
                //    currentParams.RouseCategoryList = postBody['RouseCategoryList'];
                //    Object.assign(postBody, currentParams);
                // }

                // Object.assign(postBody, f);

                if (this.authenticationService &&
                    this.authenticationService._userInfoView &&
                    this.authenticationService._userInfoView.SelectedClient) {

                    let delimit = '?';

                    if (endpoint.toLowerCase().indexOf('clientid=') === -1) {
                        if (endpoint.indexOf('?') > -1) {
                            delimit = '&';
                        }
                        endpoint = endpoint + '?' + 'ClientId=' + this.authenticationService._userInfoView.SelectedClient.ClientID.toString();
                    }
                }

                this.config.baseApiUrl.subscribe(apiUrl => {

                    const url = apiUrl + endpoint;

                    const xhr = new XMLHttpRequest();

                    xhr.open('GET', url, true);
                    // xhr.setRequestHeader('Content-Type', 'application/json');
                    xhr.setRequestHeader('Authorization', 'Bearer ' + token.AccessToken);
                    xhr.withCredentials = true;
                    xhr.responseType = 'blob';

                    xhr.onreadystatechange = () => {
                        if (xhr.readyState === 4) {
                            if (xhr.status === 200) {
                                const contentType = xhr.getResponseHeader('content-type') || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                                const blob = new Blob([xhr.response], { type: contentType });
                                observer.next(blob);
                                observer.complete();
                            } else {
                                observer.error(xhr.response);
                            }
                        }
                    };

                    xhr.send(); // JSON.stringify(postBody));
                });
                // });
            });
        });
    }

    public patch = (...args: any[]): Observable<Record<any, any>> => {
        return this.authenticationService
            .getAuthToken()
            .pipe(switchMap(token => {
                const endpoint = args[0].indexOf('/') === 0 ? args[0] : '/' + args[0];
                args[1] = args[1] || {};

                return this.config.baseApiUrl.pipe(switchMap(apiUrl => {
                    const url = apiUrl + endpoint;
                    const headers = new HttpHeaders().set('Authorization', `Bearer ${token.AccessToken}`);

                    return this.http.patch(url, args[1], { withCredentials: true, headers });
                }));
            }));
    }

    public put = (...args: any[]): Observable<Record<any, any>> => {
        return this.authenticationService
            .getAuthToken()
            .pipe(switchMap(token => {
                const endpoint = args[0].indexOf('/') === 0 ? args[0] : '/' + args[0];
                args[1] = args[1] || {};

                return this.config.baseApiUrl.pipe(switchMap(apiUrl => {
                    const url = apiUrl + endpoint;
                    const headers = new HttpHeaders().set('Authorization', `Bearer ${token.AccessToken}`);
                    return this.http.put(url, args[1], { headers, withCredentials: true });
                }));
            }));
    }

    public post = (endpoint: string, content?: Body, overrideSearchQuery?: SearchQuery, searchCriteria: string = null): Observable<Record<any, any>> => {
        endpoint = !endpoint.indexOf('/') ? endpoint : '/' + endpoint;
        if (!content) {
            content = new ProductQuery();
        }
        return this.authenticationService
            .getAuthToken()
            .pipe(switchMap(token => {
                if (token && token.AccessToken) {
                    const filterParams = this.filterService.getFilterParams();
                    if (filterParams !== undefined) {
                        // replace rouse categories if it is null and just use global filter
                        if (_.isNil((content as ProductQuery).RouseCategoryList) || !(content as ProductQuery).RouseCategoryList.length) {
                            if (filterParams.RouseCategoryList && !!filterParams.RouseCategoryList.length) {
                                (content as ProductQuery).RouseCategoryList = filterParams.RouseCategoryList.filter(f => !!f).slice();
                            }
                        }
                    }

                    const postBody: any = {};
                    Object.assign(postBody, overrideSearchQuery || filterParams);
                    Object.assign(postBody, content);
                    if (searchCriteria) {
                        postBody.SearchCriteria = searchCriteria;
                    }
                    if (this.authenticationService &&
                        this.authenticationService._userInfoView &&
                        this.authenticationService._userInfoView) {

                        // postBody.ClientId = f.ClientId;
                        postBody.ClientId = this.authenticationService._userInfoView.SelectedClient.ClientID.toString();
                    }
                    const headers = new HttpHeaders().set('Authorization', `Bearer ${token.AccessToken}`);
                    return this.config.baseApiUrl.pipe(switchMap(apiUrl => {
                        const url = apiUrl + endpoint;
                        return this.http.post(url, postBody, { headers, withCredentials: true });
                    }));
                }
            }));
    }

    public pivotPost = (endpoint: string, content?: Body): Observable<Record<any, any>> => {
        endpoint = !endpoint.indexOf('/') ? endpoint : '/' + endpoint;
        return this.authenticationService
            .getAuthToken()
            .pipe(switchMap(token => {
                return this.config.baseApiUrl.pipe(switchMap(apiUrl => {
                    let result = new Observable();
                    if (token) {
                        if (token.AccessToken) {
                            const url = apiUrl + endpoint;
                            const headers = new HttpHeaders().set('Authorization', `Bearer ${token.AccessToken}`);
                            result = this.http.post(url, content, { headers, withCredentials: true });
                        }
                    }
                    return result;
                }));
            }));
    }

    /**
     * Issues http GET request with global filter parameters appended and returns observable
     * @param args same as Http.get
     */
    public getf(...args: any[]): Observable<Record<any, any>> {
        return this.getInternal({ args });
    }

    /**
     * Issues http GET request with global filter parameters appended and returns observable
     * Allows to remove specified query parameters
     * @params except array of string representing parameter names to remove from query
     * @param args same as Http.get
     */
    public getfx(except: string[], ...args: any[]): Observable<Record<any, any>> {
        return this.getInternal({ except, args });
    }

    private getInternal(req: any): Observable<Record<any, any>> {
        const except = req.except;
        const args = req.args;
        return this.authenticationService
            .getAuthToken()
            .pipe(switchMap(token => {
                let endpoint = args[0].indexOf('/') === 0 ? args[0] : '/' + args[0];
                args[1] = args[1] || {};
                let searchParams: QueryParams = null;
                const currentParams = new QueryParams();
                if (args.length > 1 && args[1]) {
                    searchParams = args[1].search as QueryParams;
                }
                if (this.authenticationService &&
                    this.authenticationService._userInfoView &&
                    this.authenticationService._userInfoView.SelectedClient) {
                    let delimit = '?';
                    if (endpoint.toLowerCase().indexOf('clientid=') === -1) {
                        if (endpoint.indexOf('?') > -1) {
                            delimit = '&';
                        }
                        endpoint = endpoint + '?' + 'ClientId=' + this.authenticationService._userInfoView.SelectedClient.ClientID.toString(); // this.filterService.clientId.trim();
                    }
                }
                return this.filterService.filterParamsForGet.pipe(switchMap(f => {
                    searchParams = searchParams || new QueryParams;
                    currentParams.appendAll(f);
                    // replace rouse categories from global filter
                    // only if it is null in search params
                    if (searchParams.has('RouseCategoryList')) {
                        currentParams.delete('RouseCategoryList');
                    }
                    if (except) {
                        _.forEach(except, p => { currentParams.delete(p); });
                    }
                    searchParams.appendAll(currentParams);
                    if (token) {
                        if (token.AccessToken) {
                            const headers = new HttpHeaders().set('Authorization', `Bearer ${token.AccessToken}`);
                            const params = this.toHttpParams(searchParams);
                            return this.config.baseApiUrl.pipe(switchMap(apiUrl => {
                                const url = apiUrl + endpoint;
                                return this.http.get(url, { headers, params });
                            }));
                        }
                    }
                }));
            }));
    }

    private normalizeBodyParameter = (key: string, value: string[]): any => {
        const collectionParams = ['CycleBillRange', 'RegionList', 'DistrictList', 'BranchList'];
        if (collectionParams.indexOf(key) > -1) {
            return value;
        }
        return value.length === 1 ? value[0] : value;
    }

    // TODO: remove this and just use HttpParams across the application
    private toHttpParams = (params: QueryParams): HttpParams => {
        if (!params) {
            return new HttpParams();
        }
        let httpParams = new HttpParams();
        Array.from(params.paramsMap.entries()).forEach(entry => {
            if (entry[1].length === 1) {
                httpParams = httpParams.append(entry[0], entry[1][0]);
            } else {
                entry[1].forEach(item => {
                    httpParams = httpParams.append(entry[0], item);
                });
            }
        });
        // try {
        if (sessionStorage.getItem('MultiClientId') != null) {
            httpParams = httpParams.append('MultiClientId', sessionStorage.getItem('MultiClientId'));
        }
        // }| catch (e) {
        //    var s = '';
        // } finally { }
        return httpParams;
    }

    public uploadFileToBucket = (clientid: number, f: File): Observable<Record<any, any>> => {
        const result: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
        const endpoint = '/upload';
        return this.authenticationService.getAuthToken().pipe(switchMap(token => {
            if (token && token.AccessToken) {
                const formdata = new FormData();
                formdata.append(f.name, f);
                if (this.authenticationService && this.authenticationService._userInfoView && this.authenticationService._userInfoView) {
                    formdata.append('ClientId', this.authenticationService._userInfoView.SelectedClient.ClientID.toString());
                }
                const headers = new HttpHeaders().set('Authorization', `Bearer ${token.AccessToken}`);
                headers.append('Content-Type', undefined);
                return this.config.baseApiUrl.pipe(switchMap(apiUrl => {
                    const url = apiUrl + endpoint;
                    return this.http.post(url, formdata, { headers, withCredentials: true });
                }));
            }
        }));
    }
}

// TODO PRS DEDUPE / todo <-- whatever the original todo was, also, move to own file
// export class ExportSpreadsheetBody {
// 	Columns: any;
// 	Filters: any;

// 	constructor(columns: any, filters: any) {
// 		this.Columns = JSON.stringify(columns);
// 		this.Filters = JSON.stringify(filters);
// 	}
// }
