// auth0 supported languages
export const SupportedLocaleDictionary = {
    'en-US': 'en', 
    'fr-CA': 'fr', 
    'de-DE': 'de', 
    'ja-JP': 'ja',
    'en-GB': 'en'
    // "zh-CN": "zh-CN",
    // "zh-TW": "zh-TW",
    // "da": "da",
    // "de-DE": "de",
    // "de-LI": "de",
    // "de-LU": "de",
    // "de-CH": "de",
    // "en-US": "en",
    // "fr-CA": "fr",
    // "fr-FR": "fr",
    // "it-CH": "it",
    // "it-IT": "it",
    // "ja-JP": "ja",
    // "nl-BE": "nl",
    // "nl-NL": "nl",
    // "no-NO": "nb",
    // "pt-BR": "pt",
    // "pt-PT": "pt",
    // "ru": "ru",
    // "ru-MO": "ru",
    // "es-es": "es",
    // "sv-FI": "sv",
    // "sv-SE": "sv"
}