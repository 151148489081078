import { Subscriber } from '../Subscriber';
export function pairwise() {
  return source => source.lift(new PairwiseOperator());
}
class PairwiseOperator {
  call(subscriber, source) {
    return source.subscribe(new PairwiseSubscriber(subscriber));
  }
}
class PairwiseSubscriber extends Subscriber {
  constructor(destination) {
    super(destination);
    this.hasPrev = false;
  }
  _next(value) {
    let pair;
    if (this.hasPrev) {
      pair = [this.prev, value];
    } else {
      this.hasPrev = true;
    }
    this.prev = value;
    if (pair) {
      this.destination.next(pair);
    }
  }
}
