import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CookieConsentService } from '../core/services/cookie-consent.service';

@Component({
  selector: 'rdo-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent {
  ccmVisible: boolean = false;
  theScript = null;
  showBlackBar = false;
  constructor(
    private cookieConsentService: CookieConsentService
  ) {
    this.cookieConsentService.watchForBlackBar();
    this.cookieConsentService.showBlackBar.subscribe(value => {
      this.showBlackBar = value;
      if(this.showBlackBar) {
        const ccm = this.cookieConsentService.getForcedCcm();
        if(ccm) {
          this.loadScript(ccm);
        }
      }
    });
  }

  getRandomInt() {
    return Math.floor(Math.random() * 10000);
  }

  loadScript(params) {
    this.cookieConsentService.cookiesAccepted.next(false);
    let url = '//consent.trustarc.com/notice?domain=rouse.ritchiebros.com&c=teconsent&js=nj&noticeType=bb&gtm=1&text=true';
    if (params) {
       url += `&country=${params}`;
    }
    url += `&rand=${this.getRandomInt().toString()}`;
   
    this.theScript = document.createElement('script'); // creates the script tag
    this.theScript.src = url  // sets the source (insert url in between quotes)
    this.theScript.type = 'text/javascript'; // set the script type
    this.theScript.async = true; // makes script run asynchronously
    this.theScript.crossOrigin = '';
    // append to head of document
    document.getElementsByTagName('head')[0].appendChild(this.theScript);
    this.listenForBlackBarInteractions();
    this.cookieConsentService.watchForBlackBar();
  }

  /**
   * Starts listening for clicks on the coockie acceptance buttons.
   */
  listenForBlackBarInteractions() {
    const btnSelectors = ['#truste-consent-button', '#truste-consent-required'];
    btnSelectors.forEach(selector => {
      this.addClickAcceptanceEventListener(selector);
    });
  }

  /**
   * Adds an event listener for clicks for the element with the
   * given selector. If the element is not found, more attempts
   * are made.
   */
  addClickAcceptanceEventListener(selector: string, remainingAttempts: number = 5): void {
    setTimeout(() => {
      const elem = document.querySelector(selector);
      if(elem && elem.addEventListener) {
        elem.addEventListener('click', ()=> {
          this.cookieConsentService.blackBarIsActuallyVisible.next(false);
          this.cookieConsentService.cookiesAccepted.next(true);
        });
      } else if(remainingAttempts > 0) { // if the element is not found, try again latter
        this.addClickAcceptanceEventListener(selector, remainingAttempts--);
      }
    }, 1000);
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if(event.altKey === true && event.ctrlKey === true && event.key === '6') {
      this.ccmVisible = true;
    }
  }

  handleCCMClick(value) {
    this.ccmVisible = false;
    this.loadScript(value);
  }
}
