import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs'; //Observable';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs'; // /observable/of';

export class Token {
	constructor(public token: string, public validTo: Date) {

	}
}

@Injectable()
export class AuthService {
	private token: Token;
	constructor(private http: HttpClient) {
	}

	getAuthToken(): Observable<Token> {
		if (this.token && this.token.validTo > new Date()) {

			return of(this.token);
		}
		return this.http.get('/account/token')
			.pipe(map(r => this.createToken(r)), catchError((e: any, t: Observable<Token>) => {
				console.warn('Could not get token. Logging out');
				window.location.href = '/signout';
				return of(<Token>null);
			}));
	}

	private createToken(r: any): Token {
		const obj = r;
		const validTo = <string>obj.ValidTo;
		this.token = new Token(<string>obj.Token, new Date(parseInt(validTo.substr(6))));
		return this.token;
	}
}
