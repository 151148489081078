import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { TxAttributes } from '../../../app/models/transaction-attributes';
import { UserInfo } from '../../../app/models/user-info';
import { FilterDefault } from '../../../app/models/filter-default';
import { RdoHttpService } from '../../../app/core/http/rdo-http.service';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
	providedIn: 'root',
})
export class ClientProfileService {
	public txAttributes: TxAttributes;
	private clientAttributesSubject = new Subject<TxAttributes>();
	private currentClientId: number;
	public selectedLocaleSubject = new Subject<any>();
	public selectedLocaleFilterDefault: any;
	clientAttributes = this.clientAttributesSubject.asObservable();
	constructor(
		private authenticationService: AuthenticationService,
		private http: RdoHttpService
	) {
		this.loadClientAttributes();
		this.authenticationService.selectedClientId.subscribe((clientId) => {
			this.currentClientId = clientId;
		});
	}

	public setSelectedLocaleFilterDefault = (filterValue: any) => {
		this.selectedLocaleFilterDefault = filterValue;
		this.selectedLocaleSubject.next(filterValue);
	}

	public getClientAttributes = (): Observable<TxAttributes> => {
		return this.http.get('/filter/tx-attributes').pipe(map(this.createClientCredentials));
	}

	public getClientFilterDefaults = (): Observable<Array<FilterDefault>> => {
		return this.http.get('/filter/filter-defaults/' + this.currentClientId.toString()).pipe(map(x => <Array<FilterDefault>>x));
	}

	public getUserInfo = (): Observable<UserInfo> => {
		return this.http.get('identity/users/me')
			.pipe(map(x => x as UserInfo));
	}

	private loadClientAttributes = () => {
		this.http.get('/filter/tx-attributes').pipe(map(this.createClientCredentials))
			.subscribe(attributes => this.clientAttributesSubject.next(attributes));
	}

	private createClientCredentials = (resp: any): TxAttributes => {
		this.txAttributes = resp[0];
		return this.txAttributes;
	}
}
