import {
  Component,
  OnDestroy,
  OnInit,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { zip } from 'rxjs/observable/zip';
import {
  BreadcrumbService,
  ActiveFilterService,
  PageOptions,
  SortOptions,
  HistogramBucket,
  FirestoreSettingsService,
  GridSortingService,
} from './../../../core';
import { EquipmentService } from './../../equipment.service';
import { SelectedProductTypeService } from './../selected-product-type.service';
import { ProductTypeBaseComponent } from './../product-type-base.component';
import { Interval } from './../../../models';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { FilterProfileService } from '../../../filter/profiles/filter-profile.service';
import { CODENAMES } from '../../../core/constants';

export class RateTypeData {
  clientBenchmarkedRevenue: number;
  benchmarkedRevenue: number;
}

@Component({
  selector: 'rdo-product-type-rate-types',
  templateUrl: 'product-type-rate-types.component.html',
})
export class ProductTypeRateTypesComponent
  extends ProductTypeBaseComponent
  implements OnInit, OnDestroy
{
  @ViewChild('rateTypesScreen') rateTypesScreen: ElementRef;
  public chartWidth: number;

  hourlyRateType: RateTypeData = new RateTypeData();
  dailyRateType: RateTypeData = new RateTypeData();
  weeklyRateType: RateTypeData = new RateTypeData();
  monthlyRateType: RateTypeData = new RateTypeData();

  monthlyRateTrend: Array<any>;
  weeklyRateTrend: Array<any>;
  dailyRateTrend: Array<any>;
  hourlyRateTrend: Array<any>;

  paging: PageOptions = new PageOptions();
  sorting: SortOptions; // todo: RDO-1119

  monthlyHistogramData: any;
  weeklyHistogramData: any;
  dailyHistogramData: any;
  hourlyHistogramData: any;

  private loading = true;
  private clientId: string;
  private showHourlyRate = true;
  private gridName: string = 'PRODUCT_TYPE_RATETYPE_GRID';

  constructor(
    private equipmentService: EquipmentService,
    private router: Router,
    private route: ActivatedRoute,
    private activeFilterService: ActiveFilterService,
    selectedProductTypeService: SelectedProductTypeService,
    breadcrumbService: BreadcrumbService,
    private hostElement: ElementRef,
    private firestoreSettingsService: FirestoreSettingsService,
    private authenticationService: AuthenticationService,
    private gridSortingService: GridSortingService,
    private filterProfileService: FilterProfileService
  ) {
    super(selectedProductTypeService, breadcrumbService, activeFilterService);
    this.clientId =
      this.authenticationService._userInfoView.SelectedClient.ClientID.toString();
  }

  ngOnInit() {
    this.gridSortingService.setGridName(this.gridName);
    this.sorting = this.gridSortingService.getSortOptions();

    this.subscriptions.push(
      this.route.parent.params.subscribe((params) => {
        const routeParams = this.getRouteParams(params);
        if (this.hasRouteChanged(this.params, routeParams)) {
          this.params = routeParams;
          this.doUpdateBreadcrumbs();
          this.load();
        }
      })
    );

    this.subscriptions.push(
      this.activeFilterService.filterChange.subscribe((_) => {
        this.hostElement.nativeElement.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        });
        this.load();
      })
    );

    this.subscriptions.push(
      this.selectedProductTypeService.productTypeChange.subscribe((p) => {
        this.doUpdateBreadcrumbs();

        // When useRentAsProductType is active then we have to use the Description property as the product type
        //  product types will not match in this case.
        const currentProductType = this.params.useRentedAsProductType
          ? p.Description
          : p.ProductType;
        if (this.params.productType !== currentProductType) {
          this.hostElement.nativeElement.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
          });
        }
        this.selectedProductTypeService.loading = true;
        this.params.productType = currentProductType;
        this.load();
      })
    );

    if (!this.selectedProductTypeService.selectedProductType) {
      this.subscriptions.push(
        this.selectedProductTypeService.productType.subscribe((p) => {
          this.doUpdateBreadcrumbs();
        })
      );
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private doUpdateBreadcrumbs() {
    this.updateBreadcrumbs([
      {
        title: 'main.core.common.counts.rate_types.plural',
        class: 'active',
      },
    ]);
  }

  private load = () => {
    if (!this.params.productType) {
      this.loading = false;
      return;
    }
    this.loading = true;
    this.sorting.sortOn = this.gridSortingService.getSortFieldOrDefault(); // todo: RDO-1119
    this.sorting.descending =
      this.gridSortingService.getSortDirectionOrDefault() === -1;
    const histogramOptions = {
      mode: Interval.Monthly,
      productType: this.params.productType,
      category: this.params.category,
      useRentedAsProductType: this.params.useRentedAsProductType,
    };
    zip(
      this.equipmentService.getProductTypeRateTypes({
        productType: this.params.productType,
        category: this.params.category,
        useRentedAsProductType: this.params.useRentedAsProductType,
      }),

      /*
            { mode: Interval, productType: string, category?: string, useRentedAsProductType?: boolean }
            * */
      this.equipmentService.getHistogramData(histogramOptions),
      this.equipmentService.getHistogramData(
        Object.assign({}, histogramOptions, { mode: Interval.Weekly })
      ),
      this.equipmentService.getHistogramData(
        Object.assign({}, histogramOptions, { mode: Interval.Daily })
      ),
      this.equipmentService.getHistogramData(
        Object.assign({}, histogramOptions, { mode: Interval.Hourly })
      ),
      this.equipmentService.getProductTypeRateTrends(
        {
          productType: this.params.productType,
          category: this.params.category,
          useRentedAsProductType: this.params.useRentedAsProductType,
        },
        this.paging,
        this.sorting
      ),
      this.filterProfileService.wait4CurrentProfile()
    ).subscribe((results: [any, any, any, any, any, any, boolean]) => {
      this.monthlyRateType = results[0].Items[0];
      this.weeklyRateType = results[0].Items[2];
      this.dailyRateType = results[0].Items[3];
      this.hourlyRateType = results[0].Items[4] || {};

      this.monthlyHistogramData = results[1];
      this.weeklyHistogramData = results[2];
      this.dailyHistogramData = results[3];
      this.hourlyHistogramData = results[4];

      this.monthlyRateTrend = results[5].MonthlyRateTrend;
      this.weeklyRateTrend = results[5].WeeklyRateTrend;
      this.dailyRateTrend = results[5].DailyRateTrend;
      this.hourlyRateTrend = results[5].HourlyRateTrend;
      this.selectedProductTypeService.loading = false;
      this.loading = false;
      this.showHourlyRate =
        this.filterProfileService.readCurrentProfileProperty(
          CODENAMES.CN_SHOW_HOURLY_RATE
        ) !== false; // nulls are defaulted to true
      this.chartWidth = this.calculateChartWidth();
    });
  };

  /**
   * Calculates each chart's width based on the number of charts displayed.
   */
  public calculateChartWidth() {
    if (this.rateTypesScreen) {
      let width =
        this.rateTypesScreen.nativeElement.clientWidth +
        2 * this.rateTypesScreen.nativeElement.offsetLeft;
      width = width / (this.showHourlyRate ? 4 : 3) - 30;
      return width;
    }
  }

  public widthClass() {
    return 'col-xs-' + (this.showHourlyRate ? 3 : 4);
  }

  private handleHistogramClick = (
    bucket: HistogramBucket,
    rateType: string
  ) => {
    const rtPath = rateType.split('.');
    let navRateType = rtPath.length ? rtPath[rtPath.length - 1] : rateType;
    navRateType = navRateType.charAt(0).toUpperCase() + navRateType.slice(1);
    const oldParams = this.params.clone();
    const route = [
      '/equipment',
      'product-types',
      this.params.productType,
      oldParams,
      'transactions',
      {
        fromValue: bucket.from,
        toValue: bucket.to,
        rateType: navRateType,
      },
    ];

    const from = bucket.displayFrom.toLocaleString();
    const to = bucket.displayTo.toLocaleString();

    this.updateBreadcrumbs([
      {
        title: `${from} - ${to} ${rateType} main.core.common.counts.transactions.plural`,
        class: 'active',
      },
    ]);
    this.router.navigate(route);
  };
}
