import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { FormatService } from '../core/query/format.service';
import { BreadcrumbService } from '../core/view/breadcrumb/breadcrumb.service';
import { RdoHttpService } from '../core/http/rdo-http.service';
import { ClientUserRole, PageOptionsNg, SearchQuery, SortOptionsNg } from '../models';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
/* eslint-disable @typescript-eslint/unbound-method */
@Injectable({
    providedIn: 'root'
})
export class DownloadsService {
    clientDownloadUsersLoaded = new Subject<Array<ClientUserRole>>();
    clientDownloadUsers: Array<ClientUserRole>;

    constructor(
        private authenticationService: AuthenticationService,
        private rdoHttp: RdoHttpService,
        private formatService: FormatService,
        private breadCrumbService: BreadcrumbService,
    ) { }

    public getDownloads = (sorting: SortOptionsNg, paging: PageOptionsNg, searchCriteria: string = null): Observable<any> => {
        return this
            .rdoHttp
            .post(`downloads`, {
                OrderBy: sorting.sortField || '',
                OrderByDirection: sorting.sortOrder || '',
                PageSize: paging.pageSize || '',
                Page: paging.page || ''
            },
                null,
                searchCriteria)
            .pipe(map(p => {
                const result = (p as any);
                const items = result.results;
                _.forEach(items, i => {
                    let userId = i.MetaData.UserId || '';
                    if (userId) {
                        const splittedUserId = userId.split('|');
                        if (splittedUserId.length > 1) {
                            userId = splittedUserId[1];
                        }
                    }
                    i.MetaData.UserId = userId.toLowerCase();
                    i.Modified = new Date(i.Modified);
                });
                return {
                    totalCount: result.totalCount,
                    items: items
                }
            }));
    }

    public uploadFile = (f: File): Observable<any> => {

        const result: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

        if (this.authenticationService && this.authenticationService._userInfoView && this.authenticationService._userInfoView) {

            const clientid = this.authenticationService._userInfoView.SelectedClient.ClientID;

            this.rdoHttp.uploadFileToBucket(clientid, f).subscribe((response: any) => {

                result.next(response);

            }, error => {

                result.next(error);
            });
        }

        return result;
    }

    public addUser = (userid: number, fileid: number, filename: string): Observable<any> => {

        const result: BehaviorSubject<object> = new BehaviorSubject<object>(undefined);

        if (this.authenticationService && this.authenticationService._userInfoView && this.authenticationService._userInfoView) {

            const clientid = this.authenticationService._userInfoView.SelectedClient.ClientID;

            this.rdoHttp.post('adduser', { UserId: userid, FileId: fileid, FileName: filename }).subscribe(response => {

                result.next(response);

            }, error => {

                result.next(error);
            });
        }

        return result;
    }

    public removeUser = (userid: number, fileid: number): Observable<any> => {

        const result: BehaviorSubject<object> = new BehaviorSubject<object>(undefined);

        if (this.authenticationService && this.authenticationService._userInfoView && this.authenticationService._userInfoView) {

            const clientid = this.authenticationService._userInfoView.SelectedClient.ClientID;

            this.rdoHttp.post('removeuser', { UserId: userid, FileId: fileid }).subscribe(response => {

                result.next(response);

            }, error => {

                result.next(error);
            });
        }

        return result;
    }

    public getAllClientDownloadUsers = () => {

        this.rdoHttp.post('getclientusers').subscribe((response: Array<ClientUserRole>) => {
            this.clientDownloadUsers = response;
            this.clientDownloadUsersLoaded.next(response);

        }, error => {

            throwError(error);
        });

    }

    public deleteFile = (fileid: number, filename: string): Observable<any> => {

        const result: BehaviorSubject<object> = new BehaviorSubject<object>(undefined);

        if (this.authenticationService && this.authenticationService._userInfoView && this.authenticationService._userInfoView) {

            const clientid = this.authenticationService._userInfoView.SelectedClient.ClientID;

            this.rdoHttp.post('deletefile', { FileId: fileid, FileName: filename }).subscribe(response => {

                result.next(response);

            }, error => {

                result.next(error);
            });
        }

        return result;
    }

    private buildFileName(): string {
        const today = new Date();
        const dd = today.getDate();
        const mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        const todayFormatted = `${mm < 10 ? '0' + mm.toString() : mm.toString()}-${dd < 10 ? '0' + dd.toString() : dd.toString()}-${yyyy}`;
        const breadCrumbInfo = this.breadCrumbService.instructions.map((ins) => ins.title).join(' - ');
        const translatedInfo = this.formatService.translateAndFormat(breadCrumbInfo, true);
        const name = `${translatedInfo} - ${todayFormatted}.xlsx`;
        return name;
    }

    public saveExcelBlob = (blob, fileName: string = null) => {
        /*const filename = 'gridexport_' + now.getFullYear() + ((((now.getMonth() + 1).toString().length < 2)) ? '0' : '')
        + (now.getMonth() + 1) + (((now.getDate().toString().length < 2)) ? '0' : '') + now.getDate()
        + (((now.getHours().toString().length < 2)) ? '0' : '') + now.getHours()
        + (((now.getMinutes().toString().length < 2)) ? '0' : '') + now.getMinutes()
        + (((now.getSeconds().toString().length < 2)) ? '0' : '') + now.getSeconds() + '.xlsx';*/
        let filename = fileName || this.buildFileName();
        filename = filename.replace(/[/\\?%*:|"<>]/g, '');
        if (navigator.saveBlob) {
            navigator.saveBlob(blob, filename);
        } else {
            const saveBlob = navigator.webkitSaveBlob || navigator.mozSaveBlob || navigator.saveBlob;
            if (saveBlob) {
                navigator.saveBlob(blob, filename);
            } else {
                const urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;;
                const url = urlCreator.createObjectURL(blob);
                try {
                    const link = document.createElement('a');
                    link.setAttribute('href', url);
                    link.setAttribute('download', filename);
                    const event = document.createEvent('MouseEvents');
                    event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
                    link.dispatchEvent(event);
                } catch (e) {
                    window.location.href = url;
                } finally {
                    urlCreator.revokeObjectURL(url);
                }
            }
        }
    }

    public updateRestrictedUsersList = (userids: Array<any>, fileid: number, filename: string): Observable<any> => {

        const result: BehaviorSubject<object> = new BehaviorSubject<object>(undefined);

        if (this.authenticationService && this.authenticationService._userInfoView && this.authenticationService._userInfoView) {

            const clientid = this.authenticationService._userInfoView.SelectedClient.ClientID;

            this.rdoHttp.post('updateRestrictedUsersList', { UserIds: userids, FileId: fileid, FileName: filename }).subscribe(response => {

                result.next(response);

            }, error => {

                result.next(error);
            });
        }

        return result;
    }
}
