import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

import { LazyLoadEvent } from 'primeng/api';

import {
	ActiveFilterService,
	ColumnDefinitionService,
	MetricsGridConfig,
	BreadcrumbService,
	GridTableConfiguredExpandableComponent,
	FormatService,
	GridSortingService
} from '../core';

import { DownloadsService } from '../downloads';
import { HeaderService } from '../header';

import { CustomerBaseComponent } from './customer-base.component';
import { SelectedCustomerService } from './selected-customer.service';
import { CustomerSalesRepService } from './customer-sales-rep.service';

import { PageOptionsNg, SortOptionsNg, SortOptions } from '../models';
import { AuthenticationService } from '../core/authentication/authentication.service';

@Component({
	selector: 'rdo-customer-sales-reps',
	template: `
    <rdo-overlay [enabled]="loading"></rdo-overlay>
    <rdo-grid-table-expanded-ng
      #customerSalesRepsGrid
      *ngIf="data && totalCount > 0"
      [name]="gridName"
      [dataKey]="dataKey"
      [gridConfig]="gridConfig"
      [rowExpansion]="gridConfig.rowExpansion"
      [pagedData]="data"
      [frozenRows]="frozenRows"
      [sorting]="sorting"
      [(paging)]="paging"
      (pageOnChange)="changePage($event)"
      (lazyLoadOnChange)="changeLazyLoad($event)"
      [totalCount]="totalCount"
      [frozenWidth]="frozenWidth"
      [showColumnSelector]="true"
      (downloadExcelClick)="exportExcel($event)"
    >
    </rdo-grid-table-expanded-ng>
  `,
})

export class CustomerSalesRepsComponent extends CustomerBaseComponent implements OnInit, OnDestroy {
	@ViewChild('customerSalesRepsGrid') customerSalesRepGrid: GridTableConfiguredExpandableComponent;
	loading = true;
	gridName = 'CUSTOMER_SALESREPS_GRID';
	data: any;
	customerId: number;
	gridConfig: MetricsGridConfig;
	frozenRows: any;
	paging = new PageOptionsNg();
	sorting: SortOptionsNg;
	totalCount = 0;
	frozenWidth: string;
	dataKey = 'SalesRepId';

	constructor(
		private authenticationService: AuthenticationService,
		private columnService: ColumnDefinitionService,
		private customerSalesRepService: CustomerSalesRepService,
		private downloadsService: DownloadsService,
		private route: ActivatedRoute,
		private router: Router,
		selectedCustomerService: SelectedCustomerService,
		breadcrumbService: BreadcrumbService,
		private location: Location,
		private filterService: ActiveFilterService,
		private headerService: HeaderService,
		private formatService: FormatService,
		private gridSortingService: GridSortingService,
	) {
		super(selectedCustomerService, breadcrumbService);
	}

	ngOnInit() {
		this.gridSortingService.setGridName(this.gridName);
		this.sorting = this.gridSortingService.getSortOptionsNg();

		// eslint-disable-next-line
		const component = this;
		let customerId = component.customerId;
		component.subscriptions.push(component.route.parent.params.subscribe(params => {
			customerId = params.customer;
			if (component.customerId !== customerId) {
				component.customerId = customerId;
				component.configureGrid();
				component.load();
			}
		}));
		component.subscriptions.push(
			component.selectedCustomerService.customerChange.subscribe(c => {
				component.customerId = c.CustomerId;
				customerId = c.CustomerId;
				this.paging.page = 1;
				if (component.customerSalesRepGrid) {
					component.customerSalesRepGrid.resetPaginator();
				}
				component.load();
			}));
		component.subscriptions.push(
			component.filterService.filterChange.subscribe(component.load)
		);
		component.subscriptions.push(component.authenticationService.userInfoView.subscribe(userInfo => {
			if (this.gridConfig) {
				this.gridConfig.enableExcelExport = userInfo.HasClientAccessToExportData;
			}
		}));
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	load = () => {
		this.loading = true;
		const sorting = this.getSorting();
		this.customerSalesRepService.getCustomerSalesReps(this.customerId, this.paging, sorting).subscribe(result => {
			this.loading = false;
			if (result.Items && result.Items.length > 0) {
				this.data = result.Items;
			} else {
				this.data = [];
			}
			this.totalCount = result.TotalCount;
		});
		this.updateBreadcrumbs([
			{ title: 'main.core.common.counts.sales_reps.plural', class: 'active' }
		]);
	}

	changePage = (event: any) => {
		if (event.rows !== this.paging.pageSize) {
			this.paging.pageSize = event.rows;
		}
		this.paging.page = event.first < 1 ? 1 : ((event.first / event.rows) + 1);
		this.load();
	}

	changeLazyLoad = (event: LazyLoadEvent) => {
		if (this.sorting.sortField === event.sortField && this.sorting.sortOrder === event.sortOrder) {
			return;
		}
		this.gridSortingService.setSortOption(event.sortField, event.sortOrder === -1);

		this.sorting.sortField = event.sortField || this.gridSortingService.defaultSortColumn;
		this.sorting.sortOrder = event.sortOrder || -1;
		this.load();
	}

	exportExcel = (args: any) => {
		this.loading = true;
		let customer = '';
		const sorting = this.getSorting();
		const translatedConfig = this.gridConfig.cloneAndTranslate(text => this.formatService.translateAndFormat(text, false));
		this.subscriptions.push(this.selectedCustomerService.Customer.subscribe(result => customer = result.CustomerName));
		this.subscriptions.push(this.customerSalesRepService.getCustomerSalesRepsDownload(customer, this.customerId, sorting, translatedConfig).subscribe(blob => {
			this.loading = false;
			this.downloadsService.saveExcelBlob(blob);
		}));
	}

	private handleTransactionCountClick = (record: any, parent: any): boolean => {
		this.updateBreadcrumbs([
			{
				title: parent.SalesRepName,
				class: 'active'
			},
			{
				title: record.Description,
				class: 'active'
			}]);
		this.router.navigateByUrl(this.getTransactionUrlTree(record, parent));
		return false;
	}

	private getTransactionUrlTree = (record: any, parent: any) => {
		return this.router.createUrlTree(['transactions', {
			salesRep: parent.SalesRepId,
			productType: record.ProductType
		}], { relativeTo: this.route.parent });
	}

	private generateTransactionsLink = (record: any, parent: any) => {
		const urlTree = this.getTransactionUrlTree(record, parent);
		return this.location.prepareExternalUrl(urlTree.toString());
	}

	private getSorting = (): SortOptions => {
		this.sorting.sortField = this.gridSortingService.getSortFieldOrDefault();
		this.sorting.sortOrder = this.gridSortingService.getSortDirectionOrDefault();

		return new SortOptions(this.sorting.sortField, !(this.sorting.sortOrder === 1));
	}

	private configureGrid = () => {
		this.frozenWidth = '460px';
		this.gridConfig = new MetricsGridConfig(
			[
				{
					title: '',
					visible: true,
					locked: true,
					columnSelectorTitle: 'main.core.common.counts.sales_reps.singular',
					columns: [
						{
							title: 'main.core.common.counts.sales_reps.singular',
							field: 'SalesRepName',
							sortColumn: 'SalesRepName',
							width: 175,
							headerStyle: {
								'text-align': 'left'
							},
							cellStyle: {
								'text-align': 'left'
							},
							childConfig: {
								field: ''
							}
						},
						this.columnService.ProductTypeCount(this.columnService.ProductType()),
						this.columnService.TransactionCount(
							this.columnService.TransactionCount(
								null,
								(v, r, p) => this.generateTransactionsLink(r, p),
								(v, r, p) => this.handleTransactionCountClick(r, p)
							)
						)
					]
				},
				...this.columnService.StandardMetricSetColumnGroups()
			],
			p => this.loadChildren(p),
			true
		);

	}

	private loadChildren = (parent: any): Observable<Array<any>> => {
		const sorting = this.getSorting();
		return this.customerSalesRepService.getCustomerSalesRepsProductTypes(this.customerId, parent.SalesRepId, sorting)
			.pipe(map(items => {
				return items.map(this.convert)
			}));
	}

	private convert = (item: any): any => {
		const clone = (<any>Object).assign({}, item);
		clone.SalesRepName = null;
		clone.ProductTypeCount = item.Description;
		return clone;
	}
}
