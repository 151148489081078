﻿<div class="card-set-container">
	<div class="panel panel-default rdo-card rdo-card-dashboard-default flex-card-4"
		[class.rdo-card-primary-green]="marketUonRentChange > 0"
		[class.rdo-card-primary-red]="marketUonRentChange < 0"
		[class.rdo-card-light-gray-1]="!marketUonRentChange || marketUonRentChange === 0"
		(mouseenter)="panelEvent($event, 'unitsonrent')"
		(mouseleave)="panelEvent($event, 'unitsonrent')"
		style="margin-left: 0">
			<div class="panel-title" id="units-on-rent-title">{{'main.tabs.equipment.product_types.change.market_units_on_rent' | translate}}</div>
			<div class="rdo-card-metric" id="units-on-rent-metric">
				{{getVerbForValue(marketUonRentChange)}} {{marketUonRentChange === 0 ? '' : marketUonRentChange | rdoPercent}}
			</div>		
	</div>

	<div class="panel panel-default rdo-card rdo-card-dashboard-default flex-card-4"
		[class.rdo-card-primary-green]="marketPhysicalUtilChange > 0"
		[class.rdo-card-primary-red]="marketPhysicalUtilChange < 0"
		[class.rdo-card-light-gray-1]="!marketPhysicalUtilChange || marketPhysicalUtilChange === 0"
		(mouseenter)="panelEvent($event, 'physicalutilization')"
		(mouseleave)="panelEvent($event, 'physicalutilization')">
		
			<div class="panel-title" id="market-physical-util-title">{{'main.tabs.equipment.product_types.change.market_physical_util' | translate}}</div>
		
		
			<div class="rdo-card-metric" id="market-physical-util-metric">
				{{getVerbForValue(marketPhysicalUtilChange)}} {{marketPhysicalUtilChange === 0 ? '' : marketPhysicalUtilChange | rdoPercent}} {{getPtsText()}}
			</div>
		
	</div>

	<div class="panel panel-default rdo-card rdo-card-dashboard-default flex-card-4"
		[class.rdo-card-primary-green]="monthlyRentalRatesChange > 0"
		[class.rdo-card-primary-red]="monthlyRentalRatesChange < 0"
		[class.rdo-card-light-gray-1]="!monthlyRentalRatesChange || monthlyRentalRatesChange === 0"
		(mouseenter)="panelEvent($event, 'monthlyrates')"
		(mouseleave)="panelEvent($event, 'monthlyrates')">
		
		
			<div class="panel-title" id="market-monthly-rates-title">{{'main.tabs.equipment.product_types.change.market_monthly_rates' | translate}}</div>
		
			<div class="rdo-card-metric" id="market-monthly-rates-metric">
				{{getVerbForValue(monthlyRentalRatesChange)}} {{monthlyRentalRatesChange === 0 ? '' : monthlyRentalRatesChange | rdoPercent}}
			</div>
		
	</div>

	<div class="panel panel-default rdo-card rdo-card-dashboard-default flex-card-4"
		[class.rdo-card-primary-green]="clientShareUnitsChange > 0"
		[class.rdo-card-primary-red]="clientShareUnitsChange < 0"
		[class.rdo-card-light-gray-1]="!clientShareUnitsChange || clientShareUnitsChange === 0"		
		(mouseenter)="panelEvent($event, 'relativerentals')"
		(mouseleave)="panelEvent($event, 'relativerentals')"
		style="margin-right: 0">

			<div class="panel-title" id="client-relative-rentals-title">{{'main.tabs.equipment.product_types.change.charts.client_relative_rentals.title' | translate}}</div>
			<div class="rdo-card-metric" id="client-relative-rentals-metric">
				{{getVerbForValue(clientShareUnitsChange)}} {{clientShareUnitsChange === 0 ? '' : clientShareUnitsChange | rdoNumber:1}} {{getUnitsText()}}
			</div>
		
	</div>
</div>