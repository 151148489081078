import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { ErrorService } from './error.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private errorService: ErrorService) { }

    handleError(error: any) {

        const errorMsg = ErrorService.genericErrorMessage;

        // Check if it's an error from an HTTP response.  there's another global handler written to handle http response situations
        if (!(error instanceof HttpErrorResponse)) {

            //if (error && error.message) {
            //    errorMsg = error.message;
            //}

            this.errorService.handleError(error);
        }
    }
}
