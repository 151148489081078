import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Map of transforms to apply to supplied date format should the format need a specific
 * format for the current locale.
 *
 * Added for this ticket:
 * https://rouseservices.atlassian.net/browse/ANA-15466
 */
const dateFormatModifiers = {
  'M/d/YYYY': (locale: string, format = 'M/d/YYYY') => {
    let result = format;
    if (['en-GB', 'fr-FR', 'de-DE'].includes(locale)) {
      result = 'd/M/YYYY';
    } else if (['ja-JP'].includes(locale)) {
      result = 'YYYY/M/d';
    }
    return result;
  },
  'M/d/YYYY h:mm a': (locale: string, format = 'M/d/YYYY h:mm a') => {
    let result = format;
    if (['en-GB', 'fr-FR', 'de-DE'].includes(locale)) {
      result = 'd/M/YYYY h:mm a';
    } else if (['ja-JP'].includes(locale)) {
      result = 'YYYY/M/d h:mm a';
    }
    return result;
  },
  'M/d/YYYY, h:mm a': (locale: string, format = 'M/d/YYYY, h:mm a') => {
    let result = format;
    if (['en-GB', 'fr-FR', 'de-DE'].includes(locale)) {
      result = 'd/M/YYYY, h:mm a';
    } else if (['ja-JP'].includes(locale)) {
      result = 'YYYY/M/d, h:mm a';
    }
    return result;
  },
};

/**
 * Get the correct date format taking the locale into accoutn
 * @param locale - client locale
 * @param format - a date formatting string.
 *
 * This url shows DatePipe format strings: https://v17.angular.io/api/common/DatePipe
 */
export function getDateFormatForLocale(
  locale: string,
  format: string = 'M/d/YYYY'
): string {
  const result = dateFormatModifiers[format]
    ? dateFormatModifiers[format](locale)
    : format;
  return result;
}
/*
 * Formats date for display using the current language
 * Usage:
 *   dateValue | rdoLocalizedDate
 * Example:
 *   {{ date | rdoLocalizedDate:'MMMM d, y'}}
 *   formats to: Yay
 *   {{ null | rdoLocalizedDate}}
 *   formats to: -
 *
 * Formats used:
 * MMM yyyy, M/d/YYYY h:mm a, M/d/YYYY, MMM dd, yyyy
 */
@Pipe({
  name: 'rdoLocalizedDate',
  pure: false,
})
export class RdoLocalizedDatePipe implements PipeTransform {
  private locale: string = 'en-US';
  constructor() {}

  /**
   * This is used because you can't pass strings to the constructor and use the pipe inline in a template.
   * The string constructor param will throw a very unhelpful error in the DI pipeline. Use this method when
   * building Pipe instances directly.
   * @param locale
   */
  static createInstance(locale: string) {
    const pipe = new RdoLocalizedDatePipe();
    pipe.setLocale(locale);
    return pipe;
  }

  setLocale(locale: string): void {
    this.locale = locale;
  }

  transform(
    value: any,
    format: string = 'mediumDate',
    localeOverride?: string
  ): any {
    let localizedDate: string;
    try {
      // the localeFormat that is set up from the server has an incorrect key for Japan
      // it uses 'ja' instead of 'ja-JP'.  Originally we were using the localeService
      // there the locale was also incorrectly returning 'jp-JP'.
      // Leaving them both in place for this hack.
      const datePattern = getDateFormatForLocale(
        localeOverride || this.locale,
        format
      );
      const datePipe: DatePipe = new DatePipe(localeOverride || this.locale);
      localizedDate = datePipe.transform(value, datePattern);
      localizedDate = this.limitLength(localizedDate, format);
    } catch (error) {
      console.error(error);
    }
    return this.capitalize(localizedDate);
  }

  /**
   * Used to maintain consistency in the way we display dates across
   * different languages.
   */
  private limitLength(str: string, pattern: string): string {
    let newStr = str;
    if (pattern === 'MMM yyyy') {
      newStr = newStr.replace('.', ''); // Erase dots
      const strArr = newStr.split(' ');
      newStr = strArr[0].substr(0, 3) + ' ' + strArr[1]; // limit length to 4 chars
    }
    return newStr;
  }

  private capitalize(str: string): string {
    if (str) {
      return str.charAt(0).toUpperCase() + str.substring(1, str.length);
    }
    return str;
  }
}
