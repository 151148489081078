import cloneDeep from 'lodash/cloneDeep';
import {
    MetricsGridConfig,
    IGridColumnGroup,
    IColumnConfig
} from '../'
import {moveGridColumn} from './moveGridColumn';
import {getPairedPatch, patchGridColumns} from './patchGridColumns';

/**
 * Contains all logic around moving a grid column(s). TODO: Simplify into a single movement function.
 * Right now this logic is relegated to the very first group.
 */
export const moveColumn = (column: IColumnConfig, gridConfig: MetricsGridConfig, direction: 'left' | 'right', shouldClone = true) => {

    const cloneGridConfig = shouldClone ? cloneDeep(gridConfig) : gridConfig;
    const currentIndex = cloneGridConfig.groups[0].columns.findIndex((item) => {
        return column.title === item.title;
    });

    if (direction === 'left') {

        // Find the first column that is visible and movable.
        let queryIndex = currentIndex - 1;
        let foundQueryIndex = false;
        while (!foundQueryIndex && queryIndex >= 0) {
            const col = cloneGridConfig.groups[0].columns[queryIndex];
            if (col.visible && col.isMovable) {
                foundQueryIndex = true;
                break;
            }
            queryIndex -= 1;
        }

        const queryColumn = cloneGridConfig.groups[0].columns[queryIndex];

        // if the column to the left is paired to this column, swap them.
        if (queryColumn.pairing && queryColumn.pairing.includes(column.title)) {
            moveGridColumn(column, cloneGridConfig, queryIndex, currentIndex);
        }

        // if the column to the left is not paired but it is not paired to anything else swap them
        if (!queryColumn?.pairing) {
            if (!column.pairing) {
                moveGridColumn(column, cloneGridConfig, queryIndex, currentIndex);
            } else {
                const pairedPatch = getPairedPatch(column, cloneGridConfig);
                patchGridColumns(pairedPatch, gridConfig, queryIndex);
            }
        } else {
            const queryPatch = getPairedPatch(queryColumn, cloneGridConfig);
            const queryIndex = queryPatch.keys[0];
            if (!column.pairing) {
                moveGridColumn(column, cloneGridConfig, queryIndex, currentIndex);
            } else {
                const pairedPatch = getPairedPatch(column, cloneGridConfig);
                patchGridColumns(pairedPatch, cloneGridConfig, queryIndex);
            }
        }

    } else {

        const movableCols = gridConfig.groups[0].columns.filter((col: IColumnConfig) => {
            return col.isMovable;
        });

        // Find the first column that is visible and movable.
        let queryIndex = currentIndex + 1;
        let foundQueryIndex = false;
        while (!foundQueryIndex && queryIndex < movableCols.length) {
            const col = cloneGridConfig.groups[0].columns[queryIndex];
            if (col.visible && col.isMovable) {
                foundQueryIndex = true;
                break;
            }
            queryIndex += 1;
        }

        const queryColumn = cloneGridConfig.groups[0].columns[queryIndex];
        // if the column to the left is paired to this column, swap them.
        if (queryColumn.pairing && queryColumn.pairing.includes(column.title)) {
            moveGridColumn(column, cloneGridConfig, queryIndex, currentIndex);
        }

        // if the column to the left is not paired but it is not paired to anything else swap them
        if (!queryColumn?.pairing) {
            if (!column.pairing) {
                moveGridColumn(column, cloneGridConfig, queryIndex, currentIndex);
            } else {
                const pairedPatch = getPairedPatch(column, cloneGridConfig);
                patchGridColumns(pairedPatch, gridConfig, queryIndex + pairedPatch.keys.length);
            }
        } else {
            const queryPatch = getPairedPatch(queryColumn, cloneGridConfig);
            const queryIndex = queryPatch.keys[0];
            if (!column.pairing) {
                moveGridColumn(column, cloneGridConfig, queryIndex, currentIndex);
            } else {
                const pairedPatch = getPairedPatch(column, cloneGridConfig);
                patchGridColumns(pairedPatch, cloneGridConfig, queryIndex + pairedPatch.keys.length);
            }
        }
    }
    return cloneGridConfig;
};
