import { Observable } from '../Observable';
import { from } from './from';
import { EMPTY } from './empty';
export function using(resourceFactory, observableFactory) {
  return new Observable(subscriber => {
    let resource;
    try {
      resource = resourceFactory();
    } catch (err) {
      subscriber.error(err);
      return undefined;
    }
    let result;
    try {
      result = observableFactory(resource);
    } catch (err) {
      subscriber.error(err);
      return undefined;
    }
    const source = result ? from(result) : EMPTY;
    const subscription = source.subscribe(subscriber);
    return () => {
      subscription.unsubscribe();
      if (resource) {
        resource.unsubscribe();
      }
    };
  });
}
