import { Pipe, PipeTransform } from '@angular/core';
import { FormatService } from '../../core/query';

/*
 * Formats currency for display
 * Usage:
 *   currencyValue | rdoCurrency
 * Example:
 *   {{ -12345 | rdoCurrency}}
 *   formats to: (12,345)
 *   {{ 12345.67 | rdoCurrency:true}}
 *   formats to: $12,346
 */
@Pipe({
  name: 'rdoCurrency',
})
export class RdoCurrencyPipe implements PipeTransform {
  constructor(private formatService: FormatService) {}

  transform = (
    value: number,
    useDollarSymbol: boolean,
    dummyUpdater?: any
  ): any => {
    return this.formatService.formatCurrency(value, !!useDollarSymbol);
  };
}
