import { StepActionType, TriggerType, UserGuide, UserGuideOptions, UserGuideStep } from '../models';

function getGuide() {
    const guide: UserGuide = {
        // Data to audit the object in the db
        id: null,
        name: 'rate_analysis',
        translatedName: 'Rate Analysis Guide',
        created_date: new Date(2023, 11, 28),
        created_by: 'Marcelo Schild',
        modified_date: null,
        modified_by: null,
  
        // Data to know when and where to execute it
        application: 'RDO',
        trigger_type: TriggerType.USER,
        trigger_value: 'Manual',
  
        // Actual JSON that affects IntroJS
        options: Object.assign(new UserGuideOptions(), {
          exitOnOverlayClick: false,
          keyboardNavigation: true,
          doneLabel: 'main.shared.finish',
          prevLabel: 'main.shared.back',
          nextLabel: 'main.shared.next',
          steps: [{
            title: 'main.rate_analysis.step_title_1',
            intro: 'main.rate_analysis.step_text_1',
            type: StepActionType.MESSAGE
          }, {
            element: '#filter_toggle',
            intro: 'main.rate_analysis.step_text_2',
            type: StepActionType.HIGHLIGHT
          }, {
            type: StepActionType.REFRESH_BACKWARDS
          }, {
            actionCode: `let btn = document.querySelector('#filters-menu');btn && btn.click();`,
            undoActionCode: `let btn = document.querySelector('#close-filter-button');btn && btn.click();`,
            type: StepActionType.ACTION
          }, {
            element: '#Filter_Month',
            title: 'main.rate_analysis.step_title_3',
            intro: 'main.rate_analysis.step_text_3',
            type: StepActionType.HIGHLIGHT
          }, {
            actionCode: `
              let btn = document.querySelector('#Filter_Month > div > button');
              btn && btn.click();
              setTimeout(()=>{
                let latest3Months = document.querySelector('#Filter_Month > div > ul > li:nth-child(6) > a');
                latest3Months && latest3Months.click();
                btn && btn.click();
              }, 100);`,
            undoActionCode: `let btn = document.querySelector('#Filter_Month > div > button'); btn && btn.click();
            let latestMonth = document.querySelector('#Filter_Month > div > ul > li:nth-child(4) > a'); latestMonth && latestMonth.click()`,
            type: StepActionType.ACTION
          }, {
            element: '#Filter_RateSource',
            title: 'main.rate_analysis.step_title_4',
            intro: 'main.rate_analysis.step_text_4',
            type: StepActionType.HIGHLIGHT
          }, {
            position: 'left',
            element: '#apply-filters-button',
            intro: 'main.rate_analysis.step_text_5',
            type: StepActionType.HIGHLIGHT
          }, {
            actionCode: `let btn = document.querySelector('#apply-filters-button');btn && btn.click();`,
            undoActionCode: `let btn = document.querySelector('#filters-menu');btn && btn.click();`,
            type: StepActionType.ACTION
          }, {
            title: 'main.rate_analysis.step_title_6',
            intro: 'main.rate_analysis.step_text_6',
            type: StepActionType.MESSAGE
          }, {
            element: '#equipment-dropdown',
            title: 'main.rate_analysis.step_title_7',
            intro: 'main.rate_analysis.step_text_7',
            type: StepActionType.HIGHLIGHT
          }, {
            actionCode: `let btn = document.querySelector('#equipment-dropdown > a');btn && btn.click();`,
            undoActionCode: `
              let btn = document.querySelector('#main-tabs-summary-page_title-selector');
              btn && btn.click();
              let equipmentBtn = document.querySelector('#equipment-dropdown > a');
              equipmentBtn && equipmentBtn.click();`,
            type: StepActionType.ACTION
          }, {
            element: '#equipment-list-item > a',
            title: 'main.rate_analysis.step_title_8',
            intro: 'main.rate_analysis.step_text_8',
            type: StepActionType.HIGHLIGHT
          }, {
            // This action navigates to the next tab, so it restarts the service
            actionCode: `let btn = document.querySelector('#equipment-list-item > a');btn && btn.click();`,
            undoActionCode: `let btn = document.querySelector('#equipment-dropdown > a');btn && btn.click();`,
            type: StepActionType.ACTION
          }, {
            waitForElement: '#dLabel',
            intro: 'main.shared.loading',
            type: StepActionType.REFRESH_FORWARD
          }, {
            title: 'main.rate_analysis.step_title_9',
            intro: 'main.rate_analysis.step_text_9',
            type: StepActionType.MESSAGE
          }, {
            position: 'left',
            element: '#dLabel',
            intro: 'main.rate_analysis.step_text_10',
            type: StepActionType.HIGHLIGHT
          }, {
            actionCode: `let btn = document.querySelector('#dLabel');btn && btn.click();`,
            undoActionCode: `let btn = document.querySelector('#dLabel');btn && btn.click();`,
            type: StepActionType.ACTION
          }, {
            element: '#dLabelDropdown',
            intro: 'main.rate_analysis.step_text_11',
            type: StepActionType.HIGHLIGHT
          }, {
            actionCode: `
              setTimeout(() => {
                // Close all columns first
                let btnsArr = document.querySelectorAll('.groupHeader > a');
                btnsArr && btnsArr.forEach(x => x.click());

                // Open columns listed in the highlighted message
                let columnSelectorIds = [
                  '#main-core-tables-titles-monthly_rate_performance-monthly_rate_comparison-list-selector'
                ];
                columnSelectorIds.forEach(x => {
                  let columSelector = document.querySelector(x);
                  columSelector && columSelector.click();
                });
              }, 1);`,
            undoActionCode: `let btn = document.querySelector('#dLabel');btn && btn.click();`,
            type: StepActionType.ACTION
          }, {
            actionCode: `
              setTimeout(() => {
                // Open columns listed in the highlighted message
                let columnSelectorIds = [
                  '#main-core-tables-titles-weekly_rate_performance-weekly_rate_comparison-list-selector',
                  '#main-core-tables-titles-daily_rate_performance-daily_rate_comparison-list-selector',
                  '#main-core-tables-titles-hourly_rate_performance-hourly_rate_comparison-list-selector'
                ];
                columnSelectorIds.forEach(x => {
                  let columSelector = document.querySelector(x);
                  columSelector && columSelector.click();
                });
                //Close the column selector
                let btn = document.querySelector('#dLabel');btn && btn.click();
                vm && vm.showGlobalSpinner && vm.showGlobalSpinner(false);
              }, 800);`,
            intro: 'main.rate_analysis.step_text_12',
            type: StepActionType.MESSAGE
          }
        ]
        })
      };
      guide.options.steps = guide.options.steps.map(x => Object.assign(new UserGuideStep(), x));
      return guide;
}

export const RATE_ANALYSIS_GUIDE = getGuide();
