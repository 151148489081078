import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { get, set, toUpper, toLower } from 'lodash';

// Interfaces
import {
    IFirestoreExportJob, IFirestoreMethods,
    IMakeRequestOptions, IRequestOptions
} from '../interface';

// Services
import { FirestoreService } from '../firestore/firestore.service';

// Configs
import { environment } from '../../../environments/environment';
import { ExportJob } from '../firestore/model/export-job';
import { MetricsHttpService } from '../http/metrics-http.service';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class ExportsService {
    private readonly useCase: string = 'exports';
    private jobMap: { [fileName: string]: string } = {};
    jobListSubject: BehaviorSubject<Array<ExportJob>> = new BehaviorSubject([]);
    public get jobs$(): Observable<Array<ExportJob>> { return this.jobListSubject.asObservable(); }
    public jobs: Array<ExportJob> = [];

    constructor(
        private authenticationService: AuthenticationService,
        private firestoreService: FirestoreService,
        private metricsHttp: MetricsHttpService
    ) {
        this.initialize();
    }

    private initialize(): void {
        this.firestoreService.startUseCase(this.useCase);

        this.getToken(data => {
            const token = JSON.parse(data).fbt;
            if (!token) {
                return;
            }

            this.signIn(token)
                .then(() => this.registerCollectionSubscriptions())
                .catch(err => {
                    // this.debug.error('Unable to sign into firestore for exports:', err)
                    throw err;
                });
        });
    }

    private signIn(token: string): Promise<void> {
        return new Promise((resolve, reject) => (this.firestoreService.exportsUseCase as IFirestoreMethods)
            .signIn(token, (err: Error | null, success: boolean) => {
                if (err) {
                    return reject(err);
                }
                resolve();
            }));
    }

    private registerCollectionSubscriptions(): void {
        const clientCode = toUpper(this.authenticationService._userInfoView.SelectedClient.ClientCode);
        const clientId = this.authenticationService.tokenInfo.AccessTokenDecoded.sub;
        this.authenticationService._userInfoView.SelectedClient.AvailableCustomGridSources?.forEach(source => {
            const path = `exports/${source}/clients/${clientCode}/users/${toLower(clientId)}/jobs`;
            this.firestoreService.exportsUseCase.subscribeToPath(path).pipe(debounceTime(500)).subscribe(data => {
                if (Array.isArray(data[0]) && data[0].length > 0) {
                    data[0].forEach((job, i) => this.jobMap[job.file] = data[1][i]);
                }
                this.parseJobs(data[0]);
            });
        });
    }

    private parseJobs(jobs?: Array<IFirestoreExportJob>): void {

        for (const j of jobs) {
            let userId = j.user_id || '';
            if (userId) {
                const splittedUserId = userId.split('|');
                if (splittedUserId.length > 1) {
                    userId = splittedUserId[1];
                }
            }
            const userEmail = j.user_email || userId;
            const item: ExportJob = {
                id: this.jobMap[j.file],
                file: j.file,
                user_id: userId.toLowerCase(),
                user_email: userEmail.toLowerCase(),
                store_id: j.storeId,
                expires: j.expiration,
                notify: false,
                creation: new Date(j.creation * 1000),
                state_id: '',
                status: j.status
            };


            const jobIndex = this.jobs.findIndex(jobItem => jobItem.file === j.file);
            if (jobIndex !== -1) {
                this.jobs[jobIndex] = item;
            } else {
                this.jobs.push(item);
            }

        }
        this.jobListSubject.next(this.jobs);
    }

    private getToken(cb): void {
        const url = `${environment.metricsApiUrl}/getFirestoreToken`;
        const options = { requestOptions: this.readyOptions() };
        this.makeRequest('post', url, options).subscribe(data => cb(data));
    }

    private makeRequest(method: 'post' | 'get', url: string, options: IMakeRequestOptions = {}): Observable<any> {
        options.requestOptions = this.readyOptions(options.requestOptions);
        return this.metricsHttp.post('getFirestoreToken', options);
    }

    private readyOptions(requestOptions: IRequestOptions = {}): IRequestOptions {
        if (!('observe' in requestOptions)) {
            requestOptions.observe = 'body';
        }

        set(requestOptions, 'responseType', get(requestOptions, 'responseType', 'json'));
        set(requestOptions, 'headers.Content-Type', get(requestOptions, 'headers.Content-Type', 'application/json; charset=utf-8'));

        const bearerToken = get(this, 'auth.token');
        if (bearerToken) {
            set(requestOptions, 'headers.authorization', 'Bearer ' + bearerToken);
        }
        return requestOptions;
    }

}
