import { Component, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';

import * as _ from 'lodash';

import { BreadcrumbService, InstructionInfo } from '../core';
import { SelectedCustomerService } from './selected-customer.service';


@Component({
	template: ''
})
export class CustomerBaseComponent implements OnDestroy {
	protected subscriptions: Array<Subscription> = [];

	constructor(
		protected selectedCustomerService: SelectedCustomerService,
		protected breadcrumbService: BreadcrumbService
	) {

	}

	protected updateBreadcrumbs(instructions: Array<InstructionInfo>) {
		this.selectedCustomerService.Customer.subscribe(c => {
			const baseInstructions = [
				{
					title: 'main.core.common.counts.customers.all',
					linkDSL: ['/customers']
				},
				{
					title: c.CustomerName,
					linkDSL: ['/customers', c.CustomerId]
				}
			];

			const breadcrumInstructions = _.union<InstructionInfo>(baseInstructions, instructions);
			this.breadcrumbService.update(breadcrumInstructions);
		});
	}
	ngOnDestroy() {
		while (this.subscriptions && this.subscriptions.length > 0) {
			this.subscriptions.pop().unsubscribe();
		}
	}

}
