import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { map } from 'rxjs/operators';

import { LazyLoadEvent } from 'primeng/api';

import {
	ActiveFilterService,
	BreadcrumbService,
	ColumnDefinitionService,
	MetricsGridConfig,
	ClientProfileService,
	GridTableConfiguredExpandableComponent,
	FormatService,
	GridSortingService
} from '../core';

import { DownloadsService } from '../downloads';
import { HeaderService } from '../header';

import { CustomerBaseComponent } from './customer-base.component';
import { SelectedCustomerService } from './selected-customer.service';
import { EquipmentService } from '../equipment';

import { PageOptionsNg, SortOptionsNg, SortOptions } from '../models';
import { AuthenticationService } from '../core/authentication/authentication.service';

@Component({
	selector: 'rdo-customer-product-types',
	template: `
    <rdo-overlay [enabled]="loading"></rdo-overlay>
    <rdo-grid-table-expanded-ng
      #productTypesGrid
      *ngIf="data && totalCount > 0"
      [name]="gridName"
      [dataKey]="dataKey"
      [gridConfig]="gridConfig"
      [rowExpansion]="gridConfig.rowExpansion"
      [pagedData]="data"
      [frozenRows]="frozenRows"
      [sorting]="sorting"
      [(paging)]="paging"
      (pageOnChange)="changePage($event)"
      (lazyLoadOnChange)="changeLazyLoad($event)"
      [totalCount]="totalCount"
      [frozenWidth]="frozenWidth"
      [showColumnSelector]="true"
      (downloadExcelClick)="exportExcel($event)"
    >
    </rdo-grid-table-expanded-ng>
  `,
})
export class CustomerProductTypesComponent extends CustomerBaseComponent implements OnInit, OnDestroy {
    @ViewChild('productTypesGrid') productTypesGrid: GridTableConfiguredExpandableComponent;
	loading = true;
	gridName = 'CUSTOMER_PRODUCTTYPES_GRID';
	data: any;
	customerId: number;
	gridConfig: MetricsGridConfig;
	frozenRows: any;
	paging = new PageOptionsNg();
	sorting: SortOptionsNg;
	totalCount = 0;
	frozenWidth: string;
	dataKey = 'ProductType';
	private clientProfileServiceSubscription: Subscription;

	constructor(
		private authenticationService: AuthenticationService, 
		private columnService: ColumnDefinitionService,
		private route: ActivatedRoute,
		private equipmentService: EquipmentService,
		private downloadsService: DownloadsService,
		private router: Router,
		selectedCustomerService: SelectedCustomerService,
		breadcrumbService: BreadcrumbService,
		private location: Location,
		private filterService: ActiveFilterService,
		private headerService: HeaderService,
		private clientProfileService: ClientProfileService,
		private formatService: FormatService,
		private gridSortingService: GridSortingService,
	) {
		super(selectedCustomerService, breadcrumbService);
	}

	ngOnInit() {
		this.gridSortingService.setGridName(this.gridName);
		this.sorting = this.gridSortingService.getSortOptionsNg();
		// eslint-disable-next-line
		const component = this;
		let customerId = component.customerId;
		component.subscriptions.push(component.route.parent.params.subscribe(params => {
			customerId = params.customer;
			if (component.customerId !== customerId) {
				component.customerId = customerId;
				component.configureGrid();
				component.load();
			}
		}));
		component.subscriptions.push(
			component.selectedCustomerService.customerChange.subscribe(c => {
				component.customerId = c.CustomerId;
				customerId = c.CustomerId;
				this.paging.page = 1;
				if (component.productTypesGrid) {
					component.productTypesGrid.resetPaginator();
				}
				component.load();
			}));
		component.subscriptions.push(
			component.filterService.filterChange.subscribe(component.load)
		);
        component.subscriptions.push(component.authenticationService.userInfoView.subscribe(userInfo => {
			if (this.gridConfig) {
                this.gridConfig.enableExcelExport = userInfo.HasClientAccessToExportData;
			}
		}));
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	load = () => {
		this.loading = true;
		const sorting = this.getSorting();
		this.equipmentService.getCustomerProductTypes(this.customerId, this.paging, sorting).subscribe(result => {
			this.loading = false;
			if (result.Items && result.Items.length > 0) {
				this.data = result.Items;
			} else {
				this.data = [];
			}
			this.totalCount = result.TotalCount;
		});
		this.updateBreadcrumbs([
			{ title: 'main.core.common.counts.product_types.plural', class: 'active' }
		]);
	}

	changePage = (event: any) => {
		if (event.rows !== this.paging.pageSize) {
			this.paging.pageSize = event.rows;
		}
		this.paging.page = event.first < 1 ? 1 : ((event.first / event.rows) + 1);
		this.load();
	}

	changeLazyLoad = (event: LazyLoadEvent) => {
		if (this.sorting.sortField === event.sortField && this.sorting.sortOrder === event.sortOrder) {
			return;
		}
		this.gridSortingService.setSortOption(event.sortField, event.sortOrder === -1);

		this.sorting.sortField = event.sortField || this.gridSortingService.getSortFieldOrDefault();
		this.sorting.sortOrder = event.sortOrder || this.gridSortingService.getSortDirectionOrDefault();
		this.load();
	}

	exportExcel = (args: any) => {
		this.loading = true;
		const sorting = this.getSorting();
		let customer = '';
		const translatedConfig = this.gridConfig.cloneAndTranslate(text => this.formatService.translateAndFormat(text, false));
		this.subscriptions.push(this.selectedCustomerService.Customer.subscribe(result => customer = result.CustomerName));
		this.subscriptions.push(this.equipmentService.getCustomerProductTypesDownload(customer, this.customerId, sorting, translatedConfig).subscribe(blob => {
			this.loading = false;
			this.downloadsService.saveExcelBlob(blob);
		}));
	}

	private handleTransactionCountClick = (record: any, parent: any): boolean => {
		this.updateBreadcrumbs([
			{
				title: parent.Description,
				class: 'active'
			},
			{
				title: record.SalesRepName,
				class: 'active'
			}]);

		this.router.navigateByUrl(this.getTransactionUrlTree(record, parent));
		return false;
	}

	private getTransactionUrlTree = (record: any, parent: any) => {
		return this.router.createUrlTree([
			'transactions', {
				productType: parent.ProductType,
				salesRep: record.SalesRepId
			}
		],
			{ relativeTo: this.route.parent });
	}

	private generateTransactionsLink = (record: any, parent: any) => {
		const urlTree = this.getTransactionUrlTree(record, parent);
		return this.location.prepareExternalUrl(urlTree.toString());
	}

	private getSorting = (): SortOptions => {
		this.sorting.sortField = this.gridSortingService.getSortFieldOrDefault();
		this.sorting.sortOrder = this.gridSortingService.getSortDirectionOrDefault();
		return new SortOptions(this.sorting.sortField, !(this.sorting.sortOrder === 1));
	}

	private configureGrid = () => {
		this.clientProfileServiceSubscription = this.clientProfileService.getClientAttributes().subscribe(attributes => {
			if (this.clientProfileServiceSubscription) {
				this.clientProfileServiceSubscription.unsubscribe();
			}
			let calculatedProductTypeLength = attributes.MaxProdTypeLen * 7.25;
			calculatedProductTypeLength = calculatedProductTypeLength < 175 ? 175 : calculatedProductTypeLength;
			this.frozenWidth = `${calculatedProductTypeLength + 175 + 110}px`;
			this.gridConfig = new MetricsGridConfig(
				[
					{
						title: '',
						visible: true,
						locked: true,
						columnSelectorTitle: 'main.core.common.counts.product_types.singular',
						columns: [
							{
								title: 'main.core.common.counts.product_types.singular',
								field: 'Description',
								sortColumn: 'Description',
								width: 100,
								maxWidth: 500,
								minWidth: 115,
								autoWidth: true,
								headerStyle: {
									'text-align': 'left'
								},
								cellStyle: {
									'text-align': 'left',
									'text-transform': 'uppercase'
								},
								childConfig: {
									field: ''
								}

							},
							this.columnService.SalesRepCount(this.columnService.SalesRep()),
							this.columnService.TransactionCount(
								this.columnService.TransactionCount(
									null,
									(v, r, p) => this.generateTransactionsLink(r, p),
									(v, r, p) => this.handleTransactionCountClick(r, p)
								)
							)
						]
					},
					...this.columnService.StandardMetricSetColumnGroups()
				],
				p => this.loadChildren(p),
				true
			);
		});
	}

	private loadChildren = (parent: any): Observable<Array<any>> => {
		const sorting = this.getSorting();
		return this.equipmentService.getCustomerProductTypesSalesReps({ customerId: this.customerId, productType: parent.ProductType }, sorting)
			.pipe(map(items => {
				return items.map(this.convert)
			}));
	}

	private convert = (item: any): any => {
		const clone = (<any>Object).assign({}, item);
		clone.Description = null;
		clone.SalesRepCount = item.SalesRepName;
		return clone;
	}
}
