export class PageOptions {
    public page: number = 1;
	public pageSize: number = 20;

	constructor(init?: Partial<PageOptions>) {
		Object.assign(this, init);
	}
}

export class PageOptionsNg extends PageOptions {
	public first: number = 0;

	constructor(init?: Partial<PageOptionsNg>) {
		super(init);
		Object.assign(this, init);
	}
}

export class SortOptions {
    constructor(public sortOn: string = null, public descending: boolean = false) { }
}

export class SortOptionsNg {
	sortField: string;
	sortOrder: number;

	constructor(init?: Partial<SortOptionsNg>) {
		Object.assign(this, init);
	}
}