<rdo-overlay [enabled]="isVisible && isLoading" imgClass='cg-overlay-img'></rdo-overlay>
<div *ngIf="isVisible && !isLoading" class="e-dlg-container e-dlg-center-center sched-dial-meta-container">
    <div class="e-pivot-error-dialog e-control e-dialog e-lib e-dlg-modal e-popup-open sched-dial-container"
        id="PivotView_PivotFieldList_ErrorDialog" tabindex="-1">
        <div class="e-dlg-header-content sched-dial-head" id="PivotView_PivotFieldList_ErrorDialog_dialog-header">
            <button class="e-dlg-closeicon-btn e-control e-btn e-lib e-flat e-icon-btn" type="button" title="Close"
                aria-label="Close" (click)="closeMessageDialog()"><span
                    class="e-btn-icon e-icon-dlg-close e-icons"></span></button>
            <div class="e-dlg-header" id="PivotView_PivotFieldList_ErrorDialog_title">{{title}}</div>
        </div>
        <form [formGroup]="scheduleForm">
            <div id="sched-opts-container" class="sched-opts-container"
                [class.sched-opts-container-max-height]="showFileManager">
                <table class="sched-opts-table">
                    <tr>
                        <td class="td-label">
                            <label for="startDate">{{'main.tabs.custom_grids.scheduled_exports.labels.start_date' |
                                translate }}:</label>
                        </td>
                        <td *ngIf="isAdmin">

                            <mat-form-field>
                                <mat-label>{{'main.tabs.custom_grids.scheduled_exports.labels.choose_a_date' | translate
                                    }}</mat-label>
                                <input matInput id="startDate" name="startDate" required formControlName="startDate"
                                    [matDatepicker]="picker" class="e-list-text">
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker style='width: 1.5em; height: 1.5em'
                                    [disabled]="!isAdmin"></mat-datepicker>
                            </mat-form-field>
                            <div *ngIf="submitted && startDate.errors?.['required']" class="e-error">
                                {{'main.tabs.custom_grids.scheduled_exports.labels.start_date_is_required' | translate
                                }}.
                            </div>
                        </td>
                        <td *ngIf="!isAdmin">
                            <label>{{getShortDateFromDate(schedule.StartDate)}}</label>
                        </td>
                        <td class="td-label">
                            <label>{{'main.tabs.custom_grids.scheduled_exports.labels.next_run_date' | translate
                                }}:</label>
                        </td>
                        <td class="e-list-text">
                            {{getShortDateFromDate(schedule.NextRunDate)}}
                        </td>
                    </tr>
                    <tr>
                        <td rowspan="4" class="td-label">
                            <label for="frequency">{{'main.tabs.custom_grids.scheduled_exports.labels.frequency' |
                                translate }}:</label>
                        </td>
                        <td rowspan="4" *ngIf="isAdmin">
                            <mat-radio-group aria-label="Frequency" class="e-list-text" formControlName="frequency"
                                id="frequency" name="frequency" [disabled]="!isAdmin">
                                <mat-radio-button value="monthly" class="e-list-text"><label
                                        class="radio-label">{{'main.tabs.custom_grids.scheduled_exports.labels.monthly'
                                        |
                                        translate }}</label></mat-radio-button><br />
                                <mat-radio-button value="weekly" class="e-list-text"><label
                                        class="radio-label">{{'main.tabs.custom_grids.scheduled_exports.labels.weekly' |
                                        translate }}</label></mat-radio-button><br />
                                <mat-radio-button value="daily" class="e-list-text"><label
                                        class="radio-label">{{'main.tabs.custom_grids.scheduled_exports.labels.daily' |
                                        translate }}</label></mat-radio-button>
                            </mat-radio-group>
                            <div *ngIf="submitted && frequency.errors?.['required']" class="e-error">
                                {{'main.tabs.custom_grids.scheduled_exports.labels.frequency_required' | translate }}.
                            </div>
                        </td>
                        <td *ngIf="!isAdmin" rowspan="4">
                            <label>{{schedule.Frequency}}</label>
                        </td>
                        <td class="td-label">
                            <label>{{'main.tabs.custom_grids.scheduled_exports.labels.created_by' | translate
                                }}:</label>
                        </td>
                        <td class="e-list-text">
                            {{schedule.CreatedBy}}
                        </td>
                    </tr>
                    <tr>
                        <td class="td-label">
                            <label>{{'main.tabs.custom_grids.scheduled_exports.labels.created_date' | translate
                                }}:</label>
                        </td>
                        <td class="e-list-text">
                            {{getShortDateFromDate(schedule.CreatedDate)}}
                        </td>
                    </tr>
                    <tr>
                        <td class="td-label">
                            <label>{{'main.tabs.custom_grids.scheduled_exports.labels.last_modified_by' | translate
                                }}:</label>
                        </td>
                        <td class="e-list-text">
                            {{schedule.LastModifiedBy}}
                        </td>
                    </tr>
                    <tr>
                        <td class="td-label">
                            <label>{{'main.tabs.custom_grids.scheduled_exports.labels.last_modified_date' | translate
                                }}:</label>
                        </td>
                        <td class="e-list-text">
                            {{getShortDateFromDate(schedule.LastModifiedDate)}}
                        </td>
                    </tr>
                </table>
                <app-file-manager #fileManagerComponent *ngIf="showFileManager"
                    (saveBtnDisabled)="onFileSaveChange($event)" [config]="usersConfig" [standalone]="false"
                    [selectedUsers]="schedule.ScheduleUserPermissions || []">
                </app-file-manager>
            </div>
            <div class="e-footer-content">
                <button type="button" class="e-control e-btn e-lib e-ok-btn e-primary e-flat"
                    (click)="closeMessageDialog()"
                    [disabled]="isSubmitting">{{'main.tabs.custom_grids.scheduled_exports.labels.close' | translate
                    }}</button>
                <button type="button" *ngIf="schedule.ScheduledExportId && isAdmin"
                    class="e-control e-btn e-lib e-ok-btn e-primary e-flat" (click)="onCancelSchedule()"
                    [disabled]="isSubmitting">{{'main.tabs.custom_grids.scheduled_exports.labels.cancel_schedule' |
                    translate }}</button>
                <button type="button" *ngIf="isAdmin" class="e-control e-btn e-lib e-ok-btn e-primary e-flat"
                    (click)="onSubmit()"
                    [disabled]="disableSubmitBtn()">{{'main.tabs.custom_grids.scheduled_exports.labels.submit' |
                    translate }}</button>
            </div>
        </form>
    </div>
    <div class="e-dlg-overlay scheduler-overlay"></div>
</div>