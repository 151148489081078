import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';

import { GeographyService } from './geography.service';
import {
  ColumnDefinitionService,
  IGridColumnGroup,
  MetricsGridConfig,
  BreadcrumbService,
  ActiveFilterService,
  FormatService,
  GridSortingService,
} from '../core';
import { TranslateService } from '@ngx-translate/core';
import { HeaderService } from '../header';
import { GeographyComponent } from './geography.component';
import { DownloadsService } from '../downloads';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'rdo-district-grid',
  templateUrl: 'geography.component.html',
  styleUrls: ['geography.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DistrictComponent
  extends GeographyComponent
  implements OnInit, OnDestroy
{
  constructor(
    protected authenticationService: AuthenticationService,
    protected filterService: ActiveFilterService,
    protected columnService: ColumnDefinitionService,
    protected headerService: HeaderService,
    protected formatService: FormatService,
    private geographyService: GeographyService,
    private breadcrumbService: BreadcrumbService,
    private downloadsService: DownloadsService,
    protected translateService: TranslateService,
    protected gridSortingService: GridSortingService,
    protected router: Router
  ) {
    super(
      authenticationService,
      filterService,
      columnService,
      headerService,
      formatService,
      translateService,
      gridSortingService,
      router
    );
    this.gridName = 'DISTRICTS_GRID';
    this.dataKey = 'DistrictId';
    this.useExpandedGrid = true;
    this.breadcrumbService.update([
      { title: 'main.tabs.geography.districts.all', class: 'active' },
    ]);
    this.gridSortingService.setGridName(this.gridName);
  }

  loadData(): Observable<any> {
    return this.geographyService.getDistricts(
      null,
      this.paging,
      this.getSorting(),
      true
    ) /*.pipe(map(region => {
			if (region.Items && region.Items[0] && region.Items[0].ClientDistrict == 'ALL GEOGRAPHIES') {
				region.Items[0].ClientDistrict = 'main.tabs.geography.all'
			}
			return region;
		}))*/;
  }

  exportExcel(args: any) {
    this.loading = true;

    this.sorting.sortField = this.gridSortingService.getSortFieldOrDefault();
    this.sorting.sortOrder =
      this.gridSortingService.getSortDirectionOrDefault();
    const translatedConfig = this.gridConfig.cloneAndTranslate((text) =>
      this.formatService.translateAndFormat(text, false)
    );
    this.subscriptions.push(
      this.geographyService
        .getDistrictsDownload(this.getSorting(), translatedConfig)
        .subscribe((blob) => {
          this.loading = false;
          this.downloadsService.saveExcelBlob(blob);
        })
    );
  }

  getFixedColumnGroup(): IGridColumnGroup {
    return {
      title: '',
      visible: true,
      locked: true,
      expandable: false,
      columns: [
        {
          title: 'main.tabs.geography.districts.singular',
          field: 'ClientDistrict',
          sortColumn: 'ClientDistrict',
          minWidth: 125,
          width: 100,
          maxWidth: 255,
          autoWidth: true,
          headerStyle: {
            'text-align': 'left',
          },
          cellStyle: {
            'text-align': 'left',
          },
          childConfig: {
            field: '',
          },
        },
        this.columnService.BranchCount(),
      ],
    };
  }

  configureGrid(): MetricsGridConfig {
    this.frozenWidth = '335px';

    const grid = new MetricsGridConfig(
      [this.getFixedColumnGroup(), ...this.getColumnGroups()],
      (p) => this.loadChildren(p),
      true
    );

    return grid;
  }

  private loadChildren = (parent: any): Observable<Array<any>> => {
    return parent.DistrictId
      ? this.geographyService
          .getBranches(
            parent.DistrictId,
            { pageSize: 500, page: 1 },
            this.getSorting(),
            false
          )
          .pipe(map((x) => x.Items.map((item) => this.convert(item))))
      : of(null);
  };

  private convert = (item: any): any => {
    const clone = (<any>Object).assign({}, item);
    clone.ClientDistrict = null;
    clone.LocationCount = item.LocationCode;
    return clone;
  };
}
