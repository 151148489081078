import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';

/*
 * Formats string for display
 * Usage:
 *   currencyValue | rdoString
 * Example:
 *   {{ 'Yay' | rdoString}}
 *   formats to: Yay
 *   {{ null | rdoString}}
 *   formats to: -
 */
@Pipe({
  name: 'rdoString',
})
export class RdoStringPipe implements PipeTransform {
  transform = (value: any): string => {
    if (_.isNil(value)) {
      return '-';
    }

    return value;
  };
}
