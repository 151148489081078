﻿<div class="container-fluid" id="category-product-type">
	<div class="row scroll-container">

		<div class="rdo-col-card-fixed scroll"
			 infinite-scroll
			 [infiniteScrollThrottle]="500"
			 (scrolled)="getPaginatedCards()"
			 [scrollWindow]="false">
			<div class="rdo-card-list">
				<rdo-nav-card *ngFor="let c of productCards"
							  [data]="c" [titleSelector]="'Description'"
							  [selected]="isSelected(c)"
							  [comparisonMode]="mode"
							  (selectedChange)="selectProduct(c)"
							  [rdo-scroll-into]="isSelected(c)">
				</rdo-nav-card>
			</div>
		</div>

		<div class="rdo-col-card-offset scroll-container">
			<div class="row">
				<div class="col-xs-12">
					<h3 style="line-height: 40px;">
						<ng-container *ngIf="selectedProduct">
                            <div class="product-types__icon-container">
							<rdo-category-icon [category]="selectedProduct.CategoryIcon || selectedProduct.RouseCategory"></rdo-category-icon>
                            </div>
                            <rdo-product-type-title [title]="selectedProduct.Description" [isRentedAs]="params.useRentedAsProductType"></rdo-product-type-title>
						</ng-container>
					</h3>
				</div>
			</div>

			<div class="row margin-bottom-xl">
				<div class="col-xs-12">
					<ul class="nav nav-pills rdo-nav-pills">
						<li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
							<a [routerLink]="['./']" [ngClass]="params?.useRentedAsProductType && 'disabled'" >{{'main.core.common.dashboard' | translate}}</a>
						</li>
						<li routerLinkActive="active">
							<a [routerLink]="['sales-reps']">{{'main.core.common.counts.sales_reps.plural' | translate}}</a>
						</li>
						<li routerLinkActive="active">
							<a [routerLink]="['customers']" >{{'main.core.common.counts.customers.plural' | translate}}</a>
						</li>
						<li routerLinkActive="active">
							<a [routerLink]="['rate-types']">{{'main.core.common.counts.rate_types.plural' | translate}}</a>
						</li>
						<li routerLinkActive="active">
							<a [routerLink]="['mom-change']" [ngClass]="params?.useRentedAsProductType && 'disabled'">{{'main.tabs.equipment.product_types.mom_change' | translate}}</a>
						</li>
						<li routerLinkActive="active">
							<a [routerLink]="['yoy-change']" [ngClass]="params?.useRentedAsProductType && 'disabled'">{{'main.tabs.equipment.product_types.yoy_change' | translate}}</a>
						</li>
						<li routerLinkActive="active" id="product-type-rental-assets-list-item" *ngIf="hasAssetGrid">
							<a [routerLink]="['rental-assets']" [ngClass]="params?.useRentedAsProductType && 'disabled'">{{'main.tabs.equipment.rental_assets.title' | translate}}</a>
						</li>
					</ul>
				</div>
			</div>
			<div class="row" id="category-product-type-content">
				<div class="scroll-container">
					<div class="col-xs-12 scroll active">
						<router-outlet></router-outlet>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
