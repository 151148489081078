import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { GeographyService } from './geography.service';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import {
  ColumnDefinitionService,
  IGridColumnGroup,
  BreadcrumbService,
  ActiveFilterService,
  MetricsGridConfig,
  FormatService,
  GridSortingService,
} from './../core';

import { HeaderService } from '../header';
import { GeographyComponent } from './geography.component';
import { DownloadsService } from '../downloads';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'rdo-branch-grid',
  templateUrl: 'geography.component.html',
  styleUrls: ['geography.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BranchComponent
  extends GeographyComponent
  implements OnInit, OnDestroy
{
  constructor(
    protected authenticationService: AuthenticationService,
    protected filterService: ActiveFilterService,
    protected columnService: ColumnDefinitionService,
    protected headerService: HeaderService,
    protected formatService: FormatService,
    private geographyService: GeographyService,
    private breadcrumbService: BreadcrumbService,
    private downloadsService: DownloadsService,
    protected translateService: TranslateService,
    protected gridSortingService: GridSortingService,
    protected router: Router
  ) {
    super(
      authenticationService,
      filterService,
      columnService,
      headerService,
      formatService,
      translateService,
      gridSortingService,
      router
    );
    this.gridName = 'BRANCHES_GRID';
    this.breadcrumbService.update([
      { title: 'main.tabs.geography.branches.all', class: 'active' },
    ]);
    this.gridSortingService.setGridName(this.gridName);
  }

  loadData(): Observable<any> {
    return this.geographyService.getBranches(
      null,
      this.paging,
      this.getSorting(),
      true
    ) /*.pipe(map(region => {
			if (region.Items && region.Items[0] && region.Items[0].LocationCode == 'ALL GEOGRAPHIES') {
				region.Items[0].LocationCode = 'main.tabs.geography.all';
			}
			return region;
		}))*/;
  }

  exportExcel(args: any) {
    this.loading = true;
    this.sorting.sortField = this.gridSortingService.getSortFieldOrDefault();
    this.sorting.sortOrder =
      this.gridSortingService.getSortDirectionOrDefault();
    const translatedConfig = this.gridConfig.cloneAndTranslate((text) =>
      this.formatService.translateAndFormat(text, false)
    );
    this.subscriptions.push(
      this.geographyService
        .getBranchesDownload(this.getSorting(), translatedConfig)
        .subscribe((blob) => {
          this.loading = false;
          this.downloadsService.saveExcelBlob(blob);
        })
    );
  }

  configureGrid(): MetricsGridConfig {
    this.frozenWidth = '335px';
    return new MetricsGridConfig([
      this.getFixedColumnGroup(),
      ...this.getColumnGroups(),
    ]);
  }

  getFixedColumnGroup(): IGridColumnGroup {
    return {
      title: "",
      visible: true,
      locked: true,
      expandable: false,
      columns: [
        {
          title: "main.tabs.geography.branches.singular",
          field: "LocationCode",
          sortColumn: "LocationCode",
          minWidth: 125,
          width: 100,
          maxWidth: 255,
          autoWidth: true,
          headerStyle: {
            "text-align": "left",
          },
          cellStyle: {
            "text-align": "left",
          },
          isString: true,
        },
      ],
    };
  }
}
