import { instrumentMethod } from '../tools/instrumentMethod';
import { monitor } from '../tools/monitor';
import { Observable } from '../tools/observable';
import { assign } from '../tools/utils/polyfills';
import { clocksNow } from '../tools/utils/timeUtils';
import { normalizeUrl } from '../tools/utils/urlPolyfill';
var fetchObservable;
export function initFetchObservable() {
  if (!fetchObservable) {
    fetchObservable = createFetchObservable();
  }
  return fetchObservable;
}
export function resetFetchObservable() {
  fetchObservable = undefined;
}
function createFetchObservable() {
  return new Observable(function (observable) {
    if (!window.fetch) {
      return;
    }
    var stop = instrumentMethod(window, 'fetch', function (call) {
      return beforeSend(call, observable);
    }, {
      computeHandlingStack: true
    }).stop;
    return stop;
  });
}
function beforeSend(_a, observable) {
  var parameters = _a.parameters,
    onPostCall = _a.onPostCall,
    handlingStack = _a.handlingStack;
  var input = parameters[0],
    init = parameters[1];
  var methodFromParams = init && init.method;
  if (methodFromParams === undefined && input instanceof Request) {
    methodFromParams = input.method;
  }
  var method = methodFromParams !== undefined ? String(methodFromParams).toUpperCase() : 'GET';
  var url = input instanceof Request ? input.url : normalizeUrl(String(input));
  var startClocks = clocksNow();
  var context = {
    state: 'start',
    init: init,
    input: input,
    method: method,
    startClocks: startClocks,
    url: url,
    handlingStack: handlingStack
  };
  observable.notify(context);
  // Those properties can be changed by observable subscribers
  parameters[0] = context.input;
  parameters[1] = context.init;
  onPostCall(function (responsePromise) {
    return afterSend(observable, responsePromise, context);
  });
}
function afterSend(observable, responsePromise, startContext) {
  var context = startContext;
  function reportFetch(partialContext) {
    context.state = 'resolve';
    assign(context, partialContext);
    observable.notify(context);
  }
  responsePromise.then(monitor(function (response) {
    reportFetch({
      response: response,
      responseType: response.type,
      status: response.status,
      isAborted: false
    });
  }), monitor(function (error) {
    var _a, _b;
    reportFetch({
      status: 0,
      isAborted: ((_b = (_a = context.init) === null || _a === void 0 ? void 0 : _a.signal) === null || _b === void 0 ? void 0 : _b.aborted) || error instanceof DOMException && error.code === DOMException.ABORT_ERR,
      error: error
    });
  }));
}
