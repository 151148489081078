import {
  Component,
  Input,
  Output,
  ElementRef,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as _ from 'lodash';
import { FormatService } from './../../query';
import { RevenueChartHelperService, HighchartsConfiguration } from './../base';
import { RevenueSlice } from './../../../summary/revenue.service';
import { TranslateService } from '@ngx-translate/core';
import { RdoPathToIdPipe } from '../../pipes';
import { CHART_COLORS } from '../base/chart-color-constants';
import { LocaleService } from '../../services';
/* eslint-disable @angular-eslint/no-input-rename, no-use-before-define, @angular-eslint/no-output-rename, @angular-eslint/component-selector */
@Component({
  selector: 'revenue-vs-benchmark-chart',
  styleUrls: ['./../base/revenue-chart-helper.scss'],
  templateUrl: './revenue-versus-book-chart.component.html',
})
export class RevenueBenchmarkChartComponent implements OnChanges {
  private I18N_PATH =
    'main.tabs.summary.charts.client_revenue.vs_bench_quartiles';
  private chart: any;
  chartConfig: HighchartsConfiguration = new HighchartsConfiguration();
  @Input('chart-data') data: any;
  @Input('active-slices') activeSlices: boolean;
  @Input('dashed-line-offset') dashedLineOffset: number;
  @Output('slice-click')
  sliceClick: EventEmitter<RevenueSlice> = new EventEmitter<RevenueSlice>();

  constructor(
    private hostElement: ElementRef,
    private formatService: FormatService,
    private translateService: TranslateService,
    private revUtilChartService: RevenueChartHelperService,
    private localeService: LocaleService
  ) {
    this.translateService.stream(this.I18N_PATH).subscribe((i18n) => {
      if (this.chart) {
        this.chart
          .get(LEGEND_TYPE.ABOVE_TQ)
          .update({ name: i18n[LEGEND_TYPE.ABOVE_TQ] });
        this.chart
          .get(LEGEND_TYPE.BELOW_BQ)
          .update({ name: i18n[LEGEND_TYPE.BELOW_BQ] });
        this.chart
          .get(LEGEND_TYPE.BTW_AVG_AND_TQ)
          .update({ name: i18n[LEGEND_TYPE.BTW_AVG_AND_TQ] });
        this.chart
          .get(LEGEND_TYPE.BTW_BQ_AND_AVG)
          .update({ name: i18n[LEGEND_TYPE.BTW_BQ_AND_AVG] });
        this.chart
          .get(LEGEND_TYPE.NOT_COMPARED)
          .update({ name: i18n[LEGEND_TYPE.NOT_COMPARED] });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.data && this.dashedLineOffset) {
      this.loadChart(this.data);
    }
  }

  private pivotData(
    data: Array<any>,
    revUtilChartService: RevenueChartHelperService
  ) {
    const pivotedData: any = {};
    pivotedData.revenueUnbenchmarked = [];
    pivotedData.revenueBelowBQ = [];
    pivotedData.revenueAvgToBQ = [];
    pivotedData.revenueTQToAvg = [];
    pivotedData.revenueAboveTQ = [];
    const currentCategoryAlpha = '1.0';
    const fadedAlpha = '0.3';
    _.forEach(data, (inData) => {
      const isCurrentCategory = revUtilChartService.isCurrentCategory(inData);
      pivotedData.revenueUnbenchmarked.push({
        y: inData.RevenueTotal - inData.RevenueBenchmarked,
        color: CHART_COLORS.COLOR_A6A6A6,
        borderColor:
          this.revUtilChartService.isCurrentCategory(inData) &&
          inData.RevenueTotal - inData.RevenueBenchmarked
            ? CHART_COLORS.COLOR_000000
            : null,
        borderWidth:
          this.revUtilChartService.isCurrentCategory(inData) &&
          inData.RevenueTotal - inData.RevenueBenchmarked
            ? 3
            : 0,
        selected:
          this.revUtilChartService.isCurrentCategory(inData) &&
          inData.RevenueTotal - inData.RevenueBenchmarked,
      });
      pivotedData.revenueBelowBQ.push({
        y: inData.RevenueBelowBQ,
        revenueBelowBenchmarkBQ: inData.RevenueBelowBQ,
        discountToBenchmarkAvg:
          inData.RevenueBelowBQ - inData.RevenueBelowBQAvgBench,
        discountToBenchmarkBQ:
          inData.RevenueBelowBQ - inData.RevenueBelowBQBench,
        cssClass: 'text-danger',
        color: CHART_COLORS.COLOR_843438,
        borderColor:
          this.revUtilChartService.isCurrentCategory(inData) &&
          inData.RevenueBelowBQ
            ? CHART_COLORS.COLOR_000000
            : null,
        borderWidth:
          this.revUtilChartService.isCurrentCategory(inData) &&
          inData.RevenueBelowBQ
            ? 3
            : 0,
        selected:
          this.revUtilChartService.isCurrentCategory(inData) &&
          inData.RevenueBelowBQ,
      });
      pivotedData.revenueAvgToBQ.push({
        y: inData.RevenueAvgToBQ,
        revenueBetweenBenchmarkBQAndAvg: inData.RevenueAvgToBQ,
        discountToBenchmarkAvg:
          inData.RevenueAvgToBQ - inData.RevenueAvgToBQBench,
        cssClass: 'text-danger',
        color: CHART_COLORS.COLOR_CA8C8C,
        borderColor:
          this.revUtilChartService.isCurrentCategory(inData) &&
          inData.RevenueAvgToBQ
            ? CHART_COLORS.COLOR_000000
            : null,
        borderWidth:
          this.revUtilChartService.isCurrentCategory(inData) &&
          inData.RevenueAvgToBQ
            ? 3
            : 0,
        selected:
          this.revUtilChartService.isCurrentCategory(inData) &&
          inData.RevenueAvgToBQ,
      });
      pivotedData.revenueTQToAvg.push({
        y: inData.RevenueTQToAvg,
        revenueBetweenBenchmarkTQAndAvg: inData.RevenueTQToAvg,
        premiumToBenchmarkAvg:
          inData.RevenueTQToAvg - inData.RevenueTQToAvgBench,
        color: CHART_COLORS.COLOR_92AA7A,
        borderColor:
          this.revUtilChartService.isCurrentCategory(inData) &&
          inData.RevenueTQToAvg
            ? CHART_COLORS.COLOR_000000
            : null,
        borderWidth:
          this.revUtilChartService.isCurrentCategory(inData) &&
          inData.RevenueTQToAvg
            ? 3
            : 0,
        selected:
          this.revUtilChartService.isCurrentCategory(inData) &&
          inData.RevenueTQToAvg,
      });
      pivotedData.revenueAboveTQ.push({
        y: inData.RevenueAboveTQ,
        revenueAboveBenchmarkTQ: inData.RevenueAboveTQ,
        premiumToBenchmarkAvg:
          inData.RevenueAboveTQ - inData.RevenueAboveTQAvgBench,
        premiumToBenchmarkTQ:
          inData.RevenueAboveTQ - inData.RevenueAboveTQBench,
        color: CHART_COLORS.COLOR_385723,
        borderColor:
          this.revUtilChartService.isCurrentCategory(inData) &&
          inData.RevenueAboveTQ
            ? CHART_COLORS.COLOR_000000
            : null,
        borderWidth:
          this.revUtilChartService.isCurrentCategory(inData) &&
          inData.RevenueAboveTQ
            ? 3
            : null,
        selected:
          this.revUtilChartService.isCurrentCategory(inData) &&
          inData.RevenueAboveTQ,
      });
    });
    return pivotedData;
  }

  private loadChart(data: Array<any>) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;
    const pivotedData: any = this.pivotData(
      data,
      component.revUtilChartService
    );
    const options = {
      colors: [
        CHART_COLORS.COLOR_385723,
        CHART_COLORS.COLOR_92AA7A,
        CHART_COLORS.COLOR_CA8C8C,
        CHART_COLORS.COLOR_843438,
        CHART_COLORS.COLOR_A6A6A6,
      ],
      chart: {
        height: this.hostElement.nativeElement.parentElement.offsetHeight,
        width: this.hostElement.nativeElement.parentElement.offsetWidth,
        type: 'column',
        borderRadiusTopLeft: 5,
        borderRadiusTopRight: 5,
      },
      title: {
        text: null,
      },
      legend: {
        enabled: true,
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        itemMarginTop: 5,
        itemMarginBottom: 5,
        labelFormatter: function () {
          return `<div id="legend-${this.userOptions.id}">${this.name}</div>`;
        },
        useHTML: true,
        itemHiddenStyle: {
          symbolOpacity: 0.3,
          color: CHART_COLORS.TRANSPARENCY,
          textDecoration: 'none',
        },
      },
      loading: {
        showDuration: 100,
        hideDuration: 100,
      },
      xAxis: {
        labels: {
          useHTML: true,
          formatter: function () {
            const locale = component.localeService.getLocale();
            const dateStr =
              component.revUtilChartService.addBenchmarkDiffForRevenueChart(
                this.value,
                locale
              );
            return `<div id="x-axis-${this.value.index}">${dateStr}</div>`;
          },
        },
        categories: data.map((obj, index) => {
          obj.index = index;
          return obj;
        }),
        plotLines: [
          {
            color: CHART_COLORS.COLOR_000000,
            width: 2,
            dashStyle: 'Dash',
            value: data.length - (component.dashedLineOffset + 0.5), // dashed line at the carryover months.
          },
        ],
      },
      plotOptions: {
        series: {
          stacking: 'normal',
          cursor: null,
          events: {
            click: (e) => {
              if (this.activeSlices) {
                // only 'current' month is clickable
                const isCurrent = this.revUtilChartService.isCurrentCategory(
                  e.point.category
                );
                if (isCurrent) {
                  this.sliceClick.emit(e.point.series.options.sliceType);
                }
              }
            },
          },
          point: {
            events: {
              mouseOver: function (e) {
                // make sure 'pointer' is displayed only on current month category
                // so we dont confuse users on what they can click
                if (component.activeSlices) {
                  const isCurrent =
                    component.revUtilChartService.isCurrentCategory(
                      e.target.category
                    );
                  if (isCurrent) {
                    this.graphic.element.style.cursor = 'pointer';
                  }
                }
              },
            },
          },
        },
      },
      yAxis: [
        {
          endOnTick: false,
          startOnTick: false,
          title: {
            enabled: false,
          },
          plotLines: [
            {
              color: '#D8D8D8',
              width: 1,
              value: 0,
            },
          ],
          minorGridLineWidth: 0,
          labels: {
            formatter: function () {
              return (
                component.formatService.selectedCurrencySymbol +
                this.axis.defaultLabelFormatter.call(this)
              );
            },
          },
        },
      ],
      series: [
        {
          id: LEGEND_TYPE.ABOVE_TQ,
          data: pivotedData.revenueAboveTQ,
          name: component.translateService.instant(
            this.I18N_PATH + '.' + LEGEND_TYPE.ABOVE_TQ
          ),
          borderRadiusTopLeft: 2,
          borderRadiusTopRight: 2,
          borderColor: 'rgba(255, 255, 255, 1.0)',
          borderWidth: 1,
          sliceType: RevenueSlice.AboveTq,
          states: {
            select: {
              color: CHART_COLORS.COLOR_385723,
            },
          },
        },
        {
          id: LEGEND_TYPE.BTW_AVG_AND_TQ,
          data: pivotedData.revenueTQToAvg,
          name: component.translateService.instant(
            this.I18N_PATH + '.' + LEGEND_TYPE.BTW_AVG_AND_TQ
          ),
          borderColor: 'rgba(255, 255, 255, 1.0)',
          borderWidth: 1,
          sliceType: RevenueSlice.TqToAvg,
          states: {
            select: {
              color: CHART_COLORS.COLOR_92AA7A,
            },
          },
        },
        {
          id: LEGEND_TYPE.BTW_BQ_AND_AVG,
          data: pivotedData.revenueAvgToBQ,
          name: component.translateService.instant(
            this.I18N_PATH + '.' + LEGEND_TYPE.BTW_BQ_AND_AVG
          ),
          borderColor: 'rgba(255, 255, 255, 1.0)',
          borderWidth: 1,
          sliceType: RevenueSlice.AvgToBq,
          states: {
            select: {
              color: CHART_COLORS.COLOR_CA8C8C,
            },
          },
        },
        {
          id: LEGEND_TYPE.BELOW_BQ,
          data: pivotedData.revenueBelowBQ,
          name: component.translateService.instant(
            this.I18N_PATH + '.' + LEGEND_TYPE.BELOW_BQ
          ),
          borderColor: 'rgba(255, 255, 255, 1.0)',
          borderWidth: 1,
          sliceType: RevenueSlice.BelowBq,
          states: {
            select: {
              color: CHART_COLORS.COLOR_843438,
            },
          },
        },
        {
          id: LEGEND_TYPE.NOT_COMPARED,
          data: pivotedData.revenueUnbenchmarked,
          name: component.translateService.instant(
            this.I18N_PATH + '.' + LEGEND_TYPE.NOT_COMPARED
          ),
          borderRadiusBottomLeft: 2,
          borderRadiusBottomRight: 2,
          borderColor: 'rgba(255, 255, 255, 1.0)',
          borderWidth: 1,
          sliceType: RevenueSlice.Unbenchmarked,
          states: {
            select: {
              color: CHART_COLORS.COLOR_A6A6A6,
            },
          },
        },
      ],
      tooltip: {
        backgroundColor: null,
        borderWidth: 0,
        shadow: false,
        style: {
          padding: 0,
        },
        positioner: (boxWidth, boxHeight, point) =>
          this.revUtilChartService.getTooltipPositionCustom(point, 85, 285, 0),
        formatter: function () {
          let markup: string = '';
          if (this.series.userOptions.id === LEGEND_TYPE.ABOVE_TQ) {
            markup = component.getFormatter(
              component.formatService,
              component.I18N_PATH + '.tq.revenue_above_bench_tq',
              this.point.revenueAboveBenchmarkTQ,
              this.point.percentage,
              this.color,
              component.I18N_PATH + '.tq.premium_to_bench_avg',
              this.point.premiumToBenchmarkAvg,
              component.I18N_PATH + '.tq.premium_to_bench_tq',
              this.point.premiumToBenchmarkTQ,
              this.point.cssClass
            );
          } else if (
            this.series.userOptions.id === LEGEND_TYPE.BTW_AVG_AND_TQ
          ) {
            markup = component.getFormatter(
              component.formatService,
              component.I18N_PATH + '.avg_tq.revenue_btw_bench_avg_and_tq',
              this.point.revenueBetweenBenchmarkTQAndAvg,
              this.point.percentage,
              this.color,
              component.I18N_PATH + '.avg_tq.premium_to_bench_avg',
              this.point.premiumToBenchmarkAvg,
              null,
              null,
              this.point.cssClass
            );
          } else if (
            this.series.userOptions.id === LEGEND_TYPE.BTW_BQ_AND_AVG
          ) {
            markup = component.getFormatter(
              component.formatService,
              component.I18N_PATH + '.bq_avg.revenue_btw_bench_bq_and_avg',
              this.point.revenueBetweenBenchmarkBQAndAvg,
              this.point.percentage,
              this.color,
              component.I18N_PATH + '.bq_avg.discount_to_bench_avg',
              this.point.discountToBenchmarkAvg,
              null,
              null,
              this.point.cssClass
            );
          } else if (this.series.userOptions.id === LEGEND_TYPE.BELOW_BQ) {
            markup = component.getFormatter(
              component.formatService,
              component.I18N_PATH + '.bq.revenue_below_bench_bq',
              this.point.revenueBelowBenchmarkBQ,
              this.point.percentage,
              this.color,
              component.I18N_PATH + '.bq.discount_to_bench_avg',
              this.point.discountToBenchmarkAvg,
              component.I18N_PATH + '.bq.discount_to_bench_bq',
              this.point.discountToBenchmarkBQ,
              this.point.cssClass
            );
          } else if (this.series.userOptions.id === LEGEND_TYPE.NOT_COMPARED) {
            markup = component.getFormatter(
              component.formatService,
              component.I18N_PATH + '.not_compared',
              this.point.y,
              this.point.percentage,
              this.color,
              null,
              null,
              null,
              null,
              this.point.cssClass
            );
          }
          return markup;
        },
        shared: false,
        useHTML: true,
      },
    };
    const config = new HighchartsConfiguration();
    config.exportingOptions = {
      getColumnsConfig: () => {
        return [
          {
            field: 'Month',
            displayName: component.translateService.instant(
              'main.core.charts.export.month'
            ),
            customFormat: (rowValues) => {
              return component.revUtilChartService.getShortDate(
                rowValues.Month
              );
            },
          },
          {
            field: 'RevenueAboveTQ',
            displayName: component.translateService.instant(
              'main.core.charts.export.revenue_above_bench_top_qrt'
            ),
          },
          {
            field: 'PremiumToBenchAvg',
            displayName: component.translateService.instant(
              'main.core.charts.export.premium_to_bench_avg'
            ),
            customFormat: (rowValues) => {
              return (
                rowValues.RevenueAboveTQ - rowValues.RevenueAboveTQAvgBench
              );
            },
          },
          {
            field: 'PremiumToBenchTQ',
            displayName: component.translateService.instant(
              'main.core.charts.export.premium_to_bench_top_qrt'
            ),
            customFormat: (rowValues) => {
              return rowValues.RevenueAboveTQ - rowValues.RevenueAboveTQBench;
            },
          },
          {
            field: 'RevenueTQToAvg',
            displayName: component.translateService.instant(
              'main.core.charts.export.revenue_btw_bench_avg_and_top_qrt'
            ),
          },
          {
            field: 'PremiumBenchAvg',
            displayName: component.translateService.instant(
              'main.core.charts.export.premium_to_bench_avg'
            ),
            customFormat: (rowValues) => {
              return rowValues.RevenueTQToAvg - rowValues.RevenueTQToAvgBench;
            },
          },
          {
            field: 'RevenueAvgToBQ',
            displayName: component.translateService.instant(
              'main.core.charts.export.revenue_btw_bench_bot_qrt_and_bench_avg'
            ),
          },
          {
            field: 'DiscountBenchAvgBtwAvgAndTQ',
            displayName: component.translateService.instant(
              'main.core.charts.export.discount_to_bench_avg_btw_avg_tq'
            ),
            customFormat: (rowValues) => {
              return rowValues.RevenueAvgToBQ - rowValues.RevenueAvgToBQBench;
            },
          },
          {
            field: 'RevenueBelowBQ',
            displayName: component.translateService.instant(
              'main.core.charts.export.revenue_bel_bench_bot_qrt'
            ),
          },
          {
            field: 'DiscountBenchAvgBelowBQ',
            displayName: component.translateService.instant(
              'main.core.charts.export.discount_to_bench_avg_bel_bq'
            ),
            customFormat: (rowValues) => {
              return (
                rowValues.RevenueBelowBQ - rowValues.RevenueBelowBQAvgBench
              );
            },
          },
          {
            field: 'DiscountBenchBottomQ',
            displayName: component.translateService.instant(
              'main.core.charts.export.discount_to_bench_bot_qrt'
            ),
            customFormat: (rowValues) => {
              return rowValues.RevenueBelowBQ - rowValues.RevenueBelowBQBench;
            },
          },
          {
            field: 'NotComparedRevenue',
            displayName: component.translateService.instant(
              'main.core.charts.export.not_compared_revenue'
            ),
            customFormat: (rowValues) => {
              return rowValues.RevenueTotal - rowValues.RevenueBenchmarked;
            },
          },
        ];
      },
      getFileName: () =>
        this.translateService.instant(
          'main.tabs.summary.charts.client_revenue.vs_bench_quartiles.title'
        ),
    };
    config.options = options;
    config.onLoadCallback = (chart) => {
      this.chart = chart;
    };
    this.chartConfig = config;
  }

  private getFormatter(
    formatService: any,
    tooltip1Label: string,
    tooltip1Value: number,
    tooltip1Percentage: number,
    tooltip1BulletColor: string,
    tooltip2Label: string,
    tooltip2Value: number,
    tooltip3Label: string,
    tooltip3Value: number,
    cssClass: string
  ) {
    const idPipe = new RdoPathToIdPipe();
    let markup = `	<div class="client-rate-bench-chart">
                            <table class="table rdo-table-tooltip">
                                <tr>
                                    <td id="${idPipe.transform('tooltip-' + tooltip1Label)}">
                                        <span style="color:${tooltip1BulletColor}">&#x25CF</span> ${this.translateService.instant(tooltip1Label)}
                                    </td>
                                    <td>
                                        ${formatService.formatCurrency(tooltip1Value, true)} (${formatService.formatNumber(tooltip1Percentage, 1)}%)
                                    </td>
                                </tr>`;
    if (tooltip2Label && tooltip2Value) {
      markup += `			<tr>
									<td id="${idPipe.transform('tooltip-' + tooltip2Label)}">
										<span class="${cssClass}">&nbsp;&nbsp;&nbsp;&nbsp;</span>${this.translateService.instant(tooltip2Label)}
									</td>
									<td>
										<span class="${cssClass}">${formatService.formatCurrency(tooltip2Value, true)}</span>
									</td>
								</tr>`;
    }
    if (tooltip3Label && tooltip3Value) {
      markup += `			<tr>
									<td id="${idPipe.transform('tooltip-' + tooltip3Label)}">
										<span class="${cssClass}">&nbsp;&nbsp;&nbsp;&nbsp;</span>${this.translateService.instant(tooltip3Label)}
									</td>
									<td>
										<span class="${cssClass}">${formatService.formatCurrency(tooltip3Value, true)}</span>
									</td>
								</tr>`;
    }
    markup += `
				</table>
			</div>`;
    return markup;
  }
}

enum LEGEND_TYPE {
  ABOVE_TQ = 'above_tq',
  BTW_AVG_AND_TQ = 'btw_avg_and_tq',
  BTW_BQ_AND_AVG = 'btw_bq_and_avg',
  BELOW_BQ = 'below_bq',
  NOT_COMPARED = 'not_compared',
}
