﻿<div class="panel panel-default rdo-panel">
        <div class="panel-heading">
            <div class="row">
                <div class="col-xs-12">
                    <h3 class="panel-title">
                        <a (click)="toggleOpen($event)">
                            <i class="fa fa-line-chart"></i>&nbsp; {{titleValue | translate}}
                        </a>
                    </h3>
                </div>
            </div>
        </div>
        <div class="panel-collapse"  [ngClass]="{'hide': !isOpen}">
            <div class="panel-body">
                <table class="table table-nowrap rdo-table-dashboard">
                    <colgroup>
                        <col style="width: 100px;">
                        <col>
                        <col>
                        <col>
                        <col>
                        <col>
                        <col>
                        <col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th *ngFor="let i of items" [ngClass]="{'filter-month-highlight': isMonthSelected(i.BenchMarkDate)}">
                                {{ i.BenchMarkDate | rdoLocalizedDate:'MMM yyyy'}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th rowspan="7">{{'main.tabs.equipment.product_types.rate_types.rate_trends.client_results' | translate}}</th>
                        </tr>
                        <tr>
                            <td>{{timeUnitTitle | translate}}</td>
                            <td *ngFor="let i of items" [ngClass]="{'text-muted':i.Interval === 0}">{{i.Interval | number:'1.1-1'}}</td>
                        </tr>
                        <tr style="font-weight: 600;">
                            <td>{{'main.tabs.equipment.product_types.rate_types.rate_trends.book_rate' | translate}}</td>
                            <td *ngFor="let i of items" [ngClass]="{'text-muted':i.BookRevenueRate === 0}">{{i.BookRevenueRate | rdoCurrency}}</td>
                        </tr>
                        <tr style="font-weight: 600;">
                            <td>{{'main.tabs.equipment.product_types.rate_types.rate_trends.floor_rate' | translate}}</td>
                            <td *ngFor="let i of items"[ngClass]="{'text-muted':i.FloorRevenueRate === 0}">{{i.FloorRevenueRate | rdoCurrency}}</td>
                        </tr>
                        <tr class="color-green-highlight">
                            <td>{{'main.tabs.equipment.product_types.rate_types.rate_trends.top_quartile_rate' | translate}}</td>
                            <td *ngFor="let i of items" [ngClass]="{'text-muted':i.TopQRevenueRate === 0}">
                                <span *ngIf="isMonthSelected(i.BenchMarkDate) && (getRateDifference(i.TopQRevenueRate ,i.BenchmarkedTopQRevenueRate) > 0)">
                                    <i class="fa fa-angle-up"></i>&nbsp;{{getRateDifference(i.TopQRevenueRate ,i.BenchmarkedTopQRevenueRate) | number:'1.0-0'}}</span>
                                <span *ngIf="isMonthSelected(i.BenchMarkDate) && (getRateDifference(i.TopQRevenueRate ,i.BenchmarkedTopQRevenueRate) < 0)" class="text-danger">
                                    <i class="fa fa-angle-down fa-fw"></i>&nbsp;{{getRateDifference(i.TopQRevenueRate ,i.BenchmarkedTopQRevenueRate) | number:'1.0-0'}}</span>
                                <span *ngIf="isMonthSelected(i.BenchMarkDate) && (getRateDifference(i.TopQRevenueRate ,i.BenchmarkedTopQRevenueRate) === 0)" class="text-muted"></span>
                                {{i.TopQRevenueRate | rdoCurrency}}
                            </td>
                        </tr>
                        <tr>
                            <td>{{'main.tabs.equipment.product_types.rate_types.rate_trends.average_rate' | translate}}</td>
                            <td *ngFor="let i of items" [ngClass]="{'text-muted':i.RevenueRate === 0}">
                                <span *ngIf="isMonthSelected(i.BenchMarkDate) && (getRateDifference(i.RevenueRate ,i.BenchmarkedRevenueRate) > 0)">
                                    <i class="fa fa-angle-up"></i>&nbsp;{{getRateDifference(i.RevenueRate ,i.BenchmarkedRevenueRate) | number:'1.0-0'}}</span>
                                <span *ngIf="isMonthSelected(i.BenchMarkDate) && (getRateDifference(i.RevenueRate ,i.BenchmarkedRevenueRate) < 0)" class="text-danger">
                                    <i class="fa fa-angle-down fa-fw"></i>&nbsp;{{getRateDifference(i.RevenueRate ,i.BenchmarkedRevenueRate) | number:'1.0-0'}}</span>
                                <span *ngIf="isMonthSelected(i.BenchMarkDate) && (getRateDifference(i.RevenueRate ,i.BenchmarkedRevenueRate) === 0)" class="text-muted"></span>
                                {{i.RevenueRate | rdoCurrency}}
                            </td>
                        </tr>
                        <tr class="color-red-highlight">
                            <td>{{'main.tabs.equipment.product_types.rate_types.rate_trends.bottom_quartile_rate' | translate}}</td>
                            <td *ngFor="let i of items" [ngClass]="{'text-muted':i.BotQRevenueRate === 0}">
                                <span *ngIf="isMonthSelected(i.BenchMarkDate) && (getRateDifference(i.BotQRevenueRate, i.BenchmarkedBotQRevenueRate) > 0)">
                                    <i class="fa fa-angle-up"></i>&nbsp;{{getRateDifference(i.BotQRevenueRate, i.BenchmarkedBotQRevenueRate) | number:'1.0-0'}}</span>
                                <span *ngIf="isMonthSelected(i.BenchMarkDate) && (getRateDifference(i.BotQRevenueRate, i.BenchmarkedBotQRevenueRate) < 0)" class="text-danger">
                                    <i class="fa fa-angle-down fa-fw"></i>&nbsp;{{getRateDifference(i.BotQRevenueRate, i.BenchmarkedBotQRevenueRate) | number:'1.0-0'}}</span>
                                <span *ngIf="isMonthSelected(i.BenchMarkDate) && (getRateDifference(i.BotQRevenueRate, i.BenchmarkedBotQRevenueRate) === 0)" class="text-muted"></span>
                                {{i.BotQRevenueRate | rdoCurrency}}
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <th rowspan="4">{{'main.core.common.rates.benchmark' | translate}}</th>
                        </tr>
                        <tr class="color-green-highlight">
                            <td>{{'main.tabs.equipment.product_types.rate_types.rate_trends.top_quartile_rate' | translate}}</td>
                                <td *ngFor="let i of items" [ngClass]="{'text-muted':i.BenchmarkedTopQRevenueRate === 0}">{{i.BenchmarkedTopQRevenueRate | rdoCurrency}}</td>
                        </tr>
                        <tr>
                            <td>{{'main.tabs.equipment.product_types.rate_types.rate_trends.average_rate' | translate}}</td>
                                <td *ngFor="let i of items" [ngClass]="{'text-muted':i.BenchmarkedRevenueRate === 0}">{{i.BenchmarkedRevenueRate | rdoCurrency}}</td>
                        </tr>
                        <tr class="color-red-highlight">
                            <td>{{'main.tabs.equipment.product_types.rate_types.rate_trends.bottom_quartile_rate' | translate}}</td>
                                <td *ngFor="let i of items" [ngClass]="{'text-muted':i.BenchmarkedBotQRevenueRate === 0}">{{i.BenchmarkedBotQRevenueRate | rdoCurrency}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
