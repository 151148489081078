import { Directive, ElementRef, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import { FilterInfoService } from '../../../filter/services/filter-info.service';
import { ChartExportService } from './chart-export-service';
import { SortOptions, FormatService } from '../../query';
import { DownloadsService } from '../../../downloads';
import { BreadcrumbService } from '../../view';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';
import html2canvas from 'html2canvas';
export type IOnLoadCallback = (chart: Highcharts.Chart) => void;

export class HighchartsConfiguration {
  options: any;
  exportingOptions: any;
  onLoadCallback: IOnLoadCallback;
  jpeg: () => unknown;
  xlsx: () => unknown;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[rdo-highcharts]',
})
export class HighchartsDirective {
  chart: Highcharts.Chart;
  hostElement: ElementRef;
  renderTo: Highcharts.Options;
  currentConfig: HighchartsConfiguration;

  constructor(
    ele: ElementRef,
    private filterInfoService: FilterInfoService,
    private chartExportService: ChartExportService,
    private downloadsService: DownloadsService,
    private router: Router,
    private breadCrumbService: BreadcrumbService,
    private translateService: TranslateService
  ) {
    this.hostElement = ele;
  }

  @Input() useVendorExport: boolean = false;
  @Input() chartId: string;
  @Input('rdo-highcharts') set config(config: any) {
    if (!config || !config.options) {
      return;
    }
    this.setupExportFunctions(config);
    const opt = config.options;
    const callback = config.onLoadCallback;
    // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-this-alias
    const _this = this;
    if (opt.series || opt.data) {
      if (this.chart) {
        this.chart.destroy();
      }
      if (!opt.chart) {
        opt.chart = {};
      }
      opt.chart.renderTo = this.hostElement.nativeElement;

      if (!opt.chart.height) {
        opt.chart.height =
          this.hostElement.nativeElement.parentElement.offsetHeight;
      }
      if (!opt.chart.width) {
        opt.chart.width =
          this.hostElement.nativeElement.parentElement.offsetWidth;
      }

      if (!config.exportingOptions) {
        config.exportingOptions = {
          getHeaders: (rows) => {
            const result = [];
            for (let i = 0; i < rows[1].length; i++) {
              const element = rows[1][i];
              result[i] = {
                type: 'string',
                value: element,
              };
            }
            return result;
          },
          getRowValues: (row) => {
            const result = [];
            for (let i = 0; i < row.length; i++) {
              const element = row[i];
              result[i] = {
                type: typeof row[i] === 'number' ? 'number' : 'string',
                value: element,
              };
            }
            return result;
          },
        };
      }
      if (!config.exportingOptions.disabled) {
        opt.exporting = {
          buttons: {
            contextButton: {
              enabled: false,
            },
            exportButton: {
              enabled: _this.useVendorExport,
              text: _this.translateService.instant(
                'main.core.charts.export.button_text'
              ),
              menuItems: ['downloadXLSX', 'downloadJPEG'],
              y: -15,
            },
          },
          menuItemDefinitions: {
            downloadXLSX: {
              text: _this.translateService.instant(
                'main.core.charts.export.download_excel'
              ),
              textKey: 'downloadXLSX',
              onclick: () => {
                _this.downloadXLSX();
              },
            },
            downloadJPEG: {
              text: _this.translateService.instant(
                'main.core.charts.export.download_jpeg'
              ),
              textKey: 'downloadJPEG',
              onclick: () => {
                _this.downloadJPEG();
              },
            },
          },
        };
      }
      this.chart = new Highcharts.Chart(opt);
      if (callback) {
        callback(this.chart);
      }
    } else {
      // eslint-disable-next-line
      console.log('No valid options for series or data properties: ');
      console.dir(opt);
    }
    this.currentConfig = config;
  }

  /**
   * configures export functions to be executed from outside this directive.
   * This is used to set the export button outside the chart.
   */
  private setupExportFunctions(config: HighchartsConfiguration) {
    config.jpeg = () => {
      this.downloadJPEG();
    };
    config.xlsx = () => {
      this.downloadXLSX();
    };
  }

  public getExportFileName(isExcel: boolean) {
    let name = this.currentConfig.exportingOptions.getFileName();
    const route = this.router.url.split('/');
    if (
      this.breadCrumbService &&
      this.breadCrumbService.instructions &&
      route[route.length - 1] !== 'summary'
    ) {
      const breadCrumbInfo = this.breadCrumbService.instructions
        .map((ins) => this.translateService.instant(ins.title))
        .join(' - ');
      name +=
        ' ' + breadCrumbInfo.replace('ALL ', '').replace(/[/\\?%*:|"<>]/g, '');
      if (isExcel) {
        name = name.replace(/\./g, ' ');
      }
    }
    const today = new Date();
    const dd = today.getDate();
    const mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    const todayFormatted = `${mm < 10 ? '0' + mm.toString() : mm.toString()}-${dd < 10 ? '0' + dd.toString() : dd.toString()}-${yyyy}`;
    if (isExcel) {
      name += `-${todayFormatted}`;
    } else {
      name += `-${todayFormatted}.jpeg`;
    }
    return name;
  }

  /**
   * Downloads the current Chart as a spreadhseet.
   */
  public downloadXLSX() {
    const div = document.createElement('div');
    let name;
    const xlsxRows = [];
    div.style.display = 'none';
    document.body.appendChild(div);
    // eslint-disable-next-line
    //@ts-ignore
    const rows = this.chart.getDataRows(true);
    if (this.currentConfig.exportingOptions.getFileName) {
      name = this.getExportFileName(true);
    } else {
      name = 'chart';
    }
    this.chartExportService
      .getChartDownload(new SortOptions(), {
        columns: this.currentConfig.exportingOptions.getColumnsConfig(),
        data: this.getChartValues(rows, this.currentConfig),
        image: null,
      })
      .subscribe((blob) => {
        this.downloadsService.saveExcelBlob(blob, name + '.xlsx');
      });
  }

  private getJPEGName() {
    let name = 'image.jpeg';
    if (this.currentConfig.exportingOptions.getFileName) {
      name = this.getExportFileName(false);
      this.currentConfig.exportingOptions.filename = name;
    }
    return name;
  }

  /**
   * Downloads the current Chart as a JPEG image.
   */
  public downloadJPEG() {
    const name = this.getJPEGName();
    let domElem = document.getElementById('full-chart');
    domElem = domElem ? domElem : document.getElementById(this.chartId);
    if (domElem) {
      domElem.setAttribute('style', 'pointer-events:none;');
      const infoIcon = document.getElementById('info-icon');
      if (infoIcon) {
        infoIcon.setAttribute('style', 'border:none;');
      }
      const panels = domElem.getElementsByClassName('panel-body');
      const panelsCss = [];
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < panels.length; i++) {
        const element = panels[i];
        panelsCss.push(element.getAttribute('style'));
        element.setAttribute('style', 'background-color:white;');
      }
      const tooltips = document.getElementsByClassName('highcharts-tooltip');
      const tooltipscss = [];
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let j = 0; j < tooltips.length; j++) {
        const element = tooltips[j];
        tooltipscss.push(element.getAttribute('style'));
        element.setAttribute('style', 'visibility:hidden;');
      }
      setTimeout(() => {
        if (window.navigator.saveBlob) {
          // IE 11
          (<any>this.chart).exportChartLocal(
            this.currentConfig.exportingOptions
          );
          domElem.setAttribute('style', null);
          panelsCss.forEach((element) => {
            if (element != null) {
              element.setAttribute('style', element);
            }
          });
          for (let j = 0; j < tooltips.length; j++) {
            const element = tooltips[j];
            if (element != null) {
              element.setAttribute('style', tooltipscss[j]);
            }
          }
          /*let svgString = (<any>this.chart).getSVG();
                    console.log(svgString);
                    this.downloadSvgAsJpeg(svgString, domElem.offsetHeight, domElem.offsetWidth, name);*/
        } else {
          // Actual Browsers
          html2canvas(domElem, { scale: 1 }).then((canvas) => {
            canvas.toBlob(function (blob) {
              saveAs(blob, name);
              domElem.setAttribute('style', null);
              panelsCss.forEach((element) => {
                if (element != null) {
                  element.setAttribute('style', element);
                }
              });
              for (let j = 0; j < tooltips.length; j++) {
                const element = tooltips[j];
                if (element != null) {
                  element.setAttribute('style', tooltipscss[j]);
                }
              }
            });
          });
        }
      }, 100);
    } else {
      console.error("Can't find chart to create JPEG image.");
    }
  }

  downloadSvgAsJpeg(
    svgString: string,
    height: number,
    width: number,
    name: string
  ) {
    const canvas = document.createElement('canvas');
    canvas.height = height;
    canvas.width = width;
    canvas.id = 'mycanvas';

    const ctx = canvas.getContext('2d');
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const DOMURL = self.URL || self.webkitURL || self;
    const img = new Image();
    const svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
    const url = (<any>DOMURL).createObjectURL(svg);
    img.onload = () => {
      ctx.drawImage(img, 0, 0);
      try {
        const a = document.createElement('a');
        a.href = canvas
          .toDataURL('image/jpeg')
          .replace('image/jpeg', 'image/octet-stream');
        a.download = name;
        a.click();
      } catch (err) {
        (<any>canvas).msToBlob((blob) => {
          window.navigator.saveBlob(blob, name);
        });
      }
    };
    img.src = url;
  }

  appendFiltersToExport = (xlsxRows: Array<any>) => {
    const filterHeaders = [];
    const filterValues = [];
    const currentFilter = this.filterInfoService.getFilterExport();
    for (const key in currentFilter) {
      // eslint-disable-next-line no-prototype-builtins
      if (currentFilter.hasOwnProperty(key)) {
        filterHeaders.push({
          type: 'string',
          value: key,
        });
        filterValues.push({
          type: typeof currentFilter[key] === 'number' ? 'number' : 'string',
          value: currentFilter[key],
        });
      }
    }
    xlsxRows.push(filterHeaders);
    xlsxRows.push(filterValues);
  };

  appendChartHeaders = (xlsxRows: Array<any>, chartHeaders: any) => {
    const chartExportinHeaders = [];
    for (let i = 0; i < chartHeaders.length; i++) {
      const element = chartHeaders[i];
      chartExportinHeaders[i] = {
        type: 'string',
        value: element,
      };
    }
    xlsxRows.push([]);
    xlsxRows.push(chartExportinHeaders);
  };

  getChartValues = (rows: any, config: HighchartsConfiguration) => {
    const result = [];
    for (let i = 2; i < rows.length; i++) {
      const rowValues = rows[i][0];
      const exportingRow = {};
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (
        let j = 0;
        j < config.exportingOptions.getColumnsConfig().length;
        j++
      ) {
        const columnConfig = config.exportingOptions.getColumnsConfig()[j];
        if (columnConfig.customFormat) {
          exportingRow[columnConfig.field] = columnConfig.customFormat(
            rowValues,
            rows,
            i - 2
          );
        } else {
          exportingRow[columnConfig.field] = rowValues[columnConfig.field];
          // if (columnConfig.isPercent) {
          //     exportingRow[columnConfig.field] = this.formatService.formatNumber2decimal((exportingRow[columnConfig.field] * 100));
          // }
        }
        if (exportingRow[columnConfig.field] == null) {
          exportingRow[columnConfig.field] = 0;
        }
      }
      result.push(exportingRow);
    }
    return result;
  };

  getChartColumnsConfig = (rows: any, config: HighchartsConfiguration) => {
    for (let i = 2; i < rows.length; i++) {
      const element = rows[i];
      const rowValues = config.exportingOptions.getRowValues(element[0]);
      const exportingRow = [];
      for (let j = 0; j < rowValues.length; j++) {
        const element = rowValues[j];
        exportingRow[j] = {
          type: typeof element === 'number' ? 'number' : 'string',
          value: element,
        };
      }
      const columns = [];
      columns.push(exportingRow);
    }
  };
}
