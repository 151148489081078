
<div id="search-spinner">
    <div>
        <img src="/assets/images/search-spinner.gif" />
    </div>
</div>

<div id="user-menu" type="popup" [class.active]="isUserPopupOpen" [hidden]="!isUserPopupOpen">
    <a (click)="signOut()">
        <i class="fa fa-lg fa-sign-out"></i>
        {{'main.navigation.sign_out' | translate}}
    </a>
    <a (click)="showCookiePreferences()">
        <i class="fa fa-lg fa-gear"></i>
        {{'main.navigation.cookie_preferences' | translate}}
    </a>
    <a *ngIf="showCompetitiveDisclaimerLink()" (click)="setShowCompetitionDisclaimer()">
        <i class="fa fa-lg fa-warning"></i>
        {{'main.navigation.competition_disclaimer' | translate}}
    </a>
    <a style="display: flex;align-items: center;" (click)="showInApplicationGuidanceSettings()" *ngIf="showGuidedToursOptOut">
        <mat-icon style="height: 16px;width: 16px;font-size: 16px;">language</mat-icon>
        <span style="margin-left: 2px;">{{'main.core.guided-tours.settings.navigation-btn' | translate}}</span>
    </a>
</div>

<div id="alert-menu" class="container-fluid" type="popup" [class.active]="isAlertPopupOpen" [hidden]="!isAlertPopupOpen">
    <div class="row" style="margin-top: 10px;">
        <div class="col-md-12" style="padding-left: 5px; padding-right:5px;">
            <div class="form-group">
                <label>{{'main.navigation.alerts.alert_type' | translate}}</label>
                <rdo-multiselect-dropdown [disabled]="alertTypeMultiSelectDisabled" [texts]="alertTypeSelectionTexts" [settings]="alertTypeSelectSettings" [options]="alertTypes" [(ngModel)]="selectedAlertTypeIds" (ngModelChange)="alertCriteriaChange($event)"></rdo-multiselect-dropdown>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12" style="padding-left: 5px; padding-right:5px;">
            <div class="form-group">
                <label>{{'main.navigation.alerts.geography.title' | translate}}</label>
                <rdo-multiselect-dropdown [disabled]="geographyMultiSelectDisabled"
                [(texts)]="geographyMultiSelectTexts" [settings]="geographyMultiSelectSettings"
                [options]="alertGeographies" [(ngModel)]="selectedAlertGeographyIds"
                (ngModelChange)="alertCriteriaChange($event)"></rdo-multiselect-dropdown>
            </div>
        </div>
    </div>

    <div class="row" id="alert-checkboxes" style="padding: 0px 5px;">
        <div class="rdo-checkbox" style="float: left; width: 50%; padding-left: 5px;">
            <div class="checkbox">
                <label>
                    <input style="display: none;" type="checkbox" [(ngModel)]="hideReadAlerts" (ngModelChange)="alertCriteriaChange($event, true)" />
                    <span style="line-height: inherit; max-width: 87%;">{{'main.navigation.alerts.hide_read' | translate}}</span>
                </label>
            </div>
        </div>

        <div class="rdo-checkbox" style="float: right; width: 50%; padding-left: 5px;">
            <div class="checkbox">
                <label>
                    <input style="display: none;" type="checkbox" [(ngModel)]="hideClearedAlerts" (ngModelChange)="alertCriteriaChange($event, true)" />
                    <span style="line-height: inherit; max-width: 87%;">{{'main.navigation.alerts.hide_resolved_from_last_period' | translate}}</span>
                </label>
            </div>
        </div>
    </div>
<!-- alerts dropdown -->
    <div class="row" style="flex-grow: 1;" *ngIf="this.isAlertPopupOpen" >
        <div class="col-md-12" style="padding-left: 5px; padding-right:5px; height: 100%;">
            <div class="notifications"
                 infinite-scroll
                 [infiniteScrollDistance]="2"
                 [infiniteScrollThrottle]="500"
                 (scrolled)="getPaginatedAlerts()"
                 [infiniteScrollContainer]="scrollableNotificationList"
                 [scrollWindow]="false">
                <ul class="notification-list" [class.hide]="isAlertNotificationListHidden">
                    <li class="bs-callout" style="border-left-width: 1px;"
                        [class.active]="selectedAlert && selectedAlert.AlertID === alert.AlertID"
                        [class.hide]="hideReadAlerts && selectedAlert && selectedAlert.AlertID !== alert.AlertID && alert.AlertStatus === 2"
                        *ngFor="let alert of alerts | rdoAlerts: hideReadAlerts : hideClearedAlerts; let i = index"
                        (click)="handleAlertClick(i, alert)">

                        <div class="media">
                            <div class="media-left">
                                <rdo-category-icon [category]="alert.CategoryIcon || alert.RouseCategory"></rdo-category-icon>
                            </div>
                            <div class="media-body">
                                <p class="heading truncate">{{!!alert.UseRouseSchema ? alert.RouseProductType : alert.Description}}</p>
                                <p class="sub-text truncate">
                                    {{'main.navigation.alerts.geography.title' | translate}}:&nbsp;{{alert.Geography}}</p>

                                <div class="rdo-card-metric">
                                    {{alert.RevenueBenchmarked | rdoCurrency:true}} / <span [ngClass]="{'text-danger' : alert.RevenueDifference < 0}">
                                        {{alert.RevenueDifference | rdoCurrency:true}}
                                    </span>
                                    <div class="pull-right">
                                        <span [rdo-dynamic-pie]="alert.PhysicalUtilizationTotal * 100" class="pie"></span>
                                        <span class="text-default">&nbsp;{{alert.PhysicalUtilizationTotal | percent:'1.1-1'}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="media-right">
                                <div *ngIf="alert.IsCleared">
                                    <i [attr.id]="'alert-icon' + i" *ngIf="alert.AlertStatus !== 2" class="fa fa-fw fa-lg fa-thumbs-o-up"
                                    data-toggle="tooltip" data-placement="left" title="{{'main.navigation.alerts.resolved_from_last_period' | translate}}"></i>
                                    <span [attr.id]="'alert-icon' + i" *ngIf="alert.AlertStatus === 2" class="fa-stack" data-toggle="tooltip"
                                    data-placement="left" title="{{'main.navigation.alerts.resolved_from_last_period' | translate}}">
                                        <i class="fa fa-circle fa-stack-2x text-success"></i>
                                        <i class="fa fa-thumbs-o-up fa-stack-1x text-white"></i>
                                    </span>
                                </div>
                                <div *ngIf="!alert.IsCleared">
                                    <i [attr.id]="'alert-icon' + i" *ngIf="alert.NumPeriodNotCleared >= 6 && alert.AlertStatus !== 2"
                                        class="fa fa-fw fa-lg fa-fire text-danger"
                                        data-toggle="tooltip" data-placement="left" [attr.title]="'main.navigation.alerts.unresolved_for_6_or_more_periods' | translate">
                                    </i>

                                    <i [attr.id]="'alert-icon' + i" *ngIf="alert.NumPeriodNotCleared > 1 && alert.NumPeriodNotCleared < 6 && alert.AlertStatus !== 2"
                                        class="fa fa-fw fa-lg fa-fire text-danger"
                                        data-toggle="tooltip" data-placement="left" [attr.title]="getUnresolvedForText(alert.NumPeriodNotCleared)">
                                    </i>

                                    <span [attr.id]="'alert-icon' + i" *ngIf="alert.NumPeriodNotCleared >= 6 && alert.AlertStatus === 2" class="fa-stack"
                                          data-toggle="tooltip" data-placement="left" [attr.title]="'main.navigation.alerts.unresolved_for_6_or_more_periods' | translate">
                                        <i class="fa fa-circle fa-stack-2x text-success"></i>
                                        <i class="fa fa-fire fa-stack-1x text-white"></i>
                                    </span>

                                    <span [attr.id]="'alert-icon' + i" *ngIf="alert.NumPeriodNotCleared > 1 && alert.NumPeriodNotCleared < 6 && alert.AlertStatus === 2" class="fa-stack"
                                          data-toggle="tooltip" data-placement="left" [attr.title]="getUnresolvedForText(alert.NumPeriodNotCleared)">
                                        <i class="fa fa-circle fa-stack-2x text-success"></i>
                                        <i class="fa fa-fire fa-stack-1x text-white"></i>
                                    </span>

                                    <i [attr.id]="'alert-icon' + i" *ngIf="alert.NumPeriodNotCleared <= 1 && alert.AlertStatus !== 2" class="fa fa-fw fa-lg fa-warning text-warning"
                                       data-toggle="tooltip" data-placement="left" title="{{'main.navigation.alerts.new_alert_this_period' | translate}}"></i>

                                    <span [attr.id]="'alert-icon' + i" *ngIf="alert.NumPeriodNotCleared <= 1 && alert.AlertStatus === 2" class="fa-stack"
                                          data-toggle="tooltip" data-placement="left" title="{{'main.navigation.alerts.new_alert_this_period' | translate}}">
                                        <i class="fa fa-circle fa-stack-2x text-success"></i>
                                        <i class="fa fa-warning fa-stack-1x text-white"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div id="change-log-menu" class="container-fluid" type="popup" [class.active]="isChangeLogPopupOpen" [hidden]="!isChangeLogPopupOpen">
    <changes-log-entry (closeClicked)="isChangeLogPopupOpen = false"></changes-log-entry>
</div>
<app-competition-disclaimer></app-competition-disclaimer>
<rdo-in-application-guidance-settings></rdo-in-application-guidance-settings>
<header class="box" id="banner">
    <div class="trial-mode" *ngIf="clientAttributes?.IsTrial">
        <div>{{'main.navigation.demo' | translate}}</div>
    </div>

    <div class="logo flex nav-bar">
        <span>Rouse Services</span>
        <div class="divider"></div>
        <span *ngIf="!hasRatesAndAvailability">{{'main.navigation.buttons.rental_dashboard' | translate}}</span>
        <select #ratesAndAvailabilitySelect name="ratesAndAvailabilitySelect" id="ratesAndAvailabilitySelect" *ngIf="hasRatesAndAvailability"
            style="background: rgb(82, 82, 82);" (change)="openRatesAndAvailability($event.target.value)">
            <option value="rdo" selected >{{'main.navigation.buttons.rental_dashboard' | translate}}</option>
            <option value="ratesAndAvailability">{{'main.navigation.buttons.rates_and_availability' | translate}}</option>
        </select>
        <div class="divider"></div>
        <span id="client-short-name">{{authenticationService._userInfoView.SelectedClient ? authenticationService._userInfoView.SelectedClient.ClientShortName : ''}}</span>
        <span id="client-id" hidden>{{authenticationService._userInfoView.SelectedClient ? authenticationService.   _userInfoView.SelectedClient.ClientID : ''}}</span>
        <span id="software-version" hidden>{{softwareVersion}}</span>
        <div class="divider"></div>
    </div>

    <div class="searchbox-container">
        <input type="text" id="searchbox" [disabled]="clientAttributes?.IsTrial" class="form-control typeahead"
        placeholder="&#xf002;  {{'main.navigation.search_bar.placeholder' | translate}}" [(ngModel)]="searchCriteria"
        style="font-family: Roboto, sans-serif, FontAwesome;" />
    </div>



    <div class="nav-menuItem" id="market-maps-menu-item" (click)="windowPopup()" data-toggle="tooltip"
    data-placement="bottom" title="{{'main.navigation.buttons.market_maps' | translate}}">
        <a><i class="fa fa-fw fa-map"></i></a>
    </div>

    <div class="nav-menuItem" (click)="windowGuidePopup()" id="user-guide-menu-item" data-toggle="tooltip"
    data-placement="bottom" title="{{'main.navigation.buttons.user_guide' | translate}}">
        <a><i class="fa fa-fw fa-info-circle"></i></a>
    </div>

    <div class="changes-log nav-menuItem" id="changes-log-menu-item" (click)="toggleChangeLogPopup()"
    data-toggle="tooltip" data-placement="bottom" title="{{'main.navigation.changes_log_entry.title' | translate}}"
    *ngIf="!clientAttributes?.IsTrial && hasChangeLog" [class.active]="isChangeLogPopupOpen">
        <a><i class="fa fa-fw fa-pencil-square-o"></i></a>
    </div>

    <div class="nav-menuItem" id="changes-log-menu-item" data-toggle="tooltip" data-placement="bottom"
    title="{{'main.navigation.changes_log_entry.title' | translate}}" *ngIf="clientAttributes?.IsTrial && !hasChangeLog">
        <a><i class="fa fa-fw fa-pencil-square-o"></i></a>
    </div>

    <div class="nav-menuItem" id="print-menu-item" onClick="window.print();" data-toggle="tooltip"
    data-placement="bottom" title="{{'main.navigation.buttons.print' | translate}}">
        <a><i class="fa fa-fw fa-print"></i></a>
    </div>

    <div class="alerts nav-menuItem" id="alerts-menu-item" *ngIf="allAlerts.length"
    (click)="toggleAlertPopup()" data-toggle="tooltip" data-placement="bottom" title="{{'main.navigation.alerts.title' | translate}}"
    [class.active]="isAlertPopupOpen">
        <a><span *ngIf="allAlerts.length !== alertReadCount" class="fa-stack fa-lg"
            [attr.data-count]="alertCount">
                <i class="fa fa-bell fa-stack-1x fa-inverse"></i>
            </span>
            <i *ngIf="allAlerts.length === alertReadCount" class="fa fa-fw fa-bell"></i>
        </a>
    </div>

    <div class="clientavatar nav-menuItem" id="client-menu-item" (click)="toggleMulticlientPopup(false)"
    [hidden]="!authenticationService._userInfoView.HasClientSelection" style="display:none;">
        <a style="line-height: 28px;">
            <span>
                {{authenticationService._userInfoView.SelectedClient ? authenticationService._userInfoView.SelectedClient.ClientShortName : ''}}
            </span>
            <i class="fa fa-fw fa-globe"></i>
        </a>
    </div>

    <div class="avatar nav-menuItem" id="user-menu-item" (click)="toggleUserPopup()" [class.active]="isUserPopupOpen">
        <a style="line-height: 28px;">
            <span>{{authenticationService._userInfoView.ImpersonatedOrCurrentUserFullName}}</span>
            <i class="fa fa-fw fa-user-circle-o"></i>
        </a>
    </div>

    <button id="reinitializeHeaderButton" (click)="reinitializeHeader()" style="display:none;">
        {{'main.navigation.re_initialize' | translate}}</button>
</header>
