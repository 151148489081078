import { TranslateService } from '@ngx-translate/core';

export class HistogramLabel {
    public highChartsLabel: any;
    public translationPath: string;

    constructor(
        translationPath: string,
        protected translateService: TranslateService
    ) {
        this.translationPath = translationPath;
    }

    public updateLanguage(): void {
        if (!this.highChartsLabel) {
            throw Error('Invalid high charts label reference.');
        }
        this.highChartsLabel.attr({
            text: this.getText()
        });
    }

    public getText() {
        return this.translateService.instant('main.tabs.equipment.product_types.rate_types.histogram.' + this.translationPath);
    }
}
