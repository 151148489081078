import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';
import { MetricsHttpService } from '../core/http/metrics-http.service';
import { CustomGridsValuesBody } from '../models/body';
import { LocaleService, RdoHttpService } from '../core';
import { IFieldOptions, PivotViewComponent } from '../pivotcharts/ej2-angular-pivotview';
import { CustomGridTargetFieldValues, LocaleFormat } from '../models';
import { FilterDataService } from '../filter';
import { CODENAMES, CG_FIELDS } from '../core/constants';
import { FilterModel, FieldOptionsModel } from '../pivotcharts/ej2-pivotview/src/pivotview/model/datasourcesettings-model';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import 'rxjs/add/operator/first';
import { environment } from '../../environments/environment';
import { FilterProfileService } from '../filter/profiles/filter-profile.service';

@Injectable({
    providedIn: 'root',
})
export class CustomGridsService {
    public gridConfigs: any;

    public monthFilterFields: CustomGridTargetFieldValues[] = [];
    public geoLevelsFilterFields: CustomGridTargetFieldValues[];
    public gridConfigsLoaded = new Subject<any>();
    private isLoadingGridConfigs = false;
    public defaultMonthColumnSetting = 6;
    public defaultFilterSettings: { name: string, type: string, items?: string[] }[] = [];
    public filterTypeFilter = 'filter';
    public filterTypeColumn = 'column';
    public defaultGeoSetting = 'Market to Country';
    public currentGridConfiguration: any;
    public pivotGridObj: any;

    constructor(
        private metricsHttp: MetricsHttpService,
        private rdoHttp: RdoHttpService,
        private filterDataService: FilterDataService,
        private translateService: TranslateService,
        private authenticationService: AuthenticationService,
        private localeService: LocaleService,
        private filterProfileService: FilterProfileService
    ) {
        this.monthFilterFields = [];
    }

    public setFilterOverrides(filterSettings: FilterModel[], filters: IFieldOptions[], columns: IFieldOptions[], currentGridFilterSettings: FilterModel[],) {
        this.setFilterOverridesForType(filters, filterSettings, this.filterTypeFilter);
        this.setFilterOverridesForType(columns, filterSettings, this.filterTypeColumn);
        currentGridFilterSettings = filterSettings;

    }

    public setFilterOverridesForType(collection: IFieldOptions[], filterSettings: FilterModel[], type: string) {
        collection.forEach(filter => {
            const defaultFilterSetting = this.defaultFilterSettings.find(r => r.name === filter.name && r.type === type);
            const filterSetting = filterSettings.find(r => r.name === filter.name);
            if (defaultFilterSetting && filterSetting) {
                const array1 = defaultFilterSetting.items ? defaultFilterSetting.items : [];
                const array2 = filterSetting.items ? filterSetting.items : [];
                const array2Sorted = array2.slice().sort();
                const array1Sorted = array1.slice().sort();
                let areValuesDifferent = array1.length !== array2.length;
                if (!areValuesDifferent) {
                    array1Sorted.forEach((value, index) => {
                        if (value !== array2Sorted[index]) {
                            areValuesDifferent = true;
                        }
                    });
                }
                filterSetting.overrideDefault = areValuesDifferent;
            } else if (filterSetting) {
                filterSetting.overrideDefault = true;
            }
        });
    }


    public fleetDataValues = (filters: any): Observable<any> => {
        const body = new CustomGridsValuesBody();
        body.filters = filters;
        body.page = { index: 0, size: 30000 };
        return this
            .metricsHttp
            .post('custom-grids/values/custom_grids_fleet_data', body);
    }

    public getDistinctFieldValuesForTarget(field: string, target: string) {
        return this.rdoHttp.get(`/pivot/${target}/values/${field}`);
    }

    public loadGridConfigurations = (currencyCode) => {
        if (!this.isLoadingGridConfigs) {
            this.isLoadingGridConfigs = true;
            this.gridConfigs = null;
            this.metricsHttp.get('custom-grids/grids').subscribe(resp => {
                this.createGridConfigs(resp, currencyCode);
            },
                () => { this.isLoadingGridConfigs = false; },
            );
        }
    }

    public createGridConfigs = (configs: any, currencyCode: string) => {
        for (const key in configs.global) {
            if (Object.prototype.hasOwnProperty.call(configs.global, key)) {
                this.gridConfigs = { ...this.gridConfigs, ...configs.global[key] };
            }
        }
        const unSortedClientGridConfigs = {
            ...this.gridConfigs,
            ...configs.client,
            ...configs.user
        };

       
        this.gridConfigs = { ...this.sortGridConfigs(this.gridConfigs), ...this.sortGridConfigs(unSortedClientGridConfigs) }

        let currencyLocale = "en-US";
        switch (currencyCode) {
            case "EUR":
                currencyLocale = "de-DE";
                break;
            case "JPY":
                currencyLocale = "ja-JP";
                break;
            case "GBP":
                currencyLocale = "en-GB";
                break;
        }

        Object.keys(this.gridConfigs).forEach(key => {
            const config = this.gridConfigs[key];
            config.settings.url = `${environment.apiUrl}/pivot/${config.target}/${currencyLocale}`;
            config.settings.mode = 'Server';
            if (config.settings.valueSortSettings && config.settings.valueSortSettings.headerDelimiter) {
                config.settings.valueSortSettings.headerDelimiter = '~';
            }
        });

        this.isLoadingGridConfigs = false;
        this.gridConfigsLoaded.next(this.gridConfigs);
    }

    public sortGridConfigs(gridConfigs: any) {
        let gridArray = [];
        Object.keys(gridConfigs).forEach(key => {
            const obj: { id: string, name: string } = { id: key, name: gridConfigs[key].name.toLowerCase() };
            gridArray.push(obj);
        });

        gridArray = gridArray.sort((a, b) => (a.name < b.name ? -1 : 1));
        const sortedGridConfigs = {};
        gridArray.forEach(r => {
            sortedGridConfigs[r.id] = gridConfigs[r.id];
        });
        return sortedGridConfigs;
    }

    public gridNameIsUnique(reportId: string, reportName: string) {
        let nameNotMatched = true;
        Object.keys(this.gridConfigs).forEach(key => {
            const obj: { id: string, name: string } = { id: key, name: this.gridConfigs[key].name.toLowerCase() };
            if (obj.id !== reportId && reportName.toLowerCase().trim() === obj.name.toLowerCase().trim()) {
                nameNotMatched = false;
                return;
            }
        });
        return nameNotMatched;
    }

    public isGridNameValid(reportName: string) {
        if (reportName.length > 50) {
            return false;
        }
        return reportName.match(/^[A-Za-z0-9_.\s()-]+$/)
    }

    public updateConfiguration = (configId: string, newConfig: any, email: string): Observable<any> => {
        newConfig.lastModifiedBy = email;
        newConfig.lastModifiedDate = new Date().toDateString();
        return this.metricsHttp.put(`custom-grids/grids/${configId}`, newConfig);
    }

    public deleteConfig = (configId: string, scope: string, target: string): Observable<any> => {
        let url = `custom-grids/grids/${configId}?scope=${scope}&target=${target}`;
        if (this.authenticationService._userInfoView?.SelectableClients?.length > 1) {
            url += `&selected_multiclient=${this.authenticationService._userInfoView.SelectedClient.ClientID}`;
        }
        return this.metricsHttp.delete(url);
    }

    public createConfiguration = (newConfig: any, email: string): Observable<any> => {
        const today = new Date().toDateString();
        newConfig.createdBy = email;
        newConfig.createdDate = today;
        newConfig.lastModifiedBy = email;
        newConfig.lastModifiedDate = today;
        return this.metricsHttp.post(`custom-grids/grids`, newConfig);
    }

    public export = (exportSettings: any): Observable<any> => {
        const body = {
            name: exportSettings.name,
            timeZoneOffset: new Date().getTimezoneOffset(),
            gmtTimeZone: new Date().toString().split(' ')[5],
            ...exportSettings.settings
        };

        return this.metricsHttp.post(`exports/grid/${exportSettings.target}`, body);
    }

    public updateFieldListFilterNames(pivotView: PivotViewComponent) {
        const filterContainer = document.getElementsByClassName('e-field-list-filters');
        if (filterContainer && filterContainer.length === 1 && pivotView && pivotView.dataSourceSettings && pivotView.dataSourceSettings.filterSettings) {
            const filterFields = filterContainer[0].getElementsByClassName('e-content');
            Array.from(filterFields).forEach((filterField) => {
                // loc exists in filters but not filtersettings
                const defaultSuffix = this.translateService.instant('main.tabs.custom_grids.page_labels.all');
                let labelVerified = false;
                pivotView.dataSourceSettings.filterSettings.forEach(r => {
                    let suffix = defaultSuffix;
                    const filter = pivotView.dataSourceSettings.filters.find(f => f.name === r.name);
                    if (filter) {
                        if (r.items && r.items.length === 1) {
                            suffix = ` (${r.items[0]})`;
                        } else if (r.items && r.items.length > 1) {
                            suffix = this.translateService.instant('main.tabs.custom_grids.page_labels.multiple');
                        }
                        const label = filter.caption + suffix;
                        if (filterField.innerHTML.startsWith(filter.caption)) {
                            labelVerified = true;
                            if (filterField.innerHTML !== label) {
                                filterField.innerHTML = label;
                            }
                        }
                    }
                });
                if (!labelVerified) {
                    let label = filterField.innerHTML;
                    const beginTrim = label.lastIndexOf(' (');
                    if (beginTrim > 0) {
                        label = label.substring(0, beginTrim);
                    }
                    const beginTrimJP = label.lastIndexOf('（');
                    if (beginTrimJP > 0) {
                        label = label.substring(0, beginTrimJP);
                    }
                    label += defaultSuffix;
                    if (filterField.innerHTML !== label) {
                        filterField.innerHTML = label;
                    }
                }
            });
        }
    }

    public setEditorFields(userIsCustomGridEditor: boolean) {
        if (!userIsCustomGridEditor) {
            const dialogContainers = document.getElementsByClassName('e-pivotfieldlist-wrapper');
            if (dialogContainers && dialogContainers.length > 0) {
                const dialogContainer = dialogContainers[0];
                this.removeEditorOnlyFields(dialogContainer);
            }
        }
    }

    public setGridListWidth() {
        const result = document.getElementsByClassName('e-ddl');
        const style = 'width: 280px';
        if (result && result.length > 0 && result[0].getAttribute('style') !== style) {
            result[0].setAttribute('style', style);
        }

    }

    public sortFieldListItemsIfShown() {
        const filterListContainer = document.getElementById('PivotView_PivotFieldList_Wrapper_dialog-content');
        if (filterListContainer) {
            const sortSpan = filterListContainer.getElementsByClassName('e-sort-ascend');
            if (sortSpan && sortSpan[0] && !sortSpan[0].classList.contains('e-selected')) {
                const elememnt = sortSpan[0] as HTMLElement;
                elememnt.click();
            }
        }
    }

    public applyDefaultColumnSettings(columns: FieldOptionsModel[], filterSettings: FilterModel[], fieldName: string) {
        const monthFilter = columns.find(r => r.name === CG_FIELDS.MONTH);
        if (monthFilter) {
            let monthFilterSetting = filterSettings.find(r => r.name === fieldName);
            if (!monthFilterSetting) {
                monthFilterSetting = this.createFilterSettingsfromFilter(fieldName);
                this.setLatestMonthFilterSetting(monthFilterSetting);
                filterSettings.push(monthFilterSetting);
            }
        }
    }

    public applyDefaultMonthFilterSettings(filters: FieldOptionsModel[], filterSettings: FilterModel[], fieldName: string) {
        const monthFilter = filters.find(r => r.name === fieldName);
        if (monthFilter) {
            let monthFilterSetting = filterSettings.find(r => r.name === fieldName);
            if (!monthFilterSetting) {
                monthFilterSetting = this.createFilterSettingsfromFilter(fieldName);
                this.setLatestMonthFilterSetting(monthFilterSetting);
                filterSettings.push(monthFilterSetting);
            }
        }
    }

    public setDefaultFilterSetting(filterModel: FilterModel, type: string) {

        const currentFilter = this.defaultFilterSettings.find(r => r.name === filterModel.name && r.type === type);
        if (currentFilter) {
            currentFilter.items = filterModel.items;
        } else {
            this.defaultFilterSettings.push({ name: filterModel.name, type, items: filterModel.items });
        }
    }

    public async applyDefaultFilterSettings(filters: FieldOptionsModel[], filterSettings: FilterModel[], target: string) {
        await this.setDefaultMonthFilter(filters, filterSettings, target);

        const benchmarkFilter = filters.find(r => r.name === CG_FIELDS.RATE_BENCHMARK);
        if (benchmarkFilter) {
            let benchmarkFilterSetting = filterSettings.find(r => r.name === CG_FIELDS.RATE_BENCHMARK);
            if (!benchmarkFilterSetting) {
                benchmarkFilterSetting = this.createFilterSettingsfromFilter(CG_FIELDS.RATE_BENCHMARK);
                filterSettings.push(benchmarkFilterSetting);
            }
            const defaultBenchMark = await this.filterProfileService.readPropertyAsync(CODENAMES.CN_GEOGRAPHY_RATE_BENCHMARK);
            await this.setDefaultBenchMarkFilterSetting(benchmarkFilterSetting, defaultBenchMark);
        }

        const geoLevelFilter = filters.find(r => r.name === CG_FIELDS.BENCHMARK_GEO_LEVEL);
        if (geoLevelFilter) {
            let geoLevelFilterSetting = filterSettings.find(r => r.name === CG_FIELDS.BENCHMARK_GEO_LEVEL);
            if (!geoLevelFilterSetting) {
                geoLevelFilterSetting = this.createFilterSettingsfromFilter(CG_FIELDS.BENCHMARK_GEO_LEVEL);
                filterSettings.push(geoLevelFilterSetting);
            }
            await this.setDefaultGeLevelFilterSetting(geoLevelFilterSetting, target);
        }
    }

    public async setDefaultMonthFilter(filters: FieldOptionsModel[], filterSettings: FilterModel[], target: string) {
        const monthFilter = filters.find(r => r.name === CG_FIELDS.MONTH);
        if (monthFilter) {
            let monthFilterSetting = filterSettings.find(r => r.name === CG_FIELDS.MONTH);
            if (!monthFilterSetting) {
                monthFilterSetting = this.createFilterSettingsfromFilter(CG_FIELDS.MONTH);
                filterSettings.push(monthFilterSetting);
            }
            await this.setDefaultMonthFilterSetting(monthFilterSetting, target);
        }
    }

    public createFilterSettingsfromFilter(name: string) {
        const filterSetting: FilterModel = new Object();
        filterSetting.condition = 'DoesNotEquals';
        filterSetting.items = [];
        filterSetting.levelCount = 1;
        filterSetting.name = name;
        filterSetting.type = 'Include';
        return filterSetting;

    }

    public async setDefaultMonthFilterSetting(monthFilterSetting: FilterModel, target: string) {
        if (monthFilterSetting && !monthFilterSetting.overrideDefault) {
            let monthFieldValues = this.monthFilterFields.find(r => r.Field === CG_FIELDS.MONTH && r.Target === target);
            if (!monthFieldValues) {
                await this.getDistinctFieldValuesForTarget(CG_FIELDS.MONTH, target).toPromise().then((values: Array<string>) => {
                    monthFieldValues = new CustomGridTargetFieldValues();
                    monthFieldValues.Field = CG_FIELDS.MONTH;
                    monthFieldValues.Values = values;
                    monthFieldValues.Target = target;
                    this.monthFilterFields.push(monthFieldValues);
                });
            }
            //let latestFullMonthLabel = 'Latest Full Month';this.translateService.instant('main.tabs.custom_grids.page_labels.latest_full_month')
            const latestFullMonthLabel = this.translateService.instant('main.tabs.custom_grids.page_labels.latest_full_month')
            monthFilterSetting.items = [latestFullMonthLabel];
            //this.getDefaultMonthFilterItems(startIndex, endIndex, monthFieldValues.Values);
        }
    }

    public async loadDefaultFilterSettingsForTarget(target: string, filterSettings: any) {
        if (this.filtersettingsHasField(filterSettings, CG_FIELDS.MONTH)) {
            const monthFilter = { name: CG_FIELDS.MONTH };
            await this.setDefaultMonthFilterSetting(monthFilter, target);
            this.setDefaultFilterSetting(monthFilter, this.filterTypeFilter);
            this.setLatestMonthFilterSetting(monthFilter);
            this.setDefaultFilterSetting(monthFilter, this.filterTypeColumn);
        }
        if (this.filtersettingsHasField(filterSettings, CG_FIELDS.RATE_BENCHMARK)) {
            const benchmarkFilter = { name: CG_FIELDS.RATE_BENCHMARK };
            const defaultBenchMark = await this.filterProfileService.readPropertyAsync(CODENAMES.CN_GEOGRAPHY_RATE_BENCHMARK);
            await this.setDefaultBenchMarkFilterSetting(benchmarkFilter, defaultBenchMark);
            this.setDefaultFilterSetting(benchmarkFilter, this.filterTypeFilter);
        }
        if (this.filtersettingsHasField(filterSettings, CG_FIELDS.BENCHMARK_GEO_LEVEL)) {
            const geoLevelFilter = { name: CG_FIELDS.BENCHMARK_GEO_LEVEL };
            await this.setDefaultGeLevelFilterSetting(geoLevelFilter, target);
            this.setDefaultFilterSetting(geoLevelFilter, this.filterTypeFilter);
        }
    }

    public filtersettingsHasField(filterSettings: any, fieldName: string) {
        const monthsIndex = filterSettings.findIndex(r => r.name.toLowerCase() === fieldName.toLocaleLowerCase());
        return monthsIndex !== -1;
    }

    public async loadMonthFieldValues(target) {
        let monthFieldValues = this.monthFilterFields.find(r => r.Field === CG_FIELDS.MONTH && r.Target === target);
        if (!monthFieldValues) {
            await this.getDistinctFieldValuesForTarget(CG_FIELDS.MONTH, target).toPromise().then((values: Array<string>) => {
                monthFieldValues = new CustomGridTargetFieldValues();
                monthFieldValues.Field = CG_FIELDS.MONTH;
                monthFieldValues.Values = values;
                monthFieldValues.Target = target;
                this.monthFilterFields.push(monthFieldValues);
            });
        }
    }

    public setLatestMonthFilterSetting(monthFilterSetting: FilterModel) {
        if (monthFilterSetting) {
            //let latestFullMonthLabel ='Latest Full Month';
            const latestFullMonthLabel = this.translateService.instant('main.tabs.custom_grids.page_labels.latest_full_month')
            monthFilterSetting.items = [latestFullMonthLabel];
        }
    }


    public getDefaultMonthFilterItems(startIndex: number, endIndex: number, months: Array<string>) {
        const items = [];
        if (months.length > 0) {
            for (let i = startIndex; i < (endIndex + 1); i++) {
                if (i < months.length) {
                    items.push(months[i]);
                }
            }
        }

        return items;
    }

    public async setDefaultBenchMarkFilterSetting(benchmarkFilterSetting: FilterModel, defaultBenchMark: any) {
        if (benchmarkFilterSetting && !benchmarkFilterSetting.overrideDefault && defaultBenchMark) {
            let benchmarkFieldValues: CustomGridTargetFieldValues;
            await this.filterDataService.getBenchmarks(this.localeService.getLocale()).toPromise().then((values) => {
                benchmarkFieldValues = new CustomGridTargetFieldValues();
                benchmarkFieldValues.Field = CG_FIELDS.RATE_BENCHMARK;
                benchmarkFieldValues.Values = [];
                values.forEach(value => benchmarkFieldValues.Values.push(`${value.Bid}:${value.DisplayName}`));
            });
            const filterItem = benchmarkFieldValues.Values.find(r => r.startsWith(`${defaultBenchMark}:`));
            if (filterItem) {
                benchmarkFilterSetting.items = [filterItem.split(':')[1]];
            }
        }
    }

    // public filtersRemainForGlobalReport(scope: string, originalReportFilters: FilterModel[], updatedFilterList: FieldOptionsModel[]) {
    //     let returnVal = true;
    //     if (scope === 'global') {
    //         originalReportFilters.forEach(item => {
    //             let matchingFilter = updatedFilterList.find(r => r.name === item.name);
    //             if (!matchingFilter) {
    //                 returnVal = false;
    //             }
    //         });
    //     }
    //     return returnVal;
    // }

    public async setDefaultGeLevelFilterSetting(geoLevelFilterSetting: FilterModel, target: string) {
        const defaultGeoLevel = await this.filterProfileService.readPropertyAsync(CODENAMES.CN_GEOGRAPHY_BENCHMARK_GEOGRAPHY);
        if (geoLevelFilterSetting && !geoLevelFilterSetting.overrideDefault && defaultGeoLevel) {
            let geoFieldValues: CustomGridTargetFieldValues;
            await this.filterDataService.getCustomGridsGeographyLevels(target).toPromise().then((values) => {
                geoFieldValues = new CustomGridTargetFieldValues();
                geoFieldValues.Field = CG_FIELDS.BENCHMARK_GEO_LEVEL;
                geoFieldValues.Values = [];
                values.forEach(value => geoFieldValues.Values.push(`${value.Gid}:${value.DisplayName}`));
            });
            let filterItem = geoFieldValues.Values.find(r => r.startsWith(`${defaultGeoLevel}:`));
            if (filterItem) {
                geoLevelFilterSetting.items = [filterItem.split(':')[1]];
            } else {
                filterItem = geoFieldValues.Values.find(r => r.startsWith(this.defaultGeoSetting));
                if (filterItem) {
                    geoLevelFilterSetting.items = [this.defaultGeoSetting];
                } else {
                    // Set translated version of Market to country using it's ID, which is always 14
                    const geoFieldValueToUse = geoFieldValues.Values.length ? geoFieldValues.Values.find(v => v.split(':')[0] === '14') : null;
                    const actualGeoFieldValueToUse = geoFieldValueToUse && geoFieldValueToUse.split(':').length > 1 ? geoFieldValueToUse.split(':')[1] : null;
                    if (actualGeoFieldValueToUse) {
                        geoLevelFilterSetting.items = [actualGeoFieldValueToUse];
                    }
                }
            }
        }
    }


    public removeEditorOnlyFields(dialogContainer: Element) {
        this.removeItem(dialogContainer.getElementsByClassName('e-field-table'));
        this.removeItem(dialogContainer.getElementsByClassName('e-field-list-rows'));
        this.removeItem(dialogContainer.getElementsByClassName('e-right-axis-fields'));
        this.removeItem(dialogContainer.getElementsByClassName('e-calculated-field'));
        this.hideDraggableItems(dialogContainer.getElementsByClassName('e-filters'));
        dialogContainer.classList.add('small-pivot-field-list');
    }

    public disableContainer(container: HTMLCollectionOf<Element>) {
        if (container && container.length > 0 && !container[0].classList.contains('e-disabled')) {
            container[0].classList.add('e-disabled');
        }
    }

    public hideDraggableItems(container: HTMLCollectionOf<Element>) {
        if (container && container.length > 0) {
            this.removeItem(container[0].getElementsByClassName('e-drag'));
            this.removeItem(container[0].getElementsByClassName('e-draggable'));
            this.removeItem(container[0].getElementsByClassName('e-checkbox-wrapper'));
            this.removeItem(container[0].getElementsByClassName('e-remove'));
        }
    }

    public hideGridIfNoReportSelected(currentGridConfig: any) {
        // prevents display of grand totals when grid is empty
        const grid = document.getElementById('PivotView_grid');
        if (!currentGridConfig) {
            if (grid && !grid.classList.contains('e-hidden')) {
                grid.classList.add('e-hidden');
            }
        } else {
            if (grid && grid.classList.contains('e-hidden')) {
                grid.classList.remove('e-hidden');
            }
        }
    }

    removeItem(elements: HTMLCollectionOf<Element>) {
        if (elements && elements.length > 0) {
            Array.from(elements).forEach((el) => {
                el.remove();
            });
        }
    }

    public refreshToken() {
        return this.rdoHttp.get(`/pivot/token`);
    }

    public validateFormula(formula: string, clientid: string, target: string) {
        const body = { ClientId: clientid, Formula: formula, Target: target };
        return this.rdoHttp.pivotPost('/pivot/formula/validate', body);
    }

    public isConfigModified(originalConfig, endConfig) {
        const allEquals = this.equals(originalConfig.rows, endConfig.rows) &&
            this.equals(originalConfig.columns, endConfig.columns) &&
            (this.equals(originalConfig.values, endConfig.values) || this.equals(this.translateCaptions(originalConfig.values), endConfig.values)) &&
            this.equals(originalConfig.filters, endConfig.filters) &&
            this.equals(originalConfig.calculatedFieldSettings, endConfig.calculatedFieldSettings) &&
            this.equals(originalConfig.conditionalFormatSettings, endConfig.conditionalFormatSettings) &&
            this.equals(originalConfig.drilledMembers, endConfig.drilledMembers) &&
            this.equals(originalConfig.filterSettings, endConfig.filterSettings, true) &&
            this.equals(originalConfig.formatSettings, endConfig.formatSettings) &&
            this.equals(originalConfig.sortSettings, endConfig.sortSettings);

        return !allEquals;
    }

    translateCaptions = (values) => {
        const translatedCaptions = [];
        values.forEach(element => {
            const clonedElement = { ...element };

            let translationKey = `main.tabs.custom_grids.labels.${element.name}`;
            let translatedCaption = this.translateService.instant(translationKey);
            if (translationKey === translatedCaption) {
                translationKey = `main.tabs.custom_grids.labels.${element.caption}`;
                translatedCaption = this.translateService.instant(translationKey);
                if (translationKey !== translatedCaption) {
                    clonedElement.caption = translatedCaption;
                }
            }
            else {
                clonedElement.caption = translatedCaption;
            }
            translatedCaptions.push(clonedElement);
        });
        return translatedCaptions;
    }

    equals = (a, b, areFilterSettings = false) => {
        if (a === b) { return true; }
        if (a instanceof Date && b instanceof Date) {
            return a.getTime() === b.getTime();
        }
        if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) {
            return a === b;
        }
        if (a.prototype !== b.prototype) {
            return false;
        }


        if (_.isArray(a) && _.isArray(b)) {
            if ((a.length !== 0 && typeof a[0] !== 'object') ||
                (b.length !== 0 && typeof b[0] !== 'object')) {
                return _.isEqual(a, b)
            }
        }

        if (a.length !== b.length) {
            return false;
        }
        else {
            // a = new Map([...a].sort((c, d) => String(c[0]).localeCompare(d[0])));
            //b = new Map([...b].sort((c, d) => String(c[0]).localeCompare(d[0])));
            const keysA = Object.keys(a);
            return keysA.every(k => ((k === 'overrideDefault' || k === 'caption') && areFilterSettings) ? true : this.equals(a[k], b[k], areFilterSettings));
        }
    }

    public getCurrentBranches = async (): Promise<number[]> => {
        const regionKeys = ['client_region', 'region'];
        const districtKeys = ['client_district', 'district'];
        const locationCodeKey = 'location_code';

        const clientRegion = _.find(this.currentGridConfiguration?.settings?.filterSettings, (filter) => regionKeys.includes(filter.name));
        const clientDistrict = _.find(this.currentGridConfiguration?.settings?.filterSettings, (filter) => districtKeys.includes(filter.name));
        const locationCode = _.find(this.currentGridConfiguration?.settings?.filterSettings, (filter) => filter.name === locationCodeKey);

        if (!clientRegion && !clientDistrict && !locationCode) {
            return [];
        }
        const queryParams = {
            client_id: this.authenticationService.getClientId(),
            client_region: clientRegion?.items || [],
            client_district: clientDistrict?.items || [],
            location_code: locationCode?.items || []
        };
        return await this.rdoHttp.post('scheduler/m2m-query/report/branch-ids', queryParams).first().toPromise() as number[];
    }

    public getGeoMembers(geoMember, geoMemberKey) {
        if (!(geoMember?.items?.length > 0) && this.pivotGridObj.engineModule.fieldList[geoMemberKey]?.members) {
            if (Object.keys(this.pivotGridObj.engineModule.fieldList[geoMemberKey]?.members).length === 0) {
                this.pivotGridObj.getEngine('fetchFieldMembers', null, null, null, null, null, geoMemberKey);
            }
            return { items: Object.keys(this.pivotGridObj.engineModule.fieldList[geoMemberKey]?.members) };
        }
        return geoMember;
    }
    public setCurrentGridConfiguration(config: any) {
        this.currentGridConfiguration = config;
    }

    public setPivotGridObj(pivotObj: any) {
        this.pivotGridObj = pivotObj;
    }

}

