import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MetricsHttpService } from '../../core/http/metrics-http.service';
import { ExportSchedule, ScheduleNotification } from './export-schedule';
@Injectable({
	providedIn: 'root',
  })
export class ExportSchedulerService {

    constructor(
        private metricsHttp: MetricsHttpService
        ) {}
    public getGridExportSchedule = (gridId: string, target: string): Observable<any> => {
        return this.metricsHttp.get(`exports/schedule/${target}/${gridId}`);
    }

    public setGridGridExportSchedule = (scheduledExportId: number, gridFirestoreId: string, target: string, startDate: Date, frequency: string, userIds: number[],
      notificationUserIds: number[], notificationMsg: string ): Observable<any> => {
        const body = {ScheduledExportId: scheduledExportId, StartDate: startDate, Frequency: frequency, GridFirestoreId: gridFirestoreId, GridType: target,
        allowedUserIds: userIds, notificationUserIds, notificationMsg};
        return this.metricsHttp.post(`exports/schedule/save`, body);
    }

    public deleteGridGridExportSchedule = (scheduledExportId: number): Observable<any> => {
        return  this.metricsHttp.delete(`exports/schedule/${scheduledExportId}`);
    }

    parseDate(value: string) {
        try {
          const date = value.substring(0, 10).split('-')
          return new Date(parseInt(date[0]), parseInt(date[1])-1, parseInt(date[2]));
        } catch {
          return null;
        }
      }

    transformJsonToExportSchedule(data: any) {
        const newSchedule: ExportSchedule = new ExportSchedule();
        newSchedule.CreatedBy = data.CreatedBy;
        newSchedule.CreatedDate = this.parseDate(data.CreatedDate);
        newSchedule.Frequency = data.Frequency;
        newSchedule.GridFirestoreId = data.GridFirestoreId;
        newSchedule.GridType = data.GridType;
        newSchedule.LastModifiedBy = data.LastModifiedBy;
        newSchedule.LastModifiedDate = this.parseDate(data.LastModifiedDate);
        newSchedule.NextRunDate = this.parseDate(data.NextRunDate);
        newSchedule.ScheduledExportId = data.ScheduledExportId;
        newSchedule.StartDate = this.parseDate(data.StartDate);
        newSchedule.ScheduleUserPermissions = data.ScheduleUserPermissions || [];
        if (newSchedule.ScheduleUserPermissions && newSchedule.ScheduleUserPermissions.length) {
          newSchedule.ScheduleUserPermissions.forEach(sup => sup.UserName = sup.Email);
        }

        newSchedule.ScheduleNotification = data.ScheduleNotification || new ScheduleNotification();
        return newSchedule;
      }

}
