export enum AlertStatus {
	Unknown = 0,
	Unread = 1,
	Read = 2,
	Cleared = 3
}

export class Alert {
	ClientID: number;
	AlertID: number;
	MonthIDStart: number;
	MonthIDEnd: number;
	BID: number;
	GID: number;
	AlertTypeName: string;
	AlertDescription: string;
	AlertStatus: AlertStatus;
	Country: string;
    Region: string;
    RegionId: number;
    District: string;
    DistrictId: number;
    LocationCode: string;
    BranchId: number;
    Geography: string;
    GeographyId: number;
	ClientProductType: string;
	Description: string;
	Category: string;
	DateCreated?: Date;
	PhysicalUtilizationTotal?: number;
	RevenueBenchmarked?: number;
	RevenueBench?: number;
	RevenueDifference?: number;
	UserID?: number;
	CBID?: number;
	IsContract?: boolean;
	IsNationalAcct?: boolean;
	IsPrime?: boolean;
	IsRPO?: boolean;
	IsReRent?: boolean;
	IsSpecialPricing?: boolean;
	IsSubstitution?: boolean;
	IsCleared?: boolean;
	NumPeriodNotCleared?: number;
	RouseProductTypeId?: number;
	RouseProductType: string;
	UseRouseSchema?: boolean;
	constructor(init?: Partial<Alert>) {
		Object.assign(this, init);
	}
}
