﻿<div *ngIf="selectedProductTypeService.isProductSelected" #rateTypesScreen>
    <rdo-overlay [enabled]="loading"></rdo-overlay>
    <div class="row" [hidden]="loading">
        <div [class]="widthClass()">
            <rdo-rate-type-card title-name="main.tabs.equipment.product_types.rate_types.cards.monthly_revenue"
            [title-value]="monthlyRateType.RevenueTotal" sub-title-name="main.tabs.equipment.product_types.rate_types.cards.compared_revenue"
            [first-value]="monthlyRateType.ClientBenchmarkedRevenue" [second-value]="monthlyRateType.BenchmarkedRevenue">
            </rdo-rate-type-card>
        </div>
        <div [class]="widthClass()">
            <rdo-rate-type-card title-name="main.tabs.equipment.product_types.rate_types.cards.weekly_revenue" 
            [title-value]="weeklyRateType.RevenueTotal" sub-title-name="main.tabs.equipment.product_types.rate_types.cards.compared_revenue"
            [first-value]="weeklyRateType.ClientBenchmarkedRevenue" [second-value]="weeklyRateType.BenchmarkedRevenue">
            </rdo-rate-type-card>
        </div>
        <div [class]="widthClass()">
            <rdo-rate-type-card title-name="main.tabs.equipment.product_types.rate_types.cards.daily_revenue" 
            [title-value]="dailyRateType.RevenueTotal" sub-title-name="main.tabs.equipment.product_types.rate_types.cards.compared_revenue"
            [first-value]="dailyRateType.ClientBenchmarkedRevenue" [second-value]="dailyRateType.BenchmarkedRevenue">
            </rdo-rate-type-card>
        </div>
        <div [class]="widthClass()" [hidden]="!showHourlyRate">
            <rdo-rate-type-card title-name="main.tabs.equipment.product_types.rate_types.cards.hourly_revenue" 
            [title-value]="hourlyRateType.RevenueTotal" sub-title-name="main.tabs.equipment.product_types.rate_types.cards.compared_revenue"
            [first-value]="hourlyRateType.ClientBenchmarkedRevenue" [second-value]="hourlyRateType.BenchmarkedRevenue">
            </rdo-rate-type-card>
        </div>
    </div>

    <div class="row" [hidden]="loading || !chartWidth">
        <div [class]="widthClass()" id="histogram-monthly-chart">
            <div class="panel panel-default" style="border-color: transparent;">
                <rdo-histogram [chart-data]="monthlyHistogramData" [chartId]="'histogram-monthly-chart'"
                [name]="'main.tabs.equipment.product_types.rate_types.histogram.monthly'"
                [chartWidth]="chartWidth"
                (bucketClick)="handleHistogramClick($event, 'main.tabs.equipment.product_types.rate_types.histogram.monthly')"></rdo-histogram>
            </div>
        </div>
        <div [class]="widthClass()" id="histogram-weekly-chart">
            <div class="panel panel-default" style="border-color: transparent;">
                <rdo-histogram [chart-data]="weeklyHistogramData" [chartId]="'histogram-weekly-chart'"
                [name]="'main.tabs.equipment.product_types.rate_types.histogram.weekly'"
                [chartWidth]="chartWidth"
                (bucketClick)="handleHistogramClick($event, 'main.tabs.equipment.product_types.rate_types.histogram.weekly')"></rdo-histogram>
            </div>
        </div>
        <div [class]="widthClass()" id="histogram-daily-chart">
            <div class="panel panel-default" style="border-color: transparent;">
                <rdo-histogram [chart-data]="dailyHistogramData" [chartId]="'histogram-daily-chart'"
                [name]="'main.tabs.equipment.product_types.rate_types.histogram.daily'"
                [chartWidth]="chartWidth"
                 (bucketClick)="handleHistogramClick($event, 'main.tabs.equipment.product_types.rate_types.histogram.daily')"></rdo-histogram>
            </div>
        </div>
        <div [class]="widthClass()" id="histogram-hourly-chart" [hidden]="!showHourlyRate">
            <div class="panel panel-default" style="border-color: transparent;">
                <rdo-histogram [chart-data]="hourlyHistogramData" [chartId]="'histogram-hourly-chart'"
                [name]="'main.tabs.equipment.product_types.rate_types.histogram.hourly'"
                [chartWidth]="chartWidth"
                (bucketClick)="handleHistogramClick($event, 'main.tabs.equipment.product_types.rate_types.histogram.hourly')"></rdo-histogram>
            </div>
        </div>
    </div>

    <div class="row" [hidden]="loading">
        <div [class]="widthClass()">
            <rdo-rate-type-panel [chart-data]="monthlyHistogramData">
            </rdo-rate-type-panel>
        </div>
        <div [class]="widthClass()">
            <rdo-rate-type-panel [chart-data]="weeklyHistogramData">
            </rdo-rate-type-panel>
        </div>
        <div [class]="widthClass()">
            <rdo-rate-type-panel [chart-data]="dailyHistogramData">
            </rdo-rate-type-panel>
        </div>
        <div [class]="widthClass()" [hidden]="!showHourlyRate">
            <rdo-rate-type-panel [chart-data]="hourlyHistogramData">
            </rdo-rate-type-panel>
        </div>
    </div>

    <div class="panel-group" [hidden]="loading">
        <rdo-rate-type-rate-trend title-value="main.tabs.equipment.product_types.rate_types.rate_trends.monthly_rate_trend"
            time-unit-title="main.tabs.equipment.product_types.rate_types.rate_trends.n_of_months"
            [rate-trend-items]="monthlyRateTrend" chart-title-time-unit="Monthly">
        </rdo-rate-type-rate-trend>

        <rdo-rate-type-rate-trend title-value="main.tabs.equipment.product_types.rate_types.rate_trends.weekly_rate_trend" 
            time-unit-title="main.tabs.equipment.product_types.rate_types.rate_trends.n_of_weeks"
            [rate-trend-items]="weeklyRateTrend" chart-title-time-unit="Weekly">
        </rdo-rate-type-rate-trend>

        <rdo-rate-type-rate-trend title-value="main.tabs.equipment.product_types.rate_types.rate_trends.daily_rate_trend"  
            time-unit-title="main.tabs.equipment.product_types.rate_types.rate_trends.n_of_days"
            [rate-trend-items]="dailyRateTrend" chart-title-time-unit="Daily">
        </rdo-rate-type-rate-trend>

        <rdo-rate-type-rate-trend title-value="main.tabs.equipment.product_types.rate_types.rate_trends.hourly_rate_trend"  
            time-unit-title="main.tabs.equipment.product_types.rate_types.rate_trends.n_of_hours"
            [rate-trend-items]="hourlyRateTrend" chart-title-time-unit="Hourly"
            [hidden]="!showHourlyRate">
        </rdo-rate-type-rate-trend>
    </div>
</div>
<div class="no-data-available" *ngIf="!selectedProductTypeService.isProductSelected && !loading">
    {{'main.tabs.equipment.product_types.no_data_available' | translate}}
</div>
