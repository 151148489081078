<div class="profile-list-component">
    <button (click)="onOpenList()" class="main-btn" #filterProfileToggleBtn>
        <span>{{currentProfile && currentProfile.profileName ? currentProfile.profileName :
            ('main.filters.profiles.list.no_selected' | translate)}}</span>
        <i class="fa fa-chevron-down main-btn-icon"></i>
    </button>
    <div [hidden]="!openProfileList" class="profile-list-container" #filterProfileMenu>
        <div class="filter-profiles-list">
            <div *ngFor="let profile of profilesList" class="filter-profile-elem"
                (click)="onProfileSelected(profile.profileId)">{{profile?.profileName}}</div>
            <div class="filter-profile-elem" (click)="onProfileSelected(-1)">{{'main.filters.profiles.list.no_selected'
                | translate}}</div>
        </div>
        <div class="manage-profiles-btn" *ngIf="displayManagerBtn" (click)="onManageProfiles()">
            {{'main.filters.profiles.list.manage' | translate}}</div>
    </div>
</div>