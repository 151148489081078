<ng-container *ngIf="this.service.isLoaded && this.service.isActive">
    <div class="maintenance-mode">
        <div class="container animated">
            <h1>{{this.service.mxTitle}}</h1>
            <p>{{this.service.mxDescription}}</p>
            <time class="bold">{{this.service.nextEndTime | date:'MMM d, y, h:mm a'}}</time>
            <a routerLink="/signout">{{ this.service.mxNavigation }}</a>
        </div>
    </div>
</ng-container>
<!--
<ng-container *ngIf="!this.service.isContentVisible">
-->
<div [hidden]="!this.service.isContentVisible">
    <ng-content ></ng-content>
</div>
<!--</ng-container>-->

