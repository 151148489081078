import { Injectable } from '@angular/core';
import { IMultiSelectSettings, IMultiSelectTexts } from '../../core/dropdown/types';

@Injectable()
export class FilterConfigService {

  public static readonly filterMultiSelectTexts: IMultiSelectTexts = {
    checkAll: 'main.filters.common.check_all', // 'Check all'
    uncheckAll: 'main.filters.common.uncheck_all', // 'Uncheck all'
    checked: 'main.filters.common.checked_singular', // 'selected'
    checkedPlural: 'main.filters.common.checked_plural', // 'selected'
    defaultTitle: 'main.filters.common.default_title', // 'All'
    allSelected: 'main.filters.common.all_selected' // 'All'
  };

  public static readonly monthMultiSelectionTexts: IMultiSelectTexts = {
    defaultTitle: 'main.filters.month.multi_selection.default_title', // 'Current Month',
    allSelected: 'main.filters.month.multi_selection.all_selected', // 'All',
    checked: 'main.filters.month.multi_selection.checked_singular', // 'month checked',
    checkedPlural: 'main.filters.month.multi_selection.checked_plural', // 'months checked'
    yearToDate: 'main.filters.month.multi_selection.year_to_date',
    latestMonth: 'main.filters.month.multi_selection.latest_month',
    latestFullMonth: 'main.filters.month.multi_selection.latest_full_month',
    latest3FullMonths: 'main.filters.month.multi_selection.latest_3_full_months',
    latest6FullMonths: 'main.filters.month.multi_selection.latest_6_full_months',
    latest9FullMonths: 'main.filters.month.multi_selection.latest_9_full_months',
    latest12FullMonths: 'main.filters.month.multi_selection.latest_12_full_months',
  };

  public static readonly monthMultiSelectSettings: IMultiSelectSettings = {
    checkedStyle: 'fontawesome',
    containerClasses: 'dropdown-block',
    dynamicTitleMaxItems: 4,
    displayAllSelectedText: true,
    maxHeight: '260px',
    showCheckAll: true,
    showUncheckAll: true
  };

  public static readonly singleSelectSettings: IMultiSelectSettings = {
    selectionLimit: 1,
    autoUnselect: true,
    checkedStyle: 'fontawesome',
    containerClasses: 'dropdown-block',
    maxHeight: '260px',
    closeOnSelect: true,
  };

  public static readonly singleSelectWithSearchSettings: IMultiSelectSettings = {
    selectionLimit: 1,
    autoUnselect: true,
    checkedStyle: 'fontawesome',
    containerClasses: 'dropdown-block',
    maxHeight: '260px',
    closeOnSelect: true,
    enableSearch: true
  };

  public static readonly multiSelectSettings: IMultiSelectSettings = {
    showCheckAll: true,
    showUncheckAll: true,
    checkedStyle: 'fontawesome',
    containerClasses: 'dropdown-block',
    buttonClasses: 'btn btn-default truncate',
    maxHeight: '115px',
    dynamicTitleMaxItems: 2,
    displayAllSelectedText: true
  };

  public static readonly multiSelectWithSearchSettings: IMultiSelectSettings = {
    showCheckAll: true,
    showUncheckAll: true,
    checkedStyle: 'fontawesome',
    containerClasses: 'dropdown-block',
    buttonClasses: 'btn btn-default truncate',
    maxHeight: '229px',
    dynamicTitleMaxItems: 2,
    displayAllSelectedText: true,
    enableSearch: true
  };

  public static readonly rouseProductTypesmultiSelectSettings: IMultiSelectSettings = {
    showCheckAll: true,
    showUncheckAll: true,
    checkedStyle: 'fontawesome',
    containerClasses: 'dropdown-block',
    buttonClasses: 'btn btn-default truncate',
    maxHeight: '229px',
    dynamicTitleMaxItems: 2,
    displayAllSelectedText: true,
    enableSearch: true
  };

  public static readonly clientProductTypesmultiSelectSettings: IMultiSelectSettings = {
    showCheckAll: true,
    showUncheckAll: true,
    checkedStyle: 'fontawesome',
    containerClasses: 'dropdown-block',
    buttonClasses: 'btn btn-default truncate',
    maxHeight: '229px',
    dynamicTitleMaxItems: 2,
    displayAllSelectedText: true,
    enableSearch: true
  };

  public static readonly multiSelectCategorySettings: IMultiSelectSettings = {
    showCheckAll: true,
    showUncheckAll: true,
    checkedStyle: 'fontawesome',
    containerClasses: 'dropdown-block',
    buttonClasses: 'btn btn-default truncate',
    maxHeight: '229px',
    dynamicTitleMaxItems: 2,
    displayAllSelectedText: true,
    enableSearch: true
  };

  public static readonly multiSelectDistrictSettings: IMultiSelectSettings = {
    showCheckAll: true,
    showUncheckAll: true,
    checkedStyle: 'fontawesome',
    containerClasses: 'dropdown-block',
    buttonClasses: 'btn btn-default truncate',
    maxHeight: '190px',
    dynamicTitleMaxItems: 2,
    displayAllSelectedText: true,
    enableSearch: true
  };

  public static readonly multiSelectBranchSettings: IMultiSelectSettings = {
    showCheckAll: true,
    showUncheckAll: true,
    checkedStyle: 'fontawesome',
    containerClasses: 'dropdown-block',
    buttonClasses: 'btn btn-default truncate',
    maxHeight: '190px',
    dynamicTitleMaxItems: 2,
    displayAllSelectedText: true,
    enableSearch: true
  };

  public static readonly multiSelectMarketSettings: IMultiSelectSettings = {
    showCheckAll: true,
    showUncheckAll: true,
    checkedStyle: 'fontawesome',
    containerClasses: 'dropdown-block',
    buttonClasses: 'btn btn-default truncate',
    maxHeight: '190px',
    dynamicTitleMaxItems: 2,
    displayAllSelectedText: true,
    enableSearch: true
  };


  public static readonly multiSelectRegionSettings: IMultiSelectSettings = {
    showCheckAll: true,
    showUncheckAll: true,
    checkedStyle: 'fontawesome',
    containerClasses: 'dropdown-block',
    buttonClasses: 'btn btn-default truncate',
    maxHeight: '190px',
    dynamicTitleMaxItems: 2,
    displayAllSelectedText: true,
    enableSearch: true
  };

  constructor() { }
}
