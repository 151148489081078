import { Component, ElementRef, ViewContainerRef, ChangeDetectionStrategy, QueryList, Renderer2, Injector, ValueProvider, ContentChild } from '@angular/core';
import { ComponentBase, IComponentBase, applyMixins, ComponentMixins, PropertyCollectionInfo, setValue } from '../../ej2-angular-base';
import { PivotView } from '../../ej2-pivotview';
import { Template } from '../../ej2-angular-base';


export const /** @type {?} */ inputs: string[] = ['aggregateTypes','allowCalculatedField','allowConditionalFormatting','allowDataCompression','allowDeferLayoutUpdate','allowDrillThrough','allowExcelExport','allowGrouping','allowNumberFormatting','allowPdfExport','cellTemplate','chartSettings','chartTypes','currencyCode','dataSourceSettings','displayOption','editSettings','enableHtmlSanitizer','enablePersistence','enableRtl','enableValueSorting','enableVirtualization','exportAllPages','gridSettings','groupingBarSettings','height','hyperlinkSettings','loadOnDemandInMemberEditor','locale','maxNodeLimitInMemberEditor','maxRowsInDrillThrough','pivotValues','showFieldList','showGroupingBar','showToolbar','showTooltip','showValuesButton','spinnerTemplate','toolbar','toolbarTemplate','tooltipTemplate','width'];
export const /** @type {?} */ outputs: string[] = ['aggregateCellInfo','aggregateMenuOpen','beforeExport','beforeServiceInvoke','beginDrillThrough','calculatedFieldCreate','cellClick','cellSelected','cellSelecting','chartSeriesCreated','conditionalFormatting','created','dataBound','destroyed','drill','drillThrough','editCompleted','enginePopulated','enginePopulating','fetchReport','fieldDragStart','fieldDrop','fieldListRefreshed','fieldRemove','hyperlinkCellClick','load','loadReport','memberEditorOpen','memberFiltering','newReport','numberFormatting','onFieldDropped','onPdfCellRender','removeReport','renameReport','saveReport','toolbarClick','toolbarRender'];
export const /** @type {?} */ twoWays: string[] = [];
/**
 * `ej-pivotview` represents the Angular PivotView Component.
 * ```html
 * <ej-pivotview></ej-pivotview>
 * ```
 */
@ComponentMixins([ComponentBase])
export class PivotViewComponent extends PivotView implements IComponentBase {
public context : any;
public tagObjects: any;
	aggregateCellInfo: any;
	aggregateMenuOpen: any;
	beforeExport: any;
	beforeServiceInvoke: any;
	beginDrillThrough: any;
	calculatedFieldCreate: any;
	cellClick: any;
	cellSelected: any;
	cellSelecting: any;
	chartSeriesCreated: any;
	conditionalFormatting: any;
	created: any;
	dataBound: any;
	destroyed: any;
	drill: any;
	drillThrough: any;
	editCompleted: any;
	enginePopulated: any;
	enginePopulating: any;
	fetchReport: any;
	fieldDragStart: any;
	fieldDrop: any;
	fieldListRefreshed: any;
	fieldRemove: any;
	hyperlinkCellClick: any;
	load: any;
	loadReport: any;
	memberEditorOpen: any;
	memberFiltering: any;
	newReport: any;
	numberFormatting: any;
	onFieldDropped: any;
	onPdfCellRender: any;
	removeReport: any;
	renameReport: any;
	saveReport: any;
	toolbarClick: any;
public toolbarRender: any;
/**
 * Allows the table cell elements to be customized with either an HTML string or the element’s ID, 
 * that can be used to add additional HTML elements with custom formats to the cell elements that are displayed in the pivot table.
 * \@default null
 */
@Template()
    public cellTemplate: any;
/**
 * @param {?} ngEle
 * @param {?} srenderer
 * @param {?} viewContainerRef
 * @param {?} injector
 */
constructor(private ngEle: ElementRef,
private srenderer: Renderer2,
private viewContainerRef:ViewContainerRef,
private injector: Injector) {
        super();
        this.element = this.ngEle.nativeElement;
        this.injectedModules = this.injectedModules || [];
        try {
                let mod = this.injector.get('PivotViewGroupingBar');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('PivotViewFieldList');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('PivotViewCalculatedField');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('PivotViewConditionalFormatting');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('PivotViewVirtualScroll');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('PivotViewDrillThrough');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('PivotViewToolbar');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('PivotViewPivotChart');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('PivotViewPDFExport');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('PivotViewExcelExport');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('PivotViewNumberFormatting');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }
        try {
                let mod = this.injector.get('PivotViewGrouping');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }

        this.registerEvents(outputs);
        this.addTwoWay.call(this, twoWays);
        setValue('currentInstance', this, this.viewContainerRef);
        this.context  = new ComponentBase();
    }
/**
 * @return {?}
 */
public ngOnInit() {
        this.context.ngOnInit(this);
    }
/**
 * @return {?}
 */
public ngAfterViewInit(): void {
        this.context.ngAfterViewInit(this);
    }
/**
 * @return {?}
 */
public ngOnDestroy(): void {
        this.context.ngOnDestroy(this);
    }
/**
 * @return {?}
 */
public ngAfterContentChecked(): void {
        
        this.context.ngAfterContentChecked(this);
    }
public registerEvents: (eventList: string[]) => void;
public addTwoWay: (propList: string[]) => void;
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
    selector: 'ejs-pivotview',
    inputs: inputs,
    outputs: outputs,
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
    queries: {

    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
{type: Renderer2, },
{type: ViewContainerRef, },
{type: Injector, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'cellTemplate': [{ type: ContentChild, args: ['cellTemplate', ] },],
};
}

function PivotViewComponent_tsickle_Closure_declarations() {
/** @type {?} */
PivotViewComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
PivotViewComponent.ctorParameters;
/** @type {?} */
PivotViewComponent.propDecorators;
/** @type {?} */
PivotViewComponent.prototype.context;
/** @type {?} */
PivotViewComponent.prototype.tagObjects;
/** @type {?} */
PivotViewComponent.prototype.aggregateCellInfo;
/** @type {?} */
PivotViewComponent.prototype.aggregateMenuOpen;
/** @type {?} */
PivotViewComponent.prototype.beforeExport;
/** @type {?} */
PivotViewComponent.prototype.beforeServiceInvoke;
/** @type {?} */
PivotViewComponent.prototype.beginDrillThrough;
/** @type {?} */
PivotViewComponent.prototype.calculatedFieldCreate;
/** @type {?} */
PivotViewComponent.prototype.cellClick;
/** @type {?} */
PivotViewComponent.prototype.cellSelected;
/** @type {?} */
PivotViewComponent.prototype.cellSelecting;
/** @type {?} */
PivotViewComponent.prototype.chartSeriesCreated;
/** @type {?} */
PivotViewComponent.prototype.conditionalFormatting;
/** @type {?} */
PivotViewComponent.prototype.created;
/** @type {?} */
PivotViewComponent.prototype.dataBound;
/** @type {?} */
PivotViewComponent.prototype.destroyed;
/** @type {?} */
PivotViewComponent.prototype.drill;
/** @type {?} */
PivotViewComponent.prototype.drillThrough;
/** @type {?} */
PivotViewComponent.prototype.editCompleted;
/** @type {?} */
PivotViewComponent.prototype.enginePopulated;
/** @type {?} */
PivotViewComponent.prototype.enginePopulating;
/** @type {?} */
PivotViewComponent.prototype.fetchReport;
/** @type {?} */
PivotViewComponent.prototype.fieldDragStart;
/** @type {?} */
PivotViewComponent.prototype.fieldDrop;
/** @type {?} */
PivotViewComponent.prototype.fieldListRefreshed;
/** @type {?} */
PivotViewComponent.prototype.fieldRemove;
/** @type {?} */
PivotViewComponent.prototype.hyperlinkCellClick;
/** @type {?} */
PivotViewComponent.prototype.load;
/** @type {?} */
PivotViewComponent.prototype.loadReport;
/** @type {?} */
PivotViewComponent.prototype.memberEditorOpen;
/** @type {?} */
PivotViewComponent.prototype.memberFiltering;
/** @type {?} */
PivotViewComponent.prototype.newReport;
/** @type {?} */
PivotViewComponent.prototype.numberFormatting;
/** @type {?} */
PivotViewComponent.prototype.onFieldDropped;
/** @type {?} */
PivotViewComponent.prototype.onPdfCellRender;
/** @type {?} */
PivotViewComponent.prototype.removeReport;
/** @type {?} */
PivotViewComponent.prototype.renameReport;
/** @type {?} */
PivotViewComponent.prototype.saveReport;
/** @type {?} */
PivotViewComponent.prototype.toolbarClick;
/** @type {?} */
PivotViewComponent.prototype.toolbarRender;
/**
 * Allows the table cell elements to be customized with either an HTML string or the element’s ID, 
 * that can be used to add additional HTML elements with custom formats to the cell elements that are displayed in the pivot table.
 * \@default null
 * @type {?}
 */
PivotViewComponent.prototype.cellTemplate;
/** @type {?} */
PivotViewComponent.prototype.registerEvents;
/** @type {?} */
PivotViewComponent.prototype.addTwoWay;
/** @type {?} */
PivotViewComponent.prototype.ngEle;
/** @type {?} */
PivotViewComponent.prototype.srenderer;
/** @type {?} */
PivotViewComponent.prototype.viewContainerRef;
/** @type {?} */
PivotViewComponent.prototype.injector;
}



interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
