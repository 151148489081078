<div class="panel panel-default rdo-card rdo-card-default">
    <div class="panel-heading">
        <div class="panel-title">
            <i class="fa fa-calendar"></i>&nbsp;
            {{titleName | translate}} {{titleValue | rdoCurrency:true:translateService.currentLang}} {{'main.core.common.total' | translate}}
        </div>
    </div>
    <div class="panel-body">
        <div class="row">
            <div class="col-xs-6 panel-title two-lined-ellipsed">
                {{subTitleName | translate}}
            </div>
            <div class="col-xs-6 text-right panel-title two-lined-ellipsed"
                >&nbsp; {{'main.tabs.equipment.product_types.rate_types.cards.bench_difference' | translate}}
            </div>
        </div>

        <div class="row rdo-top-padded-value">
            <div class="col-xs-6 rdo-card-metric">
                <span [ngClass]="{'text-danger': firstValue < 0, 'text-muted': firstValue === 0 }">
                    {{firstValue | rdoCurrency:undefined:translateService.currentLang}}
                </span>
            </div>
            <div class="col-xs-6 text-right rdo-card-metric">
                <rdo-amount-difference [clientValue]=firstValue [benchmarkedValue]=secondValue [showCurrencySymbol]=true>
                </rdo-amount-difference>
            </div>
        </div>
    </div>
</div>