import { MetricsGridConfig } from '../MetricsGridConfig';
import { IColumnConfig } from '../IColumnConfig.interface';

export function getMovableColumns(grid: MetricsGridConfig): IColumnConfig[] {
  return grid.groups[0].columns.reduce((acc, col) => {
    if (col.isMovable && col.visible) {
      acc.push(col);
    }
    return acc;
  }, []);
}
