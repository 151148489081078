import { Component, Input, EventEmitter, Output } from '@angular/core';
import * as _ from 'lodash';

import { MetricsGridConfig } from './MetricsGridConfig';
import { IColumnConfig } from './IColumnConfig.interface';
import { IDataRow } from './IDataRow.interface';
import { GridConfigService } from './grid-config.service';
import { TranslateService } from '@ngx-translate/core';
import { GridSortingService } from '../services';

@Component({
    selector: 'rdo-metrics-grid',
    templateUrl: './metrics-grid.html',
})
export class MetricsGridComponent {
    rawPagedData: any;
    @Input()
    set pagedData(rawPagedData: any) {
        this.sortInProgress = false;
        this.rawPagedData = rawPagedData;

        this.rows = _.map(rawPagedData.Items, (r) => {
            return { raw: r };
        });

    }
    get pagedData(): any {
        return this.rawPagedData;
    }

    @Input()
    loading: boolean;

    @Input()
    disablePaging: boolean;

    @Input()
    sortOn: string;

    @Input()
    desc: boolean;

    @Input()
    name: string;

    @Input()
    set gridConfig(config: MetricsGridConfig) {
        this._gridConfig = this.gridConfigService.applyVisibilityOptions(this.name, config);
    }

    get gridConfig(): MetricsGridConfig {
        return this._gridConfig;
    }

    // eslint-disable-next-line
    @Output('pageChange')
    pageChanged = new EventEmitter<number>();

    @Output()
    pageSizeChange = new EventEmitter<number>();

    @Output()
    sortOnChange = new EventEmitter<any>();

    @Output()
    descChange = new EventEmitter<boolean>();

    @Output()
    downloadExcelClick: EventEmitter<MetricsGridConfig> = new EventEmitter<MetricsGridConfig>();

    @Output()
    deleteClick: EventEmitter<MetricsGridConfig> = new EventEmitter<MetricsGridConfig>();

    rows: Array<IDataRow>;
    sortInProgress: boolean;
    private _gridConfig: MetricsGridConfig;

    constructor(
        private gridConfigService: GridConfigService,
        private translateService: TranslateService,
        private gridSortingService: GridSortingService,
    ) { }

    previousPage() {
        this.pageChanged.next(this.pagedData.Page - 1);
    }

    nextPage() {
        this.pageChanged.next(this.pagedData.Page + 1);
    }

    onSubmitGoToPage() {
        this.pageChanged.next(this.pagedData.Page);
    }

    changeSort(args: IColumnConfig) {
        this.sortInProgress = true;
        this.sortOnChange.next(args);
    }

    changeSortDirection(desc: boolean) {
        this.sortInProgress = true;
        this.descChange.next(desc);
    }
    changePageSize(size: number) {
        this.pageSizeChange.next(size);
    }
    saveVisibility(args: any) {
        this.gridConfigService.saveVisibilityOptions(this.name, this.gridConfig);
    }
    expandBasedOnView(columnTitle: string): boolean {
        const totalRevenueText = this.translateService.instant('main.core.metrics.total_revenue');
        if (columnTitle.toLowerCase() === totalRevenueText.toLowerCase()) {
            return this.gridSortingService.defaultSortColumn === 'RevenueBookDifference'; // todo: RDO-1119
        }
    }
}


