import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CoreModule } from './../core/core.module';
import { TrialGuardService } from '../core';
import {
    SalesRepBaseComponent,
    SalesRepComponent,
    SalesRepsComponent,
    SalesRepCustomersComponent,
    SalesRepDashboardComponent,
    SalesRepProductTypesComponent,
    SalesRepService,
    SelectedSalesRepService,
    SalesRepCustomerService
} from '../sales-rep';
import { EquipmentService, ProductTypeTransactionsComponent } from '../equipment';

const root = 'sales-reps';
const salesRepRoutes: Routes = [
    {   path: root, 
        component: SalesRepsComponent,
        data: { title: 'main.tabs.sales_reps.page_titles.root' },
        canActivate: [TrialGuardService] },
    {
        path: root + '/:salesRep', 
        component: SalesRepComponent,
        canActivate: [TrialGuardService],
        children: [
            {   path: '', 
                component: SalesRepDashboardComponent,
				data: { 
                    name: 'dashboard', 
                    title: 'main.tabs.sales_reps.page_titles.dashboard' }
            },
            {   path: 'customers',
                component: SalesRepCustomersComponent,
                data: { 
                    name: 'customers', 
                    title: 'main.tabs.sales_reps.page_titles.customers' }
            },
            {   path: 'product-types',
                component: SalesRepProductTypesComponent,
                data: { name: 'product-types',
                        title: 'main.tabs.sales_reps.page_titles.product_types' }
            },
            {   path: 'transactions',
                component: ProductTypeTransactionsComponent,
                data: { title: 'main.tabs.sales_reps.page_titles.transactions' }
            }
        ]

    }
];

@NgModule({
    imports: [RouterModule.forChild(salesRepRoutes), CommonModule, CoreModule, InfiniteScrollModule],
    declarations: [
        SalesRepBaseComponent,
        SalesRepComponent,
        SalesRepsComponent,
        SalesRepDashboardComponent,
        SalesRepCustomersComponent,
        SalesRepProductTypesComponent
    ],
    providers: [
        SalesRepService,
        SalesRepCustomerService,
        SelectedSalesRepService,
		EquipmentService,
		TrialGuardService
    ]
})
export class SalesRepModule { }
