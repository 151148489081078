﻿<div class="rental-growth">
  <rental-growth-call-out [marketUonRentChange]="data.unitsOnRent.BenchmarkGrowth"
                          [marketPhysicalUtilChange]="data.physicalUtil.BenchmarkGrowth"
                          [clientShareChange]="data.unitsOnRent.CvsBGrowthPointsDiff"
                          [clientShareUnitsChange]="data.unitsOnRent.CvsBGrowthUnitsDiff"
                          [monthlyRentalRatesChange]="data.monthlyRentalRates.BenchmarkGrowth"></rental-growth-call-out>

  <div class="row">
    <div class="col-xs-12 rental-growth">
      <table class="table table-bordered table-nowrap">
        <thead>
          <tr>
            <th class="title-col"></th>
            <th colspan="2" class="rental-growth-col-highlight uppercased">{{'main.tabs.equipment.product_types.change.table.client_total' | translate}}</th>
            <th colspan="2" class="uppercased">{{'main.tabs.equipment.product_types.change.table.client_compared' | translate}}</th>
            <th colspan="2" class="uppercased">{{'main.tabs.equipment.product_types.change.table.market_benchmarks' | translate}}</th>
            <th class="client-vs-bench-col rental-growth-col-highlight uppercased">{{'main.tabs.equipment.product_types.change.table.client_relative_to_market' | translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th></th>
            <th class="rental-growth-col-highlight"><div class="growth-table-title">
              {{'main.tabs.equipment.product_types.change.table.current_average' | translate}}</div></th>
            <th class="rental-growth-col-highlight"><div class="growth-table-title">{{typeChange()}}</div></th>
            <th><div class="growth-table-title">
              {{'main.tabs.equipment.product_types.change.table.current_average' | translate}}</div></th>
            <th><div class="growth-table-title">{{typeChange()}}</div></th>
            <th><div class="growth-table-title">
              {{'main.tabs.equipment.product_types.change.table.current_average' | translate}}</div></th>
            <th><div class="growth-table-title">{{typeChange()}}</div></th>
            <th class="rental-growth-col-highlight"><div class="growth-table-title">{{typeChangeDiff()}}</div></th>
          </tr>
          <ng-template [ngIf]="data.unitsOnRent">
            <tr>
              <td><strong>{{data.unitsOnRent.Title | translate}}</strong></td>
              <td class="rental-growth-col-highlight">{{data.unitsOnRent.ClientTotal | rdoNumber:1}}</td>
              <td class="rental-growth-col-highlight"><span class="{{data.unitsOnRent.GrowthTotal < 0 ? 'decrease' : ''}}">{{data.unitsOnRent.GrowthTotal | rdoPercent}}</span></td>
              <td>{{data.unitsOnRent.ClientAvg | rdoNumber:1}}</td>
              <td><span class="{{data.unitsOnRent.ClientGrowth<0 ? 'decrease' : ''}}">{{data.unitsOnRent.ClientGrowth | rdoPercent}}</span></td>
              <td>-</td>
              <td [class.flat-highlight]="panelEvent && panelEvent.panelType === 'unitsonrent' && data.unitsOnRent.BenchmarkGrowth === 0"
                  [class.increase-highlight]="panelEvent && panelEvent.panelType === 'unitsonrent' && data.unitsOnRent.BenchmarkGrowth > 0"
                  [class.decrease-highlight]="panelEvent && panelEvent.panelType === 'unitsonrent' && data.unitsOnRent.BenchmarkGrowth < 0">
                <span class="{{data.unitsOnRent.BenchmarkGrowth<0 ? 'decrease' : ''}}">{{data.unitsOnRent.BenchmarkGrowth  | rdoPercent}}</span>
              </td>
              <td class="rental-growth-col-highlight"
                  [class.flat-highlight]="panelEvent && panelEvent.panelType === 'relativerentals' && data.unitsOnRent.CvsBGrowthUnitsDiff === 0"
                  [class.increase-highlight]="panelEvent && panelEvent.panelType === 'relativerentals' && data.unitsOnRent.CvsBGrowthUnitsDiff > 0"
                  [class.decrease-highlight]="panelEvent && panelEvent.panelType === 'relativerentals' && data.unitsOnRent.CvsBGrowthUnitsDiff < 0">
                <span class="{{data.unitsOnRent.CvsBGrowthPointsDiff < 0 ? 'decrease' : ''}}">{{data.unitsOnRent.CvsBGrowthUnitsDiff  | rdoNumber:1}} {{units(data.unitsOnRent.CvsBGrowthUnitsDiff)}}&nbsp;/&nbsp;{{data.unitsOnRent.CvsBGrowthPointsDiff  | rdoPercent}}&nbsp;{{pts(data.unitsOnRent.CvsBGrowthPointsDiff)}}</span>
              </td>
            </tr>
          </ng-template>
          <ng-template [ngIf]="data.unitsOnFleet">
            <tr>
              <td><strong>{{data.unitsOnFleet.Title | translate}}</strong></td>
              <td class="rental-growth-col-highlight">{{data.unitsOnFleet.ClientTotal | rdoNumber:1}}</td>
              <td class="rental-growth-col-highlight"><span class="{{data.unitsOnFleet.GrowthTotal<0 ? 'decrease' : ''}}">{{data.unitsOnFleet.GrowthTotal | rdoPercent}}</span></td>
              <td>{{data.unitsOnFleet.ClientAvg | rdoNumber:1}}</td>
              <td><span class="{{data.unitsOnFleet.ClientGrowth<0 ? 'decrease' : ''}}">{{data.unitsOnFleet.ClientGrowth  | rdoPercent}}</span></td>
              <td>-</td>
              <td><span class="{{data.unitsOnFleet.BenchmarkGrowth<0 ? 'decrease' : ''}}">{{data.unitsOnFleet.BenchmarkGrowth  | rdoPercent}}</span></td>
              <td class="rental-growth-col-highlight"><span class="{{data.unitsOnFleet.CvsBGrowthPointsDiff<0 ? 'decrease' : ''}}">{{data.unitsOnFleet.CvsBGrowthUnitsDiff | rdoNumber:1}} {{units(data.unitsOnFleet.CvsBGrowthUnitsDiff)}}&nbsp;/&nbsp;{{data.unitsOnFleet.CvsBGrowthPointsDiff  | rdoPercent}}&nbsp;{{pts(data.unitsOnFleet.CvsBGrowthPointsDiff)}}</span></td>
            </tr>
          </ng-template>
          <ng-template [ngIf]="data.physicalUtil">
            <tr class="highlight">
              <td><strong>{{data.physicalUtil.Title | translate}}</strong></td>
              <td class="rental-growth-col-highlight">{{data.physicalUtil.ClientTotal | rdoPercent}}</td>
              <td class="rental-growth-col-highlight"><span class="{{data.physicalUtil.GrowthTotal<0 ? 'decrease' : ''}}">{{data.physicalUtil.GrowthTotal | rdoPercent}} {{pts(data.physicalUtil.GrowthTotal)}}</span></td>
              <td>{{data.physicalUtil.ClientAvg  | rdoPercent}}</td>
              <td><span class="{{data.physicalUtil.ClientGrowth<0 ? 'decrease' : ''}}">{{data.physicalUtil.ClientGrowth | rdoPercent}} {{pts(data.physicalUtil.ClientGrowth)}}</span></td>
              <td>{{data.physicalUtil.BenchmarkAvg | rdoPercent}}</td>
              <td [class.flat-highlight]="panelEvent && panelEvent.panelType === 'physicalutilization' && data.physicalUtil.BenchmarkGrowth === 0"
                  [class.increase-highlight]="panelEvent && panelEvent.panelType === 'physicalutilization' && data.physicalUtil.BenchmarkGrowth > 0"
                  [class.decrease-highlight]="panelEvent && panelEvent.panelType === 'physicalutilization' && data.physicalUtil.BenchmarkGrowth < 0">
                <span class="{{data.physicalUtil.BenchmarkGrowth<0 ? 'decrease' : ''}}">{{data.physicalUtil.BenchmarkGrowth | rdoPercent}} {{pts(data.physicalUtil.BenchmarkGrowth)}}</span>
              </td>
              <td class="rental-growth-col-highlight"><span class="{{data.physicalUtil.CvsBGrowthPointsDiff<0 ? 'decrease' : ''}}">{{data.physicalUtil.CvsBGrowthPointsDiff | rdoPercent}} {{pts(data.physicalUtil.CvsBGrowthPointsDiff)}}</span></td>
            </tr>
          </ng-template>
          <tr>
            <td><strong>{{data.unitCost.Title | translate}}</strong></td>
            <td class="rental-growth-col-highlight">-</td>
            <td class="rental-growth-col-highlight">-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td class="rental-growth-col-highlight">-</td>
          </tr>
          <tr>
            <ng-template [ngIf]="data.monthlyRentalRates">
              <td><strong>{{data.monthlyRentalRates.Title | translate}}</strong></td>
              <td class="rental-growth-col-highlight">{{data.monthlyRentalRates.ClientTotal  | rdoCurrency:true}}</td>
              <td class="rental-growth-col-highlight"><span class="{{data.monthlyRentalRates.GrowthTotal<0 ? 'decrease' : ''}}">{{data.monthlyRentalRates.GrowthTotal  | rdoPercent}}</span></td>
              <td>{{data.monthlyRentalRates.ClientAvg  | rdoCurrency:true}}</td>
              <td><span class="{{data.monthlyRentalRates.ClientGrowth<0 ? 'decrease' : ''}}">{{data.monthlyRentalRates.ClientGrowth  | rdoPercent}}</span></td>
              <td>{{data.monthlyRentalRates.BenchmarkAvg | rdoCurrency:true}}</td>
              <td [class.flat-highlight]="panelEvent && panelEvent.panelType === 'monthlyrates' && data.monthlyRentalRates.BenchmarkGrowth === 0"
                  [class.increase-highlight]="panelEvent && panelEvent.panelType === 'monthlyrates' && data.monthlyRentalRates.BenchmarkGrowth > 0"
                  [class.decrease-highlight]="panelEvent && panelEvent.panelType === 'monthlyrates' && data.monthlyRentalRates.BenchmarkGrowth < 0">
                <span class="{{data.monthlyRentalRates.BenchmarkGrowth<0 ? 'decrease' : ''}}">{{data.monthlyRentalRates.BenchmarkGrowth  | rdoPercent}}</span>
              </td>
              <td class="rental-growth-col-highlight"><span class="{{data.monthlyRentalRates.CvsBGrowthPointsDiff<0 ? 'decrease' : ''}}">{{data.monthlyRentalRates.CvsBGrowthPointsDiff  | rdoPercent}} {{pts(data.monthlyRentalRates.CvsBGrowthPointsDiff)}}</span></td>
            </ng-template>
          </tr>
          <ng-template [ngIf]="data.financialUtil">
            <tr class="highlight">
              <td><strong>{{data.financialUtil.Title | translate}}</strong></td>
              <td class="rental-growth-col-highlight">{{data.financialUtil.ClientTotal | rdoPercent}}</td>
              <td class="rental-growth-col-highlight"><span class="{{data.financialUtil.GrowthTotal<0 ? 'decrease' : ''}}">{{data.financialUtil.GrowthTotal | rdoPercent}} {{pts(data.financialUtil.GrowthTotal)}}</span></td>
              <td>{{data.financialUtil.ClientAvg  | rdoPercent}}</td>
              <td><span class="{{data.financialUtil.ClientGrowth<0 ? 'decrease' : ''}}">{{data.financialUtil.ClientGrowth | rdoPercent}} {{pts(data.financialUtil.ClientGrowth)}}</span></td>
              <td>{{data.financialUtil.BenchmarkAvg | rdoPercent}}</td>
              <td><span class="{{data.financialUtil.BenchmarkGrowth<0 ? 'decrease' : ''}}">{{data.financialUtil.BenchmarkGrowth | rdoPercent}} {{pts(data.financialUtil.BenchmarkGrowth)}}</span></td>
              <td class="rental-growth-col-highlight"><span class="{{data.financialUtil.CvsBGrowthPointsDiff<0 ? 'decrease' : ''}}">{{data.financialUtil.CvsBGrowthPointsDiff | rdoPercent}} {{pts(data.financialUtil.CvsBGrowthPointsDiff)}}</span></td>
            </tr>
          </ng-template>
        </tbody>
      </table>

    </div>
  </div>
</div>
