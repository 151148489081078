import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef  } from '@angular/material/legacy-dialog';;
import { Router } from '@angular/router';

@Component({
    selector: 'app-unauthorized',
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.scss']
})

export class UnauthorizedComponent {

    constructor(private router: Router, 
                private dialogRef: MatDialogRef<UnauthorizedComponent>,
                @Inject(MAT_DIALOG_DATA) data) {
    }

    public closeClick = () => {

        this.dialogRef.close({ response: null });
    }
}
