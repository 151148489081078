import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';
import { LazyLoadEvent } from 'primeng/api';
import {
  ActiveFilterService,
  MetricsGridConfig,
  ColumnDefinitionService,
  BreadcrumbService,
  ClientProfileService,
  FormatService,
  GridTableNgComponent,
  GridSortingService,
} from './../core';
import { HeaderService } from './../header';
import { DownloadsService } from './../downloads';
import { RentalAssetService } from './rental-asset.service';
import { PageOptionsNg, SortOptionsNg, SortOptions } from '../models';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { Observable, BehaviorSubject } from 'rxjs';

@Component({
  templateUrl: 'rental-assets.component.html',
  styleUrls: ['rental-asset.component.scss'],
})
export class RentalAssetsComponent implements OnInit, OnDestroy {
  loading = true;
  data: any;
  gridConfig: MetricsGridConfig;
  frozenRows: any;
  paging = new PageOptionsNg();
  sorting: SortOptionsNg;
  totalCount = 0;
  frozenWidth: string;
  gridName: string = 'RENTALASSETS_GRID';
  private clientProfileServiceSubscription: Subscription;
  private subscriptions: Array<Subscription> = [];
  private selectedAsset: string;
  @ViewChild('grid') grid: GridTableNgComponent;
  constructor(
    private authenticationService: AuthenticationService,
    private filterService: ActiveFilterService,
    private columnService: ColumnDefinitionService,
    private breadcrumbService: BreadcrumbService,
    private headerService: HeaderService,
    private downloadsService: DownloadsService,
    private rentalAssetService: RentalAssetService,
    private clientProfileService: ClientProfileService,
    private formatService: FormatService,
    private gridSortingService: GridSortingService
  ) {}

  ngOnInit() {
    this.gridSortingService.setGridName(this.gridName);
    this.sorting = this.gridSortingService.getSortOptionsNg();

    this.loading = true;
    this.breadcrumbService.update([
      { title: 'main.tabs.equipment.rental_assets.all', class: 'active' },
    ]);
    this.configureGrid().subscribe((isconfigured) => {
      if (isconfigured === true) {
        this.load();
        this.subscriptions.push(
          this.filterService.filterChange.subscribe(() => {
            this.paging.page = 1;
            if (this.grid) {
              this.grid.resetPaginator();
            }
            this.load();
          })
        );
        this.subscriptions.push(
          this.authenticationService.userInfoView.subscribe((userInfo) => {
            if (this.gridConfig) {
              this.gridConfig.enableExcelExport =
                userInfo.HasClientAccessToExportData;
            }
          })
        );
      }
    });
  }

  ngOnDestroy() {
    while (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.pop().unsubscribe();
    }
  }

  load = () => {
    this.loading = true;

    this.rentalAssetService
      .getProductTypeEquipment(
        this.paging,
        this.getSorting(),
        this.selectedAsset
      )
      .subscribe((x) => {
        this.loading = false;

        if (x.Items && x.Items.length > 0) {
          this.data = x.Items;
          this.gridConfig.lastUpdated = x.Items[0].LastUpdatedDate || null;
        } else {
          this.data = [];
        }

        this.totalCount = x.TotalCount;
      });
  };

  changePage = (event: any) => {
    if (event.rows !== this.paging.pageSize) {
      this.paging.pageSize = event.rows;
    }

    this.paging.page = event.first < 1 ? 1 : event.first / event.rows + 1;
    this.load();
  };

  changeLazyLoad = (event: LazyLoadEvent) => {
    if (
      this.sorting.sortField === event.sortField &&
      this.sorting.sortOrder === event.sortOrder
    ) {
      return;
    }

    this.gridSortingService.setSortOption(
      event.sortField,
      event.sortOrder === -1
    );

    this.sorting.sortField =
      event.sortField || this.gridSortingService.defaultSortColumn;
    this.sorting.sortOrder = event.sortOrder || -1;

    this.load();
  };

  exportExcel = (args: any) => {
    this.loading = true;
    const translatedConfig = this.gridConfig.cloneAndTranslate((text) =>
      this.formatService.translateAndFormat(text, false)
    );
    this.subscriptions.push(
      this.rentalAssetService
        .getProductTypeEquipmentDownload(
          translatedConfig,
          this.getSorting(),
          this.selectedAsset
        )
        .subscribe((blob) => {
          this.loading = false;
          this.downloadsService.saveExcelBlob(blob);
        })
    );
  };

  private configureGrid = (): Observable<boolean> => {
    const result = new BehaviorSubject<boolean>(false);

    this.clientProfileServiceSubscription = this.clientProfileService
      .getClientAttributes()
      .subscribe((attributes) => {
        if (this.clientProfileServiceSubscription) {
          this.clientProfileServiceSubscription.unsubscribe();
        }

        const linkDsl = (v, r) => {
          const sorting = this.getSorting();
          const params = { sortOn: sorting.sortOn, desc: sorting.descending };
          return ['.', r.RouseEquipmentID, params];
        };

        let calculatedEquipmentLength = attributes.MaxEquipmentLen * 8;
        calculatedEquipmentLength =
          calculatedEquipmentLength < 120 ? 120 : calculatedEquipmentLength;

        let calculatedProductTypeLength = attributes.MaxProdTypeLen * 7.25;
        calculatedProductTypeLength =
          calculatedProductTypeLength < 175 ? 175 : calculatedProductTypeLength;

        this.frozenWidth = `${calculatedEquipmentLength}px`;

        this.gridConfig = new MetricsGridConfig(
          this.columnService.RentalAssetsColumnGroups(
            linkDsl,
            calculatedEquipmentLength,
            calculatedProductTypeLength
          ),
          null,
          true
        );
        result.next(true);
      });

    return result.asObservable();
  };

  private getSorting = (): SortOptions => {
    this.sorting.sortField = this.gridSortingService.getSortFieldOrDefault();
    this.sorting.sortOrder =
      this.gridSortingService.getSortDirectionOrDefault();
    return new SortOptions(
      this.sorting.sortField,
      !(this.sorting.sortOrder === 1)
    );
  };
}
