import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import {
    BranchComponent,
    RegionComponent,
    DistrictComponent,
    GeographyService
} from './index';
import { CoreModule } from './../core/core.module';
import { TrialGuardService } from '../core';

@NgModule({
    imports: [
        RouterModule.forChild([
            {   path: 'districts',
                component: DistrictComponent,
                data: { title: 'main.tabs.geography.districts.page_title' },
                canActivate: [TrialGuardService]
            }, {
                path: 'regions',
                component: RegionComponent,
                data: { title: 'main.tabs.geography.regions.page_title' },
                canActivate: [TrialGuardService]
            }, {
                path: 'branches',
                component: BranchComponent,
                data: { title: 'main.tabs.geography.branches.page_title' },
                canActivate: [TrialGuardService] }
        ]),
        CommonModule,
        CoreModule,
        TableModule,
        MultiSelectModule,
        PaginatorModule],
	providers: [
		GeographyService,
		TrialGuardService
    ],
	declarations: [
        RegionComponent,
        BranchComponent,
		DistrictComponent
    ]
})
export class GeographyModule { }
