import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

export enum RevenueSlice {
    AboveTq = 1,
    TqToAvg = 2,
    AvgToBq = 3,
    BelowBq = 4,
    Unbenchmarked = 5
}


@Injectable()
export class RevenueService {
    private sliceConfig: any = {};

    constructor(private router: Router) {
        this.sliceConfig[RevenueSlice.AboveTq] = {
            title: 'main.core.common.counts.transactions.breadcrumb_titles.above_top_quartile',
            params: { aboveTq: true }
        };
        this.sliceConfig[RevenueSlice.TqToAvg] = {
            title: 'main.core.common.counts.transactions.breadcrumb_titles.top_quartile_to_average',
            params: { tqToAvg: true }
        };
        this.sliceConfig[RevenueSlice.AvgToBq] = {
            title: 'main.core.common.counts.transactions.breadcrumb_titles.average_to_bottom_quartile',
            params: { avgToBq: true }
        };
        this.sliceConfig[RevenueSlice.BelowBq] = {
            title: 'main.core.common.counts.transactions.breadcrumb_titles.below_bottom_quartile',
            params: { belowBq: true }
        };
        this.sliceConfig[RevenueSlice.Unbenchmarked] = {
            title: 'main.core.common.counts.transactions.breadcrumb_titles.not_compared',
            params: { unbenchmarked: true }
        };
    }

    public getSliceDisplayTitle = (slice: RevenueSlice): string => {
        return this.sliceConfig[slice].title;
    }

    public getSliceParams = (slice: RevenueSlice): any => {
        return this.sliceConfig[slice].params;
    }
}
