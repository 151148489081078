﻿<rdo-breadcrumb></rdo-breadcrumb>
<div class="container-fluid" id="customer">
  <div class="row scroll-container">
	  <div class="rdo-col-card-fixed scroll"
	       infinite-scroll
	       [infiniteScrollThrottle]="500"
	       (scrolled)="getPaginatedCards()"
	       [scrollWindow]="false">
	    <div class="rdo-card-list">
        <rdo-nav-card-book 
          *ngFor="let c of customerCards; let baseId = index"
          [baseId]="baseId"
          [data]="c" [titleSelector]="'CustomerName'"
          [selected]="isSelected(c)"
          [icon]="'fa-building'"
          [comparisonMode]="mode"
          (selectedChange)="select(c)"
          [rdo-scroll-into]="isSelected(c)">
        </rdo-nav-card-book>
      </div>
    </div>

    <div class="rdo-col-card-offset scroll-container">
      <div class="row">
        <div class="col-xs-12">
          <h3>
            <i class="fa fa-building"></i>
            {{selectedCustomer?.CustomerName}}
          </h3>
        </div>
      </div>
      <div class="row margin-bottom-xl">
        <div class="col-xs-12">
          <ul class="nav nav-pills rdo-nav-pills">
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
              <a [routerLink]="['./']" id="customer-dashboard-tab"
              >{{'main.core.common.dashboard' | translate}}</a>
            </li>
            <li routerLinkActive="active">
              <a [routerLink]="['sales-reps']" id="customer-sales-reps-tab"
              >{{'main.core.common.counts.sales_reps.plural' | translate}}</a>
            </li>
            <li routerLinkActive="active">
              <a [routerLink]="['product-types']" id="customer-product-types-tab"
              >{{'main.core.common.counts.product_types.plural' | translate}}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row" id="customer-content">
        <div class="scroll-container">
          <div class="col-xs-12 scroll active">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>