import { catchUserErrors } from '../tools/catchUserErrors';
import { setDebugMode } from '../tools/monitor';
import { assign } from '../tools/utils/polyfills';
import { display } from '../tools/display';
export function makePublicApi(stub) {
  var publicApi = assign({
    version: "5.28.1",
    // This API method is intentionally not monitored, since the only thing executed is the
    // user-provided 'callback'.  All SDK usages executed in the callback should be monitored, and
    // we don't want to interfere with the user uncaught exceptions.
    onReady: function (callback) {
      callback();
    }
  }, stub);
  // Add a "hidden" property to set debug mode. We define it that way to hide it
  // as much as possible but of course it's not a real protection.
  Object.defineProperty(publicApi, '_setDebug', {
    get: function () {
      return setDebugMode;
    },
    enumerable: false
  });
  return publicApi;
}
export function defineGlobal(global, name, api) {
  var existingGlobalVariable = global[name];
  if (existingGlobalVariable && !existingGlobalVariable.q && existingGlobalVariable.version) {
    display.warn('SDK is loaded more than once. This is unsupported and might have unexpected behavior.');
  }
  global[name] = api;
  if (existingGlobalVariable && existingGlobalVariable.q) {
    existingGlobalVariable.q.forEach(function (fn) {
      return catchUserErrors(fn, 'onReady callback threw an error:')();
    });
  }
}
