import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { LocaleService } from '../services';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
    constructor(private localeService: LocaleService){ }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const langReq = req.clone({
            headers: req.headers.set('Accept-Language', this.localeService.getLocale())
        });
        return next.handle(langReq);
    }
}