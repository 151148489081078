import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SelectableClient } from '../models/selectableclient';

@Component({
    selector: 'app-client-selector',
    templateUrl: './client-selector.component.html',
    styleUrls: ['./client-selector.component.scss']
})

export class ClientSelectorComponent {

    public _selectableclients: Array<SelectableClient>; // = new Array(new SelectableClient(-9999, '(Rien)', '(pas de description court)'));
    private _selectedClientId: number = null;
  
    constructor(private router: Router,
                private dialogRef: MatDialogRef<ClientSelectorComponent>,
                @Inject(MAT_DIALOG_DATA) data) {

        this._selectableclients = data.SelectableClients;
        this._selectedClientId = data.SelectedClientId;
      
    }

    public selectMultiClient = () => {

        const selectedclient = this._selectableclients.find(i => i.ClientID === this._selectedClientId);

        this.dialogRef.close({ selectedClient: selectedclient });
    }

    public selectSetMultiClient = (clientid: number) => {

        this._selectedClientId = Number(clientid);

        const selectedclient = this._selectableclients.find(i => i.ClientID === this._selectedClientId);

        this.dialogRef.close({ selectedClient: selectedclient });
    }

    public clientSelectionChanged(client: any) {
        this._selectedClientId = Number(client);
    }

    public closeClick = () => {

        this.dialogRef.close({ selectedClient: null });
    }
}
