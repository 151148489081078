'use strict';

exports.__esModule = true;
exports.registerDefaultHelpers = registerDefaultHelpers;
exports.moveHelperToHooks = moveHelperToHooks;
// istanbul ignore next

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    'default': obj
  };
}
var _helpersBlockHelperMissing = require('./helpers/block-helper-missing');
var _helpersBlockHelperMissing2 = _interopRequireDefault(_helpersBlockHelperMissing);
var _helpersEach = require('./helpers/each');
var _helpersEach2 = _interopRequireDefault(_helpersEach);
var _helpersHelperMissing = require('./helpers/helper-missing');
var _helpersHelperMissing2 = _interopRequireDefault(_helpersHelperMissing);
var _helpersIf = require('./helpers/if');
var _helpersIf2 = _interopRequireDefault(_helpersIf);
var _helpersLog = require('./helpers/log');
var _helpersLog2 = _interopRequireDefault(_helpersLog);
var _helpersLookup = require('./helpers/lookup');
var _helpersLookup2 = _interopRequireDefault(_helpersLookup);
var _helpersWith = require('./helpers/with');
var _helpersWith2 = _interopRequireDefault(_helpersWith);
function registerDefaultHelpers(instance) {
  _helpersBlockHelperMissing2['default'](instance);
  _helpersEach2['default'](instance);
  _helpersHelperMissing2['default'](instance);
  _helpersIf2['default'](instance);
  _helpersLog2['default'](instance);
  _helpersLookup2['default'](instance);
  _helpersWith2['default'](instance);
}
function moveHelperToHooks(instance, helperName, keepHelper) {
  if (instance.helpers[helperName]) {
    instance.hooks[helperName] = instance.helpers[helperName];
    if (!keepHelper) {
      delete instance.helpers[helperName];
    }
  }
}
