import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'rdoPredicate'
})
export class RdoPredicatePipe implements PipeTransform {
	transform = (items: any[], condition: string, compareValue: any, defaultReturnValue: boolean = false): any[] => {
        let predicate = (source, valueToCompare): boolean => { return defaultReturnValue; };

		switch (condition) {
			case '>':
				predicate = (source: any, valueToCompare: any) => {
					return source > valueToCompare;
				}
				break;
			case '>=':
				predicate = (source: any, valueToCompare: any) => {
					return source >= valueToCompare;
				}
				break;
			case '<':
				predicate = (source: any, valueToCompare: any) => {
					return source < valueToCompare;
				}
				break;
			case '<=':
				predicate = (source: any, valueToCompare: any) => {
					return source < valueToCompare;
				}
				break;
			case '=':
				predicate = (source: any, valueToCompare: any) => {
					return source === valueToCompare;
				}
				break;
		}

		return items.filter(x => predicate(x, compareValue));
	}
}