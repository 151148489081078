import {
  Component,
  Input,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import * as _ from 'lodash';

import { HighchartsConfiguration, RevenueChartHelperService } from './../base';
import { FormatService } from './../../query';
import { MathService } from './../../math';
import { RateChangeResult, RateChangeType } from './rate-change-data';
import { TranslateService } from '@ngx-translate/core';
import { AngularRouterTracking } from 'angulartics2';
import { RateChangeBaseChart } from './rate-change-base-chart';
import { CHART_COLORS } from '../base/chart-color-constants';
import { LocaleService } from '../../services';

enum LEGEND_TYPE {
  CLIENT = 'client',
  BENCHMARK = 'benchmark',
}

@Component({
  selector: 'rdo-rate-change-chart',
  styleUrls: ['./../base/revenue-chart-helper.scss'],
  templateUrl: 'rate-change-chart.component.html',
})
export class RateChangeChartComponent
  extends RateChangeBaseChart
  implements OnChanges
{
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('chart-data') data: Array<RateChangeResult>;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('rate-change-type') rateChangeType: number;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('dashed-line-offset') dashedLineOffset: number;
  private chart: any;
  private I18N_PATH = 'main.tabs.summary.charts.client_rate_change.vs_bench';
  private chartConfig: HighchartsConfiguration = new HighchartsConfiguration();
  private colors = [
    '#385723',
    '#E2F0D9',
    '#FFCDCD',
    '#8A0000',
    '#EEEEEE',
    'rgba(167, 167, 167, 0.3)',
    '#000000',
    'rgba(167, 167, 167, 0.2)',
    'rgba(0, 0, 0, 0.2)',
  ];
  private chartColor1 = 'rgba(153, 168, 189,1)';
  private chartColor1Transparent = 'rgba(153, 168, 189,0.2)';
  private chartColor2 = 'rgba(153, 168, 189,0.3)';
  private chartColor2Transparent = 'rgba(153, 168, 189,0.1)';

  constructor(
    private element: ElementRef,
    private mathService: MathService,
    private formatService: FormatService,
    private translateService: TranslateService,
    private revUtilChartService: RevenueChartHelperService,
    private localeService: LocaleService
  ) {
    super();
    this.setupChartTranslations();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.data !== null && this.rateChangeType !== null) {
      this.loadChart();
    }
  }

  private setupChartTranslations() {
    this.translateService.stream(this.I18N_PATH).subscribe((i18n) => {
      if (this.chart) {
        const newText =
          this.rateChangeType === RateChangeType.Monthly
            ? i18n.mom_rate_change
            : i18n.yoy_rate_change;
        this.chart.yAxis[0].update({ title: { text: newText } });
        this.chart
          .get(LEGEND_TYPE.CLIENT)
          .update({ name: i18n[LEGEND_TYPE.CLIENT] });
        this.chart
          .get(LEGEND_TYPE.BENCHMARK)
          .update({ name: i18n[LEGEND_TYPE.BENCHMARK] });
      }
    });
  }

  private loadChart = () => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;
    const config = new HighchartsConfiguration();
    const options = {
      chart: {
        height:
          component.element.nativeElement.parentElement.offsetHeight <= 0
            ? 280
            : component.element.nativeElement.parentElement.offsetHeight,
        width: component.element.nativeElement.parentElement.offsetWidth,
        type: 'column',
      },
      colors: component.colors,
      yAxis: {
        labels: {
          format: '{value}%',
        },
        minPadding: 0.2,
        title: {
          ...component.formatYAxisTitle(
            component.rateChangeType === RateChangeType.Monthly
              ? component.translateService.instant(
                  component.I18N_PATH + '.mom_rate_change'
                )
              : component.translateService.instant(
                  component.I18N_PATH + '.yoy_rate_change'
                )
          ),
          style: {
            fontSize: '12px',
          },
        },
        minorGridLineWidth: 0,
      },
      xAxis: {
        categories: component.data as Array<any>,
        labels: {
          useHTML: true,
          formatter: function () {
            const locale = component.localeService.getLocale();
            return component.revUtilChartService.getShortDate(
              this.value.Month,
              locale
            );
          },
        },
        plotLines: [
          {
            color: CHART_COLORS.COLOR_000000,
            width: 2,
            dashStyle: 'Dash',
            value: component.data.length - (component.dashedLineOffset + 0.5), // dashed line at the carryover months.
          },
        ],
      },
      plotOptions: {
        column: {
          events: {
            click: (e) => {
              this.revUtilChartService.selectMonthFromChart(e.point.category);
            },
          },
        },
      },
      legend: {
        itemHiddenStyle: {
          symbolOpacity: 0.3,
          color: CHART_COLORS.TRANSPARENCY,
          textDecoration: 'none',
        },
      },
      series: [
        {
          id: LEGEND_TYPE.CLIENT,
          name: component.translateService.instant(
            this.I18N_PATH + '.' + LEGEND_TYPE.CLIENT
          ),
          pointPlacement: -0.05,
          color: CHART_COLORS.COLOR_5779A3,
          data: component.data.map((obj) => {
            const percent =
              component.rateChangeType === RateChangeType.Monthly
                ? obj.RateChangeMoMBenchmarked * 100
                : obj.RateChangeYoYBenchmarked * 100;

            if (component.revUtilChartService.isCurrentCategory(obj)) {
              return {
                y: percent,
                color: CHART_COLORS.COLOR_5779A3,
                borderColor: CHART_COLORS.COLOR_000000,
                borderWidth: 3,
                selected: true,
              };
            }

            return {
              y: percent,
            };
          }),
          states: {
            select: {
              color: CHART_COLORS.COLOR_5779A3,
            },
          },
          point: {
            events: {
              // mouseOver: function () {
              // 	if (!this.selected) {

              // 		this.graphic.attr({
              // 			stroke: 'black',
              // 			'stroke-width': 3,
              // 			color: CHART_COLORS.COLOR_5779A3,
              // 		});
              // 	}
              // },
              // mouseOut: function () {
              // 	if (!this.selected) {

              // 		this.graphic.attr({
              // 			stroke: '',
              // 			'stroke-width': 0,
              // 			color: CHART_COLORS.COLOR_5779A3,
              // 		});

              // 	}
              // },
              click: function () {
                this.selected = !this.selected;

                this.graphic.attr({
                  color: CHART_COLORS.COLOR_5779A3,
                  stroke: 'black',
                  'stroke-width': 3,
                });
              },
            },
          },
          dataLabels: {
            enabled: true,
            crop: false,
            overflow: 'none',
            allowOverlap: true,
            style: {
              z: '1 !important',
            },
            formatter: function () {
              const item = _.find(
                component.data,
                (i) => i.Month === this.x.Month
              );
              const percent =
                component.rateChangeType === RateChangeType.Monthly
                  ? item.RateChangeMoMBenchmarked
                  : item.RateChangeYoYBenchmarked;
              if (this.y < 0) {
                return `<div class="client-benchmark-data-label"><span class='text-danger' style="font-weight:normal;font-size:11px;">${component.formatService.formatPercent(percent)}</span></div>`;
              } else {
                return `<div class="client-benchmark-data-label">
                                    <span style="font-weight:normal; color: #4685BB;font-size:11px;">
									<td>${component.formatService.formatPercent(percent)}</td>
                                    </span></div>`;
              }
            },
            useHTML: true,
          },
        },
        {
          id: LEGEND_TYPE.BENCHMARK,
          name: component.translateService.instant(
            this.I18N_PATH + '.' + LEGEND_TYPE.BENCHMARK
          ),
          pointPlacement: 0.05,
          color: CHART_COLORS.COLOR_595959,
          data: component.data.map((obj) => {
            const percent =
              component.rateChangeType === RateChangeType.Monthly
                ? obj.RateChangeMoMBench * 100
                : obj.RateChangeYoYBench * 100;

            if (component.revUtilChartService.isCurrentCategory(obj)) {
              return {
                y: percent,
                color: CHART_COLORS.COLOR_595959,
                borderColor: CHART_COLORS.COLOR_000000,
                borderWidth: 3,
                selected: true,
              };
            }

            return {
              y: percent,
            };
          }),
          states: {
            select: {
              color: CHART_COLORS.COLOR_595959,
            },
          },
          point: {
            events: {
              click: function () {
                this.selected = !this.selected;

                this.graphic.attr({
                  color: CHART_COLORS.COLOR_595959,
                  stroke: 'black',
                  'stroke-width': 3,
                });
              },
            },
          },
          dataLabels: {
            enabled: true,
            crop: false,
            overflow: 'none',
            allowOverlap: true,
            style: {
              z: '1 !important',
            },
            formatter: function () {
              const item = _.find(
                component.data,
                (i) => i.Month === this.x.Month
              );
              const percent =
                component.rateChangeType === RateChangeType.Monthly
                  ? item.RateChangeMoMBench
                  : item.RateChangeYoYBench;

              if (this.y < 0) {
                return `<div class="client-benchmark-data-label"><span class='text-danger' style="font-weight:normal;font-size:11px;">${component.formatService.formatPercent(percent)}</span></div>`;
              } else {
                return `<div class="client-benchmark-data-label">
                                    <span style="font-weight:normal;font-size:11px;">
                                    <td>${component.formatService.formatPercent(percent)}</td>
                                    </span></div>`;
              }
            },
            useHTML: true,
          },
        },
      ],
      tooltip: {
        backgroundColor: null,
        borderWidth: 0,
        shadow: false,
        style: {
          padding: 0,
        },
        positioner: function (boxWidth, boxHeight, point) {
          return component.revUtilChartService.getTooltipPositionClientRateBenchmark(
            point
          );
        },
        formatter: function () {
          const chartItem = _.find(
            component.data,
            (i) => i.Month === this.x.Month
          );
          const percentBench =
            component.rateChangeType === RateChangeType.Monthly
              ? chartItem.RateChangeMoMBench
              : chartItem.RateChangeYoYBench;
          const percentClient =
            component.rateChangeType === RateChangeType.Monthly
              ? chartItem.RateChangeMoMBenchmarked
              : chartItem.RateChangeYoYBenchmarked;
          const percentDifference = percentClient - percentBench;
          const markup = `<div class="client-rate-bench-chart">
					<table class="table rdo-table-tooltip" style="text-align: right;">
						<tr>
						<th></th>
						<th>${
              component.rateChangeType === RateChangeType.Monthly
                ? component.translateService.instant(
                    component.I18N_PATH + '.mom_rate_change'
                  )
                : component.translateService.instant(
                    component.I18N_PATH + '.yoy_rate_change'
                  )
            }</th>
						</tr>
						<tr>
						<td style="text-align: left;"><span style="color:${component.chartColor1}">&#x25CF</span> ${component.translateService.instant(component.I18N_PATH + '.client_compared')}:</td>
						<td class='${percentClient < 0 ? 'text-danger' : ''}'>${component.formatService.formatPercent(percentClient)}</td>
						</tr>
						<tr>
						<td style="text-align: left;"><span style="color:${component.colors[6]}">&#x25CF</span> ${component.translateService.instant(component.I18N_PATH + '.benchmark')}:</td>
						<td class='${percentBench < 0 ? 'text-danger' : ''}'>${component.formatService.formatPercent(percentBench)}</td>
						</tr>
						<tr></tr>
						<tr>
						<td style="text-align: left;"><span class='${percentDifference < 0 ? 'text-danger' : ''}'>&#x25CF</span> ${component.translateService.instant(component.I18N_PATH + '.difference')}:</td>
						<td class='${percentDifference < 0 ? 'text-danger' : ''}'>${component.formatService.formatPercent(percentDifference)}
							${percentDifference === null ? '' : Math.abs(percentDifference) > 0.01 ? component.translateService.instant('main.core.common.counts.pts') : component.translateService.instant('main.core.common.counts.pts')}
							</td>
						</tr>
					</table>
					</div>`;

          return markup;
        },
        shared: true,
        useHTML: true,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
    };
    config.exportingOptions = {
      getColumnsConfig: () => {
        /*
					 Month, Client Compared MoM Rate Change, Benchmark MoM Rate Change, Difference
					  */
        return [
          {
            field: 'Month',
            displayName: component.translateService.instant(
              'main.core.charts.export.month'
            ),
            customFormat: (rowValues) => {
              return component.revUtilChartService.getShortDate(
                rowValues.Month
              );
            },
          },
          {
            field: 'ClientComparedRateChange',
            displayName:
              component.rateChangeType === RateChangeType.Monthly
                ? component.translateService.instant(
                    'main.core.charts.export.client_compared_mom_rate_change'
                  )
                : component.translateService.instant(
                    'main.core.charts.export.client_compared_yoy_rate_change'
                  ),
            customFormat: (rowValues) =>
              component.rateChangeType === RateChangeType.Monthly
                ? rowValues.RateChangeMoMBenchmarked
                : rowValues.RateChangeYoYBenchmarked,
            isPercent: true,
          },
          {
            field: 'BenchmarkRateChange',
            displayName:
              component.rateChangeType === RateChangeType.Monthly
                ? component.translateService.instant(
                    'main.core.charts.export.bench_mom_rate_change'
                  )
                : component.translateService.instant(
                    'main.core.charts.export.bench_yoy_rate_change'
                  ),
            customFormat: (rowValues) =>
              component.rateChangeType === RateChangeType.Monthly
                ? rowValues.RateChangeMoMBench
                : rowValues.RateChangeYoYBench,
            isPercent: true,
          },
          {
            field: 'Difference',
            displayName: component.translateService.instant(
              'main.core.charts.export.difference_percent_pts'
            ),
            customFormat: (rowValues) => {
              const percentBench =
                component.rateChangeType === RateChangeType.Monthly
                  ? rowValues.RateChangeMoMBench
                  : rowValues.RateChangeYoYBench;
              const percentClient =
                component.rateChangeType === RateChangeType.Monthly
                  ? rowValues.RateChangeMoMBenchmarked
                  : rowValues.RateChangeYoYBenchmarked;
              return percentClient - percentBench;
            },
            isPercent: true,
          },
        ];
      },
      getFileName: () =>
        this.rateChangeType === RateChangeType.Monthly
          ? this.translateService.instant(
              'main.tabs.summary.charts.client_rate_change.vs_bench.mom_title'
            )
          : this.translateService.instant(
              'main.tabs.summary.charts.client_rate_change.vs_bench.yoy_title'
            ),
    };
    config.options = options;
    config.onLoadCallback = (chart) => {
      this.chart = chart;
    };
    this.chartConfig = config;
  };
}
