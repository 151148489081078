import { Pipe, PipeTransform } from '@angular/core';
import { FormatService } from '../../core/query';

@Pipe({
    name: 'rdoNumber'
})
export class RdoNumberPipe implements PipeTransform {
    constructor(private formatService: FormatService) { }

    // eslint-disable-next-line id-denylist
    transform = (number: any, precision: number): any => {
        return this.formatService.formatNumber(number, precision);
    }
}
