import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CoreModule } from './../core/core.module';
import { TrialGuardService } from '../core';
import {
	RentalAssetBaseComponent,
	RentalAssetsComponent,
	RentalAssetComponent,
	RentalAssetDashboardComponent,
	RentalAssetService,
	SelectedAssetService
} from './index';

const root = 'rental-assets';
const rentalAssetRoutes: Routes = [
	{
		path: root,
		component: RentalAssetsComponent,
		data: { title: 'main.tabs.equipment.rental_assets.page_titles.root' },
		canActivate: [TrialGuardService]
	},
	{	path: root + '/:asset', 
		component: RentalAssetComponent,
		canActivate: [TrialGuardService],
		children: [
			{	path: '',
				component: RentalAssetDashboardComponent,
				data: { name: 'dashboard',
						title: 'main.tabs.equipment.rental_assets.page_titles.dashboard' }
			}
		]
	}
];

@NgModule({
	imports: [
		RouterModule.forChild(rentalAssetRoutes),
		CommonModule,
		CoreModule,
		InfiniteScrollModule
	],
	declarations: [
		RentalAssetBaseComponent,
		RentalAssetsComponent,
		RentalAssetComponent,
		RentalAssetDashboardComponent
	],
	providers: [
		RentalAssetService,
		SelectedAssetService
	]
})
export class RentalAssetsModule { }
