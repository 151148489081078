import { Subject } from './Subject';
import { Subscription } from './Subscription';
export class AsyncSubject extends Subject {
  constructor() {
    super(...arguments);
    this.value = null;
    this.hasNext = false;
    this.hasCompleted = false;
  }
  _subscribe(subscriber) {
    if (this.hasError) {
      subscriber.error(this.thrownError);
      return Subscription.EMPTY;
    } else if (this.hasCompleted && this.hasNext) {
      subscriber.next(this.value);
      subscriber.complete();
      return Subscription.EMPTY;
    }
    return super._subscribe(subscriber);
  }
  next(value) {
    if (!this.hasCompleted) {
      this.value = value;
      this.hasNext = true;
    }
  }
  error(error) {
    if (!this.hasCompleted) {
      super.error(error);
    }
  }
  complete() {
    this.hasCompleted = true;
    if (this.hasNext) {
      super.next(this.value);
    }
    super.complete();
  }
}
