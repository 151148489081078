<div class="guided-tours-container" id="guided-tours" *ngIf="showGuidedTours">
    <!-- Toggle Button -->
    <div class="guided-tours-toggle" *ngIf="!showContent" (click)="showContent = true" id="guided-tours-show-btn">
        <span class="toggle-title">{{'main.core.guided-tours.management.menu_name' | translate}}</span>
        <i class="fa fa-arrow-left toggle-icon" aria-hidden="true"></i>
    </div>

    <!-- Toggled popup -->
    <div class="guided-tours-content" *ngIf="showContent">
        <div class="guided-tours-content-title">{{'main.core.guided-tours.management.menu_name' | translate}}</div>
        <div class="guided-tours-content-description">{{'main.core.guided-tours.management.menu_description' | translate}}</div>
        <ul class="guided-tours-content-list">
            <li class="listed-guide" *ngFor="let guide of availableGuides" (click)="triggerGuide(guide)">
                {{guide.translatedName}}
            </li>
        </ul>
    </div>
    <div class="guided-tours-toggle" (click)="showContent = false" *ngIf="showContent"  id="guided-tours-hide-btn">
        <span class="toggle-title">{{'main.core.guided-tours.management.minimize' | translate}}</span>
        <i class="fa fa-arrow-right toggle-icon" aria-hidden="true"></i>
    </div>
</div>
