import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { zip } from 'rxjs/observable/zip';
import * as _ from 'lodash';
import * as filterFunctions from './../filter/functions/filter.functions';
import { ActiveFilterService, BreadcrumbService, ViewService, ComparisonMode, ChartType, ChartSwitcher, ClientProfileService, ChartSwitchingService, ChartDisplayer } from '../core';
import { CustomerBaseComponent } from './customer-base.component';
import { SelectedCustomerService } from './selected-customer.service';
import { CustomerService } from './customer.service';
import { RevenueService, RevenueSlice } from '../summary';
import { ChartData, TopItemsData, DashboardCardType, TxAttributes, DashboardCardSetData } from '../models';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { FilterProfileService } from '../filter/profiles/filter-profile.service';
import { CODENAMES } from '../core/constants';

@Component({
	templateUrl: 'customer-dashboard.component.html',
	styleUrls: ['customer.component.scss', '../../styles/_summary-charts.scss']
})

export class CustomerDashboardComponent extends CustomerBaseComponent implements OnInit, OnDestroy {

	private selectedCustomer: any;
	private chartData = new ChartData({ data: [], loading: false });
	private dashboardCardData: DashboardCardSetData;
	private loading = true;
	private productTypes: TopItemsData = new TopItemsData();
	private salesReps: TopItemsData = new TopItemsData();
	private rateTypes: TopItemsData = new TopItemsData();
	private comparisonMode = ComparisonMode;
	private mode: ComparisonMode = ComparisonMode.Benchmark;
	private chartSwitcher: ChartSwitcher = new ChartSwitcher(ChartType.None);
	private clientAttributes = new TxAttributes(<TxAttributes>{ IsTrial: true });
	private monthsToReturn = 6;

    constructor(
		private router: Router,
		private route: ActivatedRoute,
		private viewService: ViewService,
		private revenueService: RevenueService,
		private customerService: CustomerService,
		private filterService: ActiveFilterService,
		private translateService: TranslateService,
		private authenticationService: AuthenticationService,
		private chartSwitchingService: ChartSwitchingService,
		public breadcrumbService: BreadcrumbService,
		public selectedCustomerService: SelectedCustomerService,
		protected clientProfileService: ClientProfileService,
		private filterProfileService: FilterProfileService 
	) {
		super(selectedCustomerService, breadcrumbService);
	}

    ngOnInit() {
		// eslint-disable-next-line
        const component = this;
        component.subscriptions.push(
            component.clientProfileService.getClientAttributes().subscribe(
                attributes => component.clientAttributes = attributes
            )
        );
        this.subscriptions.push(this.filterProfileService.wait4CurrentProfile().subscribe(() => {
            const filterValue = this.filterProfileService.readClientProfileProperty(CODENAMES.CN_MONTHS_TO_RETURN);
            this.monthsToReturn = filterValue ? filterValue : 6;
         }));
        component.subscriptions.push(
            component.selectedCustomerService.Customer.subscribe(c => {
                component.selectedCustomer = c;
                component.load();
                component.subscriptions.push(component.selectedCustomerService.customerChange.subscribe(x => {
                    if (component.selectedCustomer.CustomerId !== x.CustomerId) { // load only if differrent customer selected.
                        component.selectedCustomer = x;
                        component.load();
                    }
                }));
            }));
        component.subscriptions.push(
            component.filterService.filterChange.subscribe(component.load)
        );
    }

    ngOnDestroy() {
		super.ngOnDestroy();
	}

    private load = () => {
        this.loading = true;
        this.subscriptions.push(this.filterProfileService.wait4CurrentProfile().subscribe(() => {
			const filterValue = this.filterProfileService.readClientProfileProperty(CODENAMES.CN_MONTHS_TO_RETURN);
            this.monthsToReturn = filterValue ? filterValue : 6;
            this.updateBreadcrumbs([{ title: 'main.core.common.dashboard', class: 'active' }]);
            const customerId = this.selectedCustomer.CustomerId;
            this.loadChart(customerId);
            zip(
                this.customerService
                    .getDashboardCardData(customerId)
                    .pipe(map(d => <DashboardCardSetData>d.Items[0])),
                this.customerService
                    .getTopSalesReps(customerId)
                    .pipe(map(p => <TopItemsData>{ data: p })),
                this.customerService
                    .getRateTypes(customerId)
                    .pipe(map(p => <TopItemsData>{ data: p })),
                this.customerService
                    .getTopProductTypes(customerId)
                    .pipe(map(p => <TopItemsData>{ data: p })))
                .subscribe((results: [DashboardCardSetData, TopItemsData, TopItemsData, TopItemsData]) => {
                    this.dashboardCardData = results[0];
                    this.salesReps = results[1];
                    this.rateTypes = results[2];
                    this.productTypes = results[3];
                this.loading = false;
            });
        }));
	}

    private loadChart = (customerId: number) => {
		this.chartData = new ChartData({ data: [], loading: true });
		zip(
			this.customerService
				.getRevenueByMonthData(customerId, this.monthsToReturn)
				.pipe(map(d => <ChartData>{ data: d, loading: false })),
			this.viewService.getComparisonMode())
			.subscribe((results: [ChartData, ComparisonMode]) => {
				this.chartData = results[0];
				this.mode = results[1];
				
				this.chartSwitcher = this.chartSwitchingService.getCurrentChart(
					ChartDisplayer.CUSTOMERS,
					this.mode,
					this.chartSwitcher,
					null);
				this.subscriptions.push(this.chartSwitcher.chartChanged.subscribe(chart => {
					this.chartSwitchingService.setCurrentChart(ChartDisplayer.CUSTOMERS, chart);
				}));
			});
	}

	private onSliceClick = (slice: RevenueSlice) => {
		const sliceParams = this.revenueService.getSliceParams(slice);
		const sliceName = this.revenueService.getSliceDisplayTitle(slice);
		this.updateBreadcrumbs([
			{
				title: sliceName,
				class: 'active'
			}]);
		const urlTree = this.router.createUrlTree(['transactions', sliceParams], { relativeTo: this.route });
		this.router.navigateByUrl(urlTree);
	}

	onCardClicked = (cardType: DashboardCardType) => {
		switch (cardType) {
			case DashboardCardType.ClientRevenue:
				this.chartSwitcher.selectRevenueBookChart();
				break;
			case DashboardCardType.ClientRateVsBenchmark:
				this.chartSwitcher.selectRevenueBenchChart();
				break;
			case DashboardCardType.TopQuartile:
			case DashboardCardType.BottomQuartile:
				this.chartSwitcher.selectTotalRevenueVsBenchmarkChart();
				break;
			default:
				break;
		}
	}

	getSelectedChartName() {
		return this.chartSwitcher.translatedCurrent(text => this.translateService.instant(text));
	}
}
