import { Component, ElementRef, Input } from '@angular/core';
import { SvgService } from '../../../core/query';

@Component({
    selector: 'rdo-category-icon',
    styleUrls: ['./category-icon.component.scss'],
    template: '<span class="icon-category" [class.default-category-icon]="isDefaultIcon" [ngClass]="{loading:hasLoadingClass}"></span>'
})
export class CategoryIconComponent {
    private static availableCategories: string[] = [
        'aggregate-equipment',
        'agricultural-equipment',
        'air-compressors',
        'air-equipment',
        'air-tools',
        'all-terrain-cranes',
        'articulated-dump-trucks',
        'articulating-booms',
        'articulating-boom-lifts',
        'backhoe-loaders',
        'boom-trucks-bucket-trucks-and-digger-derricks',
        'box-trailers',
        'carry-deck-and-pick-and-carry-cranes',
        'compact-track-loader',
        'compact-track-loaders',
        'compaction-equipment-ride-on',
        'concrete-equipment',
        'crawler-cranes',
        'crawler-loaders',
        'double-drum-rollers',
        'dozers',
        'dump-trailers',
        'dump-trucks',
        'earthmoving-attachments',
        'electric-tools',
        'engines',
        'excavators',
        'forestry-equipment',
        'forklift-trucks',
        'fuel-tank-and-vacuum-trailers',
        'gear',
        'generators',
        'heavy-earthmoving-attachments',
        'hvac',
        'hydraulic-tools',
        'ladders',
        'lawn-and-landscape',
        'light-compaction',
        'light-towers',
        'light-vehicles',
        'lighting-equipment',
        'material-handling',
        'mini-dumpers-and-loaders',
        'motor-graders',
        'office-trailers',
        'other-forklifts',
        'other-trailers',
        'other-trucks',
        'paving-equipment',
        'pickup-trucks',
        'pneumatic-rollers',
        'power-equipment',
        'pumps',
        'rough-terrain-forklifts',
        'rough-terrain-cranes',
        'scaffolding-and-staging',
        'scissor-lifts',
        'scrapers',
        'semi-trailers',
        'service-trucks',
        'single-drum-rollers',
        'skid-steer-loaders',
        'skid-steers',
        'soil-and-landfill-compactors',
        'storage-containers',
        'surface-treatment',
        'sweepers-and-brooms',
        'tag-along-trailers',
        'tanks-and-boxes',
        'telehandlers',
        'telescopic-booms',
        'telescopic-boom-lifts',
        'towable-boom-lifts',
        'tower-cranes',
        'track-driven-equipment',
        'tractors',
        'traffic-control',
        'trailers',
        'transport-trucks',
        'trench-shoring',
        'trenching-equipment',
        'truck-cranes',
        'vehicles',
        'vertical-mast-lifts',
        'water-trailers',
        'water-trucks',
        'welders',
        'welding-tools',
        'wheel-dozers',
        'wheel-dumpers',
        'wheel-excavators',
        'wheel-loaders'
    ];
    hostElement: ElementRef;
    @Input()
    set loading(val: boolean) {
        this._loading = val;
        if (val) {
            this.hasLoadingClass = true;
        }
    }
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('category')
    set categoryName(category: string) {
        const decodedCat = decodeURIComponent(category || '');
		const cat = decodedCat.replace(/,/g, '').replace(/\W+/g, '-').toLowerCase().trim();
        let validatedCat;
        if (CategoryIconComponent.availableCategories.indexOf(cat) !== -1) {
            this.isDefaultIcon = false;
            validatedCat = cat;
        } else {
            this.isDefaultIcon = true;
            validatedCat = 'gear';
        }
        this.svgService.getIconMarkup(`assets/images/icons/${validatedCat}.svg`, this.defaultIconUrl).subscribe(markup => {

            const node = this.hostElement.nativeElement.firstChild;
            while (node.firstChild) {
                node.removeChild(node.firstChild);
            }
            const endAnimation = () => {
                this.hasLoadingClass = this._loading;
            };

            if (markup != null) {
                node.appendChild(markup);
                markup.addEventListener('webkitAnimationIteration', endAnimation);
                markup.addEventListener('animationiteration', endAnimation, false);
            }
        });
    }

    private isDefaultIcon = false;
    private defaultIconUrl = 'assets/images/icons/gear.svg';
    private _loading: boolean;
    private hasLoadingClass: boolean;
    constructor(ele: ElementRef, private svgService: SvgService) {
        this.hostElement = ele;
    }
}

