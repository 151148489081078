import { Router, RouterLink }   from '@angular/router';
import { Location } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';

import { ComparisonMode } from './../../../query';
import { LoaderComponent } from './../../loader';
import { AmountDifferenceComponent } from './../../amount-difference';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'rdo-top-list',
    templateUrl: 'top-list.component.html',
    styleUrls: ['./../list.scss', 'top-list.component.scss']
})
export class TopListComponent {

    @Input() comparisonMode: ComparisonMode;

    @Input()
    public title: string;

    @Input()
    public items: Array<any>;

    @Input()
    public loading: boolean;

    @Input()
    public linkUrl: (item: any) => string;

    @Input()
    public nameSelector: string;

    @Output()
    linkClick: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    footerLinkDsl: Array<any>;

    @Input()
    footerLinkTitle: string;

    constructor(
        private router: Router, private location: Location,
        private translateService: TranslateService
    ) { }

    handleClick(item: any) {
        this.linkClick.next(item);
        return false;
    }

    private isBenchmarkMode(): boolean {
        return this.comparisonMode === ComparisonMode.Benchmark;
    }

    getFooterLinkTitle(footerLinkTitle: any) {
		if (footerLinkTitle) {
			return this.translateService.instant(footerLinkTitle);
		} else {
			return this.translateService.instant('main.tabs.summary.tables.all');
		}
	}
}
