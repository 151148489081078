<div *ngIf="showAppGuidancePopUp()">
    <div class="black-background black-spinner" *ngIf="saving"></div>

    <div class="black-background"></div>

    <div class="dialog-container-border">
        <div class="dialog-close-btn-border" (click)="closeDialog()">
            <div class="dialog-close-btn">
                <i class="fa fa-close"></i>
            </div>
        </div>
        <div class="dialog-container">
            <div class="guidance-settings-container">
                <div class="guidance-settings-title">
                    {{'main.core.guided-tours.settings.title' | translate}}
                </div>
                <div class="guidance-settings-body">
                    <div class="guidance-settings-body-text">
                        <div class="guidance-settings-body-title">
                            {{'main.core.guided-tours.settings.sub-title' | translate}}
                        </div>
                        <div class="guidance-settings-body-text">
                            {{'main.core.guided-tours.settings.content' | translate}}
                        </div>
                    </div>
                    <div class="yes-no-container">
                        <div [class]="!allowGuidedTours ? 'yes-no-btn no-btn selected-yes-no-btn' : 'yes-no-btn no-btn'"
                            (click)="allowGuidedTours = false">
                            {{'main.core.guided-tours.settings.no' | translate}}
                        </div>
                        <div [class]="allowGuidedTours ? 'yes-no-btn yes-btn selected-yes-no-btn' : 'yes-no-btn yes-btn'"
                            (click)="allowGuidedTours = true">
                            {{'main.core.guided-tours.settings.yes' | translate}}
                        </div>
                    </div>
                </div>
                <div class="guidance-settings-button-container">
                    <div (click)="onSubmitPreferences()" class="submit-guidance-settings-btn" >
                        {{'main.core.guided-tours.settings.submit' | translate}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

