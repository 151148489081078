<ng-template #movable>
    <div class="moveable-column-arrows__container">
        <div class="moveable-column-arrows__arrow-container" *ngIf="!isLeftMost">
            <button (click)="onClick('left', $event)" class="moveable-column-arrows__arrow arrow--left">
                <i class="fa fa-sort-asc"></i>
            </button>
        </div>
        <div class="moveable-column-arrows__content">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
        <div class="moveable-column-arrows__arrow-container">
            <button *ngIf="!isRightMost" (click)="onClick('right', $event)" class="moveable-column-arrows__arrow arrow--right">
                <i class="fa fa-sort-asc"></i>
            </button>
        </div>
    </div>
</ng-template>
<ng-template #unmovable>
    <div class="moveable-column-arrows__container moveable-column-arrows__container--static">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
</ng-template>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>

<ng-template *ngTemplateOutlet="!column?.isMovable || !isActive() ? unmovable : movable"></ng-template>



