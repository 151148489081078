import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { MetricsGridConfig } from './MetricsGridConfig';
import { IGridColumnGroup } from './IGridColumnGroup.interface';
import { RowExpandArgs } from './RowExpandArgs.interface';
import { IColumnConfig } from './IColumnConfig.interface';
import { IGridRow } from './IGridRow.interface';
import { IDataRow } from './IDataRow.interface';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { FormatService } from '../query';
import { ColumnDefinitionService } from './column-definition.service';
import { GridSortingService, LocaleService } from '../services';

@Component({
  selector: 'rdo-grid-table',
  templateUrl: './grid-table.html',
})
export class GridTableComponent {
  gridData: Array<IGridRow>;

  @Output()
  checkedRows: Array<boolean> = [true];

  @Input()
  expanded: boolean = false;

  // eslint-disable-next-line
  @Input('title')
  tableTitle: string;

  @Input()
  headerStyle: any;

  @Input()
  set data(value: Array<IDataRow>) {
    this.rawData = value;
    this.prepare();
  }
  @Input()
  sortInProgress: boolean;
  @Input()
  sortOn: string;

  @Output()
  sortOnChange: EventEmitter<IColumnConfig> = new EventEmitter<IColumnConfig>();

  @Input()
  desc: boolean;

  @Output()
  descChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  // todo: refactor grid tables to use column group instead of separate set of input properites (columns, title, headerStyle.. )
  @Input()
  group: IGridColumnGroup;

  @Input()
  set columns(columns: Array<IColumnConfig>) {
    this.fullWidth = _.sumBy(columns, (c) => {
      const calculated = this.calculateColumnNameWidth(c.title, 26);
      c.width = Math.max(c.width || c.minWidth || 110, calculated);
      return c.width;
    });
    this.columnList = columns;
    this.prepare();
  }
  get columns(): Array<IColumnConfig> {
    return this.columnList;
  }

  // eslint-disable-next-line
  @Input('rowExpansion')
  childrenFactory: (parentRecord?: any) => Observable<Array<any>>;

  @Input()
  showExpansionIndicator: boolean;

  @Output()
  rowToggled: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  set toggleRowIndex(args: RowExpandArgs) {
    if (!this.isExpandable()) {
      return;
    }

    if (args) {
      const row = this.gridData[args.index];
      this.ensureChildrenLoaded(row);
    }
  }
  @Input()
  expanderClass: string;

  @Input()
  showColumnSelector: boolean;

  // required for column group selection
  @Input()
  gridConfig: MetricsGridConfig;

  @Output()
  visibilityChanged: EventEmitter<IGridColumnGroup> =
    new EventEmitter<IGridColumnGroup>();

  @Output()
  downloadExcelClick: EventEmitter<MetricsGridConfig> =
    new EventEmitter<MetricsGridConfig>();

  @Output()
  deleteClick: EventEmitter<MetricsGridConfig> =
    new EventEmitter<MetricsGridConfig>();

  private columnList: Array<IColumnConfig>;
  private fullWidth: number;
  private ROW_HEIGHT = 34;
  private rawData: Array<any>;
  private childColumnList: Array<IColumnConfig>;

  constructor(
    private formatService: FormatService,
    private localeService: LocaleService,
    private translateService: TranslateService,
    private columnDefintionService: ColumnDefinitionService,
    private gridSortingService: GridSortingService
  ) {}

  filterSortableColumns(column) {
    return this.columnDefintionService.filterSortableColumns(column);
  }

  private prepare() {
    if (!this.rawData || !this.columnList) {
      return;
    }
    this.childColumnList = _.map(
      this.columnList,
      (c: IColumnConfig) => c.childConfig || c
    );
    this.gridData = this.processRows(this.rawData, this.columnList);
    _.forEach(this.gridData, (r) => this.processChildren(r));
    this.handleColumnsWidth();
    this.initialize(this.columnList);
  }

  private handleColumnsWidth() {
    this.gridData.forEach((row) => {
      row.cells.forEach((cell) => {
        if (cell.value) {
          const textWidth = this.calculateColumnNameWidth(cell.value);
          const newWidth = Math.max(
            textWidth,
            cell.config.width || 100,
            cell.config.minWidth || 100
          );
          cell.config.width = newWidth; // Math.min(newWidth, cell.config.maxWidth)
        }
      });
    });
    this.fullWidth = _.sumBy(this.columnList, (c) => c.width || 100);
  }

  private processRows(
    data: Array<IDataRow>,
    columns: Array<IColumnConfig>,
    parent?: any
  ): Array<IGridRow> {
    // builds gridData, an array of IGridRow calculating dynamic styles and urls along the way
    return _.map(data, (row) => {
      return {
        data: row,
        cells: _.map(columns, (col) => {
          const value = this.getFieldValue(col, row.raw);
          return {
            value: value,
            class: col.classFactory ? col.classFactory(value, row.raw) : null,
            style: col.cellStyle,
            href: col.urlFactory
              ? col.urlFactory(value, row.raw, parent)
              : null,
            config: col,
            linkDsl: col.linkDsl ? col.linkDsl(value, row.raw, parent) : null,
            type: col.type,
          };
        }),
      };
    });
  }
  private ensureChildrenLoaded(row: IGridRow): Observable<any> {
    if (!row.childRows) {
      if (!row.data.children) {
        row.data.chidlLoading = true;
        return this.childrenFactory(row.data.raw).pipe(
          map((children) => {
            const data = _.map(children, (c) => <IDataRow>{ raw: c });
            row.data.children = data;
            row.childRows = this.processRows(
              row.data.children,
              this.childColumnList,
              row.data.raw
            );
            row.data.childrenHeight =
              row.data.children.length * this.ROW_HEIGHT;
            row.data.chidlLoading = false;
            return row.childRows;
          })
        );
      }
      row.childRows = this.processRows(
        row.data.children,
        this.childColumnList,
        row.data.raw
      );
      row.data.childrenHeight = row.data.children.length * this.ROW_HEIGHT;
    }
    return of(row.childRows);
  }

  private toggleChildren(row: IGridRow, index: number) {
    if (!this.isExpandable()) {
      return;
    }

    this.ensureChildrenLoaded(row).subscribe((c) => {
      row.data.expanded = !row.data.expanded;

      this.rowToggled.next({ index: index, expanded: row.data.expanded });
    });
  }

  isExpandable(): boolean {
    return !!this.childrenFactory;
  }

  hasOneColumnAndNotLockedOrApplyColor(group: IGridColumnGroup): boolean {
    return (
      (this.columns.length === 1 && !group.locked) || group.applyBackgroundColor
    );
  }

  initialize(columns: Array<IColumnConfig>): void {
    // 'virtual' method for derived  tables
  }

  getFieldValue(columnConfig: IColumnConfig, record: any): any {
    const fieldValue = record[columnConfig.field];
    if (columnConfig.valueFactory) {
      return columnConfig.valueFactory(fieldValue, record);
    } else {
      return fieldValue;
    }
  }

  clickCellLink(column: IColumnConfig, row: any, parent?: any): boolean {
    return column.linkClick ? column.linkClick(column, row, parent) : true;
  }

  setSortColumn(column: IColumnConfig) {
    // if already sorting on this column, just flip direction
    if (column.sortColumn === this.gridSortingService.getSortFieldOrDefault()) {
      this.setSortDirection(!this.desc);
    } else {
      // this.sortOn = column.sortColumn; // todo: after RDO-1119 is complete uncomment to replace with old way.
      this.sortOn = column.sortColumn;
      this.gridSortingService.setSortOption(column.sortColumn, this.desc);
      this.sortOnChange.next(column);
    }
  }

  setSortDirection(desc: boolean) {
    this.gridSortingService.setSortOption(
      this.gridSortingService.defaultSortColumn,
      desc
    );
    this.descChange.next(desc);
  }

  isBool(val: any) {
    return typeof val === 'boolean';
  }
  childrenHeightStyle(row: IGridRow) {
    const height = row.data.expanded ? row.data.childrenHeight : 0;
    return height.toString() + 'px';
  }
  processChildren(row: IGridRow) {
    // process childre only if data is available or row is expanded
    // this handles case when row is expanded (children loaded)
    // and user adds more column(s)
    if (!row.data.children || !row.data.expanded) {
      return;
    }
    if (!row.childRows) {
      row.childRows = this.processRows(
        row.data.children,
        this.childColumnList,
        row.data.raw
      );
      row.data.childrenHeight = row.data.children.length * this.ROW_HEIGHT;
    }
  }
  toggleColumnVisibility(group: IGridColumnGroup) {
    group.visible = !group.visible;
    this.visibilityChanged.next(group);
  }

  translatePossibleDate(cell: any): string {
    if (cell) {
      if (cell.config && cell.config.field === 'DateCreated') {
        // Dates should use valueFactory in the grid configuration to mutate the date correctly
        // leaving the formatLocalizedDateTime call in for backwards comp.
        // it should probably be removed.
        if (cell.config.valueFactory) {
          return cell.value;
        } else {
          return this.formatService.formatLocalizedDateTime(cell.value, {
            format: 'short',
            ignoreLocale: true,
          });
        }
      } else {
        return cell.value
          ? this.translateService.instant(cell.value)
          : cell.value;
      }
    }
  }

  private calculateColumnNameWidth(text: string, iconWidth: number = 10) {
    return this.formatService.getTextWidth(text, '700 13px Roboto', iconWidth);
  }
}
