import { Component, input, computed } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { getFrozenWidth } from '@/app/equipment/categories-with-rented-as.component';

@Component({
  selector: 'rdo-product-type-title',
  templateUrl: './product-type-title.component.html',
  standalone: true,
  imports: [TranslateModule],
})
export class ProductTypeTitleComponent {
  isRentedAs = input<boolean>(false);
  title = input.required<string>();

  // Cached display title.
  // public displayTitle = signal('');
  // cache for translation.
  private rentedAsStr: string;

  constructor(private translateService: TranslateService) {}

  public displayTitle = computed(() => {
    if (!this.isRentedAs()) {
      return this.title();
    }

    if (!this.rentedAsStr) {
      // TODO: Maybe move rented as to a better place.
      this.rentedAsStr = this.translateService.instant(
        'main.core.common.counts.product_types.rented_as'
      );
    }

    return `${this.rentedAsStr}: ${this.title()}`;
  });
}
