const TimeoutErrorImpl = (() => {
  function TimeoutErrorImpl() {
    Error.call(this);
    this.message = 'Timeout has occurred';
    this.name = 'TimeoutError';
    return this;
  }
  TimeoutErrorImpl.prototype = Object.create(Error.prototype);
  return TimeoutErrorImpl;
})();
export const TimeoutError = TimeoutErrorImpl;
