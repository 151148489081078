﻿<rdo-breadcrumb></rdo-breadcrumb>
<div class="container-fluid" id="sales-rep">
	<div class="row scroll-container">
		<div class="rdo-col-card-fixed scroll"
			 infinite-scroll
			 [infiniteScrollDistance]="2"
			 [infiniteScrollThrottle]="500"
			 (scrolled)="getPaginatedCards()"
			 [scrollWindow]="false">
			<div class="rdo-card-list">
				<rdo-nav-card-book
					*ngFor="let c of salesRepCards; let baseId = index"
					[baseId]="baseId"
					[data]="c"
					[titleSelector]="'SalesRepName'"
					[selected]="isSelected(c)"
					[icon]="'fa-user'"
					[comparisonMode]="mode"
					(selectedChange)="select(c)"
					[rdo-scroll-into]="isSelected(c)">
				</rdo-nav-card-book>
			</div>
		</div>

		<div class="rdo-col-card-offset scroll-container">
			<div class="row">
				<div class="col-xs-12">
					<h3>
						<i class="fa fa-user"></i>
						{{selectedSalesRep?.SalesRepName}}
					</h3>
				</div>
			</div>

			<div class="row margin-bottom-xl">
				<div class="col-xs-12">
					<ul class="nav nav-pills rdo-nav-pills">
						<li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" id="{{'main.core.common.dashboard' | rdoPathToId}}">
							<a [routerLink]="['./']">{{'main.core.common.dashboard' | translate}}</a>
						</li>
						<li routerLinkActive="active" id="{{'main.core.common.counts.customers.plural.button' | rdoPathToId}}">
							<a [routerLink]="['customers']">{{'main.core.common.counts.customers.plural' | translate}}</a>
						</li>
						<li routerLinkActive="active" id="{{'main.core.common.counts.product_types.plural.button' | rdoPathToId}}">
							<a [routerLink]="['product-types']">{{'main.core.common.counts.product_types.plural' | translate}}</a>
						</li>
					</ul>
				</div>
			</div>
			<div class="row" id="sales-rep-content">
				<div class="scroll-container">
					<div class="col-xs-12 scroll active">
						<router-outlet></router-outlet>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>