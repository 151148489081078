import { NgModule, ValueProvider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PivotViewComponent } from './pivotview.component';
import { PivotViewModule } from './pivotview.module';
import {GroupingBar, FieldList, CalculatedField, ConditionalFormatting, VirtualScroll, DrillThrough, Toolbar, PivotChart, PDFExport, ExcelExport, NumberFormatting, Grouping} from '../../ej2-pivotview';


export const /** @type {?} */ GroupingBarService: ValueProvider = { provide: 'PivotViewGroupingBar', useValue: GroupingBar};
export const /** @type {?} */ FieldListService: ValueProvider = { provide: 'PivotViewFieldList', useValue: FieldList};
export const /** @type {?} */ CalculatedFieldService: ValueProvider = { provide: 'PivotViewCalculatedField', useValue: CalculatedField};
export const /** @type {?} */ ConditionalFormattingService: ValueProvider = { provide: 'PivotViewConditionalFormatting', useValue: ConditionalFormatting};
export const /** @type {?} */ VirtualScrollService: ValueProvider = { provide: 'PivotViewVirtualScroll', useValue: VirtualScroll};
export const /** @type {?} */ DrillThroughService: ValueProvider = { provide: 'PivotViewDrillThrough', useValue: DrillThrough};
export const /** @type {?} */ ToolbarService: ValueProvider = { provide: 'PivotViewToolbar', useValue: Toolbar};
export const /** @type {?} */ PivotChartService: ValueProvider = { provide: 'PivotViewPivotChart', useValue: PivotChart};
export const /** @type {?} */ PDFExportService: ValueProvider = { provide: 'PivotViewPDFExport', useValue: PDFExport};
export const /** @type {?} */ ExcelExportService: ValueProvider = { provide: 'PivotViewExcelExport', useValue: ExcelExport};
export const /** @type {?} */ NumberFormattingService: ValueProvider = { provide: 'PivotViewNumberFormatting', useValue: NumberFormatting};
export const /** @type {?} */ GroupingService: ValueProvider = { provide: 'PivotViewGrouping', useValue: Grouping};
/**
 * NgModule definition for the PivotView component with providers.
 */
export class PivotViewAllModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [CommonModule, PivotViewModule],
    exports: [
        PivotViewModule
    ],
    providers:[
        GroupingBarService,
        FieldListService,
        CalculatedFieldService,
        ConditionalFormattingService,
        VirtualScrollService,
        DrillThroughService,
        ToolbarService,
        PivotChartService,
        PDFExportService,
        ExcelExportService,
        NumberFormattingService,
        GroupingService
    ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function PivotViewAllModule_tsickle_Closure_declarations() {
/** @type {?} */
PivotViewAllModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
PivotViewAllModule.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
