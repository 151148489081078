import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';

import { Subscription } from 'rxjs/Subscription';

import * as _ from 'lodash';
import { QueryParams } from '../core/http/query-params';
import { LazyLoadEvent } from 'primeng/api';

import {
  ActiveFilterService,
  ColumnDefinitionService,
  MetricsGridConfig,
  BreadcrumbService,
  FormatService,
  GridConfigService,
  IGridColumnGroup,
  ClientProfileService,
  GridTableNgComponent,
  GridSortingService,
} from '../core';

import { HeaderService } from '../header';
import { CustomerService } from './customer.service';
import { DownloadsService } from '../downloads';
import { PageOptionsNg, SortOptionsNg, SortOptions } from '../models';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'rdo-customers',
  templateUrl: 'customers.component.html',
  styleUrls: ['customer.component.scss'],
})
export class CustomersComponent implements OnInit, OnDestroy {
  constructor(
    private authenticationService: AuthenticationService,
    private filterService: ActiveFilterService,
    private customerService: CustomerService,
    private columnService: ColumnDefinitionService,
    private breadcrumbService: BreadcrumbService,
    private downloadsService: DownloadsService,
    private headerService: HeaderService,
    private formatService: FormatService,
    private gridConfigService: GridConfigService,
    private clientProfileService: ClientProfileService,
    private gridSortingService: GridSortingService,
    private router: Router
  ) {}
  loading: boolean = true;
  customers: any;
  gridConfig: MetricsGridConfig;
  frozenRows: any;
  paging = new PageOptionsNg();
  sorting: SortOptionsNg;
  totalCount = 0;
  frozenWidth: string;
  gridName = 'CUSTOMERS_GRID';
  calculatedProductTypeLength = 175;
  private subscriptions: Array<Subscription> = [];
  @ViewChild('grid') grid: GridTableNgComponent;
  customersRequestInProgress: Subscription;
  ngOnInit(): void {
    this.authenticationService.startSentryTransaction(
      this.router.routerState.snapshot.url
    );
    this.gridSortingService.setGridName(this.gridName);
    this.sorting = this.gridSortingService.getSortOptionsNg();

    this.breadcrumbService.update([
      { title: 'main.core.common.counts.customers.all', class: 'active' },
    ]);
    this.clientProfileService.getClientAttributes().subscribe((attributes) => {
      this.beginLoad();
    });

    this.subscriptions.push(
      this.filterService.filterChange.subscribe(() => {
        this.paging.page = 1;
        if (this.grid) {
          this.grid.resetPaginator();
        }
        this.load();
      })
    );

    this.subscriptions.push(
      this.authenticationService.userInfoView.subscribe((userInfo) => {
        if (this.gridConfig) {
          this.gridConfig.enableExcelExport =
            userInfo.HasClientAccessToExportData;
        }
      })
    );
  }

  beginLoad = () => {
    this.calculatedProductTypeLength =
      this.clientProfileService.txAttributes.MaxProdTypeLen * 7.25;
    this.calculatedProductTypeLength =
      this.calculatedProductTypeLength < 175
        ? 175
        : this.calculatedProductTypeLength;
    this.configureGrid(true);
    this.load(true);
  };

  ngOnDestroy() {
    while (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.pop().unsubscribe();
    }
  }
  load = (initEvent?: boolean) => {
    if (this.customersRequestInProgress) {
      this.customersRequestInProgress.unsubscribe();
    }

    this.loading = true;
    const params = new QueryParams();
    if (this.gridConfig) {
      if (
        this.gridConfig.groups[1].visible ||
        (initEvent && this.isStoredValueVisible(1))
      ) {
        params.set('groupBySalesRep', 'true');
      }
      if (
        this.gridConfig.groups[2].visible ||
        (initEvent && this.isStoredValueVisible(2))
      ) {
        params.set('groupByProductType', 'true');
      }
    }
    if (this.filterService.getCurrentFilter().useRouseSchema) {
      params.set('useRouseSchema', 'true');
    }
    this.customersRequestInProgress = this.customerService
      .getCustomers(this.paging, this.getSorting(), params)
      .subscribe((x) => {
        this.finishLoad(x);
        this.authenticationService.finishSentryTransaction();
      });
  };

  finishLoad = (x: any) => {
    this.loading = false;
    if (x.Items && x.Items.length > 0) {
      this.customers = x.Items;
    } else {
      this.customers = [];
    }

    this.totalCount = x.TotalCount;
  };

  isStoredValueVisible(index: number): boolean {
    return (
      this.gridConfigService.getConfig(this.gridName) &&
      this.gridConfigService.getConfig(this.gridName)[index] &&
      this.gridConfigService.getConfig(this.gridName)[index].visible
    );
  }

  visibilityChanged = (event: IGridColumnGroup) => {
    const aux = event;
    if (
      event.columnSelectorTitle === 'main.core.common.counts.sales_reps.count'
    ) {
      this.gridConfig.groups[3].visible = !event.visible;
    }
    this.gridConfig.groups[0].columns[0].isGroupingEnabled =
      this.gridConfig.groups[1].visible || this.gridConfig.groups[2].visible;
    if (
      event.columnSelectorTitle ===
        'main.core.common.counts.sales_reps.count' ||
      event.columnSelectorTitle ===
        'main.core.common.counts.product_types.count'
    ) {
      this.paging.page = 1;
      this.grid.resetPaginator();
      this.load();
    }
  };

  changePage = (event: any) => {
    if (event.rows !== this.paging.pageSize) {
      this.paging.pageSize = event.rows;
    }

    this.paging.page = event.first < 1 ? 1 : event.first / event.rows + 1;
    this.load();
  };

  changeLazyLoad = (event: LazyLoadEvent) => {
    if (
      this.sorting.sortField === event.sortField &&
      this.sorting.sortOrder === event.sortOrder
    ) {
      return;
    }

    this.gridSortingService.setSortOption(
      event.sortField,
      event.sortOrder === -1
    );

    this.sorting.sortField =
      event.sortField || this.gridSortingService.getSortFieldOrDefault();
    this.sorting.sortOrder =
      event.sortOrder || this.gridSortingService.getSortDirectionOrDefault();

    this.load();
  };

	exportExcel = (args: any) => {
		this.loading = true;
		const translatedConfig = this.gridConfig.cloneAndTranslate(text => this.formatService.translateAndFormat(text, false));
		this.customerService.getCustomersDownload(this.getSorting(), translatedConfig).subscribe(blob => {
			this.loading = false;
			this.downloadsService.saveExcelBlob(blob);
		});
	}

  private configureGrid = (initEvent?: boolean) => {
    const customerNameWidth = 225;
    const salesRepCountColumn = this.columnService.SalesRepCount();
    if (
      initEvent &&
      this.gridSortingService.getSortFieldOrDefault() === 'SalesRepCount'
    ) {
      salesRepCountColumn.width += 15;
    }

    const transactionCountColumn = this.columnService.TransactionCount();
    if (
      initEvent &&
      this.gridSortingService.getSortFieldOrDefault() === 'TransactionCount'
    ) {
      transactionCountColumn.width += 15;
    }
    this.frozenWidth = `${customerNameWidth + (salesRepCountColumn.width || 110) + (transactionCountColumn.width || 110)}px`;

    this.gridConfig = new MetricsGridConfig(
      [
        {
          title: '',
          visible: true,
          locked: true,
          columnSelectorTitle: 'main.core.common.counts.customers.singular',
          columns: [
            {
              title: 'main.core.common.counts.customers.singular',
              field: 'CustomerName',
              sortColumn: 'CustomerName',
              width: 100,
              isGroupingEnabled: true,
              isString: true,
              autoWidth: true,
              maxWidth: 255,
              headerStyle: {
                'text-align': 'left',
              },
              cellStyle: {
                'text-align': 'left',
              },
              linkDsl: (v, r) => {
                const sorting = this.getSorting();
                const params = {
                  sortOn: sorting.sortOn,
                  desc: sorting.descending,
                };
                return ['.', r.CustomerId, params];
              },
            },
          ],
        },
        {
          title: '',
          visible: false,
          locked: true,
          forceCanClose: true,
          keyColumn: true,
          columnSelectorTitle: 'main.core.common.counts.sales_reps.count',
          columns: [
            {
              title: 'main.core.common.counts.sales_reps.count',
              field: 'SalesRepName',
              sortColumn: 'SalesRepName',
              headerStyle: {
                'text-align': 'left',
              },
              cellStyle: {
                'text-align': 'left',
              },
              width: 100,
              maxWidth: 180,
              autoWidth: true,
            },
          ],
        },
        {
          title: '',
          visible: false,
          locked: true,
          forceCanClose: true,
          keyColumn: true,
          columnSelectorTitle: 'main.core.common.counts.product_types.count',
          columns: [
            {
              title: 'main.core.common.counts.product_types.count',
              field: 'ProductTypeDescription',
              sortColumn: 'ProductTypeDescription',
              headerStyle: {
                'text-align': 'left',
              },
              cellStyle: {
                'text-align': 'left',
              },
              width: 100,
              maxWidth: 500,
              minWidth: 115,
              autoWidth: true,
            },
          ],
        },
        {
          title: '',
          visible: true,
          locked: true,
          columnSelectorTitle: 'main.core.common.counts.sales_reps.count',
          columns: [salesRepCountColumn],
        },
        {
          title: '',
          visible: true,
          locked: true,
          columnSelectorTitle: 'main.core.common.counts.transactions.count',
          columns: [transactionCountColumn],
        },
        ...this.columnService.StandardMetricSetColumnGroups(true),
      ],
      null,
      true
    );
  };

  private getSorting = (): SortOptions => {
    this.sorting.sortField = this.gridSortingService.getSortFieldOrDefault();
    this.sorting.sortOrder =
      this.gridSortingService.getSortDirectionOrDefault();
    return new SortOptions(
      this.sorting.sortField,
      !(this.sorting.sortOrder === 1)
    );
  };
}
