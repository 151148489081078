import { Injectable } from '@angular/core';
import { QueryParams } from '../core/http/query-params';
import { Observable } from 'rxjs/Observable';
import { map, switchMap } from 'rxjs/operators';

import * as _ from 'lodash';

import * as filterFunctions from './../filter/functions/filter.functions';

import {
  PagedQuery,
  PageOptions,
  SortOptions,
  ProductQuery,
  GeneralExtendedQuery,
} from '../models';

import {
  RdoHttpService,
  ExportSpreadsheetBody,
  ViewService,
  QueryService,
  FormatService,
  ComparisonMode,
  MetricsGridConfig,
  RevenueMappingService,
  ActiveFilterService,
  ExcelExportService,
} from '../core';

import { FilterInfoService } from './../filter';
import { Interval, ProductTypeTransactionQuery } from '../models';

export type GetProductTypesOptions = {
  clientProductType?: string;
  category?: string;
  useRentedAsProductType?: boolean;
  showExpandedData?: boolean;
  includeTotal?: boolean;
};

export type GetProductTypesCardsOptions = {
  useRentedAsProductType?: boolean;
};

export const EXCEL_ENDPOINT_WITH_RENTED_AS =
  '/exports/product-types/spreadsheet';
export const EXCEL_ENDPOINT_SANS_RENTED_AS =
  '/metrics/categories/product-types-download';

@Injectable()
export class EquipmentService {
  private defaultParams: ProductQuery;
  private defaultParamsForGet: QueryParams;
  private isGetRequest = false;

  constructor(
    private rdoHttp: RdoHttpService,
    private formatService: FormatService,
    private filterInfoService: FilterInfoService,
    private filterService: ActiveFilterService,
    private viewService: ViewService,
    private queryService: QueryService,
    private revenueMappingService: RevenueMappingService,
    private excelExportService: ExcelExportService
  ) {
    this.defaultParams = new PagedQuery();
    this.defaultParams.PageSize = 20;
    this.defaultParams.Page = 1;

    this.defaultParamsForGet = new QueryParams();
    this.defaultParamsForGet.set('pageSize', '20');
    this.defaultParamsForGet.set('page', '1');

    this.isGetRequest = this.getIsGetRequest();
  }

  public getCategoryCards = (): Observable<any> => {
    this.isGetRequest = this.getIsGetRequest();
    return this.viewService.getComparisonMode().pipe(
      switchMap((mode: ComparisonMode) => {
        const sortOptions = new SortOptions();
        sortOptions.descending = true;
        if (mode === ComparisonMode.Book) {
          sortOptions.sortOn = 'RevenueTotal';
        } else {
          sortOptions.sortOn = 'RevenueBenchmarked';
        }

        return this.queryService.getSorted(
          'metrics/categories/cards',
          sortOptions,
          this.isGetRequest,
          new QueryParams()
        );
      })
    );
  };

  public getIsGetRequest(): boolean {
    return filterFunctions.isGetRequest(this.filterInfoService);
  }

  public getProductTypeCards = (
    category: string,
    sort: SortOptions,
    options?: GetProductTypesCardsOptions
  ): Observable<any> => {
    this.isGetRequest = this.getIsGetRequest();
    if (this.isGetRequest) {
      const params: QueryParams = this.defaultParamsForGet.clone();

      // Adding the following to the request leads to failure.
      if (options.useRentedAsProductType) {
        params.set('UseRentedAsProductType', options.useRentedAsProductType);
      }

      if (category) {
        const categoryId = this.filterInfoService.getRouseCategoryId(category);
        if (categoryId) {
          params.set('RouseCategoryList', categoryId.toString());
        }
      }

      this.queryService.setSortingParams(params, sort);

      return this.rdoHttp
        .getf('metrics/product-types/cards', { search: params })
        .pipe(map((res) => res));
    } else {
      const pagedSorted = new ProductQuery();
      if (category) {
        pagedSorted.RouseCategoryList = [
          this.filterInfoService.getRouseCategoryId(category),
        ];
      }
      pagedSorted.SetSorting(sort);

      // Adding the following to the request leads to failure.
      if (options.useRentedAsProductType) {
        pagedSorted.UseRentedAsProductType = options.useRentedAsProductType;
      }

      return this.rdoHttp
        .post('metrics/product-types/cards', pagedSorted)
        .pipe(map((res) => res));
    }
  };

  public getProductTypeRevenueByMonth = (
    productType: string,
    category: string = null
  ): Observable<any> => {
    const pagedSorted = this.defaultParams.clone();
    pagedSorted.ClientProductType = productType;
    if (category) {
      pagedSorted.RouseCategoryList = [
        this.filterInfoService.getRouseCategoryId(category),
      ];
    }

    return this.rdoHttp
      .post('metrics/product-types/dashboard', pagedSorted)
      .pipe(map((res) => res));
  };

  public getProductTypes = (
    options: GetProductTypesOptions = {
      category: null,
      useRentedAsProductType: false,
      showExpandedData: false,
      includeTotal: true,
    },
    paging: PageOptions = new PageOptions(),
    sorting: SortOptions = new SortOptions()
  ): Observable<any> => {
    const defaultOptions = {
      category: null,
      useRentedAsProductType: false,
      showExpandedData: false,
    };

    const { category, ...addonParams } = { ...defaultOptions, ...options };

    this.isGetRequest = this.getIsGetRequest();

    // Under certain circumstances a GET request is not possible because the url exceeds the url limit
    if (this.isGetRequest) {
      const params = this.defaultParamsForGet.clone();

      if (options.category) {
        const categoryId = this.filterInfoService.getRouseCategoryId(category);
        if (categoryId) {
          params.set('RouseCategoryList', categoryId.toString());
        }
      }

      params.set('IncludeRateChange', 'true');
      params.set('IncludeTotal', options.includeTotal?.toString() || 'true');

      // Add any additional params.
      Object.keys(addonParams).forEach((key) => {
        params.set(_.upperFirst(key), addonParams[key]);
      });

      return this.queryService.getPagedSorted(
        'metrics/categories/product-types',
        paging,
        sorting,
        this.isGetRequest,
        params
      );
    } else {
      const params = this.defaultParams.clone();
      if (options.category) {
        params.RouseCategoryList = [
          this.filterInfoService.getRouseCategoryId(category),
        ];
      }

      params.IncludeRateChange = true;
      params.IncludeTotal = options.includeTotal;

      // Add any additional params.
      Object.keys(addonParams).forEach((key) => {
        params[_.upperFirst(key)] = addonParams[key];
      });

      return this.queryService.getPagedSorted(
        'metrics/categories/product-types',
        paging,
        sorting,
        this.isGetRequest,
        null,
        params
      );
    }
  };

  public getProductTypesDownload = (
    options: {
      category?: string;
      gridConfig: MetricsGridConfig;
      // This will be true when the first column is one of the rented as columns.
      useRentedAsProductType?: boolean;
      // this will be true if the rented as information is available for the client.
      hasRentedAs?: boolean;
    },
    sorting: SortOptions = new SortOptions()
  ): Observable<Record<any, any>[]> => {
    const filters = this.filterInfoService.getFilterExport();
    if (options.category) {
      filters.Category = options.category;
    }

    const body = new ExportSpreadsheetBody(
      options.gridConfig.getAllColumns(true),
      filters
    );
    const pagedSorted = new ProductQuery();
    pagedSorted.SetSorting(sorting);
    pagedSorted.IncludeRateChange = true;
    //if (options.useRentedAsProductType) {
    pagedSorted.UseRentedAsProductType = options.useRentedAsProductType;
    //}
    if (options.category) {
      pagedSorted.RouseCategoryList = [
        this.filterInfoService.getRouseCategoryId(options.category),
      ];
    }

    const endpointUrl: string = options.hasRentedAs
      ? EXCEL_ENDPOINT_WITH_RENTED_AS
      : `${EXCEL_ENDPOINT_SANS_RENTED_AS}/${this.formatService.getDateLocale()}`;

    return this.excelExportService.generateGridSpreadsheet(
      endpointUrl,
      body,
      pagedSorted,
      options.hasRentedAs
    );
  };

  public getProductTypeRateTypes(
    options: {
      productType: string;
      category?: string;
      useRentedAsProductType?: boolean;
    },
    paging: PageOptions = new PageOptions(),
    sorting: SortOptions = new SortOptions()
  ): Observable<any> {
    const pagedSorted = this.defaultParams.clone();
    pagedSorted.ClientProductType = options.productType;
    if (options.category) {
      pagedSorted.RouseCategoryList = [
        this.filterInfoService.getRouseCategoryId(options.category),
      ];
    }
    pagedSorted.SetSorting(sorting);
    if (options.useRentedAsProductType) {
      pagedSorted.UseRentedAsProductType = true;
    }
    return this.rdoHttp
      .post('metrics/product-types/dashboard/rate-types', pagedSorted)
      .pipe(
        map((res) => {
          const response = (res as any).Items[0];
          return this.revenueMappingService.processRateTypesResponse(response);
        })
      );
  }

  public getCustomerProductTypes(
    customerId: number,
    paging: PageOptions = new PageOptions(),
    sorting: SortOptions = new SortOptions()
  ): Observable<any> {
    const pagedSorted = new ProductQuery();

    pagedSorted.CustomerId = customerId;

    return this.queryService.getPagedSorted(
      'metrics/customers/product-types',
      paging,
      sorting,
      false,
      null,
      pagedSorted
    );
  }

  public getCustomerProductTypesDownload(
    customer: string,
    customerId: number,
    sorting: SortOptions = new SortOptions(),
    gridConfig: MetricsGridConfig
  ): Observable<Record<any, any>[]> {
    const filters = this.filterInfoService.getFilterExport();
    filters.Customer = customer;
    const pagedSorted = new ProductQuery();
    pagedSorted.CustomerId = customerId;
    pagedSorted.SetSorting(sorting);
    const body = new ExportSpreadsheetBody(gridConfig.getAllColumns(), filters);
    return this.excelExportService.generateGridSpreadsheet(
      'metrics/customers/product-types-download',
      body,
      pagedSorted
    );
  }

  public getCustomerProductTypesSalesReps(
    options: {
      customerId: number;
      productType: string;
      useRentedAsProductType?: boolean;
    },
    sorting: SortOptions = new SortOptions()
  ): Observable<any> {
    const pagedSorted = new ProductQuery();

    pagedSorted.Page = 1;
    pagedSorted.PageSize = 500;
    pagedSorted.CustomerId = options.customerId;
    pagedSorted.ClientProductType = options.productType;
    pagedSorted.UseRentedAsProductType =
      options.useRentedAsProductType || false;

    pagedSorted.SetSorting(sorting);

    return this.rdoHttp
      .post('metrics/customers/product-types/sales-reps', pagedSorted)
      .pipe(
        map((r: any) => {
          return r.Items;
        })
      );
  }

  public getSalesRepProductTypes(
    salesRepId: string,
    paging: PageOptions = new PageOptions(),
    sorting: SortOptions = new SortOptions()
  ): Observable<any> {
    const pagedSorted = new ProductQuery();

    pagedSorted.SalesRepId = salesRepId;

    return this.queryService.getPagedSorted(
      'metrics/sales-reps/product-types',
      paging,
      sorting,
      false,
      null,
      pagedSorted
    );
  }

  public getSalesRepProductTypesDownload = (
    salesRep: string,
    salesRepId: string,
    sorting: SortOptions = new SortOptions(),
    gridConfig: MetricsGridConfig
  ): Observable<Record<any, any>[]> => {
    const filters = this.filterInfoService.getFilterExport();
    filters['Sales Rep'] = salesRep;
    const pagedSorted = new ProductQuery();
    pagedSorted.SalesRepId = salesRepId;
    pagedSorted.SetSorting(sorting);
    const body = new ExportSpreadsheetBody(gridConfig.getAllColumns(), filters);
    return this.excelExportService.generateGridSpreadsheet(
      'metrics/sales-reps/product-types-download',
      body,
      pagedSorted
    );
  };

  public getSalesRepProductTypesCustomers(
    options: {
      salesRepId: string;
      productType: string;
      useRentedAsProductType?: boolean;
    },
    sorting: SortOptions = new SortOptions()
  ): Observable<any> {
    const pagedSorted = new ProductQuery();

    pagedSorted.Page = 1;
    pagedSorted.PageSize = 500;
    pagedSorted.SalesRepId = options.salesRepId;
    pagedSorted.ClientProductType = options.productType;
    pagedSorted.UseRentedAsProductType =
      options.useRentedAsProductType || false;

    pagedSorted.SetSorting(sorting);

    return this.rdoHttp
      .post('metrics/sales-reps/product-types/customers', pagedSorted)
      .pipe(
        map((r: any) => {
          return r.Items;
        })
      );
  }

  public getHistogramData(options: {
    mode: Interval;
    productType: string;
    category?: string;
    useRentedAsProductType?: boolean;
  }): Observable<TODO> {
    const pagedSorted = this.defaultParams.clone();
    if (options.category) {
      pagedSorted.RouseCategoryList = [
        this.filterInfoService.getRouseCategoryId(options.category),
      ];
    }
    if (options.useRentedAsProductType) {
      pagedSorted.UseRentedAsProductType = options.useRentedAsProductType;
    }

    pagedSorted.ClientProductType = options.productType;
    pagedSorted.Mode = Interval[options.mode];

    return this.rdoHttp
      .post('/metrics/product-types/histogram', pagedSorted)
      .pipe(
        map((res) => {
          return (res as any).Items;
        })
      );
  }

  public getProductTypeTransactions(query: ProductTypeTransactionQuery) {
    const pagedSorted = new GeneralExtendedQuery();
    //!!TODO throws out false(s) like in PROD 20170802
    pagedSorted.ClientProductType = query.productType
      ? query.productType
      : null;
    pagedSorted.CustomerId = query.customer ? query.customer : null;
    pagedSorted.SalesRepId = query.salesRep ? query.salesRep : null;
    pagedSorted.RouseCategoryList = query.category
      ? [this.filterInfoService.getRouseCategoryId(query.category)]
      : null;
    pagedSorted.FromValue = query.fromValue ? query.fromValue : null;
    pagedSorted.ToValue = query.toValue ? query.toValue : null;
    pagedSorted.BelowBQ = query.belowBq ? query.belowBq : null;
    pagedSorted.AboveTQ = query.aboveTq ? query.aboveTq : null;
    pagedSorted.AvgToBQ = query.avgToBq ? query.avgToBq : null;
    pagedSorted.TQToAvg = query.tqToAvg ? query.tqToAvg : null;
    pagedSorted.Unbenchmarked = query.unbenchmarked
      ? query.unbenchmarked
      : null;
    pagedSorted.RateType = Interval[query.rateType];

    // TODO: Do we need to send UseRentedAsProductType?
    if (query.useRentedAsProductType) {
      pagedSorted.UseRentedAsProductType = query.useRentedAsProductType;
    }

    return this.queryService.getPagedSorted(
      '/metrics/product-types/sales-reps/customers/transactions',
      query.paging,
      query.sort,
      false,
      null,
      pagedSorted
    );
  }

  public getProductTypeTransactionsDownload = (
    query: ProductTypeTransactionQuery,
    gridConfig: MetricsGridConfig
  ): Observable<Record<any, any>[]> => {
    const pagedSorted = new GeneralExtendedQuery();
    //!!TODO throws out false(s) like in PROD 20170802
    pagedSorted.ClientProductType = query.productType
      ? query.productType
      : null;
    pagedSorted.CustomerId = query.customer ? query.customer : null;
    pagedSorted.SalesRepId = query.salesRep ? query.salesRep : null;
    pagedSorted.RouseCategoryList = query.category
      ? [this.filterInfoService.getRouseCategoryId(query.category)]
      : null;
    pagedSorted.FromValue = query.fromValue ? query.fromValue : null;
    pagedSorted.ToValue = query.toValue ? query.toValue : null;
    pagedSorted.BelowBQ = query.belowBq ? query.belowBq : null;
    pagedSorted.AboveTQ = query.aboveTq ? query.aboveTq : null;
    pagedSorted.AvgToBQ = query.avgToBq ? query.avgToBq : null;
    pagedSorted.TQToAvg = query.tqToAvg ? query.tqToAvg : null;
    pagedSorted.Unbenchmarked = query.unbenchmarked
      ? query.unbenchmarked
      : null;
    pagedSorted.RateType = Interval[query.rateType];
    pagedSorted.UseRentedAsProductType = query.useRentedAsProductType;

    pagedSorted.SetSorting(query.sort);

    const body = new ExportSpreadsheetBody(
      gridConfig.getAllColumns(),
      this.filterInfoService.getFilterExport()
    );

    const endpointUrl = `/metrics/product-types/sales-reps/customers/transactions-download/${this.formatService.getDateLocale()}`;

    return this.excelExportService.generateGridSpreadsheet(
      endpointUrl,
      body,
      pagedSorted
    );
  };

  public getProductTypeRateTrends(
    options: {
      productType?: string;
      category?: string;
      useRentedAsProductType?: boolean;
    },
    paging: PageOptions = new PageOptions(),
    sorting: SortOptions = new SortOptions()
  ): Observable<TODO> {
    const pagedSorted = this.defaultParams.clone();
    pagedSorted.ClientProductType = options.productType || null;
    pagedSorted.SetSorting(sorting);
    if (options.category) {
      pagedSorted.RouseCategoryList = [
        this.filterInfoService.getRouseCategoryId(options.category),
      ];
    }

    if (options.useRentedAsProductType) {
      pagedSorted.UseRentedAsProductType = options.useRentedAsProductType;
    }

    return (
      this.rdoHttp
        .post('metrics/product-types/dynamic-rate-types', pagedSorted)
        // take last 6 months.
        .pipe(
          map((res) => {
            const monthly = Array<any>();
            const weekly = Array<any>();
            const daily = Array<any>();
            const hourly = Array<any>();

            const last6Months = _.takeRight((res as any).Items, 6);

            _.forEach(last6Months, (o: any) => {
              const local = new Date(o.Month);

              // Using the o.Month directly causes issues with GMT localization, so constructing
              // the date from just the first day of the month. Leaving old code here for reference.
              // let dateRecord = this.formatService.stringToDate(o.Month);
              const dateRecord = new Date(
                local.getFullYear(),
                local.getMonth(),
                1
              );

              const monthlyRateTrend = {
                Type: 'Monthly',
                BenchMarkDate: dateRecord,
                Interval: o.Months,
                BookRevenueRate: o.MonthlyBookRevenueRate,
                FloorRevenueRate: o.MonthlyFloorRevenueRate,
                TopQRevenueRate: o.MonthlyTopQRevenueRate,
                RevenueRate: o.MonthlyRevenueRate,
                BotQRevenueRate: o.MonthlyBotQRevenueRate,
                BenchmarkedTopQRevenueRate: o.MonthlyBenchmarkedTopQRevenueRate,
                BenchmarkedRevenueRate: o.MonthlyBenchmarkedRevenueRate,
                BenchmarkedBotQRevenueRate: o.MonthlyBenchmarkedBotQRevenueRate,
              };

              monthly.push(monthlyRateTrend);

              const weeklyRateTrend = {
                Type: 'Weekly',
                BenchMarkDate: dateRecord,
                Interval: o.Weeks,
                BookRevenueRate: o.WeeklyBookRevenueRate,
                FloorRevenueRate: o.WeeklyFloorRevenueRate,
                TopQRevenueRate: o.WeeklyTopQRevenueRate,
                RevenueRate: o.WeeklyRevenueRate,
                BotQRevenueRate: o.WeeklyBotQRevenueRate,
                BenchmarkedTopQRevenueRate: o.WeeklyBenchmarkedTopQRevenueRate,
                BenchmarkedRevenueRate: o.WeeklyBenchmarkedRevenueRate,
                BenchmarkedBotQRevenueRate: o.WeeklyBenchmarkedBotQRevenueRate,
              };

              weekly.push(weeklyRateTrend);

              const dailyRateTrend = {
                Type: 'Daily',
                BenchMarkDate: dateRecord,
                Interval: o.Days,
                BookRevenueRate: o.DailyBookRevenueRate,
                FloorRevenueRate: o.DailyFloorRevenueRate,
                TopQRevenueRate: o.DailyTopQRevenueRate,
                RevenueRate: o.DailyRevenueRate,
                BotQRevenueRate: o.DailyBotQRevenueRate,
                BenchmarkedTopQRevenueRate: o.DailyBenchmarkedTopQRevenueRate,
                BenchmarkedRevenueRate: o.DailyBenchmarkedRevenueRate,
                BenchmarkedBotQRevenueRate: o.DailyBenchmarkedBotQRevenueRate,
              };

              daily.push(dailyRateTrend);

              const hourlyRateTrend = {
                Type: 'Hourly',
                BenchMarkDate: dateRecord,
                Interval: o.Hours,
                BookRevenueRate: o.HourlyBookRevenueRate,
                FloorRevenueRate: o.HourlyFloorRevenueRate,
                TopQRevenueRate: o.HourlyTopQRevenueRate,
                RevenueRate: o.HourlyRevenueRate,
                BotQRevenueRate: o.HourlyBotQRevenueRate,
                BenchmarkedTopQRevenueRate: o.HourlyBenchmarkedTopQRevenueRate,
                BenchmarkedRevenueRate: o.HourlyBenchmarkedRevenueRate,
                BenchmarkedBotQRevenueRate: o.HourlyBenchmarkedBotQRevenueRate,
              };

              hourly.push(hourlyRateTrend);
            });
            return {
              MonthlyRateTrend: monthly,
              WeeklyRateTrend: weekly,
              DailyRateTrend: daily,
              HourlyRateTrend: hourly,
            };
          })
        )
    );
  }

  public getProductTypeInfo(
    productType: string,
    category: string = null
  ): Observable<any> {
    const pagedSorted = this.defaultParams.clone();
    pagedSorted.ClientProductType = productType;

    if (category) {
      pagedSorted.RouseCategoryList = [
        this.filterInfoService.getRouseCategoryId(category),
      ];
    }
    return this.rdoHttp
      .post('metrics/categories/product-types', pagedSorted)
      .pipe(
        map((x) => {
          return _.first((x as any).Items);
        })
      );
  }

  public getProductTypesRentalGrowth(
    monthIdFrom: number,
    monthIdTo: number,
    productType: string,
    isYearly: boolean,
    category: string = null
  ): Observable<any> {
    const pagedSorted = new ProductQuery();
    pagedSorted.ClientProductType = productType;
    pagedSorted.IncludeTotal = false;
    pagedSorted.Page = 1;
    pagedSorted.PageSize = 2;
    if (category) {
      pagedSorted.RouseCategoryList = [
        this.filterInfoService.getRouseCategoryId(category),
      ];
    }

    const endpoint =
      '/metrics/product-types/rental-growth-' +
      (isYearly ? 'yearly' : 'monthly') +
      '?MonthIDStart=' +
      monthIdFrom +
      '&MonthIDEnd=' +
      monthIdTo;

    return this.rdoHttp.post(endpoint, pagedSorted).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public getProductTypesRentalGrowthChart(
    productType: string,
    isYearly: boolean,
    category: string = null
  ): Observable<any> {
    const pagedSorted = new ProductQuery();
    pagedSorted.ClientProductType = productType;
    pagedSorted.IncludeTotal = false;
    pagedSorted.Page = 1;
    pagedSorted.PageSize = 1;
    if (category) {
      pagedSorted.RouseCategoryList = [
        this.filterInfoService.getRouseCategoryId(category),
      ];
    }

    const endpoint =
      '/metrics/product-types/rental-growth-' +
      (isYearly ? 'yearly' : 'monthly') +
      '-chart';

    return this.rdoHttp.post(endpoint, pagedSorted).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public getProductTypeRateChanges = (
    productType: string,
    category: string = null,
    monthsToReturn: number = null
  ): Observable<any> => {
    const pagedSorted = new ProductQuery();
    pagedSorted.ClientProductType = productType;
    pagedSorted.IncludeTotal = false;
    pagedSorted.Page = 1;
    pagedSorted.PageSize = 1;
    pagedSorted.MonthsToReturn = monthsToReturn;

    if (category) {
      pagedSorted.RouseCategoryList = [
        this.filterInfoService.getRouseCategoryId(category),
      ];
    }

    const endpoint = '/metrics/product-types/rate-change';

    return this.filterService.filterParams.pipe(
      switchMap((f) => {
        const override = (<any>Object).assign({}, f);
        override.MonthIDStart = null;
        override.MonthIDEnd = null;

        return this.rdoHttp.post(endpoint, pagedSorted, override).pipe(
          map((res) => {
            return res;
          })
        );
      })
    );
  };

  public getProductTypeRevenueDistribution = (
    productType: string,
    category: string = null
  ): Observable<any> => {
    const pagedSorted = new ProductQuery();
    pagedSorted.ClientProductType = productType;
    pagedSorted.IncludeTotal = false;
    pagedSorted.Page = 1;
    pagedSorted.PageSize = 1;

    if (category) {
      pagedSorted.RouseCategoryList = [
        this.filterInfoService.getRouseCategoryId(category),
      ];
    }

    const endpoint = '/metrics/product-types/revenue-distribution';

    return this.rdoHttp.post(endpoint, pagedSorted).pipe(
      map((res) => {
        return res;
      })
    );
  };

  public getProductTypeEquipment = (
    productType: string,
    paging: PageOptions = new PageOptions(),
    sorting: SortOptions = new SortOptions(),
    category: string = null,
    asset: string = null
  ): Observable<any> => {
    const pagedSorted = new ProductQuery({ ClientProductType: productType });

    if (category) {
      pagedSorted.RouseCategoryList = [
        this.filterInfoService.getRouseCategoryId(category),
      ];
    }

    if (asset) {
      pagedSorted.RouseEquipmentID = asset;
    }

    return this.queryService.getPagedSorted(
      'metrics/product-types/equipment',
      paging,
      sorting,
      false,
      null,
      pagedSorted
    );
  };

  public getProductTypeEquipmentDownload = (
    productType: string,
    gridConfig: MetricsGridConfig,
    sorting: SortOptions = new SortOptions(),
    category: string = null,
    asset: string = null
  ): Observable<object[]> => {
    const filters = this.filterInfoService.getFilterExport();
    if (category) {
      filters.Category = category;
    }
    const body = new ExportSpreadsheetBody(gridConfig.getAllColumns(), filters);
    const pagedSorted = new ProductQuery({ ClientProductType: productType });

    if (asset) {
      pagedSorted.RouseEquipmentID = asset;
    }
    pagedSorted.SetSorting(sorting);
    if (category) {
      pagedSorted.RouseCategoryList = [
        this.filterInfoService.getRouseCategoryId(category),
      ];
    }
    return this.excelExportService.generateGridSpreadsheet(
      `metrics/product-types/equipment-download/${this.formatService.getDateLocale()}`,
      body,
      pagedSorted
    );
  };
}
