import { Directive, ElementRef, Input } from '@angular/core';
/* eslint-disable @angular-eslint/directive-selector */
@Directive({
    selector: '[rdo-scroll-into]'
})

export class ScrollIntoDirective {
    hostElement: ElementRef;
    @Input('rdo-scroll-into')
    set scroll(shouldScroll: boolean) {

        if (shouldScroll) {
            this.hostElement.nativeElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
    }

    constructor(ele: ElementRef) {
        this.hostElement = ele;
    }
}
