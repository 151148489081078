<rdo-breadcrumb></rdo-breadcrumb>

<div class="container-fluid downloads-container" id="sales-rep">
    <div class="row downloads-files">
        <div id="rdo-downloads-grid" class="col-xs-12">
            <rdo-downloads-grid></rdo-downloads-grid>
        </div>
    </div>
    <div class="row cg-exports">
        <div class="col-xs-12">
            <rdo-custom-grids-queue (successCountChange)="refreshDownloadsGrid()"></rdo-custom-grids-queue>
        </div>
    </div>
</div>