import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { CoreModule } from '../core/core.module';
import { CustomGridsComponent, CustomGridsService, CustomGridsGuardService, UnsavedChangesGuard } from './index';
import { PivotViewAllModule, PivotFieldListAllModule, } from '../pivotcharts/ej2-angular-pivotview';
import { MetricsHttpService } from '../core';
import { ExportSchedulerComponent } from './export-scheduler/export-scheduler.component';
import { ExportSchedulerService } from './export-scheduler/export-scheduler.service';
import { ReactiveFormsModule } from '@angular/forms';
@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'custom-grids',
                component: CustomGridsComponent,
                canActivate: [CustomGridsGuardService],
                canDeactivate: [UnsavedChangesGuard],
                data: { title: 'main.tabs.custom_grids.page_titles.root' }
            }
        ]),
        CommonModule,
        CoreModule,
        PivotViewAllModule,
        PivotFieldListAllModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatRadioModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule],
    providers: [CustomGridsService, ExportSchedulerService, CustomGridsGuardService, MetricsHttpService, UnsavedChangesGuard],
    declarations: [CustomGridsComponent, ExportSchedulerComponent]
})
export class CustomGridsModule { }
