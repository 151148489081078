import { Component, OnInit, Inject, ViewChild, ElementRef, Input, Optional, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DownloadsService } from '../../downloads';
import { HttpErrorResponse } from '@angular/common/http';
import { SelectionModel } from '@angular/cdk/collections';
import { MatLegacyListOption as MatListOption, MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';
import _ from 'lodash';
import { MatTreeFlatDataSource } from '@angular/material/tree';
import { UserNode } from './user-node';
import { UserFlatNode } from './user-flat-node';
import { ChecklistService } from './check-list.service';
import { TreeService } from './tree.service';
import { TranslateService } from '@ngx-translate/core';
import { ClientUserRole, NotificationParameters } from '../../models';
import { Subscription } from 'rxjs';
import { CODENAMES } from '../constants';
import { FilterProfileService } from '../../filter/profiles/filter-profile.service';

@Component({
    selector: 'app-file-manager',
    templateUrl: './file-manager.component.html',
    styleUrls: ['./file-manager.component.scss'],
    providers: [ChecklistService, TreeService]
})

export class FileManagerComponent implements OnInit, OnDestroy {
    private openFrom: string = '';
    private fileName: string = '';
    private clientAvailableUsers: Array<ClientUserRole>;
    private selectedFile: File = null;
    private fileQueryResult: any = null; // A renamed version of the data object obtained in the constructor
    private allSelected: boolean = false;
    private initialLoadCompleted = false;
    private isUploadMode: boolean = false;
    private isFileChanged: boolean = false;
    private forceSelectionPermission = false;
    private restrictedOrPublicSelect: string;
    private restrictedOrPublicSelectModel = [];
    private avoidApiInteractions: boolean = false;
    private addCurrentUserAsRestricted: boolean = false;
    private readonly CUSTOM_GRIDS_ORIGIN: string = 'Custom Grids';
    public useSmallerLists = false;
    public visibilitySelectSettings;
    public visibilitySettingsOptions = [];
    public modalTitle: string = undefined;
    public isFileUploading: boolean = false;
    public noRestrictedUsersMessage: string = '';
    public usersByRoleDataSource: MatTreeFlatDataSource<UserNode, UserFlatNode>;
    private subscriptions: Subscription[] = [];
    private notificationUsersList = new Set();
    private notificationMsg = null;
    private showRoleBasedCheckboxes = false;
    private isDownloadsTab = false;
    currentGridBranches: any;

    @Input() config: any;
    @Input() standalone = true;
    @Input() public selectedUsers = [];
    @Output() saveBtnDisabled = new EventEmitter<boolean>();
    @ViewChild('FileNameInput') fileNameInput: ElementRef;
    @ViewChild('UploadFileInput') uploadFileInput: ElementRef;
    @ViewChild('availableUsers') availableUsers: MatSelectionList;
    @ViewChild('selectedUsersList') selectedUsersList: SelectionModel<MatListOption>;

    constructor(
        private ds: DownloadsService,
        private treeService: TreeService,
        protected translateService: TranslateService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data,
        private authenticationService: AuthenticationService,
        @Optional() private dialogRef: MatDialogRef<FileManagerComponent>,
        private filterProfileService: FilterProfileService
    ) {
        this.initVisibilitySettings();
        // this.noRestrictedUsersMessage = this.translateService
        //     .instant('main.core.file_manager.no_restricted_users')
        //     .replace('{0}', this.authenticationService._userInfoView.SelectedClient.ClientShortName);
    }

    /**
     * Starts this madness
     */
    public ngOnInit() {
        const url = new URL(window.location.href);
        this.isDownloadsTab = url.pathname.endsWith('downloads');
        this.showRoleBasedCheckboxes = this.getShowRoleBasedCheckboxes();
        this.handleExternalConfig();
        if (this.data === null) {
            this.handleUploadModeInit();
        } else {
            this.handlePermissionsModeInit();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s: Subscription) => {
            s.unsubscribe();
        });
    }

    private loadClientDownloadUsers = () => {
        if (!this.ds.clientDownloadUsers || this.ds.clientDownloadUsers.length === 0) {
            this.subscriptions.push(this.ds.clientDownloadUsersLoaded.subscribe(r => {
                this.clientAvailableUsers = r;
                this.processDownloadsListResponse(this.addCurrentUserAsRestricted);
            }));
            this.ds.getAllClientDownloadUsers();
        } else {
            this.clientAvailableUsers = this.ds.clientDownloadUsers;
            this.processDownloadsListResponse(this.addCurrentUserAsRestricted);
        }
    }

    /**
     * Handles configurations contained within the data or
     * config object and removes them from that object afterwards.
     */
    private async handleExternalConfig() {
        // this.config comes from an input, but data comes from a modal panel configuration
        // they're both the same so they should be merged. Latter, only data is used.
        if (!this.data && this.config) {
            this.data = this.config;
        }
        if (this.data) {
            this.openFrom = this.data.openFrom;
            this.modalTitle = this.data.modalTitle;
            this.useSmallerLists = !!this.data.useSmallerLists;
            this.avoidApiInteractions = this.data.avoidApiInteractions;
            this.addCurrentUserAsRestricted = !!this.data.addCurrentUserAsRestricted;
            this.forceSelectionPermission = this.data.forceSelectionPermission || !this.standalone;
            this.notificationUsersList = this.data.notificationUserIds ? new Set(this.data.notificationUserIds) : this.notificationUsersList;
            this.notificationMsg = this.data.notificationMsg;
            this.currentGridBranches = await this.data.currentGridBranches;
            delete this.data.avoidAddingDefaultRestrictedUser;
            delete this.data.forceSelectionPermission;
            delete this.data.avoidApiInteractions;
            delete this.data.useSmallerLists;
            delete this.data.modalTitle;
            delete this.data.openFrom;
            delete this.data.notificationUserIds;
            delete this.data.notificationMsg;
            delete this.data.currentGridBranches;
        }
    }

    /**
     * Sets an empty config used for uploads.
     */
    private handleUploadModeInit() {
        this.isUploadMode = true;
        this.fileQueryResult = { FileId: null, FullPath: '', Name: '', Modified: null, FileSize: 0, Size: '', MetaData: {}, RestrictedUsers: [] };
        this.initDropdownRestrictedOrPublic();
    }

    /**
     * Based on the current data, sets the drop down default value.
     */
    public initDropdownRestrictedOrPublic(forceInsertCurrentUser: boolean = false) {
        if (this.restrictedOrPublicSelectModel.length > 0) {
            return; // already initialized
        }
        const isRestricted =
            forceInsertCurrentUser ||
            (this.selectedUsers && this.selectedUsers.length > 0) || // There are selected users
            (this.fileQueryResult && this.fileQueryResult.RestrictedUsers && this.fileQueryResult.RestrictedUsers.length > 0) || // there are external selected users
            this.addCurrentUserAsRestricted; // the current user must be added
        this.restrictedOrPublicSelectModel = isRestricted ? ['restricted'] : ['allUsers'];
    }

    /**
     * Initializes the service in a mode that has a file an user permissions.
     */
    private handlePermissionsModeInit() {

        this.sortRestrictedUsersWithinData();
        this.fileQueryResult = this.data;
        //this.treeService.checklistService = this.checklistService; // Are we really injecting a service manually into another service? why?

        this.usersByRoleDataSource = this.treeService.getNewDatasource();
        this.loadClientDownloadUsers();
    }


    /**
     * Sorts the data input of this component by user name.
     */
    private sortRestrictedUsersWithinData() {
        try {
            if (this.data?.RestrictedUsers?.length) {
                this.data.RestrictedUsers = this.data.RestrictedUsers.sort((a, b) => {
                    if (a.FirstName === b.FirstName) {
                        return a.LastName < b.LastName ? -1 : a.LastName > b.LastName ? 1 : 0;
                    } else {
                        return a.FirstName < b.FirstName ? -1 : a.FirstName > b.FirstName ? 1 : 0;
                    }
                });
            }
        } catch (e) { }
    }



    /**
     * Why is this function declared in a different way than the others?
     * TODO: Refactor this beast...
     */
    public processDownloadsListResponse = (forceInsertCurrentUser: boolean) => {
        this.treeService.clientAvailableUsers = this.clientAvailableUsers;
        if (this.currentGridBranches?.length === 0) {
            this.currentGridBranches = this.getCurrentUser().BranchACL;
        }
        this.setRestrictedUsersFromApiAsSelectedUsers();
        this.setupMqaRolesTree();
        this.checkSelectedUsersAndExpandTree(forceInsertCurrentUser);
    }

    /**
     * Takes the lis of users obtained from the API, held in the fileQueryResult object,
     * and iterates over them to set them as selected users in the selectedUsers array.
     * It also validates that they exist within the list of clientAvailableUsers.
     * If all users are selected, the allSelected flag is set to true as well.
     */
    private setRestrictedUsersFromApiAsSelectedUsers() {
        if (this.fileQueryResult && this.fileQueryResult.RestrictedUsers) {
            const userIdsAlreadyAdded = this.selectedUsers ? this.selectedUsers.map(x => x.UserId) : [];
            this.fileQueryResult.RestrictedUsers.forEach(restrictedUser => {
                const userToSelect = this.clientAvailableUsers.find(availableUser =>
                    restrictedUser.UserId === availableUser.UserId && !userIdsAlreadyAdded.includes(availableUser.UserId)
                );
                if (userToSelect) {
                    this.selectedUsers.push(userToSelect);
                }
            });
            this.sortSelectedUsers();
        }
        this.allSelected = this.clientAvailableUsers.length === this.fileQueryResult?.RestrictedUsers?.length;
    }

    /**
     * Sets up the list of users that can be checked grouping them by MQA roles.
     */
    private setupMqaRolesTree() {
        this.updateClientAvailableUserRoles();
        const uniqueSortedRoles = [..._.uniqBy(this.clientAvailableUsers, 'Role')];
        if (uniqueSortedRoles && uniqueSortedRoles.length > 0) {
            uniqueSortedRoles.sort((a, b) => (Number(a.RoleDisplayOrder) - Number(b.RoleDisplayOrder)
                || a.Role.localeCompare(b.Role)));
        };

        const usersData = {};
        const translatedSelectAll = this.translateService.instant('main.core.file_manager.select_all');
        const rootNode = {};
        rootNode[translatedSelectAll] = usersData;
        uniqueSortedRoles.forEach(role => {
            usersData[(<any>role).Role] = {};
            const usersByRole = _.filter(this.clientAvailableUsers, (usrToFilter) => (<any>role).Role === usrToFilter.Role);
            usersByRole.forEach(userByRole => {
                // eslint-disable-next-line @typescript-eslint/unbound-method
                const userKey = `${userByRole.FirstName.toLowerCase} ${userByRole.LastName.toLowerCase} (${userByRole.UserName.toLowerCase}) UserId:${userByRole.UserId}`;
                usersData[(<any>role).Role][userKey] = `${userByRole.FirstName} ${userByRole.LastName} (${userByRole.UserName})`;
            });
        });
        this.treeService.setChecklistData(rootNode);
    }

    private updateClientAvailableUserRoles = () => {
        if (!this.showRoleBasedCheckboxes) {
            this.clientAvailableUsers.forEach(r => {
                if (r.RoleId !== -1) {
                    r.RoleId = 0;
                    r.Role = 'RDO Users';
                    r.RoleDisplayOrder = 1;
                }
            })
        }
    }

    /**
     * Sets the checkboxes within the available users (normal list) or the
     * three service (role based list) and expands the tree.
     */
    private checkSelectedUsersAndExpandTree(forceInsertCurrentUser: boolean) {
        setTimeout(() => {
            this.initDropdownRestrictedOrPublic(forceInsertCurrentUser);
            this.addCurrentUserToSelectedUsers(forceInsertCurrentUser); // Only added if using the forceSelectionPermission flag
            for (let i = 0; i < this.availableUsers?.options.length; i++) {
                const option = this.availableUsers.options.get(i);
                if (_.find(this.selectedUsers, (selectedUser) => selectedUser.UserId === option.value?.UserId)) {
                    this.availableUsers.selectedOptions.select(option);
                }
            }
            this.treeService.treeControl.dataNodes.forEach(dataNode => {
                if (_.find(this.selectedUsers,
                    (selectedUser) => `${selectedUser.FirstName} ${selectedUser.LastName} (${selectedUser.UserName})` === dataNode.item)) {
                    this.treeService.todoItemSelectionToggle(dataNode, this.selectedUsers);
                }
            });
            this.treeService.treeControl.expandAll();
            this.initialLoadCompleted = true;
            this.saveBtnDisabled.emit(this.isSaveDisabled());
        }, 100);
    }

    /**
     * If needed, adds the current user or impersonated user
     * to the list of selected users.
     */
    private addCurrentUserToSelectedUsers(forceInsertCurrentUser: boolean) {
        if (forceInsertCurrentUser || this.addCurrentUserAsRestricted) {
            const currentUser = this.getCurrentUser();
            const currentUserAlreadyContained = this.selectedUsers && this.selectedUsers.find(x => x.UserId === currentUser.UserId);
            if (!currentUserAlreadyContained && this.fileIsCurrentlyRestricted()) {
                this.selectedUsers.push(currentUser);
            }
        }
    }

    /**
     * Returns the current user in the format required within
     * the selected users.
     */
    private getCurrentUser() {
        const userId = this.authenticationService.getUserIdWithImpersonation();
        let currentUser = this.clientAvailableUsers.find(x => x.UserId === userId);
        if (!currentUser) {
            currentUser = this.buildCurrentUserFromAuthService();
        }
        return currentUser;
    }

    /**
     * If the current user owns a file and can't be found
     * among the client available users, it's built with
     * the information available in the auth service.
     */
    private buildCurrentUserFromAuthService() {
        const userView = this.authenticationService._userInfoView;
        const currentUser = new ClientUserRole();
        if (userView?.IsRouseTestUserImpersonator) {
            currentUser.UserId = userView?.tokenInfo?.getClaimValue(AuthenticationService.IMPERSONATED_USER_ID_CLAIM);
            currentUser.UserName = userView?.ImpersonatedOrCurrentUserEmail;
            currentUser.ClientId = userView?.SelectedClient?.ClientID;
            currentUser.FirstName = userView?.tokenInfo?.getClaimValue(AuthenticationService.IMPERSONATED_USER_FULL_NAME_CLAIM);
            currentUser.LastName = '';
        } else {
            currentUser.UserId = userView?.Id;
            currentUser.UserName = userView?.Name;
            currentUser.ClientId = userView?.SelectedClient?.ClientID;
            currentUser.FirstName = userView?.FullName;
            currentUser.LastName = '';
        }

        return currentUser;
    }

    /**
     * Calls the API to delete the current file.
     */
    public deleteFile = () => {
        const filename: string = this.fileQueryResult.Name;
        const fileid: number = this.fileQueryResult.FileId;
        const isoktodelete = confirm(`${this.translateService.instant('main.core.file_manager.click_ok_to_delete')} ${filename}`);
        if (isoktodelete) {
            this.subscriptions.push(this.ds.deleteFile(fileid, filename).subscribe(response => {
                if (response instanceof HttpErrorResponse) {
                    const errormessage = this.formatErrorResponse(response);
                    alert(errormessage);
                } else {
                    this.isFileChanged = true;
                    this.close();
                }
            }));
        }
    }

    /**
     * Sets up the basic data when a new file is selected for
     * upload and displays an error message if the selected
     * file is bigger than 250mb.
     */
    public onFileSelected(event) {
        const maxfilesizebytes = 250000000; // 250mb
        this.selectedFile = event.target.files[0];
        if (this.selectedFile.size > maxfilesizebytes) {
            alert(this.translateService.instant('main.core.file_manager.max_file_size_reached').replace('{0}', (maxfilesizebytes / 1000000)));
            this.selectedFile = null;
            this.fileName = '';
            this.fileNameInput.nativeElement.innerHTML = '';
        } else {
            this.fileName = this.selectedFile.name;
            this.fileNameInput.nativeElement.innerHTML = this.fileName;
        }
    }

    /**
     * Uploads a previously selected file. After a successful
     * upload, the component is restarted with a permissions
     * based configuration.
     */
    public uploadFile = () => {
        if (this.selectedFile) {
            this.isFileUploading = true;
            this.subscriptions.push(this.ds.getDownloads(
                {
                    sortField: 'Name_IsCustomGrids',
                    sortOrder: -1
                },
                {
                    first: 0,
                    page: 1,
                    pageSize: 1000
                }, null
            ).subscribe(r => {
                const existingfile = r.items.filter(i => i.Name === this.selectedFile.name);
                const isfileexists: boolean = existingfile != null && existingfile.length > 0;
                let isoverwriteok: boolean = false;

                if (isfileexists) {
                    isoverwriteok = confirm(this.translateService.instant('main.core.file_manager.file_exists_overwrite_confirm').replace('{0}', this.selectedFile.name));
                }

                if ((isfileexists && isoverwriteok) || (!isfileexists)) {
                    this.ds.uploadFile(this.selectedFile).subscribe((fileinforesponse: any) => {
                        if (fileinforesponse != null) {
                            this.isFileUploading = false;
                            if (fileinforesponse instanceof HttpErrorResponse) {
                                const errormessage = this.formatErrorResponse(fileinforesponse);
                                alert(errormessage);
                            } else {
                                this.modalTitle = this.translateService
                                    .instant('main.core.file_manager.restriction_for')
                                    .replace('{0}', this.authenticationService._userInfoView.SelectedClient.ClientShortName);
                                this.data = fileinforesponse;
                                this.isUploadMode = false;
                                this.openFrom = 'uploads';
                                this.handlePermissionsModeInit();
                            }
                        }
                    });
                } else if (!isoverwriteok) {
                    this.isFileUploading = false;
                }
            }));
        }
    }

    /**
     * Close the modal panel and send a response to the component that opened it.
     * UserIds are sent if they're available but they're not mandatory.
     */
    public close = (primaryBtnClicked: boolean = false, userIds: Array<number> = []) => {
        const responseObject: any = { isFileChangeed: this.isFileChanged, showRestrictUsers: this.isUploadMode };
        if (this.avoidApiInteractions) {
            responseObject.userIds = userIds;
            responseObject.notificationUserIds = Array.from(this.notificationUsersList);
            responseObject.notificationMsg = this.notificationMsg;
        }
        if (primaryBtnClicked) {
            responseObject.primaryBtnClicked = primaryBtnClicked;
        }
        this.dialogRef?.close(responseObject);
    }

    /**
     * Initializes visibility drop down.
     */
    private initVisibilitySettings() {
        this.visibilitySettingsOptions = [
            { id: 'allUsers', name: this.translateService.instant('main.core.file_manager.available_to_all_current_and_future') },
            { id: 'restricted', name: this.translateService.instant('main.core.file_manager.restricted') }
        ];
        this.visibilitySelectSettings = {
            selectionLimit: 1,
            autoUnselect: true,
            checkedStyle: 'fontawesome',
            buttonClasses: 'file-manager-dropdown',
            maxHeight: '260px',
            closeOnSelect: true,
        };
    }

    /**
     * Where is this error response shown?
     */
    public formatErrorResponse = (errorresponse: any): string => {
        let result: string = '';
        if (errorresponse instanceof HttpErrorResponse) {
            result =
                'message: ' + errorresponse.message + '\r\n' +
                'name: ' + errorresponse.name + '\r\n' +
                'status: ' + errorresponse.status + '\r\n' +
                'statusText: ' + errorresponse.statusText + '\r\n\r\n';
        } else {
            result =
                'message: ' + errorresponse.message + '\r\n' +
                'name: ' + errorresponse.name + '\r\n\r\n';
        }
        return result;
    }



    // ------------------------------Functions Pending Review from here on------------------------------
    public selectionChange = (change: any) => {
        if (this.availableUsers.options.get(0) === change.option) {
            this.allSelected = !this.allSelected;
            if (!this.allSelected) {
                this.availableUsers.deselectAll();
                this.selectedUsers = [];
            }
            else {
                this.availableUsers.selectAll();
                this.selectedUsers = [...this.clientAvailableUsers];
            }
        }
        else {
            if (this.allSelected) {
                this.allSelected = this.allSelected = this.clientAvailableUsers.length === this.availableUsers.selectedOptions.selected.length + 1;
            }
            else {
                this.allSelected = this.clientAvailableUsers.length === this.availableUsers.selectedOptions.selected.length;
            }
            if (change.option.selected) {
                this.selectedUsers.push(change.option.value);
            }
            else {
                _.remove(this.selectedUsers, (usr) => usr.UserId === change.option.value.UserId);
            }
            this.sortSelectedUsers();
        }
        this.refreshSaveBtnStatus();
    }

    private sortSelectedUsers = () => {
        this.selectedUsers = this.selectedUsers.sort((a, b) => {
            if (a.FirstName.toLowerCase() === b.FirstName.toLowerCase()) {
                return a.LastName.toLowerCase() < b.LastName.toLowerCase() ? -1 : a.LastName.toLowerCase() > b.LastName.toLowerCase() ? 1 : 0;
            } else {
                return a.FirstName.toLowerCase() < b.FirstName.toLowerCase() ? -1 : a.FirstName.toLowerCase() > b.FirstName.toLowerCase() ? 1 : 0;
            }
        });
    }

    public unSelect = (user: any) => {
        _.remove(this.selectedUsers, (usr) => usr.UserId === user.UserId);
        for (let i = 0; i < this.availableUsers?.options.length; i++) {
            const option = this.availableUsers.options.get(i);
            if (user.UserId === option.value?.UserId) {
                this.availableUsers.selectedOptions.deselect(option);
            }
        }
        const userNode = _.find(this.treeService.treeControl.dataNodes, (node) => node.item === `${user.FirstName} ${user.LastName} (${user.UserName})`);
        this.treeService.todoItemSelectionToggle(userNode, this.selectedUsers);
        this.refreshSaveBtnStatus();
    }

    public unSelectAll = () => {
        this.selectedUsers = [];
        this.availableUsers?.deselectAll();
        _.forEach(this.treeService.treeControl.dataNodes, (node) => this.treeService.unselect(node));
        this.refreshSaveBtnStatus();
    }

    public saveChanges = () => {
        const userIds = this.selectedUsers.map((usr) => usr.UserId);
        if (!this.avoidApiInteractions) {
            this.subscriptions.push(this.ds.updateRestrictedUsersList(userIds, this.fileQueryResult.FileId, this.fileQueryResult.Name).subscribe((restrictedusers: any) => {
                if (restrictedusers != null) {
                    if (restrictedusers instanceof HttpErrorResponse) {
                        const errormessage = this.formatErrorResponse(restrictedusers);
                        alert(errormessage);
                    } else {
                        try {
                            restrictedusers = restrictedusers.sort((a, b) => {
                                if (a.FirstName === b.FirstName) {
                                    return a.LastName < b.LastName ? -1 : a.LastName > b.LastName ? 1 : 0;
                                } else {
                                    return a.FirstName < b.FirstName ? -1 : a.FirstName > b.FirstName ? 1 : 0;
                                }
                            });
                        } catch (e) { }
                        this.fileQueryResult.RestrictedUsers = restrictedusers;
                        this.fileQueryResult.FileId = restrictedusers && restrictedusers.length && restrictedusers[0]?.FileId ? restrictedusers[0]?.FileId : null;
                        this.loadClientDownloadUsers();
                        this.isFileChanged = true;
                        this.close(true);
                    }
                }
            }));
        } else {
            this.close(true, userIds);
        }
    }

    public selectFile = () => {
        this.uploadFileInput.nativeElement.click();
    }

    public getSelectedUserIds() {
        return this.selectedUsers.map((user) => user.UserId);
    }

    public getNotificationParameters(): NotificationParameters {
        const params: NotificationParameters = {
            notificationUserIds: <Array<number>>Array.from(this.notificationUsersList),
            notificationMsg: this.notificationMsg
        }
        return params;
    }

    /**
     * Returns true if the list of checkboxes with MQA roles
     * should be displayed and false otherwise.
     */
    public getShowRoleBasedCheckboxes() {
        const showMqaRoles = !!this.filterProfileService.readClientProfileProperty(CODENAMES.CN_REPORT_ACCESS_BY_MQA_ROLE);
        return this.canEditPermissions() && showMqaRoles;
    }

    /**
     * Returns true if the list of checkboxes and the trash
     * bin should be shown and false otherwise.
     */
    public canEditPermissions() {
        const cgAllowed = this.isCustomGridsFile() && this.authenticationService._userInfoView.IsOrImpersonatesCGEditor;
        const isTestImpersonator = this.authenticationService._userInfoView.IsRouseTestUserImpersonator;
        return this.forceSelectionPermission || cgAllowed || isTestImpersonator;
    }

    public disableTrashCan(selectedUser?: any) {
        if (this.forceSelectionPermission && !selectedUser && this.selectedUsers?.length === 1 && this.selectedUsers[0].UserId === this.authenticationService.getUserId()) {
            return true;
        }
        if (this.forceSelectionPermission && selectedUser?.UserId === this.authenticationService.getUserId()) {
            return true;
        }
        return !this.canEditPermissions();
    }

    /**
     * Returns true if the current file is a custom grids file
     * and false otherwise.
     */
    public isCustomGridsFile() {
        const isCustomGridFile = this.data?.MetaData?.Origin === this.CUSTOM_GRIDS_ORIGIN
            || this.data?.Origin === this.CUSTOM_GRIDS_ORIGIN;
        return isCustomGridFile;
    }

    public showDeleteFileBtn() {
        const isNotManualCgExport = !this.avoidApiInteractions; // exporting doesn't create a file until after exporting, so there's nothing to delete
        const userHasEditPermissions = // the user should either be a cg editor on a cg file, or a test user impersonator
            this.authenticationService.userIsTestUserImpersonator() ||
            (this.authenticationService.userIsOrImpersonatesCGEditor() && this.isCustomGridsFile());
        const showDelBtn = userHasEditPermissions && isNotManualCgExport;
        return showDelBtn;
    }

    public showSaveBtn() {
        const showSvBtn = this.canEditPermissions() || this.avoidApiInteractions;
        return showSvBtn;
    }

    public restrictedByDefault() {
        return this.selectedUsers?.length;
    }

    public restrictedOrPublicSelectChange(value: any) {
        if (value[0] === 'allUsers') {
            this.unSelectAll();
        }
        else {
            const forceInsertCurrentUser = this.selectedUsers?.length === 0 && this.fileQueryResult?.RestrictedUsers?.length === 0;
            this.processDownloadsListResponse(forceInsertCurrentUser);
        }
        setTimeout(() => {
            this.saveBtnDisabled.emit(this.isSaveDisabled());
        }, 100);
    }
    showCancelBtn = () => this.openFrom !== 'uploads';

    public fileIsCurrentlyRestricted() {
        return this.restrictedOrPublicSelectModel && this.restrictedOrPublicSelectModel[0] === 'restricted';
    }

    public refreshSaveBtnStatus() {
        const isDisabled = this.isSaveDisabled();
        this.saveBtnDisabled.emit(isDisabled);
    }

    public isSaveDisabled = () => {
        const notYetLoaded = !this.initialLoadCompleted;
        const restrictedWithoutUsers = this.restrictedOrPublicSelectModel[0] === 'restricted' &&
            // !!this.fileQueryResult?.RestrictedUsers?.length &&
            this.selectedUsers?.length === 0;
        return notYetLoaded || restrictedWithoutUsers;
    }

    public updateNotificationUsersList(event: any, userId: number) {
        if (event.checked) {
            this.notificationUsersList.add(userId);
        }
        else {
            this.notificationUsersList.delete(userId);
        }
    }

    public userNotGeoRestricted(selectedUser: any) {
        const dataNode = _.find(this.treeService.treeControl.dataNodes,
            (node) => `${selectedUser.FirstName} ${selectedUser.LastName} (${selectedUser.UserName})` === node.item);
        return !this.treeService.isUserSelectionDisabled(dataNode, this.currentGridBranches);
    }

    /**
     * Clears the message and sets the users list with the current user only.
     */
    public restartParameters(): void {
        this.notificationMsg = null;
        this.notificationUsersList = new Set();
        this.unSelectAll();
        this.checkSelectedUsersAndExpandTree(true);
    }
}
