import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { map, switchMap } from 'rxjs/operators';

import { ProductQuery, SearchQuery } from './../../../models';
import { FilterInfoService } from '../../../filter/services/filter-info.service';
import { ActiveFilterService } from '../../../core/query/active-filter.service';
import { RdoHttpService } from './../../../core';
import * as filterFunctions from '../../../filter/functions/filter.functions';

@Injectable()
export class ProductTypeDashboardService {
	constructor(
		protected rdoHttp: RdoHttpService,
		private filterInfoService: FilterInfoService,
		private filterService: ActiveFilterService
	) {
	}

	public getDashboardData(productType: string, category: string, monthsToReturn: number): Observable<any> {
		const pagedSorted = new ProductQuery();
		pagedSorted.ClientProductType = productType;
		pagedSorted.MonthsToReturn = monthsToReturn;

		if (category) {
			pagedSorted.RouseCategoryList = [this.filterInfoService.getRouseCategoryId(category)];
		}

		return this.filterService.filterParams.pipe(switchMap(f => {
			const override = (<any>Object).assign({}, f);
			override.MonthIDStart = null;
			override.MonthIDEnd = null;

			return this.rdoHttp
				.post('metrics/product-types/dashboard', pagedSorted, override)
				.pipe(map(res => {
					return (res as any).Items;
				}));
		}));
	}

	public getWeeklyChartData(productType: string, category: string, monthsToReturn: number): Observable<any> {
		const pagedSorted = new ProductQuery();
		pagedSorted.ClientProductType = productType;
		pagedSorted.MonthsToReturn = monthsToReturn;

		if (category) {
			pagedSorted.RouseCategoryList = [this.filterInfoService.getRouseCategoryId(category)];
		}

		return this.filterService.filterParams.pipe(switchMap(f => {
			const override = Object.assign({}, f);
			[override.MonthIDStart, override.MonthIDEnd] = filterFunctions.getWeeklyChartBegninAndEndMonthIds(monthsToReturn, this.filterInfoService.activeFilterService.dateOptions[0].id);
			return this.rdoHttp
				.post('metrics/product-types/weekly-chart', pagedSorted, override)
				.pipe(map(res => {
					return (res as any).Items;
				}));
		}));
	}
}
