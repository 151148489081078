﻿<div class="rdo-inline rdo-expander" [ngClass]="{'rdo-expander-gray' : true || !expanded}" id="book-col-expander">
    <table class="table table-nowrap">
        <colgroup>
            <col [ngStyle]="{'width': (masterColumn.width ? masterColumn.width : 110) + 'px'}">
        </colgroup>
        <thead>
            <tr>
                <th (click)="expanded=!expanded">
                    <div class="text-left">{{tableTitle}}
                        <a *ngIf="!group.locked" (click)="toggleColumnVisibility(group)" title="{{'main.core.tables.common.hide_column_group' | translate}}">
                             <img class="close" src="/assets/images/close.png">
                       </a>
                    </div>
                    <div class="text-right text-muted">
                        <i class="fa"
                           [class.fa-angle-right]="!expanded"></i>
                    </div>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr [ngStyle]="headerStyle">
                <th [ngStyle]="masterColumn.headerStyle">
                    <rdo-sort-header [column]="masterColumn"
                                     [currentSortColumn]="sortOn"
                                     [desc]="desc"
                                     (descChange)="setSortDirection(!desc)"
                                     (columnClick)="setSortColumn(masterColumn)"
                                     [sortInProgress]="sortInProgress"
                                     >
                    </rdo-sort-header>
                </th>
            </tr>
            <ng-template ngFor let-row [ngForOf]="gridData" let-rowIndex = "index">
                <tr [ngClass]="{'clickable-row' : isExpandable(), 'table-expanded-row-first': row.data.expanded}" (click)="toggleChildren(row, rowIndex)">
                    <td [ngClass]="row.cells[0].class"
                        [ngStyle]="row.cells[0].style">
                        <a *ngIf="row.cells[0].href"
                        (click)="clickCellLink(row.cells[0].config, row.data.raw)"
                        href="{{row.cells[0].href}}">{{ row.cells[0].value }}</a>
                            <i *ngIf="!row.cells[0].href && isBool(row.cells[0].value) && row.cells[0].value" class="fa fa-check"></i>
                            <span *ngIf="!row.cells[0].href && !isBool(row.cells[0].value)">{{ row.cells[0].value }}</span>
                            &nbsp;
                    </td>
                </tr>
                <tr *ngIf="isExpandable()">
                    <td [attr.colspan]="columnList.length" style="border: none; padding: 0;">
                        <div class="rdo-expander-row" [style.height]="childrenHeightStyle(row)" >
                            <table class="table table-nowrap rdo-table-pretty" style="margin-bottom: 0;">
                                <colgroup>
                                    <col [ngStyle]="{'width': (masterColumn.width ? masterColumn.width : 110) + 'px'}">
                                </colgroup>
                                <tbody>
                                    <tr class="table-expanded-row" *ngFor="let child of row.childRows">
                                        <td [ngStyle]="child.cells[0].style" [ngClass]="child.cells[0].class">
                                            <a *ngIf="child.cells[0].href" (click)="clickCellLink(child.cells[0].config, child.data.raw, row.data.raw)" href="{{child.cells[0].href}}"> {{ child.cells[0].value }}</a>
                                            <i *ngIf="!child.cells[0].href && isBool(child.cells[0].value) && child.cells[0].value" class="fa fa-check"></i>
                                            <span *ngIf="!child.cells[0].href && !isBool(child.cells[0].value)">{{ child.cells[0].value }}</span>
                                            &nbsp;
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </tbody>
    </table>
</div>
<div class="rdo-inline rdo-expand" [style.width]="tableWidthStyle()">
    <table class="table table-nowrap" [ngStyle]="{'width':  collapsedTableWidth  + 'px'}">
        <colgroup>
            <col *ngFor="let c of collapsedColumns" [ngStyle]="{'width': (c.width ? c.width : defaultWidth) + 'px'}">
        </colgroup>
        <thead>
            <tr>
                <th [attr.colspan]="collapsedColumns.length" (click)="expanded=!expanded">
                    <br>
                    <div class="text-right">
                          <i class="fa fa-angle-left text-muted" style="margin-right: 2px" title="Collapse Column Group"></i>
                    </div>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr [ngStyle]="headerStyle">
                <th *ngFor=" let c of collapsedColumns" [ngStyle]="c.headerStyle">
                    <rdo-sort-header  [column]="c"
                                      [currentSortColumn]="sortOn"
                                      [desc]="desc"
                                      (descChange)="setSortDirection(!desc)"
                                      (columnClick)="setSortColumn(c)"
                                      [sortInProgress]="sortInProgress"
                                      >
                    </rdo-sort-header>
                </th>
            </tr>
            <ng-template ngFor let-row [ngForOf]="gridData" let-rowIndex = "index">
                <tr [ngClass]="{'clickable-row' : isExpandable(), 'table-expanded-row-first': row.data.expanded}" (click)="toggleChildren(row, rowIndex)">
                    <td *ngFor="let i of collapsedIndices"
                        [ngClass]="row.cells[i].class"
                        [ngStyle]="row.cells[i].style">

                        <a *ngIf="row.cells[i].href"
                        (click)="clickCellLink(row.cells[i].config, row.data.raw)"
                        href="{{row.cells[i].href}}">{{ row.cells[i].value }}</a>
                            <i *ngIf="!row.cells[i].href && isBool(row.cells[i].value) && row.cells[i].value" class="fa fa-check"></i>
                            <span *ngIf="!row.cells[i].href && !isBool(row.cells[i].value)">
                                {{ row.cells[i].value }}</span>
                            &nbsp;
                    </td>
                 </tr>
                 <tr *ngIf="isExpandable()">
                        <td [attr.colspan]="collapsedIndices.length" style="border: none; padding: 0;">
                            <div class="rdo-expander-row" [style.height]="childrenHeightStyle(row)">
                                <table class="table table-nowrap rdo-table-pretty" style="margin-bottom: 0;">
                                    <colgroup>
                                        <col *ngFor="let c of collapsedColumns" [ngStyle]="{'width': (c.width ? c.width : defaultWidth) + 'px'}">
                                    </colgroup>
                                    <tbody>
                                        <tr class="table-expanded-row" *ngFor="let child of row.childRows">
                                            <td *ngFor="let i of collapsedIndices" [ngStyle]="child.cells[i].style" [ngClass]="child.cells[i].class">
                                                <a *ngIf="child.cells[i].href" (click)="clickCellLink(child.cells[i].config, child.data.raw, row.data.raw)" href="{{child.cells[i].href}}"> {{ child.cells[i].value }}</a>
                                                <span *ngIf="!child.cells[i].href">{{ child.cells[i].value }}</span>
                                                &nbsp;
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </td>
                </tr>
            </ng-template>
        </tbody>
    </table>
</div>
