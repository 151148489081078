import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

/*
 * Formats boolean for display
 * Usage:
 *   currencyValue | rdoBoolean
 * Example:
 *   {{ true | rdoBoolean}}
 *   formats to: Yes
 *   {{ false | rdoBoolean}}
 *   formats to: No
 *   {{ null | rdoBoolean}}
 *   formats to: -
*/
@Pipe({
	name: 'rdoBoolean'
})
export class RdoBooleanPipe implements PipeTransform {

	constructor(
		private translateService: TranslateService
	) {}

	transform = (value?: boolean, dummyUpdater?: any): string => {
		if (_.isNil(value)) {
			return '-';
		}
		return this.translateService.instant('main.core.common.' + (value ? 'yes' : 'no'));
	}
}
