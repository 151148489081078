import { Component } from '@angular/core';

@Component({
	selector: 'rdo-equipment',
	templateUrl: 'equipment.component.html'
})

export class EquipmentComponent {

    constructor() {}
}
