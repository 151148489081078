import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as Sentry from '@sentry/angular';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../core/authentication/authentication.service';

export enum HTTP_WORD {
  GET = 'get',
  PUT = 'put',
  POST = 'post',
  DELETE = 'delete'
}


/**
 * Handles API calls used for filter profiles.
 */
export class MetricsApiService {
  protected baseUrl = '';
  protected headers: HttpHeaders;

  constructor(
    protected http: HttpClient,
    protected authenticationService: AuthenticationService
  ) {
    this.baseUrl = environment.metricsApiUrl;
    this.getHttpHeaders().subscribe();
  }

  /**
   * Returns Http headers with the current access token.
   */
  public getHttpHeaders(): Observable<HttpHeaders> {
    return new Observable<HttpHeaders>(obs => {
      try {
        if (this.headers) {
          obs.next(this.headers);
          obs.complete();
        } else {
          this.authenticationService.selectedClientId.subscribe((clientId) => { // Wait untill a client is selected
            this.authenticationService.getAuthToken().subscribe(token => {
              if (token) {
                this.headers = new HttpHeaders()
                  .set('Authorization', `Bearer ${token.AccessToken}`)
                  .set('ClientId', clientId.toString());
                obs.next(this.headers);
                obs.complete();
              }
            });
          });
        }
      } catch (err) {
        obs.error(err);
      }
    });
  }

  /**
   * Wraps a request into a new observable manually handled to
   * avoid issues with unexpected problems in the api or any
   * particular endpoints. It also adds a JWT to the request.
   */
  public performHttpRequest<Type>(
    word: string, url: string,
    data: any = undefined,
    mapResult: (results: any) => any | undefined = undefined,
    validateResponse: (args: any) => unknown | undefined = undefined,
    defaultValue: Type | undefined = undefined
  ): Observable<Type> {
    return new Observable<Type>(obs => {
      try {
        this.getHttpHeaders().subscribe(() => {
          const wordsWithBody = [HTTP_WORD.POST, HTTP_WORD.PUT]
          const observableHttpCall = wordsWithBody.includes(<HTTP_WORD>word) ?
            this.http[word](url, data, { headers: this.headers }) :
            this.http[word](url, { headers: this.headers });
          observableHttpCall.subscribe((response: any) => {
            if (!validateResponse || validateResponse && validateResponse(response)) {
              const finalResult = mapResult ? mapResult(response) : response;
              obs.next(finalResult);
              obs.complete();
            } else {
              obs.next(defaultValue);
              obs.complete();
            }
          }, err => { this.handleApiError(obs, err, defaultValue); });
        });
      } catch (err) { this.handleApiError(obs, err, defaultValue); }
    });
  }

  /**
   * Handles errors in hand made observables.
   */
  protected handleApiError(obs, exception: any = undefined, defaultValue: any = undefined) {
    if (exception) {
      console.error(exception);
      Sentry.captureException(exception);
    }
    obs.next(defaultValue);
    obs.complete();
  }

  /**
   * Turns the given object into a new given type.
   */
  public mapToType<Type>(srcObj, type: new() => Type): Type {
    return Object.assign(<any>new type(), srcObj);
  }
}
