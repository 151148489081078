import {Component, Input} from '@angular/core';
import { ActiveFilterService } from './../../../core';

/* eslint-disable @angular-eslint/no-input-rename*/
@Component({
    selector: 'rdo-rate-type-rate-trend',
    templateUrl: 'rate-type-rate-trend.component.html'
})
export class RateTypeRateTrendComponent {
    isOpen = false;
    isChartOpen = false;

    @Input('title-value')
    titleValue: string;
    @Input('rate-trend-items')
    items: Array<any>;
    @Input('time-unit-title')
    timeUnitTitle: string;
    @Input('chart-title-time-unit')
    chartTitleTimeUnit: string;

    constructor(
        private activeFilterService: ActiveFilterService
    ) { }

    toggleOpen(event) {
        event.preventDefault();
        this.isOpen = !this.isOpen;
    }

    toggleChartOpen(event) {
        event.preventDefault();
        this.isChartOpen = !this.isChartOpen;
    }

    isMonthSelected(dateToCheck: Date): boolean {
        const currFilter = this.activeFilterService.getCurrentFilter();
        const inputEpoch = dateToCheck.getTime();
        return inputEpoch >= currFilter.dateFrom.getTime() && inputEpoch < currFilter.dateTo.getTime();
    }

    getRateDifference(clientRate: number, benchRate: number) {
        if (clientRate === 0 || benchRate === 0) {
            return 0;
        }
        return clientRate - benchRate;
    }

}
