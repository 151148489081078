import { Injectable, EventEmitter } from '@angular/core';

import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';

import { ProductTypeCard } from './../../models';

@Injectable({ providedIn: 'root' })
export class SelectedProductTypeService {
    public productTypeChange: EventEmitter<ProductTypeCard> = new EventEmitter<ProductTypeCard>();
    public loading: boolean;
    public selectedProductType: ProductTypeCard;
    private subject: Subject<ProductTypeCard>;

    public get isProductSelected(): boolean {
        return !!this.selectedProductType;
    }

    public get productType(): Observable<ProductTypeCard> {
        if (this.selectedProductType) {
            return of(this.selectedProductType);
        }
        if (!this.subject) {
            this.subject = new Subject<ProductTypeCard>();
        }

        return this.subject;
    }

    notify = (productType: ProductTypeCard) => {
        this.selectedProductType = productType;
        if (this.subject) {
            this.subject.next(productType);
            this.subject = null;
        }

        if (productType) {
            this.productTypeChange.next(productType);
        } else {
            this.productTypeChange.next(null);
        }
    }
}
