export enum GlobalField {
  'filter.general.monthidstart' = 1,
  'filter.general.monthidend' = 2,
  'filter.general.use-rouse-schema' = 3,
  'filter.geography.region' = 4,
  'filter.geography.district' = 5,
  'filter.geography.branch' = 6,
  'filter.geography.primary-comparison' = 7,
  'filter.geography.rate-benchmark' = 8,
  'filter.geography.benchmark-geography' = 9,
  'filter.equipment.prime-units' = 10,
  'filter.transaction.substitutions' = 11,
  'filter.transaction.national-accounts' = 12,
  'filter.transaction.contracts' = 13,
  'filter.transaction.special-pricing' = 14,
  'filter.transaction.re-rents' = 15,
  'filter.transaction.rpo' = 16,
  'filter.transaction.cycle-bill' = 17,
  'filter.transaction.include-not-compared-transactions' = 18,
  'mobile.rate.second.row.reference' = 19,
  'mobile.rate.benchmark.type' = 20,
  'mobile.rate.benchmark.geography' = 21,
  'mobile.general.use-rouse-schema' = 22,
  'client.currency-symbol' = 23,
  'client.locale-format' = 24,
  'dashboard.chart.months-to-return' = 25,
  'filter.equipment.cat-product-group' = 26,
  'filter.equipment.client-product-type' = 27,
  'filter.equipment.has-client-category' = 28,
  'filter.equipment.rouse-market' = 29,
  'filter.equipment.rouse-product-type' = 30,
  'filter.general.has-customer-size' = 31,
  'filter.general.has-outlier' = 32,
  'filter.general.has-rouse-categories' = 33,
  'filter.general.has-vertical' = 34,
  'filter.general.max-months-allowed' = 35,
  'filter.general.rouse-categories-selection' = 36,
  'filter.transaction.outliers-selection' = 37,
  'filter.transaction.verticals-selection' = 38,
  'mobile.general.has-customer-filtering' = 39,
  'mobile.general.use-client-category' = 40,
  'mobile.rate.customer.row.months' = 41,
  'mobile.rate.customer.row.multiplier' = 42,
  'mobile.rate.fifth.row.multiplier' = 43,
  'mobile.rate.fifth.row.reference' = 44,
  'mobile.rate.first.row.multiplier' = 45,
  'mobile.rate.first.row.reference' = 46,
  'mobile.rate.fourth.row.months' = 47,
  'mobile.rate.fourth.row.multiplier' = 48,
  'mobile.rate.fourth.row.reference' = 49,
  'mobile.rate.second.row.multiplier' = 50,
  'mobile.rate.third.row.multiplier' = 51,
  'mobile.rate.third.row.reference' = 52,
  'rdo.feature.has-asset-grid' = 53,
  'rdo.feature.has-change-log' = 54,
  'summary.chart.months-to-return' = 55,
  'show-hourly-rate' = 56,
  'show-custom-grids' = 57,
  'rouse-categories-selection' = 58,
  'outliers-selection' = 59,
  'verticals-selection' = 60,
  'mqa-categories-selection' = 61,
  'report-acces-by-mqa-role' = 62,
  'monthsCode' = 63,
  'months' = 64,
  'filter.equipment.rouse-categories' = 65,
  'filter.equipment.cat-product-group-array' = 66,
  'filter.equipment.client-product-type-array' = 67,
  'filter.equipment.rouse-product-type-array' = 68,
  'filter.equipment.rouse-market-array' = 69,
  'filter.equipment.client-category' = 70,
  'filter.general.customer-size' = 71,
  'filter.general.allow-disallow-outliers' = 72,
  'filter.general.allow-disallow-verticals' = 73,
  'filter.general.allow-disallow-rouse-categories' = 74,
  'filter.general.show-rented-as' = 75, // testing 123
}
