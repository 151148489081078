import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CoreModule } from './../core/core.module';
import { DownloadsComponent, DownloadsService, DownloadsGridComponent, DownloadsGuardService, CustomGridsQueueComponent } from './index';
//import { FileManagerComponent } from '../core/file-manager/file-manager.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatListModule } from '@angular/material/list';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'downloads',
                component: DownloadsComponent,
                canActivate: [DownloadsGuardService],
                data: { title: 'main.tabs.downloads.page_titles.root' }
            }
        ]),
        CommonModule,
        CoreModule,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatTreeModule],
    providers: [DownloadsService, DownloadsGuardService],
    declarations: [DownloadsComponent, DownloadsGridComponent, CustomGridsQueueComponent/*, FileManagerComponent*/]
})
export class DownloadsModule { }
