<rdo-breadcrumb></rdo-breadcrumb>
<div class="container-fluid" id="rental-asset">
	<div class="row scroll-container">
		<div class="rdo-col-card-fixed scroll"
			 infinite-scroll
			 [infiniteScrollThrottle]="500"
			 (scrolled)="getPaginatedCards()"
			 [scrollWindow]="false">
			<div class="rdo-card-list">
				<rdo-nav-card-asset *ngFor="let c of assetCards"
									[data]="c"
									[selected]="isSelected(c)"
									[icon]="'fa-tags'"
									(selectedChange)="select(c)"
									[rdo-scroll-into]="isSelected(c)">
				</rdo-nav-card-asset>
			</div>
		</div>

		<div class="rdo-col-card-offset scroll-container">
			<div class="row">
				<div class="col-xs-12">
					<h3>
						<i class="fa fa-tags"></i>
						{{selectedAsset?.ModelYear}} {{selectedAsset?.Make}} {{selectedAsset?.Model}}
					</h3>
				</div>
			</div>
			<div class="row margin-bottom-xl">
				<div class="col-xs-12">
					<ul class="nav nav-pills rdo-nav-pills">
						<li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
							<a [routerLink]="['./']">{{'main.core.common.dashboard' | translate}}</a>
						</li>
					</ul>
				</div>
			</div>
			<div class="row" id="rental-asset-content">
				<div class="scroll-container">
					<div class="col-xs-12 scroll active">
						<router-outlet></router-outlet>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>