import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import * as Sentry from '@sentry/angular';

@Injectable({ providedIn: 'root' })
export class ErrorService {
	static genericErrorMessage = 'Unknown Error';

	errorRaised = new Subject<string>();

	setError(message: string) {
		this.errorRaised.next(message);
	}

	handleError = (error: any, username?: string) => {

		const exception = error.error || error.message || error.originalError || error;
		Sentry.captureException(exception);
		console.error('Error from global error handler', error.message);
		this.setError(error.message);
	}
}


