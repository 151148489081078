import { Observable } from '../Observable';
import { AsyncSubject } from '../AsyncSubject';
import { map } from '../operators/map';
import { canReportError } from '../util/canReportError';
import { isScheduler } from '../util/isScheduler';
import { isArray } from '../util/isArray';
export function bindNodeCallback(callbackFunc, resultSelector, scheduler) {
  if (resultSelector) {
    if (isScheduler(resultSelector)) {
      scheduler = resultSelector;
    } else {
      return (...args) => bindNodeCallback(callbackFunc, scheduler)(...args).pipe(map(args => isArray(args) ? resultSelector(...args) : resultSelector(args)));
    }
  }
  return function (...args) {
    const params = {
      subject: undefined,
      args,
      callbackFunc,
      scheduler,
      context: this
    };
    return new Observable(subscriber => {
      const {
        context
      } = params;
      let {
        subject
      } = params;
      if (!scheduler) {
        if (!subject) {
          subject = params.subject = new AsyncSubject();
          const handler = (...innerArgs) => {
            const err = innerArgs.shift();
            if (err) {
              subject.error(err);
              return;
            }
            subject.next(innerArgs.length <= 1 ? innerArgs[0] : innerArgs);
            subject.complete();
          };
          try {
            callbackFunc.apply(context, [...args, handler]);
          } catch (err) {
            if (canReportError(subject)) {
              subject.error(err);
            } else {
              console.warn(err);
            }
          }
        }
        return subject.subscribe(subscriber);
      } else {
        return scheduler.schedule(dispatch, 0, {
          params,
          subscriber,
          context
        });
      }
    });
  };
}
function dispatch(state) {
  const {
    params,
    subscriber,
    context
  } = state;
  const {
    callbackFunc,
    args,
    scheduler
  } = params;
  let subject = params.subject;
  if (!subject) {
    subject = params.subject = new AsyncSubject();
    const handler = (...innerArgs) => {
      const err = innerArgs.shift();
      if (err) {
        this.add(scheduler.schedule(dispatchError, 0, {
          err,
          subject
        }));
      } else {
        const value = innerArgs.length <= 1 ? innerArgs[0] : innerArgs;
        this.add(scheduler.schedule(dispatchNext, 0, {
          value,
          subject
        }));
      }
    };
    try {
      callbackFunc.apply(context, [...args, handler]);
    } catch (err) {
      this.add(scheduler.schedule(dispatchError, 0, {
        err,
        subject
      }));
    }
  }
  this.add(subject.subscribe(subscriber));
}
function dispatchNext(arg) {
  const {
    value,
    subject
  } = arg;
  subject.next(value);
  subject.complete();
}
function dispatchError(arg) {
  const {
    err,
    subject
  } = arg;
  subject.error(err);
}
