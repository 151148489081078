"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function hostReportError(err) {
  setTimeout(function () {
    throw err;
  }, 0);
}
exports.hostReportError = hostReportError;
