import { SESSION_TIME_OUT_DELAY, createValueHistory } from '@datadog/browser-core';
export var VIEW_CONTEXT_TIME_OUT_DELAY = SESSION_TIME_OUT_DELAY;
export function startViewHistory(lifeCycle) {
  var viewValueHistory = createValueHistory({
    expireDelay: VIEW_CONTEXT_TIME_OUT_DELAY
  });
  lifeCycle.subscribe(1 /* LifeCycleEventType.BEFORE_VIEW_CREATED */, function (view) {
    viewValueHistory.add(buildViewHistoryEntry(view), view.startClocks.relative);
  });
  lifeCycle.subscribe(5 /* LifeCycleEventType.AFTER_VIEW_ENDED */, function (_a) {
    var endClocks = _a.endClocks;
    viewValueHistory.closeActive(endClocks.relative);
  });
  lifeCycle.subscribe(3 /* LifeCycleEventType.VIEW_UPDATED */, function (viewUpdate) {
    var currentView = viewValueHistory.find(viewUpdate.startClocks.relative);
    if (currentView && viewUpdate.name) {
      currentView.name = viewUpdate.name;
    }
    if (currentView && viewUpdate.context) {
      currentView.context = viewUpdate.context;
    }
  });
  lifeCycle.subscribe(9 /* LifeCycleEventType.SESSION_RENEWED */, function () {
    viewValueHistory.reset();
  });
  function buildViewHistoryEntry(view) {
    return {
      service: view.service,
      version: view.version,
      context: view.context,
      id: view.id,
      name: view.name,
      startClocks: view.startClocks
    };
  }
  return {
    findView: function (startTime) {
      return viewValueHistory.find(startTime);
    },
    stop: function () {
      viewValueHistory.stop();
    }
  };
}
