import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { RdoHttpService } from '../core/http/rdo-http.service';
import { AuthenticationService } from '../core/authentication/authentication.service';
/* eslint-disable @typescript-eslint/unbound-method */
@Injectable({
    providedIn: 'root'
})
export class DownloadService {

    constructor(
        private authenticationService: AuthenticationService,
        private rdoHttp: RdoHttpService
    ) { }

    public getSaveFile = (filename: string): Observable<boolean> => {
        const result = new BehaviorSubject<boolean>(false);
        const endpoint = 'downloads/' + filename;
        this.rdoHttp.getFile(endpoint).subscribe(blob => {
            if (navigator.saveBlob) {
                navigator.saveBlob(blob, filename);
                result.next(true);
            } else {
                const saveBlob = navigator.webkitSaveBlob || navigator.mozSaveBlob || navigator.saveBlob;
                result.next(true);
                if (saveBlob) {
                    navigator.saveBlob(blob, filename);
                    result.next(true);
                } else {
                    const urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;;
                    const url = urlCreator.createObjectURL(<any>blob);
                    try {
                        const link = document.createElement('a');
                        link.setAttribute('href', url);
                        link.setAttribute('download', filename);
                        const event = document.createEvent('MouseEvents');
                        event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
                        link.dispatchEvent(event);
                        result.next(true);
                    } catch (e) {
                        window.location.href = url;
                    } finally {
                        urlCreator.revokeObjectURL(url);
                    }
                }
            }
        });
        return result.asObservable();
    }
}
