import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { environment } from './../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

export enum UrlKey {
	failUrl = 0,
	marketMaps = 1
}

@Injectable()
export class ConfigService {
	private Urls: string[] = ['', '/downloads/general/Pdf/US%20Canada%202017%20Maps.pdf'];
	
	public get baseApiUrl(): Observable<string> {
		//return of(environment.apiUrl);
        const apiurl = environment.apiUrl;
        const result = of(apiurl);
        return result;
    }

	public get baseMetricsApiUrl(): Observable<string> {
        const apiurl = environment.metricsApiUrl;
        const result = of(apiurl);
        return result;
    }

	public getresourceUrl = (key: UrlKey): string => {		
		if (this.Urls.length > Number(key)){
			return this.Urls[key];
		}

		return this.Urls[UrlKey.failUrl];
	}

	constructor(
		private titleService: Title,
		private translateService: TranslateService
	) {	}

	public setTitle = (newTitle: string) => {
		const pathToTranslate = newTitle && newTitle !== '' ? newTitle : ' ';
		const translatedText = this.translateService.instant(pathToTranslate);
		const rentalDashboard = this.translateService.instant('main.navigation.buttons.rental_dashboard');
		this.titleService.setTitle(translatedText + ' - ' + rentalDashboard);
	};
}
