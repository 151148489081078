export class ClientUserRole {
    ClientId: number;
    UserId: number;
    FirstName: string;
    LastName: string;
    UserName: string;
    RoleId: number;
    Role: string;
    RoleDisplayOrder: number;
    BranchACL: number[];

    constructor() {
        this.BranchACL = [];
    }
}