import { Injectable } from '@angular/core';
import { ActiveFilterService, FormatService } from './../../query';
import { FilterBroadcastType } from './../../../models';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ChartService } from './chart.service';
import { FilterProfileService } from '../../../filter/profiles/filter-profile.service';
import { FilterProfileMap } from '../../../filter/profiles/models/filter-profile-map';
import { MonthSetting } from '../../../filter/profiles/models/month-settings';
import { CODENAMES } from '../../constants/filter';
@Injectable()
export class RevenueChartHelperService {
  private I18N_PATH = 'main.core.charts';
  private months = [];
  private label;

  constructor(
    private formatService: FormatService,
    private translateService: TranslateService,
    private activeFilterService: ActiveFilterService,
    private filterProfileService: FilterProfileService
  ) {
    this.months = ChartService.getLocalizedMonths(this.formatService);
  }

  public selectMonthFromChart(point) {
    const currentFilter = this.activeFilterService.getCurrentFilter();
    const local = new Date(point.Month);
    // let localAdjusted = new Date(local.getUTCFullYear(), local.getUTCMonth(), 1);
    const to = new Date(local.getFullYear(), local.getMonth() + 1, 1);
    currentFilter.dateFrom = local;
    currentFilter.dateTo = to;
    this.filterProfileService.selectedMonthCode = MonthSetting.SELECTION;

    const updatedProfile =
      this.filterProfileService.getFilterProfileFromFilterValues();
    updatedProfile.monthsCode = MonthSetting.SELECTION;
    updatedProfile[CODENAMES.CN_GENERAL_MONTH_END] = null;
    updatedProfile[CODENAMES.CN_GENERAL_MONTH_START] = null;
    updatedProfile.months = [FilterProfileMap.getMonthIdFromDate(local)];

    this.filterProfileService.currentfilterProfileApplied = updatedProfile;
    this.filterProfileService.currentProfile.next(updatedProfile);
    this.activeFilterService.updateFilter(
      currentFilter,
      FilterBroadcastType.Global
    );
    this.filterProfileService.triggerApplyFilters();
  }

  private getClientBenchLabel(i18n?: any) {
    if (!i18n) {
      i18n = this.translateService.instant(this.I18N_PATH);
    }
    return i18n.client + ' +/- <br />' + i18n.bench;
  }

  public addClientBenchBar(chart: any): void {
    if (chart) {
      this.label = chart.renderer
        .text(
          this.getClientBenchLabel(),
          chart.spacing[3] + 13,
          chart.chartHeight - 29
        )
        .css({
          color: '#777777',
          fontSize: '11px',
          width: '100px',
        })
        .attr({
          zIndex: 6,
        })
        .add();
      chart.renderer
        .rect(
          chart.plotLeft,
          chart.chartHeight - chart.marginBottom + 33,
          chart.plotWidth,
          20,
          0
        )
        .css({
          color: '#fafafa',
        })
        .add();
    }
  }

  public addWeeklyClientBenchBar(chart: any): void {
    if (chart) {
      this.label = chart.renderer
        .text(
          this.getClientBenchLabel(),
          chart.spacing[3] + 13,
          chart.chartHeight - 29
        )
        .css({
          color: '#777777',
          fontSize: '11px',
          width: '100px',
        })
        .attr({
          zIndex: 6,
        })
        .add();
      chart.renderer
        .rect(
          chart.plotLeft,
          chart.chartHeight - chart.marginBottom + 40,
          chart.plotWidth,
          20,
          0
        )
        .css({
          color: '#fafafa',
        })
        .add();
    }
  }

  public buildChartXAxisText(differencePercent: number): string {
    let markup = '';
    markup = `<div class="chart-xaxis-label">${differencePercent.toFixed(1)}%</div>`;
    if (differencePercent < 0) {
      markup = `<div class="chart-xaxis-label text-danger">${differencePercent.toFixed(1)}%</div>`;
    }
    return markup;
  }

  public addBenchmarkDiffForRevenueChart(
    dataObject: any,
    locale?: string
  ): any {
    return this.getShortDate(dataObject.Month, locale);
  }

  public addBenchmarkDiffForUtilChart(dataObject: any, locale?: string): any {
    const differencePercent =
      (dataObject.PhysicalUtilizationBenchmarked -
        dataObject.PhysicalUtilizationBench) *
      100;
    return (
      this.getShortDate(dataObject.Month, locale) +
      ' ' +
      this.buildChartXAxisText(differencePercent)
    );
  }

  public addBenchmarkDiffForUtilWeeklyChart(
    dataObject: any,
    gridData: Array<any>,
    monthlyData: Array<any>,
    locale?: string
  ): any {
    const sameMonthBench = _.filter(
      gridData,
      (data) =>
        data.Month.split('T')[0] === dataObject.Month.split('T')[0] &&
        !isNaN(data.PhysicalUtilizationBench)
    );
    const sameMonthBenchMarked = _.filter(
      gridData,
      (data) =>
        data.Month.split('T')[0] === dataObject.Month.split('T')[0] &&
        !isNaN(data.PhysicalUtilizationBenchmarked)
    );
    const physicalUtilizationBenchmarked =
      sameMonthBenchMarked.reduce(
        (a, b) => a + (b.PhysicalUtilizationBenchmarked || 0),
        0
      ) /
      (_.filter(
        sameMonthBenchMarked,
        (record) => record.PhysicalUtilizationBenchmarked !== null
      ).length || 1);
    const physicalUtilizationBench =
      sameMonthBench.reduce((a, b) => a + b.PhysicalUtilizationBench, 0) /
      (_.filter(
        sameMonthBench,
        (record) => record.PhysicalUtilizationBench !== null
      ).length || 1);
    let differencePercent =
      (physicalUtilizationBenchmarked - physicalUtilizationBench) * 100;

    const currentMonthMonthlyData = _.find(
      monthlyData,
      (data) => data.Month.split('T')[0] === dataObject.Month.split('T')[0]
    );
    if (currentMonthMonthlyData) {
      differencePercent =
        (currentMonthMonthlyData.PhysicalUtilizationBenchmarked -
          currentMonthMonthlyData.PhysicalUtilizationBench) *
        100;
    }
    return (
      this.getShortDate(dataObject.Month, locale) +
      ' ' +
      this.buildChartXAxisText(differencePercent)
    );
  }

  public addBenchmarkDiffForDollarUtilChart(
    dataObject: any,
    locale?: string
  ): any {
    const differencePercent =
      (dataObject.DollarUtilizationBenchmarked -
        dataObject.DollarUtilizationBench) *
      100;
    return (
      this.getShortDate(dataObject.Month, locale) +
      ' ' +
      this.buildChartXAxisText(differencePercent)
    );
  }

  public getTooltipPosition(point: any): any {
    return this.getTooltipPositionCustom(point, 135, 200, 0);
  }

  public getTooltipPositionClientRateBenchmark(point: any): any {
    return this.getTooltipPositionCustom(point, 85, 275, 0);
  }

  // the idea here is to shift the tooltip a set offset so that when hovering over a specific column the
  // tooltip does not block the column in anyway this moving it to the right or the left. depending on where
  // in the graph the mouse pointer is. For the first approximately 3/4 of the grapht he tool tip will be to the right of the column.
  // in the case of the last 1/4th to avoid the tooltip from floating off screen we send it to the left of the column.
  // values were determined by looking at the 13 month on summary page and 6 month view in product type screen and determining where
  // these need to be. The x positions are based on the 13 month client rate benchmark summary view.
  // product type view while not the exact spacing of summary receives some benefit by getting the tooltip off most of the column.
  public getTooltipPositionCustom(
    point: any,
    xRightTranslation: number,
    xLeftTranslation: number,
    yTranslation: number
  ): any {
    let plotX = point.plotX;
    let plotY = yTranslation ? point.plotY : 0;
    if (plotX < 807) {
      plotX += xRightTranslation;
    } else if (plotX >= 807) {
      plotX -= xLeftTranslation;
    }

    if (yTranslation) {
      plotY += yTranslation;
    }

    return {
      x: plotX,
      y: plotY,
    };
  }

  /**
   * Render points data for a chart's tooltips into a
   * form usable by the chart series. The pointFactory can return an
   * object of any shape, but it will be applied to the correct
   * shape for using in the chart library.
   * @param chart
   * @param data - data array to use. It should be the same length os the number of points being used in the chart series.
   * @param pointFactory - factory function that will transform data for the tooltips.
   */
  public buildTooltipData<T>(
    chart: { point: { index: number }; points: any[] },
    data,
    pointFactory: (tooltipData: any, chart: any) => T
  ): { points: any[]; point: T } {
    const index = chart.point.index;
    const tooltipData = data[index];
    const result: { points: any[]; point: T } = {
      point: pointFactory(tooltipData, chart),
      points: chart.points,
    };
    return result;
  }

  /**
   * This is used the render the bullet html string. Can't use angular because it's
   * being passed as a string to highcharts.
   * @param color - hex string.
   * @param className - string
   */
  public renderTooltipBullet(color: string | null, className = ''): string {
    return `<span class="${className}" style="${color ? `color:${color};` : ''}">&#x25CF;</span>`;
  }

  public getDataForTooltip(chart: any): any {
    const benchmark = chart.points[2] ? chart.points[2].point.y : 0;
    const clientBenchmark = chart.points[1] ? chart.points[1].point.y : 0;
    const benchmarkDifference = clientBenchmark - benchmark;
    return {
      points: chart.points,
      point: {
        cssClass: benchmarkDifference < 0 ? 'text-danger' : '',
        benchmarkDifference: benchmarkDifference,
        benchmark: benchmark,
        clientBenchmark: clientBenchmark,
      },
    };
  }

  public getDataForWeeklyTooltip(chart: any): any {
    const benchmark = chart.points[1] ? chart.points[1].point.y : 0;
    const clientBenchmark = chart.points[0].point.y;
    const benchmarkDifference = clientBenchmark - benchmark;
    const week = chart.points[0].point.y;

    return {
      points: chart.points,
      point: {
        cssClass: benchmarkDifference < 0 ? 'text-danger' : '',
        benchmarkDifference: benchmarkDifference,
        benchmark: benchmark,
        clientBenchmark: clientBenchmark,
        Week: week,
      },
    };
  }

  public getDataForClientBenchMarkTooltip(chart: any): any {
    if (!chart || !chart.points || !chart.points[0]) {
      return;
    }
    const benchmarkDifference = chart.points[0].point.y;
    return {
      points: chart.points,
      point: {
        cssClass: benchmarkDifference < 0 ? 'text-danger' : '',
        benchmarkDifference: benchmarkDifference,
      },
    };
  }

  public getDataForClientBookTooltip(chart: any): any {
    if (!chart.x) {
      return;
    }
    const difference = chart.x.RevenueTotal - chart.x.RevenueBook;
    return {
      points: chart.points,
      point: {
        cssClass: difference < 0 ? 'text-danger' : '',
      },
    };
  }

  public isCurrentCategory(obj: any): boolean {
    const filterValues = this.activeFilterService.getCurrentFilter();
    const fromDate: Date = new Date(
      filterValues.dateFrom.getFullYear(),
      filterValues.dateFrom.getMonth() + 1
    );
    const toDate: Date = new Date(
      filterValues.dateTo.getFullYear(),
      filterValues.dateTo.getMonth()
    );
    const categoryDate: Date = new Date(
      parseInt(obj.Month.split('-')[0], 10),
      parseInt(obj.Month.split('-')[1], 10)
    );
    return categoryDate >= fromDate && categoryDate <= toDate;
  }

  public getShortDate(dateString: string, locale?: string) {
    const months = locale
      ? ChartService.getLocalizedMonths(this.formatService, locale)
      : this.months;
    const monthIndex = parseInt(dateString.split('-')[1], 10) - 1;
    const year = dateString.split('-')[0];
    return `${months[monthIndex]} ${year}`;
  }

  public getWeekRange(weekDateString: string) {
    const monthIndex = parseInt(weekDateString.split('-')[1], 10) - 1;
    const month = weekDateString.split('-')[1];
    const year = weekDateString.split('-')[0];
    const day = weekDateString.split('-')[2].split('T')[0];

    const startDate = new Date(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day)
    );
    const endDate = new Date(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day) + 6
    );
    return `${this.months[startDate.getMonth()]} ${startDate.getDate()} - ${this.months[endDate.getMonth()]} ${endDate.getDate()}`;
  }

  public getWeekRangeWithYear(weekDateString: string) {
    const monthIndex = parseInt(weekDateString.split('-')[1], 10) - 1;
    const month = weekDateString.split('-')[1];
    const year = weekDateString.split('-')[0];
    const day = weekDateString.split('-')[2].split('T')[0];

    const startDate = new Date(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day)
    );
    const endDate = new Date(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day) + 6
    );
    return `${this.months[startDate.getMonth()]} ${startDate.getDate()} ${startDate.getFullYear()} - ${this.months[endDate.getMonth()]} ${endDate.getDate()} ${endDate.getFullYear()}`;
  }
}
