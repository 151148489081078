import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import * as _ from 'lodash';
import {
  ActiveFilterService,
  MathService,
  BreadcrumbService,
  RentalGrowthData,
  IRentalGrowthItem,
  RentalGrowthItemType,
  RentalGrowthType,
} from './../../../core';

import { EquipmentService } from './../../equipment.service';
import { ProductTypeBaseComponent } from './../product-type-base.component';
import { SelectedProductTypeService } from './../selected-product-type.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'rdo-product-type-yoy-change',
  templateUrl: 'product-type-yoy-change.component.html',
  styleUrls: ['rental-growth-charts.scss'],
  providers: [MathService],
})
export class ProductTypeYoYChangeComponent
  extends ProductTypeBaseComponent
  implements OnInit, OnDestroy
{
  data: RentalGrowthData;
  chartData: Array<RentalGrowthData>;
  type: RentalGrowthType = RentalGrowthType.yearly;
  loadingProductTypesRentalGrowth = true;
  loadingChart: boolean = true;
  currentYearRaw: any = null;
  previousYearRaw: any = null;

  constructor(
    public filterService: ActiveFilterService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private equipmentService: EquipmentService,
    private mathService: MathService,
    selectedProductTypeService: SelectedProductTypeService,
    breadcrumbService: BreadcrumbService
  ) {
    super(selectedProductTypeService, breadcrumbService, filterService);
  }

  private doBreadcrumbUpdate(): void {
    this.updateBreadcrumbs([
      {
        title: 'main.tabs.equipment.product_types.yoy_change',
        class: 'active',
      },
    ]);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.route.parent.params.subscribe((params) => {
        const routeParams = this.getRouteParams(params);

        if (this.hasRouteChanged(this.params, routeParams)) {
          this.params = routeParams;
          const filter = this.filterService.getCurrentFilter();
          // eslint-disable-next-line no-prototype-builtins
          if (filter && filter.hasOwnProperty('dateFrom')) {
            this.load();
          } else {
            const filterSubscription =
              this.filterService.filterParams.subscribe((searchParams) => {
                filterSubscription.unsubscribe();
                this.load();
              });
          }
        }
      })
    );

    this.subscriptions.push(
      this.filterService.filterChange.subscribe(() => this.load())
    );

    this.subscriptions.push(
      this.selectedProductTypeService.productTypeChange.subscribe((p) => {
        this.doBreadcrumbUpdate();
        if (this.params.productType !== p.ProductType) {
          this.selectedProductTypeService.loading = true;
          this.params.productType = p.ProductType;
          this.load();
        }
      })
    );

    if (!this.selectedProductTypeService.selectedProductType) {
      this.subscriptions.push(
        this.selectedProductTypeService.productType.subscribe((p) => {
          this.doBreadcrumbUpdate();
        })
      );
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.data = null;
    this.chartData = null;
  }

  load = () => {
    this.loadingProductTypesRentalGrowth = true;
    this.loadingChart = true;

    this.currentYearRaw = null;
    this.previousYearRaw = null;

    this.data = null;
    this.chartData = null;

    this.loadProductTypesRentalGrowth();
    this.loadChart();
  };

  private loadProductTypesRentalGrowth = () => {
    this.loadingProductTypesRentalGrowth = true;
    const currentFilter = this.getCurrentFilter();
    this.equipmentService
      .getProductTypesRentalGrowth(
        currentFilter.monthFromId,
        currentFilter.monthToId,
        this.params.productType,
        true,
        this.params.category
      )
      .subscribe((result) => {
        this.previousYearRaw = result[0];
        this.currentYearRaw = result[1];
        this.loadingProductTypesRentalGrowth = false;
        this.finishedLoad();
      });
  };

  private loadChart() {
    this.loadingChart = true;
    this.equipmentService
      .getProductTypesRentalGrowthChart(
        this.params.productType,
        true,
        this.params.category
      )
      .subscribe((result) => {
        if (result && result.length) {
          this.finishedLoadChart(result);
        }
      });
  }

  private finishedLoad() {
    if (!this.loadingProductTypesRentalGrowth) {
      const currentFilter = this.getCurrentFilter();
      this.data = this.getRentalGrowthData(
        currentFilter.dateFrom,
        this.currentYearRaw,
        this.previousYearRaw
      );
      this.doBreadcrumbUpdate();
      this.selectedProductTypeService.loading = true;
    }
  }

  private finishedLoadChart(result: any) {
    const cd: RentalGrowthData[] = new Array<RentalGrowthData>();
    let i = 0;
    const totalMonths = 12;
    while (i <= totalMonths) {
      if (result[i] && result[i + totalMonths]) {
        const dateParts = result[i].Month.split('T')[0].split('-');
        const dateFrom = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
        cd.push(
          this.getRentalGrowthData(dateFrom, result[i], result[i + totalMonths])
        );
      }
      i++;
    }
    cd.reverse();
    this.chartData = cd;
    this.loadingChart = false;
  }

  private getRentalGrowthData(
    startDate: Date,
    currentYearRaw: any,
    previousYearRaw: any
  ): RentalGrowthData {
    if (currentYearRaw && previousYearRaw) {
      const physicalUtil: IRentalGrowthItem = {
        Title:
          'main.tabs.equipment.product_types.change.table.physical_utilization',
        ItemType: RentalGrowthItemType.physicalUtil,
        ClientAvg: currentYearRaw.PhysicalUtilizationBenchmarked,
        ClientGrowth:
          currentYearRaw.PhysicalUtilizationBenchmarked != null &&
          previousYearRaw.PhysicalUtilizationBenchmarked != null
            ? this.mathService.substract(
                currentYearRaw.PhysicalUtilizationBenchmarked,
                previousYearRaw.PhysicalUtilizationBenchmarked,
                1
              )
            : null,
        BenchmarkAvg: currentYearRaw.PhysicalUtilizationBench,
        BenchmarkGrowth:
          currentYearRaw.PhysicalUtilizationBench != null &&
          previousYearRaw.PhysicalUtilizationBench != null
            ? this.mathService.substract(
                currentYearRaw.PhysicalUtilizationBench,
                previousYearRaw.PhysicalUtilizationBench,
                1
              )
            : null,
        CvsBGrowthUnitsDiff: null,
        CvsBGrowthPointsDiff:
          currentYearRaw.PhysicalUtilizationBenchmarked != null &&
          previousYearRaw.PhysicalUtilizationBenchmarked != null &&
          currentYearRaw.PhysicalUtilizationBench != null &&
          previousYearRaw.PhysicalUtilizationBench != null
            ? this.mathService.substract(
                this.mathService.substract(
                  currentYearRaw.PhysicalUtilizationBenchmarked,
                  previousYearRaw.PhysicalUtilizationBenchmarked,
                  1
                ),
                this.mathService.substract(
                  currentYearRaw.PhysicalUtilizationBench,
                  previousYearRaw.PhysicalUtilizationBench,
                  1
                ),
                1
              )
            : null,
        ClientTotal: currentYearRaw.PhysicalUtilizationTotal,
        GrowthTotal:
          currentYearRaw.PhysicalUtilizationTotal != null &&
          previousYearRaw.PhysicalUtilizationTotal != null
            ? this.mathService.substract(
                currentYearRaw.PhysicalUtilizationTotal,
                previousYearRaw.PhysicalUtilizationTotal,
                1
              )
            : null,
      };

      const unitCost: IRentalGrowthItem = {
        Title: 'main.tabs.equipment.product_types.change.table.unit_cost',
        ItemType: RentalGrowthItemType.unitCost,
        ClientAvg: null,
        ClientGrowth: null,
        BenchmarkAvg: null,
        BenchmarkGrowth: null,
        CvsBGrowthUnitsDiff: null,
        CvsBGrowthPointsDiff: null,
        ClientTotal: null,
        GrowthTotal: null,
      };

      const financialUtil: IRentalGrowthItem = {
        Title:
          'main.tabs.equipment.product_types.change.table.financial_utilization',
        ItemType: RentalGrowthItemType.financialUtil,
        ClientAvg: currentYearRaw.DollarUtilizationBenchmarked,
        ClientGrowth:
          currentYearRaw.DollarUtilizationBenchmarked != null &&
          previousYearRaw.DollarUtilizationBenchmarked != null
            ? this.mathService.substract(
                currentYearRaw.DollarUtilizationBenchmarked,
                previousYearRaw.DollarUtilizationBenchmarked,
                1
              )
            : null,
        BenchmarkAvg: currentYearRaw.DollarUtilizationBench,
        BenchmarkGrowth:
          currentYearRaw.DollarUtilizationBench != null &&
          previousYearRaw.DollarUtilizationBench != null
            ? this.mathService.substract(
                currentYearRaw.DollarUtilizationBench,
                previousYearRaw.DollarUtilizationBench,
                1
              )
            : null,
        CvsBGrowthUnitsDiff: null,
        CvsBGrowthPointsDiff:
          currentYearRaw.DollarUtilizationBenchmarked != null &&
          previousYearRaw.DollarUtilizationBenchmarked != null
            ? this.mathService.substract(
                this.mathService.substract(
                  currentYearRaw.DollarUtilizationBenchmarked,
                  previousYearRaw.DollarUtilizationBenchmarked,
                  1
                ),
                this.mathService.substract(
                  currentYearRaw.DollarUtilizationBench,
                  previousYearRaw.DollarUtilizationBench,
                  1
                ),
                1
              )
            : null,
        ClientTotal: currentYearRaw.DollarUtilizationTotal,
        GrowthTotal:
          currentYearRaw.DollarUtilizationTotal != null &&
          previousYearRaw.DollarUtilizationTotal != null
            ? this.mathService.substract(
                currentYearRaw.DollarUtilizationTotal,
                previousYearRaw.DollarUtilizationTotal,
                1
              )
            : null,
      };

      const unitsOnRent: IRentalGrowthItem = {
        Title: 'main.tabs.equipment.product_types.change.table.units_on_rent',
        ItemType: RentalGrowthItemType.unitsOnRent,
        ClientAvg: currentYearRaw.UnitsOnRentAvgYoYBenchmarked,
        ClientGrowth: currentYearRaw.GrowthYoYDoRBenchmarked,
        BenchmarkAvg: null,
        BenchmarkGrowth: currentYearRaw.GrowthYoYDoRBench,
        CvsBGrowthUnitsDiff: currentYearRaw.UnitsOnRentAvgYoYDifference,
        CvsBGrowthPointsDiff:
          currentYearRaw.GrowthYoYDoRBenchmarked != null &&
          currentYearRaw.GrowthYoYDoRBench != null
            ? this.mathService.substract(
                currentYearRaw.GrowthYoYDoRBenchmarked,
                currentYearRaw.GrowthYoYDoRBench,
                1
              )
            : null,
        ClientTotal: currentYearRaw.UnitsOnRentAvgTotal,
        GrowthTotal: currentYearRaw.GrowthYoYDoRTotal,
      };

      const unitsOnFleet: IRentalGrowthItem = {
        Title: 'main.tabs.equipment.product_types.change.table.units_in_fleet',
        ItemType: RentalGrowthItemType.unitsOnFleet,
        ClientAvg: currentYearRaw.UnitsInFleetAvgYoYBenchmarked,
        ClientGrowth: currentYearRaw.GrowthYoYDiFBenchmarked,
        BenchmarkAvg: null,
        BenchmarkGrowth: currentYearRaw.GrowthYoYDiFBench,
        CvsBGrowthUnitsDiff: currentYearRaw.UnitsInFleetAvgYoYDifference,
        CvsBGrowthPointsDiff:
          currentYearRaw.GrowthYoYDiFBenchmarked != null &&
          currentYearRaw.GrowthYoYDiFBench != null
            ? this.mathService.substract(
                currentYearRaw.GrowthYoYDiFBenchmarked,
                currentYearRaw.GrowthYoYDiFBench,
                1
              )
            : null,
        ClientTotal: currentYearRaw.UnitsInFleetAvgTotal,
        GrowthTotal: currentYearRaw.GrowthYoYDiFTotal,
      };

      // normalize monthly rates
      currentYearRaw.MonthlyRateBenchmarked =
        currentYearRaw.MonthlyRateBenchmarked || 0;
      currentYearRaw.MonthlyRateBench = currentYearRaw.MonthlyRateBench || 0;
      currentYearRaw.MonthlyRateTotal = currentYearRaw.MonthlyRateTotal || 0;
      previousYearRaw.MonthlyRateBenchmarked =
        previousYearRaw.MonthlyRateBenchmarked || 0;
      previousYearRaw.MonthlyRateBench = previousYearRaw.MonthlyRateBench || 0;
      previousYearRaw.MonthlyRateTotal = previousYearRaw.MonthlyRateTotal || 0;

      const monthlyRentalRates: IRentalGrowthItem = {
        Title: 'main.tabs.equipment.product_types.change.table.monthly_rates',
        ItemType: RentalGrowthItemType.monthlyRentalRates,
        ClientAvg: currentYearRaw.MonthlyRateBenchmarked,
        ClientGrowth:
          currentYearRaw.MonthlyRateBenchmarked !== 0 &&
          previousYearRaw.MonthlyRateBenchmarked !== 0
            ? this.mathService.differenceNumber(
                currentYearRaw.MonthlyRateBenchmarked,
                previousYearRaw.MonthlyRateBenchmarked
              )
            : null,
        BenchmarkAvg: currentYearRaw.MonthlyRateBench,
        BenchmarkGrowth:
          currentYearRaw.MonthlyRateBench !== 0 &&
          previousYearRaw.MonthlyRateBench !== 0
            ? this.mathService.differenceNumber(
                currentYearRaw.MonthlyRateBench,
                previousYearRaw.MonthlyRateBench
              )
            : null,
        CvsBGrowthUnitsDiff: null,
        CvsBGrowthPointsDiff: null,
        ClientTotal: currentYearRaw.MonthlyRateTotal,
        GrowthTotal:
          currentYearRaw.MonthlyRateTotal !== 0 &&
          previousYearRaw.MonthlyRateTotal !== 0
            ? this.mathService.differenceNumber(
                currentYearRaw.MonthlyRateTotal,
                previousYearRaw.MonthlyRateTotal
              )
            : null,
      };

      if (
        monthlyRentalRates.ClientGrowth !== null &&
        monthlyRentalRates.BenchmarkGrowth !== null
      ) {
        monthlyRentalRates.CvsBGrowthPointsDiff = this.mathService.substract(
          monthlyRentalRates.ClientGrowth,
          monthlyRentalRates.BenchmarkGrowth,
          1
        );
      }

      // normalize weekly rates
      currentYearRaw.WeeklyRateBenchmarked =
        currentYearRaw.WeeklyRateBenchmarked || 0;
      currentYearRaw.WeeklyRateBench = currentYearRaw.WeeklyRateBench || 0;
      currentYearRaw.WeeklyRateTotal = currentYearRaw.WeeklyRateTotal || 0;
      previousYearRaw.WeeklyRateBenchmarked =
        previousYearRaw.WeeklyRateBenchmarked || 0;
      previousYearRaw.WeeklyRateBench = previousYearRaw.WeeklyRateBench || 0;
      previousYearRaw.WeeklyRateTotal = previousYearRaw.WeeklyRateTotal || 0;

      const weeklyRentalRates: IRentalGrowthItem = {
        Title: 'main.tabs.equipment.product_types.change.table.weekly_rates',
        ItemType: RentalGrowthItemType.weeklyRentalRates,
        ClientAvg: currentYearRaw.WeeklyRateBenchmarked,
        ClientGrowth:
          currentYearRaw.WeeklyRateBenchmarked !== 0 &&
          previousYearRaw.WeeklyRateBenchmarked !== 0
            ? this.mathService.differenceNumber(
                currentYearRaw.WeeklyRateBenchmarked,
                previousYearRaw.WeeklyRateBenchmarked
              )
            : null,
        BenchmarkAvg: currentYearRaw.WeeklyRateBench,
        BenchmarkGrowth:
          currentYearRaw.WeeklyRateBench !== 0 &&
          previousYearRaw.WeeklyRateBench !== 0
            ? this.mathService.differenceNumber(
                currentYearRaw.WeeklyRateBench,
                previousYearRaw.WeeklyRateBench
              )
            : null,
        CvsBGrowthUnitsDiff: null,
        CvsBGrowthPointsDiff: null,
        ClientTotal: currentYearRaw.WeeklyRateTotal,
        GrowthTotal:
          currentYearRaw.WeeklyRateTotal !== 0 &&
          previousYearRaw.WeeklyRateTotal !== 0
            ? this.mathService.differenceNumber(
                currentYearRaw.WeeklyRateTotal,
                previousYearRaw.WeeklyRateTotal
              )
            : null,
      };

      if (
        weeklyRentalRates.ClientGrowth !== null &&
        weeklyRentalRates.BenchmarkGrowth !== null
      ) {
        weeklyRentalRates.CvsBGrowthPointsDiff = this.mathService.substract(
          weeklyRentalRates.ClientGrowth,
          weeklyRentalRates.BenchmarkGrowth,
          1
        );
      }

      //normalize daily rates
      currentYearRaw.DailyRateBenchmarked =
        currentYearRaw.DailyRateBenchmarked || 0;
      currentYearRaw.DailyRateBench = currentYearRaw.DailyRateBench || 0;
      currentYearRaw.DailyRateTotal = currentYearRaw.DailyRateTotal || 0;
      previousYearRaw.DailyRateBenchmarked =
        previousYearRaw.DailyRateBenchmarked || 0;
      previousYearRaw.DailyRateBench = previousYearRaw.DailyRateBench || 0;
      previousYearRaw.DailyRateTotal = previousYearRaw.DailyRateTotal || 0;

      const dailyRentalRates: IRentalGrowthItem = {
        Title: 'main.tabs.equipment.product_types.change.table.daily_rates',
        ItemType: RentalGrowthItemType.dailyRentalRates,
        ClientAvg: currentYearRaw.DailyRateBenchmarked,
        ClientGrowth:
          currentYearRaw.DailyRateBenchmarked !== 0 &&
          previousYearRaw.DailyRateBenchmarked !== 0
            ? this.mathService.differenceNumber(
                currentYearRaw.DailyRateBenchmarked,
                previousYearRaw.DailyRateBenchmarked
              )
            : null,
        BenchmarkAvg: currentYearRaw.DailyRateBench,
        BenchmarkGrowth:
          currentYearRaw.DailyRateBench !== 0 &&
          previousYearRaw.DailyRateBench !== 0
            ? this.mathService.differenceNumber(
                currentYearRaw.DailyRateBench,
                previousYearRaw.DailyRateBench
              )
            : null,
        CvsBGrowthUnitsDiff: null,
        CvsBGrowthPointsDiff: null,
        ClientTotal: currentYearRaw.DailyRateTotal,
        GrowthTotal:
          currentYearRaw.DailyRateTotal !== 0 &&
          previousYearRaw.DailyRateTotal !== 0
            ? this.mathService.differenceNumber(
                currentYearRaw.DailyRateTotal,
                previousYearRaw.DailyRateTotal
              )
            : null,
      };

      if (
        dailyRentalRates.ClientGrowth != null &&
        dailyRentalRates.BenchmarkGrowth != null
      ) {
        dailyRentalRates.CvsBGrowthPointsDiff = this.mathService.substract(
          dailyRentalRates.ClientGrowth,
          dailyRentalRates.BenchmarkGrowth,
          1
        );
      }

      // normalize hourly rates
      currentYearRaw.HourlyRateBenchmarked =
        currentYearRaw.HourlyRateBenchmarked || 0;
      currentYearRaw.HourlyRateBench = currentYearRaw.HourlyRateBench || 0;
      currentYearRaw.HourlyRateTotal = currentYearRaw.HourlyRateTotal || 0;
      previousYearRaw.HourlyRateBenchmarked =
        previousYearRaw.HourlyRateBenchmarked || 0;
      previousYearRaw.HourlyRateBench = previousYearRaw.HourlyRateBench || 0;
      previousYearRaw.HourlyRateTotal = previousYearRaw.HourlyRateTotal || 0;

      const hourlyRentalRates: IRentalGrowthItem = {
        Title: 'main.tabs.equipment.product_types.change.table.hourly_rates',
        ItemType: RentalGrowthItemType.hourlyRentalRates,
        ClientAvg: currentYearRaw.HourlyRateBenchmarked,
        ClientGrowth:
          currentYearRaw.HourlyRateBenchmarked !== 0 &&
          previousYearRaw.HourlyRateBenchmarked !== 0
            ? this.mathService.differenceNumber(
                currentYearRaw.HourlyRateBenchmarked,
                previousYearRaw.HourlyRateBenchmarked
              )
            : null,
        BenchmarkAvg: currentYearRaw.HourlyRateBench,
        BenchmarkGrowth:
          currentYearRaw.HourlyRateBench !== 0 &&
          previousYearRaw.HourlyRateBench !== 0
            ? this.mathService.differenceNumber(
                currentYearRaw.HourlyRateBench,
                previousYearRaw.HourlyRateBench
              )
            : null,
        CvsBGrowthUnitsDiff: null,
        CvsBGrowthPointsDiff: null,
        ClientTotal: currentYearRaw.HourlyRateTotal,
        GrowthTotal:
          currentYearRaw.HourlyRateTotal !== 0 &&
          previousYearRaw.HourlyRateTotal !== 0
            ? this.mathService.differenceNumber(
                currentYearRaw.HourlyRateTotal,
                previousYearRaw.HourlyRateTotal
              )
            : null,
      };

      if (
        hourlyRentalRates.ClientGrowth !== null &&
        hourlyRentalRates.BenchmarkGrowth !== null
      ) {
        hourlyRentalRates.CvsBGrowthPointsDiff = this.mathService.substract(
          hourlyRentalRates.ClientGrowth,
          hourlyRentalRates.BenchmarkGrowth,
          1
        );
      }
      return new RentalGrowthData(
        startDate,
        this.type,
        unitsOnRent,
        unitsOnFleet,
        physicalUtil,
        unitCost,
        financialUtil,
        monthlyRentalRates,
        weeklyRentalRates,
        dailyRentalRates,
        hourlyRentalRates
      );
    }
    return null;
  }
}
