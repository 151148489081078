import { EventEmitter, Injectable } from '@angular/core';
import { Alert } from '../models';

@Injectable()
export class AlertNotificationService {
	public alertSelected$: EventEmitter<Alert>;

	constructor() {
		this.alertSelected$ = new EventEmitter<Alert>();
	}

	public alertSelected(alert: Alert) {
	    this.alertSelected$.emit(alert);
    }

}