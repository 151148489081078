import { Pipe, PipeTransform } from '@angular/core';
import { FormatService } from '../../core/query';

/*
 * Formats percent for display
 * Usage:
 *   numberValue | rdoPercent
*/
@Pipe({
    name: 'rdoPercent'
})
export class RdoPercentPipe implements PipeTransform {
    constructor(private formatService: FormatService) { }

    transform = (value: any): any => {
        return this.formatService.formatPercent(value);
    }
}