import { Injectable, EventEmitter, ElementRef, NgZone, ChangeDetectorRef, Input, Output, ViewChild, ContentChildren, Component, ChangeDetectionStrategy, HostListener, Directive, Optional, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimeTemplate, SharedModule } from 'primeng/api';
import { PaginatorModule } from 'primeng/paginator';
import { DomHandler } from 'primeng/dom';
import { ObjectUtils, FilterUtils } from 'primeng/utils';
import { Subject } from 'rxjs';
import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __param = this && this.__param || function (paramIndex, decorator) {
  return function (target, key) {
    decorator(target, key, paramIndex);
  };
};
let TableService = class TableService {
  constructor() {
    this.sortSource = new Subject();
    this.selectionSource = new Subject();
    this.contextMenuSource = new Subject();
    this.valueSource = new Subject();
    this.totalRecordsSource = new Subject();
    this.columnsSource = new Subject();
    this.sortSource$ = this.sortSource.asObservable();
    this.selectionSource$ = this.selectionSource.asObservable();
    this.contextMenuSource$ = this.contextMenuSource.asObservable();
    this.valueSource$ = this.valueSource.asObservable();
    this.totalRecordsSource$ = this.totalRecordsSource.asObservable();
    this.columnsSource$ = this.columnsSource.asObservable();
  }
  onSort(sortMeta) {
    this.sortSource.next(sortMeta);
  }
  onSelectionChange() {
    this.selectionSource.next();
  }
  onContextMenu(data) {
    this.contextMenuSource.next(data);
  }
  onValueChange(value) {
    this.valueSource.next(value);
  }
  onTotalRecordsChange(value) {
    this.totalRecordsSource.next(value);
  }
  onColumnsChange(columns) {
    this.columnsSource.next(columns);
  }
};
TableService = __decorate([Injectable()], TableService);
let Table = class Table {
  constructor(el, zone, tableService, cd) {
    this.el = el;
    this.zone = zone;
    this.tableService = tableService;
    this.cd = cd;
    this.pageLinks = 5;
    this.alwaysShowPaginator = true;
    this.paginatorPosition = 'bottom';
    this.paginatorDropdownScrollHeight = '200px';
    this.currentPageReportTemplate = '{currentPage} of {totalPages}';
    this.defaultSortOrder = 1;
    this.sortMode = 'single';
    this.resetPageOnSort = true;
    this.selectionChange = new EventEmitter();
    this.contextMenuSelectionChange = new EventEmitter();
    this.contextMenuSelectionMode = "separate";
    this.rowTrackBy = (index, item) => item;
    this.lazy = false;
    this.lazyLoadOnInit = true;
    this.compareSelectionBy = 'deepEquals';
    this.csvSeparator = ',';
    this.exportFilename = 'download';
    this.filters = {};
    this.filterDelay = 300;
    this.expandedRowKeys = {};
    this.editingRowKeys = {};
    this.rowExpandMode = 'multiple';
    this.virtualScrollDelay = 150;
    this.virtualRowHeight = 28;
    this.columnResizeMode = 'fit';
    this.loadingIcon = 'pi pi-spinner';
    this.showLoader = true;
    this.stateStorage = 'session';
    this.editMode = 'cell';
    this.onRowSelect = new EventEmitter();
    this.onRowUnselect = new EventEmitter();
    this.onPage = new EventEmitter();
    this.onSort = new EventEmitter();
    this.onFilter = new EventEmitter();
    this.onLazyLoad = new EventEmitter();
    this.onRowExpand = new EventEmitter();
    this.onRowCollapse = new EventEmitter();
    this.onContextMenuSelect = new EventEmitter();
    this.onColResize = new EventEmitter();
    this.onColReorder = new EventEmitter();
    this.onRowReorder = new EventEmitter();
    this.onEditInit = new EventEmitter();
    this.onEditComplete = new EventEmitter();
    this.onEditCancel = new EventEmitter();
    this.onHeaderCheckboxToggle = new EventEmitter();
    this.sortFunction = new EventEmitter();
    this.firstChange = new EventEmitter();
    this.rowsChange = new EventEmitter();
    this.onStateSave = new EventEmitter();
    this.onStateRestore = new EventEmitter();
    this._value = [];
    this._totalRecords = 0;
    this._first = 0;
    this.selectionKeys = {};
    this._sortOrder = 1;
  }
  ngOnInit() {
    if (this.lazy && this.lazyLoadOnInit) {
      if (!this.virtualScroll) {
        this.onLazyLoad.emit(this.createLazyLoadMetadata());
      }
      if (this.restoringFilter) {
        this.restoringFilter = false;
      }
    }
    this.initialized = true;
  }
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'caption':
          this.captionTemplate = item.template;
          break;
        case 'header':
          this.headerTemplate = item.template;
          break;
        case 'body':
          this.bodyTemplate = item.template;
          break;
        case 'loadingbody':
          this.loadingBodyTemplate = item.template;
          break;
        case 'footer':
          this.footerTemplate = item.template;
          break;
        case 'summary':
          this.summaryTemplate = item.template;
          break;
        case 'colgroup':
          this.colGroupTemplate = item.template;
          break;
        case 'rowexpansion':
          this.expandedRowTemplate = item.template;
          break;
        case 'frozenrows':
          this.frozenRowsTemplate = item.template;
          break;
        case 'frozenheader':
          this.frozenHeaderTemplate = item.template;
          break;
        case 'frozenbody':
          this.frozenBodyTemplate = item.template;
          break;
        case 'frozenfooter':
          this.frozenFooterTemplate = item.template;
          break;
        case 'frozencolgroup':
          this.frozenColGroupTemplate = item.template;
          break;
        case 'emptymessage':
          this.emptyMessageTemplate = item.template;
          break;
        case 'paginatorleft':
          this.paginatorLeftTemplate = item.template;
          break;
        case 'paginatorright':
          this.paginatorRightTemplate = item.template;
          break;
      }
    });
  }
  ngAfterViewInit() {
    if (this.isStateful() && this.resizableColumns) {
      this.restoreColumnWidths();
    }
  }
  clearCache() {
    if (this.scrollable) {
      if (this.scrollableViewChild) {
        this.scrollableViewChild.clearCache();
      }
      if (this.scrollableFrozenViewChild) {
        this.scrollableViewChild.clearCache();
      }
    }
  }
  ngOnChanges(simpleChange) {
    if (simpleChange.value) {
      if (this.isStateful() && !this.stateRestored) {
        this.restoreState();
      }
      this._value = simpleChange.value.currentValue;
      if (!this.lazy) {
        this.clearCache();
        this.totalRecords = this._value ? this._value.length : 0;
        if (this.sortMode == 'single' && this.sortField) this.sortSingle();else if (this.sortMode == 'multiple' && this.multiSortMeta) this.sortMultiple();else if (this.hasFilter())
          //sort already filters
          this._filter();
      }
      this.tableService.onValueChange(simpleChange.value.currentValue);
    }
    if (simpleChange.columns) {
      this._columns = simpleChange.columns.currentValue;
      this.tableService.onColumnsChange(simpleChange.columns.currentValue);
      if (this._columns && this.isStateful() && this.reorderableColumns && !this.columnOrderStateRestored) {
        this.restoreColumnOrder();
      }
    }
    if (simpleChange.sortField) {
      this._sortField = simpleChange.sortField.currentValue;
      //avoid triggering lazy load prior to lazy initialization at onInit
      if (!this.lazy || this.initialized) {
        if (this.sortMode === 'single') {
          this.sortSingle();
        }
      }
    }
    if (simpleChange.sortOrder) {
      this._sortOrder = simpleChange.sortOrder.currentValue;
      //avoid triggering lazy load prior to lazy initialization at onInit
      if (!this.lazy || this.initialized) {
        if (this.sortMode === 'single') {
          this.sortSingle();
        }
      }
    }
    if (simpleChange.multiSortMeta) {
      this._multiSortMeta = simpleChange.multiSortMeta.currentValue;
      if (this.sortMode === 'multiple') {
        this.sortMultiple();
      }
    }
    if (simpleChange.selection) {
      this._selection = simpleChange.selection.currentValue;
      if (!this.preventSelectionSetterPropagation) {
        this.updateSelectionKeys();
        this.tableService.onSelectionChange();
      }
      this.preventSelectionSetterPropagation = false;
    }
  }
  get value() {
    return this._value;
  }
  set value(val) {
    this._value = val;
  }
  get columns() {
    return this._columns;
  }
  set columns(cols) {
    this._columns = cols;
  }
  get first() {
    return this._first;
  }
  set first(val) {
    this._first = val;
  }
  get rows() {
    return this._rows;
  }
  set rows(val) {
    this._rows = val;
  }
  get totalRecords() {
    return this._totalRecords;
  }
  set totalRecords(val) {
    this._totalRecords = val;
    this.tableService.onTotalRecordsChange(this._totalRecords);
  }
  get sortField() {
    return this._sortField;
  }
  set sortField(val) {
    this._sortField = val;
  }
  get sortOrder() {
    return this._sortOrder;
  }
  set sortOrder(val) {
    this._sortOrder = val;
  }
  get multiSortMeta() {
    return this._multiSortMeta;
  }
  set multiSortMeta(val) {
    this._multiSortMeta = val;
  }
  get selection() {
    return this._selection;
  }
  set selection(val) {
    this._selection = val;
  }
  updateSelectionKeys() {
    if (this.dataKey && this._selection) {
      this.selectionKeys = {};
      if (Array.isArray(this._selection)) {
        for (let data of this._selection) {
          this.selectionKeys[String(ObjectUtils.resolveFieldData(data, this.dataKey))] = 1;
        }
      } else {
        this.selectionKeys[String(ObjectUtils.resolveFieldData(this._selection, this.dataKey))] = 1;
      }
    }
  }
  onPageChange(event) {
    this.first = event.first;
    this.rows = event.rows;
    if (this.lazy) {
      this.onLazyLoad.emit(this.createLazyLoadMetadata());
    }
    this.onPage.emit({
      first: this.first,
      rows: this.rows
    });
    this.firstChange.emit(this.first);
    this.rowsChange.emit(this.rows);
    this.tableService.onValueChange(this.value);
    if (this.isStateful()) {
      this.saveState();
    }
    this.anchorRowIndex = null;
    if (this.scrollable) {
      this.resetScrollTop();
    }
  }
  sort(event) {
    let originalEvent = event.originalEvent;
    if (this.sortMode === 'single') {
      this._sortOrder = this.sortField === event.field ? this.sortOrder * -1 : this.defaultSortOrder;
      this._sortField = event.field;
      this.sortSingle();
      if (this.resetPageOnSort) {
        this._first = 0;
        this.firstChange.emit(this._first);
        if (this.scrollable) {
          this.resetScrollTop();
        }
      }
    }
    if (this.sortMode === 'multiple') {
      let metaKey = originalEvent.metaKey || originalEvent.ctrlKey;
      let sortMeta = this.getSortMeta(event.field);
      if (sortMeta) {
        if (!metaKey) {
          this._multiSortMeta = [{
            field: event.field,
            order: sortMeta.order * -1
          }];
          if (this.resetPageOnSort) {
            this._first = 0;
            this.firstChange.emit(this._first);
            if (this.scrollable) {
              this.resetScrollTop();
            }
          }
        } else {
          sortMeta.order = sortMeta.order * -1;
        }
      } else {
        if (!metaKey || !this.multiSortMeta) {
          this._multiSortMeta = [];
          if (this.resetPageOnSort) {
            this._first = 0;
            this.firstChange.emit(this._first);
          }
        }
        this._multiSortMeta.push({
          field: event.field,
          order: this.defaultSortOrder
        });
      }
      this.sortMultiple();
    }
    if (this.isStateful()) {
      this.saveState();
    }
    this.anchorRowIndex = null;
  }
  sortSingle() {
    if (this.sortField && this.sortOrder) {
      if (this.restoringSort) {
        this.restoringSort = false;
      }
      if (this.lazy) {
        this.onLazyLoad.emit(this.createLazyLoadMetadata());
      } else if (this.value) {
        if (this.customSort) {
          this.sortFunction.emit({
            data: this.value,
            mode: this.sortMode,
            field: this.sortField,
            order: this.sortOrder
          });
        } else {
          this.value.sort((data1, data2) => {
            let value1 = ObjectUtils.resolveFieldData(data1, this.sortField);
            let value2 = ObjectUtils.resolveFieldData(data2, this.sortField);
            let result = null;
            if (value1 == null && value2 != null) result = -1;else if (value1 != null && value2 == null) result = 1;else if (value1 == null && value2 == null) result = 0;else if (typeof value1 === 'string' && typeof value2 === 'string') result = value1.localeCompare(value2);else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
            return this.sortOrder * result;
          });
        }
        if (this.hasFilter()) {
          this._filter();
        }
      }
      let sortMeta = {
        field: this.sortField,
        order: this.sortOrder
      };
      this.onSort.emit(sortMeta);
      this.tableService.onSort(sortMeta);
    }
  }
  sortMultiple() {
    if (this.multiSortMeta) {
      if (this.lazy) {
        this.onLazyLoad.emit(this.createLazyLoadMetadata());
      } else if (this.value) {
        if (this.customSort) {
          this.sortFunction.emit({
            data: this.value,
            mode: this.sortMode,
            multiSortMeta: this.multiSortMeta
          });
        } else {
          this.value.sort((data1, data2) => {
            return this.multisortField(data1, data2, this.multiSortMeta, 0);
          });
        }
        if (this.hasFilter()) {
          this._filter();
        }
      }
      this.onSort.emit({
        multisortmeta: this.multiSortMeta
      });
      this.tableService.onSort(this.multiSortMeta);
    }
  }
  multisortField(data1, data2, multiSortMeta, index) {
    let value1 = ObjectUtils.resolveFieldData(data1, multiSortMeta[index].field);
    let value2 = ObjectUtils.resolveFieldData(data2, multiSortMeta[index].field);
    let result = null;
    if (value1 == null && value2 != null) result = -1;else if (value1 != null && value2 == null) result = 1;else if (value1 == null && value2 == null) result = 0;else if (typeof value1 == 'string' || value1 instanceof String) {
      if (value1.localeCompare && value1 != value2) {
        return multiSortMeta[index].order * value1.localeCompare(value2);
      }
    } else {
      result = value1 < value2 ? -1 : 1;
    }
    if (value1 == value2) {
      return multiSortMeta.length - 1 > index ? this.multisortField(data1, data2, multiSortMeta, index + 1) : 0;
    }
    return multiSortMeta[index].order * result;
  }
  getSortMeta(field) {
    if (this.multiSortMeta && this.multiSortMeta.length) {
      for (let i = 0; i < this.multiSortMeta.length; i++) {
        if (this.multiSortMeta[i].field === field) {
          return this.multiSortMeta[i];
        }
      }
    }
    return null;
  }
  isSorted(field) {
    if (this.sortMode === 'single') {
      return this.sortField && this.sortField === field;
    } else if (this.sortMode === 'multiple') {
      let sorted = false;
      if (this.multiSortMeta) {
        for (let i = 0; i < this.multiSortMeta.length; i++) {
          if (this.multiSortMeta[i].field == field) {
            sorted = true;
            break;
          }
        }
      }
      return sorted;
    }
  }
  handleRowClick(event) {
    let target = event.originalEvent.target;
    let targetNode = target.nodeName;
    let parentNode = target.parentElement && target.parentElement.nodeName;
    if (targetNode == 'INPUT' || targetNode == 'BUTTON' || targetNode == 'A' || parentNode == 'INPUT' || parentNode == 'BUTTON' || parentNode == 'A' || DomHandler.hasClass(event.originalEvent.target, 'ui-clickable')) {
      return;
    }
    if (this.selectionMode) {
      this.preventSelectionSetterPropagation = true;
      if (this.isMultipleSelectionMode() && event.originalEvent.shiftKey && this.anchorRowIndex != null) {
        DomHandler.clearSelection();
        if (this.rangeRowIndex != null) {
          this.clearSelectionRange(event.originalEvent);
        }
        this.rangeRowIndex = event.rowIndex;
        this.selectRange(event.originalEvent, event.rowIndex);
      } else {
        let rowData = event.rowData;
        let selected = this.isSelected(rowData);
        let metaSelection = this.rowTouched ? false : this.metaKeySelection;
        let dataKeyValue = this.dataKey ? String(ObjectUtils.resolveFieldData(rowData, this.dataKey)) : null;
        this.anchorRowIndex = event.rowIndex;
        this.rangeRowIndex = event.rowIndex;
        if (metaSelection) {
          let metaKey = event.originalEvent.metaKey || event.originalEvent.ctrlKey;
          if (selected && metaKey) {
            if (this.isSingleSelectionMode()) {
              this._selection = null;
              this.selectionKeys = {};
              this.selectionChange.emit(null);
            } else {
              let selectionIndex = this.findIndexInSelection(rowData);
              this._selection = this.selection.filter((val, i) => i != selectionIndex);
              this.selectionChange.emit(this.selection);
              if (dataKeyValue) {
                delete this.selectionKeys[dataKeyValue];
              }
            }
            this.onRowUnselect.emit({
              originalEvent: event.originalEvent,
              data: rowData,
              type: 'row'
            });
          } else {
            if (this.isSingleSelectionMode()) {
              this._selection = rowData;
              this.selectionChange.emit(rowData);
              if (dataKeyValue) {
                this.selectionKeys = {};
                this.selectionKeys[dataKeyValue] = 1;
              }
            } else if (this.isMultipleSelectionMode()) {
              if (metaKey) {
                this._selection = this.selection || [];
              } else {
                this._selection = [];
                this.selectionKeys = {};
              }
              this._selection = [...this.selection, rowData];
              this.selectionChange.emit(this.selection);
              if (dataKeyValue) {
                this.selectionKeys[dataKeyValue] = 1;
              }
            }
            this.onRowSelect.emit({
              originalEvent: event.originalEvent,
              data: rowData,
              type: 'row',
              index: event.rowIndex
            });
          }
        } else {
          if (this.selectionMode === 'single') {
            if (selected) {
              this._selection = null;
              this.selectionKeys = {};
              this.selectionChange.emit(this.selection);
              this.onRowUnselect.emit({
                originalEvent: event.originalEvent,
                data: rowData,
                type: 'row'
              });
            } else {
              this._selection = rowData;
              this.selectionChange.emit(this.selection);
              this.onRowSelect.emit({
                originalEvent: event.originalEvent,
                data: rowData,
                type: 'row',
                index: event.rowIndex
              });
              if (dataKeyValue) {
                this.selectionKeys = {};
                this.selectionKeys[dataKeyValue] = 1;
              }
            }
          } else if (this.selectionMode === 'multiple') {
            if (selected) {
              let selectionIndex = this.findIndexInSelection(rowData);
              this._selection = this.selection.filter((val, i) => i != selectionIndex);
              this.selectionChange.emit(this.selection);
              this.onRowUnselect.emit({
                originalEvent: event.originalEvent,
                data: rowData,
                type: 'row'
              });
              if (dataKeyValue) {
                delete this.selectionKeys[dataKeyValue];
              }
            } else {
              this._selection = this.selection ? [...this.selection, rowData] : [rowData];
              this.selectionChange.emit(this.selection);
              this.onRowSelect.emit({
                originalEvent: event.originalEvent,
                data: rowData,
                type: 'row',
                index: event.rowIndex
              });
              if (dataKeyValue) {
                this.selectionKeys[dataKeyValue] = 1;
              }
            }
          }
        }
      }
      this.tableService.onSelectionChange();
      if (this.isStateful()) {
        this.saveState();
      }
    }
    this.rowTouched = false;
  }
  handleRowTouchEnd(event) {
    this.rowTouched = true;
  }
  handleRowRightClick(event) {
    if (this.contextMenu) {
      const rowData = event.rowData;
      if (this.contextMenuSelectionMode === 'separate') {
        this.contextMenuSelection = rowData;
        this.contextMenuSelectionChange.emit(rowData);
        this.onContextMenuSelect.emit({
          originalEvent: event.originalEvent,
          data: rowData,
          index: event.rowIndex
        });
        this.contextMenu.show(event.originalEvent);
        this.tableService.onContextMenu(rowData);
      } else if (this.contextMenuSelectionMode === 'joint') {
        this.preventSelectionSetterPropagation = true;
        let selected = this.isSelected(rowData);
        let dataKeyValue = this.dataKey ? String(ObjectUtils.resolveFieldData(rowData, this.dataKey)) : null;
        if (!selected) {
          if (this.isSingleSelectionMode()) {
            this.selection = rowData;
            this.selectionChange.emit(rowData);
          } else if (this.isMultipleSelectionMode()) {
            this.selection = [rowData];
            this.selectionChange.emit(this.selection);
          }
          if (dataKeyValue) {
            this.selectionKeys[dataKeyValue] = 1;
          }
        }
        this.contextMenu.show(event.originalEvent);
        this.onContextMenuSelect.emit({
          originalEvent: event,
          data: rowData,
          index: event.rowIndex
        });
      }
    }
  }
  selectRange(event, rowIndex) {
    let rangeStart, rangeEnd;
    if (this.anchorRowIndex > rowIndex) {
      rangeStart = rowIndex;
      rangeEnd = this.anchorRowIndex;
    } else if (this.anchorRowIndex < rowIndex) {
      rangeStart = this.anchorRowIndex;
      rangeEnd = rowIndex;
    } else {
      rangeStart = rowIndex;
      rangeEnd = rowIndex;
    }
    if (this.lazy && this.paginator) {
      rangeStart -= this.first;
      rangeEnd -= this.first;
    }
    for (let i = rangeStart; i <= rangeEnd; i++) {
      let rangeRowData = this.filteredValue ? this.filteredValue[i] : this.value[i];
      if (!this.isSelected(rangeRowData)) {
        this._selection = [...this.selection, rangeRowData];
        let dataKeyValue = this.dataKey ? String(ObjectUtils.resolveFieldData(rangeRowData, this.dataKey)) : null;
        if (dataKeyValue) {
          this.selectionKeys[dataKeyValue] = 1;
        }
        this.onRowSelect.emit({
          originalEvent: event,
          data: rangeRowData,
          type: 'row'
        });
      }
    }
    this.selectionChange.emit(this.selection);
  }
  clearSelectionRange(event) {
    let rangeStart, rangeEnd;
    if (this.rangeRowIndex > this.anchorRowIndex) {
      rangeStart = this.anchorRowIndex;
      rangeEnd = this.rangeRowIndex;
    } else if (this.rangeRowIndex < this.anchorRowIndex) {
      rangeStart = this.rangeRowIndex;
      rangeEnd = this.anchorRowIndex;
    } else {
      rangeStart = this.rangeRowIndex;
      rangeEnd = this.rangeRowIndex;
    }
    for (let i = rangeStart; i <= rangeEnd; i++) {
      let rangeRowData = this.value[i];
      let selectionIndex = this.findIndexInSelection(rangeRowData);
      this._selection = this.selection.filter((val, i) => i != selectionIndex);
      let dataKeyValue = this.dataKey ? String(ObjectUtils.resolveFieldData(rangeRowData, this.dataKey)) : null;
      if (dataKeyValue) {
        delete this.selectionKeys[dataKeyValue];
      }
      this.onRowUnselect.emit({
        originalEvent: event,
        data: rangeRowData,
        type: 'row'
      });
    }
  }
  isSelected(rowData) {
    if (rowData && this.selection) {
      if (this.dataKey) {
        return this.selectionKeys[ObjectUtils.resolveFieldData(rowData, this.dataKey)] !== undefined;
      } else {
        if (this.selection instanceof Array) return this.findIndexInSelection(rowData) > -1;else return this.equals(rowData, this.selection);
      }
    }
    return false;
  }
  findIndexInSelection(rowData) {
    let index = -1;
    if (this.selection && this.selection.length) {
      for (let i = 0; i < this.selection.length; i++) {
        if (this.equals(rowData, this.selection[i])) {
          index = i;
          break;
        }
      }
    }
    return index;
  }
  toggleRowWithRadio(event, rowData) {
    this.preventSelectionSetterPropagation = true;
    if (this.selection != rowData) {
      this._selection = rowData;
      this.selectionChange.emit(this.selection);
      this.onRowSelect.emit({
        originalEvent: event.originalEvent,
        index: event.rowIndex,
        data: rowData,
        type: 'radiobutton'
      });
      if (this.dataKey) {
        this.selectionKeys = {};
        this.selectionKeys[String(ObjectUtils.resolveFieldData(rowData, this.dataKey))] = 1;
      }
    } else {
      this._selection = null;
      this.selectionChange.emit(this.selection);
      this.onRowUnselect.emit({
        originalEvent: event.originalEvent,
        index: event.rowIndex,
        data: rowData,
        type: 'radiobutton'
      });
    }
    this.tableService.onSelectionChange();
    if (this.isStateful()) {
      this.saveState();
    }
  }
  toggleRowWithCheckbox(event, rowData) {
    this.selection = this.selection || [];
    let selected = this.isSelected(rowData);
    let dataKeyValue = this.dataKey ? String(ObjectUtils.resolveFieldData(rowData, this.dataKey)) : null;
    this.preventSelectionSetterPropagation = true;
    if (selected) {
      let selectionIndex = this.findIndexInSelection(rowData);
      this._selection = this.selection.filter((val, i) => i != selectionIndex);
      this.selectionChange.emit(this.selection);
      this.onRowUnselect.emit({
        originalEvent: event.originalEvent,
        index: event.rowIndex,
        data: rowData,
        type: 'checkbox'
      });
      if (dataKeyValue) {
        delete this.selectionKeys[dataKeyValue];
      }
    } else {
      this._selection = this.selection ? [...this.selection, rowData] : [rowData];
      this.selectionChange.emit(this.selection);
      this.onRowSelect.emit({
        originalEvent: event.originalEvent,
        index: event.rowIndex,
        data: rowData,
        type: 'checkbox'
      });
      if (dataKeyValue) {
        this.selectionKeys[dataKeyValue] = 1;
      }
    }
    this.tableService.onSelectionChange();
    if (this.isStateful()) {
      this.saveState();
    }
  }
  toggleRowsWithCheckbox(event, check) {
    this._selection = check ? this.filteredValue ? this.filteredValue.slice() : this.value.slice() : [];
    this.preventSelectionSetterPropagation = true;
    this.updateSelectionKeys();
    this.selectionChange.emit(this._selection);
    this.tableService.onSelectionChange();
    this.onHeaderCheckboxToggle.emit({
      originalEvent: event,
      checked: check
    });
    if (this.isStateful()) {
      this.saveState();
    }
  }
  equals(data1, data2) {
    return this.compareSelectionBy === 'equals' ? data1 === data2 : ObjectUtils.equals(data1, data2, this.dataKey);
  }
  filter(value, field, matchMode) {
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout);
    }
    if (!this.isFilterBlank(value)) {
      this.filters[field] = {
        value: value,
        matchMode: matchMode
      };
    } else if (this.filters[field]) {
      delete this.filters[field];
    }
    this.filterTimeout = setTimeout(() => {
      this._filter();
      this.filterTimeout = null;
    }, this.filterDelay);
    this.anchorRowIndex = null;
  }
  filterGlobal(value, matchMode) {
    this.filter(value, 'global', matchMode);
  }
  isFilterBlank(filter) {
    if (filter !== null && filter !== undefined) {
      if (typeof filter === 'string' && filter.trim().length == 0 || filter instanceof Array && filter.length == 0) return true;else return false;
    }
    return true;
  }
  _filter() {
    if (!this.restoringFilter) {
      this.first = 0;
      this.firstChange.emit(this.first);
    }
    if (this.lazy) {
      this.onLazyLoad.emit(this.createLazyLoadMetadata());
    } else {
      if (!this.value) {
        return;
      }
      if (!this.hasFilter()) {
        this.filteredValue = null;
        if (this.paginator) {
          this.totalRecords = this.value ? this.value.length : 0;
        }
      } else {
        let globalFilterFieldsArray;
        if (this.filters['global']) {
          if (!this.columns && !this.globalFilterFields) throw new Error('Global filtering requires dynamic columns or globalFilterFields to be defined.');else globalFilterFieldsArray = this.globalFilterFields || this.columns;
        }
        this.filteredValue = [];
        for (let i = 0; i < this.value.length; i++) {
          let localMatch = true;
          let globalMatch = false;
          let localFiltered = false;
          for (let prop in this.filters) {
            if (this.filters.hasOwnProperty(prop) && prop !== 'global') {
              localFiltered = true;
              let filterMeta = this.filters[prop];
              let filterField = prop;
              let filterValue = filterMeta.value;
              let filterMatchMode = filterMeta.matchMode || 'startsWith';
              let dataFieldValue = ObjectUtils.resolveFieldData(this.value[i], filterField);
              let filterConstraint = FilterUtils[filterMatchMode];
              if (!filterConstraint(dataFieldValue, filterValue, this.filterLocale)) {
                localMatch = false;
              }
              if (!localMatch) {
                break;
              }
            }
          }
          if (this.filters['global'] && !globalMatch && globalFilterFieldsArray) {
            for (let j = 0; j < globalFilterFieldsArray.length; j++) {
              let globalFilterField = globalFilterFieldsArray[j].field || globalFilterFieldsArray[j];
              globalMatch = FilterUtils[this.filters['global'].matchMode](ObjectUtils.resolveFieldData(this.value[i], globalFilterField), this.filters['global'].value, this.filterLocale);
              if (globalMatch) {
                break;
              }
            }
          }
          let matches;
          if (this.filters['global']) {
            matches = localFiltered ? localFiltered && localMatch && globalMatch : globalMatch;
          } else {
            matches = localFiltered && localMatch;
          }
          if (matches) {
            this.filteredValue.push(this.value[i]);
          }
        }
        if (this.filteredValue.length === this.value.length) {
          this.filteredValue = null;
        }
        if (this.paginator) {
          this.totalRecords = this.filteredValue ? this.filteredValue.length : this.value ? this.value.length : 0;
        }
      }
    }
    this.onFilter.emit({
      filters: this.filters,
      filteredValue: this.filteredValue || this.value
    });
    this.tableService.onValueChange(this.value);
    if (this.isStateful() && !this.restoringFilter) {
      this.saveState();
    }
    if (this.restoringFilter) {
      this.restoringFilter = false;
    }
    this.cd.markForCheck();
    if (this.scrollable) {
      this.resetScrollTop();
    }
  }
  hasFilter() {
    let empty = true;
    for (let prop in this.filters) {
      if (this.filters.hasOwnProperty(prop)) {
        empty = false;
        break;
      }
    }
    return !empty;
  }
  createLazyLoadMetadata() {
    return {
      first: this.first,
      rows: this.rows,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      filters: this.filters,
      globalFilter: this.filters && this.filters['global'] ? this.filters['global'].value : null,
      multiSortMeta: this.multiSortMeta
    };
  }
  reset() {
    this._sortField = null;
    this._sortOrder = this.defaultSortOrder;
    this._multiSortMeta = null;
    this.tableService.onSort(null);
    this.filteredValue = null;
    this.filters = {};
    this.first = 0;
    this.firstChange.emit(this.first);
    if (this.lazy) {
      this.onLazyLoad.emit(this.createLazyLoadMetadata());
    } else {
      this.totalRecords = this._value ? this._value.length : 0;
    }
  }
  exportCSV(options) {
    let data = this.filteredValue || this.value;
    let csv = '';
    if (options && options.selectionOnly) {
      data = this.selection || [];
    }
    //headers
    for (let i = 0; i < this.columns.length; i++) {
      let column = this.columns[i];
      if (column.exportable !== false && column.field) {
        csv += '"' + (column.header || column.field) + '"';
        if (i < this.columns.length - 1) {
          csv += this.csvSeparator;
        }
      }
    }
    //body
    data.forEach((record, i) => {
      csv += '\n';
      for (let i = 0; i < this.columns.length; i++) {
        let column = this.columns[i];
        if (column.exportable !== false && column.field) {
          let cellData = ObjectUtils.resolveFieldData(record, column.field);
          if (cellData != null) {
            if (this.exportFunction) {
              cellData = this.exportFunction({
                data: cellData,
                field: column.field
              });
            } else cellData = String(cellData).replace(/"/g, '""');
          } else cellData = '';
          csv += '"' + cellData + '"';
          if (i < this.columns.length - 1) {
            csv += this.csvSeparator;
          }
        }
      }
    });
    let blob = new Blob([csv], {
      type: 'text/csv;charset=utf-8;'
    });
    if (window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, this.exportFilename + '.csv');
    } else {
      let link = document.createElement("a");
      link.style.display = 'none';
      document.body.appendChild(link);
      if (link.download !== undefined) {
        link.setAttribute('href', URL.createObjectURL(blob));
        link.setAttribute('download', this.exportFilename + '.csv');
        link.click();
      } else {
        csv = 'data:text/csv;charset=utf-8,' + csv;
        window.open(encodeURI(csv));
      }
      document.body.removeChild(link);
    }
  }
  resetScrollTop() {
    if (this.virtualScroll) this.scrollToVirtualIndex(0);else this.scrollTo({
      top: 0
    });
  }
  scrollToVirtualIndex(index) {
    if (this.scrollableViewChild) {
      this.scrollableViewChild.scrollToVirtualIndex(index);
    }
    if (this.scrollableFrozenViewChild) {
      this.scrollableFrozenViewChild.scrollToVirtualIndex(index);
    }
  }
  scrollTo(options) {
    if (this.scrollableViewChild) {
      this.scrollableViewChild.scrollTo(options);
    }
    if (this.scrollableFrozenViewChild) {
      this.scrollableFrozenViewChild.scrollTo(options);
    }
  }
  updateEditingCell(cell, data, field, index) {
    this.editingCell = cell;
    this.editingCellData = data;
    this.editingCellField = field;
    this.editingCellRowIndex = index;
    this.bindDocumentEditListener();
  }
  isEditingCellValid() {
    return this.editingCell && DomHandler.find(this.editingCell, '.ng-invalid.ng-dirty').length === 0;
  }
  bindDocumentEditListener() {
    if (!this.documentEditListener) {
      this.documentEditListener = event => {
        if (this.editingCell && !this.editingCellClick && this.isEditingCellValid()) {
          DomHandler.removeClass(this.editingCell, 'ui-editing-cell');
          this.editingCell = null;
          this.onEditComplete.emit({
            field: this.editingCellField,
            data: this.editingCellData,
            originalEvent: event,
            index: this.editingCellRowIndex
          });
          this.editingCellField = null;
          this.editingCellData = null;
          this.editingCellRowIndex = null;
          this.unbindDocumentEditListener();
        }
        this.editingCellClick = false;
      };
      document.addEventListener('click', this.documentEditListener);
    }
  }
  unbindDocumentEditListener() {
    if (this.documentEditListener) {
      document.removeEventListener('click', this.documentEditListener);
      this.documentEditListener = null;
    }
  }
  initRowEdit(rowData) {
    let dataKeyValue = String(ObjectUtils.resolveFieldData(rowData, this.dataKey));
    this.editingRowKeys[dataKeyValue] = true;
  }
  saveRowEdit(rowData, rowElement) {
    if (DomHandler.find(rowElement, '.ng-invalid.ng-dirty').length === 0) {
      let dataKeyValue = String(ObjectUtils.resolveFieldData(rowData, this.dataKey));
      delete this.editingRowKeys[dataKeyValue];
    }
  }
  cancelRowEdit(rowData) {
    let dataKeyValue = String(ObjectUtils.resolveFieldData(rowData, this.dataKey));
    delete this.editingRowKeys[dataKeyValue];
  }
  toggleRow(rowData, event) {
    if (!this.dataKey) {
      throw new Error('dataKey must be defined to use row expansion');
    }
    let dataKeyValue = String(ObjectUtils.resolveFieldData(rowData, this.dataKey));
    if (this.expandedRowKeys[dataKeyValue] != null) {
      delete this.expandedRowKeys[dataKeyValue];
      this.onRowCollapse.emit({
        originalEvent: event,
        data: rowData
      });
    } else {
      if (this.rowExpandMode === 'single') {
        this.expandedRowKeys = {};
      }
      this.expandedRowKeys[dataKeyValue] = true;
      this.onRowExpand.emit({
        originalEvent: event,
        data: rowData
      });
    }
    if (event) {
      event.preventDefault();
    }
    if (this.isStateful()) {
      this.saveState();
    }
  }
  isRowExpanded(rowData) {
    return this.expandedRowKeys[String(ObjectUtils.resolveFieldData(rowData, this.dataKey))] === true;
  }
  isRowEditing(rowData) {
    return this.editingRowKeys[String(ObjectUtils.resolveFieldData(rowData, this.dataKey))] === true;
  }
  isSingleSelectionMode() {
    return this.selectionMode === 'single';
  }
  isMultipleSelectionMode() {
    return this.selectionMode === 'multiple';
  }
  onColumnResizeBegin(event) {
    let containerLeft = DomHandler.getOffset(this.containerViewChild.nativeElement).left;
    this.lastResizerHelperX = event.pageX - containerLeft + this.containerViewChild.nativeElement.scrollLeft;
    this.onColumnResize(event);
    event.preventDefault();
  }
  onColumnResize(event) {
    let containerLeft = DomHandler.getOffset(this.containerViewChild.nativeElement).left;
    DomHandler.addClass(this.containerViewChild.nativeElement, 'ui-unselectable-text');
    this.resizeHelperViewChild.nativeElement.style.height = this.containerViewChild.nativeElement.offsetHeight + 'px';
    this.resizeHelperViewChild.nativeElement.style.top = 0 + 'px';
    this.resizeHelperViewChild.nativeElement.style.left = event.pageX - containerLeft + this.containerViewChild.nativeElement.scrollLeft + 'px';
    this.resizeHelperViewChild.nativeElement.style.display = 'block';
  }
  onColumnResizeEnd(event, column) {
    let delta = this.resizeHelperViewChild.nativeElement.offsetLeft - this.lastResizerHelperX;
    let columnWidth = column.offsetWidth;
    let minWidth = parseInt(column.style.minWidth || 15);
    if (columnWidth + delta < minWidth) {
      delta = minWidth - columnWidth;
    }
    const newColumnWidth = columnWidth + delta;
    if (newColumnWidth >= minWidth) {
      if (this.columnResizeMode === 'fit') {
        let nextColumn = column.nextElementSibling;
        while (!nextColumn.offsetParent) {
          nextColumn = nextColumn.nextElementSibling;
        }
        if (nextColumn) {
          let nextColumnWidth = nextColumn.offsetWidth - delta;
          let nextColumnMinWidth = nextColumn.style.minWidth || 15;
          if (newColumnWidth > 15 && nextColumnWidth > parseInt(nextColumnMinWidth)) {
            if (this.scrollable) {
              let scrollableView = this.findParentScrollableView(column);
              let scrollableBodyTable = DomHandler.findSingle(scrollableView, '.ui-table-scrollable-body table');
              let scrollableHeaderTable = DomHandler.findSingle(scrollableView, 'table.ui-table-scrollable-header-table');
              let scrollableFooterTable = DomHandler.findSingle(scrollableView, 'table.ui-table-scrollable-footer-table');
              let resizeColumnIndex = DomHandler.index(column);
              this.resizeColGroup(scrollableHeaderTable, resizeColumnIndex, newColumnWidth, nextColumnWidth);
              this.resizeColGroup(scrollableBodyTable, resizeColumnIndex, newColumnWidth, nextColumnWidth);
              this.resizeColGroup(scrollableFooterTable, resizeColumnIndex, newColumnWidth, nextColumnWidth);
            } else {
              column.style.width = newColumnWidth + 'px';
              if (nextColumn) {
                nextColumn.style.width = nextColumnWidth + 'px';
              }
            }
          }
        }
      } else if (this.columnResizeMode === 'expand') {
        if (newColumnWidth > minWidth) {
          if (this.scrollable) {
            this.setScrollableItemsWidthOnExpandResize(column, newColumnWidth, delta);
          } else {
            this.tableViewChild.nativeElement.style.width = this.tableViewChild.nativeElement.offsetWidth + delta + 'px';
            column.style.width = newColumnWidth + 'px';
            let containerWidth = this.tableViewChild.nativeElement.style.width;
            this.containerViewChild.nativeElement.style.width = containerWidth + 'px';
          }
        }
      }
      this.onColResize.emit({
        element: column,
        delta: delta
      });
      if (this.isStateful()) {
        this.saveState();
      }
    }
    this.resizeHelperViewChild.nativeElement.style.display = 'none';
    DomHandler.removeClass(this.containerViewChild.nativeElement, 'ui-unselectable-text');
  }
  setScrollableItemsWidthOnExpandResize(column, newColumnWidth, delta) {
    let scrollableView = column ? this.findParentScrollableView(column) : this.containerViewChild.nativeElement;
    let scrollableBody = DomHandler.findSingle(scrollableView, '.ui-table-scrollable-body');
    let scrollableHeader = DomHandler.findSingle(scrollableView, '.ui-table-scrollable-header');
    let scrollableFooter = DomHandler.findSingle(scrollableView, '.ui-table-scrollable-footer');
    let scrollableBodyTable = DomHandler.findSingle(scrollableBody, '.ui-table-scrollable-body table');
    let scrollableHeaderTable = DomHandler.findSingle(scrollableHeader, 'table.ui-table-scrollable-header-table');
    let scrollableFooterTable = DomHandler.findSingle(scrollableFooter, 'table.ui-table-scrollable-footer-table');
    const scrollableBodyTableWidth = column ? scrollableBodyTable.offsetWidth + delta : newColumnWidth;
    const scrollableHeaderTableWidth = column ? scrollableHeaderTable.offsetWidth + delta : newColumnWidth;
    const isContainerInViewport = this.containerViewChild.nativeElement.offsetWidth >= scrollableBodyTableWidth;
    let setWidth = (container, table, width, isContainerInViewport) => {
      if (container && table) {
        container.style.width = isContainerInViewport ? width + DomHandler.calculateScrollbarWidth(scrollableBody) + 'px' : 'auto';
        table.style.width = width + 'px';
      }
    };
    setWidth(scrollableBody, scrollableBodyTable, scrollableBodyTableWidth, isContainerInViewport);
    setWidth(scrollableHeader, scrollableHeaderTable, scrollableHeaderTableWidth, isContainerInViewport);
    setWidth(scrollableFooter, scrollableFooterTable, scrollableHeaderTableWidth, isContainerInViewport);
    if (column) {
      let resizeColumnIndex = DomHandler.index(column);
      this.resizeColGroup(scrollableHeaderTable, resizeColumnIndex, newColumnWidth, null);
      this.resizeColGroup(scrollableBodyTable, resizeColumnIndex, newColumnWidth, null);
      this.resizeColGroup(scrollableFooterTable, resizeColumnIndex, newColumnWidth, null);
    }
  }
  findParentScrollableView(column) {
    if (column) {
      let parent = column.parentElement;
      while (parent && !DomHandler.hasClass(parent, 'ui-table-scrollable-view')) {
        parent = parent.parentElement;
      }
      return parent;
    } else {
      return null;
    }
  }
  resizeColGroup(table, resizeColumnIndex, newColumnWidth, nextColumnWidth) {
    if (table) {
      let colGroup = table.children[0].nodeName === 'COLGROUP' ? table.children[0] : null;
      if (colGroup) {
        let col = colGroup.children[resizeColumnIndex];
        let nextCol = col.nextElementSibling;
        col.style.width = newColumnWidth + 'px';
        if (nextCol && nextColumnWidth) {
          nextCol.style.width = nextColumnWidth + 'px';
        }
      } else {
        throw "Scrollable tables require a colgroup to support resizable columns";
      }
    }
  }
  onColumnDragStart(event, columnElement) {
    this.reorderIconWidth = DomHandler.getHiddenElementOuterWidth(this.reorderIndicatorUpViewChild.nativeElement);
    this.reorderIconHeight = DomHandler.getHiddenElementOuterHeight(this.reorderIndicatorDownViewChild.nativeElement);
    this.draggedColumn = columnElement;
    event.dataTransfer.setData('text', 'b'); // For firefox
  }
  onColumnDragEnter(event, dropHeader) {
    if (this.reorderableColumns && this.draggedColumn && dropHeader) {
      event.preventDefault();
      let containerOffset = DomHandler.getOffset(this.containerViewChild.nativeElement);
      let dropHeaderOffset = DomHandler.getOffset(dropHeader);
      if (this.draggedColumn != dropHeader) {
        let dragIndex = DomHandler.indexWithinGroup(this.draggedColumn, 'preorderablecolumn');
        let dropIndex = DomHandler.indexWithinGroup(dropHeader, 'preorderablecolumn');
        let targetLeft = dropHeaderOffset.left - containerOffset.left;
        let targetTop = containerOffset.top - dropHeaderOffset.top;
        let columnCenter = dropHeaderOffset.left + dropHeader.offsetWidth / 2;
        this.reorderIndicatorUpViewChild.nativeElement.style.top = dropHeaderOffset.top - containerOffset.top - (this.reorderIconHeight - 1) + 'px';
        this.reorderIndicatorDownViewChild.nativeElement.style.top = dropHeaderOffset.top - containerOffset.top + dropHeader.offsetHeight + 'px';
        if (event.pageX > columnCenter) {
          this.reorderIndicatorUpViewChild.nativeElement.style.left = targetLeft + dropHeader.offsetWidth - Math.ceil(this.reorderIconWidth / 2) + 'px';
          this.reorderIndicatorDownViewChild.nativeElement.style.left = targetLeft + dropHeader.offsetWidth - Math.ceil(this.reorderIconWidth / 2) + 'px';
          this.dropPosition = 1;
        } else {
          this.reorderIndicatorUpViewChild.nativeElement.style.left = targetLeft - Math.ceil(this.reorderIconWidth / 2) + 'px';
          this.reorderIndicatorDownViewChild.nativeElement.style.left = targetLeft - Math.ceil(this.reorderIconWidth / 2) + 'px';
          this.dropPosition = -1;
        }
        if (dropIndex - dragIndex === 1 && this.dropPosition === -1 || dropIndex - dragIndex === -1 && this.dropPosition === 1) {
          this.reorderIndicatorUpViewChild.nativeElement.style.display = 'none';
          this.reorderIndicatorDownViewChild.nativeElement.style.display = 'none';
        } else {
          this.reorderIndicatorUpViewChild.nativeElement.style.display = 'block';
          this.reorderIndicatorDownViewChild.nativeElement.style.display = 'block';
        }
      } else {
        event.dataTransfer.dropEffect = 'none';
      }
    }
  }
  onColumnDragLeave(event) {
    if (this.reorderableColumns && this.draggedColumn) {
      event.preventDefault();
      this.reorderIndicatorUpViewChild.nativeElement.style.display = 'none';
      this.reorderIndicatorDownViewChild.nativeElement.style.display = 'none';
    }
  }
  onColumnDrop(event, dropColumn) {
    event.preventDefault();
    if (this.draggedColumn) {
      let dragIndex = DomHandler.indexWithinGroup(this.draggedColumn, 'preorderablecolumn');
      let dropIndex = DomHandler.indexWithinGroup(dropColumn, 'preorderablecolumn');
      let allowDrop = dragIndex != dropIndex;
      if (allowDrop && (dropIndex - dragIndex == 1 && this.dropPosition === -1 || dragIndex - dropIndex == 1 && this.dropPosition === 1)) {
        allowDrop = false;
      }
      if (allowDrop && dropIndex < dragIndex && this.dropPosition === 1) {
        dropIndex = dropIndex + 1;
      }
      if (allowDrop && dropIndex > dragIndex && this.dropPosition === -1) {
        dropIndex = dropIndex - 1;
      }
      if (allowDrop) {
        ObjectUtils.reorderArray(this.columns, dragIndex, dropIndex);
        this.onColReorder.emit({
          dragIndex: dragIndex,
          dropIndex: dropIndex,
          columns: this.columns
        });
        if (this.isStateful()) {
          this.zone.runOutsideAngular(() => {
            setTimeout(() => {
              this.saveState();
            });
          });
        }
      }
      this.reorderIndicatorUpViewChild.nativeElement.style.display = 'none';
      this.reorderIndicatorDownViewChild.nativeElement.style.display = 'none';
      this.draggedColumn.draggable = false;
      this.draggedColumn = null;
      this.dropPosition = null;
    }
  }
  onRowDragStart(event, index) {
    this.rowDragging = true;
    this.draggedRowIndex = index;
    event.dataTransfer.setData('text', 'b'); // For firefox
  }
  onRowDragOver(event, index, rowElement) {
    if (this.rowDragging && this.draggedRowIndex !== index) {
      let rowY = DomHandler.getOffset(rowElement).top + DomHandler.getWindowScrollTop();
      let pageY = event.pageY;
      let rowMidY = rowY + DomHandler.getOuterHeight(rowElement) / 2;
      let prevRowElement = rowElement.previousElementSibling;
      if (pageY < rowMidY) {
        DomHandler.removeClass(rowElement, 'ui-table-dragpoint-bottom');
        this.droppedRowIndex = index;
        if (prevRowElement) DomHandler.addClass(prevRowElement, 'ui-table-dragpoint-bottom');else DomHandler.addClass(rowElement, 'ui-table-dragpoint-top');
      } else {
        if (prevRowElement) DomHandler.removeClass(prevRowElement, 'ui-table-dragpoint-bottom');else DomHandler.addClass(rowElement, 'ui-table-dragpoint-top');
        this.droppedRowIndex = index + 1;
        DomHandler.addClass(rowElement, 'ui-table-dragpoint-bottom');
      }
    }
  }
  onRowDragLeave(event, rowElement) {
    let prevRowElement = rowElement.previousElementSibling;
    if (prevRowElement) {
      DomHandler.removeClass(prevRowElement, 'ui-table-dragpoint-bottom');
    }
    DomHandler.removeClass(rowElement, 'ui-table-dragpoint-bottom');
    DomHandler.removeClass(rowElement, 'ui-table-dragpoint-top');
  }
  onRowDragEnd(event) {
    this.rowDragging = false;
    this.draggedRowIndex = null;
    this.droppedRowIndex = null;
  }
  onRowDrop(event, rowElement) {
    if (this.droppedRowIndex != null) {
      let dropIndex = this.draggedRowIndex > this.droppedRowIndex ? this.droppedRowIndex : this.droppedRowIndex === 0 ? 0 : this.droppedRowIndex - 1;
      ObjectUtils.reorderArray(this.value, this.draggedRowIndex, dropIndex);
      this.onRowReorder.emit({
        dragIndex: this.draggedRowIndex,
        dropIndex: dropIndex
      });
    }
    //cleanup
    this.onRowDragLeave(event, rowElement);
    this.onRowDragEnd(event);
  }
  isEmpty() {
    let data = this.filteredValue || this.value;
    return data == null || data.length == 0;
  }
  getBlockableElement() {
    return this.el.nativeElement.children[0];
  }
  getStorage() {
    switch (this.stateStorage) {
      case 'local':
        return window.localStorage;
      case 'session':
        return window.sessionStorage;
      default:
        throw new Error(this.stateStorage + ' is not a valid value for the state storage, supported values are "local" and "session".');
    }
  }
  isStateful() {
    return this.stateKey != null;
  }
  saveState() {
    const storage = this.getStorage();
    let state = {};
    if (this.paginator) {
      state.first = this.first;
      state.rows = this.rows;
    }
    if (this.sortField) {
      state.sortField = this.sortField;
      state.sortOrder = this.sortOrder;
    }
    if (this.multiSortMeta) {
      state.multiSortMeta = this.multiSortMeta;
    }
    if (this.hasFilter()) {
      state.filters = this.filters;
    }
    if (this.resizableColumns) {
      this.saveColumnWidths(state);
    }
    if (this.reorderableColumns) {
      this.saveColumnOrder(state);
    }
    if (this.selection) {
      state.selection = this.selection;
    }
    if (Object.keys(this.expandedRowKeys).length) {
      state.expandedRowKeys = this.expandedRowKeys;
    }
    if (Object.keys(state).length) {
      storage.setItem(this.stateKey, JSON.stringify(state));
    }
    this.onStateSave.emit(state);
  }
  clearState() {
    const storage = this.getStorage();
    if (this.stateKey) {
      storage.removeItem(this.stateKey);
    }
  }
  restoreState() {
    const storage = this.getStorage();
    const stateString = storage.getItem(this.stateKey);
    if (stateString) {
      let state = JSON.parse(stateString);
      if (this.paginator) {
        this.first = state.first;
        this.rows = state.rows;
        this.firstChange.emit(this.first);
        this.rowsChange.emit(this.rows);
      }
      if (state.sortField) {
        this.restoringSort = true;
        this._sortField = state.sortField;
        this._sortOrder = state.sortOrder;
      }
      if (state.multiSortMeta) {
        this.restoringSort = true;
        this._multiSortMeta = state.multiSortMeta;
      }
      if (state.filters) {
        this.restoringFilter = true;
        this.filters = state.filters;
      }
      if (this.resizableColumns) {
        this.columnWidthsState = state.columnWidths;
        this.tableWidthState = state.tableWidth;
      }
      if (state.expandedRowKeys) {
        this.expandedRowKeys = state.expandedRowKeys;
      }
      if (state.selection) {
        Promise.resolve(null).then(() => this.selectionChange.emit(state.selection));
      }
      this.stateRestored = true;
      this.onStateRestore.emit(state);
    }
  }
  saveColumnWidths(state) {
    let widths = [];
    let headers = DomHandler.find(this.containerViewChild.nativeElement, '.ui-table-thead > tr:first-child > th');
    headers.map(header => widths.push(DomHandler.getOuterWidth(header)));
    state.columnWidths = widths.join(',');
    if (this.columnResizeMode === 'expand') {
      state.tableWidth = this.scrollable ? DomHandler.findSingle(this.containerViewChild.nativeElement, '.ui-table-scrollable-header-table').style.width : DomHandler.getOuterWidth(this.tableViewChild.nativeElement) + 'px';
    }
  }
  restoreColumnWidths() {
    if (this.columnWidthsState) {
      let widths = this.columnWidthsState.split(',');
      if (this.columnResizeMode === 'expand' && this.tableWidthState) {
        if (this.scrollable) {
          this.setScrollableItemsWidthOnExpandResize(null, this.tableWidthState, 0);
        } else {
          this.tableViewChild.nativeElement.style.width = this.tableWidthState;
          this.containerViewChild.nativeElement.style.width = this.tableWidthState;
        }
      }
      if (this.scrollable) {
        let headerCols = DomHandler.find(this.containerViewChild.nativeElement, '.ui-table-scrollable-header-table > colgroup > col');
        let bodyCols = DomHandler.find(this.containerViewChild.nativeElement, '.ui-table-scrollable-body table > colgroup > col');
        headerCols.map((col, index) => col.style.width = widths[index] + 'px');
        bodyCols.map((col, index) => col.style.width = widths[index] + 'px');
      } else {
        let headers = DomHandler.find(this.tableViewChild.nativeElement, '.ui-table-thead > tr:first-child > th');
        headers.map((header, index) => header.style.width = widths[index] + 'px');
      }
    }
  }
  saveColumnOrder(state) {
    if (this.columns) {
      let columnOrder = [];
      this.columns.map(column => {
        columnOrder.push(column.field || column.key);
      });
      state.columnOrder = columnOrder;
    }
  }
  restoreColumnOrder() {
    const storage = this.getStorage();
    const stateString = storage.getItem(this.stateKey);
    if (stateString) {
      let state = JSON.parse(stateString);
      let columnOrder = state.columnOrder;
      if (columnOrder) {
        let reorderedColumns = [];
        columnOrder.map(key => reorderedColumns.push(this.findColumnByKey(key)));
        this.columnOrderStateRestored = true;
        this.columns = reorderedColumns;
      }
    }
  }
  findColumnByKey(key) {
    if (this.columns) {
      for (let col of this.columns) {
        if (col.key === key || col.field === key) return col;else continue;
      }
    } else {
      return null;
    }
  }
  ngOnDestroy() {
    this.unbindDocumentEditListener();
    this.editingCell = null;
    this.initialized = null;
  }
};
Table.ctorParameters = () => [{
  type: ElementRef
}, {
  type: NgZone
}, {
  type: TableService
}, {
  type: ChangeDetectorRef
}];
__decorate([Input()], Table.prototype, "frozenColumns", void 0);
__decorate([Input()], Table.prototype, "frozenValue", void 0);
__decorate([Input()], Table.prototype, "style", void 0);
__decorate([Input()], Table.prototype, "styleClass", void 0);
__decorate([Input()], Table.prototype, "tableStyle", void 0);
__decorate([Input()], Table.prototype, "tableStyleClass", void 0);
__decorate([Input()], Table.prototype, "paginator", void 0);
__decorate([Input()], Table.prototype, "pageLinks", void 0);
__decorate([Input()], Table.prototype, "rowsPerPageOptions", void 0);
__decorate([Input()], Table.prototype, "alwaysShowPaginator", void 0);
__decorate([Input()], Table.prototype, "paginatorPosition", void 0);
__decorate([Input()], Table.prototype, "paginatorDropdownAppendTo", void 0);
__decorate([Input()], Table.prototype, "paginatorDropdownScrollHeight", void 0);
__decorate([Input()], Table.prototype, "currentPageReportTemplate", void 0);
__decorate([Input()], Table.prototype, "showCurrentPageReport", void 0);
__decorate([Input()], Table.prototype, "defaultSortOrder", void 0);
__decorate([Input()], Table.prototype, "sortMode", void 0);
__decorate([Input()], Table.prototype, "resetPageOnSort", void 0);
__decorate([Input()], Table.prototype, "selectionMode", void 0);
__decorate([Output()], Table.prototype, "selectionChange", void 0);
__decorate([Input()], Table.prototype, "contextMenuSelection", void 0);
__decorate([Output()], Table.prototype, "contextMenuSelectionChange", void 0);
__decorate([Input()], Table.prototype, "contextMenuSelectionMode", void 0);
__decorate([Input()], Table.prototype, "dataKey", void 0);
__decorate([Input()], Table.prototype, "metaKeySelection", void 0);
__decorate([Input()], Table.prototype, "rowTrackBy", void 0);
__decorate([Input()], Table.prototype, "lazy", void 0);
__decorate([Input()], Table.prototype, "lazyLoadOnInit", void 0);
__decorate([Input()], Table.prototype, "compareSelectionBy", void 0);
__decorate([Input()], Table.prototype, "csvSeparator", void 0);
__decorate([Input()], Table.prototype, "exportFilename", void 0);
__decorate([Input()], Table.prototype, "filters", void 0);
__decorate([Input()], Table.prototype, "globalFilterFields", void 0);
__decorate([Input()], Table.prototype, "filterDelay", void 0);
__decorate([Input()], Table.prototype, "filterLocale", void 0);
__decorate([Input()], Table.prototype, "expandedRowKeys", void 0);
__decorate([Input()], Table.prototype, "editingRowKeys", void 0);
__decorate([Input()], Table.prototype, "rowExpandMode", void 0);
__decorate([Input()], Table.prototype, "scrollable", void 0);
__decorate([Input()], Table.prototype, "scrollHeight", void 0);
__decorate([Input()], Table.prototype, "virtualScroll", void 0);
__decorate([Input()], Table.prototype, "virtualScrollDelay", void 0);
__decorate([Input()], Table.prototype, "virtualRowHeight", void 0);
__decorate([Input()], Table.prototype, "frozenWidth", void 0);
__decorate([Input()], Table.prototype, "responsive", void 0);
__decorate([Input()], Table.prototype, "contextMenu", void 0);
__decorate([Input()], Table.prototype, "resizableColumns", void 0);
__decorate([Input()], Table.prototype, "columnResizeMode", void 0);
__decorate([Input()], Table.prototype, "reorderableColumns", void 0);
__decorate([Input()], Table.prototype, "loading", void 0);
__decorate([Input()], Table.prototype, "loadingIcon", void 0);
__decorate([Input()], Table.prototype, "showLoader", void 0);
__decorate([Input()], Table.prototype, "rowHover", void 0);
__decorate([Input()], Table.prototype, "customSort", void 0);
__decorate([Input()], Table.prototype, "autoLayout", void 0);
__decorate([Input()], Table.prototype, "exportFunction", void 0);
__decorate([Input()], Table.prototype, "stateKey", void 0);
__decorate([Input()], Table.prototype, "stateStorage", void 0);
__decorate([Input()], Table.prototype, "editMode", void 0);
__decorate([Input()], Table.prototype, "minBufferPx", void 0);
__decorate([Input()], Table.prototype, "maxBufferPx", void 0);
__decorate([Output()], Table.prototype, "onRowSelect", void 0);
__decorate([Output()], Table.prototype, "onRowUnselect", void 0);
__decorate([Output()], Table.prototype, "onPage", void 0);
__decorate([Output()], Table.prototype, "onSort", void 0);
__decorate([Output()], Table.prototype, "onFilter", void 0);
__decorate([Output()], Table.prototype, "onLazyLoad", void 0);
__decorate([Output()], Table.prototype, "onRowExpand", void 0);
__decorate([Output()], Table.prototype, "onRowCollapse", void 0);
__decorate([Output()], Table.prototype, "onContextMenuSelect", void 0);
__decorate([Output()], Table.prototype, "onColResize", void 0);
__decorate([Output()], Table.prototype, "onColReorder", void 0);
__decorate([Output()], Table.prototype, "onRowReorder", void 0);
__decorate([Output()], Table.prototype, "onEditInit", void 0);
__decorate([Output()], Table.prototype, "onEditComplete", void 0);
__decorate([Output()], Table.prototype, "onEditCancel", void 0);
__decorate([Output()], Table.prototype, "onHeaderCheckboxToggle", void 0);
__decorate([Output()], Table.prototype, "sortFunction", void 0);
__decorate([Output()], Table.prototype, "firstChange", void 0);
__decorate([Output()], Table.prototype, "rowsChange", void 0);
__decorate([Output()], Table.prototype, "onStateSave", void 0);
__decorate([Output()], Table.prototype, "onStateRestore", void 0);
__decorate([ViewChild('container')], Table.prototype, "containerViewChild", void 0);
__decorate([ViewChild('resizeHelper')], Table.prototype, "resizeHelperViewChild", void 0);
__decorate([ViewChild('reorderIndicatorUp')], Table.prototype, "reorderIndicatorUpViewChild", void 0);
__decorate([ViewChild('reorderIndicatorDown')], Table.prototype, "reorderIndicatorDownViewChild", void 0);
__decorate([ViewChild('table')], Table.prototype, "tableViewChild", void 0);
__decorate([ViewChild('scrollableView')], Table.prototype, "scrollableViewChild", void 0);
__decorate([ViewChild('scrollableFrozenView')], Table.prototype, "scrollableFrozenViewChild", void 0);
__decorate([ContentChildren(PrimeTemplate)], Table.prototype, "templates", void 0);
__decorate([Input()], Table.prototype, "value", null);
__decorate([Input()], Table.prototype, "columns", null);
__decorate([Input()], Table.prototype, "first", null);
__decorate([Input()], Table.prototype, "rows", null);
__decorate([Input()], Table.prototype, "totalRecords", null);
__decorate([Input()], Table.prototype, "sortField", null);
__decorate([Input()], Table.prototype, "sortOrder", null);
__decorate([Input()], Table.prototype, "multiSortMeta", null);
__decorate([Input()], Table.prototype, "selection", null);
Table = __decorate([Component({
  selector: 'p-table',
  template: `
        <div #container [ngStyle]="style" [class]="styleClass"
            [ngClass]="{'ui-table ui-widget': true, 'ui-table-responsive': responsive, 'ui-table-resizable': resizableColumns,
                'ui-table-resizable-fit': (resizableColumns && columnResizeMode === 'fit'),
                'ui-table-hoverable-rows': (rowHover||selectionMode), 'ui-table-auto-layout': autoLayout,
                'ui-table-flex-scrollable': (scrollable && scrollHeight === 'flex')}">
            <div class="ui-table-loading ui-widget-overlay" *ngIf="loading && showLoader"></div>
            <div class="ui-table-loading-content" *ngIf="loading && showLoader">
                <i [class]="'ui-table-loading-icon pi-spin ' + loadingIcon"></i>
            </div>
            <div *ngIf="captionTemplate" class="ui-table-caption ui-widget-header">
                <ng-container *ngTemplateOutlet="captionTemplate"></ng-container>
            </div>
            <p-paginator [rows]="rows" [first]="first" [totalRecords]="totalRecords" [pageLinkSize]="pageLinks" styleClass="ui-paginator-top" [alwaysShow]="alwaysShowPaginator"
                (onPageChange)="onPageChange($event)" [rowsPerPageOptions]="rowsPerPageOptions" *ngIf="paginator && (paginatorPosition === 'top' || paginatorPosition =='both')"
                [templateLeft]="paginatorLeftTemplate" [templateRight]="paginatorRightTemplate" [dropdownAppendTo]="paginatorDropdownAppendTo" [dropdownScrollHeight]="paginatorDropdownScrollHeight"
                [currentPageReportTemplate]="currentPageReportTemplate" [showCurrentPageReport]="showCurrentPageReport"></p-paginator>

            <div class="ui-table-wrapper" *ngIf="!scrollable">
                <table role="grid" #table [ngClass]="tableStyleClass" [ngStyle]="tableStyle">
                    <ng-container *ngTemplateOutlet="colGroupTemplate; context {$implicit: columns}"></ng-container>
                    <thead class="ui-table-thead">
                        <ng-container *ngTemplateOutlet="headerTemplate; context: {$implicit: columns}"></ng-container>
                    </thead>
                    <tbody class="ui-table-tbody" [pTableBody]="columns" [pTableBodyTemplate]="bodyTemplate"></tbody>
                    <tfoot *ngIf="footerTemplate" class="ui-table-tfoot">
                        <ng-container *ngTemplateOutlet="footerTemplate; context {$implicit: columns}"></ng-container>
                    </tfoot>
                </table>
            </div>

            <div class="ui-table-scrollable-wrapper" *ngIf="scrollable">
               <div class="ui-table-scrollable-view ui-table-frozen-view" *ngIf="frozenColumns||frozenBodyTemplate" #scrollableFrozenView [pScrollableView]="frozenColumns" [frozen]="true" [ngStyle]="{width: frozenWidth}" [scrollHeight]="scrollHeight"></div>
               <div class="ui-table-scrollable-view" #scrollableView [pScrollableView]="columns" [frozen]="false" [scrollHeight]="scrollHeight" [ngStyle]="{left: frozenWidth, width: 'calc(100% - '+frozenWidth+')'}"></div>
            </div>

            <p-paginator [rows]="rows" [first]="first" [totalRecords]="totalRecords" [pageLinkSize]="pageLinks" styleClass="ui-paginator-bottom" [alwaysShow]="alwaysShowPaginator"
                (onPageChange)="onPageChange($event)" [rowsPerPageOptions]="rowsPerPageOptions" *ngIf="paginator && (paginatorPosition === 'bottom' || paginatorPosition =='both')"
                [templateLeft]="paginatorLeftTemplate" [templateRight]="paginatorRightTemplate" [dropdownAppendTo]="paginatorDropdownAppendTo" [dropdownScrollHeight]="paginatorDropdownScrollHeight"
                [currentPageReportTemplate]="currentPageReportTemplate" [showCurrentPageReport]="showCurrentPageReport"></p-paginator>

                <div *ngIf="summaryTemplate" class="ui-table-summary ui-widget-header">
                <ng-container *ngTemplateOutlet="summaryTemplate"></ng-container>
            </div>

            <div #resizeHelper class="ui-column-resizer-helper ui-state-highlight" style="display:none" *ngIf="resizableColumns"></div>

            <span #reorderIndicatorUp class="pi pi-arrow-down ui-table-reorder-indicator-up" style="display:none" *ngIf="reorderableColumns"></span>
            <span #reorderIndicatorDown class="pi pi-arrow-up ui-table-reorder-indicator-down" style="display:none" *ngIf="reorderableColumns"></span>
        </div>
    `,
  providers: [TableService],
  changeDetection: ChangeDetectionStrategy.Default
})], Table);
let TableBody = class TableBody {
  constructor(dt) {
    this.dt = dt;
  }
};
TableBody.ctorParameters = () => [{
  type: Table
}];
__decorate([Input("pTableBody")], TableBody.prototype, "columns", void 0);
__decorate([Input("pTableBodyTemplate")], TableBody.prototype, "template", void 0);
__decorate([Input()], TableBody.prototype, "frozen", void 0);
TableBody = __decorate([Component({
  selector: '[pTableBody]',
  template: `
        <ng-container *ngIf="!dt.expandedRowTemplate && !dt.virtualScroll">
            <ng-template ngFor let-rowData let-rowIndex="index" [ngForOf]="(dt.paginator && !dt.lazy) ? ((dt.filteredValue||dt.value) | slice:dt.first:(dt.first + dt.rows)) : (dt.filteredValue||dt.value)" [ngForTrackBy]="dt.rowTrackBy">
                <ng-container *ngTemplateOutlet="template; context: {$implicit: rowData, rowIndex: dt.paginator ? (dt.first + rowIndex) : rowIndex, columns: columns, editing: (dt.editMode === 'row' && dt.isRowEditing(rowData))}"></ng-container>
            </ng-template>
        </ng-container>
        <ng-container *ngIf="!dt.expandedRowTemplate && dt.virtualScroll">
            <ng-template cdkVirtualFor let-rowData let-rowIndex="index" [cdkVirtualForOf]="dt.value" [cdkVirtualForTrackBy]="dt.rowTrackBy">
                <ng-container *ngTemplateOutlet="rowData ? template: dt.loadingBodyTemplate; context: {$implicit: rowData, rowIndex: dt.paginator ? (dt.first + rowIndex) : rowIndex, columns: columns, editing: (dt.editMode === 'row' && dt.isRowEditing(rowData))}"></ng-container>
            </ng-template>
        </ng-container>
        <ng-container *ngIf="dt.expandedRowTemplate">
            <ng-template ngFor let-rowData let-rowIndex="index" [ngForOf]="(dt.paginator && !dt.lazy) ? ((dt.filteredValue||dt.value) | slice:dt.first:(dt.first + dt.rows)) : (dt.filteredValue||dt.value)" [ngForTrackBy]="dt.rowTrackBy">
                <ng-container *ngTemplateOutlet="template; context: {$implicit: rowData, rowIndex: dt.paginator ? (dt.first + rowIndex) : rowIndex, columns: columns, expanded: dt.isRowExpanded(rowData), editing: (dt.editMode === 'row' && dt.isRowEditing(rowData))}"></ng-container>
                <ng-container *ngIf="dt.isRowExpanded(rowData)">
                    <ng-container *ngTemplateOutlet="dt.expandedRowTemplate; context: {$implicit: rowData, rowIndex: dt.paginator ? (dt.first + rowIndex) : rowIndex, columns: columns}"></ng-container>
                </ng-container>
            </ng-template>
        </ng-container>
        <ng-container *ngIf="dt.loading">
            <ng-container *ngTemplateOutlet="dt.loadingBodyTemplate; context: {$implicit: columns, frozen: frozen}"></ng-container>
        </ng-container>
        <ng-container *ngIf="dt.isEmpty() && !dt.loading">
            <ng-container *ngTemplateOutlet="dt.emptyMessageTemplate; context: {$implicit: columns, frozen: frozen}"></ng-container>
        </ng-container>
    `
})], TableBody);
let ScrollableView = class ScrollableView {
  constructor(dt, el, zone) {
    this.dt = dt;
    this.el = el;
    this.zone = zone;
    this.loadedPages = [];
    this.subscription = this.dt.tableService.valueSource$.subscribe(() => {
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          this.alignScrollBar();
        }, 50);
      });
    });
    this.initialized = false;
  }
  get scrollHeight() {
    return this._scrollHeight;
  }
  set scrollHeight(val) {
    this._scrollHeight = val;
    if (val != null && (val.includes('%') || val.includes('calc'))) {
      console.log('Percentage scroll height calculation is removed in favor of the more performant CSS based flex mode, use scrollHeight="flex" instead.');
    }
  }
  ngAfterViewChecked() {
    if (!this.initialized && this.el.nativeElement.offsetParent) {
      this.alignScrollBar();
      this.initialized = true;
    }
  }
  ngAfterViewInit() {
    if (!this.frozen) {
      if (this.dt.frozenColumns || this.dt.frozenBodyTemplate) {
        DomHandler.addClass(this.el.nativeElement, 'ui-table-unfrozen-view');
      }
      let frozenView = this.el.nativeElement.previousElementSibling;
      if (frozenView) {
        if (this.dt.virtualScroll) this.frozenSiblingBody = DomHandler.findSingle(frozenView, '.ui-table-virtual-scrollable-body');else this.frozenSiblingBody = DomHandler.findSingle(frozenView, '.ui-table-scrollable-body');
      }
    } else {
      if (this.scrollableAlignerViewChild && this.scrollableAlignerViewChild.nativeElement) {
        this.scrollableAlignerViewChild.nativeElement.style.height = DomHandler.calculateScrollbarHeight() + 'px';
      }
    }
    this.bindEvents();
    this.alignScrollBar();
  }
  bindEvents() {
    this.zone.runOutsideAngular(() => {
      if (this.scrollHeaderViewChild && this.scrollHeaderViewChild.nativeElement) {
        this.headerScrollListener = this.onHeaderScroll.bind(this);
        this.scrollHeaderViewChild.nativeElement.addEventListener('scroll', this.headerScrollListener);
      }
      if (this.scrollFooterViewChild && this.scrollFooterViewChild.nativeElement) {
        this.footerScrollListener = this.onFooterScroll.bind(this);
        this.scrollFooterViewChild.nativeElement.addEventListener('scroll', this.footerScrollListener);
      }
      if (!this.frozen) {
        this.bodyScrollListener = this.onBodyScroll.bind(this);
        if (this.dt.virtualScroll) this.virtualScrollBody.getElementRef().nativeElement.addEventListener('scroll', this.bodyScrollListener);else this.scrollBodyViewChild.nativeElement.addEventListener('scroll', this.bodyScrollListener);
      }
    });
  }
  unbindEvents() {
    if (this.scrollHeaderViewChild && this.scrollHeaderViewChild.nativeElement) {
      this.scrollHeaderViewChild.nativeElement.removeEventListener('scroll', this.headerScrollListener);
    }
    if (this.scrollFooterViewChild && this.scrollFooterViewChild.nativeElement) {
      this.scrollFooterViewChild.nativeElement.removeEventListener('scroll', this.footerScrollListener);
    }
    if (this.scrollBodyViewChild && this.scrollBodyViewChild.nativeElement) {
      this.scrollBodyViewChild.nativeElement.removeEventListener('scroll', this.bodyScrollListener);
    }
    if (this.virtualScrollBody && this.virtualScrollBody.getElementRef()) {
      this.virtualScrollBody.getElementRef().nativeElement.removeEventListener('scroll', this.bodyScrollListener);
    }
  }
  onHeaderScroll() {
    const scrollLeft = this.scrollHeaderViewChild.nativeElement.scrollLeft;
    this.scrollBodyViewChild.nativeElement.scrollLeft = scrollLeft;
    if (this.scrollFooterViewChild && this.scrollFooterViewChild.nativeElement) {
      this.scrollFooterViewChild.nativeElement.scrollLeft = scrollLeft;
    }
    this.preventBodyScrollPropagation = true;
  }
  onFooterScroll() {
    const scrollLeft = this.scrollFooterViewChild.nativeElement.scrollLeft;
    this.scrollBodyViewChild.nativeElement.scrollLeft = scrollLeft;
    if (this.scrollHeaderViewChild && this.scrollHeaderViewChild.nativeElement) {
      this.scrollHeaderViewChild.nativeElement.scrollLeft = scrollLeft;
    }
    this.preventBodyScrollPropagation = true;
  }
  onBodyScroll(event) {
    if (this.preventBodyScrollPropagation) {
      this.preventBodyScrollPropagation = false;
      return;
    }
    if (this.scrollHeaderViewChild && this.scrollHeaderViewChild.nativeElement) {
      this.scrollHeaderBoxViewChild.nativeElement.style.marginLeft = -1 * event.target.scrollLeft + 'px';
    }
    if (this.scrollFooterViewChild && this.scrollFooterViewChild.nativeElement) {
      this.scrollFooterBoxViewChild.nativeElement.style.marginLeft = -1 * event.target.scrollLeft + 'px';
    }
    if (this.frozenSiblingBody) {
      this.frozenSiblingBody.scrollTop = event.target.scrollTop;
    }
  }
  onScrollIndexChange(index) {
    if (this.dt.lazy) {
      let pageRange = this.createPageRange(Math.floor(index / this.dt.rows));
      pageRange.forEach(page => this.loadPage(page));
    }
  }
  createPageRange(page) {
    let range = [];
    if (page !== 0) {
      range.push(page - 1);
    }
    range.push(page);
    if (page !== this.getPageCount() - 1) {
      range.push(page + 1);
    }
    return range;
  }
  loadPage(page) {
    if (!this.loadedPages.includes(page)) {
      this.dt.onLazyLoad.emit({
        first: this.dt.rows * page,
        rows: this.dt.rows,
        sortField: this.dt.sortField,
        sortOrder: this.dt.sortOrder,
        filters: this.dt.filters,
        globalFilter: this.dt.filters && this.dt.filters['global'] ? this.dt.filters['global'].value : null,
        multiSortMeta: this.dt.multiSortMeta
      });
      this.loadedPages.push(page);
    }
  }
  clearCache() {
    this.loadedPages = [];
  }
  getPageCount() {
    let dataToRender = this.dt.filteredValue || this.dt.value;
    let dataLength = dataToRender ? dataToRender.length : 0;
    return Math.ceil(dataLength / this.dt.rows);
  }
  scrollToVirtualIndex(index) {
    if (this.virtualScrollBody) {
      this.virtualScrollBody.scrollToIndex(index);
    }
  }
  scrollTo(options) {
    if (this.virtualScrollBody) {
      this.virtualScrollBody.scrollTo(options);
    } else {
      if (this.scrollBodyViewChild.nativeElement.scrollTo) {
        this.scrollBodyViewChild.nativeElement.scrollTo(options);
      } else {
        this.scrollBodyViewChild.nativeElement.scrollLeft = options.left;
        this.scrollBodyViewChild.nativeElement.scrollTop = options.top;
      }
    }
  }
  hasVerticalOverflow() {
    if (this.dt.virtualScroll) return this.virtualScrollBody.getDataLength() * this.dt.virtualRowHeight > this.virtualScrollBody.getViewportSize();else return DomHandler.getOuterHeight(this.scrollTableViewChild.nativeElement) > DomHandler.getOuterHeight(this.scrollBodyViewChild.nativeElement);
  }
  alignScrollBar() {
    if (!this.frozen) {
      let scrollBarWidth = this.hasVerticalOverflow() ? DomHandler.calculateScrollbarWidth() : 0;
      this.scrollHeaderBoxViewChild.nativeElement.style.paddingRight = scrollBarWidth + 'px';
      if (this.scrollFooterBoxViewChild && this.scrollFooterBoxViewChild.nativeElement) {
        this.scrollFooterBoxViewChild.nativeElement.style.paddingRight = scrollBarWidth + 'px';
      }
    }
    this.initialized = false;
  }
  ngOnDestroy() {
    this.unbindEvents();
    this.frozenSiblingBody = null;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.initialized = false;
  }
};
ScrollableView.ctorParameters = () => [{
  type: Table
}, {
  type: ElementRef
}, {
  type: NgZone
}];
__decorate([Input("pScrollableView")], ScrollableView.prototype, "columns", void 0);
__decorate([Input()], ScrollableView.prototype, "frozen", void 0);
__decorate([ViewChild('scrollHeader')], ScrollableView.prototype, "scrollHeaderViewChild", void 0);
__decorate([ViewChild('scrollHeaderBox')], ScrollableView.prototype, "scrollHeaderBoxViewChild", void 0);
__decorate([ViewChild('scrollBody')], ScrollableView.prototype, "scrollBodyViewChild", void 0);
__decorate([ViewChild('scrollTable')], ScrollableView.prototype, "scrollTableViewChild", void 0);
__decorate([ViewChild('scrollFooter')], ScrollableView.prototype, "scrollFooterViewChild", void 0);
__decorate([ViewChild('scrollFooterBox')], ScrollableView.prototype, "scrollFooterBoxViewChild", void 0);
__decorate([ViewChild('scrollableAligner')], ScrollableView.prototype, "scrollableAlignerViewChild", void 0);
__decorate([ViewChild(CdkVirtualScrollViewport)], ScrollableView.prototype, "virtualScrollBody", void 0);
__decorate([Input()], ScrollableView.prototype, "scrollHeight", null);
ScrollableView = __decorate([Component({
  selector: '[pScrollableView]',
  template: `
        <div #scrollHeader class="ui-table-scrollable-header ui-widget-header">
            <div #scrollHeaderBox class="ui-table-scrollable-header-box">
                <table class="ui-table-scrollable-header-table" [ngClass]="dt.tableStyleClass" [ngStyle]="dt.tableStyle">
                    <ng-container *ngTemplateOutlet="frozen ? dt.frozenColGroupTemplate||dt.colGroupTemplate : dt.colGroupTemplate; context {$implicit: columns}"></ng-container>
                    <thead class="ui-table-thead">
                        <ng-container *ngTemplateOutlet="frozen ? dt.frozenHeaderTemplate||dt.headerTemplate : dt.headerTemplate; context {$implicit: columns}"></ng-container>
                    </thead>
                    <tbody class="ui-table-tbody">
                        <ng-template ngFor let-rowData let-rowIndex="index" [ngForOf]="dt.frozenValue" [ngForTrackBy]="dt.rowTrackBy">
                            <ng-container *ngTemplateOutlet="dt.frozenRowsTemplate; context: {$implicit: rowData, rowIndex: rowIndex, columns: columns}"></ng-container>
                        </ng-template>
                    </tbody>
                </table>
            </div>
        </div>
        <ng-container *ngIf="!dt.virtualScroll; else virtualScrollTemplate">
            <div #scrollBody class="ui-table-scrollable-body" [ngStyle]="{'max-height': dt.scrollHeight !== 'flex' ? scrollHeight : undefined}">
                <table #scrollTable [class]="dt.tableStyleClass" [ngStyle]="dt.tableStyle">
                    <ng-container *ngTemplateOutlet="frozen ? dt.frozenColGroupTemplate||dt.colGroupTemplate : dt.colGroupTemplate; context {$implicit: columns}"></ng-container>
                    <tbody class="ui-table-tbody" [pTableBody]="columns" [pTableBodyTemplate]="frozen ? dt.frozenBodyTemplate||dt.bodyTemplate : dt.bodyTemplate" [frozen]="frozen"></tbody>
                </table>
                <div #scrollableAligner style="background-color:transparent" *ngIf="frozen"></div>
            </div>
        </ng-container>
        <ng-template #virtualScrollTemplate>
            <cdk-virtual-scroll-viewport [itemSize]="dt.virtualRowHeight" [style.height]="dt.scrollHeight !== 'flex' ? scrollHeight : undefined" 
                    [minBufferPx]="dt.minBufferPx" [maxBufferPx]="dt.maxBufferPx" (scrolledIndexChange)="onScrollIndexChange($event)" class="ui-table-virtual-scrollable-body">
                <table #scrollTable [class]="dt.tableStyleClass" [ngStyle]="dt.tableStyle">
                    <ng-container *ngTemplateOutlet="frozen ? dt.frozenColGroupTemplate||dt.colGroupTemplate : dt.colGroupTemplate; context {$implicit: columns}"></ng-container>
                    <tbody class="ui-table-tbody" [pTableBody]="columns" [pTableBodyTemplate]="frozen ? dt.frozenBodyTemplate||dt.bodyTemplate : dt.bodyTemplate" [frozen]="frozen"></tbody>
                </table>
                <div #scrollableAligner style="background-color:transparent" *ngIf="frozen"></div>
            </cdk-virtual-scroll-viewport>
        </ng-template>
        <div #scrollFooter class="ui-table-scrollable-footer ui-widget-header">
            <div #scrollFooterBox class="ui-table-scrollable-footer-box">
                <table class="ui-table-scrollable-footer-table" [ngClass]="dt.tableStyleClass" [ngStyle]="dt.tableStyle">
                    <ng-container *ngTemplateOutlet="frozen ? dt.frozenColGroupTemplate||dt.colGroupTemplate : dt.colGroupTemplate; context {$implicit: columns}"></ng-container>
                    <tfoot class="ui-table-tfoot">
                        <ng-container *ngTemplateOutlet="frozen ? dt.frozenFooterTemplate||dt.footerTemplate : dt.footerTemplate; context {$implicit: columns}"></ng-container>
                    </tfoot>
                </table>
            </div>
        </div>
    `
})], ScrollableView);
let SortableColumn = class SortableColumn {
  constructor(dt) {
    this.dt = dt;
    if (this.isEnabled()) {
      this.subscription = this.dt.tableService.sortSource$.subscribe(sortMeta => {
        this.updateSortState();
      });
    }
  }
  ngOnInit() {
    if (this.isEnabled()) {
      this.updateSortState();
    }
  }
  updateSortState() {
    this.sorted = this.dt.isSorted(this.field);
    this.sortOrder = this.sorted ? this.dt.sortOrder === 1 ? 'ascending' : 'descending' : 'none';
  }
  onClick(event) {
    if (this.isEnabled()) {
      this.updateSortState();
      this.dt.sort({
        originalEvent: event,
        field: this.field
      });
      DomHandler.clearSelection();
    }
  }
  onEnterKey(event) {
    this.onClick(event);
  }
  isEnabled() {
    return this.pSortableColumnDisabled !== true;
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
};
SortableColumn.ctorParameters = () => [{
  type: Table
}];
__decorate([Input("pSortableColumn")], SortableColumn.prototype, "field", void 0);
__decorate([Input()], SortableColumn.prototype, "pSortableColumnDisabled", void 0);
__decorate([HostListener('click', ['$event'])], SortableColumn.prototype, "onClick", null);
__decorate([HostListener('keydown.enter', ['$event'])], SortableColumn.prototype, "onEnterKey", null);
SortableColumn = __decorate([Directive({
  selector: '[pSortableColumn]',
  host: {
    '[class.ui-sortable-column]': 'isEnabled()',
    '[class.ui-state-highlight]': 'sorted',
    '[attr.tabindex]': 'isEnabled() ? "0" : null',
    '[attr.role]': '"columnheader"',
    '[attr.aria-sort]': 'sortOrder'
  }
})], SortableColumn);
let SortIcon = class SortIcon {
  constructor(dt) {
    this.dt = dt;
    this.subscription = this.dt.tableService.sortSource$.subscribe(sortMeta => {
      this.updateSortState();
    });
  }
  ngOnInit() {
    this.updateSortState();
  }
  onClick(event) {
    event.preventDefault();
  }
  updateSortState() {
    if (this.dt.sortMode === 'single') {
      this.sortOrder = this.dt.isSorted(this.field) ? this.dt.sortOrder : 0;
    } else if (this.dt.sortMode === 'multiple') {
      let sortMeta = this.dt.getSortMeta(this.field);
      this.sortOrder = sortMeta ? sortMeta.order : 0;
    }
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
};
SortIcon.ctorParameters = () => [{
  type: Table
}];
__decorate([Input()], SortIcon.prototype, "field", void 0);
SortIcon = __decorate([Component({
  selector: 'p-sortIcon',
  template: `
        <i class="ui-sortable-column-icon pi pi-fw" [ngClass]="{'pi-sort-amount-up-alt': sortOrder === 1, 'pi-sort-amount-down': sortOrder === -1, 'pi-sort-alt': sortOrder === 0}"></i>
    `
})], SortIcon);
let SelectableRow = class SelectableRow {
  constructor(dt, tableService) {
    this.dt = dt;
    this.tableService = tableService;
    if (this.isEnabled()) {
      this.subscription = this.dt.tableService.selectionSource$.subscribe(() => {
        this.selected = this.dt.isSelected(this.data);
      });
    }
  }
  ngOnInit() {
    if (this.isEnabled()) {
      this.selected = this.dt.isSelected(this.data);
    }
  }
  onClick(event) {
    if (this.isEnabled()) {
      this.dt.handleRowClick({
        originalEvent: event,
        rowData: this.data,
        rowIndex: this.index
      });
    }
  }
  onTouchEnd(event) {
    if (this.isEnabled()) {
      this.dt.handleRowTouchEnd(event);
    }
  }
  onArrowDownKeyDown(event) {
    if (!this.isEnabled()) {
      return;
    }
    const row = event.currentTarget;
    const nextRow = this.findNextSelectableRow(row);
    if (nextRow) {
      nextRow.focus();
    }
    event.preventDefault();
  }
  onArrowUpKeyDown(event) {
    if (!this.isEnabled()) {
      return;
    }
    const row = event.currentTarget;
    const prevRow = this.findPrevSelectableRow(row);
    if (prevRow) {
      prevRow.focus();
    }
    event.preventDefault();
  }
  onEnterKeyDown(event) {
    if (!this.isEnabled()) {
      return;
    }
    this.dt.handleRowClick({
      originalEvent: event,
      rowData: this.data,
      rowIndex: this.index
    });
  }
  findNextSelectableRow(row) {
    let nextRow = row.nextElementSibling;
    if (nextRow) {
      if (DomHandler.hasClass(nextRow, 'ui-selectable-row')) return nextRow;else return this.findNextSelectableRow(nextRow);
    } else {
      return null;
    }
  }
  findPrevSelectableRow(row) {
    let prevRow = row.previousElementSibling;
    if (prevRow) {
      if (DomHandler.hasClass(prevRow, 'ui-selectable-row')) return prevRow;else return this.findPrevSelectableRow(prevRow);
    } else {
      return null;
    }
  }
  isEnabled() {
    return this.pSelectableRowDisabled !== true;
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
};
SelectableRow.ctorParameters = () => [{
  type: Table
}, {
  type: TableService
}];
__decorate([Input("pSelectableRow")], SelectableRow.prototype, "data", void 0);
__decorate([Input("pSelectableRowIndex")], SelectableRow.prototype, "index", void 0);
__decorate([Input()], SelectableRow.prototype, "pSelectableRowDisabled", void 0);
__decorate([HostListener('click', ['$event'])], SelectableRow.prototype, "onClick", null);
__decorate([HostListener('touchend', ['$event'])], SelectableRow.prototype, "onTouchEnd", null);
__decorate([HostListener('keydown.arrowdown', ['$event'])], SelectableRow.prototype, "onArrowDownKeyDown", null);
__decorate([HostListener('keydown.arrowup', ['$event'])], SelectableRow.prototype, "onArrowUpKeyDown", null);
__decorate([HostListener('keydown.enter', ['$event'])], SelectableRow.prototype, "onEnterKeyDown", null);
SelectableRow = __decorate([Directive({
  selector: '[pSelectableRow]',
  host: {
    '[class.ui-selectable-row]': 'isEnabled()',
    '[class.ui-state-highlight]': 'selected',
    '[attr.tabindex]': 'isEnabled() ? 0 : undefined'
  }
})], SelectableRow);
let SelectableRowDblClick = class SelectableRowDblClick {
  constructor(dt, tableService) {
    this.dt = dt;
    this.tableService = tableService;
    if (this.isEnabled()) {
      this.subscription = this.dt.tableService.selectionSource$.subscribe(() => {
        this.selected = this.dt.isSelected(this.data);
      });
    }
  }
  ngOnInit() {
    if (this.isEnabled()) {
      this.selected = this.dt.isSelected(this.data);
    }
  }
  onClick(event) {
    if (this.isEnabled()) {
      this.dt.handleRowClick({
        originalEvent: event,
        rowData: this.data,
        rowIndex: this.index
      });
    }
  }
  isEnabled() {
    return this.pSelectableRowDisabled !== true;
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
};
SelectableRowDblClick.ctorParameters = () => [{
  type: Table
}, {
  type: TableService
}];
__decorate([Input("pSelectableRowDblClick")], SelectableRowDblClick.prototype, "data", void 0);
__decorate([Input("pSelectableRowIndex")], SelectableRowDblClick.prototype, "index", void 0);
__decorate([Input()], SelectableRowDblClick.prototype, "pSelectableRowDisabled", void 0);
__decorate([HostListener('dblclick', ['$event'])], SelectableRowDblClick.prototype, "onClick", null);
SelectableRowDblClick = __decorate([Directive({
  selector: '[pSelectableRowDblClick]',
  host: {
    '[class.ui-selectable-row]': 'isEnabled()',
    '[class.ui-state-highlight]': 'selected'
  }
})], SelectableRowDblClick);
let ContextMenuRow = class ContextMenuRow {
  constructor(dt, tableService, el) {
    this.dt = dt;
    this.tableService = tableService;
    this.el = el;
    if (this.isEnabled()) {
      this.subscription = this.dt.tableService.contextMenuSource$.subscribe(data => {
        this.selected = this.dt.equals(this.data, data);
      });
    }
  }
  onContextMenu(event) {
    if (this.isEnabled()) {
      this.dt.handleRowRightClick({
        originalEvent: event,
        rowData: this.data,
        rowIndex: this.index
      });
      this.el.nativeElement.focus();
      event.preventDefault();
    }
  }
  isEnabled() {
    return this.pContextMenuRowDisabled !== true;
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
};
ContextMenuRow.ctorParameters = () => [{
  type: Table
}, {
  type: TableService
}, {
  type: ElementRef
}];
__decorate([Input("pContextMenuRow")], ContextMenuRow.prototype, "data", void 0);
__decorate([Input("pContextMenuRowIndex")], ContextMenuRow.prototype, "index", void 0);
__decorate([Input()], ContextMenuRow.prototype, "pContextMenuRowDisabled", void 0);
__decorate([HostListener('contextmenu', ['$event'])], ContextMenuRow.prototype, "onContextMenu", null);
ContextMenuRow = __decorate([Directive({
  selector: '[pContextMenuRow]',
  host: {
    '[class.ui-contextmenu-selected]': 'selected',
    '[attr.tabindex]': 'isEnabled() ? 0 : undefined'
  }
})], ContextMenuRow);
let RowToggler = class RowToggler {
  constructor(dt) {
    this.dt = dt;
  }
  onClick(event) {
    if (this.isEnabled()) {
      this.dt.toggleRow(this.data, event);
      event.preventDefault();
    }
  }
  isEnabled() {
    return this.pRowTogglerDisabled !== true;
  }
};
RowToggler.ctorParameters = () => [{
  type: Table
}];
__decorate([Input('pRowToggler')], RowToggler.prototype, "data", void 0);
__decorate([Input()], RowToggler.prototype, "pRowTogglerDisabled", void 0);
__decorate([HostListener('click', ['$event'])], RowToggler.prototype, "onClick", null);
RowToggler = __decorate([Directive({
  selector: '[pRowToggler]'
})], RowToggler);
let ResizableColumn = class ResizableColumn {
  constructor(dt, el, zone) {
    this.dt = dt;
    this.el = el;
    this.zone = zone;
  }
  ngAfterViewInit() {
    if (this.isEnabled()) {
      DomHandler.addClass(this.el.nativeElement, 'ui-resizable-column');
      this.resizer = document.createElement('span');
      this.resizer.className = 'ui-column-resizer ui-clickable';
      this.el.nativeElement.appendChild(this.resizer);
      this.zone.runOutsideAngular(() => {
        this.resizerMouseDownListener = this.onMouseDown.bind(this);
        this.resizer.addEventListener('mousedown', this.resizerMouseDownListener);
      });
    }
  }
  bindDocumentEvents() {
    this.zone.runOutsideAngular(() => {
      this.documentMouseMoveListener = this.onDocumentMouseMove.bind(this);
      document.addEventListener('mousemove', this.documentMouseMoveListener);
      this.documentMouseUpListener = this.onDocumentMouseUp.bind(this);
      document.addEventListener('mouseup', this.documentMouseUpListener);
    });
  }
  unbindDocumentEvents() {
    if (this.documentMouseMoveListener) {
      document.removeEventListener('mousemove', this.documentMouseMoveListener);
      this.documentMouseMoveListener = null;
    }
    if (this.documentMouseUpListener) {
      document.removeEventListener('mouseup', this.documentMouseUpListener);
      this.documentMouseUpListener = null;
    }
  }
  onMouseDown(event) {
    if (event.which === 1) {
      this.dt.onColumnResizeBegin(event);
      this.bindDocumentEvents();
    }
  }
  onDocumentMouseMove(event) {
    this.dt.onColumnResize(event);
  }
  onDocumentMouseUp(event) {
    this.dt.onColumnResizeEnd(event, this.el.nativeElement);
    this.unbindDocumentEvents();
  }
  isEnabled() {
    return this.pResizableColumnDisabled !== true;
  }
  ngOnDestroy() {
    if (this.resizerMouseDownListener) {
      this.resizer.removeEventListener('mousedown', this.resizerMouseDownListener);
    }
    this.unbindDocumentEvents();
  }
};
ResizableColumn.ctorParameters = () => [{
  type: Table
}, {
  type: ElementRef
}, {
  type: NgZone
}];
__decorate([Input()], ResizableColumn.prototype, "pResizableColumnDisabled", void 0);
ResizableColumn = __decorate([Directive({
  selector: '[pResizableColumn]'
})], ResizableColumn);
let ReorderableColumn = class ReorderableColumn {
  constructor(dt, el, zone) {
    this.dt = dt;
    this.el = el;
    this.zone = zone;
  }
  ngAfterViewInit() {
    if (this.isEnabled()) {
      this.bindEvents();
    }
  }
  bindEvents() {
    this.zone.runOutsideAngular(() => {
      this.mouseDownListener = this.onMouseDown.bind(this);
      this.el.nativeElement.addEventListener('mousedown', this.mouseDownListener);
      this.dragStartListener = this.onDragStart.bind(this);
      this.el.nativeElement.addEventListener('dragstart', this.dragStartListener);
      this.dragOverListener = this.onDragEnter.bind(this);
      this.el.nativeElement.addEventListener('dragover', this.dragOverListener);
      this.dragEnterListener = this.onDragEnter.bind(this);
      this.el.nativeElement.addEventListener('dragenter', this.dragEnterListener);
      this.dragLeaveListener = this.onDragLeave.bind(this);
      this.el.nativeElement.addEventListener('dragleave', this.dragLeaveListener);
    });
  }
  unbindEvents() {
    if (this.mouseDownListener) {
      document.removeEventListener('mousedown', this.mouseDownListener);
      this.mouseDownListener = null;
    }
    if (this.dragOverListener) {
      document.removeEventListener('dragover', this.dragOverListener);
      this.dragOverListener = null;
    }
    if (this.dragEnterListener) {
      document.removeEventListener('dragenter', this.dragEnterListener);
      this.dragEnterListener = null;
    }
    if (this.dragEnterListener) {
      document.removeEventListener('dragenter', this.dragEnterListener);
      this.dragEnterListener = null;
    }
    if (this.dragLeaveListener) {
      document.removeEventListener('dragleave', this.dragLeaveListener);
      this.dragLeaveListener = null;
    }
  }
  onMouseDown(event) {
    if (event.target.nodeName === 'INPUT' || event.target.nodeName === 'TEXTAREA' || DomHandler.hasClass(event.target, 'ui-column-resizer')) this.el.nativeElement.draggable = false;else this.el.nativeElement.draggable = true;
  }
  onDragStart(event) {
    this.dt.onColumnDragStart(event, this.el.nativeElement);
  }
  onDragOver(event) {
    event.preventDefault();
  }
  onDragEnter(event) {
    this.dt.onColumnDragEnter(event, this.el.nativeElement);
  }
  onDragLeave(event) {
    this.dt.onColumnDragLeave(event);
  }
  onDrop(event) {
    if (this.isEnabled()) {
      this.dt.onColumnDrop(event, this.el.nativeElement);
    }
  }
  isEnabled() {
    return this.pReorderableColumnDisabled !== true;
  }
  ngOnDestroy() {
    this.unbindEvents();
  }
};
ReorderableColumn.ctorParameters = () => [{
  type: Table
}, {
  type: ElementRef
}, {
  type: NgZone
}];
__decorate([Input()], ReorderableColumn.prototype, "pReorderableColumnDisabled", void 0);
__decorate([HostListener('drop', ['$event'])], ReorderableColumn.prototype, "onDrop", null);
ReorderableColumn = __decorate([Directive({
  selector: '[pReorderableColumn]'
})], ReorderableColumn);
let EditableColumn = class EditableColumn {
  constructor(dt, el, zone) {
    this.dt = dt;
    this.el = el;
    this.zone = zone;
  }
  ngAfterViewInit() {
    if (this.isEnabled()) {
      DomHandler.addClass(this.el.nativeElement, 'ui-editable-column');
    }
  }
  onClick(event) {
    if (this.isEnabled()) {
      this.dt.editingCellClick = true;
      if (this.dt.editingCell) {
        if (this.dt.editingCell !== this.el.nativeElement) {
          if (!this.dt.isEditingCellValid()) {
            return;
          }
          this.closeEditingCell(true, event);
          this.openCell();
        }
      } else {
        this.openCell();
      }
    }
  }
  openCell() {
    this.dt.updateEditingCell(this.el.nativeElement, this.data, this.field, this.rowIndex);
    DomHandler.addClass(this.el.nativeElement, 'ui-editing-cell');
    this.dt.onEditInit.emit({
      field: this.field,
      data: this.data,
      index: this.rowIndex
    });
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        let focusCellSelector = this.pFocusCellSelector || 'input, textarea, select';
        let focusableElement = DomHandler.findSingle(this.el.nativeElement, focusCellSelector);
        if (focusableElement) {
          focusableElement.focus();
        }
      }, 50);
    });
  }
  closeEditingCell(completed, event) {
    if (completed) this.dt.onEditComplete.emit({
      field: this.dt.editingCellField,
      data: this.dt.editingCellData,
      originalEvent: event,
      index: this.rowIndex
    });else this.dt.onEditCancel.emit({
      field: this.dt.editingCellField,
      data: this.dt.editingCellData,
      originalEvent: event,
      index: this.rowIndex
    });
    DomHandler.removeClass(this.dt.editingCell, 'ui-editing-cell');
    this.dt.editingCell = null;
    this.dt.editingCellData = null;
    this.dt.editingCellField = null;
    this.dt.unbindDocumentEditListener();
  }
  onEnterKeyDown(event) {
    if (this.isEnabled()) {
      if (this.dt.isEditingCellValid()) {
        this.closeEditingCell(true, event);
      }
      event.preventDefault();
    }
  }
  onEscapeKeyDown(event) {
    if (this.isEnabled()) {
      if (this.dt.isEditingCellValid()) {
        this.closeEditingCell(false, event);
      }
      event.preventDefault();
    }
  }
  onShiftKeyDown(event) {
    if (this.isEnabled()) {
      if (event.shiftKey) this.moveToPreviousCell(event);else {
        this.moveToNextCell(event);
      }
    }
  }
  findCell(element) {
    if (element) {
      let cell = element;
      while (cell && !DomHandler.hasClass(cell, 'ui-editing-cell')) {
        cell = cell.parentElement;
      }
      return cell;
    } else {
      return null;
    }
  }
  moveToPreviousCell(event) {
    let currentCell = this.findCell(event.target);
    if (currentCell) {
      let targetCell = this.findPreviousEditableColumn(currentCell);
      if (targetCell) {
        if (this.dt.isEditingCellValid()) {
          this.closeEditingCell(true, event);
        }
        DomHandler.invokeElementMethod(event.target, 'blur');
        DomHandler.invokeElementMethod(targetCell, 'click');
        event.preventDefault();
      }
    }
  }
  moveToNextCell(event) {
    let currentCell = this.findCell(event.target);
    if (currentCell) {
      let targetCell = this.findNextEditableColumn(currentCell);
      if (targetCell) {
        if (this.dt.isEditingCellValid()) {
          this.closeEditingCell(true, event);
        }
        DomHandler.invokeElementMethod(event.target, 'blur');
        DomHandler.invokeElementMethod(targetCell, 'click');
        event.preventDefault();
      }
    }
  }
  findPreviousEditableColumn(cell) {
    let prevCell = cell.previousElementSibling;
    if (!prevCell) {
      let previousRow = cell.parentElement.previousElementSibling;
      if (previousRow) {
        prevCell = previousRow.lastElementChild;
      }
    }
    if (prevCell) {
      if (DomHandler.hasClass(prevCell, 'ui-editable-column')) return prevCell;else return this.findPreviousEditableColumn(prevCell);
    } else {
      return null;
    }
  }
  findNextEditableColumn(cell) {
    let nextCell = cell.nextElementSibling;
    if (!nextCell) {
      let nextRow = cell.parentElement.nextElementSibling;
      if (nextRow) {
        nextCell = nextRow.firstElementChild;
      }
    }
    if (nextCell) {
      if (DomHandler.hasClass(nextCell, 'ui-editable-column')) return nextCell;else return this.findNextEditableColumn(nextCell);
    } else {
      return null;
    }
  }
  isEnabled() {
    return this.pEditableColumnDisabled !== true;
  }
};
EditableColumn.ctorParameters = () => [{
  type: Table
}, {
  type: ElementRef
}, {
  type: NgZone
}];
__decorate([Input("pEditableColumn")], EditableColumn.prototype, "data", void 0);
__decorate([Input("pEditableColumnField")], EditableColumn.prototype, "field", void 0);
__decorate([Input("pEditableColumnRowIndex")], EditableColumn.prototype, "rowIndex", void 0);
__decorate([Input()], EditableColumn.prototype, "pEditableColumnDisabled", void 0);
__decorate([Input()], EditableColumn.prototype, "pFocusCellSelector", void 0);
__decorate([HostListener('click', ['$event'])], EditableColumn.prototype, "onClick", null);
__decorate([HostListener('keydown.enter', ['$event'])], EditableColumn.prototype, "onEnterKeyDown", null);
__decorate([HostListener('keydown.escape', ['$event'])], EditableColumn.prototype, "onEscapeKeyDown", null);
__decorate([HostListener('keydown.tab', ['$event']), HostListener('keydown.shift.tab', ['$event']), HostListener('keydown.meta.tab', ['$event'])], EditableColumn.prototype, "onShiftKeyDown", null);
EditableColumn = __decorate([Directive({
  selector: '[pEditableColumn]'
})], EditableColumn);
let EditableRow = class EditableRow {
  constructor(el) {
    this.el = el;
  }
  isEnabled() {
    return this.pEditableRowDisabled !== true;
  }
};
EditableRow.ctorParameters = () => [{
  type: ElementRef
}];
__decorate([Input("pEditableRow")], EditableRow.prototype, "data", void 0);
__decorate([Input()], EditableRow.prototype, "pEditableRowDisabled", void 0);
EditableRow = __decorate([Directive({
  selector: '[pEditableRow]'
})], EditableRow);
let InitEditableRow = class InitEditableRow {
  constructor(dt, editableRow) {
    this.dt = dt;
    this.editableRow = editableRow;
  }
  onClick(event) {
    this.dt.initRowEdit(this.editableRow.data);
    event.preventDefault();
  }
};
InitEditableRow.ctorParameters = () => [{
  type: Table
}, {
  type: EditableRow
}];
__decorate([HostListener('click', ['$event'])], InitEditableRow.prototype, "onClick", null);
InitEditableRow = __decorate([Directive({
  selector: '[pInitEditableRow]'
})], InitEditableRow);
let SaveEditableRow = class SaveEditableRow {
  constructor(dt, editableRow) {
    this.dt = dt;
    this.editableRow = editableRow;
  }
  onClick(event) {
    this.dt.saveRowEdit(this.editableRow.data, this.editableRow.el.nativeElement);
    event.preventDefault();
  }
};
SaveEditableRow.ctorParameters = () => [{
  type: Table
}, {
  type: EditableRow
}];
__decorate([HostListener('click', ['$event'])], SaveEditableRow.prototype, "onClick", null);
SaveEditableRow = __decorate([Directive({
  selector: '[pSaveEditableRow]'
})], SaveEditableRow);
let CancelEditableRow = class CancelEditableRow {
  constructor(dt, editableRow) {
    this.dt = dt;
    this.editableRow = editableRow;
  }
  onClick(event) {
    this.dt.cancelRowEdit(this.editableRow.data);
    event.preventDefault();
  }
};
CancelEditableRow.ctorParameters = () => [{
  type: Table
}, {
  type: EditableRow
}];
__decorate([HostListener('click', ['$event'])], CancelEditableRow.prototype, "onClick", null);
CancelEditableRow = __decorate([Directive({
  selector: '[pCancelEditableRow]'
})], CancelEditableRow);
let CellEditor = class CellEditor {
  constructor(dt, editableColumn, editableRow) {
    this.dt = dt;
    this.editableColumn = editableColumn;
    this.editableRow = editableRow;
  }
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'input':
          this.inputTemplate = item.template;
          break;
        case 'output':
          this.outputTemplate = item.template;
          break;
      }
    });
  }
  get editing() {
    return this.dt.editingCell && this.editableColumn && this.dt.editingCell === this.editableColumn.el.nativeElement || this.editableRow && this.dt.editMode === 'row' && this.dt.isRowEditing(this.editableRow.data);
  }
};
CellEditor.ctorParameters = () => [{
  type: Table
}, {
  type: EditableColumn,
  decorators: [{
    type: Optional
  }]
}, {
  type: EditableRow,
  decorators: [{
    type: Optional
  }]
}];
__decorate([ContentChildren(PrimeTemplate)], CellEditor.prototype, "templates", void 0);
CellEditor = __decorate([Component({
  selector: 'p-cellEditor',
  template: `
        <ng-container *ngIf="editing">
            <ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
        </ng-container>
        <ng-container *ngIf="!editing">
            <ng-container *ngTemplateOutlet="outputTemplate"></ng-container>
        </ng-container>
    `
}), __param(1, Optional()), __param(2, Optional())], CellEditor);
let TableRadioButton = class TableRadioButton {
  constructor(dt, tableService) {
    this.dt = dt;
    this.tableService = tableService;
    this.subscription = this.dt.tableService.selectionSource$.subscribe(() => {
      this.checked = this.dt.isSelected(this.value);
    });
  }
  ngOnInit() {
    this.checked = this.dt.isSelected(this.value);
  }
  onClick(event) {
    if (!this.disabled) {
      this.dt.toggleRowWithRadio({
        originalEvent: event,
        rowIndex: this.index
      }, this.value);
    }
    DomHandler.clearSelection();
  }
  onFocus() {
    DomHandler.addClass(this.boxViewChild.nativeElement, 'ui-state-focus');
  }
  onBlur() {
    DomHandler.removeClass(this.boxViewChild.nativeElement, 'ui-state-focus');
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
};
TableRadioButton.ctorParameters = () => [{
  type: Table
}, {
  type: TableService
}];
__decorate([Input()], TableRadioButton.prototype, "disabled", void 0);
__decorate([Input()], TableRadioButton.prototype, "value", void 0);
__decorate([Input()], TableRadioButton.prototype, "index", void 0);
__decorate([Input()], TableRadioButton.prototype, "inputId", void 0);
__decorate([Input()], TableRadioButton.prototype, "name", void 0);
__decorate([Input()], TableRadioButton.prototype, "ariaLabel", void 0);
__decorate([ViewChild('box')], TableRadioButton.prototype, "boxViewChild", void 0);
TableRadioButton = __decorate([Component({
  selector: 'p-tableRadioButton',
  template: `
        <div class="ui-radiobutton ui-widget" (click)="onClick($event)">
            <div class="ui-helper-hidden-accessible">
                <input type="radio" [attr.id]="inputId" [attr.name]="name" [checked]="checked" (focus)="onFocus()" (blur)="onBlur()"
                [disabled]="disabled" [attr.aria-label]="ariaLabel">
            </div>
            <div #box [ngClass]="{'ui-radiobutton-box ui-widget ui-state-default':true,
                'ui-state-active':checked, 'ui-state-disabled':disabled}" role="radio" [attr.aria-checked]="checked">
                <span class="ui-radiobutton-icon ui-clickable" [ngClass]="{'pi pi-circle-on':checked}"></span>
            </div>
        </div>
    `
})], TableRadioButton);
let TableCheckbox = class TableCheckbox {
  constructor(dt, tableService) {
    this.dt = dt;
    this.tableService = tableService;
    this.subscription = this.dt.tableService.selectionSource$.subscribe(() => {
      this.checked = this.dt.isSelected(this.value);
    });
  }
  ngOnInit() {
    this.checked = this.dt.isSelected(this.value);
  }
  onClick(event) {
    if (!this.disabled) {
      this.dt.toggleRowWithCheckbox({
        originalEvent: event,
        rowIndex: this.index
      }, this.value);
    }
    DomHandler.clearSelection();
  }
  onFocus() {
    DomHandler.addClass(this.boxViewChild.nativeElement, 'ui-state-focus');
  }
  onBlur() {
    DomHandler.removeClass(this.boxViewChild.nativeElement, 'ui-state-focus');
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
};
TableCheckbox.ctorParameters = () => [{
  type: Table
}, {
  type: TableService
}];
__decorate([Input()], TableCheckbox.prototype, "disabled", void 0);
__decorate([Input()], TableCheckbox.prototype, "value", void 0);
__decorate([Input()], TableCheckbox.prototype, "index", void 0);
__decorate([Input()], TableCheckbox.prototype, "inputId", void 0);
__decorate([Input()], TableCheckbox.prototype, "name", void 0);
__decorate([Input()], TableCheckbox.prototype, "required", void 0);
__decorate([Input()], TableCheckbox.prototype, "ariaLabel", void 0);
__decorate([ViewChild('box')], TableCheckbox.prototype, "boxViewChild", void 0);
TableCheckbox = __decorate([Component({
  selector: 'p-tableCheckbox',
  template: `
        <div class="ui-chkbox ui-widget" (click)="onClick($event)">
            <div class="ui-helper-hidden-accessible">
                <input type="checkbox" [attr.id]="inputId" [attr.name]="name" [checked]="checked" (focus)="onFocus()" (blur)="onBlur()" [disabled]="disabled"
                [attr.required]="required" [attr.aria-label]="ariaLabel">
            </div>
            <div #box [ngClass]="{'ui-chkbox-box ui-widget ui-state-default':true,
                'ui-state-active':checked, 'ui-state-disabled':disabled}" role="checkbox" [attr.aria-checked]="checked">
                <span class="ui-chkbox-icon ui-clickable" [ngClass]="{'pi pi-check':checked}"></span>
            </div>
        </div>
    `
})], TableCheckbox);
let TableHeaderCheckbox = class TableHeaderCheckbox {
  constructor(dt, tableService) {
    this.dt = dt;
    this.tableService = tableService;
    this.valueChangeSubscription = this.dt.tableService.valueSource$.subscribe(() => {
      this.checked = this.updateCheckedState();
    });
    this.selectionChangeSubscription = this.dt.tableService.selectionSource$.subscribe(() => {
      this.checked = this.updateCheckedState();
    });
  }
  ngOnInit() {
    this.checked = this.updateCheckedState();
  }
  onClick(event) {
    if (!this.disabled) {
      if (this.dt.value && this.dt.value.length > 0) {
        this.dt.toggleRowsWithCheckbox(event, !this.checked);
      }
    }
    DomHandler.clearSelection();
  }
  onFocus() {
    DomHandler.addClass(this.boxViewChild.nativeElement, 'ui-state-focus');
  }
  onBlur() {
    DomHandler.removeClass(this.boxViewChild.nativeElement, 'ui-state-focus');
  }
  isDisabled() {
    return this.disabled || !this.dt.value || !this.dt.value.length;
  }
  ngOnDestroy() {
    if (this.selectionChangeSubscription) {
      this.selectionChangeSubscription.unsubscribe();
    }
    if (this.valueChangeSubscription) {
      this.valueChangeSubscription.unsubscribe();
    }
  }
  updateCheckedState() {
    if (this.dt.filteredValue) {
      const val = this.dt.filteredValue;
      return val && val.length > 0 && this.dt.selection && this.dt.selection.length > 0 && this.isAllFilteredValuesChecked();
    } else {
      const val = this.dt.value;
      return val && val.length > 0 && this.dt.selection && this.dt.selection.length > 0 && this.dt.selection.length === val.length;
    }
  }
  isAllFilteredValuesChecked() {
    if (!this.dt.filteredValue) {
      return false;
    } else {
      for (let rowData of this.dt.filteredValue) {
        if (!this.dt.isSelected(rowData)) {
          return false;
        }
      }
      return true;
    }
  }
};
TableHeaderCheckbox.ctorParameters = () => [{
  type: Table
}, {
  type: TableService
}];
__decorate([ViewChild('box')], TableHeaderCheckbox.prototype, "boxViewChild", void 0);
__decorate([Input()], TableHeaderCheckbox.prototype, "disabled", void 0);
__decorate([Input()], TableHeaderCheckbox.prototype, "inputId", void 0);
__decorate([Input()], TableHeaderCheckbox.prototype, "name", void 0);
__decorate([Input()], TableHeaderCheckbox.prototype, "ariaLabel", void 0);
TableHeaderCheckbox = __decorate([Component({
  selector: 'p-tableHeaderCheckbox',
  template: `
        <div class="ui-chkbox ui-widget" (click)="onClick($event)">
            <div class="ui-helper-hidden-accessible">
                <input #cb type="checkbox" [attr.id]="inputId" [attr.name]="name" [checked]="checked" (focus)="onFocus()" (blur)="onBlur()"
                [disabled]="isDisabled()" [attr.aria-label]="ariaLabel">
            </div>
            <div #box [ngClass]="{'ui-chkbox-box ui-widget ui-state-default':true,
                'ui-state-active':checked, 'ui-state-disabled': isDisabled()}" role="checkbox" [attr.aria-checked]="checked">
                <span class="ui-chkbox-icon ui-clickable" [ngClass]="{'pi pi-check':checked}"></span>
            </div>
        </div>
    `
})], TableHeaderCheckbox);
let ReorderableRowHandle = class ReorderableRowHandle {
  constructor(el) {
    this.el = el;
  }
  ngAfterViewInit() {
    DomHandler.addClass(this.el.nativeElement, 'ui-table-reorderablerow-handle');
  }
};
ReorderableRowHandle.ctorParameters = () => [{
  type: ElementRef
}];
__decorate([Input("pReorderableRowHandle")], ReorderableRowHandle.prototype, "index", void 0);
ReorderableRowHandle = __decorate([Directive({
  selector: '[pReorderableRowHandle]'
})], ReorderableRowHandle);
let ReorderableRow = class ReorderableRow {
  constructor(dt, el, zone) {
    this.dt = dt;
    this.el = el;
    this.zone = zone;
  }
  ngAfterViewInit() {
    if (this.isEnabled()) {
      this.el.nativeElement.droppable = true;
      this.bindEvents();
    }
  }
  bindEvents() {
    this.zone.runOutsideAngular(() => {
      this.mouseDownListener = this.onMouseDown.bind(this);
      this.el.nativeElement.addEventListener('mousedown', this.mouseDownListener);
      this.dragStartListener = this.onDragStart.bind(this);
      this.el.nativeElement.addEventListener('dragstart', this.dragStartListener);
      this.dragEndListener = this.onDragEnd.bind(this);
      this.el.nativeElement.addEventListener('dragend', this.dragEndListener);
      this.dragOverListener = this.onDragOver.bind(this);
      this.el.nativeElement.addEventListener('dragover', this.dragOverListener);
      this.dragLeaveListener = this.onDragLeave.bind(this);
      this.el.nativeElement.addEventListener('dragleave', this.dragLeaveListener);
    });
  }
  unbindEvents() {
    if (this.mouseDownListener) {
      document.removeEventListener('mousedown', this.mouseDownListener);
      this.mouseDownListener = null;
    }
    if (this.dragStartListener) {
      document.removeEventListener('dragstart', this.dragStartListener);
      this.dragStartListener = null;
    }
    if (this.dragEndListener) {
      document.removeEventListener('dragend', this.dragEndListener);
      this.dragEndListener = null;
    }
    if (this.dragOverListener) {
      document.removeEventListener('dragover', this.dragOverListener);
      this.dragOverListener = null;
    }
    if (this.dragLeaveListener) {
      document.removeEventListener('dragleave', this.dragLeaveListener);
      this.dragLeaveListener = null;
    }
  }
  onMouseDown(event) {
    if (DomHandler.hasClass(event.target, 'ui-table-reorderablerow-handle')) this.el.nativeElement.draggable = true;else this.el.nativeElement.draggable = false;
  }
  onDragStart(event) {
    this.dt.onRowDragStart(event, this.index);
  }
  onDragEnd(event) {
    this.dt.onRowDragEnd(event);
    this.el.nativeElement.draggable = false;
  }
  onDragOver(event) {
    this.dt.onRowDragOver(event, this.index, this.el.nativeElement);
    event.preventDefault();
  }
  onDragLeave(event) {
    this.dt.onRowDragLeave(event, this.el.nativeElement);
  }
  isEnabled() {
    return this.pReorderableRowDisabled !== true;
  }
  onDrop(event) {
    if (this.isEnabled() && this.dt.rowDragging) {
      this.dt.onRowDrop(event, this.el.nativeElement);
    }
    event.preventDefault();
  }
};
ReorderableRow.ctorParameters = () => [{
  type: Table
}, {
  type: ElementRef
}, {
  type: NgZone
}];
__decorate([Input("pReorderableRow")], ReorderableRow.prototype, "index", void 0);
__decorate([Input()], ReorderableRow.prototype, "pReorderableRowDisabled", void 0);
__decorate([HostListener('drop', ['$event'])], ReorderableRow.prototype, "onDrop", null);
ReorderableRow = __decorate([Directive({
  selector: '[pReorderableRow]'
})], ReorderableRow);
let TableModule = class TableModule {};
TableModule = __decorate([NgModule({
  imports: [CommonModule, PaginatorModule, ScrollingModule],
  exports: [Table, SharedModule, SortableColumn, SelectableRow, RowToggler, ContextMenuRow, ResizableColumn, ReorderableColumn, EditableColumn, CellEditor, SortIcon, TableRadioButton, TableCheckbox, TableHeaderCheckbox, ReorderableRowHandle, ReorderableRow, SelectableRowDblClick, EditableRow, InitEditableRow, SaveEditableRow, CancelEditableRow, ScrollingModule],
  declarations: [Table, SortableColumn, SelectableRow, RowToggler, ContextMenuRow, ResizableColumn, ReorderableColumn, EditableColumn, CellEditor, TableBody, ScrollableView, SortIcon, TableRadioButton, TableCheckbox, TableHeaderCheckbox, ReorderableRowHandle, ReorderableRow, SelectableRowDblClick, EditableRow, InitEditableRow, SaveEditableRow, CancelEditableRow]
})], TableModule);

/**
 * Generated bundle index. Do not edit.
 */

export { CancelEditableRow, CellEditor, ContextMenuRow, EditableColumn, EditableRow, InitEditableRow, ReorderableColumn, ReorderableRow, ReorderableRowHandle, ResizableColumn, RowToggler, SaveEditableRow, ScrollableView, SelectableRow, SelectableRowDblClick, SortIcon, SortableColumn, Table, TableBody, TableCheckbox, TableHeaderCheckbox, TableModule, TableRadioButton, TableService };
