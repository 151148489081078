<rdo-breadcrumb></rdo-breadcrumb>
<rdo-overlay [enabled]="loading"></rdo-overlay>
<div class="container-fluid" id="rental-asset">
	<div class="row">
		<div class="col-xs-12" id="rdo-grid">
			<rdo-grid-table-ng *ngIf="data" #grid [name]="gridName" [gridConfig]="gridConfig" [pagedData]="data"
				[sorting]="sorting" [(paging)]="paging" [scrollScale]="'largeScale'" (pageOnChange)="changePage($event)"
				(lazyLoadOnChange)="changeLazyLoad($event)" [totalCount]="totalCount" [frozenWidth]="frozenWidth"
				[showColumnSelector]="true" (downloadExcelClick)="exportExcel($event)">
			</rdo-grid-table-ng>
		</div>
	</div>
</div>