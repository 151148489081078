import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  HTTP_WORD,
  MetricsApiService,
} from '../../services/metrics-api.service';
import { AuthenticationService } from '../../authentication/authentication.service';
import { ManagedPermissions, PlatformUserProfile, UserGuide } from '../models';
import { HttpLanguageLoader } from '../../i18n/http-language-loader';
import { LocaleService } from '../../services';

// TODO: Consider Merging this service with the intro js api service
@Injectable({
  providedIn: 'root',
})
export class GuidedToursService extends MetricsApiService {
  private currentProfile: BehaviorSubject<PlatformUserProfile> =
    new BehaviorSubject<PlatformUserProfile>(undefined);
  private guideTranslations: any = {};
  private languageLoader: HttpLanguageLoader;
  public $serviceIsReady = new BehaviorSubject<boolean>(false);
  private readonly DEFAULT_PROFILE: PlatformUserProfile;
  private readonly OPT_OUT_BASE_PATH = '/user-config/user/';
  private readonly OPT_OUT_FIELD_NAME = 'is_rdo_app_guidance_optout';
  private readonly USER_NAME_FIELD_NAME = 'identity_user_name';

  constructor(
    protected http: HttpClient,
    protected localeService: LocaleService,
    protected authenticationService: AuthenticationService
  ) {
    super(http, authenticationService);
    this.languageLoader = new HttpLanguageLoader(http, authenticationService);
    const defProf = {};
    defProf[this.OPT_OUT_FIELD_NAME] = false;
    this.DEFAULT_PROFILE = Object.assign(new PlatformUserProfile(), defProf);
    this.languageLoader
      .getTranslation(this.localeService.getLocale(), 'guide-translations')
      .subscribe((translations) => {
        this.guideTranslations = translations;
        this.$serviceIsReady.next(true);
      });
  }

  /**
   * Waits for this service to finish loading.
   */
  public wait4ServiceToBeReady(): Observable<boolean> {
    return new Observable<boolean>((obs) => {
      if (this.$serviceIsReady.value === true) {
        obs.next(true);
        obs.complete();
      } else {
        this.$serviceIsReady.subscribe((value) => {
          if (value) {
            obs.next(true);
            obs.complete();
          }
        });
      }
    });
  }

  /**
   * Translates a guide field based on a path within the translations json.
   */
  public translate(textPath: string): string {
    if (
      textPath &&
      textPath.length > 0 &&
      this.guideTranslations &&
      this.guideTranslations.main
    ) {
      let lastValue = this.guideTranslations;
      const path = textPath.split('.');
      path.forEach((x) => {
        lastValue =
          x && lastValue && lastValue.hasOwnProperty(x) ? lastValue[x] : null; // eslint-disable-line  no-prototype-builtins
      });
      if (lastValue && typeof lastValue === 'string') {
        textPath = lastValue;
      }
    }
    return textPath;
  }

  /**
   * Translates all relevant fields in all the given guides.
   */
  public translateGuides(guides: UserGuide[]): UserGuide[] {
    if (guides && guides.length) {
      guides.forEach((guide) => {
        guide.translatedName = this.translate('main.' + guide.name + '.name');
        guide.options.doneLabel = this.translate(guide.options?.doneLabel);
        guide.options.nextLabel = this.translate(guide.options?.nextLabel);
        guide.options.prevLabel = this.translate(guide.options?.prevLabel);
        guide.options.steps.forEach((step) => {
          if (step.title) {
            step.title = this.translate(step.title);
          }
          if (step.intro) {
            step.intro = this.translate(step.intro);
          }
        });
      });
      return guides;
    } else {
      return [];
    }
  }

  /**
   * Removes unauthorised steps in all the given guides.
   */
  public filterStepsByPermissions(guides: UserGuide[]): UserGuide[] {
    guides = this.translateGuides(guides);
    const unavailablePermissions = this.getUnavailablePermissions();
    guides.forEach((guide) => {
      this.removeUnauthorisedSteps(guide, unavailablePermissions);
    });
    return guides;
  }

  /**
   * Returns the list of managed permissions that the current user
   *  is missing.
   */
  private getUnavailablePermissions(): ManagedPermissions[] {
    const unavailablePermissions = [];
    if (!this.authenticationService._userInfoView.HasClientAccessToDownloads) {
      unavailablePermissions.push(ManagedPermissions.DOWNLOADS);
    }
    // Add Custom Grids permission management here...
    return unavailablePermissions;
  }

  /**
   * Removes steps from the given guide that require permissions
   * present in the unavailablePermissions array.
   */
  private removeUnauthorisedSteps(
    guide: UserGuide,
    unavailablePermissions: ManagedPermissions[]
  ): void {
    const unauthorisedSteps = [];
    guide.options.steps.forEach((step) => {
      if (
        step &&
        step.requiresPermission &&
        unavailablePermissions.includes(step.requiresPermission)
      ) {
        unauthorisedSteps.push(step);
      }
    });
    unauthorisedSteps.forEach((us) => {
      const index = guide.options.steps.findIndex(
        (x) =>
          x.element === us.element && x.type === us.type && x.intro === us.intro
      );
      guide.options.steps.splice(index, 1);
    });
  }

  public getLoadedProfile(): Observable<PlatformUserProfile> {
    const obs2return = new Observable<PlatformUserProfile>((obs) => {
      if (PlatformUserProfile.isValidProfile(this.currentProfile.value)) {
        obs.next(this.currentProfile.value);
        obs.complete();
      } else {
        this.readOptOutOption().subscribe((response: PlatformUserProfile) => {
          if (PlatformUserProfile.isValidProfile(response)) {
            this.currentProfile.next(response);
            obs.next(response);
          } else {
            obs.next(this.DEFAULT_PROFILE);
          }
          obs.complete();
        });
      }
    });
    return obs2return;
  }

  public profileExists(): boolean {
    return PlatformUserProfile.isValidProfile(this.currentProfile.value);
  }

  public isFirstLogin() {
    return !this.profileExists() && !this.localeService.localeWasFoundOnInit();
  }

  public readOptOutOption(): Observable<PlatformUserProfile> {
    return this.performHttpRequest<PlatformUserProfile>(
      HTTP_WORD.GET,
      this.baseUrl + this.OPT_OUT_BASE_PATH + 'read_profile',
      null,
      (response: any) => Object.assign(new PlatformUserProfile(), response)
    );
  }

  public createOptOutOption(value: boolean): Observable<PlatformUserProfile> {
    return this.performHttpRequest<PlatformUserProfile>(
      HTTP_WORD.POST,
      this.baseUrl + this.OPT_OUT_BASE_PATH + 'create_profile',
      this.buildPayload(value),
      (response: any) => Object.assign(new PlatformUserProfile(), response)
    );
  }

  public updateOptOutOption(value: boolean): Observable<PlatformUserProfile> {
    return this.performHttpRequest<PlatformUserProfile>(
      HTTP_WORD.PUT,
      this.baseUrl + this.OPT_OUT_BASE_PATH + 'update_profile',
      this.buildPayload(value),
      (response: any) => Object.assign(new PlatformUserProfile(), response)
    );
  }

  private buildPayload(value: boolean) {
    const payload = {};
    payload[this.OPT_OUT_FIELD_NAME] = value;
    payload[this.USER_NAME_FIELD_NAME] =
      this.authenticationService._userInfoView.ImpersonatedOrCurrentUserEmail;
    return payload;
  }
}
