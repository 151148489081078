import { addEventListener } from '../../browser/addEventListener';
import { combine } from '../../tools/mergeInto';
var CONTEXT_STORE_KEY_PREFIX = '_dd_c';
var storageListeners = [];
export function storeContextManager(configuration, contextManager, productKey, customerDataType) {
  var storageKey = buildStorageKey(productKey, customerDataType);
  storageListeners.push(addEventListener(configuration, window, "storage" /* DOM_EVENT.STORAGE */, function (_a) {
    var key = _a.key;
    if (storageKey === key) {
      synchronizeWithStorage();
    }
  }));
  contextManager.changeObservable.subscribe(dumpToStorage);
  contextManager.setContext(combine(getFromStorage(), contextManager.getContext()));
  function synchronizeWithStorage() {
    contextManager.setContext(getFromStorage());
  }
  function dumpToStorage() {
    localStorage.setItem(storageKey, JSON.stringify(contextManager.getContext()));
  }
  function getFromStorage() {
    var rawContext = localStorage.getItem(storageKey);
    return rawContext !== null ? JSON.parse(rawContext) : {};
  }
}
export function buildStorageKey(productKey, customerDataType) {
  return "".concat(CONTEXT_STORE_KEY_PREFIX, "_").concat(productKey, "_").concat(customerDataType);
}
export function removeStorageListeners() {
  storageListeners.map(function (listener) {
    return listener.stop();
  });
}
