import {
  Component,
  ViewEncapsulation,
  OnInit,
  OnDestroy,
  ApplicationRef,
} from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import {
  ColumnDefinitionService,
  IGridColumnGroup,
  MetricsGridConfig,
  ActiveFilterService,
  BreadcrumbService,
  FormatService,
  GridSortingService,
} from '../core';
import { HeaderService } from '../header';
import { GeographyComponent } from './geography.component';
import { GeographyService } from './geography.service';
import { DownloadsService } from '../downloads';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'rdo-region-grid',
  templateUrl: 'geography.component.html',
  styleUrls: ['geography.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegionComponent
  extends GeographyComponent
  implements OnInit, OnDestroy
{
  constructor(
    protected authenticationService: AuthenticationService,
    protected filterService: ActiveFilterService,
    protected columnService: ColumnDefinitionService,
    protected headerService: HeaderService,
    protected formatService: FormatService,
    private geographyService: GeographyService,
    private breadcrumbService: BreadcrumbService,
    private downloadsService: DownloadsService,
    protected translateService: TranslateService,
    private ref: ChangeDetectorRef,
    private appRef: ApplicationRef,
    protected gridSortingService: GridSortingService,
    protected router: Router
  ) {
    super(
      authenticationService,
      filterService,
      columnService,
      headerService,
      formatService,
      translateService,
      gridSortingService,
      router
    );
    this.gridName = 'REGIONS_GRID';
    this.dataKey = 'RegionId';
    this.useExpandedGrid = true;
    this.breadcrumbService.update([
      {
        title: 'main.tabs.geography.regions.all',
        class: 'active',
      },
    ]);

    this.gridSortingService.setGridName(this.gridName);
  }

  loadData(): Observable<any> {
    const component = this; // eslint-disable-line  @typescript-eslint/no-this-alias
    return this.geographyService.getRegions(
      this.paging,
      this.getSorting(),
      true
    ) /*.pipe(map(region => {
			if (region.Items && region.Items[0] && region.Items[0].ClientRegion == 'ALL GEOGRAPHIES') {
				region.Items[0].ClientRegion = 'main.tabs.geography.all';
			}
			return region;
		}))*/;
  }

  exportExcel(args: any) {
    this.loading = true;
    this.sorting.sortField = this.gridSortingService.getSortFieldOrDefault();
    this.sorting.sortOrder =
      this.gridSortingService.getSortDirectionOrDefault();
    const translatedConfig = this.gridConfig.cloneAndTranslate((text) =>
      this.formatService.translateAndFormat(text, false)
    );
    this.subscriptions.push(
      this.geographyService
        .getRegionsDownload(this.getSorting(), translatedConfig)
        .subscribe((blob) => {
          this.loading = false;
          this.downloadsService.saveExcelBlob(blob);
        })
    );
  }

  getFixedColumnGroup(): IGridColumnGroup {
    return {
      title: '',
      visible: true,
      locked: true,
      expandable: false,
      columns: [
        {
          title: 'main.tabs.geography.regions.singular',
          field: 'ClientRegion',
          sortColumn: 'ClientRegion',
          minWidth: 125,
          width: 100,
          maxWidth: 255,
          autoWidth: true,
          headerStyle: {
            'text-align': 'left',
          },
          cellStyle: {
            'text-align': 'left',
          },
          childConfig: {
            field: '',
          },
        },
        this.columnService.DistrictCount(),
      ],
    };
  }

  configureGrid(): MetricsGridConfig {
    this.frozenWidth = '335px';

    const grid = new MetricsGridConfig(
      [this.getFixedColumnGroup(), ...this.getColumnGroups()],
      (p) => this.loadChildren(p),
      true
    );

    return grid;
  }

  private loadChildren = (parent: any): Observable<Array<any>> => {
    return parent.RegionId
      ? this.geographyService
          .getDistricts(
            parent.RegionId,
            { pageSize: 500, page: 1 },
            this.getSorting(),
            false
          )
          .pipe(map((x) => x.Items.map((item) => this.convert(item))))
      : of(null);
  };

  private convert = (item: any): any => {
    const clone = (<any>Object).assign({}, item);
    clone.ClientRegion = null;
    clone.DistrictCount = item.ClientDistrict;
    return clone;
  };
}
