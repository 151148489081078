import { FilterProfile } from './filter-profile';
import { PlatformProfilesMap } from './platform-profiles-map';
import { PlatformFilterProfileField } from './platform-filter-profile-field';
import { GlobalField } from './global-field';
import { FilterProfileMap } from './filter-profile-map';

export class PlatformFilterProfile {
    public display_name: string;
    public profile_id: number;
    public is_default: boolean;
    public fields: Array<PlatformFilterProfileField>;

    private static readPlatformProfile(profile: FilterProfile, platformMap: PlatformProfilesMap = undefined) {
        let readProfile: PlatformFilterProfile;
        if(profile.profileId && platformMap && platformMap.user_defined?.length) {
            readProfile = platformMap.user_defined.find(x => x.profile_id === profile.profileId);
        }
        return readProfile;
    }

    /**
     * Returns the fieldId for a given global field name within a given platform profile.
     * A function is needed for this because depending on the case, different ways to obtain
     * this value are available.
     */
    private static readFieldId(platformProfile: PlatformFilterProfile, fieldName: string): number {
        const found = platformProfile?.fields?.find(x => x.field_name === fieldName || fieldName === (GlobalField[x.global_field] + '')); // Sometimes, the field_name is not available in api responses
        const result = found?.field_id || found?.module_id; // Api returns field_id in save requests but uses module_id in update requests
        return result;
    }

    /**
     * Turns a FilterProfile into a PlatformFilterProfile.
     */
    public static ingestFilterProfile(profile: FilterProfile, platformMap: PlatformProfilesMap = undefined): PlatformFilterProfile {
        let platformProfile = new PlatformFilterProfile();
        if(profile) {
            const existingPlatformProfile = this.readPlatformProfile(profile, platformMap);
            platformProfile = Object.assign(platformProfile, {
                profile_id: profile.profileId,
                display_name: profile.profileName,
                is_default: profile.isDefault,
                fields: []
            });
            let index = 1;
            const existingKeys = ['profileId', 'profileName', 'isDefault'];
            const arrayFields = PlatformFilterProfileField.getArrayFields();
            const clientOnlyFields = FilterProfileMap.getIgnorableFields(true);
            const invalidKeys = [...existingKeys, ...arrayFields, ...clientOnlyFields];
            Object.keys(profile).filter(x => !invalidKeys.includes(x)).forEach(key => {
                const fieldId = this.readFieldId(existingPlatformProfile, key);
                const reviewedValue = FilterProfileMap.restoreExcludedBoolean(key, profile[key]);
                const platformValue = PlatformFilterProfile.convert2PlatformValue(reviewedValue);
                const newPlatformField = PlatformFilterProfileField.build(key, index, platformValue, fieldId);
                platformProfile.fields.push(newPlatformField);
                index++;
            });
        }
        return platformProfile;
    }

    /**
     * Ensures that the value to be sotred in the platform filter
     * profile is an array of strings.
     */
    private static convert2PlatformValue(value: any): string[] {
        if(!Array.isArray(value)) {
            return [value];
        } else if (value.length) {
            const firstElem = value[0];
            if(typeof firstElem === 'string' || typeof firstElem === 'number') {
                return value.map(x => x + '');
            } else if (!firstElem) {
                return value;
            }
        } else {
            return [];
        }
    }
}
