import {Injectable, EventEmitter} from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { of } from 'rxjs/observable/of';

import { SalesRepCard } from '../models';

@Injectable()
export class SelectedSalesRepService {
    public salesRepChange: EventEmitter<SalesRepCard> = new EventEmitter<SalesRepCard>();
    private selectedSalesRep: SalesRepCard;
    private subject: Subject<SalesRepCard>;

    public get SalesRep(): Observable<SalesRepCard> {
        if (this.selectedSalesRep) {
            return of(this.selectedSalesRep);
        }

	    this.subject = new Subject<SalesRepCard>();
        return this.subject;
    }

    notify(salesRep: SalesRepCard) {
        if (!salesRep) {
            this.selectedSalesRep = null;
            return;
        }
        if (this.selectedSalesRep && this.selectedSalesRep.SalesRepId === salesRep.SalesRepId) {
            return;
        }
        this.selectedSalesRep = salesRep;
        if (this.subject) {
            this.subject.next(salesRep);
        }

        this.salesRepChange.next(salesRep);
    }
    clear() {
        this.selectedSalesRep = null;
    }
}
