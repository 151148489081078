<div id="multiclient-dialog" class="_container-fluid _modal-client-select" style="background-color: #555555;">
    <div class="modal-header">
        <h4 class="pull-left" style="color:white;">
            <img class="auth0-lock-header-logo" src="https://downloads.rouseservices.com/auth0/images/rse_analytics.svg" />
        </h4>
        <h4 class="pull-right" style="color:white;">&nbsp;</h4>
    </div>
    <div style="padding: 20px 20px 20px 20px;">
        <div class="row">
            <div class="col-md-12" style="color:white;">
                <h4>
                    {{'main.core.auth.client_selector.please_select_a_business_division' | translate}}:{{_tmp}}
                </h4>
            </div>
        </div>
    </div>
    <div class="row">
        &nbsp;
    </div>
    <div class="row">
        <div class="btn-group-vertical" style="width:100%;max-height:192px;overflow:auto;">
            <!--
            <div class="btn-group-vertical">
                <select class="client-selector" (click)="clientSelectionChanged($event.target.value)">
                    <option *ngFor="let c of _selectableclients" value="{{c.ClientID
                    }}">{{c.ClientShortName}}</option>
                    </select>
</div>
            -->
            <div class="btn-group-vertical" style="width: 100%;">
                <button *ngFor="let c of _selectableclients" 
                        (click)="selectSetMultiClient(c.ClientID)"
                        [disabled]="_currentClientId === c.ClientID || (!c.RDOAccess && !c.RDODownloads)"
                        class="three-state-item btn multiclient-select-button btn-default active"
                        type="button">
                    {{c.ClientShortName}}
                </button>
            </div>
        </div>
    </div>
    <div class="row">
        &nbsp;
    </div>
    <div class="row">
        &nbsp;
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="modal-footer" style="text-align: right;">
                <button id="MultiClientCancelButton" (click)="closeClick()" *ngIf="_currentClientId">
                    <i class="fa fa-times"></i>&nbsp;&nbsp;&nbsp;{{'main.core.auth.client_selector.cancel' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>

