import { Component, OnInit } from '@angular/core';
import { FILTERS_GUIDE, RATE_ANALYSIS_GUIDE, WELCOME_GUIDE } from '../../guides';
import { UserGuide } from '../../models';
import { GuidedToursService, IntroJsService } from '../../services';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { CookieConsentService } from '../../../../core/services';

@Component({
  selector: 'rdo-guided-tours-management',
  templateUrl: './guided-tours-management.component.html',
  styleUrls: ['./guided-tours-management.component.scss']
})
export class GuidedToursManagementComponent implements OnInit {
  public showContent: boolean = false;
  public showGuidedTours: boolean = false;
  private cookiesBlackBarIsVisible: boolean = false;
  public availableGuides: Array<UserGuide> = [RATE_ANALYSIS_GUIDE, FILTERS_GUIDE, WELCOME_GUIDE];

  constructor(
    private router: Router,
    private introJsService: IntroJsService,
    private guidedToursService: GuidedToursService,
    private cookieConsentService: CookieConsentService
  ) {
    this.guidedToursService.$serviceIsReady.subscribe(serviceIsReady => {
      if(serviceIsReady) {
        this.availableGuides = this.guidedToursService.filterStepsByPermissions(this.availableGuides);
      }
    });
    this.cookieConsentService.blackBarIsActuallyVisible.subscribe(blackBarIsVisible => {
      this.cookiesBlackBarIsVisible = blackBarIsVisible;
      this.updateshowGuidedTours();
    });
  }

  ngOnInit(): void {
    if(environment.enableUnpromptedGuidance) {
      this.setupFilterSelectionDisplay();
    }
    this.updateshowGuidedTours();
  }

  private updateshowGuidedTours() {
    const guidedToursEnabled = environment.enableUnpromptedGuidance;
    const currentlyInCg = this.router.url.toLowerCase().endsWith('custom-grids');
    const actuallyWaiting4Cookies = this.cookiesBlackBarIsVisible;
    this.showGuidedTours = guidedToursEnabled && !currentlyInCg && !actuallyWaiting4Cookies;
  }

  triggerGuide(guide: UserGuide) {
    this.introJsService.executeGuide(guide);
    this.showContent = false;
  }
  
  private setupFilterSelectionDisplay() {
    this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.updateshowGuidedTours();
        }
    });
}
}
