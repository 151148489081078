﻿<div class="container-fluid">
  <div class="row">
    <div class="col-xs-12">
      <ol class="breadcrumb" *ngIf="instructions">
        <li>
          <a [routerLink]="['/summary']">
            <i class="fa fa-home"></i>
          </a>
        </li>
        <li *ngFor="let i of instructions; let id = index;" [ngClass]="i.class">
          <a id="{{'breadcrumb-' + id}}" *ngIf="i.linkDSL" [routerLink]="i.linkDSL">
            <i *ngIf="i.icon" class="fa" [ngClass]="i.icon"></i>
            {{translateTitle(i.title)}}
          </a>
          <span id="{{'breadcrumb-' + id}}" *ngIf="!i.linkDSL">
            <i *ngIf="i.icon" class="fa" [ngClass]="i.icon"></i>
            {{translateTitle(i.title)}}
          </span>
        </li>
      </ol>
    </div>
  </div>
</div>