import { SortOption, SortOptionsNg, SortOptions } from '../../models';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GridSortingService {
    private readonly DEFAULT_SORT_OPTIONS_KEY = 'sort_options';
    private readonly DEFAULT_SORT_KEY = 'default_sort';
    private readonly DEFAULT_SORT_VALUE = 'RevenueBenchmarkedDifference';
    private readonly SORT_FIELD_KEY = 'field';
    private readonly SORT_DIRECTION_KEY = 'descending';

    private gridNameKey = new BehaviorSubject<string>('');

    get defaultSortColumn(): string {
        return this.readFromStorage(this.DEFAULT_SORT_KEY, this.DEFAULT_SORT_VALUE) || this.DEFAULT_SORT_VALUE;
    }

    constructor() {
        this.initService();
    }

    private initService() {
        this.gridNameKey.next(this.DEFAULT_SORT_KEY);
    }

    /**
     * Makes sure that the current grid has the proper default sorting configuration.
     */
    private assureDefaultSorting() {
        if (localStorage) {
            let sortConfig = localStorage.getItem(this.DEFAULT_SORT_OPTIONS_KEY);
            if (!sortConfig) {
                // No sort config at all, create default
                this.saveToStorage(this.DEFAULT_SORT_VALUE, false);
                sortConfig = localStorage.getItem(this.DEFAULT_SORT_OPTIONS_KEY);
            }
            if (sortConfig && !sortConfig.includes(this.gridNameKey.value)) {
                // If no sorting is configured for the current grid, set it with the default value
                // By calling this function again, the actual grid name is used rather than the defaul sorting
                this.saveToStorage(this.DEFAULT_SORT_VALUE, false);
            }
        }
    }

    private saveToStorage(value: any, descending: boolean) {
        if (localStorage && this.gridNameKey) {
            let current = localStorage.getItem(this.DEFAULT_SORT_OPTIONS_KEY);
            if (current) {
                const gridKey = this.gridNameKey.value;
                const sortOption = JSON.parse(current);
                if (sortOption[gridKey]) {
                    sortOption[gridKey].field = value;
                    sortOption[gridKey].descending = descending;
                } else {
                    sortOption[gridKey] = new SortOption(value, descending);
                }
                current = JSON.stringify(sortOption);
            } else {
                const defs = new SortOption(value, descending);
                current = JSON.stringify(defs.defaultSortOptions);
            }

            localStorage.setItem(this.DEFAULT_SORT_OPTIONS_KEY, current);
        }
    }

    private readFromStorage(sortKey: string, defaultValue: any): any {
        let result = defaultValue;
        if (localStorage && this.gridNameKey && sortKey) {
            const existing = localStorage.getItem(this.DEFAULT_SORT_OPTIONS_KEY);
            if (existing) {
                const exit = JSON.parse(existing);
                const gridKey = this.gridNameKey.value;
                if (exit[gridKey]) {
                    result = exit[gridKey][sortKey];
                }
            }
        }
        return result;
    }

    private getSortOptionsOrDefault(defaultField?: string, defaultOrder?: number): any {
        const sortField = this.getSortFieldOrDefault(defaultField);
        const sortOrder = this.getSortDirectionOrDefault(defaultOrder);
        return { sortField, sortOrder };
    }

    setGridName(gridKey: string) {
        this.gridNameKey.next(gridKey?.replace(/ /g, '_').toLowerCase());
        this.assureDefaultSorting();
    }

    getSortFieldOrDefault(defaultValue?: string): string {
        return this.readFromStorage(this.SORT_FIELD_KEY, defaultValue) || defaultValue || this.defaultSortColumn;
    }

    getSortDirectionOrDefault(defaultValue?: number): number {
        return (this.readFromStorage(this.SORT_DIRECTION_KEY, defaultValue) ? -1 : 1) || defaultValue || -1;
    }

    setSortOption(sortField: any, descending: boolean = false) {
        this.saveToStorage(sortField, descending);
    }

    getSortOptionsNg(defaultField?: string, defaultOrder?: number): SortOptionsNg {
        const { sortField, sortOrder } = this.getSortOptionsOrDefault(defaultField, defaultOrder);
        return new SortOptionsNg({ sortField, sortOrder });
    }

    getSortOptions(defaultField?: string, descending?: boolean): SortOptions {
        const { sortField, sortOrder } = this.getSortOptionsOrDefault(defaultField, descending ? -1 : 1);
        return new SortOptions(sortField, sortOrder === -1);
    }

    clearSortStorage() {
        localStorage?.removeItem(this.DEFAULT_SORT_OPTIONS_KEY);
    }
}
