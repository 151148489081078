import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { BreadcrumbService, InstructionInfo } from '../core';
import { SelectedSalesRepService } from './selected-sales-rep.service';
import * as _ from 'lodash';

@Component({
	template: ''
})
export class SalesRepBaseComponent implements OnDestroy {
	protected subscriptions: Array<Subscription> = [];

	constructor(
		protected selectedSalesRepService: SelectedSalesRepService,
		protected breadcrumbService: BreadcrumbService
	) { }

	protected updateBreadcrumbs = (instructions: Array<InstructionInfo>) => {
		this.selectedSalesRepService.SalesRep.subscribe(s => {
			const baseInstructions = [
				{
					title: 'main.core.common.counts.sales_reps.all',
					linkDSL: ['/sales-reps']
				},
				{
					title: s.SalesRepName,
					linkDSL: ['/sales-reps', s.SalesRepId]
				}
			];
			const breadcrumbInstructions = _.union<InstructionInfo>(baseInstructions, instructions);
			this.breadcrumbService.update(breadcrumbInstructions);
		});
	}

	ngOnDestroy() {
		while (this.subscriptions && this.subscriptions.length > 0) {
			this.subscriptions.pop().unsubscribe();
		}
	}

}
