import { Component, Input, ElementRef } from '@angular/core';
import {
  RevenueChartHelperService,
  HighchartsDirective,
  HighchartsConfiguration,
} from './../base';
import { FormatService, ViewService, ComparisonMode } from './../../query';
import { MathService } from './../../math';
import {
  UnitsInFleetChartService,
  RentalGrowthData,
  RentalGrowthType,
} from './../rental-growth';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../services';
import { CHART_COLORS } from '../base/chart-color-constants';

@Component({
  selector: 'rdo-on-rent-in-fleet-chart',
  styleUrls: ['./../base/revenue-chart-helper.scss'],
  templateUrl: './on-rent-in-fleet-chart.component.html',
  providers: [FormatService, MathService],
})
export class OnRentInFleetChartComponent {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('chart-type') chartType: string = 'YoY';
  private I18N_PATH = 'main.tabs.summary.charts.client_units_on_rent_growth';
  private chartConfig: HighchartsConfiguration = new HighchartsConfiguration();
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('chart-data') set chartData(data: Array<RentalGrowthData>) {
    if (data) {
      (<any>data).chartType = this.chartType;
      this.loadChart(data);
    }
  }

  private tooltipFunc = (): any => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;
    return {
      backgroundColor: null,
      borderWidth: 0,
      shadow: false,
      style: {
        padding: 0,
      },
      positioner: (boxWidth, boxHeight, point) =>
        component.unitsInFleetChartService.getTooltipPositionCustom(
          this.getChart(),
          point,
          100,
          345,
          0
        ),
      formatter: function () {
        const obj: RentalGrowthData = this.x;
        return `<div class="rental-growth-tooltip-chart">
                            <table class="table rdo-table-tooltip">
                                <tr>
									<td style="border-right: 1px solid #ddd !important;"></td>
									<td style="border-right: 1px solid #ddd !important;"><strong>${component.translateService.instant(component.I18N_PATH + '.client')}</strong></td>
									<td style="border-right: 1px solid #ddd !important;"><strong>${component.translateService.instant(component.I18N_PATH + '.bench')}</strong></td>
									<td><strong>${component.translateService.instant(component.I18N_PATH + '.diff')}</strong></td>
                                </tr>
                                <tr>
                                    <td style="border-right: 1px solid #ddd !important;">
                                        <span>${
                                          component.chartType === 'MoM'
                                            ? component.translateService.instant(
                                                component.I18N_PATH +
                                                  '.on_rent_mom'
                                              )
                                            : component.translateService.instant(
                                                component.I18N_PATH +
                                                  '.on_rent_yoy'
                                              )
                                        }</span> 
                                    </td> <!-- ClientGrowthMoM -->
									<td style="border-right: 1px solid #ddd !important;" class="${
                    (component.chartType === 'YoY' &&
                      obj.unitsOnRent.ClientGrowth < 0) ||
                    (component.chartType === 'MoM' &&
                      obj.unitsOnRent.ClientGrowthMoM < 0)
                      ? 'text-danger'
                      : ''
                  }">
											${
                        component.chartType === 'MoM'
                          ? component.formatService.formatPercent(
                              obj.unitsOnRent.ClientGrowthMoM
                            )
                          : component.formatService.formatPercent(
                              obj.unitsOnRent.ClientGrowth
                            )
                      }
                                    </td>
                                    <td style="border-right: 1px solid #ddd !important;" class="${
                                      (component.chartType === 'YoY' &&
                                        obj.unitsOnRent.BenchmarkGrowth < 0) ||
                                      (component.chartType === 'MoM' &&
                                        obj.unitsOnRent.BenchmarkGrowthMoM < 0)
                                        ? 'text-danger'
                                        : ''
                                    }">
											${
                        component.chartType === 'MoM'
                          ? component.formatService.formatPercent(
                              obj.unitsOnRent.BenchmarkGrowthMoM
                            )
                          : component.formatService.formatPercent(
                              obj.unitsOnRent.BenchmarkGrowth
                            )
                      }
                                    </td>
                                    <td class="${
                                      (component.chartType === 'YoY' &&
                                        obj.unitsOnRent.CvsBGrowthPointsDiff <
                                          0) ||
                                      (component.chartType === 'MoM' &&
                                        obj.unitsOnRent
                                          .CvsBGrowthPointsDiffMoM < 0)
                                        ? 'text-danger'
                                        : ''
                                    }">
											${
                        component.chartType === 'MoM'
                          ? component.formatService.formatPercent(
                              obj.unitsOnRent.CvsBGrowthPointsDiffMoM
                            )
                          : component.formatService.formatPercent(
                              obj.unitsOnRent.CvsBGrowthPointsDiff
                            )
                      } ${component.translateService.instant('main.core.common.counts.pts')}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="border-right: 1px solid #ddd !important;">
                                        <span>${
                                          component.chartType === 'MoM'
                                            ? component.translateService.instant(
                                                component.I18N_PATH +
                                                  '.in_fleet_mom'
                                              )
                                            : component.translateService.instant(
                                                component.I18N_PATH +
                                                  '.in_fleet_yoy'
                                              )
                                        }</span> 
                                    </td>
                                    <td style="border-right: 1px solid #ddd !important;" class="${
                                      (component.chartType === 'YoY' &&
                                        obj.unitsOnFleet.ClientGrowth < 0) ||
                                      (component.chartType === 'MoM' &&
                                        obj.unitsOnFleet.ClientGrowthMoM < 0)
                                        ? 'text-danger'
                                        : ''
                                    }">
											${
                        component.chartType === 'MoM'
                          ? component.formatService.formatPercent(
                              obj.unitsOnFleet.ClientGrowthMoM
                            )
                          : component.formatService.formatPercent(
                              obj.unitsOnFleet.ClientGrowth
                            )
                      }
                                    </td>
                                     <td style="border-right: 1px solid #ddd !important;" class="${
                                       (component.chartType === 'YoY' &&
                                         obj.unitsOnFleet.BenchmarkGrowth <
                                           0) ||
                                       (component.chartType === 'MoM' &&
                                         obj.unitsOnFleet.BenchmarkGrowthMoM <
                                           0)
                                         ? 'text-danger'
                                         : ''
                                     }">
											${
                        component.chartType === 'MoM'
                          ? component.formatService.formatPercent(
                              obj.unitsOnFleet.BenchmarkGrowthMoM
                            )
                          : component.formatService.formatPercent(
                              obj.unitsOnFleet.BenchmarkGrowth
                            )
                      }
                                    </td>
									<td class="${
                    (component.chartType === 'YoY' &&
                      obj.unitsOnFleet.CvsBGrowthPointsDiff < 0) ||
                    (component.chartType === 'MoM' &&
                      obj.unitsOnFleet.CvsBGrowthPointsDiffMoM < 0)
                      ? 'text-danger'
                      : ''
                  }">
											${
                        component.chartType === 'MoM'
                          ? component.formatService.formatPercent(
                              obj.unitsOnFleet.CvsBGrowthPointsDiffMoM
                            )
                          : component.formatService.formatPercent(
                              obj.unitsOnFleet.CvsBGrowthPointsDiff
                            )
                      } ${component.translateService.instant('main.core.common.counts.pts')}
                                    </td>
                                </tr>
                                 <tr>
                                    <td style="border-right: 1px solid #ddd !important;">
                                        <span>${component.translateService.instant(component.I18N_PATH + '.diff')}</span> 
                                    </td>
                                    <td style="border-right: 1px solid #ddd !important;" class="${
                                      (component.chartType === 'YoY' &&
                                        obj.unitsOnRent.ClientGrowth -
                                          obj.unitsOnFleet.ClientGrowth) < 0 ||
                                      (component.chartType === 'MoM' &&
                                        obj.unitsOnRent.ClientGrowthMoM -
                                          obj.unitsOnFleet.ClientGrowthMoM) < 0
                                        ? 'text-danger'
                                        : ''
                                    }">
										${
                      component.chartType === 'MoM'
                        ? component.formatService.formatPercent(
                            component.mathService.substract(
                              obj.unitsOnRent.ClientGrowthMoM,
                              obj.unitsOnFleet.ClientGrowthMoM,
                              1
                            )
                          )
                        : component.formatService.formatPercent(
                            component.mathService.substract(
                              obj.unitsOnRent.ClientGrowth,
                              obj.unitsOnFleet.ClientGrowth,
                              1
                            )
                          )
                    } ${component.translateService.instant('main.core.common.counts.pts')}
                                    </td>
                                     <td style="border-right: 1px solid #ddd !important;" class="${
                                       (component.chartType === 'YoY' &&
                                         obj.unitsOnRent.BenchmarkGrowth -
                                           obj.unitsOnFleet.BenchmarkGrowth) <
                                         0 ||
                                       (component.chartType === 'MoM' &&
                                         obj.unitsOnRent.BenchmarkGrowthMoM -
                                           obj.unitsOnFleet
                                             .BenchmarkGrowthMoM) < 0
                                         ? 'text-danger'
                                         : ''
                                     }">
										${
                      component.chartType === 'MoM'
                        ? component.formatService.formatPercent(
                            component.mathService.substract(
                              obj.unitsOnRent.BenchmarkGrowthMoM,
                              obj.unitsOnFleet.BenchmarkGrowthMoM,
                              1
                            )
                          )
                        : component.formatService.formatPercent(
                            component.mathService.substract(
                              obj.unitsOnRent.BenchmarkGrowth,
                              obj.unitsOnFleet.BenchmarkGrowth,
                              1
                            )
                          )
                    } ${component.translateService.instant('main.core.common.counts.pts')}
                                    </td>
                                    <td>
                                       
                                    </td>
                                </tr>
                                </table>
                        </div>`;
      },
      shared: false,
      useHTML: true,
    };
  };

  constructor(
    private element: ElementRef,
    private unitsInFleetChartService: UnitsInFleetChartService,
    private mathService: MathService,
    private formatService: FormatService,
    private translateService: TranslateService,
    private localeService: LocaleService,
    private revUtilChartService: RevenueChartHelperService
  ) {}

  private loadChart = (data: Array<any>) => {
    this.unitsInFleetChartService.setTooltipFunc(this.tooltipFunc());
    this.chartConfig = this.unitsInFleetChartService.loadChart(
      this.getChart(),
      data
    );
    // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-this-alias
    const _this = this;
    this.chartConfig.exportingOptions = {
      fileName: () => {
        return '';
      },
      getColumnsConfig: () => {
        return [
          {
            field: 'Month',
            displayName: _this.translateService.instant(
              'main.core.charts.export.month'
            ),
            customFormat: (rowValues) => {
              return _this.formatService.formatDate(
                rowValues.dateFrom,
                'MMM yyyy'
              );
            },
          },
          {
            field: 'ClientUnitsOnRent',
            displayName:
              _this.chartType === 'MoM'
                ? _this.translateService.instant(
                    'main.core.charts.export.client_on_rent_mom_growth_percent'
                  )
                : _this.translateService.instant(
                    'main.core.charts.export.client_on_rent_yoy_growth_percent'
                  ),
            customFormat: (rowValues) => {
              return _this.chartType === 'MoM'
                ? rowValues.unitsOnRent.ClientGrowthMoM
                : rowValues.unitsOnRent.ClientGrowth;
            },
            isPercent: true,
          },
          {
            field: 'ClientInFleet',
            displayName:
              _this.chartType === 'MoM'
                ? _this.translateService.instant(
                    'main.core.charts.export.client_in_fleet_mom_growth_rate_percent'
                  )
                : _this.translateService.instant(
                    'main.core.charts.export.client_in_fleet_yoy_growth_rate_percent'
                  ),
            customFormat: (rowValues) =>
              _this.chartType === 'MoM'
                ? rowValues.unitsOnFleet.ClientGrowthMoM
                : rowValues.unitsOnFleet.ClientGrowth,
            isPercent: true,
          },
          {
            field: 'ClientDifferenceOnRentFleet',
            displayName: _this.translateService.instant(
              'main.core.charts.export.diff_btw_client_on_rent_and_in_fleet'
            ),
            customFormat: (rowValues) =>
              _this.chartType === 'MoM'
                ? _this.mathService.substract(
                    rowValues.unitsOnRent.ClientGrowthMoM,
                    rowValues.unitsOnFleet.ClientGrowthMoM,
                    1
                  )
                : _this.mathService.substract(
                    rowValues.unitsOnRent.ClientGrowth,
                    rowValues.unitsOnFleet.ClientGrowth,
                    1
                  ),
            isPercent: true,
          },
          {
            field: 'BenchmarkOnRent',
            displayName:
              _this.chartType === 'MoM'
                ? _this.translateService.instant(
                    'main.core.charts.export.bench_on_rent_mom_growth_rate_percent'
                  )
                : _this.translateService.instant(
                    'main.core.charts.export.bench_on_rent_yoy_growth_rate_percent'
                  ),
            isPercent: true,
            customFormat: (rowValues) =>
              _this.chartType === 'MoM'
                ? rowValues.unitsOnRent.BenchmarkGrowthMoM
                : rowValues.unitsOnRent.BenchmarkGrowth,
          },
          {
            field: 'BenchmarkInFleet',
            displayName:
              _this.chartType === 'MoM'
                ? _this.translateService.instant(
                    'main.core.charts.export.bench_in_fleet_mom_growth_rate_percent'
                  )
                : _this.translateService.instant(
                    'main.core.charts.export.bench_in_fleet_yoy_growth_rate_percent'
                  ),
            isPercent: true,
            customFormat: (rowValues) =>
              _this.chartType === 'MoM'
                ? rowValues.unitsOnFleet.BenchmarkGrowthMoM
                : rowValues.unitsOnFleet.BenchmarkGrowth,
          },
          {
            field: 'BenchmarkDifferenceOnRentInFleet',
            displayName: _this.translateService.instant(
              'main.core.charts.export.diff_btw_bench_on_rent_and_bench_in_fleet_growth_rate'
            ),
            isPercent: true,
            customFormat: (rowValues) =>
              _this.chartType === 'MoM'
                ? _this.mathService.substract(
                    rowValues.unitsOnRent.BenchmarkGrowthMoM,
                    rowValues.unitsOnFleet.BenchmarkGrowthMoM,
                    1
                  )
                : _this.mathService.substract(
                    rowValues.unitsOnRent.BenchmarkGrowth,
                    rowValues.unitsOnFleet.BenchmarkGrowth,
                    1
                  ),
          },
          {
            field: 'DifferenceClientOnRentBenchmark',
            displayName: _this.translateService.instant(
              'main.core.charts.export.diff_btw_client_on_rent_and_bench'
            ),
            isPercent: true,
            customFormat: (rowValues) =>
              _this.chartType === 'MoM'
                ? rowValues.unitsOnRent.CvsBGrowthPointsDiffMoM
                : rowValues.unitsOnRent.CvsBGrowthPointsDiff,
          },
          {
            field: 'DifferenceClientInFleetBenchmark',
            displayName: _this.translateService.instant(
              'main.core.charts.export.diff_btw_client_in_fleet_and_bench'
            ),
            isPercent: true,
            customFormat: (rowValues) =>
              _this.chartType === 'MoM'
                ? rowValues.unitsOnFleet.CvsBGrowthPointsDiffMoM
                : rowValues.unitsOnFleet.CvsBGrowthPointsDiff,
          },
        ];
      },
      getFileName: () =>
        this.chartType === 'MoM'
          ? this.translateService.instant(
              'main.tabs.summary.charts.client_units_on_rent_growth.title_mom'
            )
          : this.translateService.instant(
              'main.tabs.summary.charts.client_units_on_rent_growth.title_yoy'
            ),
    };

    const options = {
      xAxis: {
        labels: {
          useHTML: true,
          formatter: function () {
            const locale = _this.localeService.getLocale();
            return `<div id="x-axis-${this.value.index}">${_this.revUtilChartService.addBenchmarkDiffForRevenueChart(this.value, locale)}</div>`;
          },
        },
      },
    };
  };

  private getChart = () => {
    return {
      height: 280,
      width: this.element.nativeElement.parentElement.offsetWidth,
      type: 'column',
    };
  };
}
