import {
  Component,
  ViewEncapsulation,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetricsGridConfig } from '../MetricsGridConfig';
import { getMovableColumns } from '../utils/getMovableColumns';
import { IColumnConfig } from '../IColumnConfig.interface';

@Component({
  selector: 'rdo-movable-column-arrows',
  templateUrl: './movable-column-arrows.component.html',
  styleUrls: ['./movable-column-arrows.component.scss'],
  imports: [CommonModule],
  standalone: true,
  // encapsulation breaks the main content when using maintenance mode as a wrapper.
  // encapsulation: ViewEncapsulation.ShadowDom
})
export class MovableColumnArrowsComponent implements OnInit, OnChanges {
  @Input() column: IColumnConfig;
  @Input() gridConfig: MetricsGridConfig;
  @Input() onMove: (col: IColumnConfig, direction: 'left' | 'right') => void;
  isActive = signal(false);

  private reducedGrid: IColumnConfig[] = [];

  ngOnInit() {
    this.reducedGrid = getMovableColumns(this.gridConfig);
    this.checkActive();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checkActive();
  }

  checkActive(): void {
    const group = this.gridConfig.groups.find((group) => {
      const findCol = group.columns.find(
        (col) => col.title === this.column.title
      );
      return typeof findCol !== 'undefined';
    });
    const visibleCols = group.columns.reduce((acc, col) => {
      if (col.visible) {
        acc += 1;
      }
      return acc;
    }, 0);

    this.isActive.set(visibleCols > 1);
  }

  onClick(direction, event: MouseEvent): void {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.onMove(this.column, direction);
  }

  get isLeftMost(): boolean {
    if (this.reducedGrid.length <= 1) {
      return false;
    }
    const leftCol = this.reducedGrid[0];
    return leftCol.title === this.column.title;
  }

  get isRightMost(): boolean {
    if (this.reducedGrid.length <= 1) {
      return false;
    }
    const rightCol = this.reducedGrid[this.reducedGrid.length - 1];
    return rightCol.title === this.column.title;
  }
}
