import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { zip } from 'rxjs/observable/zip';
import * as _ from 'lodash';
import * as filterFunctions from './../../../filter/functions/filter.functions';
import {
  ViewService,
  ComparisonMode,
  ChartType,
  ChartSwitcher,
  BreadcrumbService,
  ActiveFilterService,
  ClientProfileService,
  ChartSwitchingService,
  ChartDisplayer,
} from './../../../core';
import {
  SummaryService,
  RevenueService,
  RevenueSlice,
} from './../../../summary';
import {
  ChartData,
  TopItemsData,
  DashboardCardType,
  DashboardCardSetData,
  TxAttributes,
  FilterDefault,
} from './../../../models';
import { EquipmentService } from './../../equipment.service';
import { SelectedProductTypeService } from './../selected-product-type.service';
import { ProductTypeBaseComponent } from './../product-type-base.component';
import { ProductTypeDashboardService } from './product-type-dashboard.service';
import { ProductTypeDashboardSalesRepService } from './product-type-dashboard-sales-rep.service';
import { ProductTypeDashboardCustomerService } from './product-type-dashboard-customer.service';
// import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { FilterProfileService } from '../../../filter/profiles/filter-profile.service';
import { CODENAMES } from '../../../core/constants';

@Component({
  selector: 'rdo-product-type-dashboard',
  templateUrl: 'product-type-dashboard.component.html',
  styleUrls: [
    'product-type-dashboard.component.scss',
    './../../equipment.component.scss',
    '../../../../styles/_summary-charts.scss',
  ],
})
export class ProductTypeDashboardComponent
  extends ProductTypeBaseComponent
  implements OnInit, OnDestroy
{
  // component data
  private cardData: DashboardCardSetData;
  private chartData = new ChartData({ data: [], loading: false });
  private salesReps = new TopItemsData();
  private customers = new TopItemsData();
  private rateTypes = new TopItemsData();
  private rateChangeData = [];
  private revenueDistribution = [];
  private hasTotalYoYRateChangeData = false;
  private hasTotalMoMRateChangeData = false;
  private hasComparedYoYRateChangeData = false;
  private hasComparedMoMRateChangeData = false;
  private comparisonMode = ComparisonMode;
  private mode: ComparisonMode = ComparisonMode.Benchmark;
  private noData = false;
  private loading = true;
  private chartSwitcher = new ChartSwitcher(null);
  private clientAttributes = new TxAttributes(<TxAttributes>{ IsTrial: true });
  private monthsToReturn = 6;
  // private subscriptions = [];
  weeklyUtilization: any;
  // internal

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private viewService: ViewService,
    private summaryService: SummaryService,
    private customerService: ProductTypeDashboardCustomerService,
    private salesRepService: ProductTypeDashboardSalesRepService,
    filterService: ActiveFilterService,
    private dashboardService: ProductTypeDashboardService,
    private equipmentService: EquipmentService,
    private revenueService: RevenueService,
    selectedProductTypeService: SelectedProductTypeService,
    breadcrumbService: BreadcrumbService,
    private hostElement: ElementRef,
    private translateService: TranslateService,
    private chartSwitchingService: ChartSwitchingService,
    protected clientProfileService: ClientProfileService,
    protected filterProfileService: FilterProfileService
  ) {
    super(selectedProductTypeService, breadcrumbService, filterService);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.authenticationService.selectedClientId.subscribe((clientid) => {
        this.subscriptions.push(
          this.clientProfileService
            .getClientAttributes()
            .subscribe((attributes) => (this.clientAttributes = attributes))
        );
        this.subscriptions.push(
          this.filterProfileService.wait4CurrentProfile().subscribe(() => {
            const filterValue =
              this.filterProfileService.readClientProfileProperty(
                CODENAMES.CN_MONTHS_TO_RETURN
              );
            this.monthsToReturn = filterValue ? filterValue : 6;
            this.subscriptions.push(
              this.route.parent.params.subscribe((params) => {
                const routeParams = this.getRouteParams(params);
                if (this.hasRouteChanged(this.params, routeParams)) {
                  this.params = routeParams;
                  this.load();
                }
              })
            );
            this.subscriptions.push(
              this.selectedProductTypeService.productTypeChange.subscribe(
                (p) => {
                  // this.doUpdateBreadcrumbs(); // This is called in this.load() called below. Leaving here for now.
                  if (this.params.productType !== p.ProductType) {
                    this.params.productType = p.ProductType;
                    this.load();
                  }
                }
              )
            );
            if (!this.selectedProductTypeService.isProductSelected) {
              this.subscriptions.push(
                this.selectedProductTypeService.productType.subscribe((p) => {
                  this.doUpdateBreadcrumbs();
                })
              );
            }
            this.subscriptions.push(
              this.filterService.filterChange.subscribe(() => {
                this.hostElement.nativeElement.scrollIntoView({
                  block: 'start',
                  behavior: 'smooth',
                });
                this.load();
              })
            );
          })
        );
      })
    );
  }

  ngOnDestroy() {
    while (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.pop().unsubscribe();
    }
  }

  private doUpdateBreadcrumbs() {
    this.updateBreadcrumbs([
      {
        title: 'main.core.common.dashboard',
        class: 'active',
      },
    ]);
  }

  private load = () => {
    if (!this.params.productType) {
      return;
    }
    this.loading = true;
    this.loadChart();
    zip(
      this.salesRepService
        .getTopSalesReps(this.params.productType, this.params.category)
        .pipe(map((p) => <TopItemsData>{ data: p, loading: false })),
      this.customerService
        .getTopCustomers(this.params.productType, this.params.category)
        .pipe(map((p) => <TopItemsData>{ data: p, loading: false })),
      this.equipmentService
        .getProductTypeRateTypes({
          productType: this.params.productType,
          category: this.params.category,
        })
        .pipe(map((p) => <TopItemsData>{ data: p, loading: false })),
      this.equipmentService
        .getProductTypeInfo(this.params.productType, this.params.category)
        .pipe(map((x) => <DashboardCardSetData>x)),
      this.equipmentService.getProductTypeRateChanges(
        this.params.productType,
        this.params.category,
        this.monthsToReturn
      ),
      this.equipmentService.getProductTypeRevenueDistribution(
        this.params.productType,
        this.params.category
      )
    ).subscribe(
      (
        results: [
          TopItemsData,
          TopItemsData,
          TopItemsData,
          DashboardCardSetData,
          Array<any>,
          Array<any>,
        ]
      ) => {
        this.salesReps = results[0];
        this.customers = results[1];
        this.rateTypes = results[2];
        this.cardData = results[3];
        this.rateChangeData = results[4];
        this.revenueDistribution = results[5];
        this.loading = false;
        this.checkForRateChangeData();
        this.doUpdateBreadcrumbs();
      }
    );
  };

  private loadChart = () => {
    this.chartData = new ChartData({ data: [], loading: true });
    zip(
      this.dashboardService.getDashboardData(
        this.params.productType,
        this.params.category,
        this.monthsToReturn
      ),
      this.viewService.getComparisonMode()
    ).subscribe((results: [any, ComparisonMode]) => {
      this.chartData.data = results[0];
      this.chartData.loading = false;
      this.mode = results[1];

      this.chartSwitcher = this.chartSwitchingService.getCurrentChart(
        ChartDisplayer.PRODUCT_TYPES,
        this.mode,
        this.chartSwitcher,

        this.chartSwitcher.isTotalBenchmarkUtilizationWeeklyChart()
          ? this.selectTotalBenchmarkUtilizationChartWeeklyChart
          : this.selectBenchmarkUtilizationChartWeeklyChart
      );
      this.subscriptions.push(
        this.chartSwitcher.chartChanged.subscribe((chart) => {
          this.chartSwitchingService.setCurrentChart(
            ChartDisplayer.PRODUCT_TYPES,
            chart
          );
        })
      );
    });
  };

  private onSliceClick = (slice: RevenueSlice) => {
    const sliceParams = this.revenueService.getSliceParams(slice);
    const sliceName = this.revenueService.getSliceDisplayTitle(slice);
    const route = [
      'equipment',
      'product-types',
      this.params.productType,
      { category: this.params.category || '' },
      'transactions',
      sliceParams,
    ];
    this.updateBreadcrumbs([
      {
        title: sliceName,
        class: 'active',
      },
    ]);
    this.router.navigate(route);
  };

  onCardClicked = (cardType: DashboardCardType) => {
    switch (cardType) {
      case DashboardCardType.ClientRevenue:
        this.chartSwitcher.selectRevenueBookChart();
        break;
      case DashboardCardType.ClientRateVsBenchmark:
        this.chartSwitcher.selectRevenueBenchChart();
        break;
      case DashboardCardType.ClientPhysicalUtilization:
        this.chartSwitcher.selectTotalUtilizationChart();
        break;
      case DashboardCardType.ClientUtilizationVsBenchmark:
        this.chartSwitcher.selectBenchmarkUtilizationChart();
        break;
      case DashboardCardType.ClientDollarUtilization:
        this.chartSwitcher.selectTotalDollarUtilizationChart();
        break;
      case DashboardCardType.ClientDollarUtilizationVsBenchmark:
        this.chartSwitcher.selectBenchmarkDollarUtilizationChart();
        break;
      default:
        break;
    }
  };

  private checkForRateChangeData = () => {
    this.hasTotalMoMRateChangeData =
      this.hasTotalYoYRateChangeData =
      this.hasComparedMoMRateChangeData =
      this.hasComparedYoYRateChangeData =
        false;

    this.rateChangeData.forEach((rateChange) => {
      if (!_.isNil(rateChange.RateChangeMoMTotal)) {
        this.hasTotalMoMRateChangeData = true;
      }
      if (!_.isNil(rateChange.RateChangeYoYTotal)) {
        this.hasTotalYoYRateChangeData = true;
      }
      if (!_.isNil(rateChange.RateChangeMoMBenchmarked)) {
        this.hasComparedMoMRateChangeData = true;
      }
      if (!_.isNil(rateChange.RateChangeYoYBenchmarked)) {
        this.hasComparedYoYRateChangeData = true;
      }
    });
  };

  getSelectedChartName() {
    return this.chartSwitcher.translatedCurrent((text) =>
      this.translateService.instant(text)
    );
  }

  selectBenchmarkUtilizationChartWeeklyChart = () => {
    this.dashboardService
      .getWeeklyChartData(
        this.params.productType,
        this.params.category,
        this.filterProfileService.readClientProfileProperty(
          CODENAMES.CN_MONTHS_TO_RETURN
        )
      )
      .subscribe((weeklyData) => {
        this.weeklyUtilization = weeklyData;
        this.chartSwitcher.selectBenchmarkUtilizationWeeklyChart();
      });
  };

  selectTotalBenchmarkUtilizationChartWeeklyChart = () => {
    this.dashboardService
      .getWeeklyChartData(
        this.params.productType,
        this.params.category,
        this.filterProfileService.readClientProfileProperty(
          CODENAMES.CN_MONTHS_TO_RETURN
        )
      )
      .subscribe((weeklyData) => {
        this.weeklyUtilization = weeklyData;
        this.chartSwitcher.selectTotalBenchmarkUtilizationWeeklyChart();
      });
  };
}
