import { Injectable } from '@angular/core';
import { ComparisonMode } from '../../query/view.service';
import { ChartSwitcher, ChartType } from './chart-switcher';

export enum ChartDisplayer {
    SUMMARY = 'Summary',
    CUSTOMERS = 'Customers',
    SALES_REPS = 'Sales_Reps',
    PRODUCT_TYPES = 'Product_Types',
    CUSTOM_GRIDS = 'Custom_Grids'
}

@Injectable()
export class ChartSwitchingService {
    private currentCharts = { };

    constructor() { }

    getCurrentChart(
        screen: ChartDisplayer,
        mode: ComparisonMode,
        chartSwitcher: ChartSwitcher,
        benchWeeklyChart: () => unknown
    ) {
        const benchDefault = ChartType.RevenueBench;
        const defaultCharType = mode === ComparisonMode.Benchmark ? benchDefault : ChartType.RevenueBook;
        const charTypeToUse = chartSwitcher && chartSwitcher.selectedChart && chartSwitcher.selectedChart !== ChartType.None ?
            chartSwitcher.selectedChart : (this.currentCharts[screen] ? this.currentCharts[screen] : defaultCharType);
        
        if(charTypeToUse === ChartType.BenchmarkUtilizationWeekly || charTypeToUse === ChartType.TotalBenchmarkUtilizationWeekly) {
            if(benchWeeklyChart) {
                benchWeeklyChart();
            } else {
                chartSwitcher.selectBenchmarkUtilizationWeeklyChart();
            }
        } else {
            chartSwitcher = new ChartSwitcher(charTypeToUse);
        }
        this.currentCharts[screen] = charTypeToUse;
        return chartSwitcher;
    }

    setCurrentChart(screen: ChartDisplayer, chart: ChartType) {
        this.currentCharts[screen] = chart;
    }

}
