import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import * as _ from 'lodash';
import { RdoHttpService } from './../http';
import { TxAttributes } from './../../models';
import { ClientProfileService } from './../client';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable()
export class TrialGuardService  {

	private isTrial?: boolean;

    constructor(private authenticationService: AuthenticationService, 
		        private http: RdoHttpService,
		        private router: Router,
		        private clientProfileService: ClientProfileService) {}

    public canActivate(route: ActivatedRouteSnapshot,
                       state: RouterStateSnapshot) {

		return this.checkAccess();
	};

    private checkAccess = (): Observable<boolean> => {

        const isauthenticated = this.authenticationService.isauthenticated;

        if (isauthenticated === false) {

            this.authenticationService.login();

        } else {

            if (_.isNil(this.isTrial)) {

                return this.getTxAttributes().pipe(map((attrs: TxAttributes) => {

                    this.isTrial = attrs.IsTrial;

                    if (this.isTrial) {
                        this.router.navigate(['/summary']);
                    }

                    return isauthenticated && !this.isTrial;
                }));

            } else {

                if (this.isTrial) {

                    this.router.navigate(['/summary']);
                }

                return of(isauthenticated && !this.isTrial);
            }
        }
	}

	private getTxAttributes = (): Observable<TxAttributes> => {
		return this.clientProfileService.getClientAttributes();
	}
}
