/*
 * Angular 2 Dropdown Multiselect for Bootstrap
 *
 * Simon Lindh
 * https://github.com/softsimon/angular-2-dropdown-multiselect
 */
import { MultiSelectSearchFilter } from './search-filter.pipe';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from './types';
import {
	Component,
	DoCheck,
	ElementRef,
	EventEmitter,
	forwardRef,
	HostListener,
	Input,
	IterableDiffers,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { LocaleService } from '../services';
import { MonthSetting } from '../../../app/filter/profiles/models/month-settings';

const MULTISELECT_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	// eslint-disable-next-line no-use-before-define
	useExisting: forwardRef(() => MultiselectDropdownComponent),
	multi: true
};

/* eslint-disable @angular-eslint/no-output-on-prefix */

@Component({
	selector: 'rdo-multiselect-dropdown',
	templateUrl: 'dropdown.component.html',
	styleUrls: ['dropdown.component.scss'],
	providers: [MULTISELECT_VALUE_ACCESSOR]
})
export class MultiselectDropdownComponent implements OnInit, OnChanges, DoCheck, ControlValueAccessor, Validator {
	@Input() options: Array<IMultiSelectOption>;
	@Input() scrollableOptions: Array<IMultiSelectOption>;
	@Input() settings: IMultiSelectSettings;
	@Input() texts: IMultiSelectTexts;
	@Input() disabled: boolean = false;
	@Input() order: boolean = true;
	@Output() selectionLimitReached = new EventEmitter();
	@Output() dropdownClosed = new EventEmitter();
	@Output() dropdownOpened = new EventEmitter();
	@Output() onAdded = new EventEmitter();
	@Output() onRemoved = new EventEmitter();
	@Output() onMonthTextClicked = new EventEmitter();

	model: any[];
	parents: any[];
	title: string;
	differ: any;
	numSelected: number = 0;
	isVisible: boolean = false;
	searchFilterText: string = '';
	cursorItemStyle: object = { cursor: 'pointer' };
	startingPage: number = 0;
	defaultSettings: IMultiSelectSettings = {
		pullRight: false,
		enableSearch: false,
		checkedStyle: 'checkboxes',
		buttonClasses: 'btn btn-default btn-secondary',
		containerClasses: 'dropdown-inline',
		selectionLimit: 0,
		closeOnSelect: false,
		autoUnselect: false,
		showCheckAll: false,
		showUncheckAll: false,
		fixedTitle: false,
		dynamicTitleMaxItems: 3,
		maxHeight: '300px',
		autoClose: false
	};
	defaultTexts: IMultiSelectTexts = {
		checkAll: 'main.navigation.alerts.dropdown.check_all',
		uncheckAll: 'main.navigation.alerts.dropdown.uncheck_all',
		checked: 'main.navigation.alerts.dropdown.checked_singular',
		checkedPlural: 'main.navigation.alerts.dropdown.checked_plural',
		searchPlaceholder: 'main.navigation.alerts.dropdown.search_placeholder',
		defaultTitle: 'main.navigation.alerts.dropdown.default_title',
		allSelected: 'main.navigation.alerts.dropdown.all_selected'
	};
	currentCardPage = this.startingPage;
	cardPageSize = 20;

	constructor(
		private translateService: TranslateService,
		private element: ElementRef,
		differs: IterableDiffers,
		private localeService: LocaleService
	) {
		this.differ = differs.find([]).create(null);
		this.translateService.onLangChange.subscribe(() => {
			this.updateTitle();
		});
	}

	@HostListener('document: click', ['$event.target'])
	onClick(target: HTMLElement) {
		if (!this.isVisible) { return; }
		let parentFound = false;
		while (target != null && !parentFound) {
			if (target === this.element.nativeElement) {
				parentFound = true;
			}
			target = target.parentElement;
		}
		if (!parentFound) {
			this.isVisible = false;
			this.dropdownClosed.emit();
		}
	}

	sortOptions() {
		if (this.options && this.options.length && this.order) {
			this.options.sort((a, b) => {
				const isIE11 = !!(<any>window).MSInputMethodContext && !!(<any>document).documentMode;
				if (isIE11) {
					const textA = (<any>a.name).replace(/[\u0300-\u036f]/g, '').toLowerCase();
					const textB = (<any>b.name).replace(/[\u0300-\u036f]/g, '').toLowerCase();
					const result = (textA < textB) ? -1 : (textA > textB) ? 1 : 0;

					return result;

				} else {
					const textA = (<any>a.name).normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
					const textB = (<any>b.name).normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
					const result = (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
					return result;
				}
			});
		}
	}

	getItemStyle(option: IMultiSelectOption): any {
		if (!option.isLabel) {
			return this.cursorItemStyle;
		}
	}

	ngOnInit() {
		this.settings = (<any>Object).assign(this.defaultSettings, this.settings);
		this.texts = (<any>Object).assign(this.defaultTexts, this.texts);
		this.title = this.safelyTranslate(this.texts.defaultTitle);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.options) {
			this.options = this.options || [];
			this.parents = this.options
				.filter(option => typeof option.parentId === 'number')
				.map(option => option.parentId);
			this.sortOptions();
			this.initializeList();
		}

		if (changes.texts && !changes.texts.isFirstChange()) {
			this.updateTitle();
		}
	}

	onModelChange: (_: any) => unknown = (_: any) => { };
	onModelTouched: () => unknown = () => { };

	writeValue(value: any): void {
		if (value !== undefined && value !== null) {
			this.model = value;
		} else {
			this.model = [];
		}
	}

	registerOnChange(fn: () => unknown): void {
		this.onModelChange = fn;
	}

	registerOnTouched(fn: () => unknown): void {
		this.onModelTouched = fn;
	}

	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}

	ngDoCheck() {
		const changes = this.differ.diff(this.model) || this.differ.diff(this.options);
		if (changes) {
			this.updateNumSelected();
			this.updateTitle();
		}
	}

	validate(_c: AbstractControl): { [key: string]: any; } {
		return (this.model && this.model.length) ? null : {
			required: {
				valid: false,
			},
		};
	}

	registerOnValidatorChange(_fn: () => void): void {
		throw new Error('Method not implemented.');
	}

	clearSearch(event: Event) {
		event.stopPropagation();
		this.searchFilterText = '';
		this.initializeList();
	}

	toggleDropdown() {
		this.isVisible = !this.isVisible;
		// eslint-disable-next-line no-unused-expressions
		this.isVisible ? this.dropdownOpened.emit() : this.dropdownClosed.emit();
		if (this.isVisible && this.searchFilterApplied) {
			this.initializeList();
		}
	}

	isSelected(option: IMultiSelectOption): boolean {
		return this.model && this.model.indexOf(option.id) > -1;
	}

	setSelected(_event: Event, option: IMultiSelectOption) {
		this.onMonthTextClicked.emit(MonthSetting.SELECTION);
		_event.stopPropagation();
		if (!this.model) {
			this.model = [];
		}
		const index = this.model.indexOf(option.id);
		if (index > -1) {
			this.model.splice(index, 1);
			this.onRemoved.emit(option.id);
			const parentIndex = option.parentId && this.model.indexOf(option.parentId);
			if (parentIndex >= 0) {
				this.model.splice(parentIndex, 1);
				this.onRemoved.emit(option.parentId);
			} else if (this.parents.indexOf(option.id) > -1) {
				const childIds = this.options.filter(child => this.model.indexOf(child.id) > -1 && child.parentId === option.id).map(child => child.id);
				this.model = this.model.filter(id => childIds.indexOf(id) < 0);
				childIds.forEach(childId => this.onRemoved.emit(childId));
			}
		} else {
			if (this.settings.selectionLimit === 0 || (this.settings.selectionLimit && this.model.length < this.settings.selectionLimit)) {
				this.model.push(option.id);
				this.onAdded.emit(option.id);
				if (option.parentId) {
					const children = this.options.filter(child => child.id !== option.id && child.parentId === option.parentId);
					if (children.every(child => this.model.indexOf(child.id) > -1)) {
						this.model.push(option.parentId);
						this.onAdded.emit(option.parentId);
					}
				} else if (this.parents.indexOf(option.id) > -1) {
					const children = this.options.filter(child => this.model.indexOf(child.id) < 0 && child.parentId === option.id);
					children.forEach(child => {
						this.model.push(child.id);
						this.onAdded.emit(child.id);
					});
				}
			} else {
				if (this.settings.autoUnselect) {
					this.model.push(option.id);
					this.onAdded.emit(option.id);
					const removedOption = this.model.shift();
					this.onRemoved.emit(removedOption);
				} else {
					this.selectionLimitReached.emit(this.model.length);
					return;
				}
			}
		}
		if (this.settings.closeOnSelect) {
			this.toggleDropdown();
		}
		this.model = this.model.slice();
		this.onModelChange(this.model);
		this.onModelTouched();
	}

	updateNumSelected() {
		this.numSelected = this.model && this.model.filter(id => this.parents.indexOf(id) < 0).length || 0;
	}

	safelyTranslate(text: string): string {
		return (text && text !== '') ? this.translateService.instant(text) : '';
	}

	updateTitle() {
		if (this.numSelected === 0 || this.settings.fixedTitle) {
			this.title = this.safelyTranslate(this.texts.defaultTitle);
		} else if (this.settings.displayAllSelectedText && this.model.length === this.options.length) {
			this.title = this.safelyTranslate(this.texts.allSelected);
		} else if (this.settings.dynamicTitleMaxItems && this.settings.dynamicTitleMaxItems >= this.numSelected) {
			this.title = this.options
				.filter((option: IMultiSelectOption) =>
					this.model && this.model.indexOf(option.id) > -1
				)
				.map((option: IMultiSelectOption) => option.name)
				.join(', ');
		} else {
			this.title = this.numSelected
				+ ' ' + (this.numSelected === 1 ?
					this.safelyTranslate(this.texts.checked)
					: this.safelyTranslate(this.texts.checkedPlural));
		}
	}

	searchFilterApplied() {
		return this.settings.enableSearch && this.searchFilterText && this.searchFilterText.length > 0;
	}

	checkAll() {
		this.onMonthTextClicked.emit(MonthSetting.ALL_MONTHS);
		const checkedOptions = (!this.searchFilterApplied() ? this.options :
			(new MultiSelectSearchFilter()).transform(this.options, this.searchFilterText))
			.filter((option: IMultiSelectOption) => {
				if (this.model.indexOf(option.id) === -1) {
					this.onAdded.emit(option.id);
					return true;
				}
				return false;
			}).map((option: IMultiSelectOption) => option.id);
		this.model = this.model.concat(checkedOptions);
		this.onModelChange(this.model);

		this.onModelTouched();
	}

	getCurrentMonthYear() {
		const date = new Date();  // 2009-11-10
		let month = date.toLocaleString(this.localeService.getLocale(), { month: 'short' });
		if (month.endsWith('.')) {
			month = month.substring(0, month.length - 1);
		}
		const year = date.getFullYear();
		return `${month} ${year}`.toLocaleLowerCase();
	}

	/**
	 * Retruns the list of checked months based on a number of months to
	 * return and a number of months to skip.
	 */
	getCheckedMonthOptions(monthsToGet?: number, monthsToSkip?: number) {
		const currentMonthYear = this.getCurrentMonthYear();
		const currentYear = new Date().getFullYear();
		let currentCount = 0;
		monthsToSkip = monthsToSkip ? monthsToSkip : 0;
		let options = [];
		if (!this.searchFilterApplied()) {
			options = this.options;
		} else {
			options = (new MultiSelectSearchFilter()).transform(this.options, this.searchFilterText);
		}

		const latestMonth = monthsToGet === 1 && !monthsToSkip;
		const latestFullMonth = monthsToGet === 1 && monthsToSkip === 1;
		if (latestMonth) {
			const latestOption = options[0];
			this.onAdded.emit(latestOption.id);
			return latestOption.id;
		}
		else if (latestFullMonth) {
			let latestFullOption = options[0];
			if (latestFullOption.name.toLocaleLowerCase() === currentMonthYear) {
				latestFullOption = options[1];
			}
			this.onAdded.emit(latestFullOption.id);
			return latestFullOption.id;
		}
		else {
			const filteredOptions = options.filter((option: IMultiSelectOption) => {
				if (monthsToGet) {
					const yearMatches = option.name.toLocaleLowerCase() !== currentMonthYear;
					const canReturnMore = currentCount < (monthsToGet + (monthsToSkip ? monthsToSkip : 0));
					if (yearMatches && canReturnMore) {
						currentCount++;
						const skipThisOne = monthsToSkip && monthsToSkip > 0 && currentCount <= monthsToSkip;
						if (!skipThisOne) {
							this.onAdded.emit(option.id);
							return true;
						} else {return false;}
					}
				} else {
					if (option.name.endsWith(currentYear.toString())) {
						this.onAdded.emit(option.id);
						return true;
					}
				}
				return false;
			})
				.map((option: IMultiSelectOption) => option.id);
			return filteredOptions;
		}

	}

	checkMonths(monthCode?: string, monthsToGet?: number, monthsToSkip?: number) {
		if (monthCode) {
			this.onMonthTextClicked.emit(monthCode);
		}
		this.uncheckAll();
		const checkedOptions = this.getCheckedMonthOptions(monthsToGet, monthsToSkip);
		this.model = this.model.concat(checkedOptions);
		this.onModelChange(this.model);
		this.onModelTouched();
	}

	emitUncheckedMonths() {
		this.onMonthTextClicked.emit(MonthSetting.NO_MONTHS);
	}

	uncheckAll() {
		const unCheckedOptions = (!this.searchFilterApplied() ? this.model
			: (new MultiSelectSearchFilter()).transform(this.options, this.searchFilterText).map((option: IMultiSelectOption) => option.id)
		);
		this.model = this.model.filter((id: number) => {
			if (unCheckedOptions.indexOf(id) < 0) {
				return true;
			} else {
				this.onRemoved.emit(id);
				return false;
			}
		});
		this.onModelChange(this.model);
		this.onModelTouched();
	}

	preventCheckboxCheck(event: Event, option: IMultiSelectOption) {
		if (this.settings.selectionLimit && !this.settings.autoUnselect &&
			this.model.length >= this.settings.selectionLimit &&
			this.model.indexOf(option.id) === -1
		) {
			event.preventDefault();
		}
	}

	onMouseLeave($event) {

		if (this.settings.autoClose) {
			this.toggleDropdown();
		}
	}


	getPaginatedOptions = () => {
		this.currentCardPage++;
		const offset = (this.currentCardPage - 1) * this.cardPageSize;
		const nextCards = _.drop((!this.searchFilterApplied() ? this.options :
			(new MultiSelectSearchFilter()).transform(this.options, this.searchFilterText)), offset).slice(0, this.cardPageSize);
		if (nextCards && nextCards.length) {
			this.scrollableOptions = _.union(this.scrollableOptions, nextCards);
		}
	}

	searchFilterTextChange(event: Event) {
		this.initializeList();
	}

	initializeList() {
		this.currentCardPage = this.startingPage;
		this.scrollableOptions = [];
		this.getPaginatedOptions();
	}
}
