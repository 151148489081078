import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CoreModule } from './../core/core.module';
import { DownloadComponent, DownloadService } from './index';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'downloads',
                component: DownloadComponent,
                data: { title: 'main.tabs.downloads.page_titles.root' }
            }
        ]),
        CommonModule, CoreModule],
    providers: [DownloadService], //, DownloadsGuardService],
    declarations: [DownloadComponent] //, DownloadsGridComponent]
})
export class DownloadModule { }
