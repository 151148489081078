import {Component, Input, ElementRef } from '@angular/core';
import {RevenueChartHelperService, HighchartsConfiguration} from './../base';
import {FormatService } from './../../query';

import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

export enum LEGEND_TYPE {
    RATE = 'rate',
    CLIENT_RATE = 'client_rate',
    BENCHMARK_RATE = 'benchmark_rate',
    BOOK_RATE = 'book_rate'
}

@Component({
    selector: 'rdo-rate-types-quartiles-chart',
    templateUrl: './rate-types-quartiles-chart.component.html'
})

export class RateTypeQuartilesChartComponent {
    private I18N_PATH = 'main.tabs.summary.charts.rate_type_quartiles';
    private chart: any;
    hostElement: ElementRef;
    chartConfig: HighchartsConfiguration = new HighchartsConfiguration();
    formatService: FormatService;
    revUtilChartService: RevenueChartHelperService;
    data: Array<any>;

    // eslint-disable-next-line
    @Input('chart-data') set chartData(data: any) {
        if (data) {
            this.data = data;
            this.loadChart(data);
        }
    }

    constructor(
        element: ElementRef,
        formatService: FormatService,
        revUtilChartService: RevenueChartHelperService,
        private translateService: TranslateService
    ) {
        this.hostElement = element;
        this.formatService = formatService;
        this.revUtilChartService = revUtilChartService;
        this.setupChartTranslations();
    }

    private setupChartTranslations() {
		this.translateService.stream(this.I18N_PATH).subscribe((i18n) => {
			if (this.chart) {
				const newText = `${i18n[LEGEND_TYPE.RATE]}, ${this.formatService.selectedCurrencySymbol}`;
                this.chart.yAxis[0].update({ title: { text: newText } });
				this.chart.get(LEGEND_TYPE.CLIENT_RATE).update({ name: i18n[LEGEND_TYPE.CLIENT_RATE] });
                this.chart.get(LEGEND_TYPE.BENCHMARK_RATE).update({ name: i18n[LEGEND_TYPE.BENCHMARK_RATE] });
                this.chart.get(LEGEND_TYPE.BOOK_RATE).update({ name: i18n[LEGEND_TYPE.BOOK_RATE] });
			}
		});
	}

    private loadChart(chartData: Array<any>) {
	    let data = chartData;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const component = this;
        if (!_.some(chartData, o => o.BenchMarkDate)) {
            data = [];
        }

        const options = {
            chart: {
                 //TODO: make this dynamic
                 height: 200,
                 width: 1252
            },
            credits: {
                enabled: false
            },
            xAxis: {
                categories: _.map(data, o => {
                    return this.formatService.formatDate(o.BenchMarkDate);
                }),

            },
            yAxis: [
                {
                    id: LEGEND_TYPE.RATE,
                    title: {
						text: `${component.translateService.instant(component.I18N_PATH + '.' + LEGEND_TYPE.RATE)}, ${this.formatService.selectedCurrencySymbol}`
                    },
                    minorGridLineWidth: 0,
                    labels: {
                        formatter: function() {
							return this.formatService.selectedCurrencySymbol + this.axis.defaultLabelFormatter.call(this);
                        }
                    }
                }
            ],
            series: [
                {
                    id: LEGEND_TYPE.CLIENT_RATE,
                    yAxis: 0,
                    type: 'line',
                    name: component.translateService.instant(component.I18N_PATH + '.' + LEGEND_TYPE.CLIENT_RATE), // "Client Rate",
                    color: 'rgba(153, 168, 189,1)',
                    data: data.map(obj => {
                        return obj.RevenueRate;
                    })
                },
                {
                    id: LEGEND_TYPE.BENCHMARK_RATE,
                    yAxis: 0,
                    type: 'line',
                    name: component.translateService.instant(component.I18N_PATH + '.' + LEGEND_TYPE.BENCHMARK_RATE), // "Benchmark Rate",
                    color: 'rgba(75,75,75,1)',
                    data: data.map(obj => {
                        return obj.BenchmarkedRevenueRate;
                    })
                },
                {
                    id: LEGEND_TYPE.BOOK_RATE,
                    yAxis: 0,
                    type: 'line',
                    name: component.translateService.instant(component.I18N_PATH + '.' + LEGEND_TYPE.BOOK_RATE), // "Book Rate",
                    color: 'rgba(153, 168, 189,0.4)',
                    data: data.map(obj => {
                        return obj.BookRevenueRate;
                    })
                }
            ]
        };

        const config = new HighchartsConfiguration();
		config.options = options;
        config.onLoadCallback = (chart) => {
			this.chart = chart;
		};
        this.chartConfig = config;
    }
}

