import { initConsoleObservable, ConsoleApiName } from '@datadog/browser-core';
export function trackConsoleError(errorObservable) {
  var subscription = initConsoleObservable([ConsoleApiName.error]).subscribe(function (consoleLog) {
    return errorObservable.notify(consoleLog.error);
  });
  return {
    stop: function () {
      subscription.unsubscribe();
    }
  };
}
