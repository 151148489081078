import { Component, Input } from '@angular/core';
import { RentalGrowthPanelEventService } from './../../../core';
import { TranslateService } from '@ngx-translate/core';

/* eslint-disable @angular-eslint/component-selector */
@Component({
	selector: 'rental-growth-call-out',
	templateUrl: 'rental-growth-call-out.component.html',
	styleUrls: ['rental-growth-grid.component.scss']
})
export class RentalGrowthCallOutComponent {
	@Input() private marketUonRentChange: number;
	@Input() private marketPhysicalUtilChange: number;
	@Input() private clientShareChange: number;
	@Input() private clientShareUnitsChange: number;
	@Input() private monthlyRentalRatesChange: number;

	constructor(
		private translateService: TranslateService,
		private rentalGrowthPanelEventService: RentalGrowthPanelEventService
	) { }

	getVerbForValue(value: number): string {
		if (value > 0) {
			return this.translateService.instant('main.core.common.directions.up');
		}
		if (value < 0) {
			return this.translateService.instant('main.core.common.directions.down');
		}
		if (value === 0) {
			return this.translateService.instant('main.core.common.directions.flat');
		}
		return '';
	}

	public panelEvent = ($event, panelType: string): void => {
		this.rentalGrowthPanelEventService.notify({ eventType: $event.type, panelType: panelType });
	}

	public getUnitsText() {
		let text = '';
		if (this.clientShareUnitsChange && this.clientShareUnitsChange !== 0) {
			text = this.translateService.instant('main.core.common.counts.units');
		}
		return text;
	}

	public getPtsText() {
		let text = '';
		if (this.marketPhysicalUtilChange && this.marketPhysicalUtilChange !== 0) {
			text = this.translateService.instant('main.core.common.counts.pts');
		}
		return text;
	}
}
