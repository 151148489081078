import { NavCardsSetData } from '../core';

export class CardBase implements NavCardsSetData {
	RevenueBench: number;
	RevenueBenchmarked: number;
	RevenueBenchmarkedDifference: number;
	RevenueBook: number;
	RevenueBookDifference: number;
	RevenueTotal: number;
    TotalCount: number;

    public constructor(init?: Partial<CardBase>) {
		Object.assign(this, init);
	}
}