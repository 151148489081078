<rdo-breadcrumb></rdo-breadcrumb>
<rdo-overlay [enabled]="loading"></rdo-overlay>
<div class="container-fluid" id="geography">
	<div class="row">
		<div class="col-xs-12" id="rdo-grid">
			<rdo-grid-table-ng *ngIf="!useExpandedGrid" #grid [name]="gridName" [gridConfig]="gridConfig"
				[pagedData]="data" [frozenRows]="frozenRows" [sorting]="sorting" [(paging)]="paging"
				[scrollScale]="'largeScale'" (pageOnChange)="changePage($event)"
				(lazyLoadOnChange)="changeLazyLoad($event)" [totalCount]="totalCount" [frozenWidth]="frozenWidth"
				[showColumnSelector]="true" (downloadExcelClick)="exportExcel($event)"
				[useProductTypeColumnSelector]="true">
			</rdo-grid-table-ng>

			<rdo-grid-table-expanded-ng *ngIf="useExpandedGrid" #gridExpanded [name]="gridName" [dataKey]="dataKey"
				[gridConfig]="gridConfig" [rowExpansion]="gridConfig.rowExpansion" [pagedData]="data"
				[frozenRows]="frozenRows" [sorting]="sorting" [(paging)]="paging" [scrollScale]="'largeScale'"
				(pageOnChange)="changePage($event)" (lazyLoadOnChange)="changeLazyLoad($event)"
				[totalCount]="totalCount" [frozenWidth]="frozenWidth" [showColumnSelector]="true"
				(downloadExcelClick)="exportExcel($event)" [useProductTypeColumnSelector]="true">
			</rdo-grid-table-expanded-ng>
		</div>
	</div>
</div>