import { ClientProfileService } from '../../../core/client/client-profile.service';

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FilterProfileService } from '../filter-profile.service';
import { FilterProfileMap } from '../models/filter-profile-map';
import { TxAttributes } from '../../../models/transaction-attributes';

@Component({
  selector: 'rdo-save-profile-as-buttons',
  templateUrl: './save-profile-as.component.html',
  styleUrls: ['./save-profile-as.component.scss']
})
export class SaveProfileAsButtonsComponent  {
  public showSaveBtn: boolean = false;
  constructor(
    private filterProfileService: FilterProfileService,
    private clientProfileService: ClientProfileService,
  ) {
    this.filterProfileService.currentProfile.subscribe(profile => {
      this.showSaveBtn = !!FilterProfileMap.validateProfile(profile) &&  profile.profileId > 0;
    });
  }

  public onSaveFilterProfileAs() {
    if (!this.clientProfileService.txAttributes || !this.clientProfileService.txAttributes.IsTrial) {
      this.filterProfileService.showSaveAs.next(true);
    }
  }

  public onSaveFilterProfile() {
    if (!this.clientProfileService.txAttributes || !this.clientProfileService.txAttributes.IsTrial) {
      this.filterProfileService.applyFiltersFromComponent(()=> {
        this.filterProfileService.saveCurrentProfile();
      });
    }
  }
}
