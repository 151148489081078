import jwtDecode, * as jwt_decode from 'jwt-decode';

export class TokenInfo {

    public IdentityToken: string;
    public AccessToken: string;
    public AccessTokenDecoded: any;
    public ValidTo: Date;

    constructor(identitytoken: string, accesstoken: string) {

        this.IdentityToken = identitytoken;
        this.AccessToken = accesstoken;
        this.AccessTokenDecoded = this.getDecodedAccessToken(this.AccessToken);
        //this.ValidTo = this.AccessTokenDecoded.exp;
        const tomorrow = this.addDays(new Date(), 1);
        this.ValidTo = tomorrow;
    }

    public getClaimValue = (claimname: string): any => {

        let result = null;

        try {
            result = this.AccessTokenDecoded[claimname];
        } catch {
            result = '???';
        }

        return result;
    }

    private addDays(date: Date, days: number): Date {

        date.setDate(date.getDate() + days);

        return date;
    }

    private getDecodedAccessToken = (token: string): any => {

        let result = null;

        try {
            //result = JSON.parse(jwt_decode(token));
            result = jwtDecode(token);
        } catch (e) {
            result = null;
        }

        return result;
    }
}
