﻿<div *ngIf="gridRows" id="rdo-grid">
	<div class="ui-table-missing-scrollable-header"></div>
	<p-table
        #rdoExpandedGrid
        [value]="gridRows"
        [dataKey]="dataKey"
        [styleClass]="'rdo-ui-table'"
        [rowHover]="false"
        [sortMode]="'single'"
        [sortField]="sorting.sortField"
        [sortOrder]="sorting.sortOrder"
        [scrollable]="true"
        [scrollHeight]="scrollHeight"
        [frozenValue]="frozenGridRows"
        [frozenWidth]="frozenWidth"
        [columns]="scrollableColumns"
        [frozenColumns]="frozenColumns"
        [customSort]="true"
        [lazy]="true"
        (onLazyLoad)="onLazyLoad($event)"
        (onRowExpand)="onRowExpand && onRowExpand($event.data)"
        (onRowCollapse)="onRowCollapse($event.data)"
        (onSort)="changeSort($event)"
    >
		<ng-template pTemplate="caption">
			<div>
				<rdo-column-selector
                        *ngIf="showColumnSelector && !useProductTypeColumnSelector && !useConfiguredColumnSelector"
                        [size]="'md'"
                        [gridConfig]="columnSelectorConfig"
                        (visibilityChanged)="saveGridLayout()"
                        (downloadExcelClick)="downloadExcelClick.next(gridConfig)">
				</rdo-column-selector>

				<rdo-column-selector-product-type *ngIf="showColumnSelector && useProductTypeColumnSelector"
					[size]="'md'" [gridConfig]="columnSelectorConfig" (visibilityChanged)="saveGridLayout()"
					(downloadExcelClick)="downloadExcelClick.next(gridConfig)">
				</rdo-column-selector-product-type>

                <rdo-configured-column-selector
                        *ngIf="useConfiguredColumnSelector"
                        [size]="'md'"
                        [gridConfig]="columnSelectorConfig"
                        (visibilityChanged)="saveGridLayout()"
                        (downloadExcelClick)="downloadExcelClick.next(gridConfig)"
                >
                </rdo-configured-column-selector>
			</div>
		</ng-template>

		<ng-template pTemplate="colgroup" let-columns>
			<ng-container *ngFor="let group of columns">
				<ng-container *ngFor="let column of group.columns">
					<colgroup>
						<col [style.width]="getWidthStyle(column)" />
					</colgroup>
				</ng-container>
			</ng-container>
		</ng-template>

        <!-- top header row -->
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th *ngFor="let group of columns"
                    [style.width]="getColGroupWidth(group)"
					[attr.colspan]="group.columns.length"
                    [class.expandable]="group.expandable"
					[class.group-end]="isGroupEnd(group)"
                    id="{{group.title | rdoPathToId}}"
					[ngStyle]="group.headerStyle"
                >
					<div data-toggle="tooltip" tooltip="{{getGroupTooltip(group)}}"
						[attr.title]="getGroupTooltip(group) ? null : translate(group.title)">{{translate(group.title)}}
					</div>
                    <!-- From non expandable... -->
                    <!-- *ngIf="(!group.forceCanClose !== false && group.locked || group.forceCanClose) && (group.text || group.columns.length > 1)" -->
					<a *ngIf="!group.locked && group.columns.length > 1" (click)="toggleColumnVisibility(group)"
						title="{{'main.core.tables.common.hide_column_group' | translate}}">
						<span class="fa fa-lg fa-times-circle pull-right"></span>
					</a>
				</th>
			</tr>
            <!-- second header row -->
			<tr style="height: 38px;">
				<ng-container *ngFor="let group of columns">
                    <ng-container  *ngFor="let column of group.columns; let columnIndex = index">
                        <th
                            id="{{column.sortColumn}}"
                            [pSortableColumn]="filterSortableColumns(column)"
                            [ngStyle]="column.headerStyle"
                            [style.width.px]="column.width"
                            [class.group-end]="isHeaderGroupEnd(group, column)"
                            class="ellipsed-th allow-overflow make-relative"
                        >
                            <rdo-movable-column-arrows
                                [column]="column"
                                [gridConfig]="gridConfig"
                                [onMove]="moveColumn"
                            >
                                <!-- close button -->
                                <a *ngIf="column.isClosable"
                                   (click)="toggleColumnVisibility(column)"
                                   title="{{'main.core.tables.common.hide_column_group' | translate}}"
                                   class="table-close-button"
                                   style="position: absolute; right: -2px; top: -5px"
                                >
                                    <span class="fa fa-lg fa-times-circle pull-right"></span>
                                </a>
                                <!-- end close button -->
                                <div class="ellipsed-div" [class.header-moveable]="group.isMovable">
                                    <div tooltip="{{getColumnTooltip(column)}}"
                                        [attr.title]="getColumnTooltip(column) ? null : translate(column.title)"
                                        [ngStyle]="{'line-height': !allowDoubleHeader ? '2.9em' : 'unset' }">{{(column |
                                        rdoEllipsis:column.renderedWidth).processedTitle}}</div>
                                    <p-sortIcon [field]="column.sortColumn"></p-sortIcon>
                                </div>
                            </rdo-movable-column-arrows>
                        </th>
                    </ng-container>
				</ng-container>
			</tr>
		</ng-template>

		<ng-template pTemplate="frozenrows" let-rowData let-columns="columns">
			<tr class="frozen-row">
				<ng-container *ngFor="let group of columns">
					<td *ngFor="let column of group.columns"
						[ngClass]="rowData[column.sortColumn].class"
                        [ngStyle]="rowData[column.sortColumn].style"
						[style.width]="rowData[column.sortColumn].width"
                        [class]="getHeaderClass(column)"
						[class.group-end]="isCellGroupEnd(group, column)"
                        id="header-{{column.sortColumn}}-cell"
						title="{{handleValue(rowData[column.sortColumn].value)}}"
                    >
						<i *ngIf="!rowData[column.sortColumn].href && !rowData[column.sortColumn].linkDsl && isBool(rowData[column.sortColumn].value) && rowData[column.sortColumn].value"
							class="fa fa-check"></i>
						<a *ngIf="rowData[column.sortColumn].linkDsl"
							(click)="clickCellLink(rowData[column.sortColumn].config, rowData.raw)"
							[routerLink]="rowData[column.sortColumn].linkDsl"
                        >{{rowData[column.sortColumn].value |translate}}</a>
						<a *ngIf="rowData[column.sortColumn].href && !rowData[column.sortColumn].linkDsl"
                           (click)="clickCellLink(rowData[column.sortColumn].config, rowData.raw)"
                           href="{{rowData[column.sortColumn].href}}"
                        >{{rowData[column.sortColumn].value | translate}}</a>
						<span id="header-{{column.sortColumn}}-cell-text"
                              *ngIf="!rowData[column.sortColumn].href && !rowData[column.sortColumn].linkDsl && !isBool(rowData[column.sortColumn].value)"
                        >{{translate(rowData[column.sortColumn].value)}}</span>
					</td>
				</ng-container>
			</tr>
		</ng-template>
        <!-- render the rows in the frozen part of grid -->
		<ng-template pTemplate="frozenbody" let-rowData let-rowIndex="rowIndex" let-expanded="expanded" let-columns="columns">
			<tr [class.clickable-row]="!disableExpandability && rowData.isExpandable"
                (mouseenter)="onMouseEnter(rowData, false, 'frozen')"
                (mouseleave)="onMouseLeave(rowData, false, 'frozen')"
				[pRowToggler]="rowData" [class.row-hover]="rowData.hover">
				<ng-container *ngFor="let group of columns">
					<ng-container *ngFor="let column of group.columns; let columnIndex = index">
						<td *ngIf="columnIndex === 0"
                            id="detail-{{column.sortColumn}}-{{rowIndex}}"
							[ngClass]="rowData[column.sortColumn].class"
                            [ngStyle]="rowData[column.sortColumn].style"
							[style.width]="rowData[column.sortColumn].width"
							[class.group-end]="isCellGroupEnd(group, column)"
							title="{{handleValue(rowData[column.sortColumn].value)}}">
                            <!-- TODO: Hide the carat and disable the click when the column shouldn't be expandable -->
							<i *ngIf="!rowData.childLoading && !disableExpandability && rowData.isExpandable"
								[ngClass]="rowData.expanded ? 'fa fa-fw fa-angle-down' : 'fa fa-fw fa-angle-right'">
                            </i>
                            <span *ngIf="!rowData.childLoading && !disableExpandability && !rowData.isExpandable"
                               [ngClass]="'non-expandable-spacer'">
                            </span>
							<i *ngIf="rowData.childLoading" class="fa fa-fw fa-circle-o-notch fa-spin"></i>
							<span *ngIf="!rowData[column.sortColumn].href">{{rowData[column.sortColumn].value}}</span>
                            <a *ngIf="rowData[column.sortColumn].href"
                               (click)="clickCellLink(rowData[column.sortColumn].config, rowData.raw)"
                               href="{{rowData[column.sortColumn].href}}">{{rowData[column.sortColumn].value}}</a>
						</td>
						<td *ngIf="columnIndex !== 0"
                            id="detail-{{column.sortColumn}}-{{rowIndex}}"
							[ngClass]="rowData[column.sortColumn].class"
                            [ngStyle]="rowData[column.sortColumn].style"
							[style.width]="rowData[column.sortColumn].width"
							[class.group-end]="isCellGroupEnd(group, column)"
							title="{{handleValue(rowData[column.sortColumn].value)}}"
                        >
                            <i *ngIf="!rowData[column.sortColumn].href && !rowData[column.sortColumn].linkDsl && isBool(rowData[column.sortColumn].value) && rowData[column.sortColumn].value"
                               class="fa fa-check"></i>
							<a *ngIf="rowData[column.sortColumn].linkDsl"
								(click)="clickCellLink(rowData[column.sortColumn].config, rowData.raw)"
								[routerLink]="rowData[column.sortColumn].linkDsl">{{rowData[column.sortColumn].value}}</a>
							<a *ngIf="rowData[column.sortColumn].href && !rowData[column.sortColumn].linkDsl"
								(click)="clickCellLink(rowData[column.sortColumn].config, rowData.raw)"
								href="{{rowData[column.sortColumn].href}}">{{rowData[column.sortColumn].value}}</a>
							<span
								*ngIf="!rowData[column.sortColumn].href && !rowData[column.sortColumn].linkDsl && !isBool(rowData[column.sortColumn].value)">{{rowData[column.sortColumn].value}}</span>
						</td>
					</ng-container>
				</ng-container>
			</tr>
		</ng-template>

		<ng-template pTemplate="body" let-rowData let-index="rowIndex" let-columns="columns" let-rowIndex="rowIndex">
			<tr [class.clickable-row]="!disableExpandability && rowData.isExpandable"
                (mouseenter)="onMouseEnter(rowData, false,  'body')"
                (mouseleave)="onMouseLeave(rowData, false, 'body')"
				[pRowToggler]="rowData" [class.row-hover]="rowData.hover">
				<ng-container *ngFor="let group of columns">
					<td id="detail-{{column.sortColumn}}-{{index}}"
                        *ngFor="let column of group.columns"
						[ngClass]="rowData[column.sortColumn].class"
                        [ngStyle]="rowData[column.sortColumn].style"
						[style.width]="rowData[column.sortColumn].width"
						[class.group-end]="isCellGroupEnd(group, column)"
						title="{{handleValue(rowData[column.sortColumn].value)}}"
                    >
						<i *ngIf="!rowData[column.sortColumn].href && !rowData[column.sortColumn].linkDsl && isBool(rowData[column.sortColumn].value) && rowData[column.sortColumn].value"
							class="fa fa-check"></i>
						<a *ngIf="rowData[column.sortColumn].linkDsl"
							(click)="clickCellLink(rowData[column.sortColumn].config, rowData.raw)"
							[routerLink]="rowData[column.sortColumn].linkDsl">{{translate(rowData[column.sortColumn].value)}}</a>
						<a *ngIf="rowData[column.sortColumn].href && !rowData[column.sortColumn].linkDsl"
							(click)="clickCellLink(rowData[column.sortColumn].config, rowData.raw)"
							href="{{rowData[column.sortColumn].href}}">{{translate(rowData[column.sortColumn].value)}}</a>
						<span
							*ngIf="!rowData[column.sortColumn].href && !rowData[column.sortColumn].linkDsl && !isBool(rowData[column.sortColumn].value)">{{translate(rowData[column.sortColumn].value)}}</span>
					</td>
				</ng-container>
			</tr>
		</ng-template>

		<ng-template pTemplate="rowexpansion" let-rowData let-columns="columns" let-rowData let-index="rowIndex" let-rowIndex="rowIndex">
			<tr *ngFor="let childRowData of rowData.childRows" class="expansion-row"
				(mouseenter)="onMouseEnter(childRowData, true, 'rowexpansion')" (mouseleave)="onMouseLeave(childRowData, true, 'rowexpansion')"
				[class.row-hover]="childRowData.hover">
				<ng-container *ngFor="let group of columns">
					<td *ngFor="let column of group.columns"
                        id="detail-{{column.sortColumn}}-{{rowIndex}}"
                        [ngClass]="childRowData[column.sortColumn].class"
						[ngStyle]="childRowData[column.sortColumn].style"
						[style.width]="childRowData[column.sortColumn].width"
						[class.group-end]="isCellGroupEnd(group, column)"
						title="{{handleValue(childRowData[column.sortColumn].value)}}">
						<i *ngIf="!childRowData[column.sortColumn].href && !childRowData[column.sortColumn].linkDsl && isBool(childRowData[column.sortColumn].value) && childRowData[column.sortColumn].value"
							class="fa fa-check"></i>
						<a *ngIf="childRowData[column.sortColumn].linkDsl"
							(click)="clickCellLink(childRowData[column.sortColumn].config, childRowData.raw, rowData.raw)"
							[routerLink]="childRowData[column.sortColumn].linkDsl">{{translate(childRowData[column.sortColumn].value)}}</a>
						<a *ngIf="childRowData[column.sortColumn].href && !childRowData[column.sortColumn].linkDsl"
							(click)="clickCellLink(childRowData[column.sortColumn].config, childRowData.raw, rowData.raw)"
							href="{{childRowData[column.sortColumn].href}}">{{translate(childRowData[column.sortColumn].value)}}</a>
						<span
							*ngIf="!childRowData[column.sortColumn].href && !childRowData[column.sortColumn].linkDsl && !isBool(childRowData[column.sortColumn].value)">{{translate(childRowData[column.sortColumn].value)}}</span>
					</td>
				</ng-container>
			</tr>
		</ng-template>

	</p-table>

	<p-paginator #gridPaginator id="gridPaginator" styleClass="rdo-paginator" [(first)]="paging.first"
		[rowsPerPageOptions]="[10,20,50]" [pageLinkSize]="10" [rows]="paging.pageSize" [totalRecords]="totalCount"
		(onPageChange)="onPage($event)">
	</p-paginator>

</div>
