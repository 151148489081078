import {Component, Input, ViewChild} from '@angular/core';

@Component({
    selector: 'rdo-overlay',
    templateUrl: 'overlay.component.html',
    styleUrls: ['overlay.component.scss']
})
export class OverlayComponent {
    @Input()
    public enabled: boolean;
    @Input()
    public imgClass: string;
}
