import { Observable } from '../Observable';
import { from } from './from';
import { isArray } from '../util/isArray';
import { EMPTY } from './empty';
export function onErrorResumeNext(...sources) {
  if (sources.length === 0) {
    return EMPTY;
  }
  const [first, ...remainder] = sources;
  if (sources.length === 1 && isArray(first)) {
    return onErrorResumeNext(...first);
  }
  return new Observable(subscriber => {
    const subNext = () => subscriber.add(onErrorResumeNext(...remainder).subscribe(subscriber));
    return from(first).subscribe({
      next(value) {
        subscriber.next(value);
      },
      error: subNext,
      complete: subNext
    });
  });
}
