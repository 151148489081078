import { Component, Input, Output, EventEmitter } from '@angular/core';

import { RentalAssetCard } from './../../../../app/models';

@Component({
	selector: 'rdo-nav-card-asset',
	templateUrl: 'nav-card-asset.component.html'
})
export class NavCardAssetComponent {
	// eslint-disable-next-line  @angular-eslint/no-input-rename
	@Input('data')
	cardData: RentalAssetCard;

	@Input()
	selected: boolean;

	@Input()
	icon: string;

	@Output()
	selectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	public select() {
		if (!this.selected) {
			this.selected = true;
			this.selectedChange.next(this.selected);
		}
	}
}
