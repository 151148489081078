export class ScheduleUserPermission {
    public UserId: number;
    public FirstName: string;
    public LastName: string;
    public Email: string;
    public UserName: string;
}

export class ScheduleNotification {
    public ScheduleNotificationId: number;
    public ScheduledExportId: number;
    public CustomMessage: string;
    public FirestorePath: string;
    public CreatedBy: string;
    public CreatedDate: Date;
    public LastModifiedBy: Date;
    public LastModifiedDate: Date;
    public notificationUserIds: Array<number>;
}

export class ExportSchedule {
  ScheduledExportId: number;
  GridType: string;
  GridFirestoreId: string;
  StartDate: Date;
  Frequency: string;
  NextRunDate: Date;
  CreatedBy: string;
  CreatedDate: Date;
  LastModifiedBy: string;
  LastModifiedDate: Date;
  ScheduleUserPermissions: Array<ScheduleUserPermission>;
  ScheduleNotification: ScheduleNotification;
  constructor() {
    this.ScheduleUserPermissions = [];
    this.ScheduleNotification = new ScheduleNotification();
    this.ScheduleNotification.notificationUserIds = [];
  }
}
