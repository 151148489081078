import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CoreModule } from './../core/core.module';
import { TrialGuardService } from '../core';
import {
	CustomerBaseComponent,
	CustomerComponent,
	CustomersComponent,
	CustomerSalesRepsComponent,
	CustomerDashboardComponent,
	CustomerProductTypesComponent,
	CustomerService,
	SelectedCustomerService,
	CustomerSalesRepService
} from '../customer';
import { ProductTypeTransactionsComponent } from '../equipment';

const root = 'customers';
const customerRoutes: Routes = [
	{
		path: root,
		component: CustomersComponent,
		data: { title: 'main.tabs.customers.page_titles.root' },
		canActivate: [TrialGuardService]
	},
	{
		path: root + '/:customer',
		component: CustomerComponent,
		canActivate: [TrialGuardService],
		children: [
			{
				path: '',
				component: CustomerDashboardComponent,
				data: {
					name: 'dashboard',
					title: 'main.tabs.customers.page_titles.dashboard' }
			},
			{	path: 'sales-reps',
				component: CustomerSalesRepsComponent,
				data: {
					name: 'sales-reps',
					title: 'main.tabs.customers.page_titles.sales_reps' }
			},
			{	path: 'product-types',
				component: CustomerProductTypesComponent,
				data: {
					name: 'product-types',
					title: 'main.tabs.customers.page_titles.product_types' }
			},
			{	path: 'transactions',
				component: ProductTypeTransactionsComponent,
				data: {
					title: 'main.tabs.customers.page_titles.transactions' }
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(customerRoutes), CommonModule, CoreModule, InfiniteScrollModule],
	providers: [CustomerService, SelectedCustomerService, CustomerSalesRepService, TrialGuardService],
	declarations: [CustomerBaseComponent,
		CustomerComponent,
		CustomersComponent,
		CustomerDashboardComponent,
		CustomerSalesRepsComponent,
		CustomerProductTypesComponent]
})
export class CustomerModule { }
