import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterLink} from '@angular/router';
import { RouterModule } from '@angular/router';

import { MaintenanceModeComponent } from './maintenance-mode.component';
import { MetricsHttpService} from '../core';
import { CoreModule } from '../core/core.module';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { MaintenanceModeService } from './maintenance-mode.service';

@NgModule({
  declarations: [
    MaintenanceModeComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    RouterModule,
    RouterLink,
  ],
  providers: [
    MetricsHttpService,
    AuthenticationService
  ],
  exports: [
    MaintenanceModeService,
    MaintenanceModeComponent,
  ]
})
export class MaintenanceModeModule { }
