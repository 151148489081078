import {Injectable, EventEmitter} from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { of } from 'rxjs/observable/of';

import { CustomerCard } from '../models';

@Injectable()
export class SelectedCustomerService {
    public customerChange: EventEmitter<CustomerCard> = new EventEmitter<CustomerCard>();
    private selectedCustomer: CustomerCard;
    private subject: Subject<CustomerCard> = new Subject<CustomerCard>();

    public get Customer(): Observable<CustomerCard> {
        if (this.selectedCustomer) {
            return of(this.selectedCustomer);
        }
        return this.subject;
    }

    notify(customer: CustomerCard) {
        if (!customer) {
            this.selectedCustomer = null;
            return;
        }

        if (this.selectedCustomer && this.selectedCustomer.CustomerId === customer.CustomerId) {
            return;
        }

        if (!customer) {
            return;
        }

        this.selectedCustomer = customer;
        if (this.subject) {
            this.subject.next(customer);
        }

        this.customerChange.next(customer);
    }

    clear() {
        this.selectedCustomer = null;
    }
}
