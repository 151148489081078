import { ManagedPermissions } from './managed-permissions';
import { StepActionType } from './step-action-type';

export class UserGuideStep {
    public intro: string;
    public title: string;
    public element?: string;
    public position?: string;
    public nextguide?: string;
    public stepsCount?: number;
    public actionCode?: string;
    public type: StepActionType;
    public waitForElement: string;
    public undoActionCode?: string;
    public serviceFunction?: string;
    public serviceFunctionParameters?: Array<any>;
    public requiresPermission?: ManagedPermissions;
}
