﻿<rdo-overlay [enabled]="loading"></rdo-overlay>

<div class="row">
	<rdo-dashboard-card-set [data]="dashboardCardData" [show-quartiles]="'quartiles'"
		(onNavigateToBqTransactions)="updateBreadcrumbs([{
			title:'main.core.common.counts.transactions.breadcrumb_titles.below_bottom_quartile',
			class:'active'}])"
		(onNavigateToTqTransactions)="updateBreadcrumbs([{
			title:'main.core.common.counts.transactions.breadcrumb_titles.above_top_quartile',
			class:'active'}])"
		(onCardClicked)="onCardClicked($event)">
	</rdo-dashboard-card-set>
</div>
<div class="row customer-dashboard" id="full-chart">
	<div class="col-xs-12">
		<div class="no-border">
			<div>
				<ul class="nav nav-tabs" id="summary-nav-chart">
					<li class="dropdown active chart-drop-down-container">
						<a href="#" class="dropdown-toggle chart-title" id="summary-chart-title" data-toggle="dropdown" style="cursor: pointer;">
							{{getSelectedChartName()}}<span class="caret"></span>
						</a>
						<ul class="dropdown-menu" role="menu">
							<li class="dropdown-submenu">
								<a tabindex="-1" (click)="$event.stopPropagation()"
								id="client-revenue-options">{{'main.tabs.summary.charts.client_revenue.text' | translate}}</a>
								<ul class="dropdown-menu">
									<li>
										<a id="revenue-book-chart-link" [class.active]="chartSwitcher.isRevenueBookChart()"
										(click)="chartSwitcher.selectRevenueBookChart();">{{'main.tabs.summary.charts.client_revenue.vs_books.text' | translate}}</a>
									</li>
									<li>
										<a id="revenue-benchmark-chart-link" [class.active]="chartSwitcher.isTotalRevenueVsBenchmarkChart()"
										(click)="chartSwitcher.selectTotalRevenueVsBenchmarkChart();">{{'main.tabs.summary.charts.client_revenue.vs_bench_quartiles.text' | translate}}</a>
									</li>
								</ul>
							</li>
							<li>
								<a id="rate-bench-chart-link" [class.active]="chartSwitcher.isRevenueBenchChart()"
								(click)="chartSwitcher.selectRevenueBenchChart();">{{'main.tabs.summary.charts.client_rate_vs_bench.text' | translate}}</a>
							</li>
						</ul>
					</li>
					<li id="info-icon" class="hover-only">
						<span style="color: #2d6ca2;" title="{{'main.tabs.summary.charts.tooltip' | translate}}">
							<i class="fa fa-fw fa-info-circle"></i>
						</span>
					</li>
				</ul>
			</div>
			<div class="tab-content chart" id="summary-charts">
				<revenue-vs-book-chart *ngIf="chartSwitcher.isRevenueBookChart()" class="active tab-pane revenue-util-charts" [chart-data]="chartData.data"></revenue-vs-book-chart>
				<revenue-vs-benchmark-chart *ngIf="chartSwitcher.isTotalRevenueVsBenchmarkChart()" class="active tab-pane revenue-util-charts" [chart-data]="chartData.data" [active-slices]="true" [dashed-line-offset]="clientAttributes.DashedLineOffset" (slice-click)="onSliceClick($event)"></revenue-vs-benchmark-chart>
				<rdo-revenue-by-month-chart *ngIf="chartSwitcher.isRevenueBenchChart()" class="active tab-pane revenue-util-charts" [chart-data]="chartData.data"
											[comparisonMode]="comparisonMode.Benchmark" [dashed-line-offset]="clientAttributes.DashedLineOffset"></rdo-revenue-by-month-chart>

			</div>
			<div *ngIf="chartData.loading && !loading" class="chart-loader">
				<img src="/assets/images/loader-sm.gif" />
			</div>
		</div>
	</div>
</div>
<div class="row">
	<div class="col-xs-4">
		<rdo-top-list [title]="'main.core.common.counts.sales_reps.plural'" [items]="salesReps.data.Items" [loading]="salesReps.loading" [nameSelector]="'SalesRepName'"
					  [footerLinkTitle]="'main.core.common.counts.sales_reps.view_all'" [footerLinkDsl]="['sales-reps']" [comparisonMode]="mode">
		</rdo-top-list>
	</div>
	<div class="col-xs-4">
		<rdo-top-list [title]="'main.core.common.counts.product_types.plural'" [items]="productTypes.data.Items" [loading]="productTypes.loading" [nameSelector]="'Description'"
					  [footerLinkTitle]="'main.core.common.counts.product_types.view_all'" [footerLinkDsl]="['product-types']" [comparisonMode]="mode">
		</rdo-top-list>
	</div>
	<div class="col-xs-4">
		<rdo-top-list [title]="'main.core.common.counts.rate_types.plural'" [items]="rateTypes.data.Items" [loading]="rateTypes.loading" [nameSelector]="'type'"
					  [comparisonMode]="mode">
		</rdo-top-list>
	</div>
</div>