//import { ClientInfo } from './client-info';
import { SelectableClient } from './selectableclient';

/// this class holds the /identity/usersme api result
export class UserInfo {
	public Email: string;
    public FullName: string;
    public HasClientAccessToDownloads: boolean;
    public HasClientAccessToExportData: boolean;
    public HasClientChangeLogAccess: boolean;
    public HasRDOCustomGridsEditor: boolean;
    public HasClientCustomGridsEditor: boolean;
    public HasCustomGridsData: boolean;
    public AvailableCustomGridSources: Array<string>;
    public HasGeographyFullAccess: boolean;
    public Id: number;
    public IsRouseTestUserImpersonator: boolean;
    public Name: string;
    public HasClientSelection: boolean;
    public SelectableClients: Array<SelectableClient>;
    public lang: string;
    public HasSalesRepRole: boolean;
    public IsOrImpersonatesCGEditor: boolean;
    public ImpersonatedOrCurrentUserEmail: string;
    public ImpersonatedOrCurrentUserFullName: string;

    constructor(userinfo: any) {
        this.Email = userinfo.Email;
        this.FullName = userinfo.FullName;
        this.HasClientChangeLogAccess = userinfo.HasClientChangeLogAccess;
        this.Id = userinfo.Id;
        this.Name = userinfo.Name;
        this.HasClientSelection = userinfo.HasClientSelection;
        this.SelectableClients = userinfo.SelectableClients;
        this.lang = userinfo.lang;
        this.HasRDOCustomGridsEditor = userinfo.RDOCustomGridsEditor;
        this.HasClientCustomGridsEditor = userinfo.RDOClientCustomGridsEditor;
        this.HasGeographyFullAccess = userinfo.HasGeographyFullAccess;
        this.HasSalesRepRole = userinfo.HasSalesRepRole;
        this.HasCustomGridsData = userinfo.HasCustomGridsData;
        this.AvailableCustomGridSources = userinfo.AvailableCustomGridSources;
        this.IsRouseTestUserImpersonator = userinfo.IsRouseTestUserImpersonator;
        this.IsOrImpersonatesCGEditor = userinfo.IsOrImpersonatesCGEditor;
        this.ImpersonatedOrCurrentUserEmail = userinfo.ImpersonatedOrCurrentUserEmail;
        this.ImpersonatedOrCurrentUserFullName = userinfo.ImpersonatedOrCurrentUserFullName;
    }
}
