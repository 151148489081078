"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var identity_1 = require("./identity");
function pipe() {
  var fns = [];
  for (var _i = 0; _i < arguments.length; _i++) {
    fns[_i] = arguments[_i];
  }
  return pipeFromArray(fns);
}
exports.pipe = pipe;
function pipeFromArray(fns) {
  if (fns.length === 0) {
    return identity_1.identity;
  }
  if (fns.length === 1) {
    return fns[0];
  }
  return function piped(input) {
    return fns.reduce(function (prev, fn) {
      return fn(prev);
    }, input);
  };
}
exports.pipeFromArray = pipeFromArray;
