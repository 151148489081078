import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'rdo-three-state-checkbox',
    styleUrls: ['three-state-checkbox.component.scss'],
    templateUrl: 'three-state-checkbox.component.html'
})

export class ThreeStateCheckboxComponent {
    @Input() private excludeTransactionType: boolean;
    @Output() excludeTransactionTypeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Input() falseLabel: string;
	@Input() trueLabel: string;
	@Input() nullLabel: string;

	@Input() falseTooltip: string;
	@Input() trueTooltip: string;
	@Input() nullTooltip: string;

     toggleVisible(val: boolean) {
         this.excludeTransactionType = val;
         this.excludeTransactionTypeChange.emit(this.excludeTransactionType);
     }
}
