﻿<div class="panel panel-default rdo-card invisible" [class.active]="selected" [class.rdo-card-default]="!selected" [class.rdo-card-highlight]="selected"
     title="{{(cardData[titleSelector] || defaultTitle) | translate}} " [class.invisible]="hidden" (click)="select()">

  <div class="panel-heading">
    <div class="panel-title">
      <i class="icon-left fa" [ngClass]="icon"></i>{{(cardData[titleSelector] || defaultTitle) | translate}}
    </div>
  </div>
  <div class="panel-body">
    <div class="rdo-card-panel-content">
      <div>
        <ng-template [ngIf]="isBenchmarkMode()">
          <div class="panel-title" id="{{'sales_reps.compared_revenue.' + baseId | rdoPathToId}}">
            {{'main.tabs.sales_reps.compared_revenue' | translate}}
          </div>
          <div class="rdo-card-metric">
            {{cardData.RevenueBenchmarked | rdoCurrency:true:translateService.currentLang}} / <span [ngClass]="{'text-danger' : cardData.RevenueBenchmarkedDifference < 0}">
              {{cardData.RevenueBenchmarkedDifference | rdoCurrency:true:translateService.currentLang}}
            </span>
          </div>
        </ng-template>
        <ng-template [ngIf]="!isBenchmarkMode()">
          <div class="panel-title" id="{{'sales_reps.total_revenue.' + baseId | rdoPathToId}}">
            {{'main.tabs.sales_reps.total_revenue' | translate}}
          </div>
          <div class="rdo-card-metric">
            {{cardData.RevenueTotal | rdoCurrency:true:translateService.currentLang}} / <span [ngClass]="{'text-danger' : cardData.RevenueBookDifference < 0}">
              {{cardData.RevenueBookDifference | rdoCurrency:true:translateService.currentLang}}
            </span>
          </div>
        </ng-template>
      </div>
      <div class="rdo-card-right-group">
        <div class="panel-title" id="{{'sales_reps.book_discount.' + baseId | rdoPathToId}}">
          {{'main.tabs.sales_reps.book_discount' | translate}}
        </div>
        <div class="rdo-card-metric">
          <i class="fa fa-book"></i>&nbsp; <span [ngClass]="{'text-danger':getBookDiscount(cardData)  < 0}">{{ getBookDiscount(cardData) | percent:'1.1-1'}}</span>
        </div>
      </div>
    </div>
  </div>
</div>