<div class="panel panel-default rdo-card" [class.active]="selected" [class.rdo-card-default]="!selected" [class.rdo-card-highlight]="selected"
	 title="{{cardData.EquipmentId}}" (click)="select()">
	<div class="panel-heading">
		<div class="panel-title">
			<i class="icon-left fa" [ngClass]="icon"></i>{{cardData.ModelYear}} {{cardData.Make}} {{cardData.Model}}
		</div>
	</div>
	<div class="panel-body">
		<div class="row">
			<div class="col-xs-12">
				<div class="rdo-card-metric">#{{cardData.EquipmentId}}</div>
				<div class="rdo-card-metric"><span [ngClass]="{'text-success': (!cardData.IsSold && !cardData.OnRent), 'text-danger': (!cardData.IsSold && cardData.OnRent)}">{{cardData.RentalStatus}}</span></div>
				<div class="rdo-card-metric"><i class="fa fa-map-marker icon-left"></i>{{cardData.BranchCity}}, {{cardData.BranchState}}</div>
			</div>
		</div>
	</div>
</div>