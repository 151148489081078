import { addEventListeners, timeStampNow } from '@datadog/browser-core';
import { RecordType } from '../../../types';
export function trackFocus(configuration, focusCb) {
  return addEventListeners(configuration, window, ["focus" /* DOM_EVENT.FOCUS */, "blur" /* DOM_EVENT.BLUR */], function () {
    focusCb({
      data: {
        has_focus: document.hasFocus()
      },
      type: RecordType.Focus,
      timestamp: timeStampNow()
    });
  });
}
