import { Component, ElementRef, Input, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { FilterProfileService } from '../filter-profile.service';
import { TranslateService } from '@ngx-translate/core';
import { FilterProfile } from '../models/filter-profile';
import { filter } from 'lodash';
import { ClientProfileService } from '../../../core/client/client-profile.service';
import { TxAttributes } from '../../../models/transaction-attributes';


@Component({
  selector: 'rdo-filter-profile-list',
  templateUrl: './filter-profile-list.component.html',
  styleUrls: ['./filter-profile-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class FilterProfileListComponent {
  @ViewChild('filterProfileMenu') menu: ElementRef;
  @ViewChild('filterProfileToggleBtn') toggleButton: ElementRef;
  public openProfileList = false;
  public displayManagerBtn = false;
  protected currentProfile: FilterProfile = undefined;
  protected profilesList: FilterProfile[] = []
  protected listHeight: string = '289px';
  constructor(
    private renderer: Renderer2,
    private filterService: FilterProfileService,
    protected translateService: TranslateService,
    private clientProfileService: ClientProfileService
  ) {
    this.filterService.profilesList.subscribe(value => {
      this.profilesList = JSON.parse(JSON.stringify(value)); // Deep Copy
      this.profilesList.sort(FilterProfile.compareByName); // eslint-disable-line @typescript-eslint/unbound-method
      this.displayManagerBtn = !!(this.profilesList && this.profilesList.length);
    });
    this.filterService.currentProfile.subscribe(currentProfile => {
      // Used for when alerts are applied
      this.currentProfile = currentProfile;

    });
    this.handleOutsideClicks();
  }

  public onProfileSelected(profileId: number) {
    if (!this.clientProfileService.txAttributes || !this.clientProfileService.txAttributes.IsTrial) {
      this.openProfileList = false;
      this.currentProfile = this.profilesList.find(x => x.profileId === profileId); // Using this to set the profile name faster

      this.filterService.loadProfile(profileId);
    }
  }

  public onManageProfiles() {
    if (!this.clientProfileService.txAttributes || !this.clientProfileService.txAttributes.IsTrial) {
      this.openProfileList = false;
      this.filterService.showManagement.next(true);
    }
  }

  public onMgmtClose() {
    this.filterService.showManagement.next(false);
  }

  public onOpenList() {
    this.openProfileList = !this.openProfileList;
  }

  /**
   * Closes the drop down when clicking outside of it.
   */
  private handleOutsideClicks() {
    this.renderer.listen('window', 'click', (e: Event) => {
      const parent = e.target && (<any>e.target).parentElement ? (<any>e.target).parentElement : null;
      if (e.target !== this.toggleButton.nativeElement && e.target !== this.menu.nativeElement && parent !== this.toggleButton.nativeElement) {
        this.openProfileList = false;
      }
    });
  }
}
