<div *ngIf="showDisclaimer()">
    <rdo-overlay [enabled]="true" imgClass='cg-overlay-img'></rdo-overlay>
    <div class="e-dlg-container e-dlg-center-center" style="z-index: 1000003; display: flex; position: fixed">
        <div class="e-pivot-error-dialog e-control e-dialog e-lib e-dlg-modal e-popup-open" tabindex="-1"
            style="max-height: 400px; z-index: 1000003; left: 0px; top: 0px; position: relative;max-width: 750px">
            <div class="e-dlg-header-content" style="max-width: 750px">
                <div class="e-dlg-header">{{'main.disclaimers.disclaimer' | translate }}</div>
            </div>
        
            <div class="e-dlg-content" style="overflow: hidden;">
                
                <p style="line-height: 120%;" innerHTML="{{'main.disclaimers.competition_law' | translate }}"></p>
                
            </div>
            <div class="e-footer-content">
                <button type="button" class="e-control e-btn e-lib e-ok-btn e-primary e-flat" (click)="exitApplication()" *ngIf="showExitButton()" >
                    {{getExitButtonText()}}
                </button>
                &nbsp;
                <button type="button" class="e-control e-btn e-lib e-ok-btn e-primary e-flat" (click)="recordDisclaimerAcceptance()" >
                    {{getButtonText()}}
                </button>
        </div>
        
    </div>
</div>

