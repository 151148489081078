export enum RentalGrowthType {
    monthly = 0,
    yearly = 1
}

export enum RentalGrowthItemType {
    unitsOnRent = 0,
    unitsOnFleet = 1,
    physicalUtil = 2,
    unitCost = 3,
    financialUtil = 4,
    monthlyRentalRates = 5,
    weeklyRentalRates = 6,
    dailyRentalRates = 7,
    hourlyRentalRates = 8
}


export interface IRentalGrowthItem {
    Title: string;
    ItemType: RentalGrowthItemType;
    ClientAvg: number;
    ClientGrowth: number;
    BenchmarkAvg: number;
    BenchmarkGrowth: number;
    CvsBGrowthUnitsDiff: number;
    CvsBGrowthPointsDiff: number;
    ClientTotal: number;
    GrowthTotal: number;
    // MoM Data
    ClientAvgMoM?: number;
    ClientGrowthMoM?: number;
    BenchmarkGrowthMoM?: number;
    CvsBGrowthUnitsDiffMoM?: number;
    CvsBGrowthPointsDiffMoM?: number;
    GrowthTotalMoM?: number;
}


export class RentalGrowthData {

    constructor(
        protected _dateFrom: Date,
        protected _type: RentalGrowthType,
        protected _unitsOnRent: IRentalGrowthItem,
        protected _unitsOnFleet: IRentalGrowthItem,
        protected _physicalUtil: IRentalGrowthItem,
        protected _unitCost: IRentalGrowthItem,
        protected _financialUtil: IRentalGrowthItem, 
        protected _monthlyRentalRates: IRentalGrowthItem,
        protected _weeklyRentalRates: IRentalGrowthItem,
        protected _dailyRentalRates: IRentalGrowthItem,
        protected _hourlyRentalRates: IRentalGrowthItem
    ) { }

    get dateFrom(): Date {
        return this._dateFrom;
    }

    get type(): RentalGrowthType {
        return this._type;
    }

    get unitsOnRent(): IRentalGrowthItem {
        return this._unitsOnRent;
    }

    get unitsOnFleet(): IRentalGrowthItem {
        return this._unitsOnFleet;
    }

    get physicalUtil(): IRentalGrowthItem {
        return this._physicalUtil;
    }

    get unitCost(): IRentalGrowthItem {
        return this._unitCost;
    }

    get financialUtil(): IRentalGrowthItem {
        return this._financialUtil;
    }

    get monthlyRentalRates(): IRentalGrowthItem {
        return this._monthlyRentalRates;
    }

    get weeklyRentalRates(): IRentalGrowthItem {
        return this._weeklyRentalRates;
    }

    get dailyRentalRates(): IRentalGrowthItem {
        return this._dailyRentalRates;
    }

    get hourlyRentalRates(): IRentalGrowthItem {
        return this._hourlyRentalRates;
    }
}


