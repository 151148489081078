<div class="container" id="summary" style="width: 1500px;">
	<rdo-overlay id="summary-overlay" [enabled]="loading"></rdo-overlay>
	<div class="row" style="margin-bottom: 10px;">
		<rdo-dashboard-card-set-extended [data]="cardData" [show-quartiles]="'no'" [loading]="false" (onCardClicked)="onCardClicked($event)"></rdo-dashboard-card-set-extended>
	</div>
	<div id="full-chart" class="row">
		<div class="col-xs-12">
			<div class="no-border">
				<div>
					<ul class="nav nav-tabs" id="summary-nav-chart">
						<li class="dropdown active chart-drop-down-container">
							<a href="#" class="dropdown-toggle chart-title" id="summary-chart-title" data-toggle="dropdown" style="cursor: pointer;">
								<span tooltip="{{getSelectedChartTooltip()}}">{{getSelectedChartName()}}</span>
								<span class="caret"></span>
							</a>
							<ul class="dropdown-menu" role="menu">
								<li class="dropdown-submenu">
									<a tabindex="-1" (click)="$event.stopPropagation()"
										id="client-revenue-options">
										{{'main.tabs.summary.charts.client_revenue.text' | translate}}</a>
									<ul class="dropdown-menu">
										<li>
											<a id="revenue-book-chart-link" [class.active]="chartSwitcher.isRevenueBookChart()"
											(click)="chartSwitcher.selectRevenueBookChart();">
											{{'main.tabs.summary.charts.client_revenue.vs_books.text' | translate}}</a>
										</li>
										<li>
											<a id="revenue-benchmark-chart-link" [class.active]="chartSwitcher.isTotalRevenueVsBenchmarkChart()"
											(click)="chartSwitcher.selectTotalRevenueVsBenchmarkChart();">
											{{'main.tabs.summary.charts.client_revenue.vs_bench_quartiles.text' | translate}}</a>
										</li>
									</ul>
								</li>
								<li>
									<a id="rate-bench-chart-link" [class.active]="chartSwitcher.isRevenueBenchChart()"
									(click)="chartSwitcher.selectRevenueBenchChart();">
									{{'main.tabs.summary.charts.client_rate_vs_bench.text' | translate}}</a>
								</li>
								<li class="dropdown-submenu" *ngIf="hasTotalMoMRateChangeData || hasTotalYoYRateChangeData || hasComparedMoMRateChangeData || hasComparedYoYRateChangeData">
									<a tabindex="-1" (click)="$event.stopPropagation()" id="client-rate-change-options"
									>{{'main.tabs.summary.charts.client_rate_change.text' | translate}}</a>
									<ul class="dropdown-menu">
										<li *ngIf="hasTotalMoMRateChangeData">
											<a id="rate-change-total-mom-chart-link" [class.active]="chartSwitcher.isMoMRateChangeTotalChart()"
											(click)="chartSwitcher.selectMoMRateChangeTotalChart();">
											{{'main.tabs.summary.charts.client_rate_change.total.total_mom' | translate}}</a>
										</li>
										<li *ngIf="hasTotalYoYRateChangeData">
											<a id="rate-change-total-yoy-chart-link" [class.active]="chartSwitcher.isYoYRateChangeTotalChart()"
											(click)="chartSwitcher.selectYoYRateChangeTotalChart();">
											{{'main.tabs.summary.charts.client_rate_change.total.total_yoy' | translate}}</a>
										</li>
										<li *ngIf="hasComparedMoMRateChangeData">
											<a id="rate-change-mom-chart-link" [class.active]="chartSwitcher.isMoMRateChangeChart()"
											(click)="chartSwitcher.selectMoMRateChangeChart();">
											{{'main.tabs.summary.charts.client_rate_change.vs_bench.vs_bench_mom' | translate}}</a>
										</li>
										<li *ngIf="hasComparedYoYRateChangeData">
											<a id="rate-change-yoy-chart-link" [class.active]="chartSwitcher.isYoYRateChangeChart()"
											(click)="chartSwitcher.selectYoYRateChangeChart();">
											{{'main.tabs.summary.charts.client_rate_change.vs_bench.vs_bench_yoy' | translate}}</a>
										</li>
									</ul>
								</li>
								<li class="dropdown-submenu">
									<a tabindex="-1" (click)="$event.stopPropagation()"
									id="client-physical-utilization-options">
										{{'main.tabs.summary.charts.client_physical_utilization.text' | translate}}</a>
									<ul class="dropdown-menu">
										<li>
											<a id="utilization-total-chart-link" [class.active]="chartSwitcher.isTotalUtilizationChart()"
											(click)="chartSwitcher.selectTotalUtilizationChart();">
											{{'main.tabs.summary.charts.client_physical_utilization.total.text' | translate}}</a>
										</li>
										<li>
											<a id="utilization-total-chart-link" [class.active]="chartSwitcher.isTotalBenchmarkUtilizationWeeklyChart()"
											(click)="selectTotalBenchmarkUtilizationChartWeeklyChart()">
											{{'main.tabs.summary.charts.benchmark_utilization_weekly.total' | translate}}</a>
										</li>
										<li>
											<a id="utilization-benchmark-chart-link" [class.active]="chartSwitcher.isBenchmarkUtilizationChart()"
											(click)="chartSwitcher.selectBenchmarkUtilizationChart();">
											{{'main.tabs.summary.charts.client_physical_utilization.vs_bench.text' | translate}}</a>
										</li>
										<li>
											<a id="utilization-benchmark-weekly-chart-link" [class.active]="chartSwitcher.isBenchmarkUtilizationWeeklyChart()"
											(click)="selectBenchmarkUtilizationChartWeeklyChart()">
											{{'main.tabs.summary.charts.client_physical_utilization.vs_bench.weekly' | translate}}</a>
										</li>
									</ul>
								</li>
								<li class="dropdown-submenu">
									<a tabindex="-1" (click)="$event.stopPropagation()"
									id="client-financial-utilization-options">
										{{'main.tabs.summary.charts.client_financial_utilization.text' | translate}}</a>
									<ul class="dropdown-menu">
										<li>
											<a id="dollar-utilization-total-chart-link" [class.active]="chartSwitcher.isTotalDollarUtilizationChart()"
											(click)="chartSwitcher.selectTotalDollarUtilizationChart();">
											{{'main.tabs.summary.charts.client_financial_utilization.total.text' | translate}}</a>
										</li>
										<li>
											<a id="dollar-utilization-benchmark-chart-link" [class.active]="chartSwitcher.isBenchmarkDollarUtilizationChart()"
											(click)="chartSwitcher.selectBenchmarkDollarUtilizationChart();">
											{{'main.tabs.summary.charts.client_financial_utilization.vs_bench.text' | translate}}</a>
										</li>
									</ul>
								</li>
								<li class="dropdown-submenu">
									<a tabindex="-1" (click)="$event.stopPropagation()" id="rental-growth-charts-link">
										{{'main.tabs.summary.charts.client_units_on_rent_growth.title' | translate}}</a>
									<ul class="dropdown-menu">
										<li>
											<a id="mom-rental-growth-chart-link"
											[class.active]="chartSwitcher.isOnRentInFleetMoMChart()"
											(click)="chartSwitcher.selectOnRentInFleetMoMChart();"
											>{{'main.tabs.summary.charts.client_units_on_rent_growth.vs_bench_mom' | translate}}</a>
										</li>
										<li *ngIf="hasOrifChartData">
											<a id="yoy-rental-growth-chart-link"
											[class.active]="chartSwitcher.isOnRentInFleetYoYChart()"
											(click)="chartSwitcher.selectOnRentInFleetYoYChart();"
											>{{'main.tabs.summary.charts.client_units_on_rent_growth.vs_bench_yoy' | translate}}</a>
										</li>
									</ul>
								</li>
								<li class="dropdown-submenu">
									<a tabindex="-1" (click)="$event.stopPropagation()" id="revenue-distribution-charts-link">
										{{'main.tabs.summary.charts.client_business_mix_vs_bench.title' | translate}}</a>
									<ul class="dropdown-menu">
											<li>
												<a id="revenue-distrubution-total-chart-link" [class.active]="chartSwitcher.isRevenueDistributionTotalChart()"
												(click)="chartSwitcher.selectRevenueDistributionTotalChart();">
												{{'main.tabs.summary.charts.client_business_mix_vs_bench.total' | translate}}</a>
											</li>
											<li>
												<a id="revenue-distrubution-vs-bench-chart-link" [class.active]="chartSwitcher.isRevenueDistributionVsBenchChart()"
												(click)="chartSwitcher.selectRevenueDistributionVsBenchChart();">
												{{'main.tabs.summary.charts.client_business_mix_vs_bench.vs_bench' | translate}}</a>
											</li>											
									</ul>
								</li>
							</ul>
						</li>
						<li id="info-icon" class="hover-only">
							<span style="color: #2d6ca2;" title="{{'main.tabs.summary.charts.tooltip' | translate}}">
								<i class="fa fa-fw fa-info-circle"></i>
							</span>
						</li>
					</ul>
				</div>
				<div class="tab-content chart" id="summary-charts">
					<revenue-vs-book-chart *ngIf="chartSwitcher.isRevenueBookChart()" class="active tab-pane revenue-util-charts" [chart-data]="chartData.data"></revenue-vs-book-chart>
					<revenue-vs-benchmark-chart *ngIf="chartSwitcher.isTotalRevenueVsBenchmarkChart()" class="active tab-pane revenue-util-charts" [chart-data]="chartData.data" [dashed-line-offset]="clientAttributes.DashedLineOffset"></revenue-vs-benchmark-chart>
					<rdo-revenue-by-month-chart *ngIf="chartSwitcher.isRevenueBenchChart()" class="active tab-pane revenue-util-charts" [chart-data]="chartData.data"
												[comparisonMode]="comparisonMode.Benchmark" [dashed-line-offset]="clientAttributes.DashedLineOffset"></rdo-revenue-by-month-chart>
					<rdo-utilization-by-month-chart *ngIf="chartSwitcher.isBenchmarkUtilizationChart()" class="active tab-pane revenue-util-charts"
												[chart-data]="chartData.data"></rdo-utilization-by-month-chart>
					<rdo-utilization-by-month-chart *ngIf="chartSwitcher.isTotalUtilizationChart()" [chart-type]="'TotalUtilization'" class="active tab-pane revenue-util-charts"
												[chart-data]="chartData.data"></rdo-utilization-by-month-chart>
					<rdo-utilization-by-week-chart *ngIf="chartSwitcher.isBenchmarkUtilizationWeeklyChart()" class="active tab-pane revenue-util-charts"
												[chart-data]="weeklyUtilization" [monthly-data]="chartData.data"></rdo-utilization-by-week-chart>
					<rdo-utilization-by-week-chart *ngIf="chartSwitcher.isTotalBenchmarkUtilizationWeeklyChart()" class="active tab-pane revenue-util-charts"
												[chart-type]="'TotalUtilization'" [chart-data]="weeklyUtilization" [monthly-data]="chartData.data"></rdo-utilization-by-week-chart>								
					<rdo-dollar-utilization-by-month-chart *ngIf="chartSwitcher.isBenchmarkDollarUtilizationChart()" [dashed-line-offset]="clientAttributes.DashedLineOffset" class="active tab-pane revenue-util-charts"
														   [chart-data]="chartData.data"></rdo-dollar-utilization-by-month-chart>
					<rdo-dollar-utilization-by-month-chart *ngIf="chartSwitcher.isTotalDollarUtilizationChart()" [chart-type]="'TotalUtilization'" class="active tab-pane revenue-util-charts"
														   [chart-data]="chartData.data"></rdo-dollar-utilization-by-month-chart>
					<rdo-on-rent-in-fleet-chart *ngIf="chartSwitcher.isOnRentInFleetYoYChart()"
												class="active tab-pane revenue-util-charts" [chart-type]="'YoY'"
												[chart-data]="orifChartData"></rdo-on-rent-in-fleet-chart>
					<rdo-on-rent-in-fleet-chart *ngIf="chartSwitcher.isOnRentInFleetMoMChart()"
												class="active tab-pane revenue-util-charts" [chart-type]="'MoM'"
												[chart-data]="orifChartData"></rdo-on-rent-in-fleet-chart>
					<rdo-revenue-distribution-chart *ngIf="chartSwitcher.isRevenueDistributionTotalChart()" [chart-type]="'Total'" class="active tab-pane revenue-util-charts"
													[chart-data]="revenueDistribution.Items"></rdo-revenue-distribution-chart>
					<rdo-revenue-distribution-chart *ngIf="chartSwitcher.isRevenueDistributionVsBenchChart()" class="active tab-pane revenue-util-charts"
													[chart-data]="revenueDistribution.Items"></rdo-revenue-distribution-chart>
					<rdo-rate-change-chart *ngIf="chartSwitcher.isMoMRateChangeChart()" class="active tab-pane revenue-util-charts"
										   [chart-data]="rateChangeData" [rate-change-type]="0" [dashed-line-offset]="clientAttributes.DashedLineOffset"></rdo-rate-change-chart>
					<rdo-rate-change-chart *ngIf="chartSwitcher.isYoYRateChangeChart()" class="active tab-pane revenue-util-charts"
										   [chart-data]="rateChangeData" [rate-change-type]="1" [dashed-line-offset]="clientAttributes.DashedLineOffset"></rdo-rate-change-chart>
					<rdo-rate-change-total-chart *ngIf="chartSwitcher.isMoMRateChangeTotalChart()" class="active tab-pane revenue-util-charts"
												 [chart-data]="rateChangeData" [rate-change-type]="0"></rdo-rate-change-total-chart>
					<rdo-rate-change-total-chart *ngIf="chartSwitcher.isYoYRateChangeTotalChart()" class="active tab-pane revenue-util-charts"
												 [chart-data]="rateChangeData" [rate-change-type]="1"></rdo-rate-change-total-chart>

				</div>
				<div id="summary-chart-loader" class="chart-loader" *ngIf="chartLoading && !loading">
					<img src="/assets/images/loader-sm.gif" />
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-xs-4">
			<rdo-multi-top-list [configs]="geographyTopListConfigs" [comparisonMode]="mode"></rdo-multi-top-list>
		</div>
		<div class="col-xs-4">
			<rdo-top-list [title]="'main.tabs.summary.tables.equipment_categories.title'" [items]="categories.data.Items" [linkUrl]="resolveCategoryUrl" [nameSelector]="'RouseCategory'"
						  (linkClick)="navigateToCategory($event)" [footerLinkTitle]="'main.tabs.summary.tables.equipment_categories.all'" [footerLinkDsl]="['/equipment']"
						  [comparisonMode]="mode" *ngIf="!clientAttributes || !clientAttributes?.IsTrial">
			</rdo-top-list>

			<rdo-top-list [title]="'main.tabs.summary.tables.equipment_categories.title'" [items]="categories.data.Items" [nameSelector]="'RouseCategory'"
						  [comparisonMode]="mode" *ngIf="clientAttributes && clientAttributes?.IsTrial">
			</rdo-top-list>
		</div>
		<div class="col-xs-4">
			<rdo-top-list [title]="'main.core.common.counts.rate_types.plural'" [items]="rateTypes.data.Items" [nameSelector]="'type'" [comparisonMode]="mode">
			</rdo-top-list>
		</div>
	</div>
</div>