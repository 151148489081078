<div *ngIf="isVisible" class="e-dlg-container e-dlg-center-center" style="z-index: 1000003; display: flex; position: fixed;">
    <div class="e-pivot-error-dialog e-control e-dialog e-lib e-dlg-modal e-popup e-popup-open"
        id="PivotView_PivotFieldList_ErrorDialog" role="dialog" aria-labelledby="PivotView_PivotFieldList_ErrorDialog"
        aria-describedby="PivotView_PivotFieldList_ErrorDialog_dialog-content" aria-modal="true" tabindex="-1"
        style="max-height: 104px; z-index: 1000003; left: 0px; top: 0px; position: relative;">
        <div class="e-dlg-header-content" id="PivotView_PivotFieldList_ErrorDialog_dialog-header"><button
                class="e-dlg-closeicon-btn e-control e-btn e-lib e-flat e-icon-btn" type="button" title="Close"
                aria-label="Close" (click)="closeMessageDialog()"><span class="e-btn-icon e-icon-dlg-close e-icons"></span></button>
            <div class="e-dlg-header" id="PivotView_PivotFieldList_ErrorDialog_title">{{title}}</div>
        </div>
        <div class="e-dlg-content" id="PivotView_PivotFieldList_ErrorDialog_dialog-content" style="overflow: hidden;">{{message}}</div>
        <div class="e-footer-content"><button type="button"
                class="e-control e-btn e-lib e-ok-btn e-primary e-flat" (click)="closeMessageDialog()">OK</button></div>
    </div>
    <div class="e-dlg-overlay" style="z-index: 1000002; display: block; position: absolute;"></div>
</div>