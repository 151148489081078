import {Pipe, PipeTransform, LOCALE_ID, Inject} from '@angular/core';
import {DatePipe} from '@angular/common';

// Did not implement Localization in this component since it's not used anywhere.
/*
 * Formats selected filter date for display
 * Usage:
 *   selectedDate | rdoRangedDate
 * Example:
 *   {{ -1 | rdoRangedDate}}
 *   formats to: Last 2 Months
 *   {{ '2016-01-01' | rdoRangedDate}}
 *   formats to: Jan 2016
*/
@Pipe({
	 name: 'rdoRangedDate'
})
export class RdoRangedDatePipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) private _locale: string) {

    }

    transform(value: string, args: string[]): any {

        const range = parseInt(value);
        // ranged value
        if (!isNaN(range)) {
            switch (range) {
                case 0:
                    return 'Current Month';
                case -1:
                    return 'Last 2 Months';
                case -2:
                    return 'Last 3 Months';
                default :
                    return 'NOT_SUPPORTED_RANGE';
            }

        }
        // month selection format
        const date = new Date(value);
        if (isNaN(date.getFullYear())) {
            return 'INVALID_DATE';
        }
        // reusing ng date pipe to do formatting, b/c internal DateFormatter is inaccessible
        // TODO: Can it be injected?
        const datePipe = new DatePipe(this._locale);
        return datePipe.transform(date, 'MMM yyyy');
    }
}
