export interface IFilterDefaultSettings {
    show_hourly_rate?: boolean;
}

export class FirestoreSettings {
    [roleId: number]: IMobileSettings;
    filterDefaults: IFilterDefaultSettings

    constructor() {
        this.filterDefaults = {};
    }
}

export enum PhysicalUtilizationFormulas{
    COST = 'cost',
    UNITS = 'units'
}

export interface IMobileSettings {
    physical_utilization_formula?: PhysicalUtilizationFormulas;
}


