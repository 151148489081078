import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import * as _ from 'lodash';
declare let $: any;

import { LazyLoadEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';

import { SortOptionsNg, PageOptionsNg } from '../../models';
import { GridConfigService } from './grid-config.service';
import { MetricsGridConfig, GridRowConfig } from './MetricsGridConfig';
import { IGridColumnGroup } from './IGridColumnGroup.interface';
import { IColumnConfig } from './IColumnConfig.interface';
import { IGridRow } from './IGridRow.interface';
import { IDataRow } from './IDataRow.interface';
import { FormatService } from '../query';
import { ColumnDefinitionService } from './column-definition.service';
import { GridTableParent } from './grid-table-parent';
import { TranslateService } from '@ngx-translate/core';
import { TooltipsService } from '../services/tooltips.service';
import { GridSortingService } from '../services';
import { moveColumn } from './utils/moveColumn';

import cloneDeep from 'lodash/cloneDeep';

export const DEFAULT_MIN_COLUMN_WIDTH = 100;
export const DEFAULT_MAX_COLUMN_WIDTH = 500;

export const cachedStringWidths = new Map();

/**
 * Find the width of the cell with the longest string in a list of strings.
 * @param strings
 * @param formatService
 */
export function getLongestCellWidth(
  strings: string[],
  formatService: FormatService,
  options?: { pad?: number; useDom?: boolean; debug?: boolean }
): number {
  // Any padding below 50 can lead to errant ellipsis showing up occasionally.
  const pad = options?.pad || 30;
  const useDom = options?.useDom || false;
  const debug = options?.debug || false;

  if (debug) {
    // eslint-disable-next-line no-console
    console.time('getLongestCellWidth');
  }
  let longestStr = '';
  let longestStrWidth: number = 0;
  strings.forEach((str: string) => {
    let width: number;
    if (cachedStringWidths.has(str)) {
      width = cachedStringWidths.get(str);
    } else {
      if (useDom) {
        const el = document.createElement('span');
        document.body.appendChild(el);
        el.style.font = 'Roboto';
        el.style.fontSize = '12px';
        el.style.height = 'auto';
        el.style.width = 'auto';
        el.style.whiteSpace = 'no-wrap';
        el.style.position = 'absolute';
        el.style.textDecorationStyle = 'solid';
        el.style.textDecorationThickness = 'auto';
        el.innerHTML = str;
        width = Math.floor(el.getBoundingClientRect().width);
        document.body.removeChild(el);
        cachedStringWidths.set(str, width);
      } else {
        width = formatService.getTextWidth(str, 'small bold Roboto');
        cachedStringWidths.set(str, width);
      }
    }

    width += pad; // tack on extra to help avoid ... in edge cases.

    if (width > longestStrWidth) {
      longestStr = str;
      longestStrWidth = width;
    }
  });

  if (debug) {
    // eslint-disable-next-line no-console
    console.log(
      'getLongestCellWidth',
      longestStr,
      'width found',
      longestStrWidth
    );
    // eslint-disable-next-line no-console
    console.timeEnd('getLongestCellWidth');
  }
  return longestStrWidth;
}

export function getClampedColumnWidth(
  strWidth: number,
  minWidth: number = DEFAULT_MIN_COLUMN_WIDTH,
  maxWidth: number = DEFAULT_MAX_COLUMN_WIDTH
) {
  return Math.max(Math.min(strWidth, maxWidth), minWidth);
}

/*
Calculate the max width of the column based on the childConfig's max width first.
 */
export function getExpandedColumnMaxWidth(
  column: IColumnConfig,
  defaultMaxWidth = DEFAULT_MAX_COLUMN_WIDTH
): number {
  if (column.childConfig && column.childConfig.maxWidth) {
    return column.childConfig.maxWidth;
  } else if (column.maxWidth) {
    return column.maxWidth;
  }
  return defaultMaxWidth;
}

export function getExpandedColumnMinWidth(
  column: IColumnConfig,
  defaultMinWidth = DEFAULT_MIN_COLUMN_WIDTH
): number {
  if (column.childConfig && column.childConfig.minWidth) {
    return column.childConfig.minWidth;
  } else if (column.minWidth) {
    return column.minWidth;
  }
  return defaultMinWidth;
}

/**
 * Find the correct width of a column based on the content of the largest column cell and min max configuration for the
 * for the expanded rows.
 */
export function calculateRowExpandedColumnWidths(
  row: { children: { raw: Record<string, any> }[] },
  columns: IColumnConfig[],
  formatService: FormatService
): Record<string, number> {
  const result: Record<string, number> = {};
  for (const column of columns) {
    // collect all the strings in the column cells
    const columnTextStrings: string[] = row.children.map((childRow) => {
      return childRow.raw[column.field]
        ? childRow.raw[column.field].toString()
        : '';
    });

    // find the longest string.
    const longestCellValueLength: number = columnTextStrings.reduce(
      (accumulator, currentValue) => {
        return currentValue.length > accumulator
          ? currentValue.length
          : accumulator;
      },
      0
    );

    // find the longest string.
    if (longestCellValueLength !== 0) {
      // calculate the actual pixel length of the longest string.
      const calculatedStringWidth = getLongestCellWidth(
        columnTextStrings,
        formatService
      );

      const calculatedMaxWidth = getExpandedColumnMaxWidth(column);
      const calculatedMinWidth = getExpandedColumnMinWidth(column);

      const currentColumnWidth = getClampedColumnWidth(
        calculatedStringWidth,
        calculatedMinWidth,
        calculatedMaxWidth
      );

      // This block mutates the column information which isn't ideal.
      if (currentColumnWidth > column.width) {
        column.width = currentColumnWidth;
        result[column.field] = currentColumnWidth;
      }
    } else {
      result[column.field] = column.width;
    }
  }
  return result;
}

/* eslint-disable no-prototype-builtins */
@Component({
  selector: 'rdo-grid-table-configured-expandable',
  templateUrl: 'grid-table-configured-expandable.component.html',
  styleUrls: ['grid-table-configured-expandable.component.scss'],
})
export class GridTableConfiguredExpandableComponent
  extends GridTableParent
  implements OnChanges, OnInit, AfterViewInit
{
  @ViewChild('gridPaginator') gridPaginator: Paginator;
  @ViewChild('rdoExpandedGrid') rdoExpandedGrid: Table;
  @Input() name: string;
  @Input() pagedData: any;
  @Input() frozenRows: Array<any> = [];
  @Input() dataKey: string;
  @Input() expanded: boolean = false;
  // eslint-disable-next-line
  @Input('title') tableTitle: string;
  @Input() headerStyle: any;
  @Input() paging: PageOptionsNg;
  @Input() sorting: SortOptionsNg;
  @Input() totalCount: number;
  @Input() sortInProgress: boolean;
  @Input() showColumnSelector: boolean;
  // The following 2 configs, gridConfig and columnSelectorConfig,  can be the same object,
  // but having them as separate parameters allows for some flexibility
  @Input() gridConfig: MetricsGridConfig;
  @Input() columnSelectorConfig: MetricsGridConfig;
  @Input() frozenWidth = '200px';
  @Input() disableExpandability = false;
  @Input() useProductTypeColumnSelector = false;
  @Input() useConfiguredColumnSelector = false;
  // eslint-disable-next-line
  @Input('rowExpansion') childrenFactory: (
    parentRecord?: any
  ) => Observable<Array<any>>;
  @Input() scrollScale: string;
  @Input() storage: 'session' | 'local' = 'session';
  @Output() pageOnChange = new EventEmitter<any>();
  @Output() lazyLoadOnChange = new EventEmitter<LazyLoadEvent>();
  @Output() visibilityChanged = new EventEmitter<IGridColumnGroup>();
  @Output() downloadExcelClick = new EventEmitter<MetricsGridConfig>();
  @Output() gridConfigChange = new EventEmitter<MetricsGridConfig>();

  rowGroupMetadata: any;
  groups: Array<any>;
  scrollableColumns: Array<any> = [];
  frozenColumns: Array<any> = [];
  gridRows: Array<IGridRow> = [];
  frozenGridRows: Array<IGridRow> = [];
  scrollHeight: string;
  expandedRowsWidth: any = {};
  private isInitialized = false;
  constructor(
    protected formatService: FormatService,
    protected translateService: TranslateService,
    private gridConfigService: GridConfigService,
    private columnDefinitionService: ColumnDefinitionService,
    private tooltipsService: TooltipsService,
    private gridSortingService: GridSortingService
  ) {
    super(formatService, translateService);
    this.moveColumn = this.moveColumn.bind(this);
  }

  ngOnInit(): void {
    this.setScrollHeight();
  }

  ngAfterViewInit(): void {
    // tslint:disable-next-line:radix
    of(null)
      .pipe(delay(0))
      .subscribe(
        () => (this.scrollHeight = `${parseInt(this.scrollHeight) + 1}px`)
      );
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      /*if (propName === 'gridConfig') {
        console.log(
          'GridTableConfiguredExpandableComponent.ngOnChanges gridConfig changed',
          changes
        );
      }*/
      if (changes.hasOwnProperty(propName)) {
        if (propName === 'pagedData') {
          this.sortInProgress = false;
          this.prepareTwice();
        }
        if (propName === 'gridConfig') {
          this.prepareTwice();
          this.saveGridLayout(null, false);
        }
      }
    }
  }

  setScrollHeight = () => {
    let height = window.innerHeight;
    if (this.scrollScale === 'largeScale') {
      height -= 370;
    } else if (this.scrollScale !== 'smallScale') {
      height -= 425;
    } else {
      height -= 485;
    }
    if (window.innerWidth < 1500) {
      height -= 25;
    }
    this.scrollHeight = `${height}px`;
    this.handleScrollMargin();
  };

  resetPaginator = () => {
    this.gridPaginator.first = 1;
    this.gridPaginator.updatePageLinks();
    this.gridPaginator.updatePaginatorState();
  };

  filterSortableColumns(column: IColumnConfig): string | boolean {
    return this.columnDefinitionService.filterSortableColumns(column);
  }

  clickCellLink = (column: IColumnConfig, row: any, parent?: any): boolean => {
    if (
      !_.isNil(parent) &&
      column.childConfig &&
      column.childConfig.linkClick
    ) {
      return column.childConfig.linkClick(column, row, parent);
    }
    this.handleScrollMargin();
    return column.linkClick ? column.linkClick(column, row, parent) : true;
  };

  onPage = ($event) => {
    this.expandedRowsWidth = {};
    this.pageOnChange.emit($event);
  };

  onLazyLoad = ($event: LazyLoadEvent) => {
    this.lazyLoadOnChange.emit($event);
  };

  onMouseEnter = (
    rowData,
    isExpansion: boolean = false,
    debug: string = ''
  ) => {
    rowData.hover = true;
  };

  onMouseLeave = (
    rowData,
    isExpansion: boolean = false,
    debug: string = ''
  ) => {
    rowData.hover = false;
  };

  onRowExpand = (row: any) => {
    if (this.disableExpandability) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;
    this.ensureChildrenLoaded(row).subscribe((c) => {
      row.expanded = true;
      const visibleColumns = component.getVisibleColumns(
        component.frozenColumns
      );
      const expandedColumnWidths = calculateRowExpandedColumnWidths(
        row,
        visibleColumns,
        this.formatService
      );
      this.expandedRowsWidth[row[this.rdoExpandedGrid.dataKey]] =
        expandedColumnWidths;
      component.frozenWidth = component.updateFrozenWidth(
        component.frozenRows,
        component.frozenColumns
      );
      this.clearFirstChildInExpandedRow();
      this.handleScrollMargin();
    });
  };

  isEmpty = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  };

  onRowCollapse = (row: any): void => {
    if (this.disableExpandability) {
      return;
    }

    row.expanded = false;

    delete this.expandedRowsWidth[row[this.rdoExpandedGrid.dataKey]];
    if (
      this.rdoExpandedGrid.expandedRowKeys &&
      !this.isEmpty(this.rdoExpandedGrid.expandedRowKeys)
    ) {
      const visibleColumns = this.getVisibleColumns(this.frozenColumns);

      // Loop through the visible columns and reset the widths of the columns
      // based on the content after closing a row.
      for (const column of visibleColumns) {
        let maxLength = 0;
        // tslint:disable-next-line:no-shadowed-variable
        for (const row in this.expandedRowsWidth) {
          if (
            this.expandedRowsWidth.hasOwnProperty(row) &&
            this.expandedRowsWidth[row]
          ) {
            const currentWidth = this.expandedRowsWidth[row][column.field];
            if (currentWidth > maxLength) {
              maxLength = currentWidth;
            }
          }
        }
        column.width = maxLength;
      }

      this.frozenWidth = this.updateFrozenWidth(
        this.frozenRows,
        this.frozenColumns
      );
      this.handleScrollMargin();
      // adding a second adjustment because on very rare occasions the frozen and non-frozen rows segments could get misaligned.
      // https://rouseservices.atlassian.net/browse/ANA-16110
      // I was able to reproduce this issue somewhat prior to adding this second handleScrollMargin call.  Doesn't seem to have
      // any real performance impact. Using prepareTwice below for the same reason.
      this.handleScrollMargin(100);
    } else {
      // TODO: this else never seems to be triggered is there a bug somewhere that causes that?
      this.frozenWidth = this.updateFrozenWidth(
        this.frozenRows,
        this.frozenColumns
      );
      this.prepareTwice();
    }
  };

  isBool = (val: any): boolean => {
    return typeof val === 'boolean';
  };

  moveColumn(column: IColumnConfig, direction: 'left' | 'right'): void {
    let beforeHookResult = true;
    if (this.gridConfig?.options?.hooks?.beforeMoveColumn) {
      beforeHookResult = this.gridConfig?.options?.hooks?.beforeMoveColumn(
        column,
        this.gridConfig,
        direction
      );
    }
    if (beforeHookResult) {
      //this.gridConfig?.options?.hooks.moveColumn(column, this.gridConfig, direction);
      // Changes to moveColumn will be saved to storage within ngOnChanges.
      const grid = moveColumn(column, this.gridConfig, direction, true);
      this.saveGridLayout(grid, false);
      this.gridConfigChange.emit(grid);
    }
    if (this.gridConfig.options?.hooks?.afterMoveColumn) {
      this.expandedRowsWidth = {};
      this.gridConfig.options?.hooks?.afterMoveColumn();
    }
  }

  // This is used when the user clicks the x on a column.
  toggleColumnVisibility = (item: IGridColumnGroup | IColumnConfig): void => {
    let beforeHookResult = true;
    if (this.gridConfig?.options?.hooks?.beforeColumnVisibilityChange) {
      beforeHookResult =
        this.gridConfig?.options?.hooks?.beforeColumnVisibilityChange(
          item,
          this.gridConfig
        );
    }
    if (beforeHookResult) {
      item.visible = !item.visible;
      const grid = cloneDeep(this.gridConfig);
      this.saveGridLayout(grid, false);
      this.gridConfigChange.emit(grid);
    }

    if (this.gridConfig?.options?.hooks?.afterColumnVisibilityChange) {
      this.expandedRowsWidth = {};
      this.gridConfig?.options?.hooks?.afterColumnVisibilityChange(
        item,
        this.gridConfig
      );
    }
  };

  saveGridLayout(config?: MetricsGridConfig, runPrepare?: boolean): void {
    this.gridConfigService.saveGridLayout(
      this.name,
      config || this.gridConfig,
      'session'
    );
    if (runPrepare === undefined || runPrepare === true) {
      this.prepareTwice();
    }
  }

  getVisibleColumns = (
    groups: Array<IGridColumnGroup>
  ): Array<IColumnConfig> => {
    const mappedData = _.flatMap(
      groups
        .filter((x) => x.visible)
        .map((x) => x.columns.filter((c) => c.visible !== false))
    );
    return mappedData;
  };

  getVisibleColumnsInGroup(groupId: number): IColumnConfig[] {
    return this.gridConfig.groups[groupId].columns.filter(
      (c) => c.visible !== false
    );
  }

  getVisibleColumnGroups = (
    groups: Array<IGridColumnGroup>
  ): Array<IGridColumnGroup> => {
    return groups.filter((x) => x.visible);
  };

  getColGroupWidth = (group: IGridColumnGroup): string => {
    let groupWidth = 0;

    group.columns.forEach((column) => {
      if (column.visible) {
        groupWidth += column?.width || 110;
      }
    });
    return `${groupWidth}px`;
  };

  getWidthStyle = (column: IColumnConfig): string => {
    return column.width ? `${column.width}px` : `110px`;
  };

  isGroupEnd = (group: IGridColumnGroup): boolean => {
    if (
      group &&
      this.scrollableColumns.indexOf(group) !==
        this.scrollableColumns.length - 1
    ) {
      return true;
    }
    return false;
  };

  isHeaderGroupEnd = (
    group: IGridColumnGroup,
    column: IColumnConfig
  ): boolean => {
    const visibleColumns = group.columns.filter((col: IColumnConfig) => {
      return col.visible !== false;
    });
    if (
      group &&
      group.columns &&
      visibleColumns.indexOf(column) === visibleColumns.length - 1 &&
      this.scrollableColumns.indexOf(group) !==
        this.scrollableColumns.length - 1
    ) {
      return true;
    }
    return false;
  };

  isCellGroupEnd = (
    group: IGridColumnGroup,
    column: IColumnConfig
  ): boolean => {
    const visibleColumns = group.columns.filter((c) => c.visible !== false);
    if (
      group &&
      group.columns &&
      visibleColumns.indexOf(column) === visibleColumns.length - 1 &&
      this.scrollableColumns.indexOf(group) !==
        this.scrollableColumns.length - 1
    ) {
      return true;
    }
    return false;
  };

  private prepare(): void {
    if (!this.pagedData || !this.gridConfig) {
      return;
    }

    if (this.rdoExpandedGrid) {
      this.rdoExpandedGrid.expandedRowKeys = {};
    }

    this.frozenGridRows = [];
    this.gridRows = [];
    this.frozenColumns = [];
    this.scrollableColumns = [];
    this.applyLayout();
    this.resetCountColumnsWidth(
      this.gridSortingService.getSortFieldOrDefault()
    );
    const visibleColumns = this.getVisibleColumns(this.frozenColumns);
    let columnIndex = 0;

    // Collect all the column cell strings
    for (const column of visibleColumns) {
      // Add the column header string.
      const columnStrings = [this.translate(column.title)];
      // then loop through all to column values
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let index = 0; index < this.pagedData.length; index++) {
        const dataObject = this.pagedData[index];
        columnStrings.push(
          dataObject[column.field] ? dataObject[column.field] : ''
        );
      }

      const arrowWidth = 15;

      let calculatedLongestWidth = getLongestCellWidth(
        columnStrings,
        this.formatService
      );

      // Buffer the first column width to accommodate the opening arrow.
      if (columnIndex === 0) {
        calculatedLongestWidth += arrowWidth;
      }

      columnIndex += 1;

      // The logic here is a little strange.  Even when autoWidth is not set the column resizes based on the longest cell value.
      // I would have assumed that if autoWidth is false the width of the column is determined by the configured width.
      // All autoWidth does in this case is allow us to set minWidth and maxWidth values for the column.
      if (column.autoWidth) {
        // const minWidth = column.minWidth
        //   ? column.minWidth
        //   : DEFAULT_MINIMUM_COLUMN_WIDTH;
        // const maxWidth = column.maxWidth || DEFAULT_MAX_COLUMN_WIDTH;
        // minWidth = Math.max(minWidth, calculatedLongestWidth);
        const currentColumnWidth = getClampedColumnWidth(
          calculatedLongestWidth,
          column.minWidth,
          column.maxWidth
        );
        // const currentColumnWidth = Math.max(
        //   Math.min(calculatedLongestWidth, maxWidth),
        //   minWidth
        // );
        column.width = currentColumnWidth; // Math.max(Math.min(maxLength * TEXT_CHAR_WIDTH, maxWidth), minWidth || 100);
      } else if (!column.width) {
        // if the column is not autoWidth and has no width applied directly set the default width.
        column.width = DEFAULT_MIN_COLUMN_WIDTH;
        // column.width = Math.max(
        //   column.width ? column.width : DEFAULT_MIN_COLUMN_WIDTH,
        //   calculatedLongestWidth
        // );
      }
    }

    this.frozenWidth = this.updateFrozenWidthWithColumns(
      this.frozenRows,
      this.frozenColumns
    );
    this.adjustFrozenColumnsWidth();

    // Update visible columns
    const visibleScrollableColumns = this.getVisibleColumns(
      this.scrollableColumns
    );
    for (const column of visibleScrollableColumns) {
      if (column.autoWidth) {
        const lengths = _.map(this.pagedData, (row) =>
          row[column.field] ? row[column.field].toString().length : 0
        );
        const maxLength = Math.max(...lengths);
        column.width = Math.max(
          Math.min(maxLength * 9, column.maxWidth || DEFAULT_MIN_COLUMN_WIDTH),
          column.minWidth || DEFAULT_MIN_COLUMN_WIDTH
        );
      }
      (<any>column).renderedWidth = this.getRenderedWidth(column);
    }

    if (this.frozenRows) {
      const frozenRows = _.map(this.frozenRows, (r) => ({ raw: r }));
      this.frozenGridRows = this.processRows(
        frozenRows,
        this.getVisibleColumns(this.frozenColumns).concat(
          this.getVisibleColumns(this.scrollableColumns)
        )
      );
    }
    // Not sure why this was here.  Commenting out seems to have fixed the ever growing columns issue.
    // This is already called above...
    // this.frozenWidth = this.updatefrozenWidth(this.frozenRows, this.frozenColumns);
    this.processVisibleColumnsInRows();
    this.scrollGridToTop();
    this.handleScrollMargin();
  }

  private scrollGridToTop(): void {
    const scrollElements = document.querySelectorAll(
      '.ui-table-scrollable-body'
    );
    if (scrollElements && scrollElements.length) {
      scrollElements[scrollElements.length - 1].scrollTop = 0;
    }
  }

  private processVisibleColumnsInRows(): void {
    const rows = _.map(this.pagedData, (r) => ({ raw: r }));
    this.gridRows = this.processRows(
      rows,
      this.getVisibleColumns(this.frozenColumns || []).concat(
        this.getVisibleColumns(this.scrollableColumns)
      )
    );
  }

  /**
   * Prevents the frozen columns from being smaller than the required space for the
   * frozen row title.
   */
  private adjustFrozenColumnsWidth() {
    // strip the 'px' off the end of the frozenWidth string
    const frozenWidthInt: number = parseInt(
      this.frozenWidth.split('px')[0],
      10
    );
    let totalFrozenColumnsWidth = 0;
    this.frozenColumns.forEach((fcol) => {
      fcol.columns.forEach((col) => {
        if (col.visible) {
          totalFrozenColumnsWidth +=
            col.width || col.minWidth || DEFAULT_MIN_COLUMN_WIDTH;
        }
      });
    });
    if (frozenWidthInt > totalFrozenColumnsWidth) {
      // Add 15px for the arrow icon in expandable rows
      this.frozenColumns[0].columns[0].width +=
        frozenWidthInt - totalFrozenColumnsWidth + 15;
    }
  }

  private applyLayout = () => {
    this.groups = [];
    this.gridConfig.groups
      .filter((x) => x.visible)
      .forEach((group) => {
        const columnGroup = {
          visible: group.visible,
          expandable: group.expandable,
        };

        this.groups.push(columnGroup);

        if (group.locked) {
          const g = _.cloneDeep(group);
          // pull the hidden columns out of the group entirely.
          g.columns = group.columns.filter((c) => c.visible !== false);
          // if (g.columns.length < 2) {
          //   g.columns = g.columns.map((item) => {
          //     item.isMovable = false;
          //     return item;
          //   });
          // }
          this.frozenColumns.push(g);
        } else {
          this.scrollableColumns.push(group);
        }
      });
  };

  private processRows = (
    data: Array<IDataRow>,
    columns: Array<IColumnConfig>,
    parent?: any
  ): Array<any> => {
    const rows = [];

    data.forEach((row: IDataRow, rowIndex) => {
      const rowData = {
        raw: row.raw,
        children: null,
        childRows: null,
        childLoading: false,
        expanded: false,
        isExpandable: true,
      };

      rowData[this.dataKey] = row.raw[this.dataKey];

      columns.forEach((col: IColumnConfig, columnIndex) => {
        rowData[col.sortColumn] = this.getCell(
          rowData,
          col,
          parent,
          rowIndex,
          columnIndex
        );
      });

      if (this.gridConfig?.options?.hooks?.afterRowConfigHook) {
        rows.push(this.gridConfig.options.hooks.afterRowConfigHook(rowData));
      } else {
        rows.push(rowData);
      }
    });

    return rows;
  };

  private ensureChildrenLoaded(row: any): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;
    if (!row.childRows) {
      if (!row.children) {
        row.childLoading = true;
        return this.childrenFactory(row.raw).pipe(
          map((children) => {
            const data = _.map(children, (c) => <IDataRow>{ raw: c });
            row.children = data;
            row.childRows = component.processRows(
              row.children,
              component
                .getVisibleColumns(component.frozenColumns || [])
                .concat(
                  component.getVisibleColumns(component.scrollableColumns)
                ),
              row.raw
            );
            row.childLoading = false;
            return row.childRows;
          })
        );
      }

      row.childRows = this.processRows(
        row.children,
        this.getVisibleColumns(this.frozenColumns || []).concat(
          this.getVisibleColumns(this.scrollableColumns)
        ),
        row.raw
      );
    }

    return of(row.childRows);
  }

  getHeaderClass(column: IColumnConfig): string {
    if (column.headerRowClassFactory) {
      return column.headerRowClassFactory(column);
    }
    return '';
  }

  private getCell = (
    rowData: IDataRow,
    column: IColumnConfig,
    parent: any,
    rowIndex: number,
    columnIndex: number
  ): any => {
    const value = this.getFieldValue(column, rowData.raw);
    const cell = {
      value,
      class: column.classFactory
        ? column.classFactory(value, rowData.raw, column)
        : null,
      style: column.cellStyle,
      width: column.width ? `${column.width}px` : `110px`,
      href: column.urlFactory
        ? column.urlFactory(value, rowData.raw, parent)
        : null,
      config: column,
      linkDsl: column.linkDsl
        ? column.linkDsl(value, rowData.raw, parent)
        : null,
      type: column.type,
    };

    if (!_.isNil(parent) && column.childConfig) {
      if (column.childConfig.urlFactory) {
        cell.href = column.childConfig.urlFactory(value, rowData.raw, parent);
      }

      if (column.childConfig.linkDsl) {
        cell.linkDsl = column.childConfig.linkDsl(value, rowData.raw, parent);
      }
    }

    return cell;
  };

  private getFieldValue = (columnConfig: IColumnConfig, record: any): any => {
    const fieldValue = record[columnConfig.field];

    if (columnConfig.valueFactory) {
      return columnConfig.valueFactory(fieldValue, record);
    }

    return fieldValue;
  };

  private clearFirstChildInExpandedRow = () => {
    setTimeout(() => {
      const rows = $('.ui-table-frozen-view .expansion-row');
      for (const r of rows) {
        r.firstChild.innerText = '';
      }
    }, 0);
  };

  changeSort($event) {
    this.resetCountColumnsWidth($event.field);
  }

  public translate(text: string) {
    return this.formatService.translateAndFormat(text, true);
  }

  resetCountColumnsWidth(currentSorting) {
    _.forIn(
      this.getVisibleColumns(this.frozenColumns),
      (column: IColumnConfig) => {
        // TODO: It would be nice to know why we have to deal with these fields specifically
        //  and not all fields.
        const specialFields = [
          'TransactionCount',
          'SalesRepCount',
          'CustomerCount',
          'ProductTypeCount',
          'LocationCount',
          'DistrictCount',
        ];

        // This is replacing the hard coded if else below
        if (specialFields.includes(column.field)) {
          const func: () => { width: number | null } =
            this.columnDefinitionService[column.field];
          const width = func ? func()?.width : 0;
          column.width = width;
        }

        if (column.field === currentSorting) {
          column.width += 15;
        }
      }
    );
  }

  private prepareTwice() {
    this.prepare();
    setTimeout(() => {
      this.configureDoubleLinedColumns(
        this.getVisibleColumns(this.scrollableColumns)
      );
      this.prepare();
    }, 1);
  }

  getRenderedWidth(column: any) {
    if (!column.elementRef) {
      const query = `#${column.sortColumn}>div>div`;
      const elementRef = document.querySelector(query);
      column.elementRef = elementRef;
    }
    if (column.elementRef && column.elementRef.offsetWidth) {
      return column.elementRef.offsetWidth;
    } else {
      return column.width ? column.width : DEFAULT_MIN_COLUMN_WIDTH;
    }
  }

  getGroupTooltip(obj: any) {
    return this.tooltipsService.getGridTooltip(obj.tooltip || obj.title);
  }

  getColumnTooltip(obj: any) {
    let field = '';
    if (obj && obj.minuend && obj.subtrahend) {
      field = obj.sortColumn;
    } else {
      field = obj.field;
    }
    const result = field.replace(/([A-Z])/g, ' $1');
    if (result) {
      return this.tooltipsService.getGridTooltip(
        obj.tooltip || result.split(' ').join('_').toLowerCase().substring(1)
      );
    }
    return null;
  }

  renderHeader(defaultDom: string) {
    return defaultDom;
  }
}
