<rdo-overlay [enabled]="loading"></rdo-overlay>
<rdo-grid-table-expanded-ng #salesRepsGrid *ngIf="selectedProductTypeService.isProductSelected && data && totalCount > 0"
                            [name]="gridName"
                            [dataKey]="dataKey"
                            [gridConfig]="gridConfig"
                            [rowExpansion]="gridConfig.rowExpansion"
                            [pagedData]="data"
                            [frozenRows]="frozenRows"
                            [sorting]="sorting"
                            [(paging)]="paging"
                            (pageOnChange)="changePage($event)"
                            (lazyLoadOnChange)="changeLazyLoad($event)"
                            [totalCount]="totalCount"
                            [frozenWidth]="frozenWidth"
                            [showColumnSelector]="true"
                            [scrollScale]="'smallScale'"
                            (downloadExcelClick)="exportExcel($event)">
</rdo-grid-table-expanded-ng>

<div class="no-data-available" *ngIf="!selectedProductTypeService.isProductSelected || totalCount === 0">
        {{'main.tabs.equipment.product_types.no_data_available' | translate}}
</div>
