import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
declare let truste: any;

@Injectable({providedIn: 'root'})
export class CookieConsentService {
  private readonly FORCE_CCM = 'FORCE_CCM';
  public watchingForBlackBar: boolean = false;
  public showBlackBar = new BehaviorSubject<boolean>(true);
  public cookiesAccepted = new BehaviorSubject<boolean>(false);
  public blackBarIsActuallyVisible = new BehaviorSubject<boolean>(true);

  constructor() { }

  public getForcedCcm(): string {
    return localStorage && localStorage.getItem(this.FORCE_CCM);
  }

  /**
   * Calls a script from consent.trustarc.com declared
   * in the index.html file to show the cookies consent,
   * or hides it otherwise, depending on the given parameter.
   */
  public showModalAndBlackBar(show: boolean): void {
    if(show && truste && truste.eu && truste.eu.clickListener) {
      truste.eu.clickListener();
      this.handleClose();
    }
    this.showBlackBar.next(show);
  }

  /**
   * Executed a given callback function when the given
   * element is no longer present in the DOM.
   */
  public observeMutation(element, callback) {
    new MutationObserver(function(mutations) {
      if(!document.body.contains(element)) {
        callback();
        this.disconnect();
      }
    }).observe(element.parentElement, {childList: true});
  }

  /**
   * Sets the ngIf variable showBlackBar to false when 
   * closing the modal popup.
   */
  public handleClose() {
    // eslint-disable-next-line
    const vm = this;
    setTimeout(() => { // Using a timeout to wait for the third party modal  to render
      const elements = document.getElementsByClassName('truste_box_overlay');
      const element = elements && elements.length && elements[0];
      vm.observeMutation(element, function() {
        vm.showBlackBar.next(false);
      });
    }, 500);
  }

  public watchForBlackBar(secondsLeft: number = 60) {
    this.watchingForBlackBar = true;
    setTimeout(() => {
      const previousVisibilityValue = this.blackBarIsActuallyVisible.value;
      const blackBarElement = (<any>document.querySelector('#truste-consent-track'));
      const blackBarIsVisible = blackBarElement && blackBarElement.style && blackBarElement.style.display !== 'none';
      if(this.blackBarIsActuallyVisible.value !== blackBarIsVisible) {
        this.blackBarIsActuallyVisible.next(blackBarIsVisible);
      }
      if(previousVisibilityValue && !this.blackBarIsActuallyVisible.value && !this.cookiesAccepted.value) {
        this.cookiesAccepted.next(true);
      }
      secondsLeft--;
      if(secondsLeft > 0) {
        this.watchForBlackBar(secondsLeft);
      } else {
        this.watchingForBlackBar = false;
      }
    }, 1000);
  }

}
