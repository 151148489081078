﻿<div *ngIf="gridRows">
	<div class="ui-table-missing-scrollable-header"></div>
	<p-table [value]="gridRows" [styleClass]="'rdo-ui-table'" [rowHover]="false" [sortMode]="'single'"
		[sortField]="sorting.sortField" [sortOrder]="sorting.sortOrder" [scrollable]="scrollable"
		[scrollHeight]="scrollHeight" [frozenValue]="frozenGridRows" [frozenWidth]="frozenWidth"
		[columns]="scrollableColumns" [frozenColumns]="frozenColumns" [customSort]="true" [lazy]="true"
		(onLazyLoad)="onLazyLoad($event)" (onSort)="changeSort($event)" [style]="getDynamicTableStyle()">

		<ng-template pTemplate="caption">
			<div>
				<rdo-column-selector *ngIf="showColumnSelector && !useProductTypeColumnSelector && !useConfiguredColumnSelector" [size]="'md'"
					[gridConfig]="gridConfig" (visibilityChanged)="saveVisibility($event)"
					(downloadExcelClick)="downloadExcelClick.next(gridConfig)">
				</rdo-column-selector>

				<rdo-column-selector-product-type *ngIf="showColumnSelector && useProductTypeColumnSelector"
					[size]="'md'" [gridConfig]="gridConfig" (visibilityChanged)="saveVisibility()"
					(downloadExcelClick)="downloadExcelClick.next(gridConfig)">
				</rdo-column-selector-product-type>

                <rdo-configured-column-selector
                        *ngIf="showColumnSelector && useConfiguredColumnSelector"
                        [size]="'md'"
                        [gridConfig]="columnSelectorConfig || gridConfig "
                        (visibilityChanged)="saveVisibility()"
                        (downloadExcelClick)="downloadExcelClick.next(gridConfig)"
                >
                </rdo-configured-column-selector>
			</div>
		</ng-template>

		<ng-template pTemplate="colgroup" let-columns>
			<ng-container *ngFor="let group of columns">
				<ng-container *ngFor="let column of group.columns">
					<colgroup>
						<col [style.width]="getWidthStyle(column)" />
					</colgroup>
				</ng-container>
			</ng-container>
		</ng-template>

		<ng-template pTemplate="header" let-columns>
			<tr>
				<th *ngFor="let group of columns" [style.width]="getColGroupWidth(group)"
					[attr.colspan]="group.columns.length" [class.expandable]="group.expandable"
					[class.key-column]="isKeyColumn(group)" [class.group-end]="isGroupEnd(group)"
					id="{{group.title | rdoPathToId}}" class="groupHeader" [ngStyle]="group.headerStyle">
					<div data-toggle="tooltip" tooltip="{{getGroupTooltip(group)}}"
						[attr.title]="getGroupTooltip(group) ? null : translate(group.title)">{{translate(group.title)}}
					</div>
					<a *ngIf="group.forceCanClose !== false && (!group.locked || group.forceCanClose) && (group.text || group.columns.length > 1)"
						(click)="toggleColumnVisibility(group)"
						title="{{'main.core.tables.common.hide_column_group' | translate}}">
						<span class="fa fa-lg fa-times-circle pull-right"></span>
					</a>
				</th>
			</tr>

			<tr>
				<ng-container *ngFor="let group of columns">
					<th *ngFor="let column of group.columns; let columnIndex = index" id="{{column.sortColumn}}"
						[pSortableColumn]="filterSortableColumns(column)" [ngStyle]="column.headerStyle"
						[style.width.px]="ceil(column.width) || 110" [class.group-end]="isHeaderGroupEnd(group, column)"
						style="white-space:nowrap;" class="ellipsed-th">
						<div class="ellipsed-div">
							<!--[ngStyle]="{'line-height': (!group.text && group.columns.length == 1) || !allowDoubleHeader ? '2.9em' : 'unset' }"-->
							<div [ngStyle]="{'line-height': !allowDoubleHeader ? '2.9em' : 'unset' }"
								[attr.title]="getColumnTooltip(column) ? null : translate(column.title)"
								tooltip="{{getColumnTooltip(column)}}">{{(column |
								rdoEllipsis:column.renderedWidth).processedTitle}}</div>
							<p-sortIcon [field]="column.sortColumn"></p-sortIcon>
							<a *ngIf="group.forceCanClose !== false && (!group.locked || group.forceCanClose) && (!group.text && group.columns.length === 1)"
								(click)="toggleColumnVisibility(group, column)"
								title="{{'main.core.tables.common.hide_column_group' | translate}}">
								<span class="fa fa-lg fa-times-circle pull-right"></span>
							</a>
						</div>
					</th>
				</ng-container>
			</tr>
		</ng-template>

		<ng-template pTemplate="frozenrows" let-rowData let-columns="columns" let-rowIndex="rowIndex">
			<tr class="frozen-row">
				<ng-container *ngFor="let group of columns">
					<td id="header-{{column.sortColumn}}-cell" *ngFor="let column of group.columns; let i = index"
						[ngClass]="rowData[column.sortColumn].class" [ngStyle]="rowData[column.sortColumn].style"
						[class.group-end]="isCellGroupEnd(group, column)"
						[class.transparent-text]="setTransparentText(group,columns)"
						[style.width]="rowData[column.sortColumn].width"
						title="{{handleValue(rowData[column.sortColumn].value)}}">
						<i *ngIf="!rowData[column.sortColumn].href && !rowData[column.sortColumn].linkDsl && isBool(rowData[column.sortColumn].value) && rowData[column.sortColumn].value"
							class="fa fa-check"></i>
						<i *ngIf="rowData[column.sortColumn].downloadFn"
							(click)="callDownloadFn(rowData[column.sortColumn].config, rowData.raw)"
							class="fa fa-download"></i>
						<a *ngIf="rowData[column.sortColumn].clickLinkFn"
							(click)="callclickLinkFn(rowData[column.sortColumn].config, rowData.raw)">{{rowData[column.sortColumn].value}}</a>
						<a *ngIf="rowData[column.sortColumn].linkDsl"
							(click)="clickCellLink(rowData[column.sortColumn].config, rowData.raw)"
							[routerLink]="rowData[column.sortColumn].linkDsl">{{rowData[column.sortColumn].value}}</a>
						<a *ngIf="rowData[column.sortColumn].href && !rowData[column.sortColumn].linkDsl"
							(click)="clickCellLink(rowData[column.sortColumn].config, rowData.raw)"
							href="{{rowData[column.sortColumn].href}}">{{rowData[column.sortColumn].value}}</a>
						<span id="header-{{column.sortColumn}}-cell-text"
							*ngIf="!rowData[column.sortColumn].href && !rowData[column.sortColumn].linkDsl && !isBool(rowData[column.sortColumn].value) && !rowData[column.sortColumn].downloadFn && !rowData[column.sortColumn].clickLinkFn">{{translate(rowData[column.sortColumn].value)}}</span>
					</td>
				</ng-container>
			</tr>
		</ng-template>

		<ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
			<tr (mouseenter)="onMouseEnter(rowData)" (mouseleave)="onMouseLeave(rowData)"
				[class.row-hover]="rowData.hover">
				<ng-container *ngFor="let group of columns">
					<td id="detail-{{column.sortColumn}}-{{rowIndex}}"
						*ngFor="let column of group.columns; let i = index" [ngClass]="rowData[column.sortColumn].class"
						[ngStyle]="rowData[column.sortColumn].style" [style.width]="rowData[column.sortColumn].width"
						[class.group-end]="isCellGroupEnd(group, column)"
						title="{{handleValue(rowData[column.sortColumn].value)}}">
						<i *ngIf="!rowData[column.sortColumn].href && !rowData[column.sortColumn].linkDsl && isBool(rowData[column.sortColumn].value) && rowData[column.sortColumn].value"
							class="fa fa-check"></i>
						<i *ngIf="rowData[column.sortColumn].downloadFn" style="cursor: pointer;"
							(click)="callDownloadFn(rowData[column.sortColumn].config, rowData.raw)"
							class="fa fa-download"></i>
						<a *ngIf="rowData[column.sortColumn].clickLinkFn"
							(click)="clickLinkFn(rowData[column.sortColumn].config, rowData.raw)">{{rowData[column.sortColumn].value}}</a>
						<a *ngIf="rowData[column.sortColumn].linkDsl"
							(click)="clickCellLink(rowData[column.sortColumn].config, rowData.raw)"
							[routerLink]="rowData[column.sortColumn].linkDsl">{{rowData[column.sortColumn].value}}</a>
						<a *ngIf="rowData[column.sortColumn].href && !rowData[column.sortColumn].linkDsl"
							(click)="clickCellLink(rowData[column.sortColumn].config, rowData.raw)"
							href="{{rowData[column.sortColumn].href}}">{{rowData[column.sortColumn].value}}</a>
						<span
							*ngIf="!rowData[column.sortColumn].href && !rowData[column.sortColumn].linkDsl && !isBool(rowData[column.sortColumn].value) && !rowData[column.sortColumn].downloadFn && !rowData[column.sortColumn].clickLinkFn">{{translate(rowData[column.sortColumn].value)}}</span>
					</td>
				</ng-container>
			</tr>
		</ng-template>

	</p-table>

	<p-paginator #gridPaginator id="gridPaginator"
		[styleClass]="fixedScrollableHeight ? 'rdo-paginator rdo-head-paginator' : 'rdo-paginator'"
		[(first)]="paging.first" [rowsPerPageOptions]="[10,20,50]" [pageLinkSize]="10" [rows]="paging.pageSize"
		[totalRecords]="totalCount" (onPageChange)="onPage($event)" [alwaysShow]="alwaysShowPager">
	</p-paginator>

</div>
<div id="dummyDivForLength" #dummy style="padding: 3px 6px; position: absolute; left: -100px; top: -100px">
</div>
