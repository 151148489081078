import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rdo-grid-message',
  templateUrl: './grid-message.component.html',
})
export class GridMessageComponent {
  message = '';
  title = '';
  isVisible = false;

  constructor(private translationService: TranslateService) {
    this.title =  this.translationService.instant('main.tabs.changes_log.error');
  }

  showMessageDialog = (message: string, title?: string, ) => {
    this.message = message;
    // eslint-disable-next-line no-unused-expressions
    title ? this.title = title : this.title = this.translationService.instant('main.tabs.changes_log.error');
    this.isVisible = true;
  }

  closeMessageDialog() {
    this.message = '';
    this.title = '';
    this.isVisible = false;
  }
}



