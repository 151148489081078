import { Injectable, inject } from '@angular/core';
import { InjectionToken } from '@angular/core';
import * as Sentry from '@sentry/angular';

import { MetricsHttpService } from '../core/http/metrics-http.service';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

type InjectionWindow = Window & typeof globalThis;

/**
 * This is an artifact of writing tests and having trouble mocking the window. Leaving in for now.
 */
export const WINDOW: InjectionToken<InjectionWindow> =
  new InjectionToken<InjectionWindow>('Global window object', {
    factory: () => window,
  });

type MaintenanceModeEvent = {
  window_start: string; // "2024-04-09 23:30:24.739000+00:00"
  window_end: string; // "2024-04-09 23:30:24.739000+00:00"
};

interface IMaintenanceMode {
  initialize(): void;
  data: any;
  isActive: boolean;
  isLoaded: boolean;
  nextEndTime: string | undefined;
}

export const SCHEMA_ERROR = 'Maintenance mode date are in an incorrect schema';
export const METRICS_ENDPOINT = 'app-config/maintenance-windows';
const TIMER_DURATION = 10000;

/**
 * Check to see if a date is between a start and an end date.
 * @param testDateTime: number UTC of current time.
 * @param start: string representing UTC date time.
 * @param end: string representing UTC date time.
 */
export function checkDateOverlap(
  testDateTime: number,
  start: string,
  end: string
): boolean {
  const startDate = Date.parse(start);
  const endDate = Date.parse(end);
  return testDateTime >= startDate && testDateTime <= endDate;
}

@Injectable({
  providedIn: 'root',
})
export class MaintenanceModeService implements IMaintenanceMode {
  public data: any = null;
  public isActive: boolean = false;
  public isInitialized: boolean = false;
  public isLoaded: boolean = false;
  // This is tracking reloading to hopefully cleanup screen when the reload event is triggered.
  public isReloading: boolean = false;
  public window: (Window & typeof globalThis) | null;
  public mxTitleDef: string = 'main.maintenance.title';
  public mxDescirptionDef: string = 'main.maintenance.description';
  public mxNavigationDef: string = 'main.navigation.sign_out';
  public mxTitle: string = '';
  public mxDescription: string = '';
  public mxNavigation: string = '';
  public hasTranslations: boolean = false;
  private nextMaintenanceEvent: MaintenanceModeEvent | null = null;
  private intervalId: number = -1;
  private timeoutId: number = -1;

  constructor(
    public metricsHttp: MetricsHttpService,
    public authService: AuthenticationService,
    public httpClient: HttpClient,
    public translationService: TranslateService
  ) {
    this.window = inject(WINDOW);
    this.authService.AuthTokenObtained.subscribe(
      () => {
        this.initialize();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  public initialize(): void {
    this.isInitialized = true;
    this.load();
  }

  private load(): void {
    this.metricsHttp.get(METRICS_ENDPOINT).subscribe(
      (resp) => {
        this.data = resp;
        this.isLoaded = true;
        try {
          this.setNextEvent();
        } catch (error) {
          this.handleError(error);
        }
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  public getStaticTranslations(
    lang: string,
    accessToken: any
  ): Observable<any> {
    let result = new Observable();
    let url = environment.apiUrl;
    url = url.charAt(url.length) === '/' ? url : url + '/';
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${accessToken}`
    );
    const currentLang = lang ?? 'en-US';
    const endpoint =
      url + `localization/static-translations?locale=${currentLang}`;
    result = this.httpClient.get(endpoint, {
      headers: headers,
      withCredentials: true,
    });
    return result;
  }

  public startTimer(d: string): void {
    const targetStartTime = Date.parse(d);
    const timeDifference = targetStartTime - Date.now();
    if (timeDifference >= 0) {
      // only create a timer for the first item that is in the future.
      // eslint-disable-next-line
      // @ts-ignore
      this.timeoutId = window.setTimeout(
        () => this.setActiveState(),
        timeDifference
      );
    }
  }

  private setNextEvent(): void {
    if (!this.data?.length) {
      return;
    }
    const now = Date.now();
    const result: MaintenanceModeEvent | undefined = this.data.find(
      (dateObject) => {
        if (!dateObject?.window_start || !dateObject?.window_end) {
          throw new Error(SCHEMA_ERROR);
        }
        const endTime = Date.parse(dateObject.window_end);
        return endTime - now > 0;
      }
    );

    if (result) {
      this.nextMaintenanceEvent = result;
      this.startTimer(this.nextMaintenanceEvent.window_start);
      // this can work, but is problematic because it has to be precise - using interval instead.
      // this.startTimer(this.nextEndTime);
      this.setActiveState();
    }
  }

  get nextEndTime(): string | undefined {
    return this.nextMaintenanceEvent.window_end;
  }

  /**
   * Initially, I was trying to remove all of the content from the dom when window was present.
   * The problem was that this was causing errors in the filters component when the app booted directly
   * into a maintenance period. I'm not sure why those components even initialize, but they did...
   * Now we're just hiding content.
   */
  public get isContentVisible(): boolean {
    return this.isLoaded && (!this.isActive || !this.isReloading);
  }

  private setActiveState(): void {
    if (!this.data?.length) {
      return;
    }
    const now = Date.now();
    let result: boolean;
    try {
      result = this.data.some((dateObject) => {
        if (!dateObject?.window_start || !dateObject?.window_end) {
          throw new Error(SCHEMA_ERROR);
        }
        if (
          checkDateOverlap(now, dateObject.window_start, dateObject.window_end)
        ) {
          this.mxTitle = this.translationService.instant(this.mxTitleDef);
          this.mxDescription = this.translationService.instant(
            this.mxDescirptionDef
          );
          this.mxNavigation = this.translationService.instant(
            this.mxNavigationDef
          );
          if (this.mxTitle === this.mxTitleDef) {
            this.getStaticTranslations(
              this.translationService.currentLang,
              this.authService.tokenInfo.AccessToken
            ).subscribe((result) => {
              this.mxTitle = result.main.maintenance.title;
              this.mxDescription = result.main.maintenance.description;
              this.mxNavigation = result.main.navigation.sign_out;
              this.hasTranslations = true;
            });
          } else {
            this.hasTranslations = true;
          }
          return true;
        }
      });
    } catch (error) {
      this.handleError(error);
    }

    // If the user was in a maintenance window and should leave it, refresh the page.
    if (this.isActive && !result) {
      this.isReloading = true;
      this.window.location.reload();
      return;
    }

    if (result && !this.isActive) {
      this.intervalId = window.setInterval(
        () => this.setActiveState(),
        TIMER_DURATION
      );
      this.isActive = true;
    }
  }

  private handleError(error): void {
    Sentry.captureException(error);
  }

  public destroy() {
    if (this.intervalId > -1) {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }
    if (this.timeoutId > -1) {
      this.timeoutId = -1;
      clearTimeout(this.timeoutId);
    }
  }
}
