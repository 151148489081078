import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreadcrumbService, InstructionInfo } from './breadcrumb.service';
import { FormatService } from '../../query';

@Component({
    selector: 'rdo-breadcrumb',
    templateUrl: 'breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
    private subscription: any;
    instructions: Array<InstructionInfo>;

    constructor(
        private breadcrumbService: BreadcrumbService,
        private formatService: FormatService
    ) { }

    ngOnInit() {
        this.instructions = this.breadcrumbService.instructions;
        this.subscription = this.breadcrumbService.onChange.subscribe(i => {
            this.instructions = i;
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    translateTitle(title: string) {
        return this.formatService.translateAndFormat(title);
    }
}
