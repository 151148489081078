import { Injectable } from '@angular/core';
import { FilterSlider } from '../models/filter-slider';
import { FilterLabel } from '../../models';
import { BehaviorSubject } from 'rxjs';
declare let $: any;

@Injectable()
export class FilterSliderService {
  private filterLabels: any; // TODO: Merge both label groups
  private labels: Array<FilterLabel>;
  private readonly GEO_SLIDER_ID = 'geo-slider';
  private readonly CYCLE_BILL_SLIDER_ID = 'cycle-bill-slider';
  
  public geoSlider = new BehaviorSubject<FilterSlider>(null);
  public cycleBillSlider = new BehaviorSubject<FilterSlider>(null);

  constructor() { }

  public setFilterLabels(labels: Array<FilterLabel>) {
    this.labels = labels;
  }

  public setAnyLabels(labels: any) {
    this.filterLabels = labels;
  }

  /**
   * Sets up the Geo Slider for the filters component. 
   */
  public setGeoSlider = (low: number, high: number) => {
    const geoSliderFilter = new FilterSlider(this.GEO_SLIDER_ID, low, high);
    const alllabels: any = [];
    for (const label of this.labels) {
      alllabels[label.CodeName] = label.DisplayName;
    }
    let sliderlabels = [
      alllabels['filter.geography.benchmark-geography.range1'],
      alllabels['filter.geography.benchmark-geography.range2'],
      alllabels['filter.geography.benchmark-geography.range3'],
      alllabels['filter.geography.benchmark-geography.range4']
    ];
    const tmp: any = $(`#${geoSliderFilter.id}`);
    sliderlabels = this.toStyledLabels(sliderlabels);
    if (!this.geoSlider.value) { // control gets created one time
      geoSliderFilter.domElement = tmp.slider({
        min: 1,
        max: 4,
        step: 1,
        value: [low, high],
        ticks: [1, 2, 3, 4],
        ticks_labels: sliderlabels,
        handle: 'square',
        tooltip: 'hide'
      }).on('change', e => {
        this.geoSlider.value.low = e.value.newValue[0];
        this.geoSlider.value.high = e.value.newValue[1];
        this.geoSlider.next(this.geoSlider.value); // trigger update
      });
    } else {
      tmp.slider('setValue', [low, high]);
      try { // todo - figure out how this components facilitates label updates for now jquery it
        $('.slider-tick-label')[0].innerHTML = sliderlabels[0];
        $('.slider-tick-label')[1].innerHTML = sliderlabels[1];
        $('.slider-tick-label')[2].innerHTML = sliderlabels[2];
        $('.slider-tick-label')[3].innerHTML = sliderlabels[3];
      } catch { }
    }
    geoSliderFilter.low = low;
    geoSliderFilter.high = high;
    try {
      geoSliderFilter.domElement.slider('setValue', [low, high]);
    } catch { }
    this.geoSlider.next(geoSliderFilter);
  }

  public setCycleBillSlider = (low: number, high: number) => {
    const cycleBillSliderFilter = new FilterSlider(this.CYCLE_BILL_SLIDER_ID, low, high);
    let labels = [
        this.filterLabels['filter.transaction.cycle-bill.range1'],
        this.filterLabels['filter.transaction.cycle-bill.range2'],
        this.filterLabels['filter.transaction.cycle-bill.range3'],
        this.filterLabels['filter.transaction.cycle-bill.range4'],
        this.filterLabels['filter.transaction.cycle-bill.range5']
    ];
    const tmp: any = $(`#${this.CYCLE_BILL_SLIDER_ID}`);
    labels = this.toStyledLabels(labels);
    if (!this.cycleBillSlider.value) {
      cycleBillSliderFilter.domElement = tmp.slider({
            min: 1,
            max: 5,
            step: 1,
            value: [low, high],
            ticks: [1, 2, 3, 4, 5],
            ticks_labels: labels,
            handle: 'square',
            tooltip: 'hide'
        }).on('change', e => {
          this.cycleBillSlider.value.low = e.value.newValue[0];
          this.cycleBillSlider.value.high = e.value.newValue[1];
          this.cycleBillSlider.next(this.cycleBillSlider.value);
        });
    } else {
      tmp.slider('setValue', [low, high]);
    }
    cycleBillSliderFilter.low = low;
    cycleBillSliderFilter.high = high;
    try {
      cycleBillSliderFilter.domElement.slider('setValue', [low, high]);
    } catch { }
    this.cycleBillSlider.next(cycleBillSliderFilter);
  }


  private toStyledLabels(labelsArray: string[]): string[] {
    const newLabels = [];
    labelsArray.forEach((label, index) => {
        let styleClass = 'rdo-slider-label';
        styleClass = index === 0 ? styleClass + ' rdo-slider-label-first' : styleClass;
        styleClass = index === labelsArray.length - 1 ? styleClass + ' rdo-slider-label-last' : styleClass;
        newLabels.push(
            '<div class=\'' + styleClass + '\' title=\'' + label + '\'>' + label + '</div>');
    });
    return newLabels;
  }
}
