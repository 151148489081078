
export var created = 'create';

export var destroyed = 'destroy';

export var load = 'load';

export var rowDataBound = 'rowDataBound';

export var queryCellInfo = 'queryCellInfo';

export var headerCellInfo = 'headerCellInfo';

export var actionBegin = 'actionBegin';

export var actionComplete = 'actionComplete';

export var actionFailure = 'actionFailure';

export var dataBound = 'dataBound';

export var rowSelecting = 'rowSelecting';

export var rowSelected = 'rowSelected';

export var rowDeselecting = 'rowDeselecting';

export var rowDeselected = 'rowDeselected';

export var cellSelecting = 'cellSelecting';

export var cellSelected = 'cellSelected';

export var cellDeselecting = 'cellDeselecting';

export var cellDeselected = 'cellDeselected';

export var columnSelecting = 'columnSelecting';

export var columnSelected = 'columnSelected';

export var columnDeselecting = 'columnDeselecting';

export var columnDeselected = 'columnDeselected';

export var columnDragStart = 'columnDragStart';

export var columnDrag = 'columnDrag';

export var columnDrop = 'columnDrop';

export var rowDragStartHelper = 'rowDragStartHelper';

export var rowDragStart = 'rowDragStart';

export var rowDrag = 'rowDrag';

export var rowDrop = 'rowDrop';

export var beforePrint = 'beforePrint';

export var printComplete = 'printComplete';

export var detailDataBound = 'detailDataBound';

export var toolbarClick = 'toolbarClick';

export var batchAdd = 'batchAdd';

export var batchCancel = 'batchCancel';

export var batchDelete = 'batchDelete';

export var beforeBatchAdd = 'beforeBatchAdd';

export var beforeBatchDelete = 'beforeBatchDelete';

export var beforeBatchSave = 'beforeBatchSave';

export var beginEdit = 'beginEdit';

export var cellEdit = 'cellEdit';

export var cellSave = 'cellSave';

export var cellSaved = 'cellSaved';

export var endAdd = 'endAdd';

export var endDelete = 'endDelete';

export var endEdit = 'endEdit';

export var recordDoubleClick = 'recordDoubleClick';

export var recordClick = 'recordClick';

export var beforeDataBound = 'beforeDataBound';

export var beforeOpenColumnChooser = 'beforeOpenColumnChooser';

export var beforeOpenAdaptiveDialog = 'beforeOpenAdaptiveDialog';

export var resizeStart = 'resizeStart';

export var onResize = 'resizing';

export var resizeStop = 'resizeStop';

export var checkBoxChange = 'checkBoxChange';

export var beforeCopy = 'beforeCopy';

export var beforePaste = 'beforePaste';

export var beforeAutoFill = 'beforeAutoFill';

export var filterChoiceRequest = 'filterchoicerequest';

export var filterAfterOpen = 'filterafteropen';

export var filterBeforeOpen = 'filterbeforeopen';

export var filterSearchBegin = 'filtersearchbegin';

export var commandClick = 'commandClick';

export var exportGroupCaption = 'exportGroupCaption';

export var lazyLoadGroupExpand = 'lazyLoadGroupExpand';

export var lazyLoadGroupCollapse = 'lazyLoadGroupCollapse';
/**
 * Specifies grid internal events
 */

export var initialLoad = 'initial-load';

export var initialEnd = 'initial-end';

export var dataReady = 'data-ready';

export var contentReady = 'content-ready';

export var uiUpdate = 'ui-update';

export var onEmpty = 'on-empty';

export var inBoundModelChanged = 'inbound-model-changed';

export var modelChanged = 'model-changed';

export var colGroupRefresh = 'colgroup-refresh';

export var headerRefreshed = 'header-refreshed';

export var pageBegin = 'paging-begin';

export var pageComplete = 'paging-complete';

export var sortBegin = 'sorting-begin';

export var sortComplete = 'sorting-complete';

export var filterBegin = 'filtering-begin';

export var filterComplete = 'filtering-complete';

export var searchBegin = 'searching-begin';

export var searchComplete = 'searching-complete';

export var reorderBegin = 'reorder-begin';

export var reorderComplete = 'reorder-complete';

export var rowDragAndDropBegin = 'rowdraganddrop-begin';

export var rowDragAndDropComplete = 'rowdraganddrop-complete';

export var groupBegin = 'grouping-begin';

export var groupComplete = 'grouping-complete';

export var ungroupBegin = 'ungrouping-begin';

export var ungroupComplete = 'ungrouping-complete';

export var groupAggregates = 'group-aggregates';

export var refreshFooterRenderer = 'refresh-footer-rendered';

export var refreshAggregateCell = 'refresh-aggregate-cell';

export var refreshAggregates = 'refresh-aggregates';

export var rowSelectionBegin = 'rowselecting';

export var rowSelectionComplete = 'rowselected';

export var columnSelectionBegin = 'columnselecting';

export var columnSelectionComplete = 'columnselected';

export var cellSelectionBegin = 'cellselecting';

export var cellSelectionComplete = 'cellselected';

export var beforeCellFocused = 'beforecellfocused';

export var cellFocused = 'cellfocused';

export var keyPressed = 'key-pressed';

export var click = 'click';

export var destroy = 'destroy';

export var columnVisibilityChanged = 'column-visible-changed';

export var scroll = 'scroll';

export var columnWidthChanged = 'column-width-changed';

export var columnPositionChanged = 'column-position-changed';

export var rowDragAndDrop = 'row-drag-and-drop';

export var rowsAdded = 'rows-added';

export var rowsRemoved = 'rows-removed';

export var columnDragStop = 'column-drag-stop';

export var headerDrop = 'header-drop';

export var dataSourceModified = 'datasource-modified';

export var refreshComplete = 'refresh-complete';

export var refreshVirtualBlock = 'refresh-virtual-block';

export var dblclick = 'dblclick';

export var toolbarRefresh = 'toolbar-refresh';

export var bulkSave = 'bulk-save';

export var autoCol = 'auto-col';

export var tooltipDestroy = 'tooltip-destroy';

export var updateData = 'update-data';

export var editBegin = 'edit-begin';

export var editComplete = 'edit-complete';

export var addBegin = 'add-begin';

export var addComplete = 'add-complete';

export var saveComplete = 'save-complete';

export var deleteBegin = 'delete-begin';

export var deleteComplete = 'delete-complete';

export var preventBatch = 'prevent-batch';

export var dialogDestroy = 'dialog-destroy';

export var crudAction = 'crud-Action';

export var addDeleteAction = 'add-delete-Action';

export var destroyForm = 'destroy-form';

export var doubleTap = 'double-tap';

export var beforeExcelExport = 'beforeExcelExport';

export var excelExportComplete = 'excelExportComplete';

export var excelQueryCellInfo = 'excelQueryCellInfo';

export var excelHeaderQueryCellInfo = 'excelHeaderQueryCellInfo';

export var exportDetailDataBound = 'exportDetailDataBound';

export var beforePdfExport = 'beforePdfExport';

export var pdfExportComplete = 'pdfExportComplete';

export var pdfQueryCellInfo = 'pdfQueryCellInfo';

export var pdfHeaderQueryCellInfo = 'pdfHeaderQueryCellInfo';

export var accessPredicate = 'access-predicate';

export var contextMenuClick = 'contextMenuClick';

export var freezeRender = 'freezerender';

export var freezeRefresh = 'freezerefresh';

export var contextMenuOpen = 'contextMenuOpen';

export var columnMenuClick = 'columnMenuClick';

export var columnMenuOpen = 'columnMenuOpen';

export var filterOpen = 'filterOpen';

export var filterDialogCreated = 'filterDialogCreated';

export var filterMenuClose = 'filter-menu-close';

export var initForeignKeyColumn = 'initForeignKeyColumn';

export var getForeignKeyData = 'getForeignKeyData';

export var generateQuery = 'generateQuery';

export var showEmptyGrid = 'showEmptyGrid';

export var foreignKeyData = 'foreignKeyData';

export var columnDataStateChange = 'columnDataStateChange';

export var dataStateChange = 'dataStateChange';

export var dataSourceChanged = 'dataSourceChanged';

export var rtlUpdated = 'rtl-updated';

export var beforeFragAppend = 'beforeFragAppend';

export var frozenHeight = 'frozenHeight';

export var textWrapRefresh = 'textWrapRefresh';

export var recordAdded = 'recordAdded';

export var cancelBegin = 'cancel-Begin';

export var editNextValCell = 'editNextValCell';

export var hierarchyPrint = 'hierarchyprint';

export var expandChildGrid = 'expandchildgrid';

export var printGridInit = 'printGrid-Init';

export var exportRowDataBound = 'export-RowDataBound';

export var exportDataBound = 'export-DataBound';

export var rowPositionChanged = 'row-position-changed';

export var columnChooserOpened = 'columnChooserOpened';

export var batchForm = 'batchedit-form';

export var beforeStartEdit = 'edit-form';

export var beforeBatchCancel = 'before-batch-cancel';

export var batchEditFormRendered = 'batcheditform-rendered';

export var partialRefresh = 'partial-refresh';

export var beforeCustomFilterOpen = 'beforeCustomFilterOpen';

export var selectVirtualRow = 'select-virtual-Row';

export var columnsPrepared = 'columns-prepared';

export var cBoxFltrBegin = 'cbox-filter-begin';

export var cBoxFltrComplete = 'cbox-filter-complete';

export var fltrPrevent = 'filter-Prevent';

export var beforeFltrcMenuOpen = 'before-filter-cmenu-open';

export var valCustomPlacement = 'validation-custom-placement';

export var filterCboxValue = 'filter-cbox-value';

export var componentRendered = 'component-rendered';

export var restoreFocus = 'restore-Focus';

export var detailStateChange = 'detail-state-change';

export var detailIndentCellInfo = 'detail-indentcell-info';

export var virtaulKeyHandler = 'virtaul-key-handler';

export var virtaulCellFocus = 'virtaul-cell-focus';

export var virtualScrollEditActionBegin = 'virtual-scroll-edit-action-begin';

export var virtualScrollEditSuccess = 'virtual-scroll-edit-success';

export var virtualScrollEditCancel = 'virtual-scroll-edit-cancel';

export var virtualScrollEdit = 'virtual-scroll-edit';

export var refreshVirtualCache = 'refresh-virtual-cache';

export var editReset = 'edit-reset';

export var virtualScrollAddActionBegin = 'virtual-scroll-add-action-begin';

export var getVirtualData = 'get-virtual-data';

export var refreshInfiniteModeBlocks = 'refresh-infinite-mode-blocks';

export var resetInfiniteBlocks = 'reset-infinite-blocks';

export var infiniteScrollHandler = 'infinite-scroll-handler';

export var infinitePageQuery = 'infinite-page-query';

export var infiniteShowHide = 'infinite-show-hide';

export var appendInfiniteContent = 'append-infinite-content';

export var removeInfiniteRows = 'remove-infinite-rows';

export var setInfiniteCache = 'set-infinite-cache';

export var infiniteEditHandler = 'infinite-edit-handler';

export var initialCollapse = 'initial-collapse';

export var getAggregateQuery = 'get-aggregate-query';

export var closeFilterDialog = 'close-filter-dialog';

export var columnChooserCancelBtnClick = 'columnChooserCancelBtnClick';

export var getFilterBarOperator = 'get-filterbar-operator';

export var resetColumns = 'reset-columns';

export var pdfAggregateQueryCellInfo = 'pdfAggregateQueryCellInfo';

export var excelAggregateQueryCellInfo = 'excelAggregateQueryCellInfo';

export var setGroupCache = 'group-cache';

export var lazyLoadScrollHandler = 'lazy-load-scroll-handler';

export var groupCollapse = 'group-collapse';

export var beforeCheckboxRenderer = 'beforeCheckboxRenderer';

export var refreshHandlers = 'refreshResizeHandlers';

export var refreshFrozenColumns = 'refresh-frozen-columns';

export var setReorderDestinationElement = 'set-reorder-destination-element';

export var refreshVirtualFrozenHeight = 'refresh-virtual-frozen-height';

export var setFreezeSelection = 'set-freeze-selection';

export var setInfiniteFrozenHeight = 'set-infinite-frozen-height';

export var setInfiniteColFrozenHeight = 'set-infinite-col-frozen-height';

export var beforeRefreshOnDataChange = 'before-refresh-on-data-change';

export var immutableBatchCancel = 'immutable-batch-cancel';

export var refreshVirtualFrozenRows = 'refresh-virtual-frozenrows';

export var checkScrollReset = 'check-scroll-reset';

export var refreshFrozenHeight = 'refresh-frozen-height';

export var setHeightToFrozenElement = 'set-height-to-frozen-element';

export var preventFrozenScrollRefresh = 'prevent-frozen-scroll-refresh';

export var nextCellIndex = 'next-cell-index';

export var refreshInfiniteCurrentViewData = 'refresh-infinite-current-view-data';

export var infiniteCrudCancel = 'infinite-crud-cancel';

export var filterDialogClose = 'filter-dialog-close';

export var refreshCustomFilterOkBtn = 'refresh-cutsom-filter-ok-button';

export var refreshCustomFilterClearBtn = 'refresh-cutsom-filter-clear-button';

export var renderResponsiveCmenu = 'render-responsive-cmenu';

export var filterCmenuSelect = 'filter-cmenu-select';

export var customFilterClose = 'custom-filter-close';

export var setFullScreenDialog = 'set-fullscreen-dialog';

export var refreshExpandandCollapse = 'refresh-Expand-and-Collapse';

export var rowModeChange = 'row-mode-change';

export var enterKeyHandler = 'enter-key-handler';

export var refreshVirtualMaxPage = 'refresh-virtual-max-page';

export var setVirtualPageQuery = 'set-virtual-page-query';

export var selectRowOnContextOpen = 'select-row-on-context-open';

export var pagerRefresh = 'pager-refresh';

export var closeInline = 'closeinline';

export var closeBatch = 'closebatch';

export var closeEdit = 'close-edit';

export var resetVirtualFocus = 'reset-virtual-focus';

export var afterContentRender = 'after-content-renderer';

export var refreshVirtualEditFormCells = 'refresh-virtual-editform-cells';

export var scrollToEdit = 'scroll-to-edit';

export var beforeCheckboxRendererQuery = 'before-checkbox-renderer-query';

export var createVirtualValidationForm = 'create-virtual-validation-form';

export var validateVirtualForm = 'validate-virtual-form';

export var destroyChildGrid = 'destroy-child-grid';

export var stickyScrollComplete = 'sticky-scroll-complete';

export var captionActionComplete = 'infinite-group-collapse';

export var refreshInfinitePersistSelection = 'refresh-infinite-persist-selection';
