import { Component, Input, Output, EventEmitter } from '@angular/core';

import { ComparisonMode } from './../../../../app/core/query';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'rdo-nav-card-book',
    templateUrl: 'nav-card-book.component.html'
})
export class NavCardBookComponent {
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('data')
    cardData: any;

    @Input()
    titleSelector: string;

    @Input()
    selected: boolean;

    @Input()
    icon: string;

    @Output()
    selectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    defaultTitle: boolean;

    @Input()
    comparisonMode: ComparisonMode;

    @Input()
    baseId: string;

    private hidden =  false;

    constructor(
        private translateService: TranslateService
    ) {}

    public select() {
        if (!this.selected) {
            this.selected = true;
            this.selectedChange.next(this.selected);
        }
    }

    getBookDiscount(record: any) {
        if(record.RevenueBookDifference && record.RevenueBook) {
            return record.RevenueBookDifference / record.RevenueBook;
        }
        return null;
    }

    private isBenchmarkMode(): boolean {
        return this.comparisonMode === ComparisonMode.Benchmark;
    }
}

