import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { EMPTY, Observable } from 'rxjs'; // /Observable';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';

@Injectable()
export class SvgService {

	private cache: any = {};

	constructor(private http: HttpClient) {
	}
    /**
     * @param path svg path to load
     * @param fallbackPath path to fall back if main svg is not found
     */
	getIconMarkup(path: string, fallbackPath?: string): Observable<any> {
		const markup = this.cache[path];
		if (!markup) {
			return this.loadMarkup(path, fallbackPath);
		}
		return of(document.importNode(markup, true));
	}

	private loadMarkup(path: string, fallbackPath?: string): Observable<object> {
		return this.http
			.get(path, { responseType: 'text' })
			.pipe(
			map(r => {
				return this.processResponse(r, path);
			}),
			catchError(err => {
				if (!fallbackPath) {
					return EMPTY;
				}

				return this.http.get(fallbackPath)
					.pipe(map(r => {
						return this.processResponse(r, path);
					}));
			}));
	}

	private processResponse(r: any, path: string): any {
		const tempHost = document.createElement('div');
		tempHost.innerHTML = r;
		const markup = tempHost.querySelector('svg');
		this.cache[path] = markup;
		return markup;
	}
}
