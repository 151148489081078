import { Injectable } from '@angular/core';
import { Observable } from 'rxjs'; // /Observable';
import { map, catchError } from 'rxjs/operators'; // operators';
import { throwError } from 'rxjs';
import * as _ from 'lodash';
import { RdoHttpService } from '../core/http/rdo-http.service';
import { FormatService } from '../core/query/format.service';
import { Alert } from '../models/alert';
import { GeneralExtendedQuery, UserQuery } from '../models/body';
import { LocaleFormat } from '../models';
import * as Sentry from '@sentry/angular';
import { environment } from '../../environments/environment';

@Injectable()
export class HeaderService {
	public manuallyShowCompetitionDisclaimer = false;
	public showInApplicationGuidanceSettings = false;

	constructor(private rdoHttp: RdoHttpService,
		private formatService: FormatService) {
	}

	public searchProductTypes = (query: string): Observable<any> => {
		return this.search(query, 'equipment');
	}

	public searchCustomers = (query: string): Observable<any> => {
		return this.search(query, 'customers');
	}

	public searchSalesReps = (query: string): Observable<any> => {
		return this.search(query, 'sales-reps');
	}

	public searchAssets = (query: string): Observable<any> => {
		return this.search(query, 'assets');
	}

	public getAlerts = (userId: number): Observable<Array<Alert>> => {
		const url = 'alerts';
		const body = new UserQuery();
		body.UserId = userId;
		return this
			.rdoHttp
			.post(url, body)
			.pipe(map(res => res as Array<Alert>));
	}

	public isLocaleIdEnabledForCompetitionDisclaimer = (localefilterValue: any) => {
		const disabledLocaleIds = environment.disabledDisclaimerLocales.split(',');
		return localefilterValue && !disabledLocaleIds.includes(localefilterValue?.toString());
	}

	public setCompetitionDisclaimerAcceptance = (): Observable<any> => {
		const url = `identity/competitionDisclaimer`;
		return this.rdoHttp.post(url).pipe(
			map(res => res),
			catchError(err => {
				Sentry.captureException(err);
				return throwError(err);
			}));
	}

	public getLocaleFormats = (): Observable<Array<LocaleFormat>> => {

		let result: Observable<Array<LocaleFormat>> = null;

		result = this.rdoHttp.get('/filter/locale-formats').pipe(

			map(r => r as Array<LocaleFormat>),

			catchError(err => {

				Sentry.captureException('header.service:getLocaleFormats failed:', err);

				return throwError(err.error || 'Server error');
			})
		);

		return result;
	};

	public updateAlert = (alert: any): Observable<object> => {
		const url = 'alerts';

		return this.rdoHttp.put(url, alert);
	};

	private mapProperties = (r: object) => {
		return _.map((r as any), (i: any) => {
			i.ClientBenchmarkedRevenueDisplay = this.formatService.formatCurrency(i.ClientBenchmarkedRevenue, false);
			const difference = Math.round(i.ClientBenchmarkedRevenue - i.BenchmarkedRevenue);
			const differencePercent = Math.round((i.BenchmarkedRevenue !== 0 ? (difference / i.BenchmarkedRevenue) : 0) * 100);
			i.BenchmarkedRevenueDifferenceDisplay = `${this.formatService.formatCurrency(difference, false)} / ${differencePercent} %`;
			i.IsBenchmarkedDifferenceBelow = i.ClientBenchmarkedRevenue < i.BenchmarkedRevenue;
			return i;
		});
	};

	private search = (query: string, type: string): Observable<any> => {
		const observableSearch = new Observable((observer) => {
			const pagedSorted = new GeneralExtendedQuery();
			pagedSorted.q = query;
			pagedSorted.PageSize = 5;
			pagedSorted.OrderBy = 'RevenueBenchmarked';
			pagedSorted.OrderByDirection = 'DESC';

			if (type === 'equipment') {
				pagedSorted.IncludeTotal = false;
				pagedSorted.IncludeGrowth = false;
			}
			const httpCall = this.rdoHttp.post(`search/${type}`, pagedSorted);
			httpCall.subscribe(value => {
				const mapped = this.mapProperties(value);
				observer.next(mapped);
			}, (err) => {
				Sentry.captureException(err);
				observer.error(err);
			});
		});
		return observableSearch;
	}
}
