import {Injectable, EventEmitter} from '@angular/core';

export interface InstructionInfo {
    title?: string;
    icon?: string;
    class?: string;
    linkDSL?: Array<any>;
}

@Injectable()
export class BreadcrumbService {
    onChange: EventEmitter<Array<InstructionInfo>> = new EventEmitter<Array<InstructionInfo>>();
    private instrs: Array<InstructionInfo>;

    get instructions(): Array<InstructionInfo> {
        return this.instrs;
    }
    update(instructions: Array<InstructionInfo>) {
        this.instrs = instructions;
        this.onChange.next(instructions);
    }
}
