import { Component } from '@angular/core';
import { HeaderService } from '../../../../header/header.service';
import { GuidedToursService, PlatformUserProfile } from '../..';

@Component({
  selector: 'rdo-in-application-guidance-settings',
  templateUrl: './in-application-guidance-settings.component.html',
  styleUrls: ['./in-application-guidance-settings.component.scss']
})
export class InApplicationGuidanceSettingsComponent {
  public allowGuidedTours: boolean;
  public saving: boolean = false;

  constructor(
    private headerService: HeaderService,
    private guidedToursService: GuidedToursService
  ) {
    this.guidedToursService.getLoadedProfile().subscribe(profile => {
      this.updateCurrentOptOutOption(profile);
    });
  }

  showAppGuidancePopUp(): boolean {
    return this.headerService.showInApplicationGuidanceSettings;
  }

  closeDialog(): void {
    this.headerService.showInApplicationGuidanceSettings = false;
    this.saving = false;
  }

  onSubmitPreferences(): void {
    this.saving = true;
    if(this.guidedToursService.profileExists()) {
      this.guidedToursService.updateOptOutOption(!this.allowGuidedTours).subscribe(response => {
        this.updateCurrentOptOutOption(response);
        this.closeDialog();
      });
    } else {
      this.guidedToursService.createOptOutOption(!this.allowGuidedTours).subscribe(response => {
        this.updateCurrentOptOutOption(response);
        this.closeDialog();
      });
    }
  }

  private updateCurrentOptOutOption(newValue: PlatformUserProfile): void {
    this.allowGuidedTours = !newValue?.is_rdo_app_guidance_optout;
  }
}
