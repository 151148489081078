import cloneDeep from 'lodash/cloneDeep';
import {
    MetricsGridConfig,
    MetricsGridConfigOptions,
    IColumnConfig,
} from '../';

export type MetricsGridColumnsPatch = {
    columns: IColumnConfig[],
    keys: number[];
}

/**
 * Move multiple columns represented by a patch.
 */
export const patchGridColumns = (patch: MetricsGridColumnsPatch, gridConfig: MetricsGridConfig, dropIndex: number, shouldClone = false): MetricsGridConfig => {
    const cloneGridConfig = shouldClone ? cloneDeep(gridConfig) : gridConfig;
    const start = patch.keys[0];
    const patchLength = patch.keys.length;
    // put the moving columns in place
    cloneGridConfig.groups[0].columns.splice(dropIndex, 0, ...patch.columns);
    // remove the moving columns from the previous position.
    if (dropIndex < start) {
        cloneGridConfig.groups[0].columns.splice(start + patchLength, patchLength);
    } else {
        cloneGridConfig.groups[0].columns.splice(start, patchLength);
    }
    return cloneGridConfig;
};

/**
 * Generate patch information for a column movement. This finds columns that are paired with the target column as well
 * as the indices of the columns.
 */
export const getPairedPatch = (column: IColumnConfig, cloneGridConfig): MetricsGridColumnsPatch => {
    const indexMap: Record<number, IColumnConfig> = {};
    column.pairing.forEach((key: string) => {
        const index = cloneGridConfig.groups[0].columns.findIndex((c: IColumnConfig) => c.title === key);
        indexMap[index] = cloneGridConfig.groups[0].columns[index];
    });
    const keys: string[] = Object.keys(indexMap).sort();
    const columns = keys.map((key: string) => indexMap[key]);
    return {
        keys: keys.map((key: string) => parseInt(key, 10)),
        columns
    };
};
