import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { of } from 'rxjs';
import { isNumeric } from 'rxjs/util/isNumeric';
import * as _ from 'lodash';
import { RdoHttpService } from '../../core/http/rdo-http.service';
import {
	ClientCategory,
	ClientMonth,
	CustomerSize,
	GeoFilterItem,
	GeographyQuery,
	FilterLabel,
	OutlierReason,
	RouseCategory,
	SearchQuery,
	LocaleFormat,
	ClientVertical,
	CatProductGroup,
	ClientProductType,
	RouseProductType,
	RouseMarket,
	RouseProductTypeQuery,
	ClientProductTypeQuery
} from '../../models';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { FormatService } from '../../core/query/format.service';
import * as Sentry from '@sentry/angular';


@Injectable({
	providedIn: 'root',
})
export class FilterDataService {
	public benchmarks: Array<any>;
	public geographyLevels: Array<any>;
	public regionsCache: Array<GeoFilterItem>;
	public districtsCache: Array<GeoFilterItem>;
	public branchesCache: Array<GeoFilterItem>;
	constructor(
		private authenticationService: AuthenticationService,
		private http: RdoHttpService,
		private formatService: FormatService
	) {
		this.benchmarks = [];
		this.geographyLevels = [];
	}

	getMonths(): Observable<Array<ClientMonth>> {
		return this.http.get('/filter/months')
			.pipe(map(res => {
				const dates = new Array<ClientMonth>();
				for (const month of (res as any)) {
					const date = new Date(1970, 0, 1);
					date.setMonth(parseInt(month.Month));

					dates.push(new ClientMonth(month.MonthID, date));
				}
				return dates;
			}),
				catchError(err => {
					Sentry.captureException(err);
					return throwError(err.error || 'Server error');
				}));
	}

	getFilterLabels = (): Observable<Array<FilterLabel>> => {
		const clientid = this.authenticationService._userInfoView.SelectedClient.ClientID;
		return this.http.get('/filter/filter-labels' + '/' + clientid.toString()).pipe(
			map(res => res as Array<FilterLabel>),
			catchError(err => {
				Sentry.captureException(err);
				return throwError(err.error || 'Server error');
			}));
	};

	getRegions(): Observable<Array<GeoFilterItem>> {
		return this.http
			.post('/filter/regions')
			.pipe(map(result => (result as any).map((x: any) => new GeoFilterItem(x.RegionId, x.Region))))
			.pipe(tap((res) => this.regionsCache = res));
	}

	getDistricts(regions: Array<number>): Observable<Array<GeoFilterItem>> {
		const body = new GeographyQuery();
		const override = new SearchQuery({ DistrictList: [], BranchList: [] });
		body.RegionList = regions;
		return this.http
			.post('/filter/districts', body, override)
			.pipe(map(result => _.map((result as any), (x: any) => new GeoFilterItem(x.DistrictId, x.District, x.RegionId))))
			.pipe(tap((res) => this.districtsCache = res));
	}

	getBranches(regions: Array<number>, districts: Array<number>): Observable<Array<GeoFilterItem>> {
		const body = new GeographyQuery();
		const override = new SearchQuery({ BranchList: [] });
		body.RegionList = regions;
		body.DistrictList = districts;

		return this.http
			.post('/filter/locationCodes', body, override)
			.pipe(map(result => _.map((result as any), (x: any) => new GeoFilterItem(x.BranchId, x.Branch, x.DistrictId, x.RegionId))))
			.pipe(tap((res) => this.branchesCache = res));
	}

	getEnglishBenchmarks(): Observable<any[]> {
		return this.http
			.get(`/filter/benchmarks/en-US`)
			.pipe(map(this.createBenchmarks));
	}

	getBenchmarks(lang: string = null): Observable<any[]> {
		if (this.benchmarks.length > 0) {
			return new Observable(obs => {
				obs.next(this.benchmarks);
				obs.complete();
			});
		}
		let languajeParam = '';
		if (lang) {
			languajeParam = `/${lang}`;
		}
		return this.http
			.get(`/filter/benchmarks${languajeParam}`)
			.pipe(map(this.createBenchmarks));
	}

	private createBenchmarks = (resp: any): any[] => {
		this.benchmarks = resp;
		return this.benchmarks;
	}

	getComparisons(): Observable<any[]> {
		return of([
			{ Cid: 1, DisplayName: 'main.filters.primary_comparison.benchmark_focused' },
			{ Cid: 2, DisplayName: 'main.filters.primary_comparison.book_focused' }
		]);
	}

	getGeographyLevels(): Observable<any[]> {
		if (this.geographyLevels.length > 0) {
			return new Observable(obs => {
				obs.next(this.geographyLevels);
				obs.complete();
			});
		}
		return this.http
			.get('/filter/geography-levels')
			.pipe(map(this.createGeographyLevels));
	}

	getCustomGridsGeographyLevels(target: string): Observable<any[]> {
		return this.http
			.get(`/filter/customgrids-geography-levels/${target}`)
			.pipe(map(this.createGeographyLevels));
	}

	getEnglishGeographyLevels(): Observable<any[]> {
		return this.http
			.get('/filter/geography-levels/en-US')
			.pipe(map(this.createGeographyLevels));
	}


	private createGeographyLevels = (resp: any): Array<any> => {
		this.geographyLevels = resp;
		return this.geographyLevels;
	}

	getRouseCategories(): Observable<Array<RouseCategory>> {
		return this.http.get('/filter/rouse-categories')
			.pipe(
				map(res => res as Array<RouseCategory>),
				catchError(err => {
					console.error(err);
					return throwError(err.error || 'Server error');
				}));
	}

	getClientCategories(): Observable<Array<ClientCategory>> {
		const clientid = this.authenticationService._userInfoView.SelectedClient ? this.authenticationService._userInfoView.SelectedClient.ClientID : undefined;
		let endpoint = '/filter/client-categories';

		if (isNumeric(clientid) === true) {
			endpoint = endpoint + '?ClientId=' + clientid.toString();
		}

		return this.http.get(endpoint)
			.pipe(
				map(res => res as Array<ClientCategory>),
				catchError(err => {
					console.error(err);
					return throwError(err.error || 'Server error');
				}));
	}

	getCatProductGroups(): Observable<Array<CatProductGroup>> {
		return this.http.get('/filter/cat-product-groups') // '/filter/client-categories')
			.pipe(
				map(res => res as Array<CatProductGroup>),
				catchError(err => {
					console.error(err);
					return throwError(err.error || 'Server error');
				}));
	}

	getClientProductTypes(categories?: Array<number>): Observable<Array<ClientProductType>> {
		const body = new ClientProductTypeQuery();
		body.ClientCategoryList = categories;
		let endpoint = '/filter/client-product-types';

		const clientid = this.authenticationService._userInfoView.SelectedClient ? this.authenticationService._userInfoView.SelectedClient.ClientID : undefined;
		if (isNumeric(clientid) === true) {
			endpoint = endpoint + '?ClientId=' + clientid.toString();
		}
		return this.http
			.post(endpoint, null)
			.pipe(
				map(res => res as Array<ClientProductType>),
				catchError(err => {
					console.error(err);
					return throwError(err.error || 'Server error');
				}));
	}
	getRouseProductTypes(categories?: Array<number>): Observable<Array<RouseProductType>> {
		const body = new RouseProductTypeQuery();
		body.RouseCategoryList = categories;
		let endpoint = '/filter/rouse-product-types';
		const clientid = this.authenticationService._userInfoView.SelectedClient ? this.authenticationService._userInfoView.SelectedClient.ClientID : undefined;
		if (isNumeric(clientid) === true) {
			endpoint = endpoint + '?ClientId=' + clientid.toString();
		}
		return this.http
			.post(endpoint, body)
			.pipe(
				map(res => res as Array<RouseProductType>),
				catchError(err => {
					console.error(err);
					return throwError(err.error || 'Server error');
				}));
	}

	getRouseMarkets(): Observable<Array<RouseMarket>> {
		return this.http.get('/filter/rouse-markets')
			.pipe(
				map(res => res as Array<RouseMarket>),
				catchError(err => {
					console.error(err);
					return throwError(err.error || 'Server error');
				}));
	}

	getCustomerSizes = (): Observable<Array<CustomerSize>> => {
		return this.http.get('/filter/customer-sizes')
			.pipe(
				map(res => res as Array<CustomerSize>),
				catchError(err => {
					console.error(err);
					return throwError(err.error || 'Server error');
				}));
	}

	getClientOutlierReasons = (): Observable<Array<OutlierReason>> => {
		return this.http.get('/filter/client-outlier-reasons')
			.pipe(map(res => res as Array<OutlierReason>),
				catchError(err => {
					console.error(err);
					return throwError(err.error || 'Server error');
				}));
	}

	getClientVerticals = (): Observable<Array<ClientVertical>> => {
		return this.http.get('/filter/client-verticals')
			.pipe(map(res => res as Array<ClientVertical>),
				catchError(err => {
					console.error(err);
					return throwError(err.error || 'Server error');
				}));
	}

	getCycleBillTitle(min: number, max: number, xlsExport?: boolean): string | string[] {
		let title = ''; let titleRange = '';
		if (min === 1 && max === 1) {
			title = 'main.filters.include_initial_bill_only'; // 'Include Initial Bill Only';
		} else if (min === 2 && max === 2) {
			titleRange = '2-3'; // 'Include Cycle Bill 2-3 Only';
		} else if (min === 3 && max === 3) {
			titleRange = '4-6'; // 'Include Cycle Bill 4-6 Only';
		} else if (min === 4 && max === 4) {
			titleRange = '7-12'; // 'Include Cycle Bill 7-12 Only';
		} else if (min === 5 && max === 5) {
			titleRange = '> 12'; //  'Include Cycle Bill > 12 Only';
		} else if (min === 1 && max === 2) {
			titleRange = '1-3'; // 'Include Cycle Bill 1-3 Only';
		} else if (min === 2 && max === 3) {
			titleRange = '2-6'; // 'Include Cycle Bill 2-6 Only';
		} else if (min === 3 && max === 4) {
			titleRange = '4-12'; // 'Include Cycle Bill 4-12 Only';
		} else if (min === 4 && max === 5) {
			titleRange = '> 6'; // 'Include Cycle Bill > 6 Only';
		} else if (min === 1 && max === 2) { // eslint-disable-line no-dupe-else-if
			titleRange = '1-3'; // 'Include Cycle Bill 1-3 Only';
		} else if (min === 1 && max === 3) {
			titleRange = '1-6'; // 'Include Cycle Bill 1-6 Only';
		} else if (min === 1 && max === 4) {
			titleRange = '1-12'; // 'Include Cycle Bill 1-12 Only';
		} else if (min === 2 && max === 4) {
			titleRange = '2-12'; // 'Include Cycle Bill 2-12 Only';
		} else if (min === 2 && max === 5) {
			titleRange = '> 1'; // 'Include Cycle Bill > 1 Only';
		} else if (min === 3 && max === 4) { // eslint-disable-line no-dupe-else-if
			titleRange = '4-12'; // 'Include Cycle Bill 4-12 Only';
		} else if (min === 3 && max === 5) {
			titleRange = '> 3'; // 'Include Cycle Bill > 3 Only';
		} else if (min === 4 && max === 5) { // eslint-disable-line no-dupe-else-if
			titleRange = '> 6'; // 'Include Cycle Bill > 6 Only';
		}
		if (0 < min && min < 6 && 0 < max && max < 6 && titleRange !== '') {
			title = 'main.filters.include_cycle_bill_x_only';
		}
		if (xlsExport) {
			const value = this.formatService.translateAndFormat(title).replace('${1}', titleRange);
			return value;
		} else {
			return [title, titleRange];
		}
	}

	getLocaleFormats = (): Observable<Array<LocaleFormat>> => {
		return this.http.get('/filter/locale-formats')
			.pipe(
				map(res => res as Array<LocaleFormat>),
				catchError(err => {
					console.error(err);
					return throwError(err.error || 'Server error');
				}));
	}
}
