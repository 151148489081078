﻿<div style="white-space: nowrap;" *ngIf="gridConfig && pagedData">
    <ng-template ngFor let-g [ngForOf]="gridConfig.groups" let-i="index">

        <rdo-grid-table *ngIf="g.visible && !g.expandable"
                        [data]="rows"
                        [columns]="g.columns"
                        [title]="g.title"
                        [sortOn]="sortOn"
                        [headerStyle]="g.headerStyle"
                        [rowExpansion]="gridConfig.rowExpansion"
                        [showExpansionIndicator] = "i === 0"
                        (sortOnChange)="changeSort($event)"
                        [desc]="desc"
                        (descChange)="changeSortDirection($event)"
                        (rowToggled)="rowToggleArgs = $event"
                        [toggleRowIndex] = "rowToggleArgs"
                        [sortInProgress]="sortInProgress"
                        [group]="g"
                        [showColumnSelector]="i === 0"
                        [gridConfig]="gridConfig"
                        (visibilityChanged) = "saveVisibility()"
                        (downloadExcelClick) = "downloadExcelClick.next(gridConfig)"
                        (deleteClick) = "deleteClick.next(gridConfig)"
                        >
        </rdo-grid-table>

      <rdo-expandable-table *ngIf="g.visible && g.expandable"
                                [columns]="g.columns"
                                [data]="rows"
                                [title]="g.title"
                                [sortOn]="sortOn"
                                [headerStyle]="g.headerStyle"
                                [rowExpansion]="gridConfig.rowExpansion"
                                [showExpansionIndicator] = "i === 0"
                                (sortOnChange)="changeSort($event)"
                                [desc]="desc"
                                (descChange)="changeSortDirection($event)"
                                (rowToggled)="rowToggleArgs = $event"
                                [toggleRowIndex] = "rowToggleArgs"
                                [expanderClass] = "g.expanderClass"
                                [sortInProgress]="sortInProgress"
                                [group]="g"
                                [gridConfig]="gridConfig"
                                (visibilityChanged) = "saveVisibility()"
                                [expanded]="expandBasedOnView(g.title)"
                                (downloadExcelClick) = "downloadExcelClick.next(gridConfig)"
                                >
        </rdo-expandable-table>
    </ng-template>
    <div class="form-inline margin-bottom-xl" *ngIf="gridConfig && pagedData && !disablePaging">
        <form (submit)="onSubmitGoToPage()">
            <div class="form-group margin-right-xl">
                <a *ngIf="pagedData.Page > 1" (click)="previousPage()"><i class="fa fa-lg fa-caret-left"></i></a>
                <label class="control-label" for="PageSortGrid_Page">{{'main.core.metrics.page' | translate}}&nbsp;</label>
                <input class="form-control input-sm" id="PageSortGrid_Page" name="PageSortGrid.Page" style="width: 40px;"
                type="text" [(ngModel)]="pagedData.Page"> {{'main.core.metrics.of' | translate}} {{pagedData.PageCount}}
                <a *ngIf="pagedData.Page < pagedData.PageCount" (click)="nextPage()"><i class="fa fa-lg fa-caret-right"></i></a>
            </div>
            <div class="form-group">
                <label class="control-label" for="PageSortGrid_PageSize">{{'main.core.metrics.page_size' | translate}}&nbsp;</label>
                <select class="form-control input-sm" (ngModelChange)="changePageSize($event)" [ngModel]="pagedData.PageSize" name="PageSortGrid_PageSize">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                </select>
            </div>
        </form>
    </div>
</div>
