export class RateChangeBaseChart {
    protected MAX_Y_AXIS_TITLE_LENGTH = 36;

    private handleTitleLength(str: string) {
		let result = str;
		if (str && str.length > this.MAX_Y_AXIS_TITLE_LENGTH) {
			let wIndex = 0;
			let lineLength = 0;
			const words = str.split(' ');
			let phrase = '';
			while(wIndex < words.length) {
				if (lineLength + words[wIndex].length < this.MAX_Y_AXIS_TITLE_LENGTH) {
					phrase += words[wIndex] + ' ';
					lineLength += words[wIndex].length + 1;
				} else {
					phrase += '<br/>' + words[wIndex];
					lineLength = words[wIndex].length;
				}
				wIndex++;
			}
			result = phrase;
		}
		return  '<span>' + result + '</span>';
	}

	protected formatYAxisTitle(baseText: string) {
		const text = this.handleTitleLength(baseText);
		if (text.length > this.MAX_Y_AXIS_TITLE_LENGTH) {
			return {x: -10, text: text};
		} else {
			return {text: text};
		}
	}
}