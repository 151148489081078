import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, UrlTree } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { LazyLoadEvent } from 'primeng/api';
import {
  ActiveFilterService,
  MetricsGridConfig,
  ColumnDefinitionService,
  BreadcrumbService,
  GridTableConfiguredExpandableComponent,
  FormatService,
  GridSortingService,
} from './../../../core';
import { HeaderService } from './../../../header';
import { ProductTypeBaseComponent } from './../product-type-base.component';
import { SelectedProductTypeService } from './../selected-product-type.service';
import { ProductTypeCustomerService } from './product-type-customer.service';
import { DownloadsService } from './../../../downloads';
import { PageOptionsNg, SortOptionsNg, SortOptions } from './../../../models';
// import { AuthenticationService } from './../../../../app/core/authentication/authentication.service';
import { AuthenticationService } from '../../../core/authentication/authentication.service';

@Component({
  selector: 'rdo-product-type-customers',
  templateUrl: 'product-type-customers.component.html',
})
export class ProductTypeCustomersComponent
  extends ProductTypeBaseComponent
  implements OnInit, OnDestroy
{
  @ViewChild('customersGrid')
  customersGrid: GridTableConfiguredExpandableComponent;
  loading = true;
  gridName = 'PRODUCT_TYPE_CUSTOMERS_GRID';
  data: any;
  gridConfig: MetricsGridConfig;
  frozenRows: any;
  paging = new PageOptionsNg();
  sorting: SortOptionsNg;
  totalCount: any;
  frozenWidth: string;
  dataKey = 'CustomerId';

  constructor(
    private authenticationService: AuthenticationService,
    filterService: ActiveFilterService,
    private columnService: ColumnDefinitionService,
    private customersService: ProductTypeCustomerService,
    private router: Router,
    private route: ActivatedRoute,
    selectedProductTypeService: SelectedProductTypeService,
    breadcrumbService: BreadcrumbService,
    private headerService: HeaderService,
    private downloadsService: DownloadsService,
    private location: Location,
    private formatService: FormatService,
    private gridSortingService: GridSortingService
  ) {
    super(selectedProductTypeService, breadcrumbService, filterService);
  }

  ngOnInit() {
    this.gridSortingService.setGridName(this.gridName);
    this.sorting = this.gridSortingService.getSortOptionsNg();

    this.subscriptions.push(
      this.route.parent.params.subscribe((params) => {
        const routeParams = this.getRouteParams(params);
        if (this.hasRouteChanged(this.params, routeParams)) {
          this.params = routeParams;
          this.doUpdateBreadcrumbs();
          this.configureGrid();
          this.load();
        }
      })
    );
    this.subscriptions.push(
      this.filterService.filterChange.subscribe(() => {
        this.paging.page = 1;
        if (this.customersGrid) {
          this.customersGrid.resetPaginator();
        }
        this.load();
      })
    );
    this.subscriptions.push(
      this.selectedProductTypeService.productTypeChange.subscribe((p) => {
        if (this.params.productType !== p.ProductType) {
          this.params.productType = p.ProductType;
          this.selectedProductTypeService.loading = true;
          this.paging.page = 1;
          if (this.customersGrid) {
            this.customersGrid.resetPaginator();
          }
          this.doUpdateBreadcrumbs();
          this.load();
        }
      })
    );
    if (!this.selectedProductTypeService.productType) {
      this.subscriptions.push(
        this.selectedProductTypeService.productType.subscribe((p) => {
          this.doUpdateBreadcrumbs();
        })
      );
    }
    this.subscriptions.push(
      this.authenticationService.userInfoView.subscribe((userInfo) => {
        if (this.gridConfig) {
          this.gridConfig.enableExcelExport =
            userInfo.HasClientAccessToExportData;
        }
      })
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private doUpdateBreadcrumbs(): void {
    this.updateBreadcrumbs([
      {
        title: 'main.core.common.counts.customers.plural',
        class: 'active',
      },
    ]);
  }

  load = () => {
    this.loading = true;
    const sorting = this.getSorting();
    this.customersService
      .getProductTypeCustomers(
        {
          productType: this.params.productType,
          category: this.params.category,
          useRentedAsProductType: this.params.useRentedAsProductType,
        },
        this.paging,
        sorting
      )
      .subscribe((result) => {
        this.loading = false;
        this.selectedProductTypeService.loading = false;
        if (result.Items && result.Items.length > 0) {
          this.data = result.Items;
          this.totalCount = result.TotalCount;
        } else {
          this.data = [];
          this.totalCount = 0;
        }
      });
  };

  changePage = (event: any) => {
    if (event.rows !== this.paging.pageSize) {
      this.paging.pageSize = event.rows;
    }
    this.paging.page = event.first < 1 ? 1 : event.first / event.rows + 1;
    this.load();
  };

  changeLazyLoad = (event: LazyLoadEvent) => {
    if (
      this.sorting.sortField === event.sortField &&
      this.sorting.sortOrder === event.sortOrder
    ) {
      return;
    }
    this.gridSortingService.setSortOption(
      event.sortField,
      event.sortOrder === -1
    );

    this.sorting.sortField =
      event.sortField || this.gridSortingService.getSortFieldOrDefault();
    this.sorting.sortOrder =
      event.sortOrder || this.gridSortingService.getSortDirectionOrDefault();
    this.load();
  };

  exportExcel = (args: any) => {
    this.loading = true;
    const sorting = this.getSorting();
    const translatedConfig = this.gridConfig.cloneAndTranslate((text) =>
      this.formatService.translateAndFormat(text, false)
    );

    this.subscriptions.push(
      this.customersService
        .getProductTypeCustomersDownload(
          this.selectedProductTypeService.selectedProductType.Description,
          translatedConfig,
          this.params.productType,
          this.params.useRentedAsProductType,
          this.params.category,
          sorting
        )
        .subscribe((blob) => {
          this.loading = false;
          this.downloadsService.saveExcelBlob(blob);
        })
    );
  };

  private handleTransactionCountClick = (record: any, parent: any): boolean => {
    this.updateBreadcrumbs([
      {
        title: parent.CustomerName,
        class: 'active',
      },
      {
        title: record.SalesRepName,
        class: 'active',
      },
    ]);
    this.router.navigateByUrl(this.getTransactionUrlTree(record, parent));
    return false;
  };

  private getTransactionUrlTree = (record: any, parent: any): UrlTree => {
    return this.router.createUrlTree(
      [
        'transactions',
        {
          customer: parent.CustomerId,
          salesRep: record.SalesRepId,
        },
      ],
      {
        relativeTo: this.route.parent,
      }
    );
  };

  private generateTransactionsLink = (record: any, parent: any) => {
    const urlTree = this.getTransactionUrlTree(record, parent);
    return this.location.prepareExternalUrl(urlTree.toString());
  };

  protected getSorting = (): SortOptions => {
    this.sorting.sortField = this.gridSortingService.getSortFieldOrDefault();
    this.sorting.sortOrder =
      this.gridSortingService.getSortDirectionOrDefault();

    return new SortOptions(
      this.sorting.sortField,
      !(this.sorting.sortOrder === 1)
    );
  };

  private configureGrid = () => {
    this.frozenWidth = '460px';
    this.gridConfig = new MetricsGridConfig(
      [
        {
          title: '',
          visible: true,
          locked: true,
          columnSelectorTitle: 'main.core.common.counts.customers.singular',
          columns: [
            {
              title: 'main.core.common.counts.customers.singular',
              field: 'CustomerName',
              width: 350,
              autoWidth: false,
              maxWidth: 255,
              sortColumn: 'CustomerName',
              headerStyle: {
                'text-align': 'left',
              },
              cellStyle: {
                'text-align': 'left',
              },
              childConfig: {
                field: '',
              } /*,
							isString:true */,
            },
            this.columnService.SalesRepCount(this.columnService.SalesRep()),
            this.columnService.TransactionCount(
              this.columnService.TransactionCount(
                null,
                (v, r, p) => this.generateTransactionsLink(r, p),
                (v, r, p) => this.handleTransactionCountClick(r, p)
              )
            ),
          ],
        },
        ...this.columnService.StandardMetricSetColumnGroups(),
      ],
      (p) => this.loadChildren(p),
      true
    );
  };

  private loadChildren = (parent: any): Observable<Array<any>> => {
    const sorting = this.getSorting();
    return this.customersService
      .getProductTypeCustomersSalesReps(
        this.params.productType,
        parent.CustomerId,
        {
          category: this.params.category,
          useRentedAsProductType: this.params.useRentedAsProductType,
        },
        sorting
      )
      .pipe(
        map((items) => {
          return items.map(this.convert);
        })
      );
  };

  private convert = (item: any): any => {
    const clone = (<any>Object).assign({}, item);
    clone.CustomerName = null;
    clone.SalesRepCount = item.SalesRepName;
    return clone;
  };
}
