﻿<div class="panel panel-default rdo-card invisible" [class.active]="selected" [class.rdo-card-default]="!selected" [class.rdo-card-highlight]="selected"
     [class.invisible]="hidden" title="{{getTitle()}} " (click)="select()">

  <div class="panel-heading">
    <div class="panel-title">
      <rdo-category-icon [category]="cardData.CategoryIcon || cardData.RouseCategory"></rdo-category-icon>&nbsp;
      {{getTitle()}}
    </div>
  </div>
  <div class="panel-body">
    <div class="row">
      <div class="col-xs-6">
        <ng-template [ngIf]="isBenchmarkMode()">
          <div class="panel-title">
            {{'main.tabs.equipment.product_types.nav_card.compared_revenue' | translate}}
          </div>
          <div class="rdo-card-metric">
            {{cardData.RevenueBenchmarked | rdoCurrency:true:translateService.currentLang}} / <span [ngClass]="{'text-danger' : cardData.RevenueBenchmarkedDifference < 0}">
              {{cardData.RevenueBenchmarkedDifference | rdoCurrency:true:translateService.currentLang}}
            </span>

          </div>
        </ng-template>
        <ng-template [ngIf]="!isBenchmarkMode()">
          <div class="panel-title">
            {{'main.tabs.equipment.product_types.nav_card.total_revenue' | translate}}
          </div>
          <div class="rdo-card-metric">
            {{cardData.RevenueTotal | rdoCurrency:true:translateService.currentLang}} / <span [ngClass]="{'text-danger' : cardData.RevenueBookDifference < 0}">
              {{cardData.RevenueBookDifference | rdoCurrency:true:translateService.currentLang}}
            </span>
          </div>
        </ng-template>
      </div>
      <div class="col-xs-6 text-right">
        <div class="panel-title">
          <span *ngIf="!allRatesMode">{{'main.tabs.equipment.product_types.nav_card.compared_util' | translate}}</span>
          <span *ngIf="allRatesMode">{{'main.tabs.equipment.product_types.nav_card.total_util' | translate}}</span>
        </div>
        <div class="rdo-card-metric">
          <span [rdo-dynamic-pie]="cardData.PhysicalUtilization * 100" class="pie"></span>&nbsp;
          <span *ngIf="!allRatesMode">
            {{cardData.PhysicalUtilization | percent:'1.1-1'}} / <span [ngClass]="{'text-danger': (cardData.PhysicalUtilization - cardData.PhysicalUtilizationBench) < 0}">
              {{cardData.PhysicalUtilization - cardData.PhysicalUtilizationBench | percent:'1.1-1'}}
            </span>
          </span>
          <span *ngIf="allRatesMode">{{cardData.PhysicalUtilization | percent:'1.1-1'}}</span>
        </div>
      </div>
    </div>
  </div>
</div>