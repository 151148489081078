// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.
export const API_URL_LOC = 'https://rdo-api.rouseanalytics.com';
export const RATES_AND_AVAILABILITY_URL = 'https://mobile.rouseanalytics.com';
export const METRICS_API_URL = 'https://metrics-api.rouseanalytics.com';
export const CLIENT_ID = 'ighWVmZUVKpk5XpJgQZnjXnIjaYVzuTe';
export const DOMAIN = 'login.rouseservices.com';

//Those params should not change...
export const RESPONSE_TYPE = 'token id_token';
export const SCOPE = 'openid profile email';
export const AUDIENCE = 'https://rdo-api.rouseanalytics.com';
export const SOFTWARE_VERSION = '@SoftwareVersion@';
export const ISPKCEFLOWMODE = false;
export const ENABLE_UNPROMPTED_GUIDANCE = true;
export const CG_TEMPATES_CAN_BE_MODIFIED = false;
export const ENABLE_COMPETITIVE_DISCLAIMER = true;
export const DISABLED_DISCLAIMER_LOCALES = '1,4';
export const HEAP_ID = 2989042078;
export const PLATFORM = 'Production';
export const UKTESTINGNAMES = 'test.uk-performancetesting.admin@sbtuk.com';
export const DATA_DOG_SESSION_SAMPLE_RATE = 100;
export const DATA_DOG_SESSION_REPLAY_RATE = 20;
export const environment = {
    apiUrl: API_URL_LOC,
    clientId: CLIENT_ID,
    domain: DOMAIN,
    responseType: RESPONSE_TYPE,
    scope: SCOPE,
    audience: AUDIENCE,
    softwareVersion: SOFTWARE_VERSION,
    isPkceFlowMode: ISPKCEFLOWMODE,
    heapId: HEAP_ID,
    metricsApiUrl: METRICS_API_URL,
    cgTemplatesCanBeModified: CG_TEMPATES_CAN_BE_MODIFIED,
    enableUnpromptedGuidance: ENABLE_UNPROMPTED_GUIDANCE,
    platform: PLATFORM,
    ratesAndAvailabilityUrl: RATES_AND_AVAILABILITY_URL,
    enableCompetitiveDisclaimer: ENABLE_COMPETITIVE_DISCLAIMER,
    disabledDisclaimerLocales: DISABLED_DISCLAIMER_LOCALES,
    sentryTraceSampleRate: 0,
    ukTestingNames: UKTESTINGNAMES,
    dataDogSessionSampleRate: DATA_DOG_SESSION_SAMPLE_RATE,
    dataDogSessionReplayRate: DATA_DOG_SESSION_REPLAY_RATE,
    /**
     * Firestore connection settings and actionable endpoints
     */
    firestore: {
        /**
         * Exports Firestore settings
         */
        exports: {
            apiKey: 'AIzaSyCEXR8MtedNvoAJoNDpafy_usyOH1wwndg',
            authDomain: "rdo-prod-ef10f1.firebaseapp.com",
            databaseURL: "https://rdo-prod-ef10f1.firebaseio.com",
            projectId: "rdo-prod-ef10f1",
            storageBucket: "rdo-prod-ef10f1.appspot.com",
            messagingSenderId: "1048344558563",
            appId: "1:1048344558563:web:8d229e206e5df441917ed6",
            measurementId: "G-XWWK7TX3P9",

            /**
             * Environment string for collection retrieval
             */
            environment: 'production',
            /**
             * Rouse API settings
             */
            ras: {
                /**
                 * Action endpoints
                 * Format: RequestMethod:ApiEndpoint
                 */
                endpoints: {
                    /**
                     * API Endpoint to fetch a custom FS token, used to sign in and subscribe
                     * to the changes of the exports jobs collection
                     */
                    token: 'post:getFirestoreToken'
                }
            }
        }
    }
};
