import {
  Component,
  Input,
  ElementRef,
  EventEmitter,
  Output,
} from '@angular/core';
import { RevenueChartHelperService, HighchartsConfiguration } from './../base';
import { FormatService } from './../../query';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { HistogramLabel } from './histogram-label';
import { HistogramHtmlLabel } from './histogram-html-label';
import { TRANSLATOR } from './histogram-translator';

export interface HistogramBucket {
	displayFrom: number;
	displayTo: number;
	from: number;
	to: number;
}


declare let $: any;
@Component({
  selector: 'rdo-histogram',
  templateUrl: 'histogram-chart.component.html',
})
export class HistogramChartComponent {
  private labels: any = {};
  hostElement: ElementRef;
  chartConfig: HighchartsConfiguration = new HighchartsConfiguration();
  formatService: FormatService;
  revUtilChartService: RevenueChartHelperService;
  data: Array<any>;
  private chart: any;
  @Input() chartWidth: number;
  @Input() name: string;
  @Input() chartId: string;
  // eslint-disable-next-line  @angular-eslint/no-input-rename
  @Input('chart-data')
  set chartData(data: any) {
    if (data) {
      this.data = data;
      this.loadChart(data);
    }
  }
  @Output()
  bucketClick: EventEmitter<HistogramBucket> =
    new EventEmitter<HistogramBucket>();

  constructor(
    element: ElementRef,
    formatService: FormatService,
    revUtilChartService: RevenueChartHelperService,
    private translateService: TranslateService
  ) {
    this.hostElement = element;
    this.formatService = formatService;
    this.revUtilChartService = revUtilChartService;
    this.translateService
      .stream('main.tabs.equipment.product_types.rate_types')
      .subscribe((i18n) => {
        this.updateNamesAndTitles();
      });
  }

  private updateNamesAndTitles() {
    if (this.chart) {
      for (const key in TRANSLATOR) {
        // eslint-disable-next-line no-prototype-builtins
        if (TRANSLATOR.hasOwnProperty(key)) {
          const element = TRANSLATOR[key];
          const property = this.chart.get(element.id);
          if (property) {
            const propertyName = element.getName(this);
            property.update({ title: { text: propertyName } });
            property.update({ name: propertyName });
          }
        }
      }
      for (const key in this.labels) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.labels.hasOwnProperty(key)) {
          const label = this.labels[key];
          label.updateLanguage();
        }
      }
    }
  }

  private loadChart(chartData: Array<any>) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;
    let data = chartData;
    if (!_.some(chartData, (o) => o.FromValue && o.ToValue)) {
      data = [];
    }
    const book = _.max(_.map(data, (o) => o.BookRate));
    const floor = _.max(_.map(data, (o) => o.FloorRate));
    const topQ = _.max(_.map(data, (o) => o.TQRateBench));
    const avg = _.max(_.map(data, (o) => o.AvgRateBench));
    const botQ = _.max(_.map(data, (o) => o.BQRateBench));
    const maxb = _.max(_.map(data, (o) => o.MaxRateBench));
    const minb = _.max(_.map(data, (o) => o.MinRateBench));

    const isBin = (obj, value) => {
      return value >= obj.FromValue && value <= obj.ToValue ? value : null;
    };
    const bookPts = _.map(data, (o) => isBin(o, book));
    const benchmarkedTransactionCounts = _.map(data, (o) =>
      o.CountBenchmarked !== 0 ? o.CountBenchmarked : null
    );
    const unbenchmarkedTransactionCounts = _.map(data, (o) => {
      const diff = o.Count - o.CountBenchmarked;
      return diff !== 0 ? diff : null;
    });
    const options = {
      chart: {
        height: 400,
        width:
          this.chartWidth ||
          this.hostElement.nativeElement.firstElementChild.clientWidth,
        type: 'column',
        inverted: true,
        style: {
          fontSize: '11px',
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          allowPointSelect: true,
          events: {
            click: (x) => {
              this.raiseBucketClickedEvent(x.point);
            },
          },
          grouping: false,
          // This value was 3 making it 0 seems to fix this bug
          // https://rouseservices.atlassian.net/browse/ANA-8823 but not sure if it has other ramifications.
          // https://api.highcharts.com/highcharts/plotOptions.histogram.minPointLength
          // Override this value if you need to show 0 values in the histogram.
          // Not sure why this was only a problem for one bar of the histogram though?
          minPointLength: 0,
        },
      },
      xAxis: {
        categories: _.map(data, (o) => {
          let from = o.FromValue;
          if (!isNaN(parseInt(from))) {
            from = parseInt(from).toLocaleString();
          }

          let to = o.ToValue;
          if (!isNaN(parseInt(to))) {
            to = parseInt(to).toLocaleString();
          }

          const label = `${from} -  ${to}`;
          o.key = label;
          return label;
        }),
        labels: {
          style: {
            fontSize: '11px',
          },
        },
        reversed: false,
      },
      yAxis: [
        {
          id: TRANSLATOR.TRANSACTION_COUNT.id,
          allowDecimals: false,
          labels: {
            style: {
              fontSize: '11px',
            },
          },
          title: {
            text: TRANSLATOR.TRANSACTION_COUNT.getName(component),
          },
          gridLineWidth: 0,
          minorGridLineWidth: 0,
        },
        {
          labels: {
            style: {
              fontSize: '11px',
            },
            enabled: false,
          },
          title: {
            text: null,
          },
          gridLineWidth: 0,
          max: 1,
          min: 0,
          minorGridLineWidth: 0,
        },
      ],
      series: [
        {
          id: TRANSLATOR.COMPARED_TRANSACTIONS_COUNT.id,
          type: 'column',
          color: 'rgba(45, 108, 162, 0.4)',
          data: benchmarkedTransactionCounts,
          name: TRANSLATOR.COMPARED_TRANSACTIONS_COUNT.getName(component),
          yAxis: 0,
          borderColor: 'rgba(45, 108, 162, 0.4)',
          borderWidth: 1,
        },
        {
          id: TRANSLATOR.NOT_COMPARED_TRANSACTIONS_COUNT.id,
          type: 'column',
          color: 'rgba(255, 255, 255, 0.0)',
          data: unbenchmarkedTransactionCounts,
          name: TRANSLATOR.NOT_COMPARED_TRANSACTIONS_COUNT.getName(component),
          yAxis: 0,
          borderColor: 'rgba(45, 108, 162, 0.4)',
          borderWidth: 1,
        },
        {
          id: TRANSLATOR.BOOK.id,
          type: 'column',
          color: 'rgba(36, 90, 140, 1.0)',
          data: bookPts,
          name: TRANSLATOR.BOOK.getName(component),
          pointPadding: 0.375,
          yAxis: 1,
        },
        ...(component.allowFloor(floor, book)
          ? [
              {
                id: TRANSLATOR.FLOOR.id,
                type: 'column',
                color: 'rgba(36, 90, 140, 1.0)',
                data: _.map(data, (o) => isBin(o, floor)),
                name: TRANSLATOR.FLOOR.getName(component),
                pointPadding: 0.375,
                yAxis: 1,
              },
            ]
          : []),
        {
          id: TRANSLATOR.BENCH_TOP_Q.id,
          type: 'column',
          color: 'rgba(92,184,92,1.0)',
          data: _.map(data, (o) => isBin(o, topQ)),
          name: TRANSLATOR.BENCH_TOP_Q.getName(component),
          pointPadding: 0.5,
          yAxis: 1,
        },
        {
          id: TRANSLATOR.BENCH_AVG.id,
          type: 'column',
          color: 'rgba(211,211,211,1.0)',
          data: _.map(data, (o) => isBin(o, avg)),
          name: TRANSLATOR.BENCH_AVG.getName(component),
          pointPadding: 0.5,
          yAxis: 1,
        },
        {
          id: TRANSLATOR.BENCH_BOT_Q.id,
          type: 'column',
          color: 'rgba(217,83,79,1.0)',
          data: _.map(data, (o) => isBin(o, botQ)),
          name: TRANSLATOR.BENCH_BOT_Q.getName(component),
          pointPadding: 0.5,
          yAxis: 1,
        },
      ],
    };
    // TODO: Write test
    const getPointIndex = (points, benchmarkValue) => {
      const matchedPoint = _.find(points, (point: any) => {
        const bucket = _.find(data, (b) => b.key === point.category);
        const fromValue = bucket.FromValue;
        const toValue = bucket.ToValue;
        const isMatch =
          benchmarkValue >= fromValue && benchmarkValue <= toValue;
        return isMatch;
      });
      if (matchedPoint) {
        return matchedPoint.index;
      }
      return false;
    };

    const config = new HighchartsConfiguration();
    config.options = options;
    config.exportingOptions = {
      getFileName: () => {
        return this.name ? this.translateService.instant(this.name) : '';
      },
    };
    config.onLoadCallback = (chart) => {
      this.chart = chart;
      let topQIndex = null;
      let avgIndex = null;
      let botQIndex = null;
      let bookIndex = null;
      let floorIndex = null;
      let maxbIndex = null;
      let minbIndex = null;

      const benchTopQPoints = this.chart.get(TRANSLATOR.BENCH_TOP_Q.id).points;
      topQIndex = getPointIndex(benchTopQPoints, topQ);

      const benchAvgPoints = this.chart.get(TRANSLATOR.BENCH_AVG.id).points;
      avgIndex = getPointIndex(benchAvgPoints, avg);

      const benchBotQPoints = this.chart.get(TRANSLATOR.BENCH_BOT_Q.id).points;
      botQIndex = getPointIndex(benchBotQPoints, botQ);

      const bookPoints = this.chart.get(TRANSLATOR.BOOK.id).points;
      bookIndex = getPointIndex(bookPoints, book);

      if (this.allowFloor(floor, book)) {
        const floorPoints = this.chart.get(TRANSLATOR.FLOOR.id).points;
        floorIndex = getPointIndex(floorPoints, floor);
      }

      const maxbPoints = this.chart.get(
        TRANSLATOR.COMPARED_TRANSACTIONS_COUNT.id
      ).points;
      maxbIndex = getPointIndex(maxbPoints, maxb);

      const minbPoints = this.chart.get(
        TRANSLATOR.COMPARED_TRANSACTIONS_COUNT.id
      ).points;
      minbIndex = getPointIndex(minbPoints, minb);

      chart.xAxis[0].addPlotBand({
        color: '#eaf6ea',
        from: avgIndex,
        to: topQIndex,
      });

      chart.xAxis[0].addPlotBand({
        color: '#fdf7f7',
        from: botQIndex,
        to: avgIndex,
      });

      // add plot lines
      if (topQIndex !== false) {
        chart.xAxis[0].addPlotLine({
          value: topQIndex,
          color: 'rgba(92,184,92,1.0)',
          width: 1,
        });
      }

      if (avgIndex !== false) {
        chart.xAxis[0].addPlotLine({
          value: avgIndex,
          color: 'rgba(211,211,211,1.0)',
          width: 1,
        });
      }

      if (botQIndex !== false) {
        chart.xAxis[0].addPlotLine({
          value: botQIndex,
          color: 'rgba(242,108,104,1.0)',
          width: 1,
        });
      }

      if (data.length > 0) {
        const yPositions = [
          304, 281, 260, 238, 217, 196, 172, 151, 131, 110, 89, 67, 45, 23, 0,
        ]; // offsets for buckets in histogram
        if (book) {
          this.labels.book = new HistogramLabel('book', this.translateService);
          this.labels.book.highChartsLabel = chart.renderer
            .label(
              this.labels.book.getText(),
              chart.container.offsetWidth - 45,
              yPositions[bookIndex]
            )
            .css({
              color: 'rgba(36, 90, 140, 1)',
              fontSize: '11px',
              fontWeight: 'bold',
            })
            .attr({
              zIndex: 6,
            })
            .add();
          chart.xAxis[0].addPlotLine({
            value: bookIndex,
            color: 'rgba(36, 90, 140, 1.0)',
            width: 4,
          });
        }
        if (this.allowFloor(floor, book)) {
          this.labels.floor = new HistogramLabel(
            'floor',
            this.translateService
          );
          const translatedText = this.labels.floor.getText();
          const textWidth = this.labelWidth(translatedText);
          this.labels.floor.highChartsLabel = chart.renderer
            .label(
              translatedText,
              chart.container.offsetWidth - textWidth,
              yPositions[floorIndex] + 20
            )
            .css({
              color: 'rgba(36, 90, 140, 1)',
              fontSize: '11px',
              fontWeight: 'bold',
            })
            .attr({
              zIndex: 6,
            })
            .add();
          chart.xAxis[0].addPlotLine({
            value: floorIndex,
            color: 'rgba(36, 90, 140, 1.0)',
            width: 4,
          });
        }
        if (maxb) {
          const value =
            maxb < 1000 ? maxb : this.formatService.formatNumber(maxb, 0);
          const ypos =
            maxbIndex > 0
              ? yPositions[maxbIndex - 1]
              : yPositions[maxbIndex] + 22;
          this.labels.max = new HistogramHtmlLabel(
            'fa-plus',
            '#36826A',
            value,
            'bench-max',
            'bench_max',
            this.translateService
          );
          const label = chart.renderer
            .label(
              this.labels.max.getHTML(),
              chart.container.offsetWidth - 15,
              ypos,
              null,
              null,
              null,
              true
            )
            .add();
          const popoverText = this.labels.max.getPopOverText();
          $('.bench-max').popover({
            content: popoverText,
            trigger: 'hover',
            template: `<div class="popover-charts" role="tooltip">
										<div class="arrow"></div>
										<div class="popover-content"></div>
										</div>`,
            html: true,
            placement: 'left',
          });
          this.labels.max.highChartsLabel = label;
        }
        if (minb) {
          const value =
            minb < 1000 ? minb : this.formatService.formatNumber(minb, 0);
          const ypos =
            minbIndex > 0
              ? yPositions[minbIndex - 1]
              : yPositions[minbIndex] + 22;
          this.labels.min = new HistogramHtmlLabel(
            'fa-minus',
            '#A76161',
            value,
            'bench-min',
            'bench_min',
            this.translateService
          );
          const label = chart.renderer
            .label(
              this.labels.min.getHTML(),
              chart.container.offsetWidth - 15,
              ypos,
              null,
              null,
              null,
              true
            )
            .add();
          const popoverText = this.labels.min.getPopOverText();
          $('.bench-min').popover({
            content: popoverText,
            trigger: 'hover',
            template: `<div class="popover-charts" role="tooltip">
											<div class="arrow"></div>
											<div class="popover-content"></div>
											</div>`,
            html: true,
            placement: 'left',
          });
          this.labels.min.highChartsLabel = label;
        }
      }
    };
    this.chartConfig = config;
  }

  private allowFloor(floor: number, book: number) {
    return floor && (floor < book || !book);
  }

  private labelWidth(text: string): number {
    let width = this.formatService.getTextWidth(
      text,
      'bold 11px sans-serif',
      13
    );
    width = width < 25 ? width + 10 : width;
    return width;
  }

  private raiseBucketClickedEvent(point: any) {
    const category = <string>point.category;
    const bucketIndex = _.findIndex(this.data, (i) => i.key === category);
    const bucket = this.data[bucketIndex];

		const displayFromValue: number = bucket.FromValue;
		const displayToValue: number = bucket.ToValue;
		let fromValue: number = bucket.FromValue;
		let toValue: number = bucket.ToValue;

    // don't pass from/to value for top/bottom bucket
    // to avoid stored procedure bin range rounding issues
    if (bucketIndex === 0) {
      fromValue = null;
    } else if (bucketIndex === this.data.length - 1) {
      toValue = null;
    }

    this.bucketClick.next(<HistogramBucket>{
      displayFrom: displayFromValue,
      displayTo: displayToValue,
      from: fromValue == null ? null : fromValue - 0.5,
      to: toValue == null ? null : toValue + 0.5,
    });
  }
}
