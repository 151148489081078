<div class="black-background" (click)="onClose()" [style.display]="showPopup ? 'flex' : 'none'"></div>
<div class="popup-container" [style.display]="showPopup ? 'flex' : 'none'">
    <!-- Title Section -->
    <div class="popup-title-section">
        <div class="popup-title">{{'main.filters.profiles.save_as.title'| translate}}</div>
        <i (click)="onClose()" class="fa fa-close popup-close"></i>
    </div>

    <!-- Content -->
    <div class="profile-name-container">
        <div class="profile-name-text">{{'main.filters.profiles.save_as.name'| translate}}:</div>
        <input type="text" [placeholder]="'main.filters.profiles.save_as.name_placeholder'| translate"
            [(ngModel)]="profileName" class="profile-name-input" maxlength="100"><br />
    </div>
    <div id="name-errmsg" class="e-error" [hidden]="isProfileNameIsValid()">
        {{'main.filters.profiles.save_as.name_required' | translate}}
    </div>

    <!-- Footer -->
    <div class="popup-footer">
        <div class="popup-check-container">
            <mat-checkbox [(ngModel)]="isDefault"></mat-checkbox>
            <div class="popup-check-label" (click)="isDefault=!isDefault">{{'main.filters.profiles.save_as.set_default'|
                translate}}</div>
        </div>
        <div class="popup-btns-container">
            <div class="popup-btn save-btn" (click)="onSave()">{{'main.filters.profiles.save_as.ok'| translate |
                uppercase}}</div>
            <div class="popup-btn" (click)="onClose()">{{'main.filters.profiles.save_as.cancel'| translate | uppercase}}
            </div>
        </div>
    </div>
</div>