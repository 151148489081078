import { Injectable } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Injectable()
export class MathService {

	private decimalPipe: DecimalPipe = new DecimalPipe('en-us');

	public round = (num: number, precision: number): number => {
		precision = Math.abs(precision) || 0;
		const sign = (num < 0) ? -1 : 1;
		const multiplier = Math.pow(10, precision);
		return ((Math.round(Math.abs(num) * multiplier) / multiplier) * sign);
	}

	public substract = (sub: number, diff: number, precision: number): number => {
		return (this.round(sub * 100, precision) - this.round(diff * 100, precision)) / 100;
	}

	public add = (val1: number, val2: number, precision: number): number => {
		return (this.round(val1 * 100, precision) + this.round(val2 * 100, precision)) / 100;
	}

	public differenceNumber = (val1: number, val2: number): number => {
		return (val1 / val2) - 1;
	}
	
	public substractWithNullCheck = (minuend: number, subtrahend: number): number => {
		if (minuend === null || subtrahend === null) {
				return null;
		}
		return minuend - subtrahend;
	}
}
