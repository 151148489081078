import {
  Component,
  Input,
  Output,
  OnDestroy,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  HostListener,
} from '@angular/core';

import {
  MetricsGridConfig,
  ColumnSelectorConfig,
  ColumnSelectorItemConfig,
} from './MetricsGridConfig';
import { IGridColumnGroup } from './IGridColumnGroup.interface';
import { AuthenticationService } from '../authentication/authentication.service';
import { ActiveFilterService, FormatService } from '../query';
import { TranslateService } from '@ngx-translate/core';
import { I18N_TITLES } from './column-definition.service';
import { IntroJsService } from '../introJs';
import { IColumnConfig } from './IColumnConfig.interface';

export const DEFAULT_HEADERS: Array<string> = [
  'main.core.tables.titles.dimensions.title',
  'main.core.tables.titles.overall_rate_performance.title',
  'main.core.tables.titles.new_monthly_rate_performance.title',
  'main.core.tables.titles.monthly_rate_performance.title',
  'main.core.tables.titles.weekly_rate_performance.title',
  'main.core.tables.titles.daily_rate_performance.title',
  'main.core.tables.titles.hourly_rate_performance.title',
  'main.core.tables.titles.rate_change.title',
  'main.core.tables.titles.utilization.title',
  'main.core.tables.titles.current_fleet.title',
  'main.core.tables.titles.mom_growth_in_fleet_and_fleet_on_rent.title',
  'main.core.tables.titles.yoy_growth_in_fleet_and_fleet_on_rent.title',
];

export const DEFAULT_COLUMNS: Record<
  number,
  (string | ColumnSelectorConfig)[]
> = {
  0: [],
  1: [
    I18N_TITLES.REVENUE_VS_BOOK_RATE_REVENUE,
    I18N_TITLES.REVENUE_VS_BENCHMARK_RATE_REVENUE,
    I18N_TITLES.PREMIUM_TO_BENCHMARK_TOP_QUARTILE,
    I18N_TITLES.DISCOUNT_TO_BENCHMARK_BOTTOM_QUARTILE,
  ],
  2: [
    I18N_TITLES.NEW_MONTHLY_RATE_REVENUE_VS_BOOK_RATE_REVENUE,
    I18N_TITLES.NEW_MONTHLY_RATE_REVENUE_VS_BENCHMARK_RATE_REVENUE,
    I18N_TITLES.NEW_MONTHLY_RATE_COMPARISON,
  ],
  3: [
    I18N_TITLES.MONTHLY_RATE_REVENUE_VS_BOOK_RATE_REVENUE,
    I18N_TITLES.MONTHLY_RATE_REVENUE_VS_BENCHMARK_RATE_REVENUE,
    I18N_TITLES.MONTHLY_RATE_COMPARISON,
  ],
  4: [
    I18N_TITLES.WEEKLY_RATE_REVENUE_VS_BOOK_RATE_REVENUE,
    I18N_TITLES.WEEKLY_RATE_REVENUE_VS_BENCHMARK_RATE_REVENUE,
    I18N_TITLES.WEEKLY_RATE_COMPARISON,
  ],
  5: [
    I18N_TITLES.DAILY_RATE_REVENUE_VS_BOOK_RATE_REVENUE,
    I18N_TITLES.DAILY_RATE_REVENUE_VS_BENCHMARK_RATE_REVENUE,
    I18N_TITLES.DAILY_RATE_COMPARISON,
  ],
  6: [
    I18N_TITLES.HOURLY_RATE_REVENUE_VS_BOOK_RATE_REVENUE,
    I18N_TITLES.HOURLY_RATE_REVENUE_VS_BENCHMARK_RATE_REVENUE,
    I18N_TITLES.HOURLY_RATE_COMPARISON,
  ],
  7: [I18N_TITLES.MOM_RATE_CHANGE, I18N_TITLES.YOY_RATE_CHANGE],
  8: [
    I18N_TITLES.PHYSICAL_UTILIZATION,
    I18N_TITLES.FINANCIAL_UTILIZATION,
    I18N_TITLES.UNAVAILABLE_UTILIZATION,
  ],
  9: [
    I18N_TITLES.FLEET_AGE,
    I18N_TITLES.TOTAL_FLEET_INVESTMENT,
    I18N_TITLES.COMPARED_FLEET_INVESTMENT,
  ],
  10: [
    I18N_TITLES.TOTAL_MOM_GROWTH_IN_FLEET,
    I18N_TITLES.COMPARED_MOM_GROWTH_IN_FLEET,
    I18N_TITLES.TOTAL_MOM_GROWTH_ON_RENT,
    I18N_TITLES.COMPARED_MOM_GROWTH_ON_RENT,
  ],
  11: [
    I18N_TITLES.TOTAL_YOY_GROWTH_IN_FLEET,
    I18N_TITLES.COMPARED_YOY_GROWTH_IN_FLEET,
    I18N_TITLES.TOTAL_YOY_GROWTH_ON_RENT,
    I18N_TITLES.COMPARED_YOY_GROWTH_ON_RENT,
  ],
};

export const groupConfigFactory = (
  title: string,
  grid: MetricsGridConfig,
  type: 'column' | 'group' = 'group'
): ColumnSelectorItemConfig => {
  const target = grid.groups.find(
    (g: IGridColumnGroup) => g.columnSelectorTitle === title
  );
  return {
    type,
    title,
    target,
  };
};

export const columnConfigFactory = (
  title: string,
  parentId: number,
  grid: MetricsGridConfig,
  type: 'column' | 'group' = 'column'
): ColumnSelectorItemConfig => {
  const target = grid.groups[parentId].columns.find((c) => c.title === title);
  return {
    type,
    title,
    target,
    parentId,
  };
};

@Component({
  selector: 'rdo-configured-column-selector',
  templateUrl: './configured-column-selector.component.html',
  styleUrls: ['./configured-column-selector.component.scss'],
})
export class ConfiguredColumnSelectorComponent implements OnChanges, OnDestroy {
  @Input() gridConfig: MetricsGridConfig;
  @Input() size = 'xs';
  @Output() visibilityChanged: EventEmitter<ColumnSelectorItemConfig> =
    new EventEmitter<ColumnSelectorItemConfig>();
  // eslint-disable-next-line @angular-eslint/no-output-rename
  @Output('downloadExcelClick') excelClick = new EventEmitter();
  @Output() deleteClick: EventEmitter<MetricsGridConfig> =
    new EventEmitter<MetricsGridConfig>();
  elements: (
    | ColumnSelectorItemConfig
    | { title: string; isHeader: boolean }
  )[] = [];
  protected subscriptions = [];
  public showDropDown = false;

  constructor(
    public authenticationService: AuthenticationService,
    private filterService: ActiveFilterService,
    private translateService: TranslateService,
    private formatService: FormatService,
    private introJsService: IntroJsService
  ) {}

  private translate(path: string) {
    return this.translateService.instant(path);
  }

  private get headers(): string[] {
    return this.gridConfig?.options?.columnSelector?.headerList || [];
  }

  ngOnDestroy() {
    while (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.pop().unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'gridConfig' || propName === '') {
        this.buildChildren();
      }
    }
  }

  handleTranslation(text: string): string {
    return this.formatService.translateAndFormat(text, false);
  }

  toggleVisibility(e: any, item: ColumnSelectorItemConfig) {
    e.stopPropagation();

    let beforeHookResult: boolean = true;
    if (this.gridConfig?.options?.hooks?.beforeColumnVisibilityChange) {
      beforeHookResult =
        this.gridConfig?.options?.hooks?.beforeColumnVisibilityChange(
          item,
          this.gridConfig
        );
      if (!beforeHookResult) {
        return;
      }
    }
    if (item.type === 'column') {
      const column = item.target as IColumnConfig;
      column.visible = !column.visible;
      this.visibilityChanged.next(item);
    } else {
      const group = item.target as IGridColumnGroup;
      if (!group.locked || group.forceCanClose) {
        group.visible = !group.visible;
        this.visibilityChanged.next(item);
      }
    }
    if (this.gridConfig?.options?.hooks?.afterColumnVisibilityChange) {
      this.gridConfig?.options?.hooks?.afterColumnVisibilityChange(
        item,
        this.gridConfig
      );
    }
  }

  clickExcelDownload(e: any, group: IGridColumnGroup) {
    e.stopPropagation();
    this.excelClick.next(group);
  }

  clickDelete(e: any) {
    e.stopPropagation();
    this.deleteClick.next();
  }

  private pushColumn(
    item: ColumnSelectorItemConfig | { title: string; isHeader: boolean }
  ) {
    this.elements.push(item);
  }

  private buildChildren = () => {
    this.elements = [];
    const headerList = this.gridConfig.options.columnSelector.headerList;
    const itemList = this.gridConfig.options.columnSelector.itemList;
    for (let i = 0; i < headerList.length; i++) {
      this.pushColumn({ title: headerList[i], isHeader: true });
      itemList[i].forEach((item) => {
        this.pushColumn(item);
      });
    }
  };

  @HostListener('document:click', ['$event'])
  onClickOut(event: PointerEvent) {
    this.introJsService.handleClickOut(event, ['column-selector-'], () => {
      this.showDropDown = false;
    });
  }
}

class SelectionHeader {
  title: string;
  children: Array<IGridColumnGroup>;

  constructor(init?: Partial<SelectionHeader>) {
    (<any>Object).assign(this, init);
  }
}
