import { UserInfo } from './../models/user-info';
import { TokenInfo } from './token.info';
import { SelectableClient } from './selectableclient';

/// this class holds the user info state as it mutates during session
export class UserInfoView extends UserInfo {
    //public SelectedClientId: number;
    //public SelectedClientName: string;
    public SelectedClient: SelectableClient;
    public tokenInfo: TokenInfo;                     // required to perform operations on userinfoview (and base) during client switching 

    constructor(userinfodata: any, tokeninfo: TokenInfo) {
        super(userinfodata);
        // quick conversion here to facilitate codes and descriptions should they become available
        this.SelectableClients = userinfodata.SelectableClients.map(i => {
            return {
                ClientID: i.Identifier,
                ClientCode: i.Code.toLowerCase(),
                ClientShortName: i.Name,
                Lang: i.Lang,
                RDOAccess: i.RDOAccess,
                MQAAccess: i.MQAAccess,
                RDODownloads: i.RDODownloads,
                RDOExportData: i.RDOExportData,
                RDOCustomGridsEditor: i.RDOCustomGridsEditor,
                RDOClientCustomGridsEditor: i.RDOClientCustomGridsEditor,
                HasGeographyFullAccess: i.HasGeographyFullAccess,
                HasSalesRepRole: i.HasSalesRepRole,
                HasCustomGridsData: i.HasCustomGridsData,
                AvailableCustomGridSources: i.AvailableCustomGridSources,
                RequiresCompetitionDisclaimer: i.RequiresCompetitionDisclaimer
            };
        }) as Array<SelectableClient>;
        // token information
        this.tokenInfo = tokeninfo;
        if (this.SelectableClients.length === 1) {
            this.updateSelectedClient(this.SelectableClients[0]);
        } else {
            this.updateSelectedClient(null);
        }
    }

    public updateSelectedClient = (client: SelectableClient): void => {
        if (client == null) {
            //this.SelectedClientId = undefined;
            //this.HasClientAccessToDownloads = false;
            this.SelectedClient = client;
        } else {
            //this.SelectedClientId = client.ClientID;
            // this.HasLegacyClientAccessToDownloads();
            this.SelectedClient = client;
            this.lang = client.Lang ? client.Lang : this.lang;
        }
        this.HasClientAccessToDownloads = !!(this.SelectedClient?.RDODownloads);
        this.HasClientAccessToExportData = !!(this.SelectedClient?.RDOExportData);
    }

    // Probably will not be used again but keep it around just in case
    public HasLegacyClientAccessToDownloads = () => {
        this.HasClientAccessToDownloads = false;
        if (this.tokenInfo) {
            const rolebyclientlist = <Array<string>>this.tokenInfo.getClaimValue('http://urn:rouse:identity:claims:userrole');
            if (rolebyclientlist) {
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let i = 0; i < rolebyclientlist.length; i++) {
                    const c = rolebyclientlist[i].split(':')[0];
                    const r = rolebyclientlist[i].split(':')[1];
                    if (c === this.SelectedClient.ClientCode && r === 'downloaduser') {
                        this.HasClientAccessToDownloads = true;
                    }
                }
            }
        }
    };
}
