import { EventEmitter } from '@angular/core';

export enum ChartType {
	None = 'None',
	RevenueBench = 'main.tabs.summary.charts.client_rate_vs_bench.title',
	RevenueBook = 'main.tabs.summary.charts.client_revenue.vs_books.title',
	BenchmarkUtilization = 'main.tabs.summary.charts.client_physical_utilization.vs_bench.title',
	TotalUtilization = 'main.tabs.summary.charts.client_physical_utilization.total.title',
	BenchmarkUtilizationWeekly = 'main.tabs.summary.charts.benchmark_utilization_weekly.title',
	TotalBenchmarkUtilizationWeekly = 'main.tabs.summary.charts.client_physical_utilization.total.title_weekly',
	BenchmarkDollarUtilization = 'main.tabs.summary.charts.client_financial_utilization.vs_bench.title',
	TotalDollarUtilization = 'main.tabs.summary.charts.client_financial_utilization.total.title',
	TotalRevenueVsBenchmark = 'main.tabs.summary.charts.client_revenue.vs_bench_quartiles.title',
	RentalGrowthUnitsOnRent = 'RentalGrowthUnitsOnRent',
	RentalGrowthUnitsInFleet = 'RentalGrowthUnitsInFleet',
	RentalGrowthPhysicalUtil = 'RentalGrowthPhysicalUtil',
	RentalGrowthDollarUtil = 'RentalGrowthDollarUtil',
	RentalGrowthMonthlyRates = 'RentalGrowthMonthlyRates',
	RentalGrowthWeeklyRates = 'RentalGrowthWeeklyRates',
	RentalGrowthDailyRates = 'RentalGrowthDailyRates',
	RentalGrowthHourlyRates = 'RentalGrowthHourlyRates',
	OnRentInFleetYoY = 'main.tabs.summary.charts.client_units_on_rent_growth.title_yoy',
	OnRentInFleetMoM = 'main.tabs.summary.charts.client_units_on_rent_growth.title_mom',
	RevenueDistributionTotal = 'main.tabs.summary.charts.client_business_mix_vs_bench.title_total',
	RevenueDistributionVsBench = 'main.tabs.summary.charts.client_business_mix_vs_bench.title_vs_bench',	
	YoYRateChange = 'main.tabs.summary.charts.client_rate_change.vs_bench.yoy_title',
	MoMRateChange = 'main.tabs.summary.charts.client_rate_change.vs_bench.mom_title',
	YoYRateChangeTotal = 'main.tabs.summary.charts.client_rate_change.total.yoy_title',
	MoMRateChangeTotal = 'main.tabs.summary.charts.client_rate_change.total.mom_title'
}

export class ChartSwitcher {
	public chartChanged: EventEmitter<ChartType>;

	constructor(public selectedChart: ChartType) {
		this.chartChanged = new EventEmitter<ChartType>();
	}

	/**
	 * Returns the current chart's name translated by the given translation 
	 * function.
	 */
	public translatedCurrent(translate: (text: string) => string): string {
		return this.selectedChart ? translate(this.selectedChart) : '-';
	}

	/**
	 * Reads property within a given object according to the given path.
	 */
	public getProperty(path: string | Array<string>, object: any, separator: string = '.'): any {
		if (object) {
			const properties = Array.isArray(path) ? path : path.split(separator);
			return properties.reduce((prev, curr) => prev && prev[curr], object);
		}
	};

	isRevenueBenchChart(): boolean {
		return this.selectedChart === ChartType.RevenueBench;
	}

	isRevenueBookChart(): boolean {
		return this.selectedChart === ChartType.RevenueBook;
	}

	isBenchmarkUtilizationChart(): boolean {
		return this.selectedChart === ChartType.BenchmarkUtilization;
	}

	isBenchmarkUtilizationWeeklyChart(): boolean {
		return this.selectedChart === ChartType.BenchmarkUtilizationWeekly;
	}

	isTotalBenchmarkUtilizationWeeklyChart(): boolean {
		return this.selectedChart === ChartType.TotalBenchmarkUtilizationWeekly;
	}

	isTotalUtilizationChart(): boolean {
		return this.selectedChart === ChartType.TotalUtilization;
	}

	isBenchmarkDollarUtilizationChart(): boolean {
		return this.selectedChart === ChartType.BenchmarkDollarUtilization;
	}

	isTotalDollarUtilizationChart(): boolean {
		return this.selectedChart === ChartType.TotalDollarUtilization;
	}

	isTotalRevenueVsBenchmarkChart(): boolean {
		return this.selectedChart === ChartType.TotalRevenueVsBenchmark;
	}

	isRentalGrowthUnitsOnRentChart(): boolean {
		return this.selectedChart === ChartType.RentalGrowthUnitsOnRent;
	}

	isRentalGrowthUnitsInFleetChart(): boolean {
		return this.selectedChart === ChartType.RentalGrowthUnitsInFleet;
	}

	isRentalGrowthPhysicalUtilChart(): boolean {
		return this.selectedChart === ChartType.RentalGrowthPhysicalUtil;
	}

	isRentalGrowthDollarUtilChart(): boolean {
		return this.selectedChart === ChartType.RentalGrowthDollarUtil;
	}

	isRentalGrowthMonthlyRatesChart(): boolean {
		return this.selectedChart === ChartType.RentalGrowthMonthlyRates;
	}

	isRentalGrowthWeeklyRatesChart(): boolean {
		return this.selectedChart === ChartType.RentalGrowthWeeklyRates;
	}

	isRentalGrowthDailyRatesChart(): boolean {
		return this.selectedChart === ChartType.RentalGrowthDailyRates;
	}

	isRentalGrowthHourlyRatesChart(): boolean {
		return this.selectedChart === ChartType.RentalGrowthHourlyRates;
	}
	
	isOnRentInFleetYoYChart(): boolean {
		return this.selectedChart === ChartType.OnRentInFleetYoY;
	}

	isOnRentInFleetMoMChart(): boolean {
		return this.selectedChart === ChartType.OnRentInFleetMoM;
	}

	selectOnRentInFleetYoYChart() {
		this.selectedChart = ChartType.OnRentInFleetYoY;
		if (this.chartChanged) {
			this.chartChanged.emit(ChartType.OnRentInFleetYoY);
		}
	}

	selectOnRentInFleetMoMChart() {
		this.selectedChart = ChartType.OnRentInFleetMoM;
		if (this.chartChanged) {
			this.chartChanged.emit(ChartType.OnRentInFleetMoM);
		}
	}
	
	isRevenueDistributionTotalChart = () => {
		return this.selectedChart === ChartType.RevenueDistributionTotal;
	}

	isRevenueDistributionVsBenchChart = () => {
		return this.selectedChart === ChartType.RevenueDistributionVsBench;
	}

	isMoMRateChangeChart = () => {
		return this.selectedChart === ChartType.MoMRateChange;
	}

	isYoYRateChangeChart = () => {
		return this.selectedChart === ChartType.YoYRateChange;
	}

	isMoMRateChangeTotalChart = () => {
		return this.selectedChart === ChartType.MoMRateChangeTotal;
	}

	isYoYRateChangeTotalChart = () => {
		return this.selectedChart === ChartType.YoYRateChangeTotal;
	}

	selectRevenueBenchChart() {
		this.selectedChart = ChartType.RevenueBench;
		if (this.chartChanged) {
			this.chartChanged.emit(ChartType.RevenueBench);
		}
	}

	selectRevenueBookChart() {
		this.selectedChart = ChartType.RevenueBook;
		if (this.chartChanged) {
			this.chartChanged.emit(ChartType.RevenueBook);
		}
	}

	selectBenchmarkUtilizationChart() {
		this.selectedChart = ChartType.BenchmarkUtilization;
		if (this.chartChanged) {
			this.chartChanged.emit(ChartType.BenchmarkUtilization);
		}
	}

	selectBenchmarkUtilizationWeeklyChart() {
		this.selectedChart = ChartType.BenchmarkUtilizationWeekly;

		if (this.chartChanged) {
			this.chartChanged.emit(this.selectedChart);
		}
	}

	selectTotalBenchmarkUtilizationWeeklyChart() {
		this.selectedChart = ChartType.TotalBenchmarkUtilizationWeekly;

		if (this.chartChanged) {
			this.chartChanged.emit(this.selectedChart);
		}
	}

	selectTotalUtilizationChart() {
		this.selectedChart = ChartType.TotalUtilization;
		if (this.chartChanged) {
			this.chartChanged.emit(ChartType.TotalUtilization);
		}
	}

	selectBenchmarkDollarUtilizationChart() {
		this.selectedChart = ChartType.BenchmarkDollarUtilization;
		if (this.chartChanged) {
			this.chartChanged.emit(ChartType.BenchmarkDollarUtilization);
		}
	}

	selectTotalDollarUtilizationChart() {
		this.selectedChart = ChartType.TotalDollarUtilization;
		if (this.chartChanged) {
			this.chartChanged.emit(ChartType.TotalDollarUtilization);
		}
	}

	selectTotalRevenueVsBenchmarkChart() {
		this.selectedChart = ChartType.TotalRevenueVsBenchmark;
		if (this.chartChanged) {
			this.chartChanged.emit(ChartType.TotalRevenueVsBenchmark);
		}
	}

	selectRentalGrowthUnitsOnRent() {
		this.selectedChart = ChartType.RentalGrowthUnitsOnRent;
		if (this.chartChanged) {
			this.chartChanged.emit(ChartType.RentalGrowthUnitsOnRent);
		}
	}

	selectRentalGrowthUnitsInFleet() {
		this.selectedChart = ChartType.RentalGrowthUnitsInFleet;
		if (this.chartChanged) {
			this.chartChanged.emit(ChartType.RentalGrowthUnitsInFleet);
		}
	}

	selectRentalGrowthPhysicalUtil() {
		this.selectedChart = ChartType.RentalGrowthPhysicalUtil;
		if (this.chartChanged) {
			this.chartChanged.emit(ChartType.RentalGrowthPhysicalUtil);
		}
	}

	selectRentalGrowthDollarUtil() {
		this.selectedChart = ChartType.RentalGrowthDollarUtil;
		if (this.chartChanged) {
			this.chartChanged.emit(ChartType.RentalGrowthDollarUtil);
		}
	}

	selectRentalGrowthMonthlyRates() {
		this.selectedChart = ChartType.RentalGrowthMonthlyRates;
		if (this.chartChanged) {
			this.chartChanged.emit(ChartType.RentalGrowthMonthlyRates);
		}
	}

	selectRentalGrowthWeeklyRates() {
		this.selectedChart = ChartType.RentalGrowthWeeklyRates;
		if (this.chartChanged) {
			this.chartChanged.emit(ChartType.RentalGrowthWeeklyRates);
		}
	}

	selectRentalGrowthDailyRates() {
		this.selectedChart = ChartType.RentalGrowthDailyRates;
		if (this.chartChanged) {
			this.chartChanged.emit(ChartType.RentalGrowthDailyRates);
		}
	}
	
	selectRevenueDistributionTotalChart = () => {
		this.selectedChart = ChartType.RevenueDistributionTotal;
        if (this.chartChanged) {
	        this.chartChanged.emit(ChartType.RevenueDistributionTotal);
        }
	}

	selectRevenueDistributionVsBenchChart = () => {
		this.selectedChart = ChartType.RevenueDistributionVsBench;
		if (this.chartChanged) {
			this.chartChanged.emit(ChartType.RevenueDistributionVsBench);
		}
	}
	selectRentalGrowthHourlyRates() {
		this.selectedChart = ChartType.RentalGrowthHourlyRates;
		if (this.chartChanged) {
			this.chartChanged.emit(ChartType.RentalGrowthHourlyRates);
		}
	}

	selectRevenueDistributionChart = () => {
		this.selectedChart = ChartType.RevenueDistributionTotal;
        if (this.chartChanged) {
	        this.chartChanged.emit(ChartType.RevenueDistributionVsBench);
        }
	}

	selectYoYRateChangeChart = () => {
		this.selectedChart = ChartType.YoYRateChange;
		if (this.chartChanged) {
			this.chartChanged.emit(ChartType.YoYRateChange);
		}
	}

	selectMoMRateChangeChart = () => {
		this.selectedChart = ChartType.MoMRateChange;
		if (this.chartChanged) {
			this.chartChanged.emit(ChartType.MoMRateChange);
		}
	}

	selectYoYRateChangeTotalChart = () => {
		this.selectedChart = ChartType.YoYRateChangeTotal;
		if (this.chartChanged) {
			this.chartChanged.emit(ChartType.YoYRateChangeTotal);
		}
	}

	selectMoMRateChangeTotalChart = () => {
		this.selectedChart = ChartType.MoMRateChangeTotal;
		if (this.chartChanged) {
			this.chartChanged.emit(ChartType.MoMRateChangeTotal);
		}
	}
}
