<rdo-breadcrumb></rdo-breadcrumb>

<rdo-export-scheduler #exportScheduler [parent]="this"></rdo-export-scheduler>
<rdo-grid-message #gridMessage></rdo-grid-message>
<rdo-overlay [enabled]="isLoading" imgClass='cg-overlay-img'></rdo-overlay>
<div class="grid-container" *ngIf="showPivotView" >    
    <div class="main-content" [ngClass]="{ 'content_large': sidebarCollapsed  }">
        <ejs-pivotview #pivotview id='PivotView'
        [dataSourceSettings]="dataSourceSettings" height='100%' allowDeferLayoutUpdate='true'
        [showFieldList]="true" [showToolbar]="true" [showTooltip]="false" allowExcelExport='true' allowNumberFormatting='true' 
        allowConditionalFormatting='false' allowPdfExport='true' allowNumberFormatting='true'
        allowCalculatedField='true'
        [toolbar]='toolbarOptions' [enableValueSorting]='true' enableVirtualization='true'
        (enginePopulated)='enginePopulated($event)' (beforeServiceInvoke)='beforeServiceInvoke($event)'
        (calculatedFieldCreate)='calculatedFieldCreate($event)'
        (saveReport)='saveReport($event)' (loadReport)='loadReport($event)' (removeReport)='removeReport($event)' 
        (onFieldDropped)="onFieldDropped($event)" 
        (fetchReport)='fetchReport($event)' (renameReport)='renameReport($event)' 
        (removeReport)='removeReport($event)' (toolbarRender)='beforeToolbarRender($event)'
        [aggregateTypes]='aggregateTypesOption' (actionComplete)='onActionComplete($event)'
        ></ejs-pivotview>
    </div>
  </div>
  