import { setInterval, clearInterval } from './timer';
import { removeItem } from './utils/arrayUtils';
import { addDuration, relativeNow, ONE_MINUTE } from './utils/timeUtils';
var END_OF_TIMES = Infinity;
export var CLEAR_OLD_VALUES_INTERVAL = ONE_MINUTE;
export function createValueHistory(_a) {
  var expireDelay = _a.expireDelay,
    maxEntries = _a.maxEntries;
  var entries = [];
  var clearOldValuesInterval = setInterval(function () {
    return clearOldValues();
  }, CLEAR_OLD_VALUES_INTERVAL);
  function clearOldValues() {
    var oldTimeThreshold = relativeNow() - expireDelay;
    while (entries.length > 0 && entries[entries.length - 1].endTime < oldTimeThreshold) {
      entries.pop();
    }
  }
  /**
   * Add a value to the history associated with a start time. Returns a reference to this newly
   * added entry that can be removed or closed.
   */
  function add(value, startTime) {
    var entry = {
      value: value,
      startTime: startTime,
      endTime: END_OF_TIMES,
      remove: function () {
        removeItem(entries, entry);
      },
      close: function (endTime) {
        entry.endTime = endTime;
      }
    };
    if (maxEntries && entries.length >= maxEntries) {
      entries.pop();
    }
    entries.unshift(entry);
    return entry;
  }
  /**
   * Return the latest value that was active during `startTime`, or the currently active value
   * if no `startTime` is provided. This method assumes that entries are not overlapping.
   *
   * If `option.returnInactive` is true, returns the value at `startTime` (active or not).
   */
  function find(startTime, options) {
    if (startTime === void 0) {
      startTime = END_OF_TIMES;
    }
    if (options === void 0) {
      options = {
        returnInactive: false
      };
    }
    for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
      var entry = entries_1[_i];
      if (entry.startTime <= startTime) {
        if (options.returnInactive || startTime <= entry.endTime) {
          return entry.value;
        }
        break;
      }
    }
  }
  /**
   * Helper function to close the currently active value, if any. This method assumes that entries
   * are not overlapping.
   */
  function closeActive(endTime) {
    var latestEntry = entries[0];
    if (latestEntry && latestEntry.endTime === END_OF_TIMES) {
      latestEntry.close(endTime);
    }
  }
  /**
   * Return all values with an active period overlapping with the duration,
   * or all values that were active during `startTime` if no duration is provided,
   * or all currently active values if no `startTime` is provided.
   */
  function findAll(startTime, duration) {
    if (startTime === void 0) {
      startTime = END_OF_TIMES;
    }
    if (duration === void 0) {
      duration = 0;
    }
    var endTime = addDuration(startTime, duration);
    return entries.filter(function (entry) {
      return entry.startTime <= endTime && startTime <= entry.endTime;
    }).map(function (entry) {
      return entry.value;
    });
  }
  /**
   * Remove all entries from this collection.
   */
  function reset() {
    entries = [];
  }
  /**
   * Stop internal garbage collection of past entries.
   */
  function stop() {
    clearInterval(clearOldValuesInterval);
  }
  return {
    add: add,
    find: find,
    closeActive: closeActive,
    findAll: findAll,
    reset: reset,
    stop: stop
  };
}
