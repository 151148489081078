export class PlatformUserProfile {
    public user_profile_id: number;
    public identity_user_id: number;
    public identity_user_name: string;
    public is_rdo_app_guidance_optout: boolean;

    public hasOptedOut() {
        return this.is_rdo_app_guidance_optout;
    }

    public static isValidProfile(profile: PlatformUserProfile) {
        return !!(profile && profile.user_profile_id && profile.user_profile_id > 0 && profile.is_rdo_app_guidance_optout !== null);
    }
}
