import { FormatService } from '../query';
import { IEllipseable } from '../pipes/rdo-ellipsis.pipe';
import { IColumnConfig } from './IColumnConfig.interface';
import { TranslateService } from '@ngx-translate/core';
import { RdoBooleanPipe } from '../pipes';

export class GridTableParent {
  protected allowDoubleHeader: boolean = false;

  constructor(
    protected formatService: FormatService,
    protected translateService: TranslateService
  ) {}

  /**
   * Manual workaround that solves primeNg p-tables missalignment issue.
   */
  protected handleScrollMargin(timeout: number = 1): void {
    setTimeout(() => {
      const nonFrozenBody = document.querySelectorAll(
        '.ui-table-scrollable-body'
      )[1];
      if (nonFrozenBody) {
        const widthWithScrollBar = (<any>nonFrozenBody).offsetWidth;
        const widthWithoutScrollBar = nonFrozenBody.clientWidth;
        const scrollbarWidth = widthWithScrollBar - widthWithoutScrollBar;
        const paddedHeight =
          document.querySelectorAll(
            '.ui-table-scrollable-header.ui-widget-header'
          )[1].clientHeight + 1;
        //(<any>document.querySelectorAll('.ui-table-scrollable-header.ui-widget-header')[1]).style.marginRight = scrollbarWidth + 'px';
        (<any>(
          document.querySelectorAll('.ui-table-missing-scrollable-header')[0]
        )).style.width = scrollbarWidth + 'px';
        (<any>(
          document.querySelectorAll('.ui-table-missing-scrollable-header')[0]
        )).style.height = paddedHeight + 'px';
      }
    }, timeout);
  }

  /*
	Sets the frozen width just using columns. This is used by all tables except grid-table-configured-expandable.
	 */
  protected updateFrozenWidth(frozenRows: any[], frozenColumns: any[]): string {
    const frozenRowsWidth = this.getFrozenRowsWidth(frozenRows, frozenColumns);
    const frozenColumnsWidth = frozenColumns.reduce(
      (a, b) => a + (b.columns.reduce((c, d) => c + (d.width || 0), 0) || 0),
      0
    );
    const frozenWidth = `${Math.max(frozenColumnsWidth, frozenRowsWidth)}px`;
    return frozenWidth;
  }

  /**
   * Sets the frozen width considering the values in the frozen rows and
   * in the frozen columns.
   */
  protected updateFrozenWidthWithColumns(
    frozenRows: any[],
    frozenColumns: any[]
  ): string {
    const frozenRowsWidth = this.getFrozenRowsWidth(frozenRows, frozenColumns);
    // calculate the full width of all the visible columns
    const frozenColumnsWidth = frozenColumns.reduce(
      (a, b) =>
        a +
        (b.columns.reduce((c, d) => c + ((d.visible && d.width) || 0), 0) || 0),
      0
    );
    const frozenWidth = `${Math.max(frozenColumnsWidth, frozenRowsWidth)}px`;
    return frozenWidth;
  }

  /**
   * Returns the width assigned to the whole frozen rows group.
   *
   * Details: Frozen rows have fields which names are within the frozen columns.
   * This method compares the text length of the frozen row fields and
   * the actual frozen culumn titles and returns the maximum on each case
   * until adding them all up. This way, every column has enough room for
   * both the frozenRow value, and the frozen column title.
   */
  private getFrozenRowsWidth(frozenRows: any[], frozenColumns: any[]) {
    let wholeWidth = 0;
    if (frozenRows && frozenRows[0] && frozenColumns && frozenColumns[0]) {
      frozenColumns[0].columns.forEach((column: any) => {
        if (column.visible) {
          wholeWidth += Math.max(
            this.formatService.getTextWidth(frozenRows[0][column.field]),
            this.formatService.getTextWidth(column.title)
          );
        }
      });
    }
    return wholeWidth;
  }

  protected configureDoubleLinedColumns(columns: Array<IColumnConfig>) {
    let doubleLined = false;
    columns.forEach((column) => {
      doubleLined =
        doubleLined || (<IEllipseable>(<unknown>column)).hasTwoLines;
    });
    this.allowDoubleHeader = doubleLined;
  }

  protected handleValue(value: any, debug?: boolean, debugContext?: any) {
    let result = '';
    const type = typeof value;
    switch (type) {
      case 'boolean':
        // eslint-disable-next-line no-case-declarations
        const rbp = new RdoBooleanPipe(this.translateService);
        result = rbp.transform(value);
        break;
      case 'string':
        result = this.formatService.translateAndFormat(value, true);
        break;
      default:
        result = value;
        break;
    }

    if (!result && debug) {
      // eslint-disable-next-line
      console.log('grid-table-parent.handleValue', value, debugContext);
    }

    return result;
  }
}
