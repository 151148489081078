import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MetricsHttpService } from '../core';
import { CoreModule } from '../core/core.module';
import { UnsubscribeComponent } from './unsubscribe.component';
import { UnsubscribeService } from './unsubscribe.service';

const root = 'unsubscribe/:hash';
const unsubscriberoutes: Routes = [{
    path: root,
    component: UnsubscribeComponent,
    data: { title: 'main.core.email_list.unsubscribe_page_title' }
}
];

@NgModule({
    imports: [
        RouterModule.forChild(unsubscriberoutes),
        CommonModule, CoreModule],
    declarations: [UnsubscribeComponent],
    exports: [UnsubscribeComponent, RouterModule],
    providers: [UnsubscribeService, TranslateService, MetricsHttpService]
})
export class UnsubscribeModule { }
