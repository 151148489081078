<div class="panel panel-default rdo-card rdo-card-default" style="margin-bottom: 0; border-bottom: 0;">
    <div class="panel-body" style="padding-top: 10px;">
        <div class="row">
            <div class="col-xs-8 panel-title two-lined-ellipsed">
                {{'main.tabs.equipment.product_types.rate_types.cards.revenue_above_bench_tq' | translate}}, {{selectedCurrencySymbol}}
            </div>
            <div class="col-xs-4 text-right panel-title two-lined-ellipsed">
                {{'main.tabs.equipment.product_types.rate_types.cards.client_tq_premium' | translate}}
            </div>
        </div>
        <div class="row rdo-top-padded-value">
            <div class="col-xs-6 rdo-card-metric">
                <span [ngClass]="{'text-muted':revenueAboveTQ === 0}">{{ revenueAboveTQ | rdoCurrency }}</span>
            </div>
            <div class="col-xs-6 text-right rdo-card-metric">
                <rdo-amount-difference
                    [clientValue]="revenueAboveTQ"
                    [benchmarkedValue]="revenueAboveTQBench">
                </rdo-amount-difference>
            </div>
        </div>
    </div>
</div>
<div class="panel panel-default rdo-card rdo-card-default">
    <div class="panel-body" style="padding-top: 10px;">
        <div class="row">
            <div class="col-xs-8 panel-title two-lined-ellipsed">
                {{'main.tabs.equipment.product_types.rate_types.cards.revenue_below_bench_bq' | translate}}, {{selectedCurrencySymbol}}
            </div>
            <div class="col-xs-4 text-right panel-title two-lined-ellipsed">
                {{'main.tabs.equipment.product_types.rate_types.cards.client_bq_discount' | translate}}
            </div>
        </div>
        <div class="row rdo-top-padded-value">
            <div class="col-xs-6 rdo-card-metric">
                <span [ngClass]="{'text-muted':revenueBelowBQ === 0}">{{ revenueBelowBQ | rdoCurrency }}</span>
            </div>
            <div class="col-xs-6 text-right rdo-card-metric">
                <rdo-amount-difference 
                    [clientValue]="revenueBelowBQ"
                    [benchmarkedValue]="revenueBelowBQBench">
                </rdo-amount-difference>
            </div>
        </div>
    </div>
</div>