import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { map, mergeMap, filter } from 'rxjs/operators';
import { zip } from 'rxjs/observable/zip';

import * as _ from 'lodash';

import {
	ActiveFilterService,
	SortOptions,
	ConfigService,
	ViewService,
	ComparisonMode
} from '../core';

import { SelectedCustomerService } from './selected-customer.service';
import { CustomerService } from './customer.service';
import { CustomerCard } from '../models';


class CustomerRouteParams {
	customer: number;
	sortOn: string;
	desc: boolean;

	get sortOptions(): SortOptions {
		return { sortOn: this.sortOn, descending: this.desc };
	}

	clone = () => {
		return {
			sortOn: this.sortOn,
			desc: this.desc
		}
	}
}

@Component({
	templateUrl: 'customer.component.html',
	styleUrls: ['customer.component.scss']
})

export class CustomerComponent implements OnInit, OnDestroy {
	params: CustomerRouteParams;
	private selectedCustomer: CustomerCard;
	private allCustomerCards: Array<CustomerCard>;
	private customerCards: Array<CustomerCard>;
	private subscriptions = [];
	private currentChildRoute = '';
	private mode: ComparisonMode = ComparisonMode.Benchmark;
	private currentCardPage = 1;
	private cardPageSize = 20;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private filterService: ActiveFilterService,
		private customerService: CustomerService,
		private selectedCustomerService: SelectedCustomerService,
		private configService: ConfigService,
		private viewService: ViewService
	) {

	}

	ngOnInit() {
		if (this.params == null) {
			this.subscriptions.push(this.route.params.subscribe(params => {
				if (this.params) {
					if (this.params.customer === parseInt(params.customer, 10)) {
						return;
					}
				}

				const p = new CustomerRouteParams();
				p.customer = parseInt(params.customer, 10);
				p.sortOn = params.sortOn || '';
				p.desc = params.desc === 'true';
				const reloadCards = params.reloadCards === 'true';
				this.load(p, reloadCards);
			}));
		}

		this.subscriptions.push(
			this.router.events
				.pipe(filter(event => event instanceof NavigationEnd),
				map(() => this.route),
				map(route => {
					while (route.firstChild) {route = route.firstChild;}
					return route;
				}),
				filter(route => route.outlet === 'primary'),
				mergeMap(route => route.data))
				.subscribe(event => {
					if (event.name) {
						this.currentChildRoute = event.name === 'dashboard'
							? ''
							: event.name;
					}
				}));

		this.subscriptions.push(this.filterService.filterChange.subscribe(() => this.load(this.params, true)));
	}

	ngOnDestroy() {
		while (this.subscriptions && this.subscriptions.length > 0) {
			this.subscriptions.pop().unsubscribe();
		}

		this.selectedCustomerService.clear();
	}

	select = (selected: CustomerCard) => {
		this.selectedCustomer = selected;

		const urlTree = this.currentChildRoute
			? this.router.createUrlTree(['../', selected.CustomerId, this.params.clone(), this.currentChildRoute], { relativeTo: this.route })
			: this.router.createUrlTree(['../', selected.CustomerId, this.params.clone()], { relativeTo: this.route });

		this.router.navigateByUrl(urlTree);
		this.selectedCustomerService.notify(this.selectedCustomer);
	}

	isSelected(customer: CustomerCard): boolean {
		return !this.selectedCustomer || this.selectedCustomer.CustomerId === customer.CustomerId;
	}

	private load(params: CustomerRouteParams, reloadCards: boolean = false) {
		if (!this.allCustomerCards || reloadCards) {
			zip(
				this.customerService
					.getCustomerCards(params.customer, params.sortOptions)
					.pipe(map(d => <CustomerCard[]>d)),
				this.viewService
					.getComparisonMode())
				.subscribe((results: [CustomerCard[], ComparisonMode]) => {
					this.allCustomerCards = results[0];
					this.mode = results[1];
					this.params = params;

					const index = _.findIndex(this.allCustomerCards, (x: CustomerCard) => x.CustomerId === params.customer);
					this.selectedCustomer = this.allCustomerCards[index];

					if (!this.selectedCustomer) {
						this.router.navigate(['/customers']);
						return;
					}

					if (this.allCustomerCards.length <= 20) {
						this.customerCards = this.allCustomerCards;
					} else {
						this.currentCardPage = index <= this.cardPageSize ? this.currentCardPage : index / this.cardPageSize;
						const previousTwenty = _.slice(this.allCustomerCards, index <= this.cardPageSize ? 0 : index - this.cardPageSize, index);
						this.customerCards = _.union(previousTwenty, _.slice(this.allCustomerCards, index, index + this.cardPageSize));
					}

					this.selectedCustomerService.notify(this.selectedCustomer);
				});
		} else {
			const index = _.findIndex(this.allCustomerCards, (x: CustomerCard) => x.CustomerId === params.customer);
			this.selectedCustomer = this.allCustomerCards[index];

			this.selectedCustomerService.notify(this.selectedCustomer);
			this.params = params;
		}
	}

	getPaginatedCards = () => {
		this.currentCardPage++;
		const offset = (this.currentCardPage - 1) * this.cardPageSize;
		const nextCards = _.drop(this.allCustomerCards, offset).slice(0, this.cardPageSize);

		if (nextCards && nextCards.length) {
			this.customerCards = _.union(this.customerCards, nextCards);
		}
	}

}
