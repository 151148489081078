import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { ProductQuery } from './../../../models';
import { FilterInfoService } from '../../../filter';
import * as filterFunctions from '../../../filter/functions/filter.functions';
import {
  RevenueMappingService,
  ExcelExportService,
  FormatService,
} from './../../../core';
import { PagedResult } from '@/types';
import {
  PageOptions,
  SortOptions,
  RdoHttpService,
  ExportSpreadsheetBody,
  QueryService,
  ViewService,
  MetricsGridConfig,
} from './../../../core';

type SalesRepItem = {
  ClientProductTypeDescription: string | null;
  CustomerCount: number;
  CustomerId: TODO;
  CustomerName: string | null;
  DailyBookRate: number | null;
  DailyFloorRate: number | null;
  DailyRate: number | null;
  DailyRateBQBench: number | null;
  DailyRateBench: number | null;
  DailyRateDifference: number | null;
  DailyRateMaxBench: number | null;
  DailyRateMinBench: number | null;
  DailyRateTQBench: number | null;
  DailyRevenueBench: number | null;
  DailyRevenueBenchmarked: number | null;
  DailyRevenueBenchmarkedDifference: number | null;
  DailyRevenueBook: number | null;
  DailyRevenueBookDifference: number | null;
  DailyRevenueTotal: number | null;
  DiscountBQ: number | null;
  HourlyBookRate: number | null;
  HourlyFloorRate: number | null;
  HourlyRate: number | null;
  HourlyRateBQBench: number | null;
  HourlyRateBench: number | null;
  HourlyRateDifference: number | null;
  HourlyRateMaxBench: number | null;
  HourlyRateMinBench: number | null;
  HourlyRateTQBench: number | null;
  HourlyRevenueBench: number | null;
  HourlyRevenueBenchmarked: number | null;
  HourlyRevenueBenchmarkedDifference: number | null;
  HourlyRevenueBook: number | null;
  HourlyRevenueBookDifference: number | null;
  HourlyRevenueTotal: number | null;
  MonthlyBookRate: number | null;
  MonthlyFloorRate: number | null;
  MonthlyRate: number | null;
  MonthlyRateBQBench: number | null;
  MonthlyRateBench: number | null;
  MonthlyRateDifference: number | null;
  MonthlyRateMaxBench: number | null;
  MonthlyRateMinBench: number | null;
  MonthlyRateTQBench: number | null;
  MonthlyRevenueBench: number | null;
  MonthlyRevenueBenchmarked: number | null;
  MonthlyRevenueBenchmarkedDifference: number | null;
  MonthlyRevenueBook: number | null;
  MonthlyRevenueBookDifference: number | null;
  MonthlyRevenueTotal: number | null;
  NewMonthlyBookRate: number | null;
  NewMonthlyFloorRate: number | null;
  NewMonthlyRate: number | null;
  NewMonthlyRateBench: number | null;
  NewMonthlyRateDifference: number | null;
  NewMonthlyRevenueBench: number | null;
  NewMonthlyRevenueBenchmarked: number | null;
  NewMonthlyRevenueBenchmarkedDifference: number | null;
  NewMonthlyRevenueBook: number | null;
  NewMonthlyRevenueBookDifference: number | null;
  NewMonthlyRevenueTotal: number | null;
  PremiumTQ: number | null;
  ProductTypeCount: number | null;
  ProductTypeDescription: string | null;
  RevenueAboveTQ: number | null;
  RevenueAboveTQBench: number | null;
  RevenueBelowBQ: number | null;
  RevenueBelowBQBench: number | null;
  RevenueBench: number | null;
  RevenueBenchmarked: number | null;
  RevenueBenchmarkedDifference: number | null;
  RevenueBook: number | null;
  RevenueBookDifference: number | null;
  RevenueTotal: number | null;
  RouseProductTypeDescription: string | null;
  SalesRepId: number | null;
  SalesRepName: string | null;
  SalesRepNumber: string | null;
  TotalCount: number | null;
  TransactionCount: number | null;
  TransactionsAboveTQ: number | null;
  TransactionsBelowBQ: number | null;
  WeeklyBookRate: number | null;
  WeeklyFloorRate: number | null;
  WeeklyRate: number | null;
  WeeklyRateBQBench: number | null;
  WeeklyRateBench: number | null;
  WeeklyRateDifference: number | null;
  WeeklyRateMaxBench: number | null;
  WeeklyRateMinBench: number | null;
  WeeklyRateTQBench: number | null;
  WeeklyRevenueBench: number | null;
  WeeklyRevenueBenchmarked: number | null;
  WeeklyRevenueBenchmarkedDifference: number | null;
  WeeklyRevenueBook: number | null;
  WeeklyRevenueBookDifference: number | null;
  WeeklyRevenueTotal: number | null;
};

type SalesRepPagedResult = PagedResult<SalesRepItem>;

@Injectable()
export class ProductTypeSalesRepService {
  private isGetRequest = false;

  constructor(
    private viewService: ViewService,
    private filterInfoService: FilterInfoService,
    private queryService: QueryService,
    private rdoHttp: RdoHttpService,
    private revenueMappingService: RevenueMappingService,
    private excelExportService: ExcelExportService,
    private formatService: FormatService
  ) {
    this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
  }

  public getProductTypeSalesReps(
    options: {
      productType: string;
      category?: string;
      useRentedAsProductType?: boolean;
    },
    paging: PageOptions = new PageOptions(),
    sorting: SortOptions = new SortOptions()
  ): Observable<SalesRepPagedResult> {
    const pagedSorted = new ProductQuery();
    pagedSorted.ClientProductType = options.productType;
    if (options.category) {
      pagedSorted.RouseCategoryList = [
        this.filterInfoService.getRouseCategoryId(options.category),
      ];
    }
    if (options.useRentedAsProductType) {
      pagedSorted.UseRentedAsProductType = options.useRentedAsProductType;
    }
    this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
    return this.queryService.getPagedSorted(
      'metrics/product-types/sales-reps',
      paging,
      sorting,
      false,
      null,
      pagedSorted
    );
  }

  public getProductTypeSalesRepsDownload = (
    equipment: string,
    productType: string,
    gridConfig: MetricsGridConfig,
    useRentedAsProductType: boolean,
    category?: string,
    sorting: SortOptions = new SortOptions()
  ): Observable<object[]> => {
    const pagedSorted = new ProductQuery();
    pagedSorted.ClientProductType = productType;
    if (category) {
      pagedSorted.RouseCategoryList = [
        this.filterInfoService.getRouseCategoryId(category),
      ];
    }
    const filters = this.filterInfoService.getFilterExport();
    filters['Product Type'] = equipment;
    if (category) {
      filters.Category = category;
    }
    pagedSorted.SetSorting(sorting);
    pagedSorted.UseRentedAsProductType = useRentedAsProductType || false;
    const body = new ExportSpreadsheetBody(gridConfig.getAllColumns(), filters);
    return this.excelExportService.generateGridSpreadsheet(
      `metrics/product-types/sales-reps-download/${this.formatService.getDateLocale()}`,
      body,
      pagedSorted
    );
  };

  public getProductTypeSalesRepCustomers = (
    productType: string,
    salesRep: string,
    options: { category?: string; useRentedAsProductType?: boolean },
    sorting: SortOptions = new SortOptions()
  ) => {
    const pagedSorted = new ProductQuery();
    pagedSorted.Page = 1;
    pagedSorted.PageSize = 500;
    pagedSorted.ClientProductType = productType;
    pagedSorted.SalesRepId = salesRep;
    if (options.category) {
      pagedSorted.RouseCategoryList = [
        this.filterInfoService.getRouseCategoryId(options.category),
      ];
    }
    if (options.useRentedAsProductType) {
      pagedSorted.UseRentedAsProductType = options.useRentedAsProductType;
    }
    pagedSorted.SetSorting(sorting);
    return this.rdoHttp
      .post('metrics/product-types/sales-reps/customers', pagedSorted)
      .pipe(
        map((r: any) => {
          return r.Items;
        })
      );
  };
}
