/* eslint-disable @typescript-eslint/unbound-method, no-prototype-builtins */
import { PlatformFilterProfileField } from './platform-filter-profile-field';
import { PlatformFilterProfile } from './platform-filter-profile';
import { FilterProfile } from './filter-profile';
import { MonthSetting } from './month-settings';
import { CODENAMES } from '../../../core/constants';
 

export class FilterProfileMap {
    public clientProfile: FilterProfile;
    public userProfiles: Array<FilterProfile>;
    public static readonly exclusionKeys = [
        'filter.equipment.prime-units',
        'filter.transaction.rpo',
        'filter.transaction.substitutions',
        'filter.transaction.national-accounts',
        'filter.transaction.contracts',
        'filter.transaction.special-pricing',
        'filter.transaction.re-rents'
    ];

    /**
     * Converts the given api response into the UI format used for FilterProfiles.
     */
    public static buildFromPlatformProfiles(results: any, noProfileSelectedStr: string): FilterProfileMap {
        const filterMap: FilterProfileMap = new FilterProfileMap();
        const isValidResponse = FilterProfileMap.validateRawMap(results);
        if (isValidResponse) {
            filterMap.clientProfile = this.processRawPlatformFields(results.system, noProfileSelectedStr, -1, false, true);
            const rawUserProfiles = results.user_defined;
            filterMap.userProfiles = [];
            rawUserProfiles.forEach((userProfile: PlatformFilterProfile) => {
                const profile = this.processRawPlatformFields(
                    userProfile.fields,
                    userProfile.display_name,
                    userProfile.profile_id,
                    userProfile.is_default);
                filterMap.userProfiles.push(profile);
            });
        }
        return filterMap;
    }

    /**
     * Returns true if the given value is a valid UI FilterProfileMap.
     */
    public static validateMap(value: FilterProfileMap) {
        if (value && value.userProfiles && value.userProfiles.length) {
            return !!value && this.validateProfile(value.clientProfile) && !!value.userProfiles;
        }
        return !!value && this.validateProfile(value.clientProfile) && !!value.userProfiles;
    }

    /**
     * Returns true if the given value is a valid UI FilterProfile.
     */
    public static validateProfile(clientProfile: FilterProfile, ensureType: boolean = false): boolean {
        return !!clientProfile && Object.keys(clientProfile).length > 3
            && clientProfile[CODENAMES.CN_GEOGRAPHY_BENCHMARK_GEOGRAPHY]
            && clientProfile[CODENAMES.CN_MONTHS_TO_RETURN]
            && ((ensureType && clientProfile.hasOwnProperty('isDefault')) || !ensureType);
    }

    /**
     * Returns true if the given value is a valid platform FilterProfileMap.
     */
    public static validateRawMap(value: any) {
        return !!value && !!value.system && !!value.user_defined;
    }

    /**
     * Ignore these fields on user profiles since only the value from the client
     * profile is relevant for these fields.
     */
    public static getIgnorableFields(isUserProfile: boolean) {
        return isUserProfile ? [
            'filter.general.max-months-allowed',
            'dashboard.chart.months-to-return',
            'summary.chart.months-to-return',
            'show-hourly-rate',
            'show-custom-grids',
            'filter.general.has-rouse-categories',
            'filter.equipment.rouse-product-type',
            'filter.equipment.has-client-category',
            'filter.equipment.client-product-type',
            'filter.equipment.cat-product-group',
        ] : ['monthsCode'];
    }

    /**
     * Converts data from a raw platform profile into a friendlier format.
     * It also fills the months field based on the months code for cases
     * where the needed data is missing. 
     */
    private static processRawPlatformFields(rawData: any, name: string, id: number, isDefault: boolean, completeFields: boolean = false): FilterProfile {
        const profile = {
            profileId: id,
            profileName: name,
            isDefault: isDefault
        }
        const fields2Ignore = FilterProfileMap.getIgnorableFields(id !== -1);
        const manuallyHandled = FilterProfileMap.getManuallyHandledCases();
        rawData.forEach((field: PlatformFilterProfileField) => {
            if (!fields2Ignore.includes(field.field_name)) {
                if (!Object.keys(manuallyHandled).includes(field.field_name)) {
                    profile[field.field_name] = this.handleNotManuallyHandledValue(field);
                } else {
                    profile[field.field_name] = manuallyHandled[field.field_name](field.default_selected_values);
                }
            }
        });
        if (completeFields) {
            let result = Object.assign(new FilterProfile(), profile);
            result = this.fillMissingFields(result, manuallyHandled);
            //This has been moved to the loading of the profile in the component
            //result = this.handleDefaultMonthIds(result);
            return result;
        } else {
            return <FilterProfile>profile;
        }
    }

    private static fillMissingFields(filterProfile: FilterProfile, manuallyHandledCases: any) {
        const existingFields = Object.keys(filterProfile);
        Object.keys(manuallyHandledCases).forEach(key => {
            if (!existingFields.includes(key)) {
                filterProfile[key] = manuallyHandledCases[key](undefined);
            }
        });
        return filterProfile;
    }

    private static handleNotManuallyHandledValue(field: PlatformFilterProfileField): any {
        const isEncapsulatedValue = Array.isArray(field.default_selected_values)
            && field.default_selected_values.length === 1;
        let value: any = isEncapsulatedValue ? field.default_selected_values[0] : field.default_selected_values;
        value = value === 'True' ? true : value;
        value = value === 'False' ? false : value;
        value = value && Array.isArray(value) ? value.map(x => parseInt(x)) : value;
        value = value && (typeof value) === 'string' ? parseInt(value) : value;
        return value;
    }

    /**
     * This function has to run right before saving a profile
     * to convert the "exclude" flags values from "exclude"
     * (logic on the RDO UI) to "include" (Logic of the Admin
     * Tool and the DB) but keeping the nulls.
     */
    public static handleExclusionKeys(profile: FilterProfile) {
        Object.keys(profile).forEach(key => {
            const value = profile[key];
            if (key.includes('exclude') && (value === true || value === false)) { // null values are ignored since they mean "both"
                profile[key] = !value;
            }
        });
        return profile;
    }

    public static getPartialEmptyProfile() {
        const partialProfile = {
            'filter.transaction.outliers-selection': [],
            'filter.transaction.verticals-selection': [],
            'filter.equipment.rouse-categories': [],
            'mqa-categories-selection': [],
            'filter.geography.region': [],
            'filter.geography.district': [],
            'filter.geography.branch': [],
            'filter.general.customer-size': [],
            'filter.equipment.client-category': [],
            // 'filter.transaction.cycle-bill': [],
            'filter.equipment.cat-product-group-array': [],
            'filter.equipment.rouse-product-type-array': [],
            'filter.equipment.client-product-type-array': [],
            'filter.equipment.rouse-market-array': [],

            'filter.equipment.prime-units': null,
            'filter.transaction.rpo': null,
            'filter.transaction.substitutions': null,
            'filter.transaction.national-accounts': null,
            'filter.transaction.contracts': null,
            'filter.transaction.special-pricing': null,
            'filter.transaction.re-rents': null,
        };
        return partialProfile;
    }

    /**
     * Handles values that don't work with the regular rule.
     */
    private static getManuallyHandledCases() {
        const manuallyHandledCases = {
            months: FilterProfileMap.handleArray,
            monthsCode: FilterProfileMap.handleMonthsCode,
            'filter.transaction.outliers-selection': FilterProfileMap.handleArray,
            'filter.transaction.verticals-selection': FilterProfileMap.handleArray,
            'filter.equipment.rouse-categories': FilterProfileMap.handleArray,
            'mqa-categories-selection': FilterProfileMap.handleArray,
            'filter.geography.region': FilterProfileMap.handleArray,
            'filter.geography.district': FilterProfileMap.handleArray,
            'filter.geography.branch': FilterProfileMap.handleArray,
            'filter.geography.primary-comparison': FilterProfileMap.handleNumber,
            'filter.geography.rate-benchmark': FilterProfileMap.handleNumber,
            'filter.general.customer-size': FilterProfileMap.handleArray,
            'filter.equipment.client-category': FilterProfileMap.handleArray,
            'filter.transaction.cycle-bill': FilterProfileMap.handleArray,

            'filter.general.use-rouse-schema': FilterProfileMap.handleBoolean,
            'filter.general.has-customer-size': FilterProfileMap.handleBoolean,
            'filter.equipment.prime-units': FilterProfileMap.handleExcludeBoolean,
            'filter.transaction.rpo': FilterProfileMap.handleExcludeBoolean,
            'filter.transaction.substitutions': FilterProfileMap.handleExcludeBoolean,
            'filter.transaction.national-accounts': FilterProfileMap.handleExcludeBoolean,
            'filter.transaction.contracts': FilterProfileMap.handleExcludeBoolean,
            'filter.transaction.special-pricing': FilterProfileMap.handleExcludeBoolean,
            'filter.transaction.re-rents': FilterProfileMap.handleExcludeBoolean,
            'filter.transaction.include-not-compared-transactions': FilterProfileMap.handleBoolean,
            'report-acces-by-mqa-role': FilterProfileMap.handleBoolean,
            'rdo.feature.has-change-log': FilterProfileMap.handleBoolean,
            'filter.equipment.has-client-category': FilterProfileMap.handleBoolean,
            'filter.general.has-outlier': FilterProfileMap.handleBoolean,
            'filter.general.has-vertical': FilterProfileMap.handleBoolean,
            'filter.general.has-rouse-categories': FilterProfileMap.handleBoolean,
            'rdo.feature.has-asset-grid': FilterProfileMap.handleBoolean,

            'filter.equipment.cat-product-group-array': FilterProfileMap.handleStrArray,
            'filter.equipment.rouse-product-type-array': FilterProfileMap.handleArray,
            'filter.equipment.client-product-type-array': FilterProfileMap.handleArray,
            'filter.equipment.rouse-market-array': FilterProfileMap.handleArray,
            'filter.equipment.rouse-market': FilterProfileMap.handleBoolean,
            'filter.equipment.rouse-product-type': FilterProfileMap.handleBoolean,
            'filter.equipment.client-product-type': FilterProfileMap.handleBoolean,
            'filter.equipment.cat-product-group': FilterProfileMap.handleBoolean,

            'show-hourly-rate': FilterProfileMap.handleBoolean,

            'filter.general.allow-disallow-outliers': FilterProfileMap.handleBoolean,
            'filter.general.allow-disallow-verticals': FilterProfileMap.handleBoolean,
            'filter.general.allow-disallow-rouse-categories': FilterProfileMap.handleBoolean,
        };
        return manuallyHandledCases;
    }

    public static restoreExcludedBoolean(key: string, value: any) {
        // values within excluded keys get swapped unless they're nulls
        return FilterProfileMap.exclusionKeys.includes(key) && (value === true || value === false) ? !value : value;
    }

    private static handleMonthsCode(value: any) {
        return Array.isArray(value) && value.length && value[0] && value[0].length > 1 ? value[0] : MonthSetting.LATEST_FULL_MONTH;
    }

    private static handleNumber(value: any) {
        if (Array.isArray(value) && value.length === 1) {
            return parseInt(value[0]);
        }
        return 1;
    }

    private static handleBoolean(value: any) {
        let result = null;
        if (Array.isArray(value) && value.length === 1) {
            if (value[0] === '1' || value[0] === 'True' || value[0] === true) {
                result = true;
            }
            if (value[0] === '0' || value[0] === 'False' || value[0] === false) {
                result = false;
            }
        }
        return result;
    }

    private static handleExcludeBoolean(value: any) {
        let result = null;
        if (Array.isArray(value) && value.length === 1) {
            if (value[0] === '1' || value[0] === 'True') {
                result = false;
            }
            if (value[0] === '0' || value[0] === 'False') {
                result = true;
            }
        }
        return result;
    }

    private static handleArray(value: any) {
        if (Array.isArray(value) && value.length) {
            const filtered = value.filter(x => x !== null);
            return filtered.map(x => parseInt(x));
        } else {return [];}
    }

    private static handleStrArray(value: any) {
        if (Array.isArray(value) && value.length) {
            const filtered = value.filter(x => x !== null);
            return filtered;
        } else {return [];}
    }



    /**
     * Converts a monthsCode into an array of month ids considering the current date.
     */
    public static getMonths4MonthCode(monthsCode: string, currentMonthId: number = -1, maxMonthsAllowed: number = 36): number[] {
        maxMonthsAllowed = maxMonthsAllowed ? maxMonthsAllowed : 36;
        currentMonthId = currentMonthId !== -1 ? currentMonthId : FilterProfileMap.getCurrentMonthId();
        const latestFullMonthId = FilterProfileMap.getCurrentMonthId() > currentMonthId ? currentMonthId : currentMonthId - 1;
        let result = [currentMonthId - 1]; // Default
        switch (monthsCode) {
            case MonthSetting.LATEST_MONTH:
                result = [currentMonthId];
                break;
            case MonthSetting.LATEST_FULL_MONTH:
                result = [latestFullMonthId];
                break;
            case MonthSetting.LATEST_3_FULL_MONTHS:
                result = FilterProfileMap.rangeFromMax(latestFullMonthId, 3);
                break;
            case MonthSetting.LATEST_6_FULL_MONTHS:
                result = FilterProfileMap.rangeFromMax(latestFullMonthId, 6);
                break;
            case MonthSetting.LATEST_9_FULL_MONTHS:
                result = FilterProfileMap.rangeFromMax(latestFullMonthId, 9);
                break;
            case MonthSetting.LATEST_12_FULL_MONTHS:
                result = FilterProfileMap.rangeFromMax(latestFullMonthId, 12);
                break;
            case MonthSetting.ALL_MONTHS:
                result = FilterProfileMap.rangeFromMax(currentMonthId, maxMonthsAllowed); // Latest 3 years
                break;
            case MonthSetting.YEAR_TO_DATE:
                result = FilterProfileMap.rangeFromMax(currentMonthId, (currentMonthId - FilterProfileMap.getYearBeginningMonthId(new Date().getFullYear())) + 1);
                break;
            default:
                result = []; // Default matches LATEST_MONTH
                break;
        }
        return result;
    }

    /**
     * Runs getMonths4MonthCode but also considering the currently available monthIds
     * from the DB as well as a Selection MonthSetting.
     */
    public static getMonths4MonthCodeAndSelection(
        monthsCode: string,
        currentMonthId: number,
        availableMonths: number[],
        profileMonths: number[],
        maxMonthsAllowed: number = 36
    ): number[] {
        let validMonts2Use = [];
        const codeBasedSelection = FilterProfileMap.getMonths4MonthCode(monthsCode, currentMonthId, maxMonthsAllowed);
        const validMonthsSelection = profileMonths && profileMonths.length ?
            profileMonths.filter(x => availableMonths.includes(x)) : [];
        validMonts2Use = validMonthsSelection && validMonthsSelection.length && monthsCode === MonthSetting.SELECTION ?
            validMonthsSelection : codeBasedSelection;
        return validMonts2Use;
    }

    /**
     * Returns an array of consecutive numbers where the max value is the given max
     * minus one, and that contains a number of elements that match the given size.
     */
    private static rangeFromMax(max, size) {
        max = max + 1;
        return [...Array(size).keys()].map(x => x + max - size);
    }

    /**
     * Returns the monthId that would correspond to today's date.
     */
    public static getCurrentMonthId() {
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();
        const currentMonthId = this.getYearBeginningMonthId(currentYear) + currentMonth;
        return currentMonthId;
    }

    public static getMonthIdFromDate(theDate: Date) {
        const monthToGet = theDate.getMonth();
        const yearToGet = theDate.getFullYear();
        const currentMonthId = this.getYearBeginningMonthId(yearToGet) + monthToGet;
        return currentMonthId;
    }

    public static getYearBeginningMonthId(year: number) {
        return ((year - 2023) * 12 + 145)
    }
}
