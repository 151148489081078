import { IntroJsStep } from './intro-js-step';
import { IntroJsOptions } from './introjs-options';
import { StepActionType } from './step-action-type';
import { UserGuideStep } from './user-guide-step';

export class UserGuideOptions {
    public doneLabel: string;
    public nextLabel: string;
    public prevLabel: string;
    public setupActionCode: string;
    public exitOnOverlayClick: boolean;
    public keyboardNavigation: boolean;
    public finishingActionCode: string;
    public requiredElements: Array<string>;
    public steps: UserGuideStep[];

    constructor() {}

    /**
     * Turns the current db options into an introJs compatible set of options.
     */
    public asIntroJsOptions(): IntroJsOptions {
        const introJsOptions = new IntroJsOptions();
        introJsOptions.exitOnOverlayClick = this.exitOnOverlayClick;
        introJsOptions.keyboardNavigation = this.keyboardNavigation;
        introJsOptions.tooltipClass = 'customTooltip';
        introJsOptions.doneLabel = this.doneLabel;
        introJsOptions.nextLabel = this.nextLabel;
        introJsOptions.prevLabel = this.prevLabel;
        introJsOptions.disableInteraction = false;
        introJsOptions.showBullets = false;
        introJsOptions.showProgress = true;
        introJsOptions.steps = this.steps.map(x => {
            const step = {
                intro: x.intro,
                element: x.element,
                title: x.title,
                position: x.position
            };
            return Object.assign(new IntroJsStep(), step);
        });
        return introJsOptions;
    }

    /**
     * Returns true of there is a step of type ACTION within this set of options.
     */
    public hasActionStep(): boolean {
        const actionTypes = [StepActionType.ACTION, StepActionType.REFRESH_BACKWARDS, StepActionType.REFRESH_FORWARD];
        const found = this.steps.find(x => actionTypes.includes(x.type));
        return !!found;
    }
}
