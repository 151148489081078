﻿<div class="btn-group-vertical three-way-filter">
    <button type="button" class="three-state-item btn btn-default" tooltip="{{ falseTooltip | translate}}"
        [class.active]="excludeTransactionType===false" (click)="toggleVisible(false);"
        >{{falseLabel}}</button>
    <button type="button" class="three-state-item btn btn-default" tooltip="{{ trueTooltip | translate}}"
        [class.active]="excludeTransactionType===true" (click)="toggleVisible(true);"
        >{{trueLabel}}</button>
    <button type="button" class="three-state-item btn btn-default" tooltip="{{ nullTooltip | translate}}"
        [class.active]="excludeTransactionType===null" (click)="toggleVisible(null);"
        >{{nullLabel}}</button>
</div>
