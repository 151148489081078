import {Component, Input} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'rdo-amount-difference',
    templateUrl: 'amount-difference.component.html'
})
export class AmountDifferenceComponent {
    @Input()
    clientValue: number;
    @Input()
    benchmarkedValue: number;
    @Input()
    showCurrencySymbol: boolean;

    get difference() {
        return this.clientValue - this.benchmarkedValue;
    }

    get variance(): number {
        const diff = this.difference;
        if (this.benchmarkedValue) {
            return diff / this.benchmarkedValue;
        }
        return null;
    }

    constructor(
        private translateService: TranslateService
    ) {}
}
