import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { LazyLoadEvent } from 'primeng/api';
import {
  PageOptions,
  ColumnDefinitionService,
  MetricsGridConfig,
  ActiveFilterService,
  I18N_TITLES,
  I18N_COLUMNS,
  FormatService,
  GridTableNgComponent,
  GridSortingService,
} from './../../../core';
import { PageOptionsNg, SortOptionsNg, SortOptions } from '../../../models';
import { EquipmentService } from './../../equipment.service';
import { HeaderService } from './../../../header';
import { DownloadsService } from './../../../downloads';
import { ProductTypeTransactionQuery, Interval } from './../../../models';
import { AuthenticationService } from './../../../core/authentication/authentication.service';

@Component({
  selector: 'rdo-product-type-transactions',
  template: ` <rdo-overlay [enabled]="loading"></rdo-overlay>
    <div id="rdo-grid">
      <rdo-grid-table-ng
        *ngIf="transactions"
        #grid
        [name]="gridName"
        [gridConfig]="gridConfig"
        [pagedData]="transactions"
        [frozenRows]="frozenRows"
        [sorting]="sorting"
        [(paging)]="paging"
        (pageOnChange)="changePage($event)"
        (lazyLoadOnChange)="changeLazyLoad($event)"
        [totalCount]="totalCount"
        [frozenWidth]="frozenWidth"
        [showColumnSelector]="true"
        (downloadExcelClick)="exportExcel($event)"
      >
      </rdo-grid-table-ng>
    </div>`,
})
export class ProductTypeTransactionsComponent implements OnInit, OnDestroy {
  loading = true;
  transactions: any;
  frozenRows: any;
  paging = new PageOptionsNg();
  sorting = new SortOptionsNg({
    sortField: 'RevenueBenchmarkedDifference',
    sortOrder: 1,
  });
  totalCount = 0;
  frozenWidth: string;
  gridName = 'TRANSACTIONS_GRID';
  private query: ProductTypeTransactionQuery = {};
  protected gridConfig: MetricsGridConfig;
  private subscriptions: Array<Subscription> = [];
  @ViewChild('grid') grid: GridTableNgComponent;
  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private equipmentService: EquipmentService,
    private filterService: ActiveFilterService,
    private columnService: ColumnDefinitionService,
    private downloadsService: DownloadsService,
    private headerService: HeaderService,
    private formatService: FormatService,
    private gridSortingService: GridSortingService
  ) {}

  ngOnInit() {
    this.gridSortingService.setGridName(this.gridName);
    this.sorting = this.gridSortingService.getSortOptionsNg();

    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        //let parentParams = this.injector.get(RouteParams);
        this.subscriptions.push(
          this.route.parent.params.subscribe((parentParams) => {
            // taking value from parent component allows reusing this component in different modules (sales reps, customers)
            // e.g in case of customer product type grid, customer id will be part of parent route and product type will be
            // passed as param to this component
            //var parent = this.router.parent.parent.currentInstruction.component.params || {};
            this.query.productType =
              params.productType || parentParams.productType;
            this.query.customer = params.customer || parentParams.customer;
            this.query.salesRep = params.salesRep || parentParams.salesRep;
            this.query.useRentedAsProductType =
              params.useRentedAsProductType === 'true' ||
              parentParams.useRentedAsProductType === 'true' ||
              false;

            this.query.category = decodeURIComponent(
              parentParams.category || ''
            );

            this.query.belowBq = !!params.belowBq;
            this.query.aboveTq = !!params.aboveTq;
            this.query.avgToBq = !!params.avgToBq;
            this.query.tqToAvg = !!params.tqToAvg;
            this.query.unbenchmarked = !!params.unbenchmarked;

            this.query.fromValue = parseFloat(params.fromValue);
            this.query.toValue = parseFloat(params.toValue);

            const rateType = params.rateType;
            if (rateType) {
              this.query.rateType = Interval[<string>rateType];
            }

            this.query.sort = new SortOptions(
              'RevenueBenchmarkedDifference',
              false
            );
            this.query.paging = new PageOptions();

            this.configureGrid();

            this.subscriptions.push(
              this.authenticationService.userInfoView.subscribe((userInfo) => {
                if (this.gridConfig) {
                  this.gridConfig.enableExcelExport =
                    userInfo.HasClientAccessToExportData;
                }
              })
            );

            this.subscriptions.push(
              this.filterService.filterChange.subscribe(() => {
                this.paging.page = 1;
                if (this.grid) {
                  this.grid.resetPaginator();
                }
                this.load();
              })
            );

            this.load();
          })
        );
      })
    );
  }

  ngOnDestroy() {
    while (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.pop().unsubscribe();
    }
  }

  private load = () => {
    this.loading = true;
    this.equipmentService
      .getProductTypeTransactions(this.query)
      .subscribe((r) => {
        this.loading = false;

        if (r.Items && r.Items.length > 0) {
          this.transactions = r.Items;
        } else {
          this.transactions = [];
        }

        this.totalCount = r.TotalCount;
      });
  };

  changePage = (event: any) => {
    if (event.rows !== this.paging.pageSize) {
      this.paging.pageSize = event.rows;
    }

    this.paging.page = event.first < 1 ? 1 : event.first / event.rows + 1;
    this.query.paging.pageSize = this.paging.pageSize;
    this.query.paging.page = this.paging.page;
    this.setQuerySorting();
    this.load();
  };

  changeLazyLoad = (event: LazyLoadEvent) => {
    if (
      this.sorting.sortField === event.sortField &&
      this.sorting.sortOrder === event.sortOrder
    ) {
      return;
    }

    this.gridSortingService.setSortOption(
      event.sortField,
      event.sortOrder === -1
    );

    this.sorting.sortField =
      event.sortField || this.gridSortingService.getSortFieldOrDefault();
    this.sorting.sortOrder =
      event.sortOrder || this.gridSortingService.getSortDirectionOrDefault();

    this.setQuerySorting();
    this.load();
  };

  exportExcel = (args: any) => {
    this.loading = true;
    const translatedConfig = this.gridConfig.cloneAndTranslate((text) =>
      this.formatService.translateAndFormat(text, false)
    );
    this.subscriptions.push(
      this.equipmentService
        .getProductTypeTransactionsDownload(this.query, translatedConfig)
        .subscribe((blob) => {
          this.loading = false;
          this.downloadsService.saveExcelBlob(blob);
        })
    );
  };

  private setQuerySorting = () => {
    this.query.sort.descending = !(this.sorting.sortOrder === 1);
    this.query.sort.sortOn = this.sorting.sortField;
  };

  private configureGrid = () => {
    this.frozenWidth = '212px';

    this.gridConfig = new MetricsGridConfig(
      [
        {
          title: '',
          visible: true,
          locked: true,
          columnSelectorTitle: 'Transaction',
          expandable: false,
          columns: [
            {
              title: 'main.core.common.counts.transactions.id',
              field: 'TransactionId',
              sortColumn: 'TransactionId',
              headerStyle: {
                'text-align': 'left',
              },
              cellStyle: {
                'text-align': 'left',
              },
              width: 212,
              isString: true,
            },
          ],
        },
        this.columnService.EquipmentTypeDetailColumnGroup(),
        this.columnService.TotalRevenueColumnGroup(),
        this.columnService.BenchmarkedRevenueComparisonColumnGroup(false),
        this.columnService.TopQuartileColumnGroup(),
        this.columnService.BottomQuartileColumnGroup(),

        this.columnService.MonthlyRatesColumnGroup(),
        this.columnService.WeeklyRatesColumnGroup(),
        this.columnService.DailyRatesColumnGroup(),
        this.columnService.HourlyRatesColumnGroup(),
        this.columnService.OtherRevenueColumnGroup(),
        {
          title: I18N_TITLES.SALES_REP_AND_CUSTOMER,
          columnSelectorTitle: I18N_TITLES.SALES_REP_AND_CUSTOMER,
          visible: true,
          expandable: true,
          columns: [
            {
              title: I18N_COLUMNS.SALES_REP_NAME,
              field: 'SalesRepName',
              sortColumn: 'SalesRepName',
              headerStyle: {
                'text-align': 'left',
              },
              cellStyle: {
                'text-align': 'left',
              },
              width: 212,
              isString: true,
            },
            {
              title: I18N_COLUMNS.CUSTOMER_NAME,
              field: 'CustomerName',
              sortColumn: 'CustomerName',
              headerStyle: {
                'text-align': 'left',
              },
              cellStyle: {
                'text-align': 'left',
              },
              width: 212,
              isString: true,
            },
            {
              title: I18N_COLUMNS.CUSTOMER_NO,
              field: 'CustomerNumber',
              sortColumn: 'CustomerNumber',
              isString: true,
            },
          ],
        },
        this.columnService.TransactionAttributesColumnGroup(),
        this.columnService.LocationDetailsColumnGroup(),
      ],
      null,
      true
    );
  };
}
