import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import * as _ from 'lodash';
import * as filterFunctions from './../../filter/functions/filter.functions';
import { FilterDefault } from './../../models';
import { ClientProfileService } from './../client';
import { AuthenticationService } from '../authentication/authentication.service';
import { FilterProfileService } from '../../filter/profiles/filter-profile.service';
import { CODENAMES } from '../constants';

@Injectable()
export class ChangesLogGuardService  {
	private hasChangeLog?: boolean;

    constructor(
        private authenticationService: AuthenticationService, 
        private router: Router,
        private filterProfileService: FilterProfileService
    ) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.checkAccess();
	};

    private checkAccess = (): Observable<boolean> => {
        const isauthenticated = this.authenticationService.isauthenticated;
        if (isauthenticated === false) {
            this.authenticationService.login();
        } else {
            if (_.isNil(this.hasChangeLog)) {
                return this.filterProfileService.wait4CurrentProfile().pipe(map(() => {
                    this.hasChangeLog = !!this.filterProfileService.readClientProfileProperty(CODENAMES.CN_HAS_CHANGES_LOG);
                    if (!this.hasChangeLog) {
                        this.router.navigate(['/summary']);
                    }
                    return this.hasChangeLog;
                }));
            } else {
                if (!this.hasChangeLog) {
                    this.router.navigate(['/summary']);
                }
                return of(this.hasChangeLog);
            }
        }
	}
}
