import { DOCS_TROUBLESHOOTING, MORE_DETAILS, display } from '../tools/display';
import { objectValues } from '../tools/utils/polyfills';
import { isPageExitReason } from '../browser/pageExitObservable';
import { jsonStringify } from '../tools/serialisation/jsonStringify';
import { computeBytesCount } from '../tools/utils/byteUtils';
export function createBatch(_a) {
  var encoder = _a.encoder,
    request = _a.request,
    flushController = _a.flushController,
    messageBytesLimit = _a.messageBytesLimit;
  var upsertBuffer = {};
  var flushSubscription = flushController.flushObservable.subscribe(function (event) {
    return flush(event);
  });
  function push(serializedMessage, estimatedMessageBytesCount, key) {
    flushController.notifyBeforeAddMessage(estimatedMessageBytesCount);
    if (key !== undefined) {
      upsertBuffer[key] = serializedMessage;
      flushController.notifyAfterAddMessage();
    } else {
      encoder.write(encoder.isEmpty ? serializedMessage : "\n".concat(serializedMessage), function (realMessageBytesCount) {
        flushController.notifyAfterAddMessage(realMessageBytesCount - estimatedMessageBytesCount);
      });
    }
  }
  function hasMessageFor(key) {
    return key !== undefined && upsertBuffer[key] !== undefined;
  }
  function remove(key) {
    var removedMessage = upsertBuffer[key];
    delete upsertBuffer[key];
    var messageBytesCount = encoder.estimateEncodedBytesCount(removedMessage);
    flushController.notifyAfterRemoveMessage(messageBytesCount);
  }
  function addOrUpdate(message, key) {
    var serializedMessage = jsonStringify(message);
    var estimatedMessageBytesCount = encoder.estimateEncodedBytesCount(serializedMessage);
    if (estimatedMessageBytesCount >= messageBytesLimit) {
      display.warn("Discarded a message whose size was bigger than the maximum allowed size ".concat(messageBytesLimit, "KB. ").concat(MORE_DETAILS, " ").concat(DOCS_TROUBLESHOOTING, "/#technical-limitations"));
      return;
    }
    if (hasMessageFor(key)) {
      remove(key);
    }
    push(serializedMessage, estimatedMessageBytesCount, key);
  }
  function flush(event) {
    var upsertMessages = objectValues(upsertBuffer).join('\n');
    upsertBuffer = {};
    var isPageExit = isPageExitReason(event.reason);
    var send = isPageExit ? request.sendOnExit : request.send;
    if (isPageExit &&
    // Note: checking that the encoder is async is not strictly needed, but it's an optimization:
    // if the encoder is async we need to send two requests in some cases (one for encoded data
    // and the other for non-encoded data). But if it's not async, we don't have to worry about
    // it and always send a single request.
    encoder.isAsync) {
      var encoderResult = encoder.finishSync();
      // Send encoded messages
      if (encoderResult.outputBytesCount) {
        send(formatPayloadFromEncoder(encoderResult));
      }
      // Send messages that are not yet encoded at this point
      var pendingMessages = [encoderResult.pendingData, upsertMessages].filter(Boolean).join('\n');
      if (pendingMessages) {
        send({
          data: pendingMessages,
          bytesCount: computeBytesCount(pendingMessages)
        });
      }
    } else {
      if (upsertMessages) {
        encoder.write(encoder.isEmpty ? upsertMessages : "\n".concat(upsertMessages));
      }
      encoder.finish(function (encoderResult) {
        send(formatPayloadFromEncoder(encoderResult));
      });
    }
  }
  return {
    flushController: flushController,
    add: addOrUpdate,
    upsert: addOrUpdate,
    stop: flushSubscription.unsubscribe
  };
}
function formatPayloadFromEncoder(encoderResult) {
  var data;
  if (typeof encoderResult.output === 'string') {
    data = encoderResult.output;
  } else {
    data = new Blob([encoderResult.output], {
      // This will set the 'Content-Type: text/plain' header. Reasoning:
      // * The intake rejects the request if there is no content type.
      // * The browser will issue CORS preflight requests if we set it to 'application/json', which
      // could induce higher intake load (and maybe has other impacts).
      // * Also it's not quite JSON, since we are concatenating multiple JSON objects separated by
      // new lines.
      type: 'text/plain'
    });
  }
  return {
    data: data,
    bytesCount: encoderResult.outputBytesCount,
    encoding: encoderResult.encoding
  };
}
