import { Component, Input, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';

import {
	RentalGrowthData,
	IRentalGrowthItem,
	RentalGrowthItemType,
	RentalGrowthType,
	RentalGrowthPanelEventService
} from './../../../core';

import {
	RentalGrowthCallOutComponent
} from './index';
import { TranslateService } from '@ngx-translate/core';
/* eslint-disable  @angular-eslint/component-selector */
@Component({
	selector: 'rental-growth-grid',
	templateUrl: 'rental-growth-grid.component.html',
	styleUrls: ['rental-growth-grid.component.scss']
})
export class RentalGrowthGridComponent implements OnInit, OnDestroy {
	@Input() private data: RentalGrowthData;
	private panelEvent: any;
	private subscriptions = [];

	constructor(
		private translateService: TranslateService,
		private rentalGrowthPanelEventService: RentalGrowthPanelEventService
	) {	}

	ngOnInit(): void {
		this.subscriptions.push(this.rentalGrowthPanelEventService.panelEventChange.subscribe(evt => {
			this.panelEvent = evt;
			if (this.panelEvent && this.panelEvent.eventType === 'mouseleave') {
				this.panelEvent = null;
			}
		}));
	}

	ngOnDestroy(): void {
		while (this.subscriptions && this.subscriptions.length > 0) {
			this.subscriptions.pop().unsubscribe();
		}
	}

	typeChange(): string {
		let text = '';
		if (this.data.type === RentalGrowthType.yearly) {
			text = this.translateService.instant('main.tabs.equipment.product_types.yoy_change');
		} else if (this.data.type === RentalGrowthType.monthly) {
			text = this.translateService.instant('main.tabs.equipment.product_types.mom_change');
		}
		return text;
	}

	typeChangeDiff(): string {
		let text = '';
		if (this.data.type === RentalGrowthType.yearly) {
			text = this.translateService.instant('main.tabs.equipment.product_types.change.table.yoy_change_diff');
		} else if (this.data.type === RentalGrowthType.monthly) {
			text = this.translateService.instant('main.tabs.equipment.product_types.change.table.mom_change_diff');
		}
		return text;
	}

	units(data: string) {
		let text = '';
		if (data) {
			text = this.translateService.instant('main.core.common.counts.units');
			text = text.charAt(0).toUpperCase() + text.substr(1, text.length).toLowerCase();
		}
		return text;
	}

	pts(data: string) {
		let text = '';
		if (data) {
			text = this.translateService.instant('main.core.common.counts.pts');
		}
		return text;
	}
}

