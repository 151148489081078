import { ONE_MINUTE, find } from '@datadog/browser-core';
import { createPerformanceObservable, RumPerformanceEntryType } from '../../../browser/performanceObservable';
// Discard FCP timings above a certain delay to avoid incorrect data
// It happens in some cases like sleep mode or some browser implementations
export var FCP_MAXIMUM_DELAY = 10 * ONE_MINUTE;
export function trackFirstContentfulPaint(configuration, firstHidden, callback) {
  var performanceSubscription = createPerformanceObservable(configuration, {
    type: RumPerformanceEntryType.PAINT,
    buffered: true
  }).subscribe(function (entries) {
    var fcpEntry = find(entries, function (entry) {
      return entry.name === 'first-contentful-paint' && entry.startTime < firstHidden.timeStamp && entry.startTime < FCP_MAXIMUM_DELAY;
    });
    if (fcpEntry) {
      callback(fcpEntry.startTime);
    }
  });
  return {
    stop: performanceSubscription.unsubscribe
  };
}
