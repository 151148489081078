<div class="container-fluid" id="category">
	<rdo-overlay [enabled]="cardChanging"></rdo-overlay>
	<div class="row scroll-container">
		<div class="rdo-col-card-fixed scroll categories-scroll-list" (scroll)="scrollHandler()">
			<div class="rdo-card-list">
				<rdo-nav-card *ngFor="let c of cards | slice: 0:categoriesPageSize * categoriesPage"
                              [data]="c"
                              [titleSelector]="'RouseCategory'"
                              [selected]="isSelected(c)"
                              [defaultTitle]="'main.tabs.equipment.all_title'"
                              [comparisonMode]="mode"
                              (selectedChange)="selectCategory(c)"
                              [rdo-scroll-into]="isSelected(c)"></rdo-nav-card>
			</div>
		</div>
		<div class="rdo-col-card-offset">
			<div class="row">
				<div class="col-xs-12">
					<h3 style="line-height: 40px; margin-bottom: 0;" *ngIf="selectedCategory"
						id="selected-category-header">
						<rdo-category-icon [category]="selectedCategory.CategoryIcon || selectedCategory.RouseCategory">
						</rdo-category-icon>&nbsp;
						{{selectedCategory.RouseCategory || ('main.tabs.equipment.all_title' | translate)}}
					</h3>
				</div>
			</div>
			<div class="row">
				<div class="col-xs-12">
					<rdo-overlay [enabled]="loading"></rdo-overlay>
					<div id="product-type-grid">
						<div id="rdo-grid">
							<rdo-grid-table-configured-expandable
                                #productTypeGrid
                                *ngIf="productTypes"
                                [name]="GRID_NAME"
                                [dataKey]="dataKey"
								[gridConfig]="gridConfig"
                                [columnSelectorConfig]="columnSelectorConfig"
                                (gridConfigChange)="onGridConfigChange($event)"
                                [pagedData]="productTypes"
                                [frozenRows]="frozenRows"
                                [rowExpansion]="gridConfig.rowExpansion"
								[sorting]="sorting"
                                [(paging)]="paging"
                                (pageOnChange)="changePage($event)"
								(lazyLoadOnChange)="changeLazyLoad($event)"
                                [totalCount]="totalCount"
								[frozenWidth]="frozenWidth"
								(downloadExcelClick)="exportExcel($event)"
                                [useConfiguredColumnSelector]="true"
                                [disableExpandability]="!isExpandableView"
                                [storage]="'local'"
                            />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
