import { Component, OnDestroy } from '@angular/core';
import { BreadcrumbService, InstructionInfo } from '../core';
import { SelectedAssetService } from './selected-asset.service';
import * as _ from 'lodash';

@Component({
	template: ''
})
export class RentalAssetBaseComponent implements OnDestroy {
	protected subscriptions: any[] = [];

	constructor(
		protected selectedAssetService: SelectedAssetService,
		protected breadcrumbService: BreadcrumbService
	) {	}

	protected updateBreadcrumbs(instructions: Array<InstructionInfo>) {
		this.selectedAssetService.Asset.subscribe(c => {
			const baseInstructions = [
				{
					title: 'main.tabs.equipment.rental_assets.all',
					linkDSL: ['/rental-assets']
				},
				{
					title: `${c.ModelYear || ''} ${c.Make || ''} ${c.Model || ''}`,
					linkDSL: ['/rental-assets', c.RouseEquipmentID]
				}
			];
			const breadcrumInstructions = _.union<InstructionInfo>(baseInstructions, instructions);
			this.breadcrumbService.update(breadcrumInstructions);
		});
	}

	ngOnDestroy() {
		while (this.subscriptions && this.subscriptions.length > 0) {
			this.subscriptions.pop().unsubscribe();
		}
	}
}
