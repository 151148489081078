import { trackFirstContentfulPaint } from './trackFirstContentfulPaint';
import { trackFirstInput } from './trackFirstInput';
import { trackNavigationTimings } from './trackNavigationTimings';
import { trackLargestContentfulPaint } from './trackLargestContentfulPaint';
import { trackFirstHidden } from './trackFirstHidden';
export function trackInitialViewMetrics(configuration, setLoadEvent, scheduleViewUpdate) {
  var initialViewMetrics = {};
  var stopNavigationTracking = trackNavigationTimings(configuration, function (navigationTimings) {
    setLoadEvent(navigationTimings.loadEvent);
    initialViewMetrics.navigationTimings = navigationTimings;
    scheduleViewUpdate();
  }).stop;
  var firstHidden = trackFirstHidden(configuration);
  var stopFCPTracking = trackFirstContentfulPaint(configuration, firstHidden, function (firstContentfulPaint) {
    initialViewMetrics.firstContentfulPaint = firstContentfulPaint;
    scheduleViewUpdate();
  }).stop;
  var stopLCPTracking = trackLargestContentfulPaint(configuration, firstHidden, window, function (largestContentfulPaint) {
    initialViewMetrics.largestContentfulPaint = largestContentfulPaint;
    scheduleViewUpdate();
  }).stop;
  var stopFIDTracking = trackFirstInput(configuration, firstHidden, function (firstInput) {
    initialViewMetrics.firstInput = firstInput;
    scheduleViewUpdate();
  }).stop;
  function stop() {
    stopNavigationTracking();
    stopFCPTracking();
    stopLCPTracking();
    stopFIDTracking();
    firstHidden.stop();
  }
  return {
    stop: stop,
    initialViewMetrics: initialViewMetrics
  };
}
