"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var isScheduler_1 = require("../util/isScheduler");
var fromArray_1 = require("./fromArray");
var scheduleArray_1 = require("../scheduled/scheduleArray");
function of() {
  var args = [];
  for (var _i = 0; _i < arguments.length; _i++) {
    args[_i] = arguments[_i];
  }
  var scheduler = args[args.length - 1];
  if (isScheduler_1.isScheduler(scheduler)) {
    args.pop();
    return scheduleArray_1.scheduleArray(args, scheduler);
  } else {
    return fromArray_1.fromArray(args);
  }
}
exports.of = of;
