import { NgModule, ValueProvider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PivotFieldListComponent } from './pivotfieldlist.component';
import { PivotFieldListModule } from './pivotfieldlist.module';
/**
 * NgModule definition for the PivotFieldList component with providers.
 */
export class PivotFieldListAllModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [CommonModule, PivotFieldListModule],
    exports: [
        PivotFieldListModule
    ],
    providers:[
        
    ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function PivotFieldListAllModule_tsickle_Closure_declarations() {
/** @type {?} */
PivotFieldListAllModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
PivotFieldListAllModule.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
