import { OnInit, OnDestroy, ViewChild, Directive } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';
import { LazyLoadEvent } from 'primeng/api';
import {
  ColumnDefinitionService,
  IGridColumnGroup,
  MetricsGridConfig,
  ActiveFilterService,
  FormatService,
  GridTableNgComponent,
  GridSortingService,
} from '../core';
import { PageOptionsNg, SortOptionsNg, SortOptions } from '../models';
import { HeaderService } from '../header';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
@Directive()
export abstract class GeographyComponent implements OnInit, OnDestroy {
  loading = true;
  data: any;
  frozenRows: any;
  paging = new PageOptionsNg();
  sorting: SortOptionsNg;
  totalCount = 0;
  frozenWidth: string;
  gridConfig: MetricsGridConfig;
  hasAccess = false;
  hasData = false;
  dataKey: string;
  protected subscriptions: Array<Subscription> = [];
  protected gridName = 'GEOGRAPHY_GRID';
  protected useExpandedGrid = false;
  @ViewChild('grid') grid: GridTableNgComponent;
  @ViewChild('gridExpanded') gridExpanded: GridTableNgComponent;

  constructor(
    protected authenticationService: AuthenticationService,
    protected filterService: ActiveFilterService,
    protected columnService: ColumnDefinitionService,
    protected headerService: HeaderService,
    protected formatService: FormatService,
    protected translateService: TranslateService,
    protected gridSortingService: GridSortingService,
    protected router: Router
  ) {}

  ngOnInit() {
    this.authenticationService.startSentryTransaction(
      this.router.routerState.snapshot.url
    );
    this.gridSortingService.setGridName(this.gridName);
    this.sorting = this.gridSortingService.getSortOptionsNg();

    this.gridConfig = this.configureGrid();
    this.load();
    this.subscriptions.push(
      this.filterService.filterChange.subscribe(() => {
        this.paging.page = 1;
        if (this.grid) {
          this.grid.resetPaginator();
        }
        if (this.gridExpanded) {
          this.gridExpanded.resetPaginator();
        }
        this.load();
      })
    );

    this.subscriptions.push(
      this.authenticationService.userInfoView.subscribe((userInfo) => {
        this.hasAccess = userInfo.HasClientAccessToDownloads;
        this.gridConfig.enableExcelExport = this.exportEnabled();
      })
    );

    this.translateService.onLangChange.subscribe(() => this.load());
  }

  ngOnDestroy() {
    _.each(this.subscriptions, (s) => s.unsubscribe());
  }

  abstract loadData(): Observable<any>;
  abstract exportExcel(args: any);
  abstract getFixedColumnGroup(): IGridColumnGroup;
  abstract configureGrid(): MetricsGridConfig;

  load = () => {
    this.loading = true;
    this.loadData().subscribe((x) => {
      this.loading = false;

      if (x.Items && x.Items.length > 0) {
        this.data = x.Items.splice(1);
        this.frozenRows = [x.Items[0]];
      } else {
        this.data = [];
        this.frozenRows = [];
      }

      this.totalCount = x.TotalCount;

      this.hasData = x && !!x.PageCount;
      this.gridConfig.enableExcelExport = this.exportEnabled();
      this.authenticationService.finishSentryTransaction();
    });
  };

  changePage = (event: any) => {
    if (event.rows !== this.paging.pageSize) {
      this.paging.pageSize = event.rows;
    }

    this.paging.page = event.first < 1 ? 1 : event.first / event.rows + 1;
    this.load();
  };

  changeLazyLoad = (event: LazyLoadEvent) => {
    if (
      this.sorting.sortField === event.sortField &&
      this.sorting.sortOrder === event.sortOrder
    ) {
      return;
    }

    this.gridSortingService.setSortOption(
      event.sortField,
      event.sortOrder === -1
    );

    this.sorting.sortField =
      event.sortField || this.gridSortingService.defaultSortColumn;
    this.sorting.sortOrder = event.sortOrder || -1;

    this.load();
  };

  getColumnGroups = (): IGridColumnGroup[] => {
    return this.columnService.ExtendedMetricSetColumnGroups();
  };

  exportEnabled = (): boolean => {
    return this.gridConfig && this.hasData && this.hasAccess;
  };

  protected getSorting = (): SortOptions => {
    return new SortOptions(
      this.sorting.sortField,
      !(this.sorting.sortOrder === 1)
    );
  };
}
