<div class="dropdown rdo-chart-buttons-container" [style.top]="topOffset" *ngIf="downloadEnabled()">
    <button id="export-chart-btn" class="dropdown-toggle rdo-chart-button rdo-chart-main-button" data-toggle="dropdown"
    >{{'main.core.charts.export.button_text' | translate}}</button>
    <ul class="dropdown-menu dropdown-menu-right" role="menu">
        <li id="download-xlsx">
            <button (click)="xlsx()" id="download-xlsx-btn" class="rdo-chart-button">{{'main.core.charts.export.download_excel' | translate}}</button>
        </li>
        <li id="download-jpeg">
            <button (click)="jpeg()" id="download-jpeg-btn" class="rdo-chart-button">{{'main.core.charts.export.download_jpeg' | translate}}</button>
        </li>
    </ul>
</div>