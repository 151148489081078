<div id="unauthorized-dialog" class="_container-fluid _modal-client-select" style="background-color:#555555;">
    <div class="modal-header">
        <h4 class="pull-left" style="color:white;">
            <img class="auth0-lock-header-logo" src="https://downloads.rouseservices.com/auth0/images/rse_analytics.svg" />
        </h4>
        <h4 class="pull-right" style="color:white;">&nbsp;</h4>
    </div>
    <div style="padding: 20px 20px 20px 20px;">
        <div class="row">
            <div class="col-md-12" style="color:white;">
                <h2>
                    {{'main.core.auth.unauthorized' | translate}}
                </h2>
            </div>
        </div>
    </div>
    <div class="row">
        &nbsp;
    </div>
    <div class="row">
        <p style="color:white;max-width:300px;padding-left:32px;padding-right:32px;">
            {{'main.core.auth.you_are_not_authorized' | translate}} 
            {{'main.core.auth.sign_with_other_account' | translate}}
        </p>
    </div>
    <div class="row">
        &nbsp;
    </div>
    <div class="row">
        &nbsp;
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="modal-footer" style="text-align: right;">
                <button id="UnauthorizedCancelButton" (click)="closeClick()">
                    <i class="fa fa-times"></i>&nbsp;&nbsp;&nbsp;{{'main.core.auth.back_to_sign_in' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>
