import { Injectable } from '@angular/core';
import { QueryParams } from '../core/http/query-params';
import { Observable } from 'rxjs/Observable';
import { map, switchMap } from 'rxjs/operators';

import { PagedQuery, GeographyQuery, ExportSpreadsheetBody } from '../models';

import {
	PageOptions,
	SortOptions,
	RdoHttpService,
	ViewService,
	ComparisonModeSortOptions,
	QueryService,
	MetricsGridConfig,
	ExcelExportService
} from './../core';

import { FilterInfoService } from '../filter';
import * as filterFunctions from './../filter/functions/filter.functions';

import * as _ from 'lodash';

@Injectable()
export class GeographyService {
	private isGetRequest = false;

	constructor(
		private rdoHttp: RdoHttpService,
		private viewService: ViewService,
		private filterInfoService: FilterInfoService,
		private queryService: QueryService,
		private excelExportService: ExcelExportService
	) {
		this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
	}

	public getTopBranches = (): Observable<any> => {
		this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
		return this.viewService.getComparisonModeSortOptions(ComparisonModeSortOptions.Revenue).pipe(switchMap(sorting => {
			const pagedSorted = new PagedQuery();

			pagedSorted.SetSorting(sorting);

			if (this.isGetRequest) {
				return this
					.rdoHttp
					.get('metrics/summary/branches')
					.pipe(map(res => res as any));
			} else {
				return this
					.rdoHttp
					.post('metrics/summary/branches', pagedSorted)
					.pipe(map(res => res as any));
			}
		}));
	}

	public getTopDistricts = (): Observable<any> => {
		this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
		return this.viewService.getComparisonModeSortOptions(ComparisonModeSortOptions.Revenue).pipe(switchMap(sorting => {
			const pagedSorted = new PagedQuery();

			pagedSorted.SetSorting(sorting);

			if (this.isGetRequest) {
				return this
					.rdoHttp
					.get('metrics/summary/branches')
					.pipe(map(res => res as any));
			} else {
				return this
					.rdoHttp
					.post('metrics/summary/districts', pagedSorted)
					.pipe(map(res => res as any));
			}
		}));
	}

	public getTopRegions = (): Observable<any> => {
		this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
		return this.viewService.getComparisonModeSortOptions(ComparisonModeSortOptions.Revenue).pipe(switchMap(sorting => {
			const pagedSorted = new PagedQuery();

			pagedSorted.SetSorting(sorting);

			if (this.isGetRequest) {
				return this
					.rdoHttp
					.get('metrics/summary/branches')
					.pipe(map(res => res as any));
			} else {
				return this
					.rdoHttp
					.post('metrics/summary/regions', pagedSorted)
					.pipe(map(res => res as any));
			}
		}));
	}

	public getBranches = (
		district?: number,
		paging: PageOptions = new PageOptions(),
		sorting: SortOptions = new SortOptions(),
		includeTotal: boolean = false): Observable<any> => {
		this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);

		if (this.isGetRequest) {
			const params = new QueryParams();
			const exclude = (district) ? ['DistrictList'] : [];

			if (district) {
				params.append('DistrictList', district.toString());
			}

			params.set('IncludeTotal', includeTotal.toString());

			return this.queryService.getfxPagedSorted(exclude, 'metrics/branches', paging, sorting, this.isGetRequest, params);
		} else {
			const query = new GeographyQuery();

			if (district) {
				query.DistrictList = [district];
			}

			query.IncludeTotal = includeTotal;

			return this.queryService.getPagedSorted('metrics/branches', paging, sorting, false, null, query);
		}
	}

	public getBranchesDownload = (sorting: SortOptions = new SortOptions(), gridConfig: MetricsGridConfig): Observable<Record<any, any>[]> => {
		const pagedSorted = new PagedQuery();
		pagedSorted.SetSorting(sorting);
		const body = new ExportSpreadsheetBody(gridConfig.getAllColumns(), this.filterInfoService.getFilterExport());
		return this.excelExportService.generateGridSpreadsheet('metrics/branches-download', body, pagedSorted);
	}

	public getDistricts(
		region?: number,
		paging: PageOptions = new PageOptions(),
		sorting: SortOptions = new SortOptions(),
		includeTotal: boolean = false): Observable<any> {
		this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);

		if (this.isGetRequest) {
			const params = new QueryParams();
			const exclude = (region) ? ['RegionList'] : [];

			if (region) {
				params.append('RegionList', decodeURIComponent(region.toString()));
			}

			params.set('IncludeTotal', includeTotal.toString());
			return this.queryService.getfxPagedSorted(exclude, 'metrics/districts', paging, sorting, true, params);
		} else {
			const geoBody = new GeographyQuery();
			if (region) {
				geoBody.RegionList = [region];
			}

			geoBody.IncludeTotal = includeTotal;
			return this.queryService.getPagedSorted('metrics/districts', paging, sorting, false, null, geoBody);
		}

	}

	public getDistrictsDownload = (sorting: SortOptions = new SortOptions(), gridConfig: MetricsGridConfig): Observable<Record<any, any>[]> => {
		const pagedSorted = new PagedQuery();
		pagedSorted.SetSorting(sorting);
		const body = new ExportSpreadsheetBody(gridConfig.getAllColumns(), this.filterInfoService.getFilterExport());
		return this.excelExportService.generateGridSpreadsheet('metrics/districts-download', body, pagedSorted);
	}

	public getRegions(
		paging: PageOptions = new PageOptions(),
		sorting: SortOptions = new SortOptions(),
		includeTotal: boolean = false): Observable<any> {
		this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);

		if (this.isGetRequest) {
			const params = new QueryParams();
			params.set('IncludeTotal', includeTotal.toString());
			return this.queryService.getPagedSorted('metrics/regions', paging, sorting, true, params);
		} else {
			const pagedSorted = new PagedQuery();
			pagedSorted.IncludeTotal = includeTotal;
			return this.queryService.getPagedSorted('metrics/regions', paging, sorting, false, null, pagedSorted);
		}
	}

	public getRegionsDownload = (sorting: SortOptions = new SortOptions(), gridConfig: MetricsGridConfig): Observable<Record<any, any>[]> => {
		const pagedSorted = new PagedQuery();
		pagedSorted.SetSorting(sorting);
		const body = new ExportSpreadsheetBody(gridConfig.getAllColumns(), this.filterInfoService.getFilterExport());
		return this.excelExportService.generateGridSpreadsheet('metrics/regions-download', body, pagedSorted);
	}
}

