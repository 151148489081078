import { Observable } from '../Observable';
export function range(start = 0, count, scheduler) {
  return new Observable(subscriber => {
    if (count === undefined) {
      count = start;
      start = 0;
    }
    let index = 0;
    let current = start;
    if (scheduler) {
      return scheduler.schedule(dispatch, 0, {
        index,
        count,
        start,
        subscriber
      });
    } else {
      do {
        if (index++ >= count) {
          subscriber.complete();
          break;
        }
        subscriber.next(current++);
        if (subscriber.closed) {
          break;
        }
      } while (true);
    }
    return undefined;
  });
}
export function dispatch(state) {
  const {
    start,
    index,
    count,
    subscriber
  } = state;
  if (index >= count) {
    subscriber.complete();
    return;
  }
  subscriber.next(start);
  if (subscriber.closed) {
    return;
  }
  state.index = index + 1;
  state.start = start + 1;
  this.schedule(state);
}
