import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StepActionType, UserGuide, UserGuideOptions, UserGuideStep } from '../models';
import { WELCOME_GUIDE } from '../guides';


@Injectable({providedIn: 'root'})
export class IntroJsApiService {

  constructor() { }

  public loadUserGuides(): Observable<UserGuide[]> {
    return new Observable<UserGuide[]>(obs => {
      try {
        // Simulate an api call with a 500ms timeout
        setTimeout(() => {
          obs.next(this.getMockedUserGuides());
          obs.complete();
        }, 500);
      } catch(err) {
        obs.error(err);
      }
    });
  }

  private getMockedUserGuides(): UserGuide[] {
    return [WELCOME_GUIDE, this.getSearchBarGuide()];
  }

  private getSearchBarGuide(): UserGuide {
    const searchBarGuide: UserGuide = {
      // Data to audit the object in the db
      id: 2,
      name: 'search bar',
      created_date: new Date(),
      created_by: 'Marcelo Schild',
      modified_date: null,
      modified_by: null,

      // Data to know when and where to execute it
      application: 'RDO',
      trigger_type: 'User',
      trigger_value: '#searchBarBtn',

      // Actual JSON that affects IntroJS
      options: Object.assign(new UserGuideOptions(), {
        exitOnOverlayClick: false,
        keyboardNavigation: true,
        steps: [{
          intro: 'This is a secondary intro',
          type: StepActionType.MESSAGE
        }, {
          element: '#searchbox',
          intro: "We're going to use this search box, please type something on it",
          type: StepActionType.HIGHLIGHT
        },
        // Maybe we should add a wait action type or a user input action type?
        {
          intro: 'Once you search for something, you get a bunch of options',
          actionCode: `
            let element = document.querySelector('#searchbox');
            element.value = 'fork';
            element.dispatchEvent(new Event('input'));
            element.dispatchEvent(new Event('focus'));`,
          type: StepActionType.ACTION
        }, {
          intro: 'clicking results on this window would navigate to a new tab',
          element: '.tt-menu',
          type: StepActionType.HIGHLIGHT
        }]
      })
    };
    searchBarGuide.options.steps = searchBarGuide.options.steps.map(x => Object.assign(new UserGuideStep(), x));
    return searchBarGuide;
  }
}
