import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { Router } from '@angular/router';
import { Heap } from '../core/interface/heap/heap-interface';

@Component({
    selector: 'app-signout',
    template: '',
})

export class SignoutComponent implements OnInit {

    constructor(private authentictionService: AuthenticationService,
                private router: Router) {
    }

    ngOnInit() {

        ((window as any).heap as Heap)?.resetIdentity();
        this.authentictionService.logout();
    }   
}
