export class FilterValues {
	monthFromId: number;
	monthToId: number;
	dateFrom: Date;
	dateTo: Date;
	regions: Array<number> = [];
	districts: Array<number> = [];
	branches: Array<number> = [];
	rouseCategories: Array<number> = [];
	clientCategories: Array<number> = [];
	catProductGroups: Array<string> = [];
	rouseProductTypes: Array<number> = [];
	clientProductTypes: Array<number> = [];
	rouseMarkets: Array<number> = [];
	customerSizes: Array<number> = [];
	outlierReasons: Array<number> = [];
    verticals: Array<number> = [];
	excludeSubstitutions: boolean;
	excludeNationalAccts: boolean;
	excludeContracts: boolean;
	excludeSpecialPricing: boolean;
    excludeRpos: boolean;
    excludePrimeUnits: boolean;	
	excludeReRent: boolean;
    useRouseSchema = false;
	includeAllRateTransactions = true;
	bid: number = 1;
	cid: number = 1;
	//private _gid: number = 1;
    public gid: number = 1;
	cycleBillRange: number[] = [];	

    //get gid(): number {
    //    return this._gid;
    //}
    //set gid(value: number) {
    //    this._gid = value;
    //}

	constructor(init?: Partial<FilterValues>) {
		(<any>Object).assign(this, init);
	}

}

export class FilterOptions {
	months: Array<Date>;
}

export class SortOption {
	field: string;
	descending?: boolean;

	constructor(field: string, descending: boolean) {
		this.field = field;
		this.descending = descending || false;
	}

	get defaultSortOptions(): any {
		return {
			default_sort: this
		}
    }
}

export enum FilterBroadcastType {
	None = 0,
	Global = 1,
	Alert = 2,
}

export class GeoFilterItem {
    id: number;
    description: string;
    parentId?: number;
    regionId?: number;

    constructor(id: number, description: string, parentId?: number, regionId?: number) {
        this.id = id;
        this.description = description;
        this.parentId = parentId;
        this.regionId = regionId;
    }
}
