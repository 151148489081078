
export const TRANSLATOR = {
    TRANSACTION_COUNT: {
        id: 'transaction_count',
        getName: (component) => {
            return component.translateService.instant('main.tabs.equipment.product_types.rate_types.histogram.transaction_count');
        }
	},
	COMPARED_TRANSACTIONS_COUNT: {
		id: 'compared_transactions_count',
        getName: (component) => {
            return component.translateService.instant('main.tabs.equipment.product_types.rate_types.histogram.compared_transactions_count');
        }
	},
	NOT_COMPARED_TRANSACTIONS_COUNT: {
		id: 'not_compared_transactions_count',
        getName: (component) => {
            return component.translateService.instant('main.tabs.equipment.product_types.rate_types.histogram.not_compared_transactions_count');
        }
	},
	BENCH_TOP_Q: {
		id: 'bench_top_q',
        getName: (component) => {
            return component.translateService.instant('main.tabs.equipment.product_types.rate_types.histogram.bench_top_q');
        }
	},
	BENCH_AVG: {
		id: 'bench_avg',
        getName: (component) => {
            return component.translateService.instant('main.tabs.equipment.product_types.rate_types.histogram.bench_avg');
        }
	},
	BENCH_BOT_Q: {
		id: 'bench_bot_q',
        getName: (component) => {
            return component.translateService.instant('main.tabs.equipment.product_types.rate_types.histogram.bench_bot_q');
        }
	},
	BOOK: {
		id: 'book',
        getName: (component) => {
            return component.translateService.instant('main.tabs.equipment.product_types.rate_types.histogram.book');
        }
	},
	FLOOR: {
		id: 'floor',
        getName: (component) => {
            return component.translateService.instant('main.tabs.equipment.product_types.rate_types.histogram.floor');
        }
	}
};
