import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
	name: 'rdoUnique'
})
export class RdoUniquePipe implements PipeTransform {
	transform(items: any[], uniqueProperty: string): any {
		return _.uniqBy(items, uniqueProperty);
	}
}