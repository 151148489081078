import {Component, Input} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/* eslint-disable @angular-eslint/no-input-rename */

@Component({
    selector: 'rdo-rate-type-card',
    templateUrl: 'rate-type-card.component.html'
})
export class RateTypeCardComponent {
    @Input('title-name')
    titleName: string;
    @Input('sub-title-name')
    subTitleName: string;
    @Input('first-value')
    firstValue: number;
    @Input('second-value')
    secondValue: number;
    @Input('title-value')
    titleValue;

    constructor(
        private translateService: TranslateService
    ) {}
}
