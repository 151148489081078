//import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
//import { FormsModule } from '@angular/forms';
//import { MultiselectDropdownComponent } from './dropdown.component';
//import { MultiSelectSearchFilter } from './search-filter.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
@NgModule({
    imports: [InfiniteScrollModule],
//	imports: [CommonModule, FormsModule],
//	exports: [MultiselectDropdownComponent, MultiSelectSearchFilter],
//	declarations: [MultiselectDropdownComponent, MultiSelectSearchFilter],
})
export class MultiselectDropdownModule { }