import { Component, Input, EventEmitter, Output } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';

import * as _ from 'lodash';

import { MetricsGridConfig } from './MetricsGridConfig';
import { IGridColumnGroup } from './IGridColumnGroup.interface';
import { RowExpandArgs } from './RowExpandArgs.interface';
import { IColumnConfig } from './IColumnConfig.interface';
import { IGridRow } from './IGridRow.interface';
import { IDataRow } from './IDataRow.interface';

import { TranslateService } from '@ngx-translate/core';
import { GridSortingService } from '../services';

@Component({
    selector: 'rdo-expandable-table',
    templateUrl: './expandable-table.html',
})
export class ExpandableTableComponent {
    collapsedTableWidth: number;
    collapsedColumns: Array<IColumnConfig>;
    masterColumn: IColumnConfig;
    collapsedIndices: Array<number>;
    private defaultWidth = 100;

    tableWidthStyle(): string {
        const w = this.expanded ? this.collapsedTableWidth : 0;
        return w + 'px';
    }

    gridData: Array<IGridRow>;

    @Input()
    expanded: boolean = false;

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('title')
    tableTitle: string;

    @Input()
    headerStyle: any;

    @Input()
    set data(value: Array<IDataRow>) {
        this.rawData = value;
        this.prepare();
    };
    @Input()
    sortInProgress: boolean;
    @Input()
    sortOn: string;

    @Output()
    sortOnChange: EventEmitter<IColumnConfig> = new EventEmitter<IColumnConfig>();

    @Input()
    desc: boolean;

    @Output()
    descChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    // todo: refactor grid tables to use column group instead of separate set of input properites (columns, title, headerStyle.. )
    @Input()
    group: IGridColumnGroup;

    @Input()
    set columns(columns: Array<IColumnConfig>) {

        this.collapsedColumns = _.slice(columns, 1);
        this.masterColumn = _.first(columns);
        this.collapsedTableWidth = _.sumBy(this.collapsedColumns, x => (x.width || this.defaultWidth));
        this.collapsedIndices = _.range(1, columns.length);

        this.fullWidth = _.sumBy(columns, c => c.width || 100);
        this.columnList = columns;
        this.prepare();
    }
    get columns(): Array<IColumnConfig> {
        return this.columnList;
    }

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('rowExpansion')
    childrenFactory: (parentRecord?: any) => Observable<Array<any>>;

    @Input()
    showExpansionIndicator: boolean;

    @Output()
    rowToggled: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    set toggleRowIndex(args: RowExpandArgs) {
        if (!this.isExpandable()) {
            return;
        }

        if (args) {
            const row = this.gridData[args.index];
            this.ensureChildrenLoaded(row);
        }
    }
    @Input()
    expanderClass: string;

    @Input()
    showColumnSelector: boolean;

    // required for column group selection
    @Input()
    gridConfig: MetricsGridConfig;

    @Output()
    visibilityChanged: EventEmitter<IGridColumnGroup> = new EventEmitter<IGridColumnGroup>();

    @Output()
    downloadExcelClick: EventEmitter<MetricsGridConfig> = new EventEmitter<MetricsGridConfig>();

    @Output()
    deleteClick: EventEmitter<MetricsGridConfig> = new EventEmitter<MetricsGridConfig>();

    private columnList: Array<IColumnConfig>;
    private fullWidth: number;
    private ROW_HEIGHT = 34;
    private rawData: Array<any>;
    private childColumnList: Array<IColumnConfig>;

    constructor(private gridSortingService: GridSortingService) { }

    private prepare() {

        if (!this.rawData || !this.columnList) {
            return;
        }
        this.childColumnList = _.map(this.columnList, (c: IColumnConfig) => c.childConfig || c);
        this.gridData = this.processRows(this.rawData, this.columnList);
        _.forEach(this.gridData, r => this.processChildren(r));
        this.initialize(this.columnList);
    }

    private processRows(data: Array<IDataRow>, columns: Array<IColumnConfig>, parent?: any): Array<IGridRow> {
        // builds gridData, an array of IGridRow calculating dynamic styles and urls along the way
        return _.map(data, row => {
            return {
                data: row,
                cells: _.map(columns, col => {
                    const value = this.getFieldValue(col, row.raw);

                    return {
                        value: value,
                        class: (col.classFactory) ? col.classFactory(value, row.raw) : null,
                        style: col.cellStyle,
                        href: (col.urlFactory) ? col.urlFactory(value, row.raw, parent) : null,
                        config: col,
                        linkDsl: col.linkDsl ? col.linkDsl(value, row.raw, parent) : null,
                        type: col.type
                    };
                })
            };
        });
    }
    private ensureChildrenLoaded(row: IGridRow): Observable<any> {
        if (!row.childRows) {
            if (!row.data.children) {
                row.data.chidlLoading = true;
                return this.childrenFactory(row.data.raw)
                    .pipe(map(children => {
                        const data = _.map(children, c => <IDataRow>{ raw: c });
                        row.data.children = data;
                        row.childRows = this.processRows(row.data.children, this.childColumnList, row.data.raw);
                        row.data.childrenHeight = row.data.children.length * this.ROW_HEIGHT;
                        row.data.chidlLoading = false;
                        return row.childRows;
                    }));
            }
            row.childRows = this.processRows(row.data.children, this.childColumnList, row.data.raw);
            row.data.childrenHeight = row.data.children.length * this.ROW_HEIGHT;
        }
        return of(row.childRows);
    }

    private toggleChildren(row: IGridRow, index: number) {
        if (!this.isExpandable()) {
            return;
        }

        this.ensureChildrenLoaded(row)
            .subscribe(c => {
                row.data.expanded = !row.data.expanded;

                this.rowToggled.next({ index: index, expanded: row.data.expanded });
            });
    }

    isExpandable(): boolean {
        return !!this.childrenFactory;
    }

    hasOneColumnAndNotLockedOrApplyColor(group: IGridColumnGroup): boolean {
        return (this.columns.length === 1 && !group.locked) ||
            group.applyBackgroundColor;
    }

    initialize(columns: Array<IColumnConfig>): void {
        // 'virtual' method for derived  tables
    }

    getFieldValue(columnConfig: IColumnConfig, record: any): any {
        const fieldValue = record[columnConfig.field];
        if (columnConfig.valueFactory) {
            return columnConfig.valueFactory(fieldValue, record);
        } else {
            return fieldValue;
        }
    }

    clickCellLink(column: IColumnConfig, row: any, parent?: any): boolean {
        return (column.linkClick) ? column.linkClick(column, row, parent) : true;
    }

    setSortColumn(column: IColumnConfig) {
        // if already sorting on this column, just flip direction
        if (column.sortColumn === this.gridSortingService.defaultSortColumn) {
            this.setSortDirection(!this.desc);
        } else {
            // this.sortOn = column.sortColumn; // todo: after RDO-1119 is complete uncomment to replace with old way.
            this.sortOn = column.sortColumn;
            this.gridSortingService.setSortOption(column.sortColumn);
            this.sortOnChange.next(column);
        }
    }

    setSortDirection(desc: boolean) {
        this.gridSortingService.setSortOption(this.gridSortingService.defaultSortColumn, desc);
        this.descChange.next(desc);
    }

    isBool(val: any) {
        return (typeof val === 'boolean');
    }
    childrenHeightStyle(row: IGridRow) {
        const height = row.data.expanded ? row.data.childrenHeight : 0;
        return height.toString() + 'px';
    }
    processChildren(row: IGridRow) {
        // process childre only if data is available or row is expanded
        // this handles case when row is expanded (children loaded)
        // and user adds more column(s)
        if (!row.data.children || !row.data.expanded) {
            return;
        }
        if (!row.childRows) {
            row.childRows = this.processRows(row.data.children, this.childColumnList, row.data.raw);
            row.data.childrenHeight = row.data.children.length * this.ROW_HEIGHT;
        }
    }
    toggleColumnVisibility(group: IGridColumnGroup) {
        group.visible = !group.visible;
        this.visibilityChanged.next(group);
    }
}

