import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { NavigationComponent } from './../navigation';
import { CoreModule } from '../core/core.module';
import { TrialGuardService } from '../core';

@NgModule({
	imports: [RouterModule, CommonModule, CoreModule],
    declarations: [NavigationComponent],
	exports: [NavigationComponent],
	providers: [TrialGuardService],
})
export class NavigationModule {
}
