﻿<div class="row" id="full-chart">
    <div class="col-xs-12">
        <div class="rental-growth-charts">
            <div class="panel panel-default" *ngIf="!chartLoading && data">
                <div class="panel-body rdo-chart-margin">
                    <ul class="nav nav-pills rdo-nav-pills" id="summary-nav-chart">
                        <li [class.active]="chartSwitcher.isRentalGrowthUnitsOnRentChart()">
                            <a data-toggle="tab" (click)="chartSwitcher.selectRentalGrowthUnitsOnRent()">{{'main.tabs.equipment.product_types.change.charts.client_relative_rentals.title' | translate}}</a>
                        </li>
                        <li [class.active]="chartSwitcher.isRentalGrowthUnitsInFleetChart()">
                            <a data-toggle="tab" (click)="chartSwitcher.selectRentalGrowthUnitsInFleet()">{{'main.tabs.equipment.product_types.change.charts.on_rent_and_in_fleet.title' | translate}}</a>
                        </li>
                        <li [class.active]="chartSwitcher.isRentalGrowthPhysicalUtilChart()">
                            <a data-toggle="tab" (click)="chartSwitcher.selectRentalGrowthPhysicalUtil()">{{'main.tabs.equipment.product_types.change.table.physical_utilization' | translate}}</a>
                        </li>
                        <li [class.active]="chartSwitcher.isRentalGrowthDollarUtilChart()">
                            <a data-toggle="tab" (click)="chartSwitcher.selectRentalGrowthDollarUtil()">{{'main.tabs.equipment.product_types.change.table.financial_utilization' | translate}}</a>
                        </li>
                        <li [class.active]="chartSwitcher.isRentalGrowthMonthlyRatesChart()">
                            <a data-toggle="tab" (click)="chartSwitcher.selectRentalGrowthMonthlyRates()">{{'main.tabs.equipment.product_types.change.table.monthly_rates' | translate}}</a>
                        </li>
                        <li [class.active]="chartSwitcher.isRentalGrowthWeeklyRatesChart()">
                            <a data-toggle="tab" (click)="chartSwitcher.selectRentalGrowthWeeklyRates()">{{'main.tabs.equipment.product_types.change.table.weekly_rates' | translate}}</a>
                        </li>
                        <li [class.active]="chartSwitcher.isRentalGrowthDailyRatesChart()">
                            <a data-toggle="tab" (click)="chartSwitcher.selectRentalGrowthDailyRates()">{{'main.tabs.equipment.product_types.change.table.daily_rates' | translate}}</a>
                        </li>
                        <li [class.active]="chartSwitcher.isRentalGrowthHourlyRatesChart()">
                            <a data-toggle="tab" (click)="chartSwitcher.selectRentalGrowthHourlyRates()">{{'main.tabs.equipment.product_types.change.table.hourly_rates' | translate}}</a>
                        </li>
                    </ul>
                </div>
                <div class="tab-content chart" id="summary-charts">
                    <rdo-chart-export-buttons [config]="chartConfig"></rdo-chart-export-buttons>
                    <div class="rental-growth-charts" [rdo-highcharts]="chartConfig" [chartId]="'summary-charts'"
                    [useVendorExport]="false"></div>
                </div>
            </div>
        </div>
    </div>
</div>