import { FilterInfoService } from '../services/filter-info.service';
import { FilterDefault } from '../../models/filter-default';
import * as _ from 'lodash';
import { MultiSelectDateOption } from '../../models';
import { FilterProfileMap } from '../profiles/models/filter-profile-map';

export function isGetRequest(filterInfoService: FilterInfoService): boolean {
	const urlWindowSafeLength = 100;
	const currentFilter = filterInfoService.activeFilterService.getCurrentFilter();
	let totalLength = 0;
	const arrays = ['branches', 'districts', 'regions', 'rouseCategories', 'clientCategories', 'rouseProductTypes', 'clientProductTypes'];
	arrays.forEach(field => {
		totalLength += currentFilter[field] ? currentFilter[field].length : 0;
	});
	return totalLength < urlWindowSafeLength;
}

export function getFilterDefault(codeName: string, filterDefaults: Array<FilterDefault>): FilterDefault {
	const filterDefault = _.find(filterDefaults, item => {
		return item.CodeName === codeName;
	});
	return filterDefault;
}

export function getWeeklyChartBegninAndEndMonthIds(monthsToReturn: number, currentMonthIdEnd: number): [number, number] {
	const currentMonthId = FilterProfileMap.getCurrentMonthId();
	const monthIdEnd = currentMonthId - currentMonthIdEnd === 1 ? currentMonthId : currentMonthIdEnd;
	const monthIdStart = currentMonthId - currentMonthIdEnd === 1 ? currentMonthIdEnd - monthsToReturn + 2 : currentMonthIdEnd - monthsToReturn + 1;
	return [monthIdStart, monthIdEnd];
}

export function getFilterDefaultBoolean(codeName: string, filterDefaults: Array<FilterDefault>): boolean {
	const filterDefault = getFilterDefault(codeName, filterDefaults);
	if (!filterDefault || _.isNil(filterDefault.DefaultValue)) {
		return null;
	}
	return filterDefault.DefaultValue ? true : false;
}

export function getFilterDefaultNumberArray(codeName: string, filterDefaults: Array<FilterDefault>): Array<number> {
	const filterDefault = getFilterDefault(codeName, filterDefaults);
	if (!filterDefault || _.isNil(filterDefault.DefaultValue)) {
		return null;
	}
	return Array.from(filterDefault.DefaultValue);
}

export function getFilterDefaultMonth(codeName: string, dates: Array<MultiSelectDateOption>, filterDefaults: Array<FilterDefault>): number {
	const filterDefault = getFilterDefault(codeName, filterDefaults);
	if ((_.isNil(filterDefault.DefaultValue) || filterDefault.DefaultValue === 0) && dates.length) {
		return dates[0].id;
	}
	if (filterDefault.DefaultValue < 0) {
		const positiveIndex = filterDefault.DefaultValue * -1;
		if (_.isNil(dates[positiveIndex])) {
			return dates[dates.length - 1].id;
		}
		return dates[positiveIndex].id;
	}
	return filterDefault.DefaultValue;
}

export function getFilterProfileDefaultMonth(dates: Array<MultiSelectDateOption>, value: number): number {
	if ((_.isNil(value) || value === 0) && dates.length) {
		return dates[0].id;
	}
	if (value < 0) {
		const positiveIndex = value * -1;
		if (_.isNil(dates[positiveIndex])) {
			return dates[dates.length - 1].id;
		}
		return dates[positiveIndex].id;
	}
	return value;
}

export function getFilterTransactionBoolean(codeName: string, exists: boolean, filterDefaults: Array<FilterDefault>): boolean {
	const filterDefault = getFilterDefault(codeName, filterDefaults);
	if (_.isNil(filterDefault.DefaultValue)) {
		return null;
	}
	if (exists) {
		return filterDefault.DefaultValue ? false : true;
	}
	return null;
}

