import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { ExcelExportService } from './http';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import {
  RdoHttpService,
  AuthService,
  ConfigService,
  RevenueChartHelperService,
  FormatService,
  QueryService,
  SvgService,
  ViewService,
  HighchartsDirective,
  DynamicPieDirective,
  AmountDifferenceComponent,
  DashboardCardSetComponent,
  DashboardCardSetExtendedComponent,
  LoaderComponent,
  MultiTopListComponent,
  TopListComponent,
  ActiveFilterService,
  // User and Client Services
  ProfileService,
  ClientProfileService,
  ColumnDefinitionService,
  GridConfigService,
  RevenueMappingService,
  // Grid Components
  GridTableComponent,
  GridTableNgComponent,
  GridTableExpandedNgComponent,
  GridTableConfiguredExpandableComponent,
  ColumnSelectorComponent,
  ColumnSelectorProductTypeComponent,
  MetricsGridComponent,
  ExpandableTableComponent,
  SortHeaderComponent,
  OverlayComponent,
  BreadcrumbComponent,
  BreadcrumbService,
  NavCardComponent,
  NavCardBookComponent,
  NavCardAssetComponent,
  ScrollIntoDirective,
  CategoryIconComponent,
  // Pipes
  RdoCurrencyPipe,
  RdoNumberPipe,
  RdoPercentPipe,
  RdoPathToIdPipe,
  RdoEllipsisPipe,
  RdoRangedDatePipe,
  RdoPredicatePipe,
  RdoUniquePipe,
  RdoFilterPipe,
  RdoFilterByPipe,
  RdoAlertsPipe,
  RdoBooleanPipe,
  RdoStringPipe,
  RdoLocalizedDatePipe,
  MultiSelectSearchFilter,
  // Services
  LocaleService,
  RentalGrowthChartService,
  RentalGrowthPanelEventService,
  DailyRatesChartService,
  HourlyRatesChartService,
  DollarUtilChartService,
  MonthlyRatesChartService,
  PhysicalUtilChartService,
  UnitsOnRentChartService,
  WeeklyRatesChartService,
  UnitsInFleetChartService,
  ChartExportService,
  MathService,
  ErrorService,
  FirestoreService,
  FirestoreSettingsService,
  GridSortingService,
  // Chart Components
  ClientRateBenchChartComponent,
  RevenueBenchmarkChartComponent,
  RevenueBookChartComponent,
  UtilizationByMonthChartComponent,
  UtilizationByWeekChartComponent,
  DollarUtilizationByMonthChartComponent,
  OnRentInFleetChartComponent,
  HistogramChartComponent,
  RateTypeCardComponent,
  RateTypePanelComponent,
  RateTypeQuartilesChartComponent,
  RevenueDistributionChartComponent,
  RateChangeChartComponent,
  RateChangeTotalChartComponent,
  // Common Components
  MultiselectDropdownComponent,
  // Guards
  TrialGuardService,
  ChangesLogGuardService,
  // Interceptors
  LanguageInterceptor,
  ChartSwitchingService,
  RDOTooltipOptions,
  TooltipsService,
  ConfiguredColumnSelectorComponent
} from '../core';
import { ChartExportButtonsComponent } from './chart/base/chart-export-buttons/chart-export-buttons.component';
import { GridMessageComponent } from './grid/grid-error/grid-message.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';
import { CompetitionDisclaimerComponent } from './disclaimers/competition-disclaimer/competition-disclaimer.component';
import { FileManagerComponent } from './file-manager';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatListModule } from '@angular/material/list';
import { MatTreeModule } from '@angular/material/tree';
import { GuidedToursManagementComponent } from './introJs/components/guided-tours-management/guided-tours-management.component';
import { InApplicationGuidanceSettingsComponent } from './introJs/components/in-application-guidance-settings/in-application-guidance-settings.component';
import { MovableColumnArrowsComponent } from './grid/column-headers/movable-column-arrows.component';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        TableModule,
        MultiSelectModule,
        PaginatorModule,
        TranslateModule,
        InfiniteScrollModule,
        MatProgressSpinnerModule,
        TooltipModule.forRoot(RDOTooltipOptions as TooltipOptions),
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatTreeModule,
        TableModule,
        MovableColumnArrowsComponent
    ],
    declarations: [
        LoaderComponent,
        HighchartsDirective,
        DynamicPieDirective,
        AmountDifferenceComponent,
        MultiTopListComponent,
        TopListComponent,
        ClientRateBenchChartComponent,
        RevenueBenchmarkChartComponent,
        RevenueBookChartComponent,
        UtilizationByMonthChartComponent,
        UtilizationByWeekChartComponent,
        DollarUtilizationByMonthChartComponent,
        OnRentInFleetChartComponent,
        DashboardCardSetComponent,
        DashboardCardSetExtendedComponent,
        ColumnSelectorComponent,
        ColumnSelectorProductTypeComponent,
        ConfiguredColumnSelectorComponent,
        ExpandableTableComponent,
        SortHeaderComponent,
        OverlayComponent,
        GridTableComponent,
        GridTableNgComponent,
        GridTableExpandedNgComponent,
        GridTableConfiguredExpandableComponent,
        BreadcrumbComponent,
        MetricsGridComponent,
        SortHeaderComponent,
        NavCardComponent,
        NavCardBookComponent,
        NavCardAssetComponent,
        ScrollIntoDirective,
        CategoryIconComponent,
        RdoCurrencyPipe,
        RdoNumberPipe,
        RdoPercentPipe,
        RdoPathToIdPipe,
        RdoEllipsisPipe,
        RdoRangedDatePipe,
        RdoPredicatePipe,
        RdoUniquePipe,
        RdoFilterPipe,
        RdoFilterByPipe,
        RdoAlertsPipe,
        RdoBooleanPipe,
        RdoStringPipe,
        RdoLocalizedDatePipe,
        MultiSelectSearchFilter,
        HistogramChartComponent,
        RateTypeCardComponent,
        RateTypePanelComponent,
        RateTypeQuartilesChartComponent,
        RevenueDistributionChartComponent,
        MultiselectDropdownComponent,
        RateChangeChartComponent,
        RateChangeTotalChartComponent,
        ChartExportButtonsComponent,
        GridMessageComponent,
        CompetitionDisclaimerComponent,
        FileManagerComponent,
        GuidedToursManagementComponent,
        InApplicationGuidanceSettingsComponent,
    ],
    providers: [
        ActiveFilterService,
        RdoHttpService,
        ExcelExportService,
        AuthService,
        ProfileService,
        ClientProfileService,
        ConfigService,
        RevenueChartHelperService,
        FormatService,
        QueryService,
        SvgService,
        ViewService,
        BreadcrumbService,
        ColumnDefinitionService,
        GridConfigService,
        RevenueMappingService,
        RentalGrowthChartService,
        RentalGrowthPanelEventService,
        DailyRatesChartService,
        HourlyRatesChartService,
        DollarUtilChartService,
        MonthlyRatesChartService,
        PhysicalUtilChartService,
        UnitsOnRentChartService,
        WeeklyRatesChartService,
        UnitsInFleetChartService,
        ChartExportService,
        MathService,
        TrialGuardService,
        ChangesLogGuardService,
        FirestoreService,
        FirestoreSettingsService,
        ErrorService,
        ChartSwitchingService,
        LocaleService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LanguageInterceptor,
            multi: true
        },
        TooltipsService,
        GridSortingService,
        provideHttpClient(withInterceptorsFromDi()),
    ],
    exports: [
        HighchartsDirective,
        DynamicPieDirective,
        ClientRateBenchChartComponent,
        RevenueBenchmarkChartComponent,
        RevenueBookChartComponent,
        UtilizationByMonthChartComponent,
        UtilizationByWeekChartComponent,
        DollarUtilizationByMonthChartComponent,
        OnRentInFleetChartComponent,
        AmountDifferenceComponent,
        DashboardCardSetComponent,
        DashboardCardSetExtendedComponent,
        LoaderComponent,
        MultiTopListComponent,
        TopListComponent,
        MetricsGridComponent,
        ColumnSelectorComponent,
        ColumnSelectorProductTypeComponent,
        ExpandableTableComponent,
        GridTableComponent,
        GridTableNgComponent,
        GridTableExpandedNgComponent,
        GridTableConfiguredExpandableComponent,
        SortHeaderComponent,
        OverlayComponent,
        BreadcrumbComponent,
        NavCardComponent,
        NavCardBookComponent,
        NavCardAssetComponent,
        ScrollIntoDirective,
        CategoryIconComponent,
        RdoCurrencyPipe,
        RdoNumberPipe,
        RdoPercentPipe,
        RdoPathToIdPipe,
        RdoEllipsisPipe,
        RdoRangedDatePipe,
        RdoPredicatePipe,
        RdoUniquePipe,
        RdoFilterPipe,
        RdoFilterByPipe,
        RdoAlertsPipe,
        RdoBooleanPipe,
        RdoStringPipe,
        RdoLocalizedDatePipe,
        MultiSelectSearchFilter,
        RateTypePanelComponent,
        RateTypeCardComponent,
        HistogramChartComponent,
        RateTypeQuartilesChartComponent,
        RevenueDistributionChartComponent,
        MultiselectDropdownComponent,
        RateChangeChartComponent,
        RateChangeTotalChartComponent,
        TranslateModule,
        ChartExportButtonsComponent,
        GridMessageComponent,
        CompetitionDisclaimerComponent,
        FileManagerComponent,
        GuidedToursManagementComponent,
        InApplicationGuidanceSettingsComponent
    ]
})
export class CoreModule { }
