import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { HotkeyModule } from 'angular2-hotkeys';
import { HeaderComponent, HeaderService, AlertNotificationService } from './../header';
import { CoreModule } from '../core/core.module';
//import { TrialGuardService } from '../core';
import { ChangesLogModule } from '../changes-log/changes-log.module';
import { AuthGuard } from './../core/guards/auth.guard';
import { FilterModule } from '../filter/filter.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
	imports: [FormsModule, CommonModule, CoreModule, RouterModule, InfiniteScrollModule, ChangesLogModule, HotkeyModule, FilterModule, MatIconModule],
	declarations: [HeaderComponent],
	providers: [HeaderService, AlertNotificationService, AuthGuard], 
	exports: [HeaderComponent]
})
export class HeaderModule { }
