import { Component, OnInit, OnDestroy } from '@angular/core';
import { DownloadService } from '../../download/download.service';
import { TranslateService } from '@ngx-translate/core';
import { ExportsService } from '../../core/services/exports.service';
import _ from 'lodash';
import { DownloadsService } from '../downloads.service';

import { PageOptionsNg, SortOptionsNg } from '../../../app/models/query';
import { LazyLoadEvent } from 'primeng/api';
import { MetricsGridConfig } from '../../../app/core/grid/MetricsGridConfig';
import { FormatService } from '../../../app/core/query/format.service';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { FileManagerComponent } from '../../core/file-manager/file-manager.component';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { MatIconModule } from '@angular/material/icon';
import { GridSortingService } from '../../../app/core/services/grid-sorting.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'rdo-downloads-grid',
  templateUrl: './downloads-grid.component.html',
  styleUrls: ['./downloads-grid.component.scss'],
  providers: [GridSortingService],
})
export class DownloadsGridComponent implements OnInit, OnDestroy {
  files: any[];
  public subscriptions = [];
  isloading: boolean = true;

  gridName: string = 'DOWNLOADABLEFILES_GRID';
  gridConfig: MetricsGridConfig;
  paging = new PageOptionsNg();
  sorting: SortOptionsNg;
  totalCount = 0;
  frozenWidth: string;
  private searchTimeout?: number;
  searchCriteria: string;
  private isUploadUser: boolean = false;

  public lastSuccessCount = 0;

  constructor(
    private authenticationService: AuthenticationService,
    private downloadService: DownloadService,
    private downloadsService: DownloadsService,
    private translateService: TranslateService,
    private exportsService: ExportsService,
    private formatService: FormatService,
    private materialdialog: MatDialog,
    private gridSortingService: GridSortingService,
    private toasterService: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.authenticationService.startSentryTransaction(
      this.router.routerState.snapshot.url
    );
    if (
      this.authenticationService != null &&
      this.authenticationService._userInfoView != null
    ) {
      this.isUploadUser =
        this.authenticationService._userInfoView.IsRouseTestUserImpersonator;
    }
    this.paging.pageSize = 50;
    this.gridSortingService.setGridName(this.gridName);
    this.sorting = this.gridSortingService.getSortOptionsNg(
      'Name_IsCustomGrids',
      -1
    );
    this.checkDirectDownload();
    this.load();

    this.exportsService.jobs$.subscribe((jobs) => {
      const unsuccessJobs = _.filter(jobs, (job) => job.status !== 'Success');
      const successCount = jobs.length - unsuccessJobs.length;
      if (this.lastSuccessCount !== successCount) {
        this.sorting.sortField =
          this.gridSortingService.getSortFieldOrDefault('Name_IsCustomGrids');
        this.sorting.sortOrder =
          this.gridSortingService.getSortDirectionOrDefault(-1);
        this.load();
      }
    });

    this.gridConfig = new MetricsGridConfig([
      {
        title: 'main.tabs.downloads.downloadable_files',
        visible: true,
        headerStyle: {
          'text-align': 'center',
          color: '#fff',
          background: '#99A8BD',
        },
        forceCanClose: false,
        columns: [
          {
            title: 'main.tabs.downloads.file_name',
            field: 'Name',
            sortColumn: 'Name',
            width: 500,
            headerStyle: {
              'text-align': 'left',
            },
            cellStyle: {
              'text-align': 'left',
            },
            clickLinkFn: (v, r) => {
              this.getFile(r.Name);
            },
          },
          {
            title: 'main.tabs.downloads.access_restricted_users_list',
            field: 'RestrictedUsersDescription',
            sortColumn: 'RestrictedUsersDescription',
            width: 400,
            headerStyle: {
              'text-align': 'left',
            },
            cellStyle: {
              'text-align': 'left',
            },
            clickLinkFn: (v, r) => {
              this.showFileManager(r);
            },
          },
          {
            title: 'main.tabs.downloads.type',
            field: 'Origin',
            width: 100,
            sortColumn: 'Origin',
            headerStyle: {
              'text-align': 'right',
            },
            cellStyle: {
              'text-align': 'right',
            },
            valueFactory: (v, r) => {
              if (v === 'Custom Grids') {
                return this.translateService.instant(
                  'main.tabs.downloads.custom_grids'
                );
              }
              return '';
            },
          },
          {
            title: 'main.tabs.downloads.size',
            field: 'Size',
            sortColumn: 'Size',
            headerStyle: {
              'text-align': 'right',
            },
            cellStyle: {
              'text-align': 'right',
            },
          },
          {
            title: 'main.tabs.downloads.exported_by',
            field: 'UserId',
            sortColumn: 'UserId',
            width: 200,
            headerStyle: {
              'text-align': 'right',
            },
            cellStyle: {
              'text-align': 'right',
            },
          },
          {
            title: 'main.tabs.downloads.modified_date',
            field: 'Modified',
            valueFactory: (v, r) => {
              return this.formatService.formatDate(v, 'MMM dd, yyyy');
            },
            sortColumn: 'Modified',
            headerStyle: {
              'text-align': 'right',
            },
            cellStyle: {
              'text-align': 'right',
            },
          },
        ],
      },
    ]);
  }

  ngOnDestroy() {
    while (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.pop().unsubscribe();
    }
    window.clearTimeout(this.searchTimeout);
  }

  public checkDirectDownload = () => {
    this.subscriptions.push(
      this.route.queryParams.subscribe((params) => {
        if (params.fileName) {
          this.subscriptions.push(
            this.downloadsService
              .getDownloads(this.sorting, this.paging, this.searchCriteria)
              .subscribe((x) => {
                if (!x.items || x.items.length === 0) {
                  this.toasterService.error(
                    this.translateService.instant(
                      'main.tabs.downloads.toasts.titles.download_error'
                    ),
                    this.translateService.instant(
                      'main.tabs.downloads.toasts.messages.cannot_download_file'
                    )
                  );
                } else {
                  const directDownloadFileName = x.items.find(
                    (file) => file.Name === params.fileName
                  );
                  if (directDownloadFileName) {
                    this.getFile(directDownloadFileName.Name);
                  } else {
                    this.toasterService.error(
                      this.translateService.instant(
                        'main.tabs.downloads.toasts.titles.download_error'
                      ),
                      this.translateService.instant(
                        'main.tabs.downloads.toasts.messages.cannot_download_file'
                      )
                    );
                  }
                }
              })
          );
        }
      })
    );
  };

  public getFile = (filename: string) => {
    this.isloading = true;
    this.downloadService.getSaveFile(filename).subscribe((completed) => {
      if (completed === true) {
        this.isloading = false;
      }
    });
  };

  public showFileManager = (filequeryresult: any) => {
    const result = new BehaviorSubject<any>(undefined);
    const dialogConfig = new MatDialogConfig();
    const dialogData = filequeryresult;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = dialogData;
    if (dialogData) {
      dialogConfig.data.openFrom = 'downloads';
    }
    dialogConfig.width = '1060px';
    dialogConfig.height = '95vh';
    dialogConfig.id = 'file-manager-popup-modal';
    this.materialdialog
      .open(FileManagerComponent, dialogConfig)
      .afterClosed()
      .subscribe((dialogresponse) => {
        if (dialogresponse != null && dialogresponse.isFileChangeed) {
          // refresh the list of files
          this.load();
        } else {
          // no changes, just let the dialog dismiss
        }
        result.complete();
      });
    return result.asObservable();
  };

  changePage = (event: any) => {
    if (event.rows !== this.paging.pageSize) {
      this.paging.pageSize = event.rows;
    }

    this.paging.page = event.first < 1 ? 1 : event.first / event.rows + 1;
    this.load();
  };

  changeLazyLoad = (event: LazyLoadEvent) => {
    if (
      this.sorting.sortField === event.sortField &&
      this.sorting.sortOrder === event.sortOrder
    ) {
      return;
    }

    this.gridSortingService.setSortOption(
      event.sortField,
      event.sortOrder === -1
    );

    this.sorting.sortField =
      event.sortField || this.gridSortingService.getSortFieldOrDefault('Name');
    this.sorting.sortOrder =
      event.sortOrder || this.gridSortingService.getSortDirectionOrDefault(-1);

    //this.paging.page = 1;
    this.load();
  };

  load = () => {
    this.isloading = true;
    this.subscriptions.push(
      this.downloadsService
        .getDownloads(this.sorting, this.paging, this.searchCriteria)
        .subscribe((x) => {
          this.totalCount = x.totalCount;
          this.files = x.items;
          this.isloading = false;

          this.files.forEach((element) => {
            element.Status = this.translateService.instant(
              'main.tabs.downloads.status'
            );
            element.UserId = element.MetaData.UserId;
            element.Origin = element.MetaData.Origin;
            element.IsCustomGrids = element.MetaData.Origin === 'Custom Grids';
          });
          this.authenticationService.finishSentryTransaction();
        })
    );
  };

  onSearchInput(value: string): void {
    this.searchCriteria = value;
    window.clearTimeout(this.searchTimeout);

    this.searchTimeout = window.setTimeout(() => this.load(), 300);
  }
}
