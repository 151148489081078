import {Component, Input} from '@angular/core';

@Component({
    selector: 'rdo-loader',
    templateUrl: 'loader.component.html',
    styleUrls: ['loader.component.scss']
})
export class LoaderComponent {
    @Input()
    public enabled: boolean;
}
