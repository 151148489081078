import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { switchMap, map } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';

import { ActiveFilterService } from './active-filter.service';
import { SortOptions } from '../../models';
export enum ComparisonMode {
	Benchmark = 1,
	Book = 2
}

export enum ComparisonModeSortOptions {
	Unknown = 0,
	Revenue = 1,
	Difference = 2
}

export interface NavCardsSetData {
	RevenueBench: number;
	RevenueBenchmarked: number;
	RevenueBenchmarkedDifference: number;
	RevenueBook: number;
	RevenueBookDifference: number;
	RevenueTotal: number;
	TotalCount: number;
}
@Injectable()
export class ViewService {

	constructor(
		private filterService: ActiveFilterService
	) { }

	public getComparisonMode(): Observable<ComparisonMode> {
		return this.filterService.filterParams.pipe(switchMap(f => {
			return of(f.Cid === 1 ?
				ComparisonMode.Benchmark :
				ComparisonMode.Book);
		}));
	}

	public getComparisonModeSortOptions(sortBy: ComparisonModeSortOptions): Observable<SortOptions> {
		return this.getComparisonMode()
			.pipe(map(mode => {
				const sorting = new SortOptions();
				switch (mode) {
					case ComparisonMode.Benchmark:
						sorting.sortOn =
							sortBy === ComparisonModeSortOptions.Revenue
								? 'ClientBenchmarkedRevenue'
								: 'BenchmarkedRevenueDifference';
						sorting.descending = sortBy === ComparisonModeSortOptions.Revenue;
						break;
					case ComparisonMode.Book:
						sorting.sortOn = sortBy === ComparisonModeSortOptions.Revenue ? 'RevenueTotal' : 'RevenueBookDifference';
						sorting.descending = sortBy === ComparisonModeSortOptions.Revenue;
						break;
					default: throw new Error('Unsupported comparison mode');
				}
				return sorting;
			}));
	}
}


