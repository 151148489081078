export class RateChangeResult {
	Month?: Date;
	RateChangeMoMTotal?: number;
	RateChangeMoMBenchmarked?: number;
	RateChangeMoMBench?: number;
	RateChangeYoYTotal?: number;
	RateChangeYoYBenchmarked?: number;
	RateChangeYoYBench?: number;
}

export enum RateChangeType {
	Monthly,
	Yearly
}
