import { Observable } from '../Observable';
import { AsyncSubject } from '../AsyncSubject';
import { map } from '../operators/map';
import { canReportError } from '../util/canReportError';
import { isArray } from '../util/isArray';
import { isScheduler } from '../util/isScheduler';
export function bindCallback(callbackFunc, resultSelector, scheduler) {
  if (resultSelector) {
    if (isScheduler(resultSelector)) {
      scheduler = resultSelector;
    } else {
      return (...args) => bindCallback(callbackFunc, scheduler)(...args).pipe(map(args => isArray(args) ? resultSelector(...args) : resultSelector(args)));
    }
  }
  return function (...args) {
    const context = this;
    let subject;
    const params = {
      context,
      subject,
      callbackFunc,
      scheduler
    };
    return new Observable(subscriber => {
      if (!scheduler) {
        if (!subject) {
          subject = new AsyncSubject();
          const handler = (...innerArgs) => {
            subject.next(innerArgs.length <= 1 ? innerArgs[0] : innerArgs);
            subject.complete();
          };
          try {
            callbackFunc.apply(context, [...args, handler]);
          } catch (err) {
            if (canReportError(subject)) {
              subject.error(err);
            } else {
              console.warn(err);
            }
          }
        }
        return subject.subscribe(subscriber);
      } else {
        const state = {
          args,
          subscriber,
          params
        };
        return scheduler.schedule(dispatch, 0, state);
      }
    });
  };
}
function dispatch(state) {
  const self = this;
  const {
    args,
    subscriber,
    params
  } = state;
  const {
    callbackFunc,
    context,
    scheduler
  } = params;
  let {
    subject
  } = params;
  if (!subject) {
    subject = params.subject = new AsyncSubject();
    const handler = (...innerArgs) => {
      const value = innerArgs.length <= 1 ? innerArgs[0] : innerArgs;
      this.add(scheduler.schedule(dispatchNext, 0, {
        value,
        subject
      }));
    };
    try {
      callbackFunc.apply(context, [...args, handler]);
    } catch (err) {
      subject.error(err);
    }
  }
  this.add(subject.subscribe(subscriber));
}
function dispatchNext(state) {
  const {
    value,
    subject
  } = state;
  subject.next(value);
  subject.complete();
}
function dispatchError(state) {
  const {
    err,
    subject
  } = state;
  subject.error(err);
}
