"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var Subscriber_1 = require("../Subscriber");
function canReportError(observer) {
  while (observer) {
    var _a = observer,
      closed_1 = _a.closed,
      destination = _a.destination,
      isStopped = _a.isStopped;
    if (closed_1 || isStopped) {
      return false;
    } else if (destination && destination instanceof Subscriber_1.Subscriber) {
      observer = destination;
    } else {
      observer = null;
    }
  }
  return true;
}
exports.canReportError = canReportError;
