import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { zip } from 'rxjs/observable/zip';
import { ClientProfileService, IntroJsService } from '../core';
import { TxAttributes } from '../models';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { CustomGridsService } from '../custom-grids';
import { FilterProfileService } from '../filter/profiles/filter-profile.service';
import { CODENAMES } from '../core/constants';

@Component({
	selector: 'rdo-navigation',
	templateUrl: './navigation.html',
	styleUrls: ['./navigation.scss']
})
export class NavigationComponent implements OnInit {
	clientAttributes = new TxAttributes(<TxAttributes>{ IsTrial: true });
	hasAssetGrid = false;
	hasChangeLog = false;
    hasRDOAccess = false;
    showCustomGrids = false;
    displayGeoDropdown = false;
    displayEquipmentDropdown = false;

    constructor(
        public authenticationService: AuthenticationService,
        public router: Router,
        protected clientProfileService: ClientProfileService,
        private filterProfileService: FilterProfileService,
        private introJsService: IntroJsService
    ) { }

    ngOnInit() {
        this.authenticationService.selectedClientId.subscribe(clientid => {
            if (clientid) {
                // this.hasAccessToDownloads = this.authenticationService._userInfoView.HasClientAccessToDownloads;
                zip(
                    this.clientProfileService.getClientAttributes(),
                    this.filterProfileService.wait4CurrentProfile()
                ).subscribe((results: [TxAttributes, boolean]) => {
                    this.showCustomGrids = !!this.filterProfileService.readClientProfileProperty(CODENAMES.CN_SHOW_CUSTOM_GRIDS);
                    this.clientAttributes = results[0];
                    const currencyConfig = this.filterProfileService.readClientProfileProperty(CODENAMES.CN_CLIENT_CURRENCY_SYMBOL);
                    this.clientProfileService.setSelectedLocaleFilterDefault(currencyConfig);
                    this.hasRDOAccess = this.authenticationService._userInfoView.SelectedClient.RDOAccess;
                    this.hasAssetGrid = !!this.filterProfileService.readClientProfileProperty(CODENAMES.CN_HAS_ASSET_GRID);
                    this.hasChangeLog = !!this.filterProfileService.readClientProfileProperty(CODENAMES.CN_HAS_CHANGES_LOG)
                });
            }
        });
	}

	isActive = (routes: any[]): boolean => {
		return this.router.isActive(this.router.createUrlTree(routes), true);
	}

    @HostListener('document:click', ['$event'])
    onClickOut(event: PointerEvent) {
        this.introJsService.handleClickOut(event, ['geo-'], () => {this.displayGeoDropdown = false});
        this.introJsService.handleClickOut(event, ['equipment-'], () => {this.displayEquipmentDropdown = false});
    }
}
