import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { ComparisonMode } from './../../../query';
import { TranslateService } from '@ngx-translate/core';

export interface ITopListItem {
	Name: string;
	ClientBenchmarkedRevenue: number;
	BenchmarkedRevenue: number;
	RevenueBook: number;
	RevenueTotal: number;
	LinkDsl?: any[];
}

export interface IListConfig {
	id?: string;
	title: string;
	load: () => Observable<Array<ITopListItem>>;
	footerLinkDsl?: any[];
	footerLinkTitle?: string;
}

@Component({
	selector: 'rdo-multi-top-list',
	templateUrl: 'multi-top-list.component.html',
	styleUrls: ['./../list.scss', 'multi-top-list.component.scss']
})
export class MultiTopListComponent {

	constructor(
		private translateService: TranslateService
	) {}

	@Input() comparisonMode: ComparisonMode;

	@Input() set configs(val: IListConfig[]) {
		this.cacheData = [];
		this._configs = val;
		this.switchTo(2);
	}

	public selectedIndex = 0;

	public items: any[];
	public loading: boolean;

	private _configs: IListConfig[];
	private cacheData: Array<ITopListItem[]> = [];
	private selectedConfig: IListConfig;

	switchTo = (index: number) => {
		if (!this._configs) {
			return;
		}
		this.selectedIndex = index;
		this.selectedConfig = this._configs[index];
		const cached = this.cacheData[index];
		if (cached) {
			this.items = cached;
		} else {
			const config = this._configs[index];
			this.loading = true;
			config.load().subscribe(x => {
				this.cacheData[index] = x;
				this.items = x;
				this.loading = false;
			});
		}
	}

	private isBenchmarkMode = (): boolean => {
		return this.comparisonMode === ComparisonMode.Benchmark;
	}

	getFooterLinkTitle(footerLinkTitle: any) {
		if (footerLinkTitle) {
			return this.translateService.instant(footerLinkTitle);
		} else {
			return this.translateService.instant('main.tabs.summary.tables.all');
		}
	}
}
