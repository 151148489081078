const CHART_COLORS = {
    COLOR_5779A3 : '#5779A3',
    COLOR_595959 : '#595959',
    COLOR_D9E5F3 : '#D9E5F3',
    COLOR_385723 : '#385723',
    COLOR_92AA7A : '#92AA7A',
    COLOR_CA8C8C : '#CA8C8C',    
    COLOR_843438 : '#843438',
    COLOR_A6A6A6 : '#A6A6A6',
    COLOR_000000 : '#000000',
    COLOR_FFFFFF : '#FFFFFF',
    COLOR_2F5597 : '#2F5597',
    COLOR_D9DEFF : '#D9DEFF',
    COLOR_404040 : '#404040',
    COLOR_969696 : '#969696',
    COLOR_FFC000 : '#FFC000',
    TRANSPARENCY : 'rgba(0,0,0,0.3)'
};

export { CHART_COLORS };
