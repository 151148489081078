import { CardBase } from './card-base';

export class CategoryCard extends CardBase {
    CategoryIcon: string;
	RouseCategory: string;
	PhysicalUtilization: number;
	PhysicalUtilizationBench: number;
    PhysicalUtilizationDifference: number;

    public constructor(init?: Partial<CategoryCard>) {
	    super();
        Object.assign(this, init);
    }
}