import {
  Component,
  OnInit,
  ElementRef,
  ViewEncapsulation,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map, filter } from 'rxjs/operators';
import { zip } from 'rxjs/observable/zip';
import { pairs } from 'rxjs';
import * as _ from 'lodash';
import * as filterFunctions from './../filter/functions/filter.functions';
import { ActiveFilterService } from '../core/query/active-filter.service';
import { ClientProfileService } from '../core/client/client-profile.service';
import {
  IMultiSelectSettings,
  IMultiSelectTexts,
} from '../core/dropdown/types';
import { FormatService } from '../core/query';
import { FilterDataService } from './services/filter-data.service';
import { AlertNotificationService } from '../header';
import { IMultiSelectOption } from 'angular-2-dropdown-multiselect';
import { AuthenticationService } from './../core/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import {
  TxAttributes,
  Alert,
  FilterValues,
  FilterBroadcastType,
  MultiSelectDateOption,
  GeoFilterItem,
  ClientMonth,
  FilterLabel,
  RouseCategory,
  ClientCategory,
  CustomerSize,
  OutlierReason,
  ClientVertical,
  LocaleFormat,
  CatProductGroup,
  RouseMarket,
  RouseProductType,
  ClientProductType,
} from '../models';
import { MultiselectDropdownComponent } from '../core/dropdown/dropdown.component';
import { CODENAMES } from '../core/constants';
import { NavigationEnd, Router } from '@angular/router';
import { FilterBox } from './components';
import { FilterConfigService } from './services/filter-config.service';
import { FilterSliderService } from './services';
import { FilterProfileService } from './profiles/filter-profile.service';
import { RawFilterProfile } from './profiles/models/raw-filter-profile';
import { FilterProfile } from './profiles/models/filter-profile';
import { MonthSetting } from './profiles/models/month-settings';
import { FilterProfileMap } from './profiles/models/filter-profile-map';
import { ProfileLoadingStage } from './profiles/models/profile-loading-stage';
import { LocaleService } from '../core/services/locale.service';

/* eslint-disable no-prototype-builtins,  @typescript-eslint/unbound-method */
declare let $: any;

@Component({
  selector: 'rdo-filter',
  templateUrl: 'filter.html',
  styleUrls: ['filter.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FilterComponent implements OnInit {
  @ViewChild('prodTypeDropDown')
  private prodTypeDropDown: MultiselectDropdownComponent;
  @ViewChild('clientProdTypeDropDown')
  private clientProdTypeDropDown: MultiselectDropdownComponent;

  @ViewChild('rouseCatTypeDropDown')
  private rouseCatTypeDropDown: MultiselectDropdownComponent;
  @ViewChild('clientCatTypeDropDown')
  private clientCatTypeDropDown: MultiselectDropdownComponent;
  @ViewChild('catProductGroupsDropDown')
  private catProductGroupsDropDown: MultiselectDropdownComponent;

  @ViewChild('regionDropDown')
  private regionDropDown: MultiselectDropdownComponent;
  @ViewChild('districtDropDown')
  private districtDropDown: MultiselectDropdownComponent;
  @ViewChild('branchDropDown')
  private branchDropDown: MultiselectDropdownComponent;
  @ViewChild('marketDropDown')
  private marketDropDown: MultiselectDropdownComponent;

  @ViewChild('verticalsDropDown')
  private verticalsDropDown: MultiselectDropdownComponent;
  @ViewChild('outliersDropDown')
  private outliersDropDown: MultiselectDropdownComponent;

  private readonly FILTER_BOX_SEPARATOR = ' | ';
  private readonly NUMBER_OF_GEOS_TO_SHOW = 1;

  isFilterVisible: boolean;
  geographyLevels = [];
  txAttributes: TxAttributes;
  geoSliderId = 'geo-slider';
  geoLowValue: any = 1;
  geoHighValue: any = 2;
  defaultGeographies: Array<number> = [];
  hasOutlier = false;
  hasVertical = false;
  hasRouseCategories = false;
  hideFilterSelectionsForRoute = false;

  dates: Array<MultiSelectDateOption> = [];

  selectedMonthRange: string;
  useLarge: boolean;
  rawFilterProfileData: RawFilterProfile;

  // Translation placeholders
  filterMultiSelectTexts: IMultiSelectTexts =
    FilterConfigService.filterMultiSelectTexts;
  monthMultiSelectionTexts: IMultiSelectTexts =
    FilterConfigService.monthMultiSelectionTexts;

  // Multi select Settings
  monthMultiSelectSettings: IMultiSelectSettings =
    FilterConfigService.monthMultiSelectSettings;
  singleSelectSettings: IMultiSelectSettings =
    FilterConfigService.singleSelectSettings;
  singleSelectWithSearchSettings: IMultiSelectSettings =
    FilterConfigService.singleSelectWithSearchSettings;
  multiSelectSettings: IMultiSelectSettings =
    FilterConfigService.multiSelectSettings;
  multiSelectWithSearchSettings: IMultiSelectSettings =
    FilterConfigService.multiSelectWithSearchSettings;
  rouseProductTypesmultiSelectSettings: IMultiSelectSettings =
    FilterConfigService.rouseProductTypesmultiSelectSettings;
  clientProductTypesmultiSelectSettings: IMultiSelectSettings =
    FilterConfigService.clientProductTypesmultiSelectSettings;
  multiSelectCategorySettings: IMultiSelectSettings =
    FilterConfigService.multiSelectCategorySettings;
  multiSelectDistrictSettings: IMultiSelectSettings =
    FilterConfigService.multiSelectDistrictSettings;
  multiSelectBranchSettings: IMultiSelectSettings =
    FilterConfigService.multiSelectBranchSettings;
  multiSelectMarketSettings: IMultiSelectSettings =
    FilterConfigService.multiSelectMarketSettings;
  multiSelectRegionSettings: IMultiSelectSettings =
    FilterConfigService.multiSelectRegionSettings;

  regions: Array<IMultiSelectOption> = [];
  selectedRegions: Array<number>;

  districts: Array<IMultiSelectOption> = [];
  filteredDistricts: Array<IMultiSelectOption> = [];
  selectedDistricts: Array<number>;

  branches: Array<IMultiSelectOption> = [];
  filteredBranches: Array<IMultiSelectOption> = [];
  selectedBranches: Array<number>;

  comparisons: IMultiSelectOption[];
  selectedComparison: string;
  selectedComparisonIds: Array<number> = [];
  defaultComparison: IMultiSelectOption;

  rouseCategories: IMultiSelectOption[];
  selectedRouseCategoryIds: Array<number> = [];

  clientCategories: IMultiSelectOption[];
  selectedClientCategoryIds: Array<number> = [];

  catProductGroups: IMultiSelectOption[];
  selectedCatProductGroupsIds: Array<string> = [];

  rouseProductTypes: IMultiSelectOption[];
  clientProductTypes: IMultiSelectOption[];
  selectedRouseProductTypeIds: Array<number> = [];
  selectedClientProductTypeIds: Array<number> = [];
  filteredRouseProductTypes: Array<IMultiSelectOption> = [];
  filteredClientProductTypes: Array<IMultiSelectOption> = [];

  rouseMarkets: IMultiSelectOption[];
  selectedRouseMarketIds: Array<number> = [];
  filteredRouseMarkets: Array<IMultiSelectOption> = [];

  customerSizes: IMultiSelectOption[];
  selectedCustomerSizeIds: Array<number> = [];

  outlierReasons: IMultiSelectOption[];
  selectedOutlierReasonIds: Array<number> = [];
  defaultOutlierReasonIds: Array<number> = [];
  defaultOutliers: Array<number> = [];

  verticals: IMultiSelectOption[];
  selectedVerticalIds: Array<number> = [];
  defaultVerticalIds: Array<number> = [];
  defaultVerticals: Array<number> = [];

  benchmarks: Array<IMultiSelectOption>;
  selectedBenchmarkName: string;
  selectedBenchmarkIds: Array<number> = [];
  defaultBenchmark: IMultiSelectOption;

  defaultUseRouseSchema = false;
  defaultIncludeAllRateTransactions = true;

  defaultExcludePrimeUnits: boolean;
  defaultExcludeSubstitutions: boolean;
  defaultExcludeNationalAccounts: boolean;
  defaultExcludeRpos: boolean;
  defaultExcludeContracts: boolean;
  defaultExcludeSpecialPricing: boolean;
  defaultExcludeReRents: boolean;
  defaultRouseCategories: Array<number> = [];
  engagedFilters: Array<FilterBox> = [];

  cycleBillSliderId = 'cycle-bill-slider';
  defaultCycleBillRange = [1, 2, 3, 4, 5];
  spotRateCycleBillRange = [1, 2, 3];
  cycleBillLowValue = 1;
  cycleBillHighValue = 5;
  defaultCycleBills: Array<number> = [];

  defaultSelectedMonthIds: Array<number>;

  standardRateBenchmarkId = '1';
  spotRateBenchmarkId = '2';
  contractRateBenchmarkId = '3';
  customerSizeAdjustedRateBenchmarkId = '4';

  private filterLabels: any = {};
  monthTooltip: string = null;

  private scrollPosition = 0;
  private readonly itemWidth = 110;

  constructor(
    private authenticationService: AuthenticationService,
    private activeFilterService: ActiveFilterService,
    private filterDataService: FilterDataService,
    private alertNotificationService: AlertNotificationService,
    private clientProfileService: ClientProfileService,
    private formatService: FormatService,
    private translateService: TranslateService,
    private router: Router,
    private filterSliderService: FilterSliderService,
    public filterProfileService: FilterProfileService,
    private localeService: LocaleService
  ) {
    this.filterProfileService.currentProfile.subscribe((profile) => {
      if (FilterProfileMap.validateProfile(profile)) {
        // console.log('loading stage: ' + this.filterProfileService.loadingStage + ', profile:', profile);
        switch (this.filterProfileService.loadingStage) {
          case ProfileLoadingStage.LOADING: {
            this.filterProfileService.selectedFilterValues =
              FilterProfile.asFilterValues(profile);
            // this.applyProfile(profile, false);
            // this.filterProfileService.loadingStage = ProfileLoadingStage.APPLIED;
            break;
          }
          case ProfileLoadingStage.LOADED: {
            //This is called once upon initial load only
            this.filterProfileService.selectedFilterValues =
              FilterProfile.asFilterValues(profile);
            this.applyProfile(profile, true);
            this.filterProfileService.loadingStage =
              ProfileLoadingStage.APPLIED;
            this.filterProfileService.currentfilterProfileApplied =
              this.filterProfileService.currentProfile.value;
            break;
          }
          case ProfileLoadingStage.APPLIED: {
            this.resetFiltersToProfile(profile, false);
            break;
          }
          default: {
            // clear anything from existing client (if there's anything to clear (hence the try / catch block)
            // broadcast type is none in order to update the filter but not trigger grid refreshes (which is unwanted, only the filter should rebuild)
            this.filterProfileService.selectedFilterValues =
              FilterProfile.asFilterValues(profile);
            this.filterProfileService.readFilterComponentValues = () => {
              return this.filterProfileService.readRawFilterComponentData();
            };
            this.filterProfileService.loadingStage =
              ProfileLoadingStage.LOADING;
            zip(
              this.filterDataService.getFilterLabels(),
              this.filterDataService.getMonths(),
              this.filterDataService.getLocaleFormats()
            ).subscribe(
              (
                results: [
                  Array<FilterLabel>,
                  Array<ClientMonth>,
                  Array<LocaleFormat>,
                ]
              ) => {
                this.handleFilterLabelsResponse(results[0]);
                this.handleMonthsResponse(results[1], results[2]);
              }
            );
            this.setFilterChangeSubscriptions();
          }
        }
      }
    });

    filterProfileService.applyFilterChanges.subscribe((r) =>
      this.applyFilters()
    );

    this.translateService.onLangChange.subscribe(() => {
      this.onLanguageChange();
    });
    this.filterProfileService.triggerApplyProfiles = () => {
      this.applyFilters(FilterBroadcastType.Global, false);
    };

    this.subscribeToChangesInSliders();
  }

  ngOnInit() {
    this.authenticationService.selectedClientId.subscribe((clientid) => {
      if (clientid) {
        try {
          // clear anything from existing client (if there's anything to clear (hence the try / catch block)
          this.resetToMostRecentFilterState();
        } catch {}
      }
    });
    this.setupFilterSelectionDisplay();
  }

  /**
   * Sets notifications in several services to handle
   * filter changes triggered by them.
   */
  private setFilterChangeSubscriptions() {
    this.filterDataService.getRegions().subscribe(this.initializeRegions);
    this.filterDataService
      .getDistricts(this.filterProfileService.selectedFilterValues.regions)
      .subscribe(this.initializeDistricts);
    this.filterDataService
      .getBranches(
        this.filterProfileService.selectedFilterValues.regions,
        this.filterProfileService.selectedFilterValues.districts
      )
      .subscribe(this.initializeBranches);
    this.activeFilterService.filterChange.subscribe((f) => {
      this.setFilter(f);
    });
    this.alertNotificationService.alertSelected$.subscribe(
      this.setAlertFilters
    );
    this.activeFilterService.clearFiltersSubject.subscribe((filters) => {
      this.removeAllActiveFilters(filters);
    });
  }

  /**
   * Subscrubes to router events to check for changes
   * in the url. If the current url contains custom
   * grids, the current filter selection is hidden.
   */
  private setupFilterSelectionDisplay() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.toLowerCase().endsWith('custom-grids')) {
          this.hideFilterSelectionsForRoute = true;
        } else {
          this.hideFilterSelectionsForRoute = false;
        }
      }
    });
  }

  showFilterMenu = () => {
    if (this.txAttributes) {
      return true;
    }
    return false;
  };

  getFilterDefault(codeName: string): any {
    return this.filterProfileService.readCurrentProfileProperty(codeName);
  }

  getFilterDefaultBoolean(codeName: string): boolean {
    const value =
      this.filterProfileService.readCurrentProfileProperty(codeName);
    const result = _.isNil(value) ? null : !!value;
    return result;
  }

  getFilterDefaultMonth(
    codeName: string,
    dates: Array<MultiSelectDateOption>
  ): number {
    const value =
      this.filterProfileService.readCurrentProfileProperty(codeName);
    return filterFunctions.getFilterProfileDefaultMonth(dates, value);
  }

  getFilterTransactionBoolean(codeName: string, exists: boolean): boolean {
    const value =
      this.filterProfileService.readCurrentProfileProperty(codeName);
    return _.isNil(value) || !exists ? null : !!value;
  }

  onLanguageChange() {
    this.dates = this.dates.map(
      (x) =>
        <MultiSelectDateOption>{
          id: x.id,
          name: this.formatDate(x.date),
          date: x.date,
        }
    );
    this.setMonthFilterBox();
  }

  private formatDate(date: Date): string {
    const locale = this.localeService.getLocale();
    return this.formatService.formatLocalizedDateTime(date, {
      locale,
      format: 'MMM yyyy',
    });
  }

  /**
   * Sets the filter labels based on the filter
   * labels response from the API.
   */
  handleFilterLabelsResponse = (filterLabels: Array<FilterLabel>): void => {
    for (const label of filterLabels) {
      this.filterLabels[label.CodeName] = label.DisplayName;
    }
  };

  handleMonthsResponse = (
    months: Array<ClientMonth>,
    localeFormats: Array<LocaleFormat>
  ): void => {
    this.dates = months.map(
      (x) =>
        <MultiSelectDateOption>{
          id: x.MonthID,
          name: this.formatDate(x.Month),
          date: x.Month,
        }
    );
    this.activeFilterService.dateOptions = this.dates.slice();
    if (this.dates && this.dates.length) {
      this.filterProfileService.selectedMonthIds = [this.dates[0].id];
    }

    if (months && months.length) {
      this.activeFilterService.clientMonth = months[0];
    }
    this.filterProfileService.selectedMonthIds =
      this.filterProfileService.reconcileSelectedMonthsWithAvailableMonths(
        this.dates,
        this.filterProfileService.selectedMonthIds,
        FilterProfileMap.getCurrentMonthId(),
        this.filterProfileService.currentProfile.value.monthsCode
      );
    this.updateFilterDateRange(this.filterProfileService.selectedMonthIds);
    this.finishInitialSetup(localeFormats);
  };

  private finishInitialSetup(localeFormats: Array<LocaleFormat>) {
    const rawConfigApplied = new Observable((obs) => {
      zip(
        this.clientProfileService.getClientAttributes(),
        this.filterDataService.getBenchmarks(),
        this.filterDataService.getComparisons(),
        this.filterDataService.getGeographyLevels(),
        this.filterDataService.getCustomerSizes(),
        this.filterDataService.getClientOutlierReasons(),
        this.filterDataService.getClientVerticals(),
        this.filterDataService.getRouseCategories(),
        this.filterProfileService.wait4CurrentProfile()
      ).subscribe(
        (
          results: [
            TxAttributes,
            Array<any>,
            Array<any>,
            Array<any>,
            Array<CustomerSize>,
            Array<OutlierReason>,
            Array<ClientVertical>,
            Array<RouseCategory>,
          ]
        ) => {
          this.handleClientSettingsReponse(
            results[0],
            results[1],
            results[2],
            results[3],
            results[4],
            results[5],
            results[6],
            localeFormats,
            results[7]
          );
          if (!this.txAttributes) {
            this.router.navigate(['/downloads']);
          }
          obs.next();
          obs.complete();
        }
      );
    });
    const sourceValuesObtained = new Observable((obs) => {
      zip(
        this.filterDataService.getRouseCategories(),
        this.filterDataService.getClientCategories(),
        this.filterDataService.getCatProductGroups(),
        this.filterDataService.getRouseProductTypes(),
        this.filterDataService.getRouseMarkets(),
        this.filterDataService.getClientProductTypes()
      ).subscribe(
        (
          results: [
            Array<RouseCategory>,
            Array<ClientCategory>,
            Array<CatProductGroup>,
            Array<RouseProductType>,
            Array<RouseMarket>,
            Array<ClientProductType>,
          ]
        ) => {
          this.setClientCategories(results[1]);
          this.setCatProductGroups(results[2]);
          this.setRouseProductTypes(results[3]);
          this.setRouseMarkets(results[4]);
          this.setClientProductTypes(results[5]);
          obs.next();
          obs.complete();
        }
      );
    });
    zip(rawConfigApplied, sourceValuesObtained).subscribe(() => {
      this.filterProfileService.loadingStage = ProfileLoadingStage.LOADED;
      this.filterProfileService.notifyCurrentProfileSuscriptors();
    });
  }

  /**
   * Returns the given source array unless a property exists in the
   * current object with the given propertyName and the length of
   * that property matches the given source.
   */
  returnEmptyIfAllSelected(source: Array<number>, propertyName: string) {
    const allSelected =
      source &&
      this[propertyName] &&
      source.length === this[propertyName].length;
    return allSelected ? [] : source;
  }

  getCategoriesForSelectionChange(source: Array<number>) {
    if (source) {
      if (this.rouseCategories) {
        if (source.length === this.rouseCategories.length) {
          return [];
        }
      }
      return source;
    }
    return source;
  }

  getOutliersForSelectionChange(source: Array<number>) {
    if (source) {
      if (this.outlierReasons) {
        if (source.length === this.outlierReasons.length) {
          return [];
        }
      }
    }
    return source;
  }

  getVerticalsForSelectionChange(source: Array<number>) {
    if (source) {
      if (this.verticals) {
        if (source.length === this.verticals.length) {
          return [];
        }
      }
    }
    return source;
  }

  handleClientSettingsReponse = (
    txAttributes: TxAttributes,
    benchmarks: Array<any>,
    comparisons: Array<any>,
    geographyLevels: Array<any>,
    customerSizes: Array<CustomerSize>,
    outlierReasons: Array<OutlierReason>,
    verticals: Array<ClientVertical>,
    localeFormats: Array<LocaleFormat>,
    rouseCategories: Array<RouseCategory>
  ): void => {
    let txFilterCount = 0;
    this.txAttributes = txAttributes;
    if (!this.txAttributes) {
      return;
    }

    this.SetRouseCategories(rouseCategories);
    this.SetOutliers(outlierReasons);
    this.SetVerticals(verticals);

    const currencySymbolDefault =
      this.filterProfileService.readClientProfileProperty(
        CODENAMES.CN_CLIENT_CURRENCY_SYMBOL
      );

    this.formatService.setLocaleFormat(
      _.find(localeFormats, (lf) => lf.LocaleFormatID === currencySymbolDefault)
    );

    this.setBenchmarks(benchmarks);
    this.setComparisons(comparisons);
    this.setGeographyLevels(geographyLevels);
    this.setCustomerSizes(customerSizes);

    this.defaultUseRouseSchema = this.getFilterDefaultBoolean(
      CODENAMES.CN_GENERAL_USE_ROUSE_SCHEMA
    );
    this.defaultIncludeAllRateTransactions = this.getFilterDefaultBoolean(
      CODENAMES.CN_TRANSACTION_INCLUDE_NOT_COMPARED_TRANSACTIONS
    );

    this.setSelectedMonthIds();

    this.hasOutlier = this.getFilterDefaultBoolean(
      CODENAMES.CN_GENERAL_HAS_OUTLIER
    );
    this.hasVertical = this.getFilterDefaultBoolean(
      CODENAMES.CN_GENERAL_HAS_VERTICAL
    );

    this.defaultExcludePrimeUnits = this.getFilterTransactionBoolean(
      CODENAMES.CN_EQUIPMENT_PRIME_UNITS,
      true
    );
    this.defaultExcludeSubstitutions = this.getFilterTransactionBoolean(
      CODENAMES.CN_TRANSACTION_SUBSTITUTIONS,
      this.txAttributes?.ExistsSubstitution
    );
    this.defaultExcludeNationalAccounts = this.getFilterTransactionBoolean(
      CODENAMES.CN_TRANSACTION_NATIONAL_ACCOUNTS,
      this.txAttributes?.ExistsNationalAcct
    );
    this.defaultExcludeRpos = this.getFilterTransactionBoolean(
      CODENAMES.CN_TRANSACTION_RPO,
      this.txAttributes?.ExistsRpo
    );
    this.defaultExcludeContracts = this.getFilterTransactionBoolean(
      CODENAMES.CN_TRANSACTION_CONTRACTS,
      this.txAttributes?.ExistsContracts
    );
    this.defaultExcludeSpecialPricing = this.getFilterTransactionBoolean(
      CODENAMES.CN_TRANSACTION_SPECIAL_PRICING,
      this.txAttributes?.ExistsSpecialPricing
    );
    this.defaultExcludeReRents = this.getFilterTransactionBoolean(
      CODENAMES.CN_TRANSACTION_RE_RENTS,
      this.txAttributes?.ExistsReRent
    );

    this.filterProfileService.selectedFilterValues.useRouseSchema =
      this.defaultUseRouseSchema;
    this.filterProfileService.selectedFilterValues.includeAllRateTransactions =
      this.defaultIncludeAllRateTransactions;
    this.filterProfileService.selectedFilterValues.excludePrimeUnits =
      this.defaultExcludePrimeUnits;
    this.filterProfileService.selectedFilterValues.excludeSubstitutions =
      this.defaultExcludeSubstitutions;
    this.filterProfileService.selectedFilterValues.excludeNationalAccts =
      this.defaultExcludeNationalAccounts;
    this.filterProfileService.selectedFilterValues.excludeRpos =
      this.defaultExcludeRpos;
    this.filterProfileService.selectedFilterValues.excludeContracts =
      this.defaultExcludeContracts;
    this.filterProfileService.selectedFilterValues.excludeSpecialPricing =
      this.defaultExcludeSpecialPricing;
    this.filterProfileService.selectedFilterValues.excludeReRent =
      this.defaultExcludeReRents;

    pairs(this.txAttributes)
      .pipe(
        map((x) => <[string, any]>x),
        filter((x) => this.txAttributes?.hasOwnProperty(x[0])),
        filter(
          (x) =>
            (<any>x[0]).startsWith('Exists') &&
            x[1] &&
            x[0].toString() !== 'ExistsPrimeUnit'
        )
      )
      .subscribe((x) => {
        txFilterCount += 1;
        this.useLarge = txFilterCount > 3;
      });

    const benchmarkGeography =
      this.filterProfileService.readCurrentProfileProperty(
        CODENAMES.CN_GEOGRAPHY_BENCHMARK_GEOGRAPHY
      );
    if (!_.isNil(benchmarkGeography)) {
      const defaultGID = benchmarkGeography.toString();
      if (defaultGID.length === 1) {
        this.defaultGeographies = [benchmarkGeography, benchmarkGeography];
      } else {
        this.defaultGeographies = [
          parseInt(defaultGID[0], 10),
          benchmarkGeography % 10,
        ];
      }
      this.geoLowValue = this.defaultGeographies[0];
      this.geoHighValue = this.defaultGeographies[1];
    }

    const cycleBill = this.filterProfileService.readCurrentProfileProperty(
      CODENAMES.CN_TRANSACTION_CYCLE_BILL
    );
    if (!_.isNil(cycleBill)) {
      const defaultCycleBill = cycleBill.toString();
      if (defaultCycleBill.length === 1) {
        this.defaultCycleBills = [cycleBill, cycleBill];
      } else {
        this.defaultCycleBills = [
          parseInt(defaultCycleBill[0], 10),
          cycleBill % 10,
        ];
      }
      this.cycleBillLowValue = this.defaultCycleBills[0];
      this.cycleBillHighValue = this.defaultCycleBills[1];
    }
  };

  setBenchmarks = (benchmarks: Array<any>): void => {
    // hide the customer size benchmark if not displaying the filter for custore size
    if (!this.getFilterDefaultBoolean(CODENAMES.CN_GENERAL_HAS_CUSTOMER_SIZE)) {
      benchmarks.splice(
        benchmarks.indexOf('Customer Size Adjusted Rate Benchmark'),
        1
      );
    }
    this.benchmarks = benchmarks.map(
      (x) =>
        <IMultiSelectOption>{
          id: x.Bid,
          name: x.DisplayName,
        }
    );
    const benchmarkDefault =
      this.filterProfileService.readCurrentProfileProperty(
        CODENAMES.CN_GEOGRAPHY_RATE_BENCHMARK
      );
    this.defaultBenchmark = _.find(this.benchmarks, (benchmark) => {
      return benchmark.id === benchmarkDefault;
    });
    if (!this.defaultBenchmark) {
      this.defaultBenchmark =
        this.benchmarks?.length > 0
          ? this.benchmarks[0]
          : this.defaultBenchmark;
    }
    if (this.defaultBenchmark) {
      this.selectedBenchmarkName = this.defaultBenchmark.name;
      this.selectedBenchmarkIds = [this.defaultBenchmark.id];
      this.filterProfileService.selectedFilterValues.bid =
        this.defaultBenchmark.id;
    }
  };

  setComparisons = (comparisons: Array<any>): void => {
    this.comparisons = comparisons.map(
      (x) =>
        <IMultiSelectOption>{
          id: x.Cid,
          name: x.DisplayName,
        }
    );
    const comparisonDefault =
      this.filterProfileService.readCurrentProfileProperty(
        CODENAMES.CN_GEOGRAPHY_PRIMARY_COMPARISON
      );
    this.defaultComparison = _.find(this.comparisons, (comparison) => {
      return comparison.id === comparisonDefault;
    });
    if (!this.defaultComparison) {
      this.defaultComparison =
        this.comparisons?.length > 0
          ? this.comparisons[0]
          : this.defaultComparison;
    }
    if (this.defaultComparison) {
      this.selectedComparison = this.defaultComparison.name;
      this.selectedComparisonIds = [this.defaultComparison.id];
      this.filterProfileService.selectedFilterValues.cid =
        this.defaultComparison.id;
    }
  };

  setRouseCategories = (rouseCategories: Array<RouseCategory>): void => {
    const categories = rouseCategories.map(
      (x) =>
        <IMultiSelectOption>{
          id: x.RouseCategoryID,
          name: x.RouseCategory,
        }
    );
    this.rouseCategories = categories;
  };

  setClientCategories = (clientCategories: Array<ClientCategory>): void => {
    const clientcategories = clientCategories.map(
      (x) =>
        <IMultiSelectOption>{
          id: x.ClientCategoryID,
          name: x.ClientCategory,
        }
    );
    this.clientCategories = clientcategories;
  };

  setCatProductGroups = (catProductGroups: Array<CatProductGroup>): void => {
    const cgp = catProductGroups.map(
      (x) =>
        <IMultiSelectOption>{
          id: x.CatProductGroup,
          name: x.CatProductGroup,
        }
    );

    this.catProductGroups = cgp;
  };
  setClientProductTypes = (
    clientProductTypes: Array<ClientProductType>
  ): void => {
    const flags = {};
    this.clientProductTypes = clientProductTypes.map(
      (x) =>
        <IMultiSelectOption>{
          id: x.Id,
          name: `${x.Description} (${x.CatClass})`,
          params: { parentId: x.CategoryId },
        }
    );
    this.filteredClientProductTypes = this.clientProductTypes
      .filter((d) => {
        if (flags[d.id]) {
          return false;
        } else {
          flags[d.id] = true;
          return true;
        }
      })
      .slice(); //
  };
  setRouseProductTypes = (rouseProductTypes: Array<RouseProductType>): void => {
    const flags = {};
    this.rouseProductTypes = rouseProductTypes.map(
      (x) =>
        <IMultiSelectOption>{
          id: x.Id,
          name: x.Description,
          params: { parentId: x.CategoryId },
        }
    );
    this.filteredRouseProductTypes = this.rouseProductTypes
      .filter((d) => {
        if (flags[d.id]) {
          return false;
        } else {
          flags[d.id] = true;
          return true;
        }
      })
      .slice();
  };

  setRouseMarkets = (rouseMarkets: Array<RouseMarket>): void => {
    const flags = {};

    this.rouseMarkets = rouseMarkets.map(
      (x) =>
        <IMultiSelectOption>{
          id: x.Id,
          name: x.Name,
          params: { parentId: x.DistrictId },
        }
    );

    this.filteredRouseMarkets = this.rouseMarkets
      .filter((d) => {
        if (flags[d.id]) {
          return false;
        } else {
          flags[d.id] = true;
          return true;
        }
      })
      .slice();
  };

  setCustomerSizes = (customerSizes: Array<CustomerSize>): void => {
    const options = customerSizes.map(
      (x) =>
        <IMultiSelectOption>{
          id: x.BinID,
          name: x.DisplayName,
        }
    );
    this.customerSizes = options;
  };

  setOutlierReasons = (outlierReasons: Array<OutlierReason>): void => {
    const options = outlierReasons.map(
      (x) =>
        <IMultiSelectOption>{
          id: x.OutlierReasonID,
          name: x.OutlierReason,
        }
    );
    this.outlierReasons = options;
  };

  setVerticals = (verticals: Array<ClientVertical>): void => {
    const options = verticals.map(
      (x) =>
        <IMultiSelectOption>{
          id: x.ClientVerticalID,
          name: x.ClientVerticalDescription,
        }
    );
    this.verticals = options;
  };

  setGeographyLevels = (geographyLevels: Array<any>): void => {
    this.geographyLevels = geographyLevels;
  };

  private setSelectedMonthIds = () => {
    const currentProfile = this.filterProfileService.currentProfile.value;
    const currentProfileMonthIdStart =
      currentProfile[CODENAMES.CN_GENERAL_MONTH_START];
    const currentProfileMonthIdEnd =
      currentProfile[CODENAMES.CN_GENERAL_MONTH_END];
    if (currentProfile && this.dates?.length > 0) {
      if (
        (currentProfile.profileId === -1 ||
          currentProfile.monthsCode === MonthSetting.DEFAULT) &&
        currentProfileMonthIdStart != null &&
        currentProfileMonthIdEnd != null
      ) {
        // Client profiles use monthIdStart and monthIdEnd rather than monthCode and months
        const currentMonthId = this.dates[0].id;
        const monthIdStart = currentMonthId + currentProfileMonthIdStart;
        const monthIdEnd = currentMonthId + currentProfileMonthIdEnd;
        currentProfile.monthsCode = MonthSetting.DEFAULT;
        this.filterProfileService.selectedMonthCode = MonthSetting.DEFAULT;
        this.filterProfileService.selectedMonthIds = [];
        for (let i = monthIdStart; i <= monthIdEnd; i++) {
          this.filterProfileService.selectedMonthIds.push(i);
        }
        //this.filterProfileService.selectedMonthIds = this.dates.filter(x => monthIdStart <= x.id && x.id <= monthIdEnd).map(x => x.id);
        this.filterProfileService.updateCurrentProfileValue(
          'months',
          this.filterProfileService.selectedMonthIds
        );
      } else {
        if (
          currentProfile.monthsCode !== MonthSetting.SELECTION ||
          !currentProfile.months ||
          currentProfile.months.length === 0
        ) {
          // User profiles use monthCode and months rather than monthIdStart and monthIdEnd
          this.filterProfileService.selectedMonthIds =
            FilterProfileMap.getMonths4MonthCodeAndSelection(
              currentProfile.monthsCode,
              this.dates[0].id,
              this.dates.map((x) => x.id),
              currentProfile.months,
              currentProfile[CODENAMES.CN_MAX_MONTHS_ALLOWED]
            );

          if (this.filterProfileService.selectedMonthIds.length === 0) {
            const currentMonthId = this.dates[0].id;
            const monthIdStart =
              currentMonthId +
              this.filterProfileService.readCurrentProfileProperty(
                CODENAMES.CN_GENERAL_MONTH_START
              );
            const monthIdEnd =
              currentMonthId +
              this.filterProfileService.readCurrentProfileProperty(
                CODENAMES.CN_GENERAL_MONTH_END
              );
            _.each(this.dates.slice(), (date) => {
              if (date.id >= monthIdStart && date.id <= monthIdEnd) {
                this.filterProfileService.selectedMonthIds.push(date.id);
              }
            });
          }
          this.filterProfileService.updateCurrentProfileValue(
            'months',
            this.filterProfileService.selectedMonthIds
          );
        } else {
          this.filterProfileService.selectedMonthIds =
            currentProfile.months.slice();
        }
      }

      this.filterProfileService.selectedMonthIds =
        this.filterProfileService.reconcileSelectedMonthsWithAvailableMonths(
          this.activeFilterService.dateOptions,
          this.filterProfileService.selectedMonthIds,
          FilterProfileMap.getCurrentMonthId(),
          this.filterProfileService.currentProfile.value.monthsCode
        );
      this.updateFilterDateRange(
        this.filterProfileService.selectedMonthIds,
        true
      );
    }
  };

  dateSelectionChange = (
    dateIds: Array<number>,
    updateMonthFilterBox = true
  ) => {
    this.filterProfileService.selectedMonthIds = [];
    if (dateIds.length) {
      const sequentialMonthIds = [];
      // ensure that the months are sequential
      const prevMonth = null;
      const sortedDateIds =
        dateIds[dateIds.length - 1] < dateIds[0]
          ? dateIds.slice().sort((a, b) => a - b)
          : dateIds
              .slice()
              .sort((a, b) => a - b)
              .reverse();
      let currentDateIndex = this.dates.findIndex(
        (x) => x.id === sortedDateIds[0]
      );

      if (
        (this.filterProfileService.selectedMonthCode = MonthSetting.SELECTION)
      ) {
        sortedDateIds.forEach((month) => {
          if (sequentialMonthIds.length === 0) {
            sequentialMonthIds.push(month);
          } else {
            const nextDateIndex = this.dates.findIndex((x) => x.id === month);
            if (
              nextDateIndex === currentDateIndex - 1 ||
              nextDateIndex === currentDateIndex + 1
            ) {
              sequentialMonthIds.push(month);
              currentDateIndex = nextDateIndex;
            } else {
              return;
            }
          }
        });
        this.filterProfileService.selectedMonthIds = sequentialMonthIds.slice();
      } else {
        this.filterProfileService.selectedMonthIds = sortedDateIds.slice();
      }

      //this.filterProfileService.reconcileSelectedMonthsWithAvailableMonths(this.dates, this.filterProfileService.selectedMonthIds, FilterProfileMap.getCurrentMonthId(), this.filterProfileService.currentProfile.value.monthsCode);
    }
    this.updateFilterDateRange(
      this.filterProfileService.selectedMonthIds,
      updateMonthFilterBox
    );
  };

  private setFilter = (f: FilterValues) => {
    //this.setSelectedMonthIds();
    // let filterSelectedMonthIds = [];
    // _.each(this.dates, dateOption => {
    //     if (dateOption.id >= f.monthFromId && dateOption.id <= f.monthToId) {
    //         filterSelectedMonthIds.push(dateOption.id);
    //     }
    // });
    // this.filterProfileService.selectedMonthIds = filterSelectedMonthIds.slice();
    // this.filterProfileService.selectedMonthIds = this.filterProfileService.reconcileSelectedMonthsWithAvailableMonths(this.dates, this.filterProfileService.selectedMonthIds,
    //     FilterProfileMap.getCurrentMonthId(), this.filterProfileService.currentProfile.value.monthsCode);
    // this.updateFilterDateRange(this.filterProfileService.selectedMonthIds, true);
  };

  private updateFilterDateRange = (
    selectedMonthIds,
    updateFilterBox = false
  ) => {
    if (selectedMonthIds.length) {
      const dateTuple =
        this.activeFilterService.getDateRangeFromSelectedMonthIds(
          selectedMonthIds
        );
      this.filterProfileService.selectedFilterValues.dateFrom = dateTuple[0];
      this.filterProfileService.selectedFilterValues.dateTo = dateTuple[1];
      this.filterProfileService.selectedFilterValues.monthFromId = _.min(
        this.filterProfileService.selectedMonthIds
      );
      this.filterProfileService.selectedFilterValues.monthToId = _.max(
        this.filterProfileService.selectedMonthIds
      );
      if (updateFilterBox) {
        this.setMonthFilterBox();
      }
    }
  };

  private setMonthFilterBox = () => {
    if (!this.filterProfileService.selectedMonthIds.length) {
      return;
    }
    if (this.filterProfileService.selectedMonthIds.length === 1) {
      let rawDateFrom = _.find(this.dates, (dateOption) => {
        return dateOption.id === this.filterProfileService.selectedMonthIds[0];
      });
      // Pick the latest date if the currently selected date is outside of the dates range
      // This happens because of an outdated db in STG.
      rawDateFrom = rawDateFrom ? rawDateFrom : this.dates[0];
      rawDateFrom.date = FilterProfile.monthId2Date(rawDateFrom.id);
      this.selectedMonthRange = this.formatDate(rawDateFrom.date);
    } else {
      const rawDateFrom = this.activeFilterService.readFilteredDateSafely(
        this.dates,
        this.filterProfileService.selectedMonthIds,
        _.min
      );
      const rawDateTo = this.activeFilterService.readFilteredDateSafely(
        this.dates,
        this.filterProfileService.selectedMonthIds,
        _.max
      );
      const dateFrom = this.formatDate(rawDateFrom);
      const dateTo = this.formatDate(rawDateTo);
      this.selectedMonthRange = dateFrom + '-' + dateTo;
    }
  };

  private updatePrimaryComparison = (cid: number) => {
    const comparison = _.find(this.comparisons, (x) => x.id === cid);
    if (comparison) {
      this.selectedComparison = comparison.name;
    }
  };

  private regionChanged = (selectedRegions: Array<number>) => {
    if (selectedRegions) {
      this.selectedRegions = selectedRegions;
    }
    const selectedAll = this.selectedRegions?.length >= this.regions.length;
    this.filterProfileService.selectedFilterValues.regions = selectedAll
      ? []
      : this.selectedRegions;
    this.enforceRegionConstraint();
    this.enforceDistrictConstraint();
    // this.enforceDistrictConstraintOnMarkets();
  };

  private districtChanged = (selectedDistricts: Array<number>) => {
    if (selectedDistricts) {
      this.selectedDistricts = selectedDistricts;
    }
    const selectedAll =
      this.selectedDistricts?.length >= this.filteredDistricts.length;
    this.filterProfileService.selectedFilterValues.districts = selectedAll
      ? []
      : this.selectedDistricts;
    this.enforceDistrictConstraint();
    // this.enforceDistrictConstraintOnMarkets();
  };

  private enforceRegionConstraint = () => {
    if (!this.districts || !this.selectedDistricts) {
      return;
    }
    const flags = {};
    const isDistinct = (o: IMultiSelectOption): boolean => {
      if (flags[o.id]) {
        return false;
      } else {
        flags[o.id] = true;
        return true;
      }
    };
    this.filteredDistricts = this.districts
      .filter(
        (d) =>
          this.selectedRegions.indexOf(d.params.parentId) > -1 ||
          !this.selectedRegions.length
      )
      .filter(isDistinct)
      .slice();
    this.selectedDistricts = this.selectedDistricts.filter(
      (d) => this.filteredDistricts.map((x) => x.id).indexOf(d) > -1
    );
    this.filterProfileService.selectedFilterValues.districts = this
      .filterProfileService.selectedFilterValues.districts
      ? this.filterProfileService.selectedFilterValues.districts.filter(
          (d) => this.filteredDistricts.map((x) => x.id).indexOf(d) > -1
        )
      : this.filterProfileService.selectedFilterValues.districts;
  };

  private enforceDistrictConstraint = () => {
    if (!this.branches || !this.selectedBranches) {
      return;
    }
    const flags = {};
    const isDistinct = (o: any): boolean => {
      if (flags[o.id]) {
        return false;
      } else {
        flags[o.id] = true;
        return true;
      }
    };
    this.filteredBranches = this.branches
      .filter(
        (b) =>
          this.selectedRegions.indexOf(b.params.regionId) > -1 ||
          (!this.selectedRegions.length &&
            this.regions.map((x) => x.id).indexOf(b.params.regionId) > -1)
      )
      .filter(
        (b) =>
          this.selectedDistricts.indexOf(b.params.parentId) > -1 ||
          (!this.selectedDistricts.length &&
            this.filteredDistricts.map((x) => x.id).indexOf(b.params.parentId) >
              -1)
      )
      .filter(isDistinct)
      .slice();

    this.selectedBranches = this.selectedBranches.filter(
      (b) => this.filteredBranches.map((x) => x.id).indexOf(b) > -1
    );

    if (this.filterProfileService.selectedFilterValues.branches) {
      this.filterProfileService.selectedFilterValues.branches =
        this.filterProfileService.selectedFilterValues.branches.filter(
          (b) => this.filteredBranches.map((x) => x.id).indexOf(b) > -1
        );
    }
  };

  private branchChanged = (selectedBranches: Array<number>) => {
    if (selectedBranches) {
      this.selectedBranches = selectedBranches;
    }
    const selectedAll =
      this.selectedBranches?.length >= this.filteredBranches.length;
    this.filterProfileService.selectedFilterValues.branches = selectedAll
      ? []
      : this.selectedBranches;
  };

  public closeFilter = () => {
    this.isFilterVisible = false;
    if (
      this.filterProfileService.currentfilterProfileApplied &&
      JSON.stringify(this.filterProfileService.currentfilterProfileApplied) !==
        JSON.stringify(this.filterProfileService.currentProfile.value)
    ) {
      const profile = this.filterProfileService.currentfilterProfileApplied;
      this.filterProfileService.updateProfileInList(profile);
      if (profile.profileId === -1) {
        this.filterProfileService.clientProfile.next(profile);
      }
      this.filterProfileService.currentProfile.next(
        this.filterProfileService.currentfilterProfileApplied
      );
      //this.applyProfile(this.filterProfileService.currentfilterProfileApplied, true);
    } else if (
      this.filterProfileService.currentProfile.value.profileId === -1
    ) {
      this.monthTooltip = null;
    }
  };

  private openFilter = () => {
    this.isFilterVisible = true;
    this.resetToMostRecentFilterState();
    setTimeout(() => {
      this.monthTooltip = this.translateService.instant(
        'main.tooltips.filters.month'
      );
    }, 200);
    setTimeout(() => {
      const geoSliderValue = $(`#${this.geoSliderId}`).slider('getValue');
      const cycleBillSliderValue = $(`#${this.cycleBillSliderId}`).slider(
        'getValue'
      );

      $(`#${this.geoSliderId}`).slider('refresh');
      $(`#${this.cycleBillSliderId}`).slider('refresh');

      $(`#${this.geoSliderId}`).slider('setValue', geoSliderValue);
      $(`#${this.cycleBillSliderId}`).slider('setValue', cycleBillSliderValue);
    }, 150);
  };

  private resetToMostRecentFilterState = () => {
    const currentFilter = this.activeFilterService.getCurrentFilter();
    this.setFilter(currentFilter);
    const lastBenchmark = _.find(
      this.benchmarks,
      (benchmarkOption: IMultiSelectOption) => {
        return benchmarkOption.id === currentFilter.bid;
      }
    );
    if (lastBenchmark) {
      this.selectedBenchmarkName = lastBenchmark.name;
      this.selectedBenchmarkIds = [lastBenchmark.id];
    }
    this.regionChanged(
      currentFilter.regions ? currentFilter.regions.slice() : []
    );
    this.districtChanged(
      currentFilter.districts ? currentFilter.districts.slice() : []
    );
    this.branchChanged(
      currentFilter.branches ? currentFilter.branches.slice() : []
    );
    this.rouseCategorySelectionChange(
      this.getCategoriesForSelectionChange(
        currentFilter.rouseCategories
          ? currentFilter.rouseCategories.slice()
          : []
      )
    );
    this.clientCategorySelectionChange(
      currentFilter.clientCategories
        ? currentFilter.clientCategories.slice()
        : []
    );
    this.catProductGroupSelectionChange(
      currentFilter.catProductGroups
        ? currentFilter.catProductGroups.slice()
        : []
    );

    this.rouseProductTypeSelectionChange(
      currentFilter.rouseProductTypes
        ? currentFilter.rouseProductTypes.slice()
        : []
    );
    this.clientProductTypeSelectionChange(
      currentFilter.clientProductTypes
        ? currentFilter.clientProductTypes.slice()
        : []
    );

    this.rouseMarketSelectionChange(
      currentFilter.rouseMarkets ? currentFilter.rouseMarkets.slice() : []
    );

    this.customerSizeSelectionChange(
      currentFilter.customerSizes ? currentFilter.customerSizes.slice() : []
    );
    this.outlierReasonSelectionChange(
      currentFilter.outlierReasons ? currentFilter.outlierReasons.slice() : []
    );
    this.verticalSelectionChange(
      currentFilter.verticals ? currentFilter.verticals.slice() : []
    );

    const lastComparison = _.find(
      this.comparisons,
      (comparisonOption: IMultiSelectOption) => {
        return comparisonOption.id === currentFilter.cid;
      }
    );
    if (lastComparison) {
      this.selectedComparison = lastComparison.name;
      this.selectedComparisonIds = [lastComparison.id];
    }
    this.filterProfileService.selectedFilterValues = currentFilter;
    if (this.isFilterVisible) {
      $('.apply-filters').focus();
    }
  };

  comparisonSelectionChange = (comparisonIds: Array<number>) => {
    if (this.selectedComparisonIds.length) {
      this.filterProfileService.selectedFilterValues.cid =
        this.selectedComparisonIds[0];
    } else {
      this.selectedComparisonIds = [this.comparisons[0].id];
      this.filterProfileService.selectedFilterValues.cid =
        this.selectedComparisonIds[0];
    }
  };

  private rouseCategorySelectionChange = (categoryIds: Array<number>) => {
    this.filterProfileService.selectedFilterValues.rouseProductTypes = []; // always reset product types
    if (!this.hasRouseCategories) {
      return;
    }
    // next block trying to avoid null / undefined errors
    this.filterProfileService.selectedFilterValues.rouseCategories = [
      ...categoryIds,
    ];
    this.selectedRouseCategoryIds = [
      ...this.filterProfileService.selectedFilterValues.rouseCategories,
    ];

    this.enforceRouseCategoryConstraint();
  };

  private enforceRouseCategoryConstraint = () => {
    if (!this.rouseProductTypes || !this.selectedRouseProductTypeIds) {
      return;
    }
    const flags = {};
    const isDistinct = (o: IMultiSelectOption): boolean => {
      if (flags[o.id]) {
        return false;
      } else {
        flags[o.id] = true;
        return true;
      }
    };
    this.filteredRouseProductTypes = this.rouseProductTypes
      .filter(
        (d) =>
          this.selectedRouseCategoryIds.indexOf(d.params.parentId) > -1 ||
          !this.selectedRouseCategoryIds.length
      )
      .filter(isDistinct)
      .slice();
    this.selectedRouseProductTypeIds = this.selectedRouseProductTypeIds.filter(
      (d) => this.filteredRouseProductTypes.map((x) => x.id).indexOf(d) > -1
    );
    this.filterProfileService.selectedFilterValues.rouseProductTypes = this
      .filterProfileService.selectedFilterValues.rouseProductTypes
      ? this.filterProfileService.selectedFilterValues.rouseProductTypes.filter(
          (d) => this.rouseProductTypes.map((x) => x.id).indexOf(d) > -1
        )
      : this.filterProfileService.selectedFilterValues.rouseProductTypes;
  };

  private enforceClientCategoryConstraint = () => {
    if (!this.clientProductTypes || !this.selectedClientProductTypeIds) {
      return;
    }
    const flags = {};
    const isDistinct = (o: IMultiSelectOption): boolean => {
      if (flags[o.id]) {
        return false;
      } else {
        flags[o.id] = true;
        return true;
      }
    };
    this.filteredClientProductTypes = this.clientProductTypes
      .filter(
        (d) =>
          this.selectedClientCategoryIds.indexOf(d.params.parentId) > -1 ||
          !this.selectedClientCategoryIds.length
      )
      .filter(isDistinct)
      .slice();
    this.selectedClientProductTypeIds =
      this.selectedClientProductTypeIds.filter(
        (d) => this.filteredClientProductTypes.map((x) => x.id).indexOf(d) > -1
      );
    this.filterProfileService.selectedFilterValues.clientProductTypes = this
      .filterProfileService.selectedFilterValues.clientProductTypes
      ? this.filterProfileService.selectedFilterValues.clientProductTypes.filter(
          (d) => this.clientProductTypes.map((x) => x.id).indexOf(d) > -1
        )
      : this.filterProfileService.selectedFilterValues.clientProductTypes;
  };

  private clientCategorySelectionChange = (
    clientCategoryIds: Array<number>
  ) => {
    this.selectedClientCategoryIds = [];
    this.filterProfileService.selectedFilterValues.clientProductTypes = [];
    if (!this.clientCategories) {
      this.clientCategories = [];
    }
    if (clientCategoryIds) {
      this.selectedClientCategoryIds = clientCategoryIds;
    }
    const selectedAll =
      this.selectedClientCategoryIds?.length >= this.clientCategories.length;
    this.filterProfileService.selectedFilterValues.clientCategories =
      selectedAll ? [] : this.selectedClientCategoryIds;
    this.enforceClientCategoryConstraint();
  };

  private catProductGroupSelectionChange = (
    catProductGroupsIds: Array<string>
  ) => {
    this.selectedCatProductGroupsIds = [];
    if (!this.catProductGroups) {
      this.catProductGroups = [];
    }
    if (catProductGroupsIds) {
      this.selectedCatProductGroupsIds = catProductGroupsIds;
    }
    const selectedAll =
      this.selectedCatProductGroupsIds?.length >= this.catProductGroups.length;
    this.filterProfileService.selectedFilterValues.catProductGroups =
      selectedAll ? [] : this.selectedCatProductGroupsIds;
  };

  private clientProductTypeSelectionChange = (
    clientProductTypeIds: Array<number>
  ) => {
    this.selectedClientProductTypeIds = [];
    if (!this.clientProductTypes) {
      this.clientProductTypes = [];
    }
    if (clientProductTypeIds) {
      this.selectedClientProductTypeIds = clientProductTypeIds;
    }
    const selectedAll =
      this.selectedClientProductTypeIds?.length >=
      this.clientProductTypes.length;
    this.filterProfileService.selectedFilterValues.clientProductTypes =
      selectedAll ? [] : this.selectedClientProductTypeIds;
  };
  private rouseProductTypeSelectionChange = (
    rouseProductTypeIds: Array<number>
  ) => {
    this.selectedRouseProductTypeIds = [];
    if (!this.rouseProductTypes) {
      this.rouseProductTypes = [];
    }
    if (rouseProductTypeIds) {
      this.selectedRouseProductTypeIds = rouseProductTypeIds;
    }
    const selectedAll =
      this.selectedRouseProductTypeIds?.length >= this.rouseProductTypes.length;
    this.filterProfileService.selectedFilterValues.rouseProductTypes =
      selectedAll ? [] : this.selectedRouseProductTypeIds;
  };

  private rouseMarketSelectionChange = (rouseMarketIds: Array<number>) => {
    this.selectedRouseMarketIds = [];
    if (!this.rouseMarkets) {
      this.rouseMarkets = [];
    }
    if (rouseMarketIds) {
      this.selectedRouseMarketIds = rouseMarketIds;
    }
  };

  private customerSizeSelectionChange = (customerSizeIds: Array<number>) => {
    if (customerSizeIds) {
      this.selectedCustomerSizeIds = customerSizeIds;
    }
    if (!this.selectedCustomerSizeIds) {
      this.selectedCustomerSizeIds = [];
    }
    if (!this.customerSizes) {
      this.customerSizes = [];
    }
    const selectedAll =
      this.selectedCustomerSizeIds?.length >= this.customerSizes.length;
    this.filterProfileService.selectedFilterValues.customerSizes = selectedAll
      ? []
      : this.selectedCustomerSizeIds;
  };

  private outlierReasonSelectionChange = (outlierReasonIds: Array<number>) => {
    if (outlierReasonIds) {
      this.selectedOutlierReasonIds = outlierReasonIds;
    }
    if (!this.selectedOutlierReasonIds) {
      this.selectedOutlierReasonIds = [];
    }
    if (!this.outlierReasons) {
      this.outlierReasons = [];
    }
    const selectedAll =
      this.selectedOutlierReasonIds?.length >= this.outlierReasons.length;
    this.filterProfileService.selectedFilterValues.outlierReasons = selectedAll
      ? []
      : this.selectedOutlierReasonIds;
  };

  private verticalSelectionChange = (verticalIds: Array<number>) => {
    if (verticalIds) {
      this.selectedVerticalIds = verticalIds;
    }
    const verticalsLength = this.verticals ? this.verticals.length : 0;
    const selectedAll = this.selectedVerticalIds?.length >= verticalsLength;
    this.filterProfileService.selectedFilterValues.verticals = selectedAll
      ? []
      : this.selectedVerticalIds;
  };

  private benchmarkSelectionChange = (benchmarkIds: Array<number>) => {
    const bid = benchmarkIds.length ? benchmarkIds[0] : this.benchmarks[0].id;
    if (!benchmarkIds.length) {
      this.selectedBenchmarkIds = [bid];
    }
    this.filterProfileService.selectedFilterValues.bid = bid;
    switch (bid.toString()) {
      case this.spotRateBenchmarkId:
        if (this.txAttributes?.ExistsSubstitution) {
          this.filterProfileService.selectedFilterValues.excludeSubstitutions =
            true;
        }
        if (this.txAttributes?.ExistsContracts) {
          this.filterProfileService.selectedFilterValues.excludeContracts =
            true;
        }
        if (this.txAttributes?.ExistsSpecialPricing) {
          this.filterProfileService.selectedFilterValues.excludeSpecialPricing =
            true;
        }
        if (this.txAttributes?.ExistsRpo) {
          this.filterProfileService.selectedFilterValues.excludeRpos = true;
        }
        if (this.txAttributes?.ExistsNationalAcct) {
          this.filterProfileService.selectedFilterValues.excludeNationalAccts =
            null;
        }

        this.filterProfileService.selectedFilterValues.cycleBillRange =
          this.spotRateCycleBillRange;
        this.setCycleBillSlider(
          _.min(this.spotRateCycleBillRange),
          _.max(this.spotRateCycleBillRange)
        );
        break;

      case this.standardRateBenchmarkId:
      case this.customerSizeAdjustedRateBenchmarkId:
        if (this.txAttributes?.ExistsSubstitution) {
          this.filterProfileService.selectedFilterValues.excludeSubstitutions =
            null;
        }
        if (this.txAttributes?.ExistsContracts) {
          this.filterProfileService.selectedFilterValues.excludeContracts =
            null;
        }
        if (this.txAttributes?.ExistsSpecialPricing) {
          this.filterProfileService.selectedFilterValues.excludeSpecialPricing =
            null;
        }
        if (this.txAttributes?.ExistsRpo) {
          this.filterProfileService.selectedFilterValues.excludeRpos = null;
        }
        if (this.txAttributes?.ExistsNationalAcct) {
          this.filterProfileService.selectedFilterValues.excludeNationalAccts =
            null;
        }

        this.setCycleBillSlider(
          _.min(this.defaultCycleBillRange),
          _.max(this.defaultCycleBillRange)
        );
        break;

      case this.contractRateBenchmarkId:
        if (this.txAttributes?.ExistsSubstitution) {
          this.filterProfileService.selectedFilterValues.excludeSubstitutions =
            true;
        }
        if (this.txAttributes?.ExistsContracts) {
          this.filterProfileService.selectedFilterValues.excludeContracts =
            false;
        }
        if (this.txAttributes?.ExistsSpecialPricing) {
          this.filterProfileService.selectedFilterValues.excludeSpecialPricing =
            null;
        }
        if (this.txAttributes?.ExistsNationalAcct) {
          this.filterProfileService.selectedFilterValues.excludeNationalAccts =
            null;
        }
        if (this.txAttributes?.ExistsRpo) {
          this.filterProfileService.selectedFilterValues.excludeRpos = null;
        }

        this.filterProfileService.selectedFilterValues.cycleBillRange =
          this.defaultCycleBillRange;
        this.setCycleBillSlider(
          _.min(this.defaultCycleBillRange),
          _.max(this.defaultCycleBillRange)
        );
        break;
    }
  };

  /**
   * Updates the low and high values of each slider in this component.
   */
  private subscribeToChangesInSliders() {
    // Only Once the first filter has been applied, changes to the slider also impact in the filter
    this.filterSliderService.geoSlider.subscribe((geoSlider) => {
      if (
        geoSlider &&
        geoSlider.low &&
        this.filterProfileService.loadingStage === ProfileLoadingStage.APPLIED
      ) {
        this.geoLowValue = geoSlider.low;
        this.geoHighValue = geoSlider.high;
        const gid: number = parseInt(
          `${this.geoLowValue}${this.geoLowValue === this.geoHighValue ? '' : this.geoHighValue}`,
          10
        );
        this.filterProfileService.selectedFilterValues.gid = gid;
        // Only Once the first filter has been applied, changes to the slider also impact in the filter
        this.filterProfileService.updateCurrentProfileValue(
          CODENAMES.CN_GEOGRAPHY_BENCHMARK_GEOGRAPHY,
          gid
        );
      }
    });
    this.filterSliderService.cycleBillSlider.subscribe((cycleBillSlider) => {
      if (
        cycleBillSlider &&
        cycleBillSlider.low &&
        this.filterProfileService.loadingStage === ProfileLoadingStage.APPLIED
      ) {
        const cbRange = [];
        for (let i = cycleBillSlider.low; i <= cycleBillSlider.high; i++) {
          cbRange.push(i);
        }
        this.filterProfileService.selectedFilterValues.cycleBillRange = cbRange;
        this.cycleBillLowValue = cycleBillSlider.low;
        this.cycleBillHighValue = cycleBillSlider.high;
        this.filterProfileService.updateCurrentProfileValue(
          CODENAMES.CN_TRANSACTION_CYCLE_BILL,
          cbRange
        );
      }
    });
  }

  /**
   * Maps the currently available data to a filter profile and
   * sets that as the current filter profile without triggering
   * suscriptors.
   */
  private updateRawFilterProfileData() {
    this.rawFilterProfileData =
      this.filterProfileService.readRawFilterComponentData();
    this.filterProfileService.runSilentCurrentProfileUpdate(
      this.rawFilterProfileData
    );
  }

  /**
   * Overrides settings with the given filter profile configuration.
   * It does not apply the filters, it only changes what is displayed
   * in the filter component screen.
   */
  private resetFiltersToProfile(
    profile: FilterProfile,
    updateMonthFilterBox: boolean = true
  ) {
    if (profile && profile.months) {
      this.resetSearchFilters();
      this.filterProfileService.selectedFilterValues = new FilterValues();
      this.filterProfileService.selectedMonthCode = profile.monthsCode;

      this.setSelectedMonthIds();

      if (updateMonthFilterBox) {
        this.dateSelectionChange(
          this.filterProfileService.selectedMonthIds || [],
          updateMonthFilterBox
        );
      }

      // General config flags
      this.filterProfileService.selectedFilterValues.useRouseSchema =
        profile[CODENAMES.CN_GENERAL_USE_ROUSE_SCHEMA];
      this.filterProfileService.selectedFilterValues.includeAllRateTransactions =
        profile[CODENAMES.CN_TRANSACTION_INCLUDE_NOT_COMPARED_TRANSACTIONS];
      this.filterProfileService.selectedFilterValues.excludePrimeUnits =
        profile[CODENAMES.CN_EQUIPMENT_PRIME_UNITS];
      this.filterProfileService.selectedFilterValues.excludeSubstitutions =
        profile[CODENAMES.CN_TRANSACTION_SUBSTITUTIONS];
      this.filterProfileService.selectedFilterValues.excludeNationalAccts =
        profile[CODENAMES.CN_TRANSACTION_NATIONAL_ACCOUNTS];
      this.filterProfileService.selectedFilterValues.excludeRpos =
        profile[CODENAMES.CN_TRANSACTION_RPO];
      this.filterProfileService.selectedFilterValues.excludeContracts =
        profile[CODENAMES.CN_TRANSACTION_CONTRACTS];
      this.filterProfileService.selectedFilterValues.excludeSpecialPricing =
        profile[CODENAMES.CN_TRANSACTION_SPECIAL_PRICING];
      this.filterProfileService.selectedFilterValues.excludeReRent =
        profile[CODENAMES.CN_TRANSACTION_RE_RENTS];

      // Benchmark
      this.applyBid(profile);

      // Comparisons
      this.applyComparison(profile);

      // Array values
      this.handleArrayProfileValue(
        profile,
        CODENAMES.CN_ROUSE_CATEGORIES,
        'rouseCategories',
        'rouseCategory',
        'filter.general.allow-disallow-rouse-categories'
      );
      this.handleArrayProfileValue(
        profile,
        CODENAMES.CN_TRANSACTION_OUTLIERS_SELECTION,
        'outlierReasons',
        'outlierReason',
        'filter.general.allow-disallow-outliers'
      );
      this.handleArrayProfileValue(
        profile,
        CODENAMES.CN_TRANSACTION_VERTICALS_SELECTION,
        'verticals',
        'vertical',
        'filter.general.allow-disallow-verticals'
      );
      this.clientCategorySelectionChange(
        profile[CODENAMES.CN_EQUIPMENT_CLIENT_CATEGORY]
      );
      this.catProductGroupSelectionChange(
        profile[CODENAMES.CN_EQUIPMENT_CAT_PRODUCT_GROUP_LIST]
      );
      this.rouseProductTypeSelectionChange(
        profile[CODENAMES.CN_EQUIPMENT_ROUSE_PRODUCT_TYPE_LIST]
      );
      this.clientProductTypeSelectionChange(
        profile[CODENAMES.CN_EQUIPMENT_CLIENT_PRODUCT_TYPE_LIST]
      );

      this.rouseMarketSelectionChange(
        profile[CODENAMES.CN_EQUIPMENT_ROUSE_MARKET_LIST]
      );
      this.customerSizeSelectionChange(profile[CODENAMES.CN_CUSTOMER_SIZE]);
      this.regionChanged(profile[CODENAMES.CN_GEOGRAPHY_REGION]);
      this.districtChanged(profile[CODENAMES.CN_GEOGRAPHY_DISTRICT]);
      this.branchChanged(profile[CODENAMES.CN_GEOGRAPHY_BRANCH]);

      // Sliders
      this.applyGid(profile, true);
      this.applyCycleBill(profile, true);
    }
  }

  /**
   * Sets the cycle bill values within the filter component only, without modifying the profiles.
   */
  private applyCycleBill(
    profile: FilterProfile,
    useProfileData: boolean = false
  ) {
    let cycleBillRange =
      this.filterProfileService.selectedFilterValues &&
      this.filterProfileService.selectedFilterValues.cycleBillRange &&
      this.filterProfileService.selectedFilterValues.cycleBillRange.length &&
      this.filterProfileService.loadingStage === ProfileLoadingStage.APPLIED &&
      !useProfileData
        ? this.filterProfileService.selectedFilterValues.cycleBillRange
        : profile[CODENAMES.CN_TRANSACTION_CYCLE_BILL];
    cycleBillRange =
      Array.isArray(cycleBillRange) && cycleBillRange.length === 1
        ? cycleBillRange[0]
        : cycleBillRange;
    if (Array.isArray(cycleBillRange)) {
      this.cycleBillLowValue =
        cycleBillRange && cycleBillRange.length ? _.min(cycleBillRange) : 1;
      this.cycleBillHighValue =
        cycleBillRange && cycleBillRange.length ? _.max(cycleBillRange) : 5;
      this.filterProfileService.selectedFilterValues.cycleBillRange =
        cycleBillRange;
    } else {
      cycleBillRange = cycleBillRange + '';
      this.cycleBillLowValue =
        cycleBillRange && cycleBillRange[0] ? parseInt(cycleBillRange[0]) : 1;
      this.cycleBillHighValue =
        cycleBillRange && cycleBillRange[1]
          ? parseInt(cycleBillRange[1])
          : cycleBillRange && cycleBillRange[0]
            ? parseInt(cycleBillRange[0])
            : 5;
      const selectedCycleBillIds = [];
      for (let i = this.cycleBillLowValue; i <= this.cycleBillHighValue; i++) {
        selectedCycleBillIds.push(i);
      }
      this.filterProfileService.selectedFilterValues.cycleBillRange =
        selectedCycleBillIds;
    }
    this.setCycleBillSlider(this.cycleBillLowValue, this.cycleBillHighValue);
  }

  protected onMonthCodeChanged(monthCode: MonthSetting) {
    this.filterProfileService.selectedMonthCode = monthCode;
  }

  listScroll(delta: number) {
    this.scrollPosition += delta * this.itemWidth;
    this.updateScroll(
      document.querySelector('.list-container') as HTMLElement | null
    );
  }

  updateScroll(listContainer: HTMLElement) {
    if (listContainer) {
      const listElements = listContainer.querySelectorAll('.filter-bar-item');

      listElements.forEach((listElement: HTMLElement) => {
        listElement.style.transform = `translateX(${-this.scrollPosition}px)`;
      });
    }
  }

  canGoBack() {
    return this.scrollPosition > 0;
  }

  canMoveForward() {
    const list = document.querySelector('.list-container');
    return list
      ? this.scrollPosition < list.scrollWidth - list.clientWidth
      : false;
  }

  // ------------------------- Apply Filter Profiles ------------------------- //
  private applyGid(
    profile: FilterProfile,
    useProfileData: boolean = false
  ): void {
    const useComponentData =
      this.filterProfileService.selectedFilterValues &&
      this.filterProfileService.selectedFilterValues.gid &&
      (this.filterProfileService.selectedFilterValues.gid + '').length &&
      !useProfileData;
    this.filterProfileService.loadingStage = ProfileLoadingStage.APPLIED;
    const profileGid = useComponentData
      ? this.filterProfileService.selectedFilterValues.gid + ''
      : profile[CODENAMES.CN_GEOGRAPHY_BENCHMARK_GEOGRAPHY] + '';
    this.geoLowValue =
      profileGid && profileGid[0] ? parseInt(profileGid[0]) : 1;
    this.geoHighValue =
      profileGid && profileGid[1]
        ? parseInt(profileGid[1])
        : profileGid && profileGid[0]
          ? parseInt(profileGid[0])
          : 4;
    const gid: number = parseInt(
      `${this.geoLowValue}${this.geoLowValue === this.geoHighValue ? '' : this.geoHighValue}`,
      10
    );
    this.filterProfileService.selectedFilterValues.gid = gid;
    this.setGeoSlider(this.geoLowValue, this.geoHighValue);
  }

  private applyBid(profile: FilterProfile) {
    const bid = profile[CODENAMES.CN_GEOGRAPHY_RATE_BENCHMARK];
    this.selectedBenchmarkName = _.find(this.benchmarks, (benchmark) => {
      return benchmark.id === bid;
    })?.name;
    this.selectedBenchmarkIds = [bid];
    this.filterProfileService.selectedFilterValues.bid = bid;
  }

  private applyComparison(profile: FilterProfile) {
    const cid = profile[CODENAMES.CN_GEOGRAPHY_PRIMARY_COMPARISON];
    this.selectedComparison = _.find(this.comparisons, (comparison) => {
      return comparison.id === cid;
    })?.name;
    this.selectedComparisonIds = [cid];
    this.filterProfileService.selectedFilterValues.cid = cid;
  }

  private setupGeoFilterBoxes(profile: FilterProfile) {
    this.regionChanged(profile[CODENAMES.CN_GEOGRAPHY_REGION]);
    this.districtChanged(profile[CODENAMES.CN_GEOGRAPHY_DISTRICT]);
    this.branchChanged(profile[CODENAMES.CN_GEOGRAPHY_BRANCH]);

    const regionBox: FilterBox = this.setupRegionFilterBox();
    const districtBox: FilterBox = this.setupDistrictFilterBox();
    const branchBox: FilterBox = this.setupBranchFilterBox();
    this.applyGeoFilterBoxDependencies(regionBox, districtBox, branchBox);
  }

  private applyGeoFilterBoxDependencies(
    regionBox: FilterBox,
    districtBox: FilterBox,
    branchBox: FilterBox
  ) {
    if (regionBox) {
      if (districtBox) {
        regionBox.dependents.push(districtBox);
      }
      if (branchBox) {
        regionBox.dependents.push(branchBox);
      }
      this.engagedFilters.push(regionBox);
    }
    if (districtBox) {
      if (branchBox) {
        districtBox.dependents.push(branchBox);
      }
      this.engagedFilters.push(districtBox);
    }
    if (branchBox) {
      this.engagedFilters.push(branchBox);
    }
  }

  private setupRegionFilterBox(): FilterBox {
    let regionBox: FilterBox;
    if (
      this.filterProfileService.selectedFilterValues.regions &&
      !!this.filterProfileService.selectedFilterValues.regions.length
    ) {
      const regionLabel =
        this.filterProfileService.selectedFilterValues.regions.length >
        this.NUMBER_OF_GEOS_TO_SHOW
          ? [
              'main.filters.x_selected',
              this.filterProfileService.selectedFilterValues.regions.length +
                '',
            ]
          : this.regions
              .filter(
                (region) =>
                  this.filterProfileService.selectedFilterValues.regions.indexOf(
                    region.id
                  ) > -1
              )
              .map((region) => region.name)
              .join(this.FILTER_BOX_SEPARATOR);
      regionBox = new FilterBox(
        regionLabel,
        () => {
          this.filterProfileService.selectedFilterValues.regions = [];
          this.filterProfileService.selectedFilterValues.districts = [];
          this.filterProfileService.selectedFilterValues.branches = [];
          this.filterDataService
            .getDistricts(
              this.filterProfileService.selectedFilterValues.districts
            )
            .subscribe(this.initializeDistricts);
          this.filterDataService
            .getBranches(
              this.filterProfileService.selectedFilterValues.regions,
              this.filterProfileService.selectedFilterValues.districts
            )
            .subscribe(this.initializeBranches);
        },
        [],
        this.filterLabels[CODENAMES.CN_GEOGRAPHY_REGION]
      );
    }
    return regionBox;
  }

  private setupDistrictFilterBox(): FilterBox {
    let districtBox: FilterBox;
    if (
      this.filterProfileService.selectedFilterValues.districts &&
      !!this.filterProfileService.selectedFilterValues.districts.length
    ) {
      const districtLabel =
        this.filterProfileService.selectedFilterValues.districts.length >
        this.NUMBER_OF_GEOS_TO_SHOW
          ? [
              'main.filters.x_selected',
              this.filterProfileService.selectedFilterValues.districts.length +
                '',
            ]
          : this.asDistinctCopy(
              this.districts.filter(
                (district) =>
                  this.filterProfileService.selectedFilterValues.districts.indexOf(
                    district.id
                  ) > -1
              )
            )
              .map((district) => district.name)
              .join(this.FILTER_BOX_SEPARATOR);

      districtBox = new FilterBox(
        districtLabel,
        () => {
          this.filterProfileService.selectedFilterValues.districts = [];
          this.filterProfileService.selectedFilterValues.branches = [];
          this.filterDataService
            .getBranches(
              [],
              this.filterProfileService.selectedFilterValues.districts
            )
            .subscribe(this.initializeBranches);
        },
        [],
        this.filterLabels[CODENAMES.CN_GEOGRAPHY_DISTRICT]
      );
    }
    return districtBox;
  }

  private setupBranchFilterBox(): FilterBox {
    let branchBox: FilterBox;
    if (
      this.filterProfileService.selectedFilterValues.branches &&
      !!this.filterProfileService.selectedFilterValues.branches.length
    ) {
      const branchLabel =
        this.filterProfileService.selectedFilterValues.branches.length >
        this.NUMBER_OF_GEOS_TO_SHOW
          ? [
              'main.filters.x_selected',
              this.filterProfileService.selectedFilterValues.branches.length +
                '',
            ]
          : this.asDistinctCopy(
              this.branches.filter(
                (branch) =>
                  this.filterProfileService.selectedFilterValues.branches.indexOf(
                    branch.id
                  ) > -1
              )
            )
              .map((branch) => branch.name)
              .join(this.FILTER_BOX_SEPARATOR);

      branchBox = new FilterBox(
        branchLabel,
        () => {
          this.filterProfileService.selectedFilterValues.branches = [];
        },
        [],
        this.filterLabels[CODENAMES.CN_GEOGRAPHY_BRANCH]
      );
    }
    return branchBox;
  }

  private setupCycleBillFilterBox(): void {
    if (
      this.filterProfileService.selectedFilterValues.cycleBillRange &&
      this.filterProfileService.selectedFilterValues.cycleBillRange.length
    ) {
      const min = _.min(
        this.filterProfileService.selectedFilterValues.cycleBillRange
      );
      const max = _.max(
        this.filterProfileService.selectedFilterValues.cycleBillRange
      );
      const defaultRange = min === 1 && max === 5;
      if (!defaultRange) {
        const title = this.filterDataService.getCycleBillTitle(min, max);
        this.engagedFilters.push(
          new FilterBox(title, () => {
            this.closeCycleByllFilterBox();
          })
        );
      }
    }
  }

  private closeCycleByllFilterBox() {
    this.filterProfileService.selectedFilterValues.cycleBillRange =
      this.defaultCycleBillRange;
    this.filterProfileService.updateCurrentProfileValue(
      CODENAMES.CN_TRANSACTION_CYCLE_BILL,
      this.defaultCycleBillRange
    );
    this.cycleBillHighValue = _.max(this.defaultCycleBillRange);
    this.cycleBillLowValue = _.min(this.defaultCycleBillRange);
    this.setCycleBillSlider(this.cycleBillLowValue, this.cycleBillHighValue);
  }

  private asDistinctCopy<T>(arr: T[]): T[] {
    const flags = {};
    const isDistinct = (o: any): boolean => {
      if (flags[o.id]) {
        return false;
      } else {
        flags[o.id] = true;
        return true;
      }
    };
    return arr.filter(isDistinct).slice();
  }

  /**
   * Generic filter box setup funciton.
   */
  private setupFilterBox(propertyName: string, codeName: string): FilterBox {
    let filterBox: FilterBox;
    codeName = codeName.replace('-array', '');
    if (
      this.filterProfileService.selectedFilterValues[propertyName] &&
      !!this.filterProfileService.selectedFilterValues[propertyName].length &&
      this[propertyName]
    ) {
      const propertyLabel =
        this.filterProfileService.selectedFilterValues[propertyName].length >
        this.NUMBER_OF_GEOS_TO_SHOW
          ? [
              'main.filters.x_selected',
              this.filterProfileService.selectedFilterValues[propertyName]
                .length + '',
            ]
          : this[propertyName]
              .filter(
                (prop) =>
                  this.filterProfileService.selectedFilterValues[
                    propertyName
                  ].indexOf(prop.id) > -1
              )
              .map((prop) => prop.name)
              .join(this.FILTER_BOX_SEPARATOR);
      filterBox = new FilterBox(
        propertyLabel,
        () => {
          // Filter Box Close Function
          this.filterProfileService.selectedFilterValues[propertyName] = [];
        },
        [],
        this.filterLabels[codeName]
      );
      this.engagedFilters.push(filterBox);
    }
    return filterBox;
  }

  private setupBooleanFilterBox(
    txAttributeName: string,
    filterPropertyName: string,
    trueCodeName: string,
    nonTrueCodeName: string
  ) {
    const validTx =
      this.txAttributes &&
      txAttributeName &&
      this.txAttributes[txAttributeName];
    if (
      validTx &&
      this.filterProfileService.selectedFilterValues[filterPropertyName] != null
    ) {
      const includeTrueLabel = [
        CODENAMES.CN_INCLUDE_X_ONLY,
        this.filterLabels[trueCodeName],
      ];
      const includeNonTrueLabel = [
        CODENAMES.CN_INCLUDE_X_ONLY,
        this.filterLabels[nonTrueCodeName],
      ];
      this.engagedFilters.push(
        new FilterBox(
          this.filterProfileService.selectedFilterValues[filterPropertyName]
            ? includeNonTrueLabel
            : includeTrueLabel,
          () => {
            this.filterProfileService.selectedFilterValues[filterPropertyName] =
              null;
          }
        )
      );
    }
  }

  private setupNonGeoFilterBoxes() {
    this.setupFilterBox('rouseCategories', CODENAMES.CN_EQUIPMENT_CATEGORY);
    this.setupFilterBox(
      'clientCategories',
      CODENAMES.CN_EQUIPMENT_CLIENT_CATEGORY
    );
    this.setupFilterBox('customerSizes', CODENAMES.CN_CUSTOMER_SIZE);
    this.setupFilterBox('outlierReasons', CODENAMES.CN_TRANSACTION_OUTLIERS);
    this.setupFilterBox('verticals', CODENAMES.CN_TRANSACTION_VERTICALS);
    this.setupFilterBox(
      'catProductGroups',
      CODENAMES.CN_EQUIPMENT_CAT_PRODUCT_GROUP_LIST
    );
    this.setupFilterBox(
      'clientProductTypes',
      CODENAMES.CN_EQUIPMENT_CLIENT_PRODUCT_TYPE_LIST
    );
    this.setupFilterBox(
      'rouseProductTypes',
      CODENAMES.CN_EQUIPMENT_ROUSE_PRODUCT_TYPE_LIST
    );
    this.setupFilterBox(
      'rouseMarkets',
      CODENAMES.CN_EQUIPMENT_ROUSE_MARKET_LIST
    );
  }

  private setupBooleanFilterBoxes() {
    if (this.txAttributes) {
      this.setupBooleanFilterBox(
        'ExistsNationalAcct',
        'excludeNationalAccts',
        CODENAMES.CN_TRANSACTION_NATIONAL_ACCOUNTS,
        CODENAMES.CN_TRANSACTION_NON_NATIONAL_ACCOUNTS
      );
      this.setupBooleanFilterBox(
        'ExistsContracts',
        'excludeContracts',
        CODENAMES.CN_TRANSACTION_CONTRACTS,
        CODENAMES.CN_TRANSACTION_NON_CONTRACTS
      );
      this.setupBooleanFilterBox(
        'ExistsRpo',
        'excludeRpos',
        CODENAMES.CN_TRANSACTION_RPO,
        CODENAMES.CN_TRANSACTION_NON_RPO
      );
      this.setupBooleanFilterBox(
        'ExistsSpecialPricing',
        'excludeSpecialPricing',
        CODENAMES.CN_TRANSACTION_SPECIAL_PRICING,
        CODENAMES.CN_TRANSACTION_NON_SPECIAL_PRICING
      );
      this.setupBooleanFilterBox(
        'ExistsSubstitution',
        'excludeSubstitutions',
        CODENAMES.CN_TRANSACTION_SUBSTITUTIONS,
        CODENAMES.CN_TRANSACTION_NON_SUBSTITUTIONS
      );
      this.setupBooleanFilterBox(
        'ExistsPrimeUnit',
        'excludePrimeUnits',
        CODENAMES.CN_EQUIPMENT_PRIME_UNITS,
        CODENAMES.CN_EQUIPMENT_NON_PRIME_UNIT
      );
      this.setupBooleanFilterBox(
        'ExistsReRent',
        'excludeReRent',
        CODENAMES.CN_TRANSACTION_RE_RENTS,
        CODENAMES.CN_TRANSACTION_NON_RE_RENTS
      );
      if (this.filterProfileService.selectedFilterValues.useRouseSchema) {
        this.engagedFilters.push(
          new FilterBox(CODENAMES.CN_USE_ROUSE_SCHEMA, () => {
            this.filterProfileService.selectedFilterValues.useRouseSchema =
              false;
          })
        );
      }
    }
  }

  private applyProfile(profile: FilterProfile, applyFilters: boolean) {
    try {
      if (
        FilterProfileMap.validateProfile(profile) &&
        this.filterProfileService.loadingStage !== ProfileLoadingStage.STARTING
      ) {
        this.resetFiltersToProfile(profile, true);
        this.applyGid(profile);
        this.applyCycleBill(profile);

        this.setSelectedMonthIds();

        this.engagedFilters.splice(0, this.engagedFilters.length);

        this.applyBid(profile);
        this.applyComparison(profile);
        this.setupGeoFilterBoxes(profile);
        this.setupNonGeoFilterBoxes();
        this.setupBooleanFilterBoxes();
        this.setupCycleBillFilterBox();

        this.activeFilterService.updateFilter(
          this.filterProfileService.selectedFilterValues,
          FilterBroadcastType.Global
        );
        if (applyFilters) {
          this.applyFilters(FilterBroadcastType.Global);
        }
      }
    } catch (e) {
      console.error(e);
      // alert('filter.component.apply ' + this.translateService.instant('main.filters.filters_threw_error'));
    } finally {
    }
    // Do not call update raw filter profile data here...
  }

  public applyFiltersClick = () => {
    this.applyFilters();
    const updatedProfile =
      this.filterProfileService.getFilterProfileFromFilterValues();
    this.filterProfileService.currentfilterProfileApplied = updatedProfile;
  };
  private applyFilters = (
    filterBroadcastType = FilterBroadcastType.Global,
    hideFilterMenu: boolean = true
  ) => {
    try {
      this.resetSearchFilters();
      const gid: number = parseInt(
        `${this.geoLowValue}${this.geoLowValue === this.geoHighValue ? '' : this.geoHighValue}`,
        10
      );
      this.filterProfileService.selectedFilterValues.gid = gid;

      const selectedCycleBillIds = [];
      for (let i = this.cycleBillLowValue; i <= this.cycleBillHighValue; i++) {
        selectedCycleBillIds.push(i);
      }
      this.filterProfileService.selectedFilterValues.cycleBillRange =
        selectedCycleBillIds;
      this.monthTooltip = null;
      if (!this.filterProfileService.selectedMonthIds.length) {
        this.filterProfileService.selectedMonthIds = [this.dates[0].id];
      }
      //this.setSelectedMonthIds();

      this.filterProfileService.selectedMonthIds =
        this.filterProfileService.reconcileSelectedMonthsWithAvailableMonths(
          this.dates,
          this.filterProfileService.selectedMonthIds,
          FilterProfileMap.getCurrentMonthId(),
          this.filterProfileService.currentProfile.value.monthsCode
        );
      this.updateFilterDateRange(
        this.filterProfileService.selectedMonthIds,
        true
      );

      this.engagedFilters.splice(0, this.engagedFilters.length);

      /* Apply the selected markets to saved filters. */
      const allMarketsSelected =
        this.selectedRouseMarketIds?.length >= this.rouseMarkets.length;

      this.filterProfileService.selectedFilterValues.rouseMarkets =
        allMarketsSelected ? [] : this.selectedRouseMarketIds;

      this.activeFilterService.updateFilter(
        this.filterProfileService.selectedFilterValues,
        filterBroadcastType
      );

      const bench =
        this.filterProfileService.selectedFilterValues.bid &&
        _.find(
          this.benchmarks,
          (x) =>
            x.id.toString() ===
            this.filterProfileService.selectedFilterValues.bid.toString()
        );
      if (bench) {
        this.selectedBenchmarkName = bench.name;
      }

      this.updatePrimaryComparison(
        Number(this.filterProfileService.selectedFilterValues.cid)
      );

      // setup filter boxes and close routines
      const regionBox: FilterBox = this.setupRegionFilterBox();
      const districtBox: FilterBox = this.setupDistrictFilterBox();
      const branchBox: FilterBox = this.setupBranchFilterBox();
      this.applyGeoFilterBoxDependencies(regionBox, districtBox, branchBox);

      //this.setMonthFilterBox();
      this.setupNonGeoFilterBoxes();
      this.setupBooleanFilterBoxes();
      this.setupCycleBillFilterBox();
      this.filterProfileService.removeTempProfile(true);
      this.filterProfileService.notifyProfileListSuscriptors();
    } catch (e) {
      alert(
        'filter.component.apply ' +
          this.translateService.instant('main.filters.filters_threw_error')
      );
    } finally {
    }
    this.updateRawFilterProfileData();
    setTimeout(() => {
      this.isFilterVisible =
        hideFilterMenu === true ? false : this.isFilterVisible;
    }, 1);
  };

  private SetVerticals(verticals: ClientVertical[]) {
    this.hasVertical = this.getFilterDefaultBoolean(
      CODENAMES.CN_GENERAL_HAS_VERTICAL
    );
    if (this.hasVertical) {
      this.defaultVerticals =
        this.filterProfileService.readCurrentProfileProperty(
          CODENAMES.CN_TRANSACTION_VERTICALS_SELECTION
        );
      this.selectedVerticalIds = this.defaultVerticals
        ? JSON.parse(JSON.stringify(this.defaultVerticals))
        : [];
      if (
        this.selectedVerticalIds &&
        this.selectedVerticalIds.length === verticals.length
      ) {
        this.selectedVerticalIds = [];
      }
      this.setVerticals(verticals);
      this.verticalSelectionChange(
        this.getVerticalsForSelectionChange(this.selectedVerticalIds)
      );
    }
  }

  private SetOutliers(outlierReasons: OutlierReason[]) {
    this.hasOutlier = this.getFilterDefaultBoolean(
      CODENAMES.CN_GENERAL_HAS_OUTLIER
    );
    if (this.hasOutlier) {
      this.defaultOutliers =
        this.filterProfileService.readCurrentProfileProperty(
          CODENAMES.CN_TRANSACTION_OUTLIERS_SELECTION
        );
      this.selectedOutlierReasonIds = this.defaultOutliers
        ? JSON.parse(JSON.stringify(this.defaultOutliers))
        : [];
      if (
        this.selectedOutlierReasonIds &&
        this.selectedOutlierReasonIds.length === outlierReasons.length
      ) {
        this.selectedOutlierReasonIds = [];
      }
      this.setOutlierReasons(outlierReasons);
      this.outlierReasonSelectionChange(
        this.getOutliersForSelectionChange(this.selectedOutlierReasonIds)
      );
    }
  }

  private SetRouseCategories(rouseCategories: RouseCategory[]) {
    this.hasRouseCategories = this.getFilterDefaultBoolean(
      CODENAMES.CN_GENERAL_HAS_ROUSE_CATEGORIES
    );
    if (this.hasRouseCategories) {
      this.defaultRouseCategories =
        this.filterProfileService.readCurrentProfileProperty(
          'filter.general.rouse-categories-selection'
        );
      this.selectedRouseCategoryIds = this.defaultRouseCategories
        ? JSON.parse(JSON.stringify(this.defaultRouseCategories))
        : [];
      if (
        this.selectedRouseCategoryIds &&
        this.selectedRouseCategoryIds.length === rouseCategories.length
      ) {
        this.selectedRouseCategoryIds = [];
      }
      this.setRouseCategories(rouseCategories);
      this.rouseCategorySelectionChange(
        this.getCategoriesForSelectionChange(this.selectedRouseCategoryIds)
      );
    }
  }

  handleTranslation(path: string, innerTitle: string) {
    const translated = this.formatService.translateAndFormat(path, true);
    return translated.replace('${1}', innerTitle);
  }

  private initializeRegions = (regions: Array<GeoFilterItem>) => {
    this.regions = regions.map(
      (x) =>
        <IMultiSelectOption>{
          id: x.id,
          name: x.description,
          params: { parentId: x.parentId },
        }
    );
  };

  private initializeDistricts = (districts: Array<GeoFilterItem>) => {
    const flags = {};
    this.districts = districts.map(
      (x) =>
        <IMultiSelectOption>{
          id: x.id,
          name: x.description,
          params: { parentId: x.parentId },
        }
    );
    this.filteredDistricts = this.districts
      .filter((d) => {
        if (flags[d.id]) {
          return false;
        } else {
          flags[d.id] = true;
          return true;
        }
      })
      .slice();
  };

  private initializeBranches = (branches: Array<GeoFilterItem>) => {
    const flags = {};
    this.branches = branches.map(
      (x) =>
        <IMultiSelectOption>{
          id: x.id,
          name: x.description,
          params: { parentId: x.parentId, regionId: x.regionId },
        }
    );
    this.filteredBranches = this.branches
      .filter((b) => {
        if (flags[b.id]) {
          return false;
        } else {
          flags[b.id] = true;
          return true;
        }
      })
      .slice();
  };

  private removeActiveFilter = (
    filterBox: FilterBox,
    updateFilter: boolean = true
  ) => {
    filterBox.close();
    if (filterBox.dependents.length) {
      filterBox.dependents.map((dep) => this.removeActiveFilter(dep, false));
    }
    _.pull(this.engagedFilters, filterBox);
    if (updateFilter) {
      this.activeFilterService.updateFilter(
        this.filterProfileService.selectedFilterValues,
        FilterBroadcastType.Global
      );
      const updatedProfile =
        this.filterProfileService.getFilterProfileFromFilterValues();
      this.filterProfileService.currentfilterProfileApplied = updatedProfile;
    }
  };

  private removeAllActiveFilters = (filters: string[]) => {
    const f: any[] = [];
    for (const filterBox of this.engagedFilters) {
      if (
        filterBox.description === 'Outliers' ||
        filterBox.description === 'Verticals'
      ) {
        f.push(filter);
      }
    }
  };

  private setAlertFilters = (alert: Alert) => {
    this.filterProfileService
      .setEmptyProfile(alert.AlertDescription)
      .subscribe((loadedProfile) => {
        this.resetFiltersToProfile(loadedProfile, false);
        if (alert.Country) {
          this.regionChanged([]);
          this.districtChanged([]);
          this.branchChanged([]);
        } else {
          this.regionChanged(alert.RegionId ? [alert.RegionId] : []);
          this.districtChanged(alert.DistrictId ? [alert.DistrictId] : []);
          this.branchChanged(alert.BranchId ? [alert.BranchId] : []);
        }
        if (alert.GID.toString().length === 2) {
          this.geoLowValue = parseInt(alert.GID.toString()[0], 10);
          this.geoHighValue = alert.GID % 10;
          this.filterProfileService.selectedFilterValues.gid = alert.GID;
          this.setGeoSlider(this.geoLowValue, this.geoHighValue);
        }
        this.benchmarkSelectionChange([alert.BID]);
        this.filterProfileService.selectedFilterValues.bid = alert.BID;

        if (alert.CBID && alert.CBID.toString().length === 2) {
          this.setCycleBillSlider(
            parseInt(alert.CBID.toString()[0], 10),
            alert.CBID % 10
          );
        }
        if (alert.MonthIDStart && alert.MonthIDEnd) {
          const selectedMonthIds = [];
          const copyOfMonths = JSON.parse(JSON.stringify(this.dates));
          // This reverses the collection by id
          _.orderBy(copyOfMonths, ['id'], ['desc'])
            .filter(
              (x) => x.id >= alert.MonthIDStart && x.id <= alert.MonthIDEnd
            )
            .map((x) => x.id)
            .forEach((monthId) => {
              selectedMonthIds.push(monthId);
            });
          this.dateSelectionChange(selectedMonthIds);
        }
        this.filterProfileService.selectedFilterValues.excludeContracts =
          alert.IsContract !== null ? !alert.IsContract : null;
        this.filterProfileService.selectedFilterValues.excludeNationalAccts =
          alert.IsNationalAcct !== null ? !alert.IsNationalAcct : null;
        this.filterProfileService.selectedFilterValues.excludeRpos =
          alert.IsRPO !== null ? !alert.IsRPO : null;
        this.filterProfileService.selectedFilterValues.excludeSpecialPricing =
          alert.IsSpecialPricing !== null ? !alert.IsSpecialPricing : null;
        this.filterProfileService.selectedFilterValues.excludeSubstitutions =
          alert.IsSubstitution !== null ? !alert.IsSubstitution : null;
        this.filterProfileService.selectedFilterValues.excludePrimeUnits =
          alert.IsPrime !== null ? !alert.IsPrime : null;
        this.filterProfileService.selectedFilterValues.excludeReRent =
          alert.IsReRent !== null ? !alert.IsReRent : null;
        this.filterProfileService.selectedFilterValues.useRouseSchema =
          !!alert.UseRouseSchema;

        this.filterProfileService.currentfilterProfileApplied = loadedProfile;

        this.applyFilters(FilterBroadcastType.Alert);
      });
  };

  /**
   * Manages array values obtained from a filter profile to
   * set the corresponding fields within the filter component.
   */
  private handleArrayProfileValue(
    profile: FilterProfile,
    codeName: string,
    propertyName: string,
    functionName: string,
    allowDissalowCodeName: string
  ) {
    let profilePropertyValue = this.handleAllowDisallow(
      profile,
      codeName,
      propertyName,
      allowDissalowCodeName
    );
    profilePropertyValue = this.validateIdsAndUpdateProfile(
      propertyName,
      profilePropertyValue,
      profile,
      codeName,
      allowDissalowCodeName
    );
    this['has' + propertyName] = !!(
      profilePropertyValue && profilePropertyValue.length
    );
    this[functionName + 'SelectionChange'](
      this.returnEmptyIfAllSelected(profilePropertyValue, propertyName)
    );
  }

  private validateIdsAndUpdateProfile(
    propertyName: string,
    profilePropertyValue: any,
    profile: FilterProfile,
    codeName: string,
    allowDissalowCodeName: string
  ) {
    const validIds = this[propertyName]
      ? this[propertyName].map((x) => x.id)
      : [];
    profilePropertyValue = profilePropertyValue.filter((x) =>
      validIds.includes(x)
    ); // remove invalid ids
    profile[codeName] = profilePropertyValue; // Update property so that invalid ids are avoided
    const allow = profile[allowDissalowCodeName];
    if (!allow) {
      profile[allowDissalowCodeName] = true;
    }
    return profilePropertyValue;
  }

  public handleAllowDisallow(
    profile: FilterProfile,
    codeName: string,
    propertyName: string,
    allowDissalowCodeName: string
  ) {
    const profilePropertyValue = profile[codeName] ? profile[codeName] : [];
    const allow = profile[allowDissalowCodeName];
    if (!allow) {
      const selected = _.filter(
        this[propertyName],
        (option) => !profilePropertyValue.includes(option.id)
      ).map((option: IMultiSelectOption) => option.id);
      return selected;
    }
    return profilePropertyValue;
  }

  /**
   * For 1px button that QA uses for caching scripts
   */
  public restoreDefaults() {
    this.filterProfileService.loadProfile(
      this.filterProfileService.currentProfile.value.profileId
    );
  }

  private setGeoSlider = (low: number, high: number) => {
    this.filterDataService.getFilterLabels().subscribe((labels) => {
      this.filterSliderService.setFilterLabels(labels);
      this.filterSliderService.setGeoSlider(low, high);
      const cid = low < high ? low + '' + high : low + '';
      this.filterProfileService.updateCurrentProfileValue(
        CODENAMES.CN_GEOGRAPHY_BENCHMARK_GEOGRAPHY,
        cid
      );
    });
  };

  private setCycleBillSlider = (low: number, high: number) => {
    this.filterSliderService.setAnyLabels(this.filterLabels);
    this.filterSliderService.setCycleBillSlider(low, high);
    const cbRange = [];
    for (let i = low; i <= high; i++) {
      cbRange.push(i);
    }
    this.filterProfileService.updateCurrentProfileValue(
      CODENAMES.CN_TRANSACTION_CYCLE_BILL,
      cbRange
    );
  };

  // ---------------------------- Functions related to Drop Downs ---------------------------- //

  /**
   * Clears the search filter in the given drop down component.
   */
  resetSearchFilterText = (component: MultiselectDropdownComponent) => {
    const isIE11 =
      !!(<any>window).MSInputMethodContext && !!(<any>document).documentMode;
    if (component) {
      if (!isIE11) {
        component.clearSearch(new Event('Reset Filters'));
      } else {
        (<any>component).searchFilterText = '';
      }
    }
  };

  /**
   * Clears all search filters in drop down components.
   */
  resetSearchFilters = () => {
    this.resetSearchFilterText(this.prodTypeDropDown);
    this.resetSearchFilterText(this.clientProdTypeDropDown);
    this.resetSearchFilterText(this.rouseCatTypeDropDown);
    this.resetSearchFilterText(this.clientCatTypeDropDown);
    this.resetSearchFilterText(this.catProductGroupsDropDown);

    this.resetSearchFilterText(this.regionDropDown);
    this.resetSearchFilterText(this.districtDropDown);
    this.resetSearchFilterText(this.branchDropDown);
    this.resetSearchFilterText(this.marketDropDown);

    this.resetSearchFilterText(this.verticalsDropDown);
    this.resetSearchFilterText(this.outliersDropDown);
  };

  // ------------------------ End of Functions related to Drop Downs ------------------------- //

  // ------------------------------ Functions that are not used ------------------------------ //

  /**
   * Returns true if the settings are currently in benchmark mode.
   */
  private isBenchmarkMode = (): boolean => {
    return (
      this.selectedComparison ===
      'main.filters.primary_comparison.compared_focused'
    ); // 'Compared Focused';
  };

  // -------------------------- End of Functions that are not used --------------------------- //
}
