import { Injectable } from '@angular/core';
import { QueryParams } from '../core/http/query-params';
import { Observable } from 'rxjs/Observable';
import { map, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { PagedQuery, CustomerQuery, StandardGridsGroupedQuery } from '../models';
import { FilterInfoService } from '../filter';
import * as filterFunctions from './../filter/functions/filter.functions';

import {
    PageOptions,
    SortOptions,
    RdoHttpService,
    ExportSpreadsheetBody,
    QueryService,
    ViewService,
    ComparisonModeSortOptions,
    MetricsGridConfig,
    RevenueMappingService,
    ActiveFilterService,
    ExcelExportService
} from '../core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SalesRepService {
    private isGetRequest = false;

    constructor(
        private viewService: ViewService,
        private filterInfoService: FilterInfoService,
        private filterService: ActiveFilterService,
        private queryService: QueryService,
        private rdoHttp: RdoHttpService,
        private revenueMappingService: RevenueMappingService,
        private excelExportService: ExcelExportService,
        private translateService: TranslateService
    ) {
        this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
    }

    getSalesReps = (paging: PageOptions = new PageOptions(), sorting: SortOptions = new SortOptions(),
        params: QueryParams = new QueryParams()): Observable<any> => {
        this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
        const groupedQuery = new StandardGridsGroupedQuery();

        groupedQuery.groupByCustomer = params?.get('groupByCustomer')?.toString() === 'true';
        groupedQuery.groupByProductType = params?.get('groupByProductType')?.toString() === 'true';

        return this.queryService.getPagedSorted('metrics/sales-reps', paging, sorting, this.isGetRequest, params, groupedQuery).pipe(map(r => {
            r.Items = _.map(r.Items, (i: any) => {
                // create properties with consistent names for rate types
                i.MonthlyRevenueRate = i.MonthlyRevenue;
                i.MonthlyBenchmarkedRevenueRate = i.MonthlyBenchmarkedRevenue;

                i.WeeklyRevenueRate = i.WeeklyRevenue;
                i.WeeklyBenchmarkedRevenueRate = i.WeeklyBenchmarkedRevenue;

                i.DailyRevenueRate = i.DailyRevenue;
                i.DailyBenchmarkedRevenueRate = i.DailyBenchmarkedRevenue;

                return i;
            });
            return r;
        }));
    }

    getSalesRepsDownload = (sorting: SortOptions = new SortOptions(), gridConfig: MetricsGridConfig): Observable<Record<any, any>[]> => {
        const pagedSorted = new PagedQuery();
        pagedSorted.SetSorting(sorting);
        const body = new ExportSpreadsheetBody(gridConfig.getAllColumns(), this.filterInfoService.getFilterExport());
        const customersGroup = _.find(gridConfig.groups, (group) => group.columnSelectorTitle ===
            this.translateService.instant('main.core.common.counts.customers.count'));
        if (customersGroup && customersGroup.visible) {
            body.groupByCustomer = 'true';
        }
        const productTypeGroup = _.find(gridConfig.groups, (group) => group.columnSelectorTitle ===
            this.translateService.instant('main.core.common.counts.product_types.count'));
        if (productTypeGroup && productTypeGroup.visible) {
            body.groupByProductType = 'true';
        }
        if (this.filterService.getCurrentFilter().useRouseSchema) {
            body.useRouseSchema = 'true';
        }
        return this.excelExportService.generateGridSpreadsheet('metrics/sales-reps-download', body, pagedSorted);
    }

    public getSalesRepCards = (sort: SortOptions): Observable<any> => {
        this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
        if (this.isGetRequest) {
            const params = new QueryParams();
            this.queryService.setSortingParams(params, sort);

            return this
                .rdoHttp
                .getf('metrics/sales-reps/cards', { search: params })
                .pipe(map(res => res as any));
        } else {
            const pagedSorted = new PagedQuery();
            pagedSorted.SetSorting(sort);

            return this
                .rdoHttp
                .post('metrics/sales-reps/cards', pagedSorted)
                .pipe(map(res => res as any));
        }
    }

    public getDashboardCardData = (salesRep: number): Observable<any> => {
        const pagedSorted = new CustomerQuery();

        pagedSorted.SalesRepId = salesRep.toString();
        pagedSorted.Page = 1;
        pagedSorted.PageSize = 1;

        return this
            .rdoHttp
            .post('metrics/sales-reps', pagedSorted)
            .pipe(map(res => res as any));
    }

    public getRevenueByMonthData = (salesRep: number, monthsToReturn: number): Observable<any> => {
        const pagedSorted = new CustomerQuery();
        pagedSorted.SalesRepId = salesRep.toString();
        pagedSorted.MonthsToReturn = monthsToReturn;

        return this.filterService.filterParams.pipe(switchMap(f => {
            const override = (<any>Object).assign({}, f);
            override.MonthIDStart = null;
            override.MonthIDEnd = null;

            return this.rdoHttp
                .post('metrics/product-types/dashboard', pagedSorted, override)
                .pipe(map(res => {
                    return (res as any).Items;
                }));
        }));
    }

    public getTopCustomers = (salesRep: number): Observable<any> => {
        return this.viewService.getComparisonModeSortOptions(ComparisonModeSortOptions.Difference).pipe(switchMap(sorting => {
            const pagedSorted = new CustomerQuery();
            pagedSorted.SalesRepId = salesRep.toString();

            pagedSorted.SetSorting(sorting);

            return this
                .rdoHttp
                .post('metrics/sales-reps/dashboard/customers', pagedSorted)
                .pipe(map(res => res as any));
        }));
    }

    public getTopProductTypes = (salesRep: number): Observable<any> => {
        return this.viewService.getComparisonModeSortOptions(ComparisonModeSortOptions.Difference).pipe(switchMap(sorting => {
            const pagedSorted = new CustomerQuery();
            pagedSorted.SalesRepId = salesRep.toString();

            pagedSorted.SetSorting(sorting);

            return this
                .rdoHttp
                .post('metrics/sales-reps/dashboard/product-types', pagedSorted)
                .pipe(map(res => res as any));
        }));
    }

    public getRateTypes = (salesRep: number): Observable<any> => {
        const pagedSorted = new CustomerQuery();

        pagedSorted.SalesRepId = salesRep.toString();

        return this.rdoHttp.post('metrics/sales-reps/dashboard/rate-types', pagedSorted)
            .pipe(map(p => this.revenueMappingService.processRateTypesResponse((p as any).Items[0])));
    }
}
