import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { CoreModule } from './../core/core.module';
import { TrialGuardService } from '../core';
import {
  ProductTypesComponent,
  ProductTypeDashboardComponent,
} from './product-type';
import { SelectedProductTypeService } from './product-type/selected-product-type.service';
import {
  ProductTypeDashboardService,
  ProductTypeDashboardSalesRepService,
  ProductTypeDashboardCustomerService,
} from './product-type/dashboard';
import {
  ProductTypeSalesRepsComponent,
  ProductTypeSalesRepService,
} from './product-type/sales-reps';
import { ProductTypeTransactionsComponent } from './product-type/transactions';
import {
  ProductTypeCustomersComponent,
  ProductTypeCustomerService,
} from './product-type/customers';
import { ProductTypeRateTypesComponent } from './product-type/rate-types';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {
  // Components
  EquipmentComponent,
  ProductTypeMoMChangeComponent,
  ProductTypeYoYChangeComponent,
  RentalGrowthChartComponent,
  RateTypeRateTrendComponent,
  RentalAssetsComponent,
  // Services
  EquipmentService,
} from './index';
import {
  RentalGrowthCallOutComponent,
  RentalGrowthGridComponent,
} from './product-type/rental-growth';
import { CategoriesWithRentedAsComponent } from './categories-with-rented-as.component';

import { environment } from '../../environments/environment';
import { ProductTypeTitleComponent } from '@/app/equipment/product-type/components/product-type-title/product-type-title.component';

const root = 'equipment';

export const getEquipmentRoutes = (): Routes => {
  // TODO: When the feature is released and we can refactor to get rid of this fork.
  // const categoriesFeatureFlaggedComponent = isRentedAsLive ? CategoriesWithRentedAsComponent : CategoriesComponent;
  const routes: Routes = [
    {
      path: root,
      component: EquipmentComponent,
      canActivate: [TrialGuardService],
      children: [
        {
          path: '',
          component: CategoriesWithRentedAsComponent,
          data: { title: 'main.tabs.equipment.product_types.page_titles.root' },
          canActivate: [TrialGuardService],
        },
        {
          path: 'product-types/:productType',
          children: [
            {
              path: '',
              component: ProductTypesComponent,
              children: [
                {
                  path: '',
                  component: ProductTypeDashboardComponent,
                  data: {
                    name: 'dashboard',
                    title:
                      'main.tabs.equipment.product_types.page_titles.dashboard',
                  },
                },
                {
                  path: 'sales-reps',
                  component: ProductTypeSalesRepsComponent,
                  data: {
                    name: 'sales-reps',
                    title:
                      'main.tabs.equipment.product_types.page_titles.sales_reps',
                  },
                },
                {
                  path: 'customers',
                  component: ProductTypeCustomersComponent,
                  data: {
                    name: 'customers',
                    title:
                      'main.tabs.equipment.product_types.page_titles.customers',
                  },
                },
                {
                  path: 'rate-types',
                  component: ProductTypeRateTypesComponent,
                  data: {
                    name: 'rate-types',
                    title:
                      'main.tabs.equipment.product_types.page_titles.rate_types',
                  },
                },
                {
                  path: 'yoy-change',
                  component: ProductTypeYoYChangeComponent,
                  data: {
                    name: 'yoy-change',
                    title:
                      'main.tabs.equipment.product_types.page_titles.yoy_change',
                  },
                },
                {
                  path: 'mom-change',
                  component: ProductTypeMoMChangeComponent,
                  data: {
                    name: 'mom-change',
                    title:
                      'main.tabs.equipment.product_types.page_titles.mom_change',
                  },
                },
                {
                  path: 'transactions',
                  component: ProductTypeTransactionsComponent,
                  data: {
                    title:
                      'main.tabs.equipment.product_types.page_titles.transactions',
                  },
                },
                {
                  path: 'rental-assets',
                  component: RentalAssetsComponent,
                  data: {
                    name: 'rental-assets',
                    title:
                      'main.tabs.equipment.product_types.page_titles.rental_assets',
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  return routes;
};

// For the rented as feature there are going to be a lot of changes to business logic at the component level.
// Instead of littering the component with if/else logic that will be hard to remove and debug in the future,
// I'm forking the feature at the routing level.

const equipmentRoutes = getEquipmentRoutes();

@NgModule({
  imports: [
    RouterModule.forChild(equipmentRoutes),
    CommonModule,
    CoreModule,
    InfiniteScrollModule,
    TableModule,
    ProductTypeTitleComponent,
  ],
  providers: [
    EquipmentService,
    SelectedProductTypeService,
    ProductTypeDashboardService,
    ProductTypeDashboardSalesRepService,
    ProductTypeDashboardCustomerService,
    ProductTypeSalesRepService,
    ProductTypeCustomerService,
    TrialGuardService,
  ],
  declarations: [
    EquipmentComponent,
    CategoriesWithRentedAsComponent,
    ProductTypesComponent,
    ProductTypeDashboardComponent,
    ProductTypeSalesRepsComponent,
    ProductTypeYoYChangeComponent,
    ProductTypeMoMChangeComponent,
    RentalGrowthCallOutComponent,
    RentalGrowthGridComponent,
    RentalGrowthChartComponent,
    ProductTypeCustomersComponent,
    ProductTypeRateTypesComponent,
    ProductTypeTransactionsComponent,
    RateTypeRateTrendComponent,
    RentalAssetsComponent,
  ],
})
export class EquipmentModule {}
