<!-- Background -->
<div class="gray-background" (click)="onClose()" [style.display]="showManagement ? 'flex' : 'none'"></div>

<!-- Actual Modal -->
<div class="popup" [style.display]="showManagement ? 'flex' : 'none'">
    <div class="popup-main-content">
        <!-- Title -->
        <div class="popup-title-section">
            <div class="popup-title">{{'main.filters.profiles.manage.title' | translate}}</div>
            <i class="fa fa-close popup-close" (click)="onClose()"></i>
        </div>

        <!-- Content -->
        <div class="popup-content">
            <div class="popup-content-table-with-header">
                <div class="popup-row-titles">
                    <div class="profile-mgr-blank">&nbsp;</div>
                    <div class="profile-mgr-default-text">{{'main.filters.profiles.manage.default' | translate}}</div>
                </div>
                <div class="popup-content-table">
                    <div *ngIf="profilesList && profilesList.length">
                        <div *ngFor="let profile of profilesList; let i = index" class="popup-content-row">
                            <div class="profile-name-container">
                                <span class="profile-name" [hidden]="nameIndex === i">{{profile?.profileName}}</span>
                                <input type="text"
                                    [(ngModel)]="profile.profileName"
                                    [hidden]="nameIndex !== i"
                                    (blur)="onNameChanged(i)"
                                    class="profile-name-input"
                                    maxlength="100"/>
                                <i class="fa fa-pencil profile-name-edit" (click)="onNameEditClick(i)"></i>
                            </div>
                            <div class="row-btns">
                                <div class="profile-checkbox-container"><mat-checkbox
                                    class="is-default-checkbox"
                                    [checked]="selected === i"
                                    (change)="selected !== i ? selected = i : selected = -1">
                                </mat-checkbox></div>
                                <div class="profile-trash-bin-container">
                                    <i class="fa fa-trash popup-trash" style="color: #5a5a5a; cursor: pointer;" (click)="onDelete(i)"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Client profile row -->
                    <div class="popup-content-row">
                        <div class="profile-name-container">
                            <span class="profile-name">{{translatedClientProfileName}}</span>
                        </div>
                        <div class="row-btns">
                            <div class="profile-checkbox-container"><mat-checkbox
                                class="is-default-checkbox"
                                [checked]="selected === -1"
                                (change)="selected = -1">
                            </mat-checkbox></div>
                            <div class="profile-trash-bin-container">
                                <i class="fa fa-trash popup-trash" style="color: #5a5a5a; opacity: .0;"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Buttons -->
    <div class="popup-btns">
        <div class="popup-btn save-btn" (click)="onSave()" *ngIf="showSaveBtn()"
            >{{'main.filters.profiles.manage.save' | translate}}</div>
        <div class="popup-btn-disabled save-btn" style="opacity: .5;" *ngIf="showDisabledSaveBtn()"
            >{{'main.filters.profiles.manage.save' | translate}}</div>
        <div class="popup-btn" (click)="onClose()">
            {{((profilesList && profilesList.length) || (idsToDelete && idsToDelete.length)) ? ('main.filters.profiles.manage.cancel' | translate) : ('main.filters.profiles.manage.close' | translate)}}
        </div>
    </div>
</div>
