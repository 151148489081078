﻿<rdo-breadcrumb></rdo-breadcrumb>
<rdo-overlay [enabled]="loading"></rdo-overlay>
<div class="container-fluid" id="sales-rep">
	<div class="row">
		<div class="col-xs-12" id="rdo-grid">
			<rdo-grid-table-ng *ngIf="salesReps" #grid [name]="gridName" [gridConfig]="gridConfig"
				[pagedData]="salesReps" [sorting]="sorting" [(paging)]="paging" (pageOnChange)="changePage($event)"
				[scrollScale]="'largeScale'" (lazyLoadOnChange)="changeLazyLoad($event)" [totalCount]="totalCount"
				[frozenWidth]="frozenWidth" [showColumnSelector]="true" (visibilityChanged)="visibilityChanged($event)"
				(downloadExcelClick)="exportExcel($event)">
			</rdo-grid-table-ng>
		</div>
	</div>
</div>