import { Injectable, EventEmitter } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { of } from 'rxjs/observable/of';

import { RentalAssetCard } from '../models';

@Injectable()
export class SelectedAssetService {
	public assetChange: EventEmitter<RentalAssetCard> = new EventEmitter<RentalAssetCard>();
	private selectedAsset: RentalAssetCard;
	private subject: Subject<RentalAssetCard> = new Subject<RentalAssetCard>();

	public get Asset(): Observable<RentalAssetCard> {
		if (this.selectedAsset) {
			return of(this.selectedAsset);
		}
		return this.subject;
	}

	notify(asset: RentalAssetCard) {
		if (!asset) {
			this.selectedAsset = null;
			return;
		}

		if (this.selectedAsset && this.selectedAsset.RouseEquipmentID === asset.RouseEquipmentID) {
			return;
		}

		if (!asset) {
			return;
		}

		this.selectedAsset = asset;
		if (this.subject) {
			this.subject.next(asset);
		}

		this.assetChange.next(asset);
	}

	clear() {
		this.selectedAsset = null;
	}
}
