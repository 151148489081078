import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, zip } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { PlatformProfilesMap } from './models/platform-profiles-map';
import { PlatformFilterProfile } from './models/platform-filter-profile';
import { HTTP_WORD, MetricsApiService } from '../../core/services/metrics-api.service';

/**
 * Handles API calls used for filter profiles.
 */
@Injectable({
  providedIn: 'root'
})
export class FilterApiProfileService {
  private baseUrl = '';
  private apiService: MetricsApiService;
  private readonly PLATFORM_BASE_PATH = '/user-config/module/v2/filters';

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {
    // Using api service rather than inheriting from it because for the profile service it throws an error.
    this.apiService = new MetricsApiService(this.http, this.authenticationService);
    this.baseUrl = environment.metricsApiUrl;
    this.apiService.getHttpHeaders().subscribe();
  }

  public getProfilesList(): Observable<PlatformProfilesMap> {
    return this.apiService.performHttpRequest<PlatformProfilesMap>(HTTP_WORD.GET, this.baseUrl + this.PLATFORM_BASE_PATH
      //,PlatformProfilesMap.map2Type, PlatformProfilesMap.isValid, PlatformProfilesMap.getDefault
    );
  }

  public saveNewProfile(profile: PlatformFilterProfile): Observable<PlatformFilterProfile> {
    return this.apiService.performHttpRequest<PlatformFilterProfile>(
        HTTP_WORD.POST,
        this.baseUrl + this.PLATFORM_BASE_PATH + '/user-defined',
        profile,
      (results: any) => this.apiService.mapToType<PlatformFilterProfile>(results, PlatformFilterProfile)
    );
  }

  public updateProfile(profile: PlatformFilterProfile): Observable<PlatformFilterProfile> {
    return this.apiService.performHttpRequest<PlatformFilterProfile>(HTTP_WORD.PUT, this.baseUrl + this.PLATFORM_BASE_PATH + '/user-defined/' + profile.profile_id, profile);
  }

  public deleteProfile(profileId: number): Observable<boolean> {
    return this.apiService.performHttpRequest<boolean>(HTTP_WORD.DELETE, this.baseUrl + this.PLATFORM_BASE_PATH + '/user-defined/' + profileId);
  }

  /**
   * Runs several delete profile requests and zips the observable
   * responses into one big observable.
   */
  public deleteManyProfiles(profileIds: Array<number>): Observable<boolean> {
    return new Observable<boolean>(obs => {
      if (profileIds && profileIds.length) {
        try {
          const observables = [];
          profileIds.forEach(profileId => {
            observables.push(this.deleteProfile(profileId));
          });
          zip(...observables).subscribe(results => {
            const strRes = results ? JSON.stringify(results) : '';
            const success = !strRes.includes('error') && !strRes.includes('not authorized');
            obs.next(success);
            obs.complete();
          }, () => { obs.next(false); });
        } catch (err) {
          obs.next(false);
        }
      } else {
        obs.next(null);
        obs.complete();
      }
    });
  }
}
