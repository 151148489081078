<div class="change-log-entry" [class.compact]="!productTypeVisible">
	<div class="modal-header" *ngIf="productTypeVisible">
		<h4 class="pull-left">{{currentRate ? currentRate.Description : ''}}</h4>
		<h4 class="pull-right">{{filterService.clientMonth.Month | rdoLocalizedDate:'MMM yyyy'}}</h4>
	</div>

	<div class="row" style="margin-top: 10px;">
		<div class="col-md-7 no-padding" *ngIf="productTypeVisible">
			<div class="row">
				<div class="col-md-12">
					<div class="col-md-3 col-md-offset-3">
						<label>{{'main.tabs.changes_log.entry.monthly' | translate}}</label>
					</div>

					<div class="col-md-3">
						<label>{{'main.tabs.changes_log.entry.weekly' | translate}} </label>
					</div>

					<div class="col-md-3">
						<label>{{'main.tabs.changes_log.entry.daily' | translate}}</label>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
					<div class="col-md-3">
						<label>{{'main.tabs.changes_log.entry.book' | translate}}</label>
					</div>

					<div class="col-md-3">
						<div class="form-group">
							<label class="sr-only"></label>
							<input type="text" class="form-control" *ngIf="currentRate"
								[ngModel]="currentRate.MonthlyBookRevenueRate | rdoCurrency:true" disabled="disabled" />
						</div>
					</div>

					<div class="col-md-3">
						<div class="form-group">
							<label class="sr-only"></label>
							<input type="text" class="form-control" *ngIf="currentRate"
								[ngModel]="currentRate.WeeklyBookRevenueRate | rdoCurrency:true" disabled="disabled" />
						</div>
					</div>

					<div class="col-md-3">
						<div class="form-group">
							<label class="sr-only"></label>
							<input type="text" class="form-control" *ngIf="currentRate"
								[ngModel]="currentRate.DailyBookRevenueRate | rdoCurrency:true" disabled="disabled" />
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
					<div class="col-md-3">
						<label>{{'main.tabs.changes_log.entry.new_book' | translate}}</label>
					</div>

					<div class="col-md-3">
						<div class="form-group">
							<label class="sr-only"></label>
							<input type="text" class="form-control" *ngIf="currentRate" currencyMask maxlength="9"
								[options]="currencyMaskOptions" [(ngModel)]="changeLog.NewMonthlyBookRevenueRate"
								placeholder="{{currentRate.MonthlyBookRevenueRate | rdoCurrency:true}}" />
						</div>
					</div>

					<div class="col-md-3">
						<div class="form-group">
							<label class="sr-only"></label>
							<input type="text" class="form-control" currencyMask maxlength="9" *ngIf="currentRate"
								[options]="currencyMaskOptions" [(ngModel)]="changeLog.NewWeeklyBookRevenueRate"
								placeholder="{{currentRate.WeeklyBookRevenueRate | rdoCurrency:true}}" />
						</div>
					</div>

					<div class="col-md-3">
						<div class="form-group">
							<label class="sr-only"></label>
							<input type="text" class="form-control" currencyMask maxlength="9" *ngIf="currentRate"
								[options]="currencyMaskOptions" [(ngModel)]="changeLog.NewDailyBookRevenueRate"
								placeholder="{{currentRate.DailyBookRevenueRate | rdoCurrency:true}}" />
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
					<div class="col-md-3">
						<label>{{'main.tabs.changes_log.entry.floor' | translate}}</label>
					</div>

					<div class="col-md-3">
						<div class="form-group">
							<label class="sr-only"></label>
							<input type="text" class="form-control" *ngIf="currentRate"
								[ngModel]="currentRate.MonthlyFloorRevenueRate | rdoCurrency:true"
								disabled="disabled" />
						</div>
					</div>

					<div class="col-md-3">
						<div class="form-group">
							<label class="sr-only"></label>
							<input type="text" class="form-control" *ngIf="currentRate"
								[ngModel]="currentRate.WeeklyFloorRevenueRate | rdoCurrency:true" disabled="disabled" />
						</div>
					</div>

					<div class="col-md-3">
						<div class="form-group">
							<label class="sr-only"></label>
							<input type="text" class="form-control" *ngIf="currentRate"
								[ngModel]="currentRate.DailyFloorRevenueRate | rdoCurrency:true" disabled="disabled" />
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
					<div class="col-md-3">
						<label>{{'main.tabs.changes_log.entry.new_floor' | translate}}</label>
					</div>

					<div class="col-md-3">
						<div class="form-group">
							<label class="sr-only"></label>
							<input type="text" class="form-control" currencyMask maxlength="9" *ngIf="currentRate"
								[options]="currencyMaskOptions" [(ngModel)]="changeLog.NewMonthlyFloorRevenueRate"
								placeholder="{{currentRate.MonthlyFloorRevenueRate | rdoCurrency:true}}" />
						</div>
					</div>

					<div class="col-md-3">
						<div class="form-group">
							<label class="sr-only"></label>
							<input type="text" class="form-control" currencyMask maxlength="9" *ngIf="currentRate"
								[options]="currencyMaskOptions" [(ngModel)]="changeLog.NewWeeklyFloorRevenueRate"
								placeholder="{{currentRate.WeeklyFloorRevenueRate | rdoCurrency:true}}" />
						</div>
					</div>

					<div class="col-md-3">
						<div class="form-group">
							<label class="sr-only"></label>
							<input type="text" class="form-control" currencyMask maxlength="9" *ngIf="currentRate"
								[options]="currencyMaskOptions" [(ngModel)]="changeLog.NewDailyFloorRevenueRate"
								placeholder="{{currentRate.DailyFloorRevenueRate | rdoCurrency:true}}" />
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="no-padding" [class.col-md-12]="!productTypeVisible" [class.col-md-5]="productTypeVisible">
			<div class="col-md-12">
				<div class="form-group">
					<label>{{'main.navigation.changes_log_entry.comments' | translate}}</label>
					<textarea id="changeLogComment" class="form-control" [(ngModel)]="changeLog.Comment"></textarea>
				</div>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-md-12">
			<div class="modal-footer">
				<button class="btn btn-primary" *ngIf="productTypeVisible" (click)="save()"><i
						class="fa fa-save"></i>{{'main.tabs.changes_log.entry.save_changes' | translate}}</button>
				<button class="btn btn-primary" *ngIf="!productTypeVisible" (click)="save()"
					[disabled]="isSaveDisabled()">
					<i class="fa fa-save"></i>{{'main.navigation.changes_log_entry.save_comments' | translate}}</button>
				<button class="btn btn-default" (click)="closeClick()">{{'main.navigation.changes_log_entry.close' |
					translate}}</button>
			</div>
		</div>
	</div>
</div>
