import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { ProductQuery } from './../../../models';
import {
  PageOptions,
  SortOptions,
  RdoHttpService,
  ExportSpreadsheetBody,
  QueryService,
  MetricsGridConfig,
  ViewService,
  ExcelExportService,
  FormatService,
} from './../../../core';
import { FilterInfoService } from '../../../filter/services/filter-info.service';
import * as filterFunctions from './../../../filter/functions/filter.functions';

@Injectable()
export class ProductTypeCustomerService {
  private isGetRequest = false;

  constructor(
    private rdoHttp: RdoHttpService,
    private filterInfoService: FilterInfoService,
    private queryService: QueryService,
    private formatService: FormatService,
    private excelExportService: ExcelExportService
  ) {
    this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
  }

  public getProductTypeCustomers = (
    options: {
      productType: string;
      category?: string;
      useRentedAsProductType?: boolean;
    },
    paging: PageOptions = new PageOptions(),
    sorting: SortOptions = new SortOptions()
  ): Observable<any> => {
    const pagedSorted = new ProductQuery();
    pagedSorted.ClientProductType = options.productType;
    if (options.category) {
      pagedSorted.RouseCategoryList = [
        this.filterInfoService.getRouseCategoryId(options.category),
      ];
    }

    if (options.useRentedAsProductType) {
      pagedSorted.UseRentedAsProductType = options.useRentedAsProductType;
    }

    const endpointUrl = `metrics/product-types/customers`;

    return this.queryService.getPagedSorted(
      endpointUrl,
      paging,
      sorting,
      false,
      null,
      pagedSorted
    );
  };

  public getProductTypeCustomersDownload = (
    equipment: string,
    gridConfig: MetricsGridConfig,
    productType: string,
    useRentedAsProductType: boolean,
    category?,
    sorting: SortOptions = new SortOptions()
  ): Observable<object[]> => {
    const pagedSorted = new ProductQuery();
    pagedSorted.ClientProductType = productType;
    if (category) {
      pagedSorted.RouseCategoryList = [
        this.filterInfoService.getRouseCategoryId(category),
      ];
    }
    pagedSorted.UseRentedAsProductType = useRentedAsProductType || false;

    pagedSorted.SetSorting(sorting);
    const filters = this.filterInfoService.getFilterExport();
    filters['Product Type'] = equipment;
    if (category) {
      filters.Category = category;
    }
    const body = new ExportSpreadsheetBody(gridConfig.getAllColumns(), filters);
    return this.excelExportService.generateGridSpreadsheet(
      `metrics/product-types/customers-download/${this.formatService.getDateLocale()}`,
      body,
      pagedSorted
    );
  };

  public getProductTypeCustomersSalesReps = (
    productType: string,
    customer: number,
    options: {
      category?: string;
      useRentedAsProductType?: boolean;
    },
    sorting: SortOptions = new SortOptions()
  ) => {
    const pagedSorted = new ProductQuery();
    pagedSorted.Page = 1;
    pagedSorted.PageSize = 500;
    pagedSorted.ClientProductType = productType;
    pagedSorted.CustomerId = customer;
    if (options.category) {
      pagedSorted.RouseCategoryList = [
        this.filterInfoService.getRouseCategoryId(options.category),
      ];
    }

    if (options.useRentedAsProductType) {
      pagedSorted.UseRentedAsProductType = options.useRentedAsProductType;
    }

    pagedSorted.SetSorting(sorting);
    return this.rdoHttp
      .post('metrics/product-types/customers/sales-reps', pagedSorted)
      .pipe(
        map((r: any) => {
          return r.Items;
        })
      );
  };
}
