﻿<div class="rdo-inline" *ngIf="columnList">
    <table class="table table-nowrap rdo-table-pretty" [ngClass]="{'rdo-expander-gray': hasOneColumnAndNotLockedOrApplyColor(group)}" [ngStyle]="{width: fullWidth.toString() + 'px'}">
        <colgroup>
            <col *ngFor="let c of columns" [ngStyle]="{'width': (c.width ? c.width : 110) + 'px'}">
        </colgroup>
        <thead>
            <tr>
                <th [attr.colspan]="columnList.length" [ngClass]="{'column-selector':showColumnSelector}">
                    <div style="position: fixed; right: 17px; top: 142px; z-index: 5000;">
                    <rdo-column-selector *ngIf="showColumnSelector" [gridConfig]="gridConfig"
                    	(visibilityChanged)="visibilityChanged.next(gridConfig)"
                    	(downloadExcelClick)="downloadExcelClick.next(gridConfig)" 
                    	(deleteClick)=deleteClick.next()> </rdo-column-selector>
                    </div>
                    {{tableTitle | translate}}
                    <a *ngIf="!group.locked" (click)="toggleColumnVisibility(group)"
                    title="{{'main.core.tables.common.hide_column_group' | translate}}">
                        <img class="close" src="/assets/images/close.png">
                    </a>
                </th> 
            </tr>
        </thead>
        <tbody>
            <tr [ngStyle]="headerStyle">
                <th *ngFor="let c of columns" [ngStyle]="c.headerStyle">
                    <rdo-sort-header 
                        [enableOrdering]="filterSortableColumns(c)"
                        [column]="c" 
                        [currentSortColumn]="sortOn !== 'Description' ? sortOn : undefined" 
                        [desc]="desc" 
                        (descChange)="setSortDirection(!desc)" 
                        (columnClick)="setSortColumn(c)" 
                        [sortInProgress]="sortInProgress">
                    </rdo-sort-header>
                </th>
            </tr>
            <ng-template ngFor let-r [ngForOf]="gridData" let-rowIndex="index">
                <tr [ngClass]="{'clickable-row' : isExpandable(), 'table-expanded-row-first': r.data.expanded }" (click)="toggleChildren(r, rowIndex)">
                    <td *ngFor="let c of r.cells; let i = index" [ngStyle]="c.style" [ngClass]="c.class" title="{{translatePossibleDate(c)}}">
                        <ng-template [ngIf]="r.data.chidlLoading && showExpansionIndicator && i === 0">
                            <img src="/assets/images/loader-sm.gif" style="width:16px;height:16px;"/>
                        </ng-template>
                        <i class="fa fa-fw" *ngIf="isExpandable() && showExpansionIndicator && i === 0 && !r.data.chidlLoading"
                        [ngClass]="{ 'fa-angle-right' : !r.data.expanded, 'fa-angle-down' : r.data.expanded }" ></i>
	                    <i *ngIf="!c.href && !c.linkDsl && isBool(c.value) && c.value" class="fa fa-check"></i>
                        <a *ngIf="c.linkDsl" (click)="clickCellLink(c.config, r.data.raw)"  [routerLink]="c.linkDsl"> {{ c.value | translate }}</a>
                        <a *ngIf="c.href && !c.linkDsl" (click)="clickCellLink(c.config, r.data.raw)" href="{{c.href}}">{{ c.value | translate }}</a>
                        <span *ngIf="!c.href && !c.linkDsl && !isBool(c.value)">{{ translatePossibleDate(c) }}</span>
                   </td>
            </tr>
            <tr *ngIf="isExpandable()">
                <td [attr.colspan]="columnList.length" style="border: none; padding: 0;">
                    <div class="rdo-expander-row" [style.height]="childrenHeightStyle(r)">
                        <table class="table table-nowrap rdo-table-pretty" style="margin-bottom: 0;">
                            <colgroup>
                                <col *ngFor="let c of columns" [ngStyle]="{'width': (c.width ? c.width : 110) + 'px'}">
                            </colgroup>
                            <tbody>
                                <tr class="table-expanded-row" *ngFor="let child of r.childRows">
                                    <td *ngFor="let c of child.cells" [ngStyle]="c.style" [ngClass]="c.class" title="{{translatePossibleDate(c)}}">
                                    <a *ngIf="c.linkDsl" (click)="clickCellLink(c.config, child.data.raw, r.data.raw)"  [routerLink]="c.linkDsl"> {{ c.value }}</a>
	                                    <a *ngIf="c.href && !c.linkDsl" (click)="clickCellLink(c.config, child.data.raw, r.data.raw)" href="{{c.href}}"> {{ c.value }}</a>
	                                    <i *ngIf="!c.href && !c.linkDsl && isBool(c.value) && c.value" class="fa fa-check"></i>
                                    <span *ngIf="!c.href && !c.linkDsl && !isBool(c.value)">{{ c.value }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </td>
            </tr>
            </ng-template>
        </tbody>
    </table>
</div>
