﻿<div class="panel panel-default rdo-card rdo-card-default" [ngClass]="{'rdo-card-bench' : isBenchmarkMode(), 'rdo-card-book': !isBenchmarkMode()}">
    <div [class.hidden]="!loading" class="ovrl">
    </div>
    <div class="panel-heading">
        <div class="panel-title">
            <ng-template ngFor let-cgf [ngForOf]="_configs" let-i="index">
                <a class="link" (click)="switchTo(i)" id="{{cgf.title | rdoPathToId}}" 
                [ngClass]="{selected:i===selectedIndex}">{{cgf.title | translate}}</a> &nbsp;&nbsp;&nbsp;
            </ng-template>
        </div>
    </div>
    <table class="table table-nowrap">
        <tbody>
            <tr>
                <th></th>
                <ng-template [ngIf]="isBenchmarkMode()">
                    <th id="{{'main.tabs.summary.tables.client_compared' | rdoPathToId}}" class="rdo-table-title rdo-narrow-title"><div
                    >{{'main.tabs.summary.tables.client_compared_revenue' | translate}}</div></th>
                    <th id="{{'main.tabs.summary.tables.client_rate_vs.1' | rdoPathToId}}" class="rdo-table-title rdo-wide-title"><div
                    >{{'main.tabs.summary.tables.client_rate_vs_benchmark' | translate}}</div></th>
                </ng-template>
                <ng-template [ngIf]="!isBenchmarkMode()">
                    <th id="{{'main.tabs.summary.tables.client_total' | rdoPathToId}}" class="rdo-table-title rdo-narrow-title"><div
                    >{{'main.tabs.summary.tables.client_total_revenue' | translate}}</div></th>
                    <th id="{{'main.tabs.summary.tables.client_rate_vs.2' | rdoPathToId}}" class="rdo-table-title rdo-wide-title"><div
                    >{{'main.tabs.summary.tables.client_rate_vs_book' | translate}}</div></th>
                </ng-template>
            </tr>
            <tr *ngFor="let i of items">
                <td>
                    <span [title]="i.Name">
                        <a *ngIf="i.LinkDsl" [routerLink]="i.LinkDsl">
                            {{i.Name}}
                        </a>
                        <span *ngIf="!i.LinkDsl">
                             {{i.Name}}
                        </span>
                    </span>
                </td>
                <ng-template [ngIf]="isBenchmarkMode()">
                    <td [ngClass]="{'text-muted':i.ClientBenchmarkedRevenue === 0}">
                        {{i.ClientBenchmarkedRevenue | rdoCurrency:true}}
                    </td>
                    <td>
                        <rdo-amount-difference [clientValue]="i.ClientBenchmarkedRevenue" [benchmarkedValue]="i.BenchmarkedRevenue"></rdo-amount-difference>
                    </td>
                </ng-template>
                <ng-template [ngIf]="!isBenchmarkMode()">
                    <td [ngClass]="{'text-muted':i.RevenueTotal === 0}">
                        {{i.RevenueTotal | rdoCurrency:true}}
                    </td>
                    <td>
                        <rdo-amount-difference [clientValue]="i.RevenueTotal" [benchmarkedValue]="i.RevenueBook"></rdo-amount-difference>
                    </td>
                </ng-template>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td colspan="3">
                    <ng-template [ngIf]="selectedConfig?.footerLinkDsl" >
                        <a [routerLink]="selectedConfig.footerLinkDsl"
                        id="{{(footerLinkTitle || 'main.tabs.summary.tables.all') | rdoPathToId}}"
                        >{{getFooterLinkTitle(selectedConfig.footerLinkTitle)}}<i class="fa fa-fw fa-angle-double-right"></i></a>
                    </ng-template>
                </td>
            </tr>
        </tfoot>
    </table>
</div>