import { Pipe, PipeTransform } from '@angular/core';
import { FormatService } from '../../core/query';
import { LocaleService } from '../services/locale.service';

export interface IEllipseable {
	title: string;
	processedTitle: string;
	hasTwoLines: boolean;
}


/*
 * Puts elipsis into the given text if it is bigger than the
 * element that contains it.
 * Usage:
 *   <div>{{'' | rdoEllipsis:width}}</div>
 **/
@Pipe({
    name: 'rdoEllipsis',
	pure:false
})
export class RdoEllipsisPipe implements PipeTransform {
	private font = '700 10px Roboto, "Trebuchet MS", Arial, Helvetica, sans-serif';
	private static charMap = {};
	private charSet = [
		'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k',
		'l', 'm', 'n', 'ñ', 'o', 'p', 'q', 'r', 's', 't', 'u',
		'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F',
		'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'Ñ', 'O', 'P',
		'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '0',
		'1', '2', '3', '4', '5', '6', '7', '8', '9', ' ', '$',
		',', '...', ', ' + this.formatService.selectedCurrencySymbol
	];
	private static splitterMap = {
		'ja-JP': '', 
		'jp-JP': '',
		default: ' '
	};
	private static errorMarginMap = {
		'ja-JP': 1, 
		'jp-JP': 1,
		default: 2.5
	};

	constructor(
		private formatService: FormatService,
		private localeService: LocaleService
	) {
		this.initCharMap();
	}

	/**
	 * The char map is used to store the length of each char under the
	 * defined font format.
	 */
	private initCharMap() {
		try {
			// TODO: Fix typing issue here.
			// eslint-disable-next-line
			// @ts-ignore
			if (RdoEllipsisPipe.charMap.a === undefined) {
				let size = 0;
				let total = 0;
				for (const key of this.charSet) {
					const width = this.measureCharWidth(key);
					RdoEllipsisPipe.charMap[key] = width;
					total += width;
					size++;
				}
				// TODO: Fix typing issue here.
				// eslint-disable-next-line
				// @ts-ignore
				RdoEllipsisPipe.charMap.default = this.round2(total / size);
			}
		} catch(err) {
			console.error(err);
		}
	}

	/**
	 * Round the given number with two decimals.
	 */
	private round2(num: number) {
		return Math.round(num * 100) / 100;
	}

    transform = (ellipseable: IEllipseable, availableWidth: number, font: string = this.font, lines: number = 2): IEllipseable => {
		let text = '';
		if(ellipseable && ellipseable.title) {
			text = this.formatService.translateAndFormat(ellipseable.title, true);
			if (availableWidth) {
				if (font !== this.font) {
					this.font = font;
					this.initCharMap();
				}
				text = this.handleTextWidth(text, availableWidth, lines, ellipseable);
			}
		}
		ellipseable.processedTitle = text;
		return ellipseable;
	}

	/**
	 * Measures the text, word by word, line by line and truncates the last
	 * line if needed.
	 */
	private handleTextWidth(text: string, availableWidth: number, lines: number, ellipseable: IEllipseable): string {
		const wordSplitter = this.getWordSplitter();
		const hasCurrency = this.hasCurrency(text);
		const words = this.splitWords(text);
		let currentLineWidth = 0;
		const errorMargin = this.getErrorMargin();
		let shortenText = '';
		let lineIndex = 0;
		let wordIndex = 0;
		// Add words while there's room on each line
		while (wordIndex < words.length && lineIndex < lines) {
			const currentWordWidth = this.stringWidth(words[wordIndex]);
			if (currentLineWidth + currentWordWidth <= availableWidth + errorMargin) {
				currentLineWidth += currentWordWidth + this.charWidth(wordSplitter);
				shortenText += words[wordIndex] + wordSplitter;
				wordIndex++;
			} else {
				if (lineIndex !== lines - 1) { // Go to next line
					currentLineWidth = 0;
				} else { // Add final word with ellipsis
					const remainingWidth = availableWidth - currentLineWidth;
					shortenText = this.handleEllipsedWord(shortenText, words[wordIndex], remainingWidth, hasCurrency);
				}
				lineIndex++;
			}
		}
		ellipseable.hasTwoLines = lineIndex > 0;
		return shortenText.trim();
	}

	private getErrorMargin() {
		const em = RdoEllipsisPipe.errorMarginMap[this.localeService.getLocale()];
		return em ? em : RdoEllipsisPipe.errorMarginMap.default;
	}

	/**
	 * Splits words within the given text using the wordSplitter defined
	 * for the current language.
	 */
	private splitWords(text: string) {
		return text.split(this.getWordSplitter());
	}

	/**
	 * Returns the character used to split words within a sentence in the
	 * current language.
	 */
	private getWordSplitter() {
		const splitter = RdoEllipsisPipe.splitterMap[this.localeService.getLocale()];
		return splitter !== undefined ? splitter : RdoEllipsisPipe.splitterMap.default
	}

	/**
	 * Adds one final word with ellipsis. If the final word is shorter than the tail
	 * to be added, the previous word is shortened in the resulting text.
	 */
	private handleEllipsedWord(shortenText: string, finalWord: string, remainingWidth: number, hasCurrency: boolean): string {
		let tailWidth = this.charWidth('...');
		let ellipsed = '';
		let tail = '...';
		if (hasCurrency) {
			const currencyWidth = this.charWidth(', ' + this.formatService.selectedCurrencySymbol);
			tailWidth += currencyWidth ? currencyWidth : 
				this.charWidth(',') + this.charWidth(this.getWordSplitter()) + this.charWidth('default');
			tail += ', ' + this.formatService.selectedCurrencySymbol;
		}
		remainingWidth -= tailWidth;

		if(0 < remainingWidth) { // There's room for adding an incomplete final word
			let charIndex = 0;
			let currentCharWidth = this.charWidth(finalWord[charIndex]);
			while (charIndex < finalWord.length && currentCharWidth < remainingWidth) {
				ellipsed += finalWord[charIndex];
				remainingWidth -= currentCharWidth;
				charIndex++;
				currentCharWidth = this.charWidth(finalWord[charIndex]);
			}
		} else if (remainingWidth < 0) { // No room for final word, Remove offset from previous
			remainingWidth = Math.abs(remainingWidth);
			let regainedWidth = 0;
			while (regainedWidth <= remainingWidth) {
				const charToRemove = shortenText.charAt(shortenText.length - 1);
				const withToRegain = this.charWidth(charToRemove);
				shortenText = shortenText.substring(0, shortenText.length - 1);
				regainedWidth += withToRegain;
			}
		}
		shortenText = ellipsed !== '' ? shortenText + ellipsed + tail : shortenText.trim() + tail;
		return shortenText;
	}

	/**
	 * Returns the proper width stored for the given char or the default value.
	 */
	private charWidth(char: string): number {
		let width = RdoEllipsisPipe.charMap[char];
		try {
			if (width === undefined) { // measure new unknown characters
				const measuredWidth = this.measureCharWidth(char);
				RdoEllipsisPipe.charMap[char] = measuredWidth;
				width = measuredWidth;
			}
		} catch(err) {
			// TODO: Fix typing issue here.
			// eslint-disable-next-line
			// @ts-ignore
			width = RdoEllipsisPipe.charMap.default;
		}
		return width;
	}

	/**
	 * Returns the length of the given string using the charMap.
	 */
	public stringWidth(text: string, font: string = this.font): number {
		let width = 0;
		if (text) {
			// eslint-disable-next-line @typescript-eslint/prefer-for-of
			for(let i = 0; i < text.length; i++) {
				width += this.charWidth(text[i]);
			}
		}
		return width;
	}

	/**
	 * Returns true if the given text contains currency.
	 */
	private hasCurrency(text: string): boolean {
		return (text.charAt(text.length - 3) === ',' || text.charAt(text.length - 2) === ',');
	}

	/**
	 * Measures the given character's width in pixels minimizing errors.
	 */
	private measureCharWidth(char: string): number {
        let repeatedChar = '';
        const times = 50;
        for(let i = 0; i < times; i++) {
            repeatedChar += char;
        }
        const width = this.formatService.getTextWidth(repeatedChar, this.font, 0, false);
        return this.round2(width/times);
    }
}

