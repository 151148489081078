import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  HostBinding,
  HostListener,
  OnInit,
} from '@angular/core';

import { MetricsGridConfig } from './MetricsGridConfig';
import { IGridColumnGroup } from './IGridColumnGroup.interface';
import { AuthenticationService } from '../authentication/authentication.service';
import { FormatService } from '../query';
import { LocaleService } from '../services';
import { DateTime } from 'luxon';

@Component({
    selector: 'rdo-column-selector',
    template: `
    
    <div #dropdownbox class="dropdown" style="overflow:visible" (blur)="onBlur($event.target)">
    <span>{{getLastUpdatedLabel()}}</span>
    <span style="font-weight:normal;margin-right:5px;">{{getLastUpdatedDateTime()}}</span>
    <a #dLabelz id="dLabel" class="btn btn-primary btn-inverse"
        data-target="#" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
        <i class="fa fa-fw fa-columns" [ngClass]="{'fa-lg': size === 'md'}">
        </i>&nbsp;{{handleTranslation('main.core.tables.common.select_columns')}}
    </a>
    <ul class="dropdown-menu" [ngClass]="{ 'pull-right' : !gridConfig.enableDelete}" aria-labelledby="dLabel">
        <ng-template ngFor let-g [ngForOf]="gridConfig?.groups">
            <li *ngIf="!g.locked || g.forceCanClose">
                <a (click)="toggleVisibility($event,g)" id="{{(g.title || g.columnSelectorTitle) | rdoPathToId:'list-selector'}}">
                    <i class="fa fa-fw" [ngClass]="{'fa-check':g.visible}"></i>
                    {{handleTranslation(g.columnSelectorTitle ||  g.title)}}
                </a>
            </li>
        </ng-template>
    </ul>
    <button id="download-excel-button" *ngIf="authenticationService._userInfoView.HasClientAccessToExportData"
    (click)="clickExcelDownload($event,g)" class="btn btn-success btn-inverse">
        <i class="fa fa-fw fa-file-excel-o" [ngClass]="{'fa-lg': size === 'md'}"></i>&nbsp;
        {{'main.core.tables.common.excel' | translate}}</button>
    <button [disabled]="gridConfig.disableDeleteButton" *ngIf="gridConfig.enableDelete" 
    (click)="clickDelete($event,g)" class="btn btn-danger btn-inverse">
        <i class="fa fa-fw fa-trash-o"></i>&nbsp;
        {{'main.core.tables.common.delete' | translate}}</button>
</div>    
    `
})
export class ColumnSelectorComponent {
  @Input() gridConfig: MetricsGridConfig;
  @Input() size = 'xs';
  @Output() visibilityChanged: EventEmitter<IGridColumnGroup> =
    new EventEmitter<IGridColumnGroup>();
  // eslint-disable-next-line @angular-eslint/no-output-rename
  @Output('downloadExcelClick') excelClick = new EventEmitter();
  @Output() deleteClick: EventEmitter<MetricsGridConfig> =
    new EventEmitter<MetricsGridConfig>();

  @ViewChild('dLabelz') dLabelz: ElementRef;
  @ViewChild('dropdownbox') dropdownbox: ElementRef;

  @HostBinding('attr.tabindex') tabindex = '0';
  @HostListener('blur', ['$event.target']) onBlur(target) {
    // this.handleIE();
  }

  constructor(
    public authenticationService: AuthenticationService,
    private formatService: FormatService,
    private localeService: LocaleService
  ) {}

  private handleIE() {
    if (this.IsInternetExplorerOrEdge()) {
      this.asyncShowDropdown(true, () => {
        this.asyncShowDropdown(false, () => {});
      });
    }
  }

  private IsInternetExplorerOrEdge() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    const edg = ua.indexOf('Edge');

    return (
      msie > 0 || edg > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./) // eslint-disable-line no-useless-escape
    );
  }

  private asyncShowDropdown(show: boolean, next: () => unknown) {
    setTimeout(() => {
      if (show) {
        if (!this.dropdownbox.nativeElement.classList.contains('open')) {
          this.dLabelz.nativeElement.click();
          next();
        } else {
          this.asyncShowDropdown(show, next);
        }
      } else {
        if (this.dropdownbox.nativeElement.classList.contains('open')) {
          this.dLabelz.nativeElement.click();
          next();
        } else {
          this.asyncShowDropdown(show, next);
        }
      }
    }, 1);
  }

  toggleVisibility(e: any, group: IGridColumnGroup) {
    if (!this.IsInternetExplorerOrEdge()) {
      e.stopPropagation();
    }
    group.visible = !group.visible;
    this.visibilityChanged.next(group);
    if (this.IsInternetExplorerOrEdge()) {
      setTimeout(() => {
        this.dropdownbox.nativeElement.classList.remove('open');
        this.dropdownbox.nativeElement.classList.add('open');
      }, 1);
    }
  }

  clickExcelDownload(e: any, group: IGridColumnGroup) {
    e.stopPropagation();
    this.excelClick.next(group);
  }

  clickDelete(e: any) {
    e.stopPropagation();
    this.deleteClick.next();
  }

  handleTranslation(text: string): string {
    return this.formatService.translateAndFormat(text, false);
  }

  getSelectorId = (group: IGridColumnGroup): string => {
    return `${group.title.toLowerCase().split(' ').join('-')}-selector`;
  };

  getLastUpdatedLabel(): string {
    if (!this.gridConfig.lastUpdated) {
      return '';
    }
    return this.formatService.translateAndFormat(
      'main.core.tables.common.last_updated'
    );
  }

  /*
     getLastUpdatedDateTime(): string {
        if (!this.gridConfig.lastUpdated) {
            return '';
        }
        const date = DateTime.fromISO(this.gridConfig.lastUpdated, { zone: 'America/Los_Angeles' });
        const dateShort = date.toLocal().toLocaleString(DateTime.DATE_SHORT);
        const timeSimple = date.toLocal().toLocaleString(date.toLocal().locale === 'en-US' ? DateTime.TIME_SIMPLE : DateTime.TIME_24_SIMPLE);
        const abbreviation = date.toLocal().setLocale(window.navigator.language).toFormat('ZZZZ');

        return ` ${dateShort} ${timeSimple} (${abbreviation})`;
    }
   */

  getLastUpdatedDateTime(): string {
    if (!this.gridConfig.lastUpdated) {
      return '';
    }

    // We need to use this because of certain unique rules.
    const dateShort = this.formatService.formatDate(
      this.gridConfig.lastUpdated,
      'M/d/YYYY'
    );

    const locale = this.localeService.getLocale();

    const date = DateTime.fromISO(this.gridConfig.lastUpdated, {
      zone: 'America/Los_Angeles',
    });

    // https://moment.github.io/luxon/#/formatting
    //const dateTimeStr = date.toFormat('f').split(',').join('');
    const timeStr = date.setLocale(locale).toFormat('t');

    const zone = date.setLocale(locale).toFormat('ZZZZ');

    return `${dateShort} ${timeStr} (${zone})`;
  }
}
