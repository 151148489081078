import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import * as _ from 'lodash';
import { RdoHttpService } from '../core/http/rdo-http.service';
import { UserInfo } from '../models';
import { AuthenticationService } from '../core/authentication/authentication.service';

@Injectable()
export class DownloadsGuardService  {
	private hasAccess?: boolean;

    constructor(private authenticationService: AuthenticationService,
                private rdoHttp: RdoHttpService,
                private router: Router) {

	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const result = this.authenticationService._userInfoView.HasClientAccessToDownloads;

        if (result !== true) {

            this.router.navigate(['/summary']);
        }

        return of(result);
	};
}
