"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isArray = function () {
  return Array.isArray || function (x) {
    return x && typeof x.length === 'number';
  };
}();
