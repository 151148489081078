import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CustomGridsComponent } from './custom-grids.component';
import { CustomGridsService } from './custom-grids.service';

@Injectable()
export class UnsavedChangesGuard  { // implements CanActivate {

    constructor(private router: Router) {
    }

    public canDeactivate(component: CustomGridsComponent,
        currentRoute: ActivatedRouteSnapshot,currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot) {

        return !component.existUnsavedChanges(nextState.url);

    }
}
