import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
})
export class CallbackComponent implements OnInit {
  constructor(
    private authentictionService: AuthenticationService,
    private router: Router
  ) {}

  async ngOnInit() {
    if (environment.isPkceFlowMode === true) {
      // non-pkce handles callback setup in app component and implemented in

      const auth0pkceclient =
        await this.authentictionService.getAuth0PkceClient();
      const result = await auth0pkceclient.handleRedirectCallback();
      const targetRoute =
        result.appState && result.appState.target ? result.appState.target : '';

      this.authentictionService.Authenticated.next(
        await auth0pkceclient.isAuthenticated()
      ); // Update observables
      this.authentictionService.profile.next(await auth0pkceclient.getUser());

      this.router.navigate([targetRoute]);
    }
  }
}
